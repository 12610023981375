import { useState, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";

// colors:
//     red
//     orange
//     blue
//     green
//     violet
//     black
//     gray

// types:
//     sportground
//     video
//     sportEvent
//     infoPage
//     club
//     clubNews
//     eventHolding
//     support
//     news

const defColor = `gray`;

export const Status = ({ type, statusId,statusText, isRound }) => {
  
  const {
    SportsgroundStatuses,
    ClubStatuses,
    VideoStatuses,
    SportEventStatuses,
    SupportTicketStatuses,
    InfoPageStatuses,
    EventHoldingStatuses,
    ClubNewsStatuses,
    NewsStatuses,
  } = useSelector((state) => state.fullList);
  const [currentListId, setCurrentListId] = useState([]);

  useEffect(() => {
    switch (type) {
      case "sportground":
        return setCurrentListId(SportsgroundStatuses);

      case "support":
        return setCurrentListId(SupportTicketStatuses);

      case "video":
        return setCurrentListId(VideoStatuses);

      case "sportEvent":
        return setCurrentListId(SportEventStatuses);

      case "infoPage":
        return setCurrentListId(InfoPageStatuses);

      case "club":
        if(statusText?.length>0){
          setCurrentListId({statusText,statusId})
        }else{
        return setCurrentListId(ClubStatuses);   
        }
      case "clubNews":
        return setCurrentListId(ClubNewsStatuses);

      case "eventHolding":
        return setCurrentListId(EventHoldingStatuses);

      case "news":
        return setCurrentListId(NewsStatuses);

      default:
        break;
    }
  }, [type]);

  const item = useMemo(() => {
    
    let res={};
    if(statusText){
      res.title=statusText;
      res.id=statusId
    }else{
      res = currentListId?.find((obj) => obj.id === statusId);
    }
    return res || null;
  }, [currentListId, statusId]);

  const itemColor = useMemo(() => {
    if (!item) return defColor;
    switch (item.title) {
      case "Надано відповідь":
      case "Питання вирішено":
      case "Опублікований":
      case "Ви модератор":
      case "Захід ще не почався":
        return "blue";
      case "На модерації":
      case "Ви учасник":
      case "Захід йде":
      case "Очікує відповіді фахівця":
        return "orange";
      case "Спам":
        return "red";
      default:
        return defColor;
    }
  }, [statusId, item]);
  if(isRound){
    return (
      item && <div className={`statusCircle ${itemColor}`}></div>
    );
  }else{
    return (
      item && <div className={`apStatusItem ${itemColor}`}>{item.title}</div>  || <div className={`apStatusItem ${itemColor}`}>Статус невідомий</div>
    );
  }
  
};
