import React from 'react';
import QRCode from 'react-qr-code';
import { Redirect } from 'react-router-dom';
import { useParams } from "react-router";


class QrPrintComponent extends React.Component {
    constructor(props) {
        super(props);
      }
    render() {
        return (
        <div className='qrComponentContainer' >
         
            <center>
                
                <h4>{this.props.name}</h4>
                <QRCode 
                    size={256}
                    style={{ height: "240px", maxWidth: "240px", width: "100%",margin:'0 auto'}}
                    value={this.props.link}
                    title={this.props.name}
                    viewBox={`0 0 256 256`}
                    fgColor={'#1BA8B2'}
                    // bgColor={'#C5E505'}
                />
            </center>
        </div>
        )
    
    
    }
}


export default QrPrintComponent;
