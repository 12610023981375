import React, { useState } from "react";
import { Reorder, useDragControls } from "framer-motion";
import { Icon } from "../Icon";
import { useDispatch } from "react-redux";
import { addWorkouts } from "../../redux/actions/workouts";
import { Icon as Button } from "../Icon";
import { useParams } from "react-router";


const CreatePlanForm = (props) => {
  const dispatch=useDispatch();
  const [form, setForm] = useState({});
  const [inputValue, setInputValue] = useState('');
  const currentId=useParams().id
  const backgroundClick = (e) => {
    if (e.target.className === "formBackground") {
      props.setFormActive(false);
    }
  };

  const dayClick = (e) => {
    let arr = form.weekDays || [];
    if (arr.indexOf(e.currentTarget.id) >= 0) {
      arr.splice(arr.indexOf(e.currentTarget.id), 1);
    } else {
      arr.push(e.currentTarget.id);
    }
    setForm({ ...form, weekDays: arr });
  };

  const heandleRemovePoint = (index) => {
      let arr =form.exercises;
      arr.splice(index,1);
    setForm({...form,['exercises']:[...arr]});
  };

  const reorderClick = (e) => {
    e.map((el, index) => {
      el.pointIndex = index;
    });
    setForm({...form,['exercises']:e})
  };

  const handleClick = () => {
    // alert("click");
  };
  const createClick =()=>{
      let body = form;
      body.userId=props.id;
    if(!body.isOnce){
        body.isOnce=false;
    }
    body.isActive=true;
    body.type='Тренування'
    if(props.videoId){
      body.videoId=props.videoId;
    }
    dispatch(addWorkouts(body)).then(res=>{
      props.setReady(false);
      props.setPlanReady(false);
    })
    props.closeClick();
  }
const submitClick=()=>{
    setForm({...form,['exercises']:[...form.exercises || [], inputValue]})
    setInputValue('')
}
  const changeHandler=(e)=>{
    setForm({ ...form, [e.target.id]: e.target.value });
  }
  const changeExerciseItem=(e)=>{
    //   let data={pointIndex:form.exercises?.length?form.exercises.length :0,
    //     value: e.target.value}
    let data= e.target.value;
    setInputValue(data)
  }
  return (
    <div className='formBackground' onClick={backgroundClick}>
      <div className="formBlock">
        <div className="apFilterHeader form">
          <h2>Створити тренування</h2>

          <Button
              type={"cross"}
              className={"cross"}
              onClick={props.closeClick}
            />
          {/* <button
            className="secondarySmallRoundedButtonTextDefault"
            style={{ fontWeight: "200" }}
            onClick={props.closeClick}
          >
            X
          </button> */}
        </div>
        <h2>Назва</h2>
        <div className="apInputTextIcon form">
          <div>
            <input
              onChange={changeHandler}
              id="title"
              type="text"
              value={form.title || ''}
              placeholder="Введіть назву тренування"
            />
          </div>
        </div>
        {/* <input placeholder='Введіть назву тренування' type="text" /> */}
        <h2>Дні тренування:</h2>
        <div className="weekList">
          <div
            className={
              form.weekDays?.indexOf("0") >= 0
                ? "weekListItem active"
                : "weekListItem"
            }
            onClick={dayClick}
            accessKey="weekDay"
            id="0"
          >
            Пн
          </div>
          <div
            className={
              form.weekDays?.indexOf("1") >= 0
                ? "weekListItem active"
                : "weekListItem"
            }
            onClick={dayClick}
            accessKey="weekDay"
            id="1"
          >
            Вт
          </div>
          <div
            className={
              form.weekDays?.indexOf("2") >= 0
                ? "weekListItem active"
                : "weekListItem"
            }
            onClick={dayClick}
            accessKey="weekDay"
            id="2"
          >
            Ср
          </div>
          <div
            className={
              form.weekDays?.indexOf("3") >= 0
                ? "weekListItem active"
                : "weekListItem"
            }
            onClick={dayClick}
            accessKey="weekDay"
            id="3"
          >
            Чт
          </div>
          <div
            className={
              form.weekDays?.indexOf("4") >= 0
                ? "weekListItem active"
                : "weekListItem"
            }
            onClick={dayClick}
            accessKey="weekDay"
            id="4"
          >
            Пт
          </div>
          <div
            className={
              form.weekDays?.indexOf("5") >= 0
                ? "weekListItem active"
                : "weekListItem"
            }
            onClick={dayClick}
            accessKey="weekDay"
            id="5"
          >
            Сб
          </div>
          <div
            className={
              form.weekDays?.indexOf("6") >= 0
                ? "weekListItem active"
                : "weekListItem"
            }
            onClick={dayClick}
            accessKey="weekDay"
            id="6"
          >
            Нд
          </div>
        </div>
        <h2>Оберіть час:</h2>
        <div className="timePickerWrap">
          <div className="timeList">
            <div className="timePicker">
              <input type="time" id="startTime" value={form.startTime|| ''} onChange={changeHandler}/>
              <Icon type={"time"} />
            </div>
            <div className="timePicker">
              <input type="time"  id="finishTime" value={form.finishTime|| ''} onChange={changeHandler}/>
              <Icon type={"time"} />
            </div>
          </div>
          <div className="apCheckBoxWithLabel">
            <label htmlFor="profileDeactivation">Періодичне</label>
            <input
              id="profileDeactivation"
              type="checkbox"
              className="apCheckbox"
              checked={form.isOnce}
              onChange={(e)=>setForm({ ...form, ["isOnce"]: !form.isOnce })}
            />
            <label htmlFor="profileDeactivation">Разове</label>
          </div>
        </div>

        <h2>Вправи:</h2>
        <div className="trainingStepsWrap">
          {form.exercises?.length > 0 && (
            <Reorder.Group
              axis="y"
              values={form.exercises}
              onReorder={reorderClick}
              layoutScroll
              style={{ overflowY: "scroll", maxHeight: "200px" }}
            >
              {form.exercises.map((item, index) => (
                <Item
                  key={`${item}.${index}`}
                  handleClick={handleClick}
                  value={item}
                  item={item}
                  onDelete={() => heandleRemovePoint(index)}
                />
              ))}
            </Reorder.Group>
          )}
          <div className="apInputText trainingAppInput">
            <input placeholder="Додати вправу" type="text" value={inputValue ||''} onChange={changeExerciseItem} />
            <Icon
              type={"submitArrow"}
              onClick={submitClick}
            />
          </div>
        </div>
        <div className="formButtonGroup">
          <Icon
            type={"file"}
            children={"Опублікувати тренування"}
            onClick={createClick}
          />
          <Icon
            type={"roundCross"}
            children={"Скасувати"}
            onClick={props.closeClick}
          />
        </div>
      </div>
    </div>
  );
};

const Item = (props) => {
  const controls = useDragControls();

  return (
    <Reorder.Item
      value={props.value}
      dragListener={false}
      dragControls={controls}
    >
      <div className="trainingReordeItem">
        {props.value}
        <div className="controls">
          <Icon className={"cross"} type={"cross"} onClick={props.onDelete} />
          <button
            className="dragButton"
            onPointerDown={(e) => controls.start(e)}
          >
            <Icon type={"list"} />
          </button>
        </div>
      </div>
    </Reorder.Item>
  );
};

export default CreatePlanForm;
