import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { addVideo, deleteVideo, editVideo, getVideoItem } from "../../redux/actions/video";
import Loader from "../../components/Loader/Loader";
import VideoUpload from "./VideoUpload/VideoUpload";
import InfoVideo from "./InfoVideo/InfoVideo";
import MessagesPage from "./MessagePage/MessagesPage";

const VideoItemPage = () => {
  const [ready, setReady] = useState(false);
  const [form, setForm] = useState({});
  const { listId } = useSelector((state) => state);
  const { currentUser } = useSelector((state) => state.currentUser);
  const { fullList } = useSelector((state) => state);
  const navigate = useNavigate();
  const currentId = useParams().id;
  const type = useParams().type;

  const dispatch = useDispatch();
  const getItem = () => {
    setReady(false);
    dispatch(getVideoItem({ id: currentId }))
      .then((res) => {
        setForm(res);
        setReady(true);
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const saveEdit = useCallback(
    (data) => {
      dispatch(editVideo(data && data.regions ? data : form)).then((res) => {});
    },
    [form]
  );

  const changeStatus = (e) => {
    let data = {};
    data.type = e.target.accessKey;
    data.id = currentId;

    dispatch(addVideo(data)).then((res) => {
      getItem();
    });
  };

  const deleteClick=(e)=>{
    let data = {};
    data.id = currentId;
    dispatch(deleteVideo(data)).then((res)=>{
      navigate("/videoCatalog")
    })
  }

  useEffect(() => {
    if (!ready) {
      getItem();
    }
  }, []);

  if (!ready || !fullList.OwnershipTypes || !listId.list) {
    return (
      <div style={{ position: "relative" }}>
        {" "}
        <div style={{ position: "absolute", top: "50%", left: "50%" }}>
          <Loader />
        </div>
      </div>
    );
  }

  if (ready) {
    return (
      <>
        <h1>
          Картка відеоролика
          <h2 style={{ margin: "0" }}>
            Статус відеороліка: {listId.list[form.statusId]}
          </h2>
        </h1>
        <div className="menuFunctionLine">
          <div className="actionButtonsBlock">
            <button
              className="secondaryButtonIconTextDefault"
              onClick={() => navigate("/videoCatalog")}
            >
              <svg>
                <use href="/images/icon24ArrowLeft.svg#icon24" />
              </svg>
              Назад
            </button>
            {form.statusId === "def3497e-3498-4a17-a514-e99ec83676b4" &&
              currentUser.roleId === "2f5a84bc-52b5-4777-8790-e2c32daa6a34" && (
                <button
                  className="primaryButtonIconTextDefault"
                  accessKey="publish"
                  onClick={changeStatus}
                >
                  <svg accessKey="publish">
                    <use
                      accessKey="publish"
                      href="/images/icon24CheckCircle.svg#icon24"
                    />
                  </svg>
                  Опублікувати
                </button>
              )}
            {form.statusId === "73f891c0-a79f-4afc-bd79-d529a5eb5774" && (
              <button
                className="primaryButtonIconTextDefault"
                accessKey="send"
                onClick={changeStatus}
              >
                <svg accessKey="send">
                  <use
                    accessKey="send"
                    href="/images/icon24CheckCircle.svg#icon24"
                  />
                </svg>
                На модерацію
              </button>
            )}

{form.statusId === "73f891c0-a79f-4afc-bd79-d529a5eb5774" || form.statusId === "def3497e-3498-4a17-a514-e99ec83676b4" &&   currentUser.roleId === "2f5a84bc-52b5-4777-8790-e2c32daa6a34" && (
              <button
                className="secondaryButtonIconTextDefault"
                onClick={deleteClick}
              >
                <svg accessKey="send">
                  <use
                    accessKey="send"
                    href="/images/icon24Trash.svg#icon24"
                  />
                </svg>
                Видалити
              </button>
            )}



            {(form.statusId === "66e2ec16-03a1-4367-834a-d6b87ee709bd" ||
              form.statusId === "f7b77851-1d35-4ab1-9fcd-ef6cd2b88ace") && (
              <button
                accessKey="send"
                className="secondaryButtonIconTextDefault"
                onClick={changeStatus}
              >
                <svg accessKey="send">
                  <use
                    accessKey="send"
                    href="/images/icon24ArrowReturn.svg#icon24"
                  />
                </svg>
                Зняти з публікації та редагувати
              </button>
            )}
            {form.statusId !== "f7b77851-1d35-4ab1-9fcd-ef6cd2b88ace" &&
              form.statusId !== "73f891c0-a79f-4afc-bd79-d529a5eb5774" && (
                <button
                  accessKey="deactivate"
                  className="secondaryButtonIconTextDefault"
                  onClick={changeStatus}
                >
                  <svg accessKey="deactivate">
                    <use
                      accessKey="deactivate"
                      href="/images/icon24Trash.svg#icon24"
                    />
                  </svg>
                  Деактивувати
                </button>
              )}
          </div>
          <div className="actionTriggersBlock">
            <div className="apCheckBoxWithLabel">
              <input
                id="closedGroundSwiper"
                disabled={
                  form.statusId === "66e2ec16-03a1-4367-834a-d6b87ee709bd"
                }
                checked={form.priority}
                type="checkbox"
                className="apCheckbox"
                onChange={() =>
                  setForm({ ...form, ["priority"]: !form.priority })
                }
                onBlur={saveEdit}
              />
              <label htmlFor="closedGroundSwiper">Пріоритетне відео</label>
            </div>
            <div className="apCheckBoxWithLabel">
              <input
                id="closedGroundSwiper"
                disabled={
                  form.statusId === "66e2ec16-03a1-4367-834a-d6b87ee709bd"
                }
                checked={form.isTro}
                type="checkbox"
                className="apCheckbox"
                onChange={() => setForm({ ...form, ["isTro"]: !form.isTro })}
                onBlur={saveEdit}
              />
              <label htmlFor="closedGroundSwiper">Відео ТРО</label>
            </div>
          </div>
        </div>
        <div className="pageContentHeaderDivider" />
        <VideoUpload
          getItem={getItem}
          setForm={setForm}
          form={form}
        ></VideoUpload>
        <div className="menuTabsLine">
          <button
            className={
              type === "info"
                ? "tabButtonIconTextActive"
                : "tabButtonIconTextDefault"
            }
            onClick={() => navigate(`/videoItem/${currentId}/info`)}
          >
            <svg>
              <use href="/images/icon24Info.svg#icon24" />
            </svg>
            Про відеоролик
          </button>
          <button
            className={
              type === "messages"
                ? "tabButtonIconTextActive"
                : "tabButtonIconTextDefault"
            }
            onClick={() => navigate(`/videoItem/${currentId}/messages`)}
          >
            <svg>
              <use href="/images/icon24MsgCircleDef.svg#icon24" />
            </svg>
            Повідомлення
          </button>
        </div>

        {type === "info" && <InfoVideo></InfoVideo>}
        {type === "messages" && <MessagesPage></MessagesPage>}
      </>
    );
  }
};

export default VideoItemPage;
