import React, { useEffect, useState, } from "react";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../components/Loader/Loader";
import Pagination from "../../components/Paggination/Paggination";
import Select from "../../components/Select/";
import { getRoutes } from "../../redux/actions/route";
import getDate from "../../helpers/getDate";

const sortOtions = [
    { id: "updatedAt", value: "За датою редагування" },
    { id: "title", value: "За абеткою" },
  ];

const RouteLibraryPage = ()=>{
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [searchOpen, setSearchOpen] = useState(false);
    const [searchValue, setSearchValue] = useState("");
    const [modal, setModal] = useState(false);
    const { route } = useSelector((state) => state);
    const [intervalId,setIntervalId] = useState(null);  const [ready, setReady] = useState(false);
    const [sortData, setSortData] = useState("updatedAt");
    const [sortName, setSortName] = useState("За датою редагування");
    const [sortValue, setSortValue] = useState("desc");
    const { listId } = useSelector((state) => state);
    const [currentPage, setCurrentPage] = useState(1);
    const [filter, setFilter] = useState(false);
    const [filterArr, setFilterArr] = useState({});

    const searchChange = (e) => {
        let filter = {};
        setSearchValue(e.target.value);
        if (localStorage.getItem("routeFilter")) {
          filter = JSON.parse(localStorage.getItem("routeFilter"));
        }
        filter.all = [];
          clearTimeout(intervalId);
          const newIntervalId = setTimeout(() =>{
          // Call your search function with the updated query value
        
          if (e.target.value.length > 2) {
            filter.all[0] = e.target.value;
            localStorage.setItem("routeFilter", JSON.stringify(filter));
            initFunc(1, filter);
          } else {
            localStorage.setItem("routeFilter", JSON.stringify(filter));
            initFunc(1, filter);
          }
        }, 1000)
        setIntervalId(newIntervalId);  ;
    
      };

      const sortClick = (e) => {
        setSortName(e.target.innerHTML);
        const currentIdIndex = sortOtions.findIndex(
          (x) => x.value === e.target.innerHTML
        );
        setSortData(sortOtions[currentIdIndex].id);
        let data = {};
        if (sortData === sortOtions[currentIdIndex].id && sortValue === "asc") {
          setSortValue("desc");
          data[sortOtions[currentIdIndex].id] = "desc";
        } else {
          data[sortOtions[currentIdIndex].id] = "asc";
          setSortValue("asc");
        }
        localStorage.setItem("routeSort", JSON.stringify(data));
        initFunc();
        setModal(false);
      };


      const  initFunc=(page = 1, filter) => {
        let data = {};
        if (filter) {
          data.filter = { ...filter };
        } else {
          if (localStorage.getItem("routeFilter")) {
            data.filter = { ...JSON.parse(localStorage.getItem("routeFilter")) };
          } else {
            data.filter = {};
          }
        }
        if (localStorage.getItem("routeSort")) {
          data.sort = { ...JSON.parse(localStorage.getItem("routeSort")) };
        } else {
          data.sort = { updatedAt: "desc" };
        }
    
    
        data.offset = (page - 1) * 10;
        data.limit = 10;
        dispatch(getRoutes(data)).then((res) => {
          console.log(res);
          setReady(true);
        });
      };

      useEffect(() => {
        if (!ready) {
          if (localStorage.getItem("routeFilter")) {
            let filter = JSON.parse(localStorage.getItem("routeFilter"));
            initFunc(1, filter);
            setFilterArr(filter);
          } else {
            initFunc();
          }
        }
      }, [ready]);


      if (!ready) {
        return (
          <div style={{ position: "relative" }}>
            {" "}
            <div style={{ position: "absolute", top: "50%", left: "50%" }}>
              <Loader />
            </div>{" "}
          </div>
        );
      } else{
        return (
    <>
        <div className="headerWithButtonsCommonBlock">
          <div className="headerWithButtonsHeaderBlock">
            <h1>Бібліотека маршрутів</h1>
          </div>
          <div className="headerWithButtonsButtonBlock">
            {searchOpen && (
              <div style={{ marginBottom: "4px" }} className="apInputText">
                <label htmlFor="search">Пошук</label>
                <input
                  id="search"
                  type="text"
                  placeholder="Мінімум 3 символи"
                  value={searchValue}
                  onChange={searchChange}
                />
              </div>
            )}
            <button
              className="secondaryButtonIconDefault"
              onClick={() => setSearchOpen(!searchOpen)}
            >
              <svg>
                <use href="/images/icon24Search.svg#icon24" />
              </svg>
            </button>
          </div>
        </div>
        <div className="pageContentHeaderDivider" />
        <div className="dictAllRowsContainer routeLibrary">
          {route &&
            route.items &&
            route.items.length > 0 ?
            route.items.map((el, index) => {
              return (
                <div key={index} className="catVideosRowNormal"
                onClick={() => navigate(`/routeitem/${el.id}`)}
                >
                  <div className="catVideoValue">
                    <div
                      style={{backgroundImage:`url("${el.coverImage||(el.photos?.length>0&&el.photos[0])}")`}}
                      className="myObjectValueImage"
                    />
                  </div>
                  <div
                    className="catVideoValue"
                    // onClick={() => navigate(`/routeitem/${el.id}`)}
                  >
                    {el.name}
                  </div>
                  <div className="catVideoValue">
                    {listId.list[el.typeId]}
                    <span>{listId.list[el.complexityId]}</span>
                  </div>
                  <div className="catVideoValue specWidth">
                    {el.address}
                    <span>Адреса</span>
                  </div>
                  <div className="catVideoValue">
                    {el.createdBy.lastName ? el.createdBy.lastName + ' ' +el.createdBy.firstName: el.createdBy.nickname}
                    <span>створив</span>
                    {/* {el.integrity == "1" && (
                      <div className="apPublished">Опубліковано</div>
                    )}
                    {el.integrity == "0" && (
                      <div className="apOnRepair">Чернетка</div>
                    )} */}
                  </div>
                  <div className="catVideoValue">
                    {(+el.routeLength).toFixed(2)} км
                    <span>Довжина</span>
                    {/* {el.integrity == "1" && (
                      <div className="apPublished">Опубліковано</div>
                    )}
                    {el.integrity == "0" && (
                      <div className="apOnRepair">Чернетка</div>
                    )} */}
                  </div>
                  <div className="catVideoValue">
                    {getDate(el.updatedAt,'dd/mm/yyyy hh:mm','.')}
                    <span>Змінено</span>
                  </div>
                </div>
              );
            })
            :
            <div className="apFcEvent empty" style={{maxWidth:'100%'}}>
            Не створено жодного маршруту
            </div>}
        </div>
        {route.total > 10 && (
          <Pagination
            className="pagination-bar"
            currentPage={currentPage}
            totalCount={route.total.toFixed(0)}
            pageSize="10"
            onPageChange={async (page) => {
              await setCurrentPage(page);
              await initFunc(page);
            }}
          />
        )}
    
    </>
)}

}

export default RouteLibraryPage;