import {
    GETUSERVIDEO_SUCCESS,
    GETUSERVIDEO_FAIL,
    EDITUSERVIDEO_SUCCESS,
    EDITUSERVIDEO_FAIL,
    DELETEUSERVIDEO_SUCCESS,
    DELETEUSERVIDEO_FAIL,
    GETUSERVIDEOITEM_SUCCESS,
    GETUSERVIDEOITEM_FAIL,
    CREATEUSERVIDEO_SUCCESS,
    CREATEUSERVIDEO_FAIL
} from "./types";

import VideoService from "../services/videoUser.service"


export const getUserVideos = (data)=> async(dispatch)=>{
        
    try{
        const res = await VideoService.getVideos(data);
        
        dispatch({
          type: GETUSERVIDEO_SUCCESS,
          list: res,
        });

        return res
    }catch (error){
        dispatch({
            type: GETUSERVIDEO_FAIL,
            list: {error: error.response},
          });
          const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
          return error.response
    }
}

export const getUserVideosMy = (data)=> async(dispatch)=>{
        
    try{
        const res = await VideoService.getVideosMy(data);
        
        dispatch({
          type: GETUSERVIDEO_SUCCESS,
          list: res,
        });
        
        return res
    }catch (error){
        
        dispatch({
            type: GETUSERVIDEO_FAIL,
            list: {error: error.response},
          });
          const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
          return error.response
    }
}


export const editUserVideo= (form)=>(dispatch)=>{
      
    return VideoService.editVideo(form).then(
        (response)=>{
              
            dispatch({
                type:EDITUSERVIDEO_SUCCESS,
                item:response
            });
            
            return response

        },
        (error)=>{
            dispatch({
                type: EDITUSERVIDEO_FAIL,
              });
            console.log(error);
              return Promise.reject();
        }
    );
}

export const editUserVideoVote= (form)=>(dispatch)=>{
      
    return VideoService.voteVideo(form).then(
        (response)=>{
              
            dispatch({
                type:EDITUSERVIDEO_SUCCESS,
                item:response
            });
            
            return response

        },
        (error)=>{
            dispatch({
                type: EDITUSERVIDEO_FAIL,
              });
            console.log(error);
              return Promise.reject();
        }
    );
}

export const deleteUserVideo= (data)=>(dispatch)=>{
      
    return VideoService.deleteVideo(data).then(
        (response)=>{
            dispatch({
                type:DELETEUSERVIDEO_SUCCESS,
            });
              
        },
        (error)=>{
            dispatch({
                type: DELETEUSERVIDEO_FAIL,
              });
            console.log(error);
                 
              return Promise.reject();
        }
    );
}

export const getUserVideoItem = (data)=>(dispatch)=>{
    return VideoService.getVideoItem(data).then(
        (response)=>{
              
            dispatch({
                type:GETUSERVIDEOITEM_SUCCESS,
                item:response,
            });
              
            return response

        },
        (error)=>{
            dispatch({
                type: GETUSERVIDEOITEM_FAIL,
              });
            console.log(error);
                 
              return Promise.reject();
        }
    );

}


export const addUserVideo = (data)=> async(dispatch)=>{
    try {
        const res = await VideoService.addVideo(data);
        dispatch({
          type: CREATEUSERVIDEO_SUCCESS,
          item: res
        });
        return res
    }catch (error){
        dispatch({
            type: CREATEUSERVIDEO_FAIL,
            item: {error: error.response},
          });
          const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
          return error.response;
    }
}