import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import { areEqual } from "../../../../../components/Calendar/calendar";
import CreatePlanForm from "../../../../../components/CreatePlanForm/CreatePlanForm";
import EditPlanForm from "../../../../../components/CreatePlanForm/EditPlanForm";
import { Icon as Button } from "../../../../../components/Icon";
import { deleteWorkouts, getWorkoutsPlan } from "../../../../../redux/actions/workouts";
import { PlanItem } from "./PlanItem";

const Plan = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const setOpenClass = props.setOpen || isOpen;
  const [form, setForm] = useState({weekDays:['0']});
  const [list, setList] = useState([]);
  const [ready, setReady] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [editItem, setEditItem] = useState({});
  const dispatch=useDispatch();
  const currentId=useParams().id

  const dayClick = (e) => {
    let arr = form.weekDays || [];
    if (arr.indexOf(e.currentTarget.id) >= 0 && arr.length==1) {
      arr.splice(arr.indexOf(e.currentTarget.id), 1);
    } else{
      arr=[e.currentTarget.id];
    }
    setForm({ ...form, weekDays: arr });

    // initFunc(e.currentTarget.id)

  };
  const editClick=(itemOn)=>{
    setEditItem(itemOn)
    setEditModal(true);
  }
  const deleteClick=(e)=>{
      e.preventDefault();
      dispatch(deleteWorkouts({id:e.target.id})).then(res=>{
        setTimeout(() => {
          props.setReady(false);
          initFunc(form.weekDays[0]);
        }, 500);
      })
  }

  const initFunc=(id)=>{
      let data={id:currentId,week:id?id:'0'};
    dispatch(getWorkoutsPlan(data)).then(res=>{
        setList(res.items)
        setReady(true)
    })
  }

  useEffect(() => {
      if(!ready){
        initFunc('0');
      }
  }, [ready]);

  return (
    <>
      {props.modalOpen && (
        <CreatePlanForm setReady={props.setReady} setPlanReady={setReady} id={currentId} closeClick={() => props.setModalOpen((s) => !s)} />
      )}
      {editModal && (
        <EditPlanForm item={editItem} setReady={props.setReady} setPlanReady={setReady} id={currentId} closeClick={() => setEditModal(false)} />
      )}
      <div className={`apTrainigPlan ${setOpenClass ? "isOpen" : ""}`}>
        <Button
          type={"plan"}
          className={"trigger"}
          onClick={() => setIsOpen((state) => !state)}
        >
          План тренувань
        </Button>
        <Button
          type={"plus"}
          className={"plus"}
          onClick={() => props.setModalOpen((s) => !s)}
        ></Button>
        <div className="apPlanContentBox">
          <div className="contentBoxHeader">
            <Button
              type={"cross"}
              className={"cross"}
              onClick={() => setIsOpen((state) => !state)}
            />
            <div className="weekList">
              <div
                className={
                  form.weekDays?.indexOf("0") >= 0
                    ? "weekListItem active"
                    : "weekListItem"
                }
                onClick={dayClick}
                accessKey="weekDay"
                id="0"
              >
                Пн
              </div>
              <div
                className={
                  form.weekDays?.indexOf("1") >= 0
                    ? "weekListItem active"
                    : "weekListItem"
                }
                onClick={dayClick}
                accessKey="weekDay"
                id="1"
              >
                Вт
              </div>
              <div
                className={
                  form.weekDays?.indexOf("2") >= 0
                    ? "weekListItem active"
                    : "weekListItem"
                }
                onClick={dayClick}
                accessKey="weekDay"
                id="2"
              >
                Ср
              </div>
              <div
                className={
                  form.weekDays?.indexOf("3") >= 0
                    ? "weekListItem active"
                    : "weekListItem"
                }
                onClick={dayClick}
                accessKey="weekDay"
                id="3"
              >
                Чт
              </div>
              <div
                className={
                  form.weekDays?.indexOf("4") >= 0
                    ? "weekListItem active"
                    : "weekListItem"
                }
                onClick={dayClick}
                accessKey="weekDay"
                id="4"
              >
                Пт
              </div>
              <div
                className={
                  form.weekDays?.indexOf("5") >= 0
                    ? "weekListItem active"
                    : "weekListItem"
                }
                onClick={dayClick}
                accessKey="weekDay"
                id="5"
              >
                Сб
              </div>
              <div
                className={
                  form.weekDays?.indexOf("6") >= 0
                    ? "weekListItem active"
                    : "weekListItem"
                }
                onClick={dayClick}
                accessKey="weekDay"
                id="6"
              >
                Нд
              </div>
            </div>
          </div>
        {ready && list.length>0 ? 
        list.map((el) => {
                if(el.weekDay === +form.weekDays[0] ){
                return(
                <PlanItem deleteClick={deleteClick} editClick={editClick}  el={el} key={el.id} />
            )
        }
        }):props.modalOpen&&<div className="apEmptyCategory">Не заплановано тренувань</div>}
           
             

        </div>
      </div>
    </>
  );
};

export default Plan;
