import React, { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import Petal from "./Petal/Petal";


const PetalsBlock=(props)=>{
    const [ready,setReady]= useState(false);
    const [ready1,setReady1]= useState(false);
    const fullList = useSelector(state=>state.fullList);
    const [textObject,setTextObject] = useState({});

    const initialFunction=()=>{
        let newTextObject={}
            props.fullList?.ExerciseCategories?.forEach(element => {
                if(element.id==='fb7b81dc-b6dc-4e14-a43d-56839be90c5c'){
                    newTextObject['01']=element;
                }else if(element.id==='76f644fd-04ca-400a-b767-1b4370c9a5ec'){
                    newTextObject['02']=element;
                }else if(element.id==='7e1937d2-3a07-4473-a1bb-a225d77ffd45'){
                    newTextObject['03']=element;
                }else if(element.id==='748b6683-0968-45d0-91ac-893d69525a6e'){
                    newTextObject['04']=element;
                }else if(element.id==='571f4d2c-cfea-476f-966b-c8e27b3cdac9'){
                    newTextObject['05']=element;
                }else if(element.id==='a2f3d609-3775-4fcc-bad0-4d8351830e1d'){
                    newTextObject['06']=element;
                }
            });
               
        setTextObject(newTextObject);
        setReady(true);
        

    }

   
    const initFunction=()=>{
        let obj={...props.className};
        let newTextObject={...textObject}
        if(props.action==='default'){
            initialFunction();
        }
        if(props.action==='qr'){
            for (const [key, value] of Object.entries(props.className)) {
                if(key!==props.categoryId){
                    obj[key]='disabled'
                }
            };
        }else if(props.action==='category'){
            if(textObject[props.category].subcategories.length>0){
                let length=textObject[props.category].subcategories.length;
                if(length>5){
                    length=5;
                }
                let prev =  Math.round(length/2);
                let after = length-prev;
                for (const [key, value] of Object.entries(props.className)) {
                    if(key===props.category){
                        obj[key]='selected';
                    }
                   else{
                        obj[key]='clear'
                    }
                };
                let counter=0;
                for(let i=props.category-1;i>=props.category-prev;i--){
                    let index='0'+(i+'')
                    obj[index]='subCategory';
                    newTextObject[index] = textObject[props.category].subcategories[counter];
                    counter++
                }
                for(let i=+props.category+1;i<=+props.category+after;i++){
                    let index='0'+(i+'')
                    obj[index]='subCategory';
                    newTextObject[index] = textObject[props.category].subcategories[counter];
                    counter++
                }
                setTextObject(newTextObject);
            }
            else{
                props.subcategoryClick();
            }
        }else if(props.action==='default'){
            for (const [key, value] of Object.entries(props.className)) {
                obj[key]='normal'
            };
        }else if(props.action==='difficulty'){

            for (const [key, value] of Object.entries(props.className)) {
                if(key=='06'||key=='01'||key=='02'){
                    obj[key]='difficulty'
                }
                else{
                    obj[key]='none'
                }
            };
        }
        props.setClassName({...obj})
    }

    useEffect(()=>{
        if(!ready1){
            initFunction()
        } 
        if(!ready){
            initialFunction()
        }
    },[props.action,props.category,ready])
    if(!ready || !fullList){
        return( 'waiting')
    }
    if(ready && fullList){
    return(
        <div className="petalsPageCenterBlock">
            <div className="petals">
                <Petal index={'06'} data={textObject['06']} className={props.className['06']} category={props.category} clickHandler={props.clickHandler} subcategoryClick={props.subcategoryClick} setDifClick={props.setDifClick}></Petal>
                <Petal index={'02'} data={textObject['02']} className={props.className['02']} category={props.category} clickHandler={props.clickHandler} subcategoryClick={props.subcategoryClick} setDifClick={props.setDifClick}></Petal>
                <Petal index={'01'} data={textObject['01']} className={props.className['01']} category={props.category} clickHandler={props.clickHandler} subcategoryClick={props.subcategoryClick} setDifClick={props.setDifClick}></Petal>
                <Petal index={'05'} data={textObject['05']} className={props.className['05']} category={props.category} clickHandler={props.clickHandler} subcategoryClick={props.subcategoryClick} setDifClick={props.setDifClick}></Petal>
                <Petal index={'03'} data={textObject['03']} className={props.className['03']} category={props.category} clickHandler={props.clickHandler} subcategoryClick={props.subcategoryClick} setDifClick={props.setDifClick}></Petal>
                <Petal index={'04'} data={textObject['04']} className={props.className['04']} category={props.category} clickHandler={props.clickHandler} subcategoryClick={props.subcategoryClick} setDifClick={props.setDifClick}></Petal>
            </div> 
            <div className="petalCenterCircle">&nbsp;</div>
            {(props.action==='difficulty' || props.action==='category')&&<div className="petalCenterCircle">
				<a>
				<button className='primaryMediumButtonIconDefault' style={{'background':'var(--apColorDark)'}} onClick={props.backClick}>
					<svg><use href="/images/icon24Cancel.svg#icon24"></use></svg>
				</button>
				</a>
			</div>}
			{props.action==='difficulty'&& <div className="petalCenterMessageContainer">
				<h2>Оберіть рівень складності</h2>
			</div>}
        </div>
    )
}
}


export default PetalsBlock;