import React, { useState, useEffect } from "react";
import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import Loader from "../../components/Loader/Loader";
import { deactivateUser, getUserFree } from "../../redux/actions/users";
import { Icon } from "../../components/Icon/";
import getDate from "../../helpers/getDate";
import { Tabbar } from "../../components/Tabbar";
import { Events } from "./components/Events";
import { getFcCreator, getFcList } from "../../redux/actions/fc";
import { Clubs } from "./components/Clubs";
import useCurrentUser from "../../hooks/isCurrentUser";
import Trainings from "./components/Trainings/Trainings";
import Select from "../../components/Select";
import setPhoneMask from "../../helpers/setPhoneMask";
import Statistic from "./components/Statistic/Statistic";

const modeOptions = [
  { id: "view", value: "Відображати" },
  { id: "cantView", value: "Не відображати" },
];

const ProfilePage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [ready, setReady] = useState(false);
  const [form, setForm] = useState({});
  const currentId = useParams().id;;
  const type = useParams().type;
  const { currentUser } = useSelector((state) => state.currentUser);
  const { listId } = useSelector((state) => state);
  const current = useSelector((state) => state.users.current)
  const [fcList, setFcList] = useState([]);
  const [isCurrentUser, setIsCurrentUser] = useState(false);
  const { list } = useSelector((state) => state.listId);

  const [isOpen, setIsOpen] = useState(true);
  const [sortData, setSortData] = useState("view");
  const [sortName, setSortName] = useState("Відображати");

  const sortClick = (e) => {
    setSortName(e.target.innerHTML);
    const currentIdIndex = modeOptions.findIndex(
      (x) => x.value === e.target.innerHTML
    );
    setSortData(modeOptions[currentIdIndex].id);
  };

  useEffect(() => {
    if ((form.hideBodyInfo == "1" || form.hideBodyInfo== "2" || form.hideBodyInfo== "0") ) {
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
  }, [form.hideBodyInfo]);

  const getUs = () => {
    dispatch(getFcCreator({ id: currentId, limit: 8 })).then((res) => {
      let merged = [...res?.items?.userIsHead, ...res?.items?.userIsMember];
      setFcList(merged);
    });
    dispatch(getUserFree(currentId ? currentId : currentUser?.id)).then(
      (res) => {
        setForm({ ...res });
        setReady(true);
      }
    );

  };

  const initialFunction = () => {
    getUs();
  };

  useEffect(() => {
    if (!ready || !form) {
      initialFunction();
    }
  }, [ready]);


  useEffect(()=>{
    setReady(false)
  },[currentId])

  const deactivateClick = (e) => {
    let data = { id: currentId };
    if (e.target.checked === true) {
      data.type = "deactivate";
    } else if (e.target.checked === false) {
      data.type = "activate";
    }
    dispatch(deactivateUser(data)).then((res) => {
      initialFunction();
    });
  };

  const setIMT =(weight,height)=>{
    let res = weight/(height/100*height/100);
    let resType;

    if(res<18.5){
      resType='Недостатня вага'
    }else if(18.5<res<24.9){
      resType='Нормальна вага'
    }else if(25<res<29.9){
      resType='Надлишкова вага (передожиріння)'
    }else if(30<res<34.9){
      resType='Ожиріння I ступеня'
    }else if(35<res<39.9){
      resType='Ожиріння II ступеня;'
    }else if(40<res){
      resType='Ожиріння III ступеня (морбідне ожиріння)'
    }
    return <><span>{res.toFixed(1)}&nbsp;</span> - {resType} </>
  }

  const getSex = useMemo(() => {
    if (!form || form.sex === null) return;
    switch (form.sex) {
      case "male":
        return { msg: "Чоловіча", ending: "ий" };
      case "female":
        return { msg: "Жіноча", ending: "a" };
      default:
        return;
    }
  }, [form]);

  const ageStatus = useMemo(() => {
    if (!form || !form.age) return;
    const num = Number(form.age);

    if (num < 45) return `Молод${getSex?.ending ?? "(ий/a)"}`;
    if (num < 59) return `Зріл${getSex?.ending ?? "(ий/a)"}`;
    if (num >= 60) return `Літн(ій/я)`;
  }, [form, getSex]);

  if (ready && form.roleId && listId.list) {
    return (
      <div className={`apPersonalPage ${(isOpen && "isFull") || ""}`}>
        <section className="apPersonalPageHeadline">
          <h1>
            {isOpen ? (
              "Особистий профіль"
            ) : (
              <>
                {form.lastName && form.firstName
                  ? form.firstName  + " " + form.lastName
                  : form.nickname}
                {form.roleId && <span>{listId.list[form.roleId]}</span>}{" "}{form.isTrainer=='1' &&' - очікує на тренера'}{form.isTrainer=='2' &&' - тренер'}
              </>
            )}
          </h1>

          {form &&
            currentUser &&
            (currentUser.id === form.id ||
              currentUser.roleId ===
                "631db81f-fa07-42e2-b394-062c9be66b09") && (
              <div className="apUserButtonWrap">
                {currentUser.id !== form.id &&
                  currentUser.roleId ===
                    "631db81f-fa07-42e2-b394-062c9be66b09" && (
                    <button
                      className="secondaryButtonTextDefault"
                      onClick={() => {
                        navigate("/users");
                      }}
                    >
                      Назад
                    </button>
                  )}
                <button
                  className="secondaryButtonTextDefault"
                  onClick={() => {
                    navigate("/videoUserCatalog");
                  }}
                >
                  Мої відео
                </button>
                <button
                  className="secondaryButtonTextDefault"
                  onClick={() => {
                    navigate("/supportlist");
                  }}
                >
                  Підтримка
                </button>
                <button
                  className="secondaryButtonTextDefault"
                  onClick={() => {
                    navigate("/infolist/start");
                  }}
                >
                  База знань
                </button>
                <button
                  onClick={() => navigate(`/personalpageedit/${currentId}`)}
                >
                  <Icon type={"edit"} />
                  Редагувати
                </button>
                {currentUser.roleId ===
                  "631db81f-fa07-42e2-b394-062c9be66b09" && (
                  <div className="apCheckBoxWithLabel">
                    <input
                      id="profileDeactivation"
                      type="checkbox"
                      checked={!form.isActive}
                      onClick={deactivateClick}
                      className="apCheckbox"
                    />
                    <label htmlFor="profileDeactivation">Деактивувати</label>
                  </div>
                )}
              </div>
            )}
        </section>
        <section className="apPersonalPageInfoContainer">
          <div className="avatar">
            <div
              className="img"
              style={{
                backgroundImage: `url(${
                  form.photo ||
                  "https://ap.sportforall.gov.ua/api/v1/uploads/b34604xc-02qb-46bb-9e91-fc3ffrb92w41"
                })`,
              }}
            />
          </div>
          {isOpen && (
            <div className="about">
              <div>
                <h3 className="name">
                  {form.lastName && form.firstName
                    ? form.firstName + " " +  form.lastName
                    : form.nickname}
                </h3>
                <p>{listId.list[form.roleId]}{form.isTrainer=='1' &&' - очікує на тренера'}{form.isTrainer=='2' &&' - тренер'}</p>   
                <div className="profileFlex">
                {form.createdAt && (
                  <div className="withFill">
                    <Icon type={"calendar"} />
                    На&nbsp;сервісі&nbsp;з&nbsp;
                    <span> {getDate(form.createdAt, "dd/mm/yyyy", ".")}</span>
                  </div>
                )}
                {form.lastLoginAt && (
                  <div className="withFill">
                    <Icon type={"timer"} /> Останнє відвідування &nbsp;
                    <span>
                      {getDate(form.lastLoginAt, "dd/mm/yyyy hh:mm", ".")}
                    </span>
                  </div>
                )}
                </div>
              </div>
              {(form.email ||  form.phone ) && <div style={{width:'220px'}}>
                <h3>Контакти:</h3>
                  {form.email && (
                    <div className="withFill">
                      <Icon type={"message"} />
                      <a href={"mailto:"+form.email}>{form.email}</a>
                    </div>
                  )}
                  {form.phone && (
                    <div className="withFill">
                      <Icon type={"tel"} />
                      <a href={'tel:'+form.phone}>{setPhoneMask(form.phone)}</a>
                    </div>
                  )}
              </div>}
              <div style={{maxWidth:'530px'}}>
                {form.aboutMe?.length>0 &&<><h4>Про мене:</h4>
                  <p>
                    {form.aboutMe}
                  </p></>}
                {form.healthState?.length>0 &&<><h4>Стан здоров’я:</h4>
                <p>
                  {form.healthState}
                </p></>}
              </div>
              <div style={{width:'220px'}}>
                {form.city?.length>0 && (
                  <div className="withFill">
                    <Icon type={"pin"} />
                    {form.city}
                  </div>
                )}
                   {ageStatus && ageStatus?.length>0 && (
                     <div className="withFill">
                     <Icon type={"cake"} />
                     {ageStatus}
                    </div>
                  )}
                    {getSex && (
                     <div className="withFill">
                      <Icon type={"user"} />
                     {getSex.msg}
                    </div>
                  )}
                  {
                    form?.height>0 && form?.weight>0&&
                    <div className="withFill" >
                      <div style={{'cursor':'help'}}   onClick={() => {
                        navigate("/info/yHDWrbD9");
                      }}>
                        <Icon type={"IMT"}/>
                      </div>
                      {setIMT(form.weight,form.height)}
                    </div>
                  }

              </div>

            </div>
          )}
        </section>
        <div className="user-tabbar">
          <button
            className={type === "clubs" || !type ? "active primaryButtonIconTextDefault" : "secondaryButtonIconTextDefault"}
            onClick={() => navigate(`/personalPage/${currentId}/clubs`)}
          >
            <svg><use href="/images/icon24Rocket.svg#icon24" /></svg>
            Клуби користувача
          </button>
          {currentUser?.id===currentId && <button
            className={type === "events" ? "active primaryButtonIconTextDefault" : "secondaryButtonIconTextDefault"}
            onClick={() => navigate(`/personalPage/${currentId}/events`)}
          >
            <svg><use href="/images/icon24List.svg#icon24" /></svg>
            Мої заходи
          </button>}
        {/* {(currentUser?.id===currentId||current.permissionUser)&& <button
          //className={`filled ${(type === "trainings" && "active") || ""}`}
          className={type === "trainings" ? "active primaryButtonIconTextDefault" : "secondaryButtonIconTextDefault"}
          onClick={() => navigate(`/personalPage/${currentId}/trainings`)}
        >
          <svg><use href="/images/icon24Log.svg#icon24" /></svg>
          Журнал тренувань
        </button>} */}
       { currentUser?.id===currentId && <button
            className={type === "statistic" ? "active primaryButtonIconTextDefault" : "secondaryButtonIconTextDefault"}
            onClick={() => navigate(`/personalPage/${currentId}/statistic`)}
          >
            <svg><use href="/images/icon24Rocket.svg#icon24" /></svg>
            Статистика
          </button>}
        </div>
        {(type === "clubs" || !type) && <Clubs data={fcList} />}
        {type === "events" && <Events />}
        {type === "trainings" &&  <Trainings />}
        {type === "statistic" &&  <Statistic />}
      </div>
    );
  } else if (ready && (form.length === 0 || !form.roleId)) {
    return <div className="apEmptyCategory">Користувача не знайдено</div>;
  } else {
    return <Loader />;
  }
};

export default ProfilePage;
