import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { uploadFile } from '../../../../../redux/actions/file';

const chunkSize = 1048576 * 1; //its  MB, increase the number measure in mb

const PhotoUpload =(props)=>{
    const [ready, setReady] = useState(false);
    const dispatch = useDispatch();
    const { fullList } = useSelector((state) => state);
    const [counter, setCounter] = useState(1);
    const [multipleFiles, setMultipleFiles] = useState([]);
    const [fileToBeUpload, setFileToBeUpload] = useState({});
    const [beginingOfTheChunk, setBeginingOfTheChunk] = useState(0);
    const [endOfTheChunk, setEndOfTheChunk] = useState(chunkSize);
    const [progress, setProgress] = useState(0);
    const [fileGuid, setFileGuid] = useState("");
    const [fileSize, setFileSize] = useState(0);
    const [chunkCount, setChunkCount] = useState(0);
    const [open, setOpen] = useState("");
    const [fileList, setFileList] = useState([]);
    const [chunkSet, setChunkSet] = useState();
    const [alertMessage, setAlertMessage] = useState("");
    const [alertModal, setAlertModal] = useState(false);
    const [deleteElement, setDeleteElement] = useState("");
    const [deleteModal, setDeleteModal] = useState(false);

    const initFunction = () => {
        let newArr = [];
        // props.form.photos.forEach((el) => {
        //   newArr.push({ link: el });
        // });
        setFileList(newArr);
        setReady(true);
      };
    

    const getFileContext = (e) => {
        let types = [...fullList.FileTypes[1].title.split(" ")];
        resetChunkProperties();
        let fileListArr = [];
    
        if (e.target && e.target.files) {
          fileListArr = [...e.target.files];
        } else if (e.dataTransfer && e.dataTransfer.files) {
          e.preventDefault();
          fileListArr = [...e.dataTransfer.files];
        } else {
          fileListArr = multipleFiles;
          setCounter(1);
        }

        if (
          fileListArr.length > 0
        ) {
          const _file = fileListArr[0];
          fileListArr.splice(0, 1);
          setMultipleFiles(fileListArr);
          if (_file.size > 10000000) {
            setAlertMessage("Не більше 10МБ");
            setAlertModal(true);
            return;
          }
          if (
            !types.find((a) =>
              a.includes(
                _file.name
                  .split(".")
                  [_file.name.split(".").length - 1].toLowerCase()
              )
            )
          ) {
            setAlertMessage("Не вірний формат файлу");
            setAlertModal(true);
            return;
          }
          setFileSize(_file.size);
          const _totalCount =
            _file.size % chunkSize == 0
              ? _file.size / chunkSize
              : Math.floor(_file.size / chunkSize) + 1; // Total count of chunks will have been upload to finish the file
          setChunkCount(_totalCount);
          setFileToBeUpload(_file);
          const _fileID = _file.name;
          setFileGuid(_fileID + Math.floor(1000 + Math.random() * 9000));
          // fileUpload();
        } else {
          setMultipleFiles([]);
        }
      };

      const fileUpload = () => {
        setCounter(counter + 1);
        if (counter > 0 && counter <= chunkCount) {
          let chunk = fileToBeUpload;
          uploadChunk(chunk);
        } else if (counter > 0 && counter > chunkCount) {
          setCounter(0);
        }
      };

      const uploadChunk = async (chunk) => {
        try {
          let formData = new FormData();
          formData.append("fileName", chunk.name);
          formData.append("size", fileSize);
          formData.append("chunkIndex", counter);
          formData.append("totalChunk", chunkCount);
          formData.append(
            "file",
            chunk.slice(beginingOfTheChunk, endOfTheChunk, fileToBeUpload.type)
          );
          formData.append("uploadId", chunk.name + chunkSize);
          formData.append("uploadType", "sport_event_gallery");
          formData.append("sportEventsId", props.currentId);
          setChunkSet(chunk);
          let response = await dispatch(uploadFile(formData));
          const data = response.data;
          if (response.status === 200) {
            setBeginingOfTheChunk(endOfTheChunk);
            setEndOfTheChunk(endOfTheChunk + chunkSize);
            if (counter == chunkCount) {
              // await uploadCompleted();
            } else {
              let percentage = (counter / chunkCount) * 100;
              setProgress(percentage);
            }
          } else if (response.status === 201) {
            setFileToBeUpload({});
            setProgress(100);
            let res = [...fileList];
            let obj = {
              name: fileGuid.split(".")[0],
              size: fileSize,
              type: fileGuid.split(".")[1],
              link: data.url,
            };
            res.push(obj);
            let arr = [];
            res.forEach((el) => {
              arr.push(el.link);
            });
            props.initFunc();
            setFileList(res);
            getFileContext(multipleFiles);
          } else {
            console.log("Error Occurred:", data.errorMessage);
          }
        } catch (error) {
          console.log("error", error);
        }
      };

      function dragOverHandler(ev) {
        // Prevent default behavior (Prevent file from being opened)
        ev.preventDefault();
      }

      const resetChunkProperties = () => {
        setProgress(0);
        setFileGuid("");
        if (counter === 1) {
          setCounter(1);
        } else {
          setCounter(0);
        }
        setBeginingOfTheChunk(0);
        setEndOfTheChunk(chunkSize);
        setFileSize(0);
      };

      useEffect(() => {
        if (fileSize > 0 && counter > 0) {
          fileUpload(counter);
        }
        if (!ready) {
          initFunction();
        }
      }, [fileToBeUpload, progress, multipleFiles]);



    return(<>
        <input
            type="file"
            accept=".jpg, .jpeg, .png, .gif"
            id="fileUpload"
            onChange={getFileContext}
            multiple
            style={{ display: "none" }}
        />
        <label
            id="drop_zone"
            htmlFor="fileUpload"
            className="imageItemGallery imageItemGalleryUpload"
            onDrop={getFileContext}
            onDragOver={dragOverHandler}
        >
            <svg>
                <use href="/images/icon24Download.svg#icon24" />
            </svg>
            <span>
                Додати фото
            </span>
        </label>
    
    </>)
}

export default PhotoUpload;
 