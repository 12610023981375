import {
    CREATENEWS_SUCCESS,
    CREATENEWS_FAIL,
    GETNEWS_SUCCESS,
    GETNEWS_FAIL,
    GETNEWSITEM_SUCCESS,
    GETNEWSITEM_FAIL,
    EDITNEWS_SUCCESS,
    EDITNEWS_FAIL,
    DELETENEWS_SUCCESS,
    DELETENEWS_FAIL,
} from "../actions/types";

const initialState = [];

export default function newsReducer(news = initialState,action){
    const {list,item,type} = action;

    switch(type) {
        case  CREATENEWS_SUCCESS:
            return {currentNews:item}
        case CREATENEWS_FAIL:
            return news;
        case GETNEWS_SUCCESS:
            return {...list};
        case GETNEWS_FAIL:
            return news;
        case GETNEWSITEM_SUCCESS:
            return {...item};
        case GETNEWSITEM_FAIL:
            return news;
        case EDITNEWS_SUCCESS:
            return news;
        case EDITNEWS_FAIL:
            return news;
        case DELETENEWS_SUCCESS:
            return news;
        case DELETENEWS_FAIL:
            return news;
        default:
            return news;
    }

}