import React, { useState } from 'react';
import InputMask from 'react-input-mask';

const PhoneMask = (props) => {
  const [phoneNumber, setPhoneNumber] = useState(props.value||'');

  const handleChange = (e) => {
    setPhoneNumber(e.target.value);
  };

  const handleBlur = () => {
    if (props.onChange) {
      props.onChange({ target: { name: 'phone', value: '+'+phoneNumber.replace(/\D/g, '') } });
    }
  };

  return (
    <div className={props.className}>
      <label htmlFor="phone">Телефон</label>
      <InputMask
        mask="+38 (099) 999-99-99"
        placeholder="+38 (099) 999-99-99"
        onChange={handleChange}
        onBlur={handleBlur}
        id="phone"
        name="phone"
        value={phoneNumber} // No longer needed
      />
      <span className="subtitle">Телефон має виглядати +38 (099) 999-99-99</span>
      {props.msg && props.msg}
    </div>
  );
};

export default PhoneMask;
