import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import Loader from "../../components/Loader/Loader";
import checkText from "../../helpers/checkText";
import getDate from "../../helpers/getDate";
import { getRoutePoints } from "../../redux/actions/route";
import Map from "../RouteItemPage/MapBlock/Map/Map";


const RouteItemInfo = () => {
  const [ready, setReady] = useState(false);
  const dispatch = useDispatch();
  const currentLocationId = useParams().id;
  const { routePoint: routeForm } = useSelector((state) => state);
  const { listId } = useSelector((state) => state);
  const [selected, setSelected] = useState("");
  const [selectedIndex, setSelectedIndex] = useState(0);
  const navigate = useNavigate();

  const initialFunction = () => {
    dispatch(getRoutePoints({ id: currentLocationId })).then((res) => {
      setReady(true);
    });
  };
  const openInNewTab = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  useEffect(() => {
    if (!ready) {
      initialFunction();
    }
  }, [ready]);


  if (ready) {
    return (
      <div className="parkInfoPage">
         <div className="menuFunctionLine">
          <div className="actionButtonsBlock">
            <button
              className="secondaryButtonIconTextDefault"
              onClick={() => navigate("/map")}
            >
              <svg>
                <use href="/images/icon24ArrowLeft.svg#icon24" />
              </svg>
              Назад
            </button>
            <button
              className="primaryButtonTextDefault"
              onClick={() =>
                openInNewTab(
                  `https://www.google.com.ua/maps/place/${routeForm.location[0]}+${routeForm.location[1]}`
                )
              }
            >
              Прокласти маршрут
            </button>
          </div>
        </div>

        <h1 className="apInnerH1">{routeForm.name || routeForm.address}
          <div className="headerClubSub">
            {routeForm.calories?.length>0 && <> Калорій: <span>{routeForm.calories}</span><span className="currentStatusDivider"></span></>} 
            {routeForm.complexityId?.length>0 && <> Складність: <span>{listId.list[routeForm.complexityId]}</span><span className="currentStatusDivider"></span></>} 
            {routeForm.coverType?.length>0 && <> Покриття: <span>{routeForm.coverType.map((el) => listId.list[el] + " ")}</span><span className="currentStatusDivider"></span></>} 
            {routeForm.integrity?.length>0 && <> Безбар’єрність: <span>Так</span><span className="currentStatusDivider"></span></>} 
            {routeForm.recommendedTime?.length>0 && <> Рекомендований час: <span>{routeForm.recommendedTime}</span><span className="currentStatusDivider"></span></>} 
            {routeForm.routeTravelTime?.length>0 && <> Час проходження: <span>{routeForm.routeTravelTime}</span><span className="currentStatusDivider"></span></>} 
            {routeForm.typeId?.length>0 && <> Тип: <span>{listId.list[routeForm.typeId]}</span><span className="currentStatusDivider"></span></>} 
          </div>
        </h1>
        <h3 className="headerHelpedCustom">Локація:
        <div className="headerClubSub">
              {routeForm.address && <> Адреса: <span onClick={() =>
                openInNewTab(
                  `https://www.google.com.ua/maps/place/${routeForm.location[0]}+${routeForm.location[1]}`
                )
              }> {routeForm.address}
                  </span><span className="currentStatusDivider"></span></>} 
            {routeForm.location && <> Координати: <span> {routeForm.location &&
                  routeForm.location.length > 0 &&
                  <a href={'https://www.google.com/maps/place/'+routeForm.location[0]+'+'+routeForm.location[1]} target="_blank">{routeForm.location[0] + " " + routeForm.location[1]}</a>
                  }</span><span className="currentStatusDivider"></span></>} 

            </div>

        </h3>
       
       {<Map  el={routeForm.pointsActiveRoutes} hight={600}></Map>}
        

        <div className="groundPropertiesImageSection">
            {routeForm && routeForm.photos &&routeForm.photos.length > 0 && 
                <h3 className="headerHelpedCustom">Галерея:
            </h3>}
            <div className="apParkCoverImageContainer parkItem">
              {(routeForm && routeForm.coverImage && routeForm.coverImage.length > 0) || (routeForm.photos?.length>0) ? (
                <div
                  onClick={()=>{setSelected('selected');setSelectedIndex(selectedIndex<routeForm.photos?.length-1 ?selectedIndex+1:0)}}
                  style={{
                    backgroundImage: `linear-gradient(rgba(68, 79, 96, 0.1), rgba(68, 79, 96, 0.1)), url('${
                      selected ? routeForm.photos[selectedIndex] : (routeForm.coverImage || (routeForm.photos?.length>0 &&  routeForm.photos[0]) || "https://ap.sportforall.gov.ua/api/v1/uploads/b34604xc-02qb-46bb-9e91-fc3ffrb92w41")
                    }')`,
                  }}
                >
                  <span></span>
                </div>
              ) : (
                ""
              )}
            </div>
            <div className="parkItemImageList">


              {routeForm &&
                routeForm.photos &&
                routeForm.photos.length > 0 &&
                routeForm.photos.map((element, index) => {
                  return (
                    <div
                      key={index}
                      onClick={() => {setSelected(element);setSelectedIndex(index)}}
                      style={{
                        cursor: "pointer",
                      }}
                      className="apParkImage"
                    >
                      <img loading="lazy" src={element} alt={`${element}.${index}`} />
                    </div>
                  );
                })}
            </div>
          </div>
    </div>
    );
  } else {
    return (
      <div style={{ position: "relative" }}>
        <div style={{ position: "absolute", top: "50%", left: "50%" }}>
          <Loader />
        </div>
      </div>
    );
  }
};

export default RouteItemInfo;
