import React, { useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import Cropper, { ReactCropperElement } from "react-cropper";
import Loader from "../../../../components/Loader/Loader";
import { uploadFile } from "../../../../redux/actions/file";

const EditModal=(props)=>{
    const [loading, setLoading] = useState(false);
    const [croppedImage, setCroppedImage] = useState(null);

    const [resize, setResize] = useState(true);
    const dispatch = useDispatch();
    const cropperRef = useRef(null);
  
    const onCrop = () => {
      const cropper = cropperRef.current?.cropper;

      // let croppedImage = cropper.getCroppedCanvas().toDataURL();
      cropper.getCroppedCanvas().toBlob((blob) => {
          const croppedImage = new File([blob], 'new-image.jpg', { type: 'image/jpeg', lastModified: new Date().getTime() });
          setCroppedImage(croppedImage);
      }, 'image/jpeg');
    };
  
    const saveCropChange =  () => {
      if (!croppedImage) {
        props.setAlertMessage("Будь ласка, оберіть область фото для обрізки");
        props.setAlertModal(true);
        return;
      } 

      let _file =croppedImage;
      let formData = new FormData();
      formData.append("fileName", _file.name);
      formData.append("size", _file.size);
      formData.append("chunkIndex", 1);
      formData.append("totalChunk", 1);
      formData.append("file", _file);
      formData.append("uploadId", _file.name + _file.size);
      formData.append("uploadType", props.options?.uploadType||"avatar");
      // formData.append("toDelete", 0);    
  
      setLoading(true)
      dispatch(uploadFile(formData)).then(response=>{
          const data = response.data;
  
          if (response.status === 201) {
            console.log(data);
            props.saveEdit(data.url);
            setLoading(false);
            props.setEdit(false);
          }
      });
  
  };


    return(
        <>
        <div className="modalForm"> 
            <div className="apFilterContainer" onBlur={()=>{ props.setEdit(false)}} tabIndex="0"> 
                <div className="apFilterHeader">
                    <h4>Додавання зображення</h4>
                    <div style={{ display: "flex" }}>
                        <button
                            className="secondarySmallRoundedButtonTextDefault"
                            onClick={()=>{props.setEdit(false);}}
                        >
                            X
                        </button>
                    </div>
                </div>
                <div className="apFilterGroupBlock">
                    {!loading ? <Cropper
                        src={props.element.url}
                        
                        style={{ height: props.options?.height ||  "176px", width:props.options?.width||"176px" }}
                        // Cropper.js options
                        initialAspectRatio={props.options?.initialAspectRatio ||16 / 10}
                        aspectRatio={props.options?.aspectRatio || 1}
                        autoCropArea={props.options?.autoCropArea || 1}
                        // dragMode={false}
                        guides={false}
                        viewMode={1}
                        dragMode="move"
                        scalable={resize}
                        cropBoxResizable={resize}
                        cropBoxMovable={resize}
                        crop={onCrop}
                        ref={cropperRef}
                        />:<Loader />}

                    <button onClick={saveCropChange} disabled={loading} className='primaryButtonIconTextDefault' style={{marginTop:'5px'}}>{"Зберегти зображення"} </button>
                    <button onClick={()=>{ props.setEdit(false);}} className='primaryButtonIconTextDefault' style={{marginTop:'5px'}}>Скасувати редагування фото</button>
                </div>
            </div>
        </div>
        </>
    )
}

export default EditModal;