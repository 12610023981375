import React, { useEffect, useState } from "react";
import { useRef } from "react";
// import QRCode from "react-qr-code";
import ReactToPrint from 'react-to-print';
import QrPrintComponent from "../../../components/PrintComponent/QrPrintComponent";

const QrComponent =(props)=>{
    const componentRef = useRef();
    const [alertWindow,setAlertWindow]=useState(false)

    const leaveClick=(event)=>{
        if (!event.currentTarget.contains(event.relatedTarget)) {
            props.setModalOpen(false)
        }
    }

    const copyClick=()=>{
        let copyText = document.getElementById("copyInput");
        // Select the text field
        copyText.select();
        copyText.setSelectionRange(0, 99999); // For mobile devices
        // Copy the text inside the text field
        navigator.clipboard.writeText(copyText.value);
        setAlertWindow(true)
    }

    useEffect(() => {
        if(alertWindow){
                setTimeout(() =>{
                setAlertWindow(false);
            }, 500);
        }
    }, [alertWindow]);

return(
    <div className="apFilterContainer apFilterContainerHeight"  onBlur={leaveClick} tabIndex="0">
         <button className="secondarySmallRoundedButtonTextDefault apQr" onClick={leaveClick}>X</button>
                {/* <div className="apFilterHeader">
                    <h4>Точка маршруту {+props.index+1}</h4>
                    <div style={{'display':'flex'}}>
                    <button style={{'marginRight':'20px'}} className="secondarySmallRoundedButtonTextDefault" onClick={printClick}>Друкувати</button>
                   
                    </div>
                </div> */}
                
                <div className="apFilterGroupBlock ">
                    {/* <QRCode 
                        size={256}
                        style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                        value={props.link}
                        title={"Точка маршруту"+props.index+1}
                        viewBox={`0 0 256 256`}
                        /> */}

                <QrPrintComponent 
                    name={"Точка маршруту "+(+props.index+1)}
                    ref={componentRef}
                    // currentId={currentId}
                    link={props.link}
                />
                    
                 
                </div>
                <div className="apFilterGroupBlock apQr">
                    <div className={'apInputTextIcon apDisabled'}>
                        <label htmlFor="copyInput">Посилання</label>
                        <div>
                            <input
                            id="copyInput"
                            type="text"
                            disabled={true}
                            readOnly={true}
                            value={props.link}
                            />
                            <svg className="copyHover" onClick={copyClick}>
                                <use href="/images/icon24Copy.svg#icon24" />
                            </svg>
                        </div>
                    </div>
                    <ReactToPrint
                        trigger={() => (
                        <button  className="primaryButtonTextDefault">
                            Друкувати
                        </button>
                        )}
                        content={() => componentRef.current}
                    />
                    {alertWindow && <div className="infoSmallText">
                        Скопійовано
                    </div>}
                </div>
                
    </div>
)
}


export default QrComponent;