import React, { useEffect, useState } from "react";

const Filter = (props) => {
  const [ready, setReady] = useState(false);
  const [form, setForm] = useState({ filter: {}, offset: 0, limit: 10 });
  let arr = { ...form };
  let initFunction = () => {
    setReady(false);
    if (localStorage.getItem("infoFilter")) {
      arr.filter = JSON.parse(localStorage.getItem("infoFilter"));
      setForm(arr);
    }
    setReady(true);
  };

  const saveFilter = (data) => {
    if (data) {
      props.setFilter(data.filter);
      props.getInfoList(1, data.filter);
    } else {
      props.setFilter(form.filter);
      props.getInfoList(1, form.filter);
    }
  };

  useEffect(() => {
    if (!ready) {
      initFunction();
    }
  }, []);

  const leaveClick = (event) => {
    if (!event.currentTarget.contains(event.relatedTarget)) {
      localStorage.setItem("infoFilter", JSON.stringify(form.filter));
      saveFilter();
      props.setModal(false);
    }
  };
  const resetClick = () => {
    arr = { filter: [] };
    setForm(arr);
    localStorage.removeItem("infoFilter");
    saveFilter(arr);
  };
  const dateChange = (e) => {
    e.preventDefault();
    arr = { ...form };

    arr.filter[e.target.accessKey] = [];
    arr.filter[e.target.accessKey].push(e.target.value);
    setForm(arr);
  };

  return (
    <div className="apFilterContainer" onBlur={leaveClick} tabIndex="0">
      <div className="apFilterHeader">
        <h4>Параметри фільтра</h4>
        <div style={{ display: "flex" }}>
          <button
            style={{ marginRight: "20px" }}
            className="secondarySmallRoundedButtonTextDefault"
            onClick={resetClick}
          >
            Скинути
          </button>
          <button
            className="secondarySmallRoundedButtonTextDefault"
            onClick={leaveClick}
          >
            OK
          </button>
        </div>
      </div>
      <div className="apFilterGroupBlock">
        <span>Дата публікації з</span>
        <div className="apFilterButtonsBlock">
          <input
            id="publishedFrom"
            accessKey="publishedFrom"
            type="date"
            onChange={dateChange}
          />
        </div>
      </div>
      <div className="apFilterGroupBlock">
        <span>Дата по</span>
        <div className="apFilterButtonsBlock">
          <input
            id="publishedTo"
            accessKey="publishedTo"
            type="date"
            onChange={dateChange}
          />
        </div>
      </div>
    </div>
  );
};

export default Filter;
