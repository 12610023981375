import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router";
import {
  addParkMessages,
  deleteParkMessages,
  getParksMessages,
} from "../../../redux/actions/parkMessages";
import Loader from "../../../components/Loader/Loader";
import { uploadFile } from "../../../redux/actions/file";
import InfoWindow from "../../../components/InfoWindow/InfoWindow";
import getDate from "../../../helpers/getDate";
import MessageItem from "../../../components/MessageItem/MessageItem";

const MessagesPage = () => {
  const dispatch = useDispatch();
  const { listId } = useSelector((state) => state);
  const { fullList } = useSelector((state) => state);
  const [ready, setReady] = useState();
  const { parksMessages } = useSelector((state) => state);
  const [form, setForm] = useState({});
  const currentId = useParams().id;
  const navigate = useNavigate();
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteElement, setDeleteElement] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [alertModal, setAlertModal] = useState(false);

  const changeHandler = (e) => {
    setForm({ ...form, [e.target.id]: e.target.value });
  };
  const getFileContext = async (e) => {
    let arr = [
      ...fullList.FileTypes[0].title.split(" "),
      ...fullList.FileTypes[1].title.split(" "),
    ];
    const _file = e.target.files[0];
    if (!_file) {
      return;
    }
    if (_file.size > 10000000) {
      setAlertMessage("Не більше 10МБ");
      setAlertModal(true);
      return;
    }

    if (
      arr.find((a) =>
        a.includes(
          _file.name.split(".")[_file.name.split(".").length - 1].toLowerCase()
        )
      )
    ) {
      let formData = new FormData();
      formData.append("fileName", _file.name);
      formData.append("size", _file.size);
      formData.append("chunkIndex", 1);
      formData.append("totalChunk", 1);
      formData.append("file", _file);
      formData.append("uploadType", "sportsground_message_file");
      formData.append("uploadId", _file.name + _file.size);

      let response = await dispatch(uploadFile(formData));
      const data = response.data;

      if (response.status === 201) {
        let res = {};
        res.file = data.url;
        res.text = _file.name;
        res.id = currentId;
        sendMessage(res);
        setForm({ ...res });
      }
    } else {
      setAlertMessage("Не вірний формат файлу");
      setAlertModal(true);
      return;
    }
  };

  const getList = () => {
    setReady(false);
    dispatch(getParksMessages({ id: currentId })).then((res) => {
      setForm({ ...res });
      setReady(true);
    });
  };
  const sendMessage = (dat) => {
    let data = {};
    if (dat && dat.text) {
      data = { ...dat };
    } else {
      data.id = currentId;
      data.text = form.text;
      if (form.file) {
        data.file = form.file;
      }
    }
    dispatch(addParkMessages(data)).then((res) => {
      let data = { ...form };
      data.items.push(res);
      data.text = "";
      setForm({ ...data });
    });
  };
  const msgDeleteClick = (e) => {
    setDeleteModal(true);
    setDeleteElement(e.target.id);
  };
  const msgDeleteSubmit = (e) => {
    let data = {};
    data.id = currentId;
    data.messageId = deleteElement;
    dispatch(deleteParkMessages(data)).then((res) => {
      setDeleteElement("");
      setDeleteModal(false);

      getList();
    });
  };

  useEffect(() => {
    if (!ready) {
      getList();
    }
  }, []);

  if (!ready || !form || !form.items || !listId.list) {
    return (
      <div style={{ position: "relative" }}>
        {" "}
        <div style={{ position: "absolute", top: "50%", left: "50%" }}>
          <Loader />
        </div>{" "}
      </div>
    );
  }

  if (ready && form && form.items && listId.list) {
    return (
      <>
        {alertModal && (
          <InfoWindow
            setModal={setAlertModal}
            message={alertMessage}
          ></InfoWindow>
        )}
        {deleteModal && (
          <InfoWindow
            setClick={msgDeleteSubmit}
            setModal={setDeleteModal}
            buttonMsg={"Видалити"}
            message={"Ви впевнені, що хочете видалити?"}
          ></InfoWindow>
        )}
        <div className="messagesHeaderWithSearch">
          <h2>Повідомлення</h2>
          <div className="messagesHeaderSearch">
            {/* <button className="secondaryButtonIconDefault">
          <svg><use href="/images/icon24Search.svg#icon24" /></svg>
        </button> */}
          </div>
        </div>
        {parksMessages.total === 0 && (
          <div className="messagesEmpty">
            <svg>
              <use href="/images/icon24Info.svg#icon24" />
            </svg>
            Повідомлень ще немає
          </div>
        )}
        {form &&
          form.items.map((el, index) => {
            return (
              <React.Fragment key={index}>
                {(index == 0 ||
                  el.updatedAt.split(" ")[0] !==
                    form.items[index - 1].updatedAt.split(" ")[0]) && (
                  <div className="messagesRow">
                    <div className="messagesRowUserPhotoCell"></div>
                    <div className="messagesRowContentCell">
                      <div className="messagesRowDate">
                      {getDate(el.updatedAt,'dd/mm/yyyy','.')}
                      </div>
                    </div>
                  </div>
                )}
                <div className="messagesRow">
                  <div className="messagesRowUserPhotoCell">
                    {el.sender.photo ? (
                      <div
                        style={{
                          backgroundImage: `URL(${el.sender.photo})`,
                        }}
                        className="userValueImage"
                      ></div>
                    ) : (
                      <div className="userValueImage">
                        {el.sender.lastName
                          ? el.sender.lastName[0] + el.sender.firstName[0]
                          : el.sender && el.sender.nickname[0]}
                      </div>
                    )}
                  </div>
                  <div className="messagesRowContentCell">
                    {!el.file ? (
                      <div className="messagesRowTextCell">
                        <div className="messagesRowTextCellUserName">
                          <h4 onClick={()=>{navigate(`/personalPage/${el.sender.id}`)}}>
                            {el.sender.lastName && el.sender.firstName
                              ? el.sender.firstName  + " " + el.sender.lastName
                              : el.sender && el.sender.nickname}
                          </h4>
                          <span>{listId.list[el.sender.roleId]}</span>
                        </div>
                        <div className="messagesRowTextCellMessage">
                          <MessageItem text={el.text}></MessageItem>
                        </div>
                        <div className="messagesRowTextCellOrigin">
                          <span></span>
                          <span>
                            {el.updatedAt.split(" ")[1].split(":")[0] +
                              ":" +
                              el.updatedAt.split(" ")[1].split(":")[1]}
                          </span>
                        </div>
                      </div>
                    ) : (
                      <div className="messagesRowTextCell apCurrentUserMessage">
                        <div className="messagesRowTextCellFile">
                          <a href={el.file}>
                            <div>
                              <svg>
                                <use href="/images/icon24File.svg#icon24"></use>
                              </svg>
                            </div>
                          </a>
                          <div>
                            <h4>
                              <a href={el.file}>{el.text}</a>
                            </h4>
                            {/* <span>1.2 MB</span> */}
                          </div>
                        </div>
                        <div className="messagesRowTextCellOrigin">
                          <span onClick={msgDeleteClick} id={el.id}>
                            видалити
                          </span>
                          <span>
                            {el.updatedAt.split(" ")[1].split(":")[0] +
                              ":" +
                              el.updatedAt.split(" ")[1].split(":")[1]}
                          </span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </React.Fragment>
            );
          })}

        <div className="messagesNewMessage">
          <div>
            <button className="secondaryButtonIconDefault apInputTypeFile">
              <input
                type="file"
                accept=".doc, .docx, .xls, .xlsx, .rtf, .pdf, .txt, .jpg, .jpeg, .gif, .png, .tiff"
                id="file"
                onChange={getFileContext}
                style={{ display: "none" }}
              />
              <label id="dat" htmlFor="file" onDrop={getFileContext}>
                <svg>
                  <use href="/images/icon24Attach.svg#icon24" />
                </svg>
              </label>
            </button>
          </div>
          <div>
            <div className="apInputText">
              <input
                placeholder="Напишіть повідолення"
                type="text"
                id="text"
                name="text"
                value={form.text}
                onChange={changeHandler}
              />
            </div>
          </div>
          <div>
            <button
              className={
                form.text
                  ? "primaryButtonIconTextDefault"
                  : "primaryButtonIconTextDisabled"
              }
              disabled={!form.text}
              onClick={sendMessage}
            >
              <svg>
                <use href="/images/icon24Send.svg#icon24" />
              </svg>
              Відправити
            </button>
          </div>
        </div>
      </>
    );
  }
};

export default MessagesPage;
