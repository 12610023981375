import React from 'react';
import {Navigate, Route,Routes, Redirect} from 'react-router-dom';
import LoginPage from './pages/LoginPage/LoginPage';
import RegistrationPage from './pages/RegistrationPage/RegistrationPage';
import RestorePage from './pages/RestorePage/RestorePage';
import Page404 from './pages/Page404/Page404';
import Map from './pages/Map/Map';
import ParkItemPage from './pages/ParkItemPage/ParkItemPage';
import MainPage from './pages/MainPage/MainPage';
import MapPage from './pages/MapPage/MapPage';
import DirectoryPage from './pages/DirectoryPage/DirectoryPage';
import ParkListPage from './pages/ParkListPage/ParkListPage';
import VideoPage from './pages/VideoPage/VideoPage';
import UsersPage from './pages/UsersPage/UsersPage';
import UsersPageReport from './pages/UsersPageReport/UsersPageReport';
import VideoCatalogPage from './pages/VideoCatalogPage/VideoCatalogPage';
import AddCoordinatorPage from './pages/AddCoordinatorPage/AddCoordinatorPage';
import VideoItemPage from './pages/VideoItemPage/VideoItemPage';
import { useSelector } from 'react-redux';
import Loader from './components/Loader/Loader';
import LogPage from './pages/LogPage/LogPage';
import SportEventsListPage from './pages/SportEventsListPage/SportEventsListPage';
import SportEventsItemPage from './pages/SportEventsItemPage/SportEventsItemPage';
import SportEventsCalendarPage from './pages/SportEventsCalendarPage/SportEventsCalendarPage';
import VideoUserCatalogPage from './pages/VideoUserCatalogPage/VideoUserCatalogPage';
import VideoUserItemPage from './pages/VideoUserItemPage/VideoUserItemPage';
import MapPageUser from './pages/MapPageUser/MapPageUser';
import NewsPage from './pages/NewsPage/NewsPage';
import ParkInfoPage from './pages/ParkInfoPage/ParkInfoPage';
import NewsListPage from './pages/NewsListPage/NewsListPage';
import StartPage from './pages/StartPage/StartPage';
import InfoPage from './pages/InfoPage/InfoPage';
import InfoListPage from './pages/InfoListPage/InfoListPage';
import InfoUserPage from './pages/InfoUserPage/InfoUserPage';
import VideoUserPage from './pages/VideoUserPage/VideoUserPage';
import NewsUserListPage from './pages/NewsUserListPage/NewsUserListPage';
import NewsUserPage from './pages/NewsUserPage/NewsUserPage';
import SportEventsUserListPage from './pages/SportEventsUserListPage/SportEventsUserListPage';
import SportEventUserCardItemPage from './pages/SportEventUserCardItemPage/SportEventUserCardItemPage';
import InfoListUserPage from './pages/InfoListUserPage/InfoListUserPage';
import SupportListPage from './pages/SupportListPage/SupportListPage';
import SupportItemPage from './pages/SupportItemPage/SupportItemPage';
import SupportItemUserPage from './pages/SupportItemUserPage/SupportItemUserPage';
import FCInnerPage from './pages/FCInnerPage/FCInnerPage';
import { UserFc } from './pages/UserFC/UserFc';
import FCEditPage from './pages/FCEditPage/FCEditPage';
import { FcEvents } from './pages/FCEvents/FCEvents';
import NotificationPage from './pages/NotificationPage/NotificationPage';
import FCNewsEditPage from './pages/FCNewsEditPage/FCNewsEditPage';
import ProfilePageEdit from './pages/ProfilePageEdit/ProfilePageEdit';
import ProfilePage from './pages/ProfilePage/ProfilePage';
import FCNEwsPage from './pages/FCNewsPage/FCNewsPage';
import UsersCoordinatorApplyPage from './pages/UserCoordinatorApply/UserCoordinatorApply';
import ReportPage from './pages/ReportPage/ReportPage';
import QRreaderPageNew from './pages/QRreaderPage/QRreaderPageNew';
import TestPage from './pages/TestPage/TestPage';
import PageNonAuthorized from './pages/PageNonAuthorized/PageNonAuthorized';
import UsersPageReportCurrent from './pages/UsersPageReportCurrent/UsersPageReportCurrent';
import UsersTrainersApplyPage from './pages/UsersTrainersApplyPage/UsersTrainersApplyPage';
import TrackLibraryPage from './pages/TrackLibraryPage/TrackLibraryPage';
import TrackItemPage from './pages/TrackItemPage/TrackItemPage';
import RouteLibraryPage from './pages/RouteLibraryPage/RouteLibraryPage';
import RouteItemPage from './pages/RouteItemPage/RouteItemPage';
import RegistrationNewPage from './pages/RegistrationNewPage/RegistrationNewPage';
import RegistrationNewPage2 from './pages/RegistrationNewPage2/RegistrationNewPage2';
import UsersPageReportUser from './pages/UsersPageReportUser/UsersPageReportUser';
import ActivityItem from './pages/ActivityItem/ActivityItem';
import RouteItemInfo from './pages/RouteItemInfo/RouteItemInfo';
import LoginPageDia from './pages/LoginPageDia/LoginPageDia';


const UseRoutes = (props) => {
    const auth = useSelector(state=>state)
    const {currentUser}= useSelector(state=>state.currentUser)

    if(!currentUser && auth.isLoggedIn && !currentUser.roleId){
    return(
        <div style={{'position':'relative'}}>  <div style={{'position':'absolute','top':'50%','left':'50%'}}>
        <Loader/>
    </div> </div>
        )
    }

    

    if((currentUser && auth.isLoggedIn && currentUser.roleId)|| !auth.isLoggedIn){
    return(
        <Routes>
            {!auth.isLoggedIn && <Route path='/login'  element={<LoginPage/>}/>}
            {!auth.isLoggedIn && <Route path='/loginTest'  element={<LoginPageDia/>}/>}
            {/* {!auth.isLoggedIn &&<Route path='/registration'  element={<RegistrationPage/>}/>} */}
            {!auth.isLoggedIn &&<Route path='/registration'  element={<RegistrationNewPage/>}/>}
            {<Route path='/registration2/:id'  element={<RegistrationNewPage2/>}/>}
            {!auth.isLoggedIn &&<Route path='/restore'  element={<RestorePage/>}/>}



            {currentUser &&(currentUser.roleId==="62fe0318-c64a-490c-859d-9d313eacbf41" || currentUser.roleId=== "460553c9-3c42-443c-afb3-24308587fe62"||currentUser.roleId==="66d22cb4-2cf9-4aa7-806b-c50b50f27020" ||currentUser.roleId==="09efbeb2-f45a-418d-89b0-b2a4c37f6122" ||currentUser.roleId==="631db81f-fa07-42e2-b394-062c9be66b09")&&<Route path='/map/:id'  element={<Map/>}/>}
            {currentUser &&(currentUser.roleId==="62fe0318-c64a-490c-859d-9d313eacbf41" || currentUser.roleId=== "460553c9-3c42-443c-afb3-24308587fe62"||currentUser.roleId==="66d22cb4-2cf9-4aa7-806b-c50b50f27020" ||currentUser.roleId==="09efbeb2-f45a-418d-89b0-b2a4c37f6122" ||currentUser.roleId==="631db81f-fa07-42e2-b394-062c9be66b09")&&<Route path='/parkmappage'  element={<MapPage/>}/>}
            {currentUser &&(currentUser.roleId==="62fe0318-c64a-490c-859d-9d313eacbf41" || currentUser.roleId=== "460553c9-3c42-443c-afb3-24308587fe62"||currentUser.roleId==="66d22cb4-2cf9-4aa7-806b-c50b50f27020" ||currentUser.roleId==="09efbeb2-f45a-418d-89b0-b2a4c37f6122" ||currentUser.roleId==="631db81f-fa07-42e2-b394-062c9be66b09")&&<Route path='/parklistpage'  element={<ParkListPage/>}/>}
            {currentUser &&(currentUser.roleId==="62fe0318-c64a-490c-859d-9d313eacbf41" || currentUser.roleId=== "460553c9-3c42-443c-afb3-24308587fe62"||currentUser.roleId==="66d22cb4-2cf9-4aa7-806b-c50b50f27020" ||currentUser.roleId==="09efbeb2-f45a-418d-89b0-b2a4c37f6122" ||currentUser.roleId==="631db81f-fa07-42e2-b394-062c9be66b09")&&<Route path='/sportsground/:id/:type'  element={<ParkItemPage/>}/>}
            {currentUser &&(currentUser.roleId==="62fe0318-c64a-490c-859d-9d313eacbf41" || currentUser.roleId=== "460553c9-3c42-443c-afb3-24308587fe62"||currentUser.roleId==="66d22cb4-2cf9-4aa7-806b-c50b50f27020" ||currentUser.roleId==="09efbeb2-f45a-418d-89b0-b2a4c37f6122" ||currentUser.roleId==="631db81f-fa07-42e2-b394-062c9be66b09")&&<Route path='/sportsground/:id'  element={<ParkItemPage/>}/>}
            {currentUser &&(currentUser.roleId==="62fe0318-c64a-490c-859d-9d313eacbf41" || currentUser.roleId=== "460553c9-3c42-443c-afb3-24308587fe62"||currentUser.roleId==="66d22cb4-2cf9-4aa7-806b-c50b50f27020" ||currentUser.roleId==="09efbeb2-f45a-418d-89b0-b2a4c37f6122" ||currentUser.roleId==="631db81f-fa07-42e2-b394-062c9be66b09")&&<Route path='/addCoordinator/:id' element={<AddCoordinatorPage/>}/>}
            
            {currentUser &&(currentUser.roleId==="631db81f-fa07-42e2-b394-062c9be66b09")&&<Route path='/users' element={<UsersPage/>}/>}
            {currentUser &&(currentUser.roleId==="631db81f-fa07-42e2-b394-062c9be66b09")&&<Route path='/usersAppend' element={<UsersCoordinatorApplyPage/>}/>}
            {currentUser &&(currentUser.roleId==="631db81f-fa07-42e2-b394-062c9be66b09")&&<Route path='/usersTrainerAppend' element={<UsersTrainersApplyPage/>}/>}
            {/* {currentUser &&(currentUser.roleId==="631db81f-fa07-42e2-b394-062c9be66b09")&&<Route path='/usersReportsList' element={<UsersPageReport/>}/>} */}
            {<Route path='/usersReportsList' element={<UsersPageReport/>}/>}
            {<Route path='/reportsList' element={<UsersPageReportUser/>}/>}
            {<Route path='/usersReportsList/:id' element={<UsersPageReportCurrent/>}/>}
           
            {currentUser && (currentUser.roleId==="e9789dde-94a9-4ad3-88e8-374ec41f8c1a" ||currentUser.roleId==="2f5a84bc-52b5-4777-8790-e2c32daa6a34" ||currentUser.roleId==="3fbea050-7049-4682-92f6-15c2bad91972" ||currentUser.roleId==="631db81f-fa07-42e2-b394-062c9be66b09")&&<Route path='/videoItem/:id/:type'  element={<VideoItemPage/>}/>}
            {currentUser && (currentUser.roleId==="e9789dde-94a9-4ad3-88e8-374ec41f8c1a" ||currentUser.roleId==="2f5a84bc-52b5-4777-8790-e2c32daa6a34" ||currentUser.roleId==="3fbea050-7049-4682-92f6-15c2bad91972" ||currentUser.roleId==="631db81f-fa07-42e2-b394-062c9be66b09")&&<Route path='/videocatalog'  element={<VideoCatalogPage/>}/>}
            {currentUser && <Route path='/personalpageedit/:id'  element={<ProfilePageEdit/>}/>}
            <Route path='/personalPage/:id'  element={<ProfilePage/>}/>
            <Route path='/personalPage/:id/:type'  element={<ProfilePage/>}/>
            <Route path='/personalPage'  element={<ProfilePage/>}/>

            {currentUser &&currentUser.roleId==="631db81f-fa07-42e2-b394-062c9be66b09"&& <Route path='/directory'  element={<DirectoryPage/>}/>}
            {currentUser &&(currentUser.roleId==="631db81f-fa07-42e2-b394-062c9be66b09"|| currentUser.roleId==="09efbeb2-f45a-418d-89b0-b2a4c37f6122" || currentUser.roleId==='5dcf0363-d171-45db-9280-cb337ca5e101')&& <Route path='/events'  element={<SportEventsListPage/>}/>}
            {currentUser &&(currentUser.roleId==="631db81f-fa07-42e2-b394-062c9be66b09"|| currentUser.roleId==="09efbeb2-f45a-418d-89b0-b2a4c37f6122" || currentUser.roleId==='5dcf0363-d171-45db-9280-cb337ca5e101')&& <Route path='/eventsItem/:id'  element={<SportEventsItemPage/>}/>}
            {currentUser &&currentUser.roleId==="631db81f-fa07-42e2-b394-062c9be66b09"&& <Route path='/log'  element={<LogPage/>}/>}



            {currentUser    &&(currentUser.roleId==="a2c99acd-0014-4fb3-8274-ad6a842f50ac" || currentUser.roleId==="631db81f-fa07-42e2-b394-062c9be66b09"|| currentUser.roleId==="e9789dde-94a9-4ad3-88e8-374ec41f8c1a" || currentUser.roleId==="09efbeb2-f45a-418d-89b0-b2a4c37f6122" || currentUser.roleId==="62fe0318-c64a-490c-859d-9d313eacbf41")&& <Route path='/videoUserCatalog'  element={<VideoUserCatalogPage/>}/>}
            {currentUser     &&(currentUser.roleId==="a2c99acd-0014-4fb3-8274-ad6a842f50ac" || currentUser.roleId==="631db81f-fa07-42e2-b394-062c9be66b09"|| currentUser.roleId==="e9789dde-94a9-4ad3-88e8-374ec41f8c1a" || currentUser.roleId==="09efbeb2-f45a-418d-89b0-b2a4c37f6122" || currentUser.roleId==="62fe0318-c64a-490c-859d-9d313eacbf41")&& <Route path='/videoUserItem/:id/:type'  element={<VideoUserItemPage/>}/>}


            {currentUser &&(currentUser.roleId==="631db81f-fa07-42e2-b394-062c9be66b09" || currentUser.roleId==="9952ac5a-fd43-4e8f-86ff-2bf9cb2a7804")&&<Route path='/newsedit/:id' element={<NewsPage/>}/>}
            {currentUser &&(currentUser.roleId==="631db81f-fa07-42e2-b394-062c9be66b09" || currentUser.roleId==="9952ac5a-fd43-4e8f-86ff-2bf9cb2a7804")&&<Route path='/news' element={<NewsListPage/>}/>}

           {currentUser &&(currentUser.roleId==="631db81f-fa07-42e2-b394-062c9be66b09" || currentUser.roleId==="9952ac5a-fd43-4e8f-86ff-2bf9cb2a7804")&& <Route path='/infoedit/:id' element={<InfoPage/>}/>}
           {currentUser &&(currentUser.roleId==="631db81f-fa07-42e2-b394-062c9be66b09" || currentUser.roleId==="9952ac5a-fd43-4e8f-86ff-2bf9cb2a7804")&& <Route path='/infoelistedit' element={<InfoListPage/>}/>}

           {currentUser &&<Route path='/supportlist' element={<SupportListPage/>}/>}
            {currentUser &&<Route path='/supportedit/:id' element={<SupportItemPage/>}/>}
            {currentUser &&<Route path='/supportuseredit/:id' element={<SupportItemUserPage/>}/>}

            {currentUser &&  currentUser.roleId==="09efbeb2-f45a-418d-89b0-b2a4c37f6122" &&<Route path='/reports' element={<ReportPage/>}/>}

            <Route path='/tracklibrary' element={<TrackLibraryPage/>}/>
            <Route path='/trackitem/:id' element={<TrackItemPage/>}/>

            <Route path='/routelibrary' element={<RouteLibraryPage/>}/>
            <Route path='/routeitem/:id' element={<RouteItemPage/>}/>



            <Route path='/page404' element={<Page404/>}/>
            <Route path='/pagenonauthorized' element={<PageNonAuthorized/>}/>
            <Route path='/eventsCalendar' element={<SportEventsCalendarPage/>}/>
            <Route path='/eventslist' element={<SportEventsUserListPage/>}/>
            <Route path='/sportsevent/:id' element={<SportEventUserCardItemPage/>}/>
            
            
            <Route path='/map' element={<MapPageUser/>}/>
            <Route path='/mainpage/:qr/:idregion/:idcategory' element={<MainPage/>}/>
            <Route path='/mainpage/:qr/:idregion/:idgromady/:idcategory' element={<MainPage/>}/>
            <Route path='/mainpage' element={<MainPage/>}/>
            <Route path='/mainpage/:type' element={<MainPage/>}/>
            <Route path='/info' element={<MainPage/>}/>
            <Route path='/qr/:idregion/:idcategory' element={<MainPage/>}/>
            <Route path='/qr/:idregion/:idgromady/:idcategory' element={<MainPage/>}/>
            <Route path="/" element={<Navigate to="/start" />} />
            <Route path='/page404' element={<Page404/>}/>
            <Route path="*" element={<Page404/>}/>

            <Route path='/newspg/:id' element={<NewsUserPage/>}/>
            <Route path='/newspage' element={<NewsUserListPage/>}/>


            <Route path='/info/:id' element={<InfoUserPage/>}/>
            <Route path='/infolist' element={<InfoListUserPage/>}/>
            <Route path='/infolist/:id' element={<InfoListUserPage/>}/>

            <Route path='/start' element={<StartPage/>}/>
            <Route path='/parkInfo/:id' element={<ParkInfoPage/>}/>
            <Route path='/routeInfo/:id' element={<RouteItemInfo/>}/>
            <Route path='/activity/:id' element={<ActivityItem/>}/>

            <Route path="/video/:idregion/:idcategory/:iddifficulty/:idsubcategory/:idvideo"  element={<VideoPage/>}/>
            <Route path="/video/:idregion/:idcategory/:iddifficulty/:idsubcategory/:idvideo/:tro"  element={<VideoPage/>}/>
            <Route path="/videouser/:idregion/:idcategory/:iddifficulty/:idsubcategory/:idvideo"  element={<VideoUserPage/>}/>

           <Route path="/fc/:id/:contentId" element={<FCInnerPage/>}/>
           {currentUser && <Route path="/fcEdit/:id" element={<FCEditPage/>}/>}
           {<Route path="/fc/:id" element={<FCInnerPage/>}/>}
            {currentUser &&<Route path="/fcEdit/:id/news/:newsId" element={<FCNewsEditPage/>}/>}
            {<Route path="/fc/:id/news/:newsId" element={<FCNEwsPage/>}/>}

            {currentUser&&<Route path="/fcList" element={<UserFc/>}/>}

            <Route path='/fc-events/:clubid/:id' element={<FcEvents/>}/>
            <Route path='/fc-events/:clubid/:id/:contentId' element={<FcEvents/>}/>
            {/* <Route path='/fc-events' element={<FcEvents/>}/> */}
            {currentUser &&(currentUser.roleId==="631db81f-fa07-42e2-b394-062c9be66b09"|| currentUser.roleId==="09efbeb2-f45a-418d-89b0-b2a4c37f6122" || currentUser.roleId==='5dcf0363-d171-45db-9280-cb337ca5e101')&& <Route path='/eventsItem/:clubId/:id'  element={<SportEventsItemPage/>}/>}

            {currentUser &&<Route path="/notifications" element={<NotificationPage/>}/>}
            <Route path="/qr/club/:id" element={<QRreaderPageNew/>}/>
            <Route path="/qr/event/:id" element={<QRreaderPageNew/>}/>
            <Route path="/qr/route-point/:id" element={<QRreaderPageNew/>}/>

            <Route path="/test" element={<TestPage/>}/>
            <Route path="/page-for-appeals" element={
            <RedirectWindow url="https://sportforall.gov.ua/informatsiini-resursy" />
            } />
        </Routes>
    
    )}
}



function RedirectWindow({ url }) {
    window.location.href = url;
    return null; // Возвращаем null, так как этот компонент не отображает ничего
  }


export default UseRoutes;