import { FcTile } from "../../../components/Tile/FcTile";

export const Clubs = ({ data }) => {
  const getStatus = (status) => {
    if (status?.isActing) return { id: "moderator", text: "Ви модератор" };
    if (status?.isCoordinator) return { id: "coodrdinator", text: "Ви координатор" };
    if (status?.isApproved) return { id: "user", text: "Ви учасник" };
    return { id: "user", text: "Ви учасник" };
  };

  if (!data || data.length === 0){

    return <div className="apEmptyCategory">Ви ще не вступили в жоден клуб</div>;
  }else{
  return data.map(
    (
      {
        id,
        logoUrl,
        name,
        slogan,
        location,
        address,
        place,
        memberAmount,
        soonestEvent,
        phone,
        clubUser,
      },
      index
    ) =>{
      
    return(
      <>
      <FcTile
        key={index}
        id={id}
        logo={logoUrl}
        title={name}
        slogan={slogan}
        location={location}
        address={address}
        place={place}
        members={memberAmount}
        eventDate={soonestEvent}
        contact={phone}
        status={getStatus(clubUser)}
      />
      </>
    )}
  );
}
};
