import {
    SETCOORDINATES_SUCCESS,
    SETCOORDINATES_FAIL,
} from "../actions/types";


const initialState={}

export default function coordinatesReducer(coordinates = initialState,action){
    const {data,type,typeOfData} = action;

    switch(type) {
        case SETCOORDINATES_SUCCESS:
            return {...data,typeOfData:typeOfData};
        case SETCOORDINATES_FAIL:
            return coordinates;
        default:
            return coordinates;
    }

}