import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { getCode, verifyMail,updateUser } from '../../../redux/actions/users';


const EmailModal =(props)=>{
    const currentId = useParams().id;
    const dispatch=useDispatch();
    const {currentUser} = useSelector(state=>state.currentUser);
    const [form,setForm]=useState({});
    const [error,setError]=useState('');
    const [ready,setReady] = useState(false);
    const [seconds, setSeconds] = useState(0);

    const closeClick=()=>{
        props.setModal(false);
    }
    const changeHandler=(e)=>{
        const codeCheck=/^[0-9]{6}$/
        const emailCheck = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        switch(e.target.id) {
            case 'email':
                if( emailCheck.test(String(e.target.value).toLowerCase())){
                    setError('')
                }else{
                    setError('Некоректна пошта')
                };
                setForm({...form, [e.target.id]: e.target.value})
                break;
            case 'code':
                if(codeCheck.test(String(e.target.value)) ){
                    setError('')
                }else{
                    setError('Некоректний код')
                };
                setForm({...form, [e.target.id]: e.target.value})
                break;
            default:
                break;
            }
       
    }
    const receiveCode=()=>{
        setSeconds(60)
        dispatch(getCode(form)).then(res=>{
            if(res.errors){
                setError(res.errors[0].msg)
            }
            else{
                setReady(true)
            }
           
        })
    }
    const saveChange=()=>{
        if(currentId){
            form.id=currentId
        }else{
            form.id=currentUser.id
        }
        dispatch(verifyMail(form)).then(res=>{
            if(res?.errors && res.errors[0] && res.errors[0].msg){
                setError(res.errors[0].msg)
            }
            else{
                props.setAlertMessage('Пошту змінено');
                props.setAlertModal(true);
                setError('')
                closeClick()
            }
        })
    }
    const updateDate = ()=>{
        if(form.email){

          let data ={...props.form}
          data.email=form.email

        if(currentId){
            data.id=currentId
        }else{
            data.id=currentUser.id
        }


          dispatch(updateUser(data)).then(res=>{
            if(res.errors){
                setError(res.errors[0].msg)
            }
            else{
                if(currentUser && currentUser.roleId ==="631db81f-fa07-42e2-b394-062c9be66b09"){
                    props.initialFunction();
                    closeClick();
                  }
            }
            
          })
        }
    }
    
    useEffect(() => {
        if (seconds > 0) {
          setTimeout(() => setSeconds(seconds - 1), 1000);
        }
      }, [seconds]);

return(
    <div className="apLoginFormContainer">
        <svg onClick={closeClick}><use href="/images/icon24Cancel.svg#icon24" /></svg>
        <h3>Зміна пошти</h3>
        <div className="apInputText">
            <label htmlFor="email">Нова пошта*</label>
            <input id="email" type="text" onChange={changeHandler} value={form.email} />
        </div>
        {error && <span>{error}</span>}

        {currentUser && currentUser.roleId !=="631db81f-fa07-42e2-b394-062c9be66b09" &&<><div className="apLoginFormFiledWithButton">
            <div className="apInputText">
            <label htmlFor="code">Код з пошти</label>
            <input id="code" type="text"onChange={changeHandler} value={form.code} />
            </div>
            <button disabled={seconds>0} className="secondaryButtonTextDefault" onClick={receiveCode}>
            Отримати код
            </button>
        </div>
        {ready && !error&&<span> Очікуйте на код </span>}
        {seconds>0 &&<span>Отримати код повторно через {seconds}</span>}

        <button disabled={!ready || error} className={ready?"secondaryButtonTextDefault":"primaryButtonTextDisabled"} onClick={saveChange}>
            Зберегти зміни
        </button></>}
        {currentUser && currentUser.roleId ==="631db81f-fa07-42e2-b394-062c9be66b09" &&<button disabled={!form.email || (form.email && form.email.length<3)||error} className={ready?"secondaryButtonTextDefault":"primaryButtonTextDisabled"} onClick={updateDate}>
            Зберегти зміни
        </button>}
    </div>

)
}




export default EmailModal