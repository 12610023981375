import {
    SETCOORDINATESMY_SUCCESS,
    SETCOORDINATESMY_FAIL,
} from "../actions/types";


const initialState={}

export default function coordinatesMyReducer(coordinates = initialState,action){
    const {data,type,typeOfData} = action;

    switch(type) {
        case SETCOORDINATESMY_SUCCESS:
            return {...data,typeOfData:typeOfData};
        case SETCOORDINATESMY_FAIL:
            return coordinates;
        default:
            return coordinates;
    }

}