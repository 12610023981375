import {
    EDITREPORTS_SUCCESS,
    GETREPORTS_SUCCESS,
    GETREPORTS_FAIL,
    EDITREPORTS_FAIL
} from "./types";

import ReportService from "../services/reports.service"



export const getReports = (data)=> async(dispatch)=>{
        
    try{
        const res = await ReportService.getReports(data);
        
        dispatch({
          type: GETREPORTS_SUCCESS,
          list: res,
        });
        
        return res
    }catch (error){
        
        dispatch({
            type: GETREPORTS_FAIL,
            list: {error: error.response},
          });
          const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
          return error.response
    }
}

export const getReportsUser = (data)=> async(dispatch)=>{
        
    try{
        const res = await ReportService.getReportsUser(data);
        
        dispatch({
          type: GETREPORTS_SUCCESS,
          list: res,
        });
        
        return res
    }catch (error){
        
        dispatch({
            type: GETREPORTS_FAIL,
            list: {error: error.response},
          });
          const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
          return error.response
    }
}


export const getReportsList = (data)=> async(dispatch)=>{
        
    try{
        const res = await ReportService.getReportsList(data);
        
        dispatch({
          type: GETREPORTS_SUCCESS,
          list: res,
        });
        return res
    }catch (error){
        dispatch({
            type: GETREPORTS_FAIL,
            list: {error: error.response},
          });
          const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
          return error.response
    }
}

export const getReportsUserList = (data)=> async(dispatch)=>{
        
    try{
        const res = await ReportService.getReportsUserList(data);
        
        dispatch({
          type: GETREPORTS_SUCCESS,
          list: res,
        });
        return res
    }catch (error){
        dispatch({
            type: GETREPORTS_FAIL,
            list: {error: error.response},
          });
          const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
          return error.response
    }
}



export const editReports= (form)=>(dispatch)=>{
      
    return ReportService.editReport(form).then(
        (response)=>{
              
            dispatch({
                type:EDITREPORTS_SUCCESS,
                item:response
            });
            
            return response

        },
        (error)=>{
            dispatch({
                type: EDITREPORTS_FAIL,
              });
            console.log(error);
              return Promise.reject();
        }
    );
}



export const getPdfReports = (data)=> async(dispatch)=>{
    try{
        const res = await ReportService.pdfReport(data);
        return res
    }catch (error){
        dispatch({
            type: GETREPORTS_FAIL,
            list: {error: error.response},
          });
          const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
          return error.response
    }
}