import React from "react";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import Calendar from "../../../components/Calendar";
import EventsList from "../../../components/EventsList";
import getDate from "../../../helpers/getDate";
import { getAllCallendarEvents, getAllEvents, getDayCallendarEvents, getEvents } from "../../../redux/actions/events";

export const Events = () => {
  const [ready, setReady] = useState(false);
  const [_, setDate] = useState(null);
  const dispatch = useDispatch();
  const [form, setForm] = useState({ filter: {}, offset: 0, limit: 10 });
  const currentId = useParams().id;
  const navigate=useNavigate()
  const [draftForm, setDraftForm] = useState([]);
  const [listForm, setListForm] = useState([]);
  const [calendarForm, setCalendarForm] = useState({});

  

  const handleDateChange = (date) => {
    setDate(date);

    let yourDate = new Date(Date.parse(date));
    const offset = yourDate.getTimezoneOffset();
    yourDate = new Date(yourDate.getTime() - offset * 60 * 1000);
    let specDate = yourDate.toISOString().split("T")[0];

    let arr = { ...form };
    arr.filter["startsFrom"] = [];
    arr.filter["startsTo"] = [];
    arr.filter["startsFrom"].push(specDate);
    arr.filter["startsTo"].push(specDate);
    setForm(arr);
    getEventsList(1, arr.filter);
  };

  const getCalendarEventsList=(filter)=>{
    let data = {};
    let yourDate = new Date();
    let yourDate2 = new Date();

    yourDate.setDate(1);
    yourDate2.setDate(31);
    const offset = yourDate.getTimezoneOffset();
    yourDate = new Date(yourDate.getTime() - offset * 60 * 1000);
    yourDate2=new Date(yourDate2.getTime() - offset * 60 * 1000);
    let specDate = yourDate.toISOString().split("T")[0];
    let specDate2 = yourDate2.toISOString().split("T")[0];

    if (filter) {
      data.filter = { ...filter };
    } else {
        data.filter = { startsFrom: [specDate],startsTo:[specDate2]  };
    }
      data.sort = { startsAt: "asc" };
    if (
      (data.filter && !data.filter.startsFrom) ||
      data.filter.startsFrom.length == 0
    ) {
      data.filter = { startsFrom: [specDate],startsTo:[specDate2] };
    }
    data.filter.isClubEvent = ["1"];
    data.filter.clubId = [currentId];
    data.filter.statusId=['35rt3s0c-7bsa-46c7-a203-c16a4fsc3004'];
    dispatch(getAllCallendarEvents(data)).then((res) => {
      setCalendarForm(res)
      setReady(true);
    });
  }

  const getEventsList = (page = 1, filter) => {
    let data = {};
    let yourDate = new Date();
    const offset = yourDate.getTimezoneOffset();
    yourDate = new Date(yourDate.getTime() - offset * 60 * 1000);
    let specDate = yourDate.toISOString().split("T")[0];

    if (filter) {
      data.filter = { ...filter };
    } else {
        data.filter = { startsFrom: [specDate],startsTo:[specDate] };
    }
      data.sort = { startsAt: "asc" };

    if (
      (data.filter && !data.filter.startsFrom) ||
      data.filter.startsFrom.length == 0
    ) {
      data.filter = { startsFrom: [specDate],startsTo:[specDate] };
    }

    data.filter.clubId = [currentId];

    data.offset = (page - 1) * 10;
    data.limit = 10;

    dispatch(getDayCallendarEvents(data)).then((res) => {
      console.log(res)
      setListForm(res.items)
    });

    let data2={};
    data2.filter={};
    data2.filter.isClubEvent = ["1"];
    data2.filter.clubId = [currentId];
    data2.filter.statusId=['35rt3s0c-7bsa-46c7-a203-c16a4fsc3004']
    data2.offset = (page - 1) * 10;
    data2.limit = 10;
    data2.sort = { createdAt: "desc" };
    dispatch(getEvents(data2)).then((res) => {
      setDraftForm(res.items);
      setReady(true);
    });
    
  };

  useEffect(() => {
    if (!ready) {
      getEventsList();
      getCalendarEventsList();
    }
  }, [ready]);

  return (
    <div className="apFcEvents">
      <h2>Календар заходів</h2>
      <Calendar onChange={handleDateChange} getCalendarEventsList={getCalendarEventsList} events={calendarForm.items} />
      <h2>Перелік заходів</h2>
      <EventsList
        data={listForm}
        fallback={"Жодного запланованого заходу"}
      />

      <div style={{'marginTop':'20px'}} className="pageContentHeaderDivider">
      </div>


      <div className="dictAllRowsContainer">
        {draftForm.length>0&& draftForm.map((el,index)=>{
              return (
                <div key={index} className="catVideosRowNormal">
                  <div className="catVideoValue">
                    <div
                      style={{ backgroundImage: `url("${el.imageUrl}")` }}
                      className="myObjectValueImage"
                    />
                  </div>
                  <div
                    className="catVideoValue"
                    onClick={() => navigate(`/eventsItem/${currentId}/${el.id}`)}
                  >
                    {el.title}
                    <br />
                    {el.shortDescription}
                  </div>
                  <div className="catVideoValue">{el.sportsground?.title}</div>
                  <div className="catVideoValue">
                    {getDate(el.startsAt,'dd/mm/yyyy hh:mm','.')} <br />
                    {getDate(el.finishesAt,'dd/mm/yyyy hh:mm','.')}
                  </div>
                  <div className="catVideoValue">
                    {el.statusId === "032fd5ba-f634-469b-3c30-77a1gh63a918" && (
                      <div className="apPublished">Опубліковано</div>
                    )}
                    {/* {el.statusId ==='f7b77851-1d35-4ab1-9fcd-ef6cd2b88ace'&&<div className="apDeactivated">Деактивовано</div>} */}
                    {el.statusId === "99ca4f35-623c-4e0d-b9ef-f70gs23481f3" && (
                      <div className="apCandidate">Кандидат</div>
                    )}
                    {el.statusId === "35rt3s0c-7bsa-46c7-a203-c16a4fsc3004" && (
                      <div className="apOnRepair">Чернетка</div>
                    )}
                    {el.statusId === "d3db15d3-3207-4e1d-b87d-f2ec25aeba23" && (
                      <div className="apDeactivated">Деактивований</div>
                    )}
                  </div>
                  <div className="catVideoValue">
                    {getDate(el.updatedAt,'dd/mm/yyyy hh:mm','.')}
                    <span style={{ display: "block" }}>Змінено</span>
                  </div>
                </div>
              );
            })}
        </div>

    </div>
  );
};
