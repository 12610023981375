import React from "react";



const SocialNetworks =()=>{



    return (
    // <section style={{margin:'40px 0 0'}}  className="main-section main-clubs">
    //     <div className="headline">
    //         <h2>Ми в соціальних мережах</h2>          
    //     </div>
    <>
    <div className="flexDirect">
        <div className="item1">
            <div className="elfsight-app-cde15812-02a7-461d-8482-f9dca6af73a9" data-elfsight-app-lazy></div>
        </div>
        <div className="item1">
            <div className="elfsight-app-1f557cba-83be-441f-9401-9cb9be99b707" data-elfsight-app-lazy></div>
        </div>
    </div>
    </>
    );
}

export default SocialNetworks