import React from "react";

const MessageItem = (props) => {
  const msg = props.text;

  // Replace URLs with anchor tags
  const htmlWithLinks = msg.replace(
    /(https?:\/\/\S+?)(\s|$)/g,
    '<a href="$1" target="_blank">$1</a>$2'
  );

  // Replace newline characters with <br />
  const htmlWithLineBreaks = htmlWithLinks.replace(/\n/g, "<br/>");

  return <div dangerouslySetInnerHTML={{ __html: htmlWithLineBreaks }} />;
};

export default MessageItem;