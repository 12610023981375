import React, { useState,useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { Icon } from "../../../../components/Icon";
import formatTime from "../../../../helpers/formatTime";
import tempCacl from "../../../../helpers/tempCacl";
import { getStatisticCompare, getStatistic } from "../../../../redux/actions/statistic";
import { getWorkouts } from "../../../../redux/actions/workouts";
import ActiveItem from "./ActiveItem/ActivityItem";
import MotivationBlock from "./MotivationBlick/MotivationBlock";
import MonthSelector from "./Selector/MonthSelector";
import WeekSelector from "./Selector/WeekSelector";
import YearSelector from "./Selector/YearSelector";

const Statistic = () => {
  const [ready, setReady] = useState(false);
  const [form, setForm] = useState({});
  const [statistic, setStatistic] = useState({});
  const [statisticCompare, setStatisticCompare] = useState({});
  
  const [compare, setCompare] = useState(false);
  const [filterActive, setFilterActive] = useState("month");
  const dispatch = useDispatch();

  // Определите текущую неделю
  const getCurrentWeek = () => {
    const currentDate = new Date();
    const currentDay = currentDate.getDay();
    const daysToMonday = currentDay === 0 ? 6 : currentDay - 1;
    const mondayDate = new Date(currentDate);
    mondayDate.setDate(currentDate.getDate() - daysToMonday);
    const sundayDate = new Date(mondayDate);
    sundayDate.setDate(mondayDate.getDate() + 6);
    return `${mondayDate.getFullYear()}-${(mondayDate.getMonth() + 1)
      .toString()
      .padStart(2, "0")}-${mondayDate.getDate().toString().padStart(2, "0")}/${sundayDate.getFullYear()}-${(sundayDate.getMonth() + 1)
      .toString()
      .padStart(2, "0")}-${sundayDate.getDate().toString().padStart(2, "0")}`;
  };
  const getCurrentMonth = () => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth() + 1;
    const firstDayOfMonth = new Date(currentYear, currentMonth - 1, 1);
    const lastDayOfMonth = new Date(currentYear, currentMonth, 0);
    return `${currentYear}-${currentMonth.toString().padStart(2, "0")}-01/${currentYear}-${currentMonth.toString().padStart(2, "0")}-${lastDayOfMonth.getDate().toString().padStart(2, "0")}`;
  };
  const getCurrentYear = () => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    return `${currentYear}-01-01/${currentYear}-12-31`;
  };

  const initFunc = () => {
    let data = {};
    dispatch(getWorkouts(data)).then((res) => {
      setForm(res);
      console.log(res);
    });
    setReady(true);
  };



  const getStatistics = (info) => {
    let data = { filter: [] };
    data.filter["startsFrom"] = [info.split("/")[0]];
    data.filter["startsTo"] = [info.split("/")[1]];
    dispatch(getStatistic(data)).then((res) => {
    if (res.time){
        res.time = formatTime(res.time);
    }
      console.log(res);
      setStatistic(res);
    });
  };

  const getStatisticCom = (info) => {
    let data = { filter: [] };
    data.filter["startsFrom"] = [info.split("/")[0]];
    data.filter["startsTo"] = [info.split("/")[1]];

    dispatch(getStatisticCompare(data)).then((res) => {
        if (res.time){
            res.time = formatTime(res.time);
        }
        console.log(res);
        setStatisticCompare(res); 
    });
  };

  useEffect(() => {
    if (!ready) {
      initFunc();
      // Получите текущую неделю и вызовите getStatistics с этой неделей
    //   let currentDate;
    //   if(filterActive ==='week'){
    //     currentDate = getCurrentWeek();
    //   }else if(filterActive ==='month'){
    //     currentDate = getCurrentMonth();
    //   }else if(filterActive ==='year'){
    //     currentDate = getCurrentYear();
    //   }else{
    //     currentDate = '2015-01-01/2023-12-31';
    //   }
    //   getStatistics(currentDate);
    }
  }, [ready]);

  useEffect(() => {
    let currentDate;
    if(filterActive ==='week'){
        currentDate = getCurrentWeek();
    }else if(filterActive ==='month'){
        currentDate = getCurrentMonth();
    }else if(filterActive ==='year'){
        currentDate = getCurrentYear();
    }else{
        const currentDate2 = new Date();
        const currentYear = currentDate2.getFullYear();
        currentDate = `2015-01-01/${currentYear}-12-31`;
    }
    getStatistics(currentDate);
    if(compare){
        getStatisticCom(currentDate);
    }
  }, [filterActive]);



return(<>
    <div className="statisticPage">
            <MotivationBlock></MotivationBlock>
        <div>
            <h2>
                Огляд
            </h2>
            <div className="filterBlock">
                <div className="filterRow">
                    <div className={filterActive==='week'?"filterRowItem filterRowItemActive":"filterRowItem"} id="week" onClick={()=>{setFilterActive('week');setCompare(false);setStatisticCompare({})}}>Тиждень</div>
                    <div className={filterActive==='month'?"filterRowItem filterRowItemActive":"filterRowItem"} id="month" onClick={()=>{setFilterActive('month');setCompare(false);setStatisticCompare({})}}>Місяць</div>
                    <div className={filterActive==='year'?"filterRowItem filterRowItemActive":"filterRowItem"} id="year" onClick={()=>{setFilterActive('year');setCompare(false);setStatisticCompare({})}}>Рік</div>
                    <div className={filterActive==='allTime'?"filterRowItem filterRowItemActive":"filterRowItem"} id="allTime" onClick={()=>{setFilterActive('allTime');setCompare(false);setStatisticCompare({})}}>Весь час</div>
                </div>
                <div className="filterDate">
                    <div>
                        {filterActive==='week' && <WeekSelector getStatistics={getStatistics}></WeekSelector>}
                        {filterActive==='month' && <MonthSelector getStatistics={getStatistics}></MonthSelector>}
                        {filterActive==='year' && <YearSelector getStatistics={getStatistics}></YearSelector>}
                    </div>
                    {filterActive!=='allTime' &&<div className="filterDateCompare">
                        {compare && filterActive==='week' && <WeekSelector getStatistics={getStatisticCom} compare={compare}></WeekSelector>}
                        {compare &&filterActive==='month' && <MonthSelector getStatistics={getStatisticCom} compare={compare}></MonthSelector>}
                        {compare &&filterActive==='year' && <YearSelector getStatistics={getStatisticCom} compare={compare}></YearSelector>}
                        {!compare && filterActive!=='allTime' && <div onClick={()=>setCompare(!compare)}>Порівняти дані</div>}
                    </div>}
                </div>
            </div>
            <div className="statisticList">
                <div className="statisticListItem">
                    <div className="statisticItem">
                        <div>
                        <Icon type='TotalTrainings'></Icon>
                        </div>
                        <div>
                            <div className="statisticItemText">
                            {statistic.data_size || '--'}
                            </div>
                            {compare && <div className="statisticItemText compare">
                            {statisticCompare.data_size || '--'}
                            </div>}
                            <div className="statisticItemLabel"> 
                                Кількість тренувань
                            </div>
                        </div>
                    </div>
                    <div className="statisticItem">
                        <div>
                            <Icon type='TotalEvents'></Icon>
                        </div>
                        <div>
                            <div className="statisticItemText"> 
                            {statistic.events || '--'}
                            </div>
                            {compare && <div className="statisticItemText compare">
                            {statisticCompare.events || '--'}                        </div>}
                            <div className="statisticItemLabel">
                                Відвідано заходів
                            </div>
                        </div>
                    </div>
                    <div className="statisticItem">
                        <div>
                            <Icon type='Calories'></Icon>
                        </div>
                        <div>
                            <div className="statisticItemText">
                                {statistic.calories || '--'} <span>ккал</span>  
                            </div>
                            {compare && <div className="statisticItemText compare">
                                {statisticCompare.calories || '--'} <span>ккал</span>  
                            </div>}
                            
                            <div className="statisticItemLabel">
                                Активних калорій
                            </div>
                        </div>
                    </div>

                    <div className="statisticItem">
                        <div>
                            <Icon type='Calories'></Icon>
                        </div>
                        <div>
                            <div className="statisticItemText">
                                {statistic.passive_calories || '--'} <span>ккал</span>  
                            </div>
                            {compare && <div className="statisticItemText compare">
                                {statisticCompare.passive_calories || '--'} <span>ккал</span>  
                            </div>}
                            
                            <div className="statisticItemLabel">
                                Пасивних калорій
                            </div>
                        </div>
                    </div>
                    <div className="statisticItem">
                        <div>
                            <Icon type='Calories'></Icon>
                        </div>
                        <div>
                            <div className="statisticItemText">
                                {statistic.total_calories || '--'} <span>ккал</span>  
                            </div>
                            {compare && <div className="statisticItemText compare">
                                {statisticCompare.total_calories || '--'} <span>ккал</span>  
                            </div>}
                            
                            <div className="statisticItemLabel">
                                Всього калорій
                            </div>
                        </div>
                    </div>


                    <div className="statisticItem">
                        <div>
                            <Icon type='Alarm'></Icon>
                        </div>
                        <div>
                            <div className="statisticItemText">
                            {statistic.time || '--'} 
                            </div>
                            {compare && <div className="statisticItemText compare">
                                {statisticCompare.time || '--'}
                            </div>}
                            <div className="statisticItemLabel">
                                Трив. активностей
                            </div>
                        </div>
                    </div>
                   
                </div>
                <div className="statisticListItem">
                    <div className="statisticItem">
                        <div>
                            <Icon type='Distance'></Icon>
                        </div>
                        <div>
                            <div className="statisticItemText">
                                {(statistic.distance && (statistic.distance/1000)?.toFixed(2)) || '--'} <span>км</span>  
                            </div>
                            {compare && <div className="statisticItemText compare">
                                {(statisticCompare.distance && (statisticCompare.distance/1000)?.toFixed(2)) || '--'} <span>км</span>  
                            </div>}
                            <div className="statisticItemLabel">
                                Дистанція
                            </div>
                        </div>
                    </div>
                    <div className="statisticItem">
                        <div>
                        <Icon type='avarageTemp'></Icon>
                        </div>
                        <div>
                            <div className="statisticItemText">
                                {statistic.temp>0?tempCacl(statistic.temp):'--'}<span>хв/км</span>  
                            </div>
                            {compare && <div className="statisticItemText compare">
                                {statisticCompare.temp>0 ?tempCacl(statisticCompare.temp):'--'} <span>хв/км</span>  
                            </div>}
                            <div className="statisticItemLabel">
                                Середній темп
                            </div>
                        </div>
                    </div>
                    <div className="statisticItem">
                        <div>
                            <Icon type='Speed'></Icon>
                        </div>
                        <div>
                            <div className="statisticItemText">
                            {statistic.speed?.toFixed(2) || '--'} <span>км/год</span>  
                            </div>
                            {compare && <div className="statisticItemText compare">
                                {statisticCompare.speed?.toFixed(2) || '--'} <span>км/год</span>  
                            </div>}
                            <div className="statisticItemLabel">
                                Середня швидкість
                            </div>
                        </div>
                    </div>
                    <div className="statisticItem">
                        <div>
                            <Icon type='AvarageHeartRate'></Icon>
                        </div>
                        <div>
                            <div className="statisticItemText">
                            {statistic.bpm || '--'} <span>уд/хв</span> 
                            </div>
                            {compare && <div className="statisticItemText compare">
                            {statisticCompare.bpm || '--'} <span>уд/хв</span> 
                            </div>}
                            <div className="statisticItemLabel">
                                Середній пульс
                            </div>
                        </div>
                    </div>
                    
                    <div className="statisticItem">
                        <div>
                            <Icon type={'steps'}></Icon>
                        </div>
                        <div>
                            <div className="statisticItemText">
                                {statistic.steps || '--'}
                            </div>
                            {compare && <div className="statisticItemText compare">
                                {statisticCompare.steps || '--'}
                            </div>}
                            <div className="statisticItemLabel">
                            Кроків
                            </div>
                        </div>
                    </div>
                    <div className="statisticItem">
                        <div>
                            <Icon type='Hight_Up'></Icon>
                        </div>
                        <div>
                            <div className="statisticItemText">
                                {statistic.hight || '--'} <span>м</span> 
                            </div>
                            {compare && <div className="statisticItemText compare">
                                {statisticCompare.hight || '--'} <span>м</span> 
                            </div>}
                            <div className="statisticItemLabel">
                                Підйом вверх
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className="statisticItem">
                    <div>
                        <Icon type='Hight_Down'></Icon>
                    </div>
                    <div>
                        <div className="statisticItemText">
                            {statistic.altitude_min || '--'} <span>м</span> 
                        </div>
                        {compare && <div className="statisticItemText compare">
                            {statisticCompare.altitude_min || '--'} <span>м</span> 
                        </div>}
                        <div className="statisticItemLabel">
                            Зниження висоти
                        </div>
                    </div>
                </div>                 */}
            </div>
        </div>
       {form.items?.length>0 &&  <div >
            <h2>
                Активності
            </h2>
            <div className="activityList">
                {form.items?.length>0 && form.items.map(el=>{
                    return(
                        <ActiveItem item={el}/>
                    )

                })}
                {/* <ActiveItem/> */}
            </div>
        </div>}

    </div>

</>)

}





export default Statistic;





