import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { getPhoneCode, updateUser, verifyPhone } from '../../../redux/actions/users';


const PhoneModal =(props)=>{
    const currentId = useParams().id;
    const {currentUser} = useSelector(state=>state.currentUser);
    const [error,setError]=useState('');
    const [form,setForm]=useState({id:currentId});
    const [ready,setReady] = useState(false);
    const [seconds, setSeconds] = useState(0);

    const dispatch=useDispatch();
    const closeClick=()=>{
        props.setModal(false)

    }
    const changeHandler=(e)=>{
        setForm({...form,[e.target.id]:e.target.value})
      }
    const updateDate = ()=>{
      if(form.phone){
        let data ={...props.form}
        data.phone=form.phone
        dispatch(updateUser(data)).then(res=>{
          if(currentUser && currentUser.roleId ==="631db81f-fa07-42e2-b394-062c9be66b09"){
            props.initialFunction();
            closeClick();
            props.setAlertMessage('Телефон змінено');
            props.setAlertModal(true);
          }
        })
      }
    }
      const receiveCode=()=>{
        setSeconds(60);
        dispatch(getPhoneCode(form)).then(res=>{
            if(res.errors){
                setError(res.errors[0].msg)
            }else{
                // updateDate()
                setError('')
                setReady(true)
            }
        })
    }
    const saveChange=()=>{
        let data={...form};
        if(currentId){
            data.id=currentId
        }else{
            
            data.id=currentUser.id
        }
        dispatch(verifyPhone(data)).then(res=>{
            if(res.errors){
                setError(res.errors[0].msg)
            }else{
                props.initialFunction();
                setError('')
                closeClick()
            }
        })
    }

    useEffect(() => {
      if (seconds > 0) {
        setTimeout(() => setSeconds(seconds - 1), 1000);
      }
    }, [seconds]);


return(
<div className="apLoginFormContainer">
  <svg onClick={closeClick}><use href="/images/icon24Cancel.svg#icon24" /></svg>
  <h3>Зміна телефону</h3>
  <div className={error?"apInputText apError":"apInputText"}>
    <label htmlFor="phone">Телефон *</label>
    <input placeholder='+380XXXXXXXXX' id="phone"onChange={changeHandler} type="text" value={form.phone} />
  </div>
  {currentUser && currentUser.roleId !=="631db81f-fa07-42e2-b394-062c9be66b09" &&<><div className="apLoginFormFiledWithButton">
    <div className={error?"apInputText apError":"apInputText"}>
      <label htmlFor="code">Код з СМС</label>
      <input id="code"onChange={changeHandler} type="text" value={form.code} />
    </div>
    <button className="secondaryButtonTextDefault" disabled={seconds>0} onClick={receiveCode}>
      Отримати код
    </button>
  </div>
  {ready && !error&&<> <span>Очiкуйте на код. Введіть код з SMS та натисніть «зберегти зміни»</span>
       {seconds>0 &&<span>Отримати код повторно через {seconds}</span>}</>
  }
  {error && <span className='apError'>{error}</span>}
  <button disabled={ form.phone?.length!==13 || !ready || form.code?.length!==6 }  className={ready?"secondaryButtonTextDefault":"primaryButtonTextDisabled"}onClick={saveChange}>
    Зберегти зміни
  </button>
  </>}
  {currentUser && currentUser.roleId ==="631db81f-fa07-42e2-b394-062c9be66b09" &&<button disabled={ form.phone?.length!==13 } className={"secondaryButtonTextDefault"} onClick={updateDate}>
    Зберегти зміни
  </button>}
</div>

)
}




export default PhoneModal;