import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { editUserVideo } from "../../../redux/actions/videoUser";

const VideoUpload = (props) => {
  const dispatch = useDispatch();
  const { currentUser } = useSelector((state) => state.currentUser);
  const [form, setForm] = useState(props.form);

  const changeHandler = (e) => {
    setForm({ ...form, [e.target.id]: e.target.value });
  };

  const editItem = (e) => {
    dispatch(editUserVideo(form)).then((res) => {});
  };

  // useEffect(() => {}, [props.form]);

  return (
    <div className="videoCard2Columns">
      <div className="videoCardVideoWithTextBlock">
        <div className="videoPlayerContainer" style={{ height: "444px" }}>
          {form.url && (
            <iframe
              width="444"
              height="315"
              src={"https://www.youtube.com/embed/" + form.url}
            ></iframe>
          )}
        </div>
        <div className="videoCardTextUnderVideo">
          <div
            className={
              !(
                props.form.statusId !==
                  "66e2ec16-03a1-4367-834a-d6b87ee709bd" &&
                props.form.statusId !== "f7b77851-1d35-4ab1-9fcd-ef6cd2b88ace"
              )
                ? "apInputText apDisabled"
                : "apInputText"
            }
          >
            <label htmlFor="url">ID відео</label>
            <input
              placeholder="Вставте ID відеo"
              style={{ width: "400px" }}
              disabled={
                !(
                  form.statusId !== "66e2ec16-03a1-4367-834a-d6b87ee709bd" &&
                  props.form.statusId !== "f7b77851-1d35-4ab1-9fcd-ef6cd2b88ace"
                )
              }
              id="url"
              type="text"
              value={form.url}
              onChange={changeHandler}
              onBlur={editItem}
            />
          </div>

          {/* <button className="secondaryButtonIconDefault apInputTypeFile">
                <label id="dat" htmlFor='fileUpload' onDrop={getFileContext}>
                <svg><use href="/images/icon24Attach.svg#icon24" /></svg>
                </label>
              </button> */}
        </div>
      </div>
      <div className="videoCardVideoWithTextBlock">
        <div className="videoPlayerContainer" style={{ height: "444px" }}>
          {form.sourceUrl && (
            <iframe
              width="444"
              height="315"
              src={"https://www.youtube.com/embed/" + form.sourceUrl}
            ></iframe>
          )}
        </div>
        <div className="videoCardTextUnderVideo">
          <div
            className={
              !(
                form.statusId !== "66e2ec16-03a1-4367-834a-d6b87ee709bd" &&
                form.statusId !== "f7b77851-1d35-4ab1-9fcd-ef6cd2b88ace"
              ) && currentUser.roleId !== "e9789dde-94a9-4ad3-88e8-374ec41f8c1a"
                ? "apInputText apDisabled"
                : "apInputText"
            }
          >
            <label htmlFor="sourceUrl">ID відео модератора</label>
            <input
              placeholder="Вставте ID відео"
              style={{ width: "400px" }}
              disabled={
                currentUser.roleId !== "e9789dde-94a9-4ad3-88e8-374ec41f8c1a" ||
                !(
                  form.statusId !== "66e2ec16-03a1-4367-834a-d6b87ee709bd" &&
                  form.statusId !== "f7b77851-1d35-4ab1-9fcd-ef6cd2b88ace"
                )
              }
              id="sourceUrl"
              type="text"
              value={form.sourceUrl}
              onChange={
                currentUser.roleId === "e9789dde-94a9-4ad3-88e8-374ec41f8c1a" &&
                changeHandler
              }
              onBlur={editItem}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default VideoUpload;
