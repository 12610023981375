import React from "react";
import { useNavigate } from "react-router";
import { Icon } from "../../../../../components/Icon";
import formatTime from "../../../../../helpers/formatTime";
import getDate from "../../../../../helpers/getDate";

const ActiveItem =(props)=>{

    const navigate =useNavigate();

    return(
        <div className="activeItem" style={{
            backgroundImage: `linear-gradient(rgba(68, 79, 96, 0.1), rgba(68, 79, 96, 0.1)), url('${props.item.coverImage||(props.item.photos.length>0&& props.item.photos[0])||"https://ap.sportforall.gov.ua/api/v1/uploads/b34604xc-02qb-46bb-9e91-fc3ffrb92w41"}')`,
        }}
        onClick={()=>navigate('/activity/'+props.item.id)}>
                <div></div>
                <div className="activeItemDetails">
                    <div className="activeItemDate">{getDate(props.item?.createdAt,'dd/mm/yy','.')}</div>
                    <div  className="activeItemType">
                        {props.item?.activityType &&<div className='leftsideInfoStartPageIcon'>
                            <Icon type={props.item.activityType==='83a70a99-f4e2-4b2d-b004-0ad75e1dbb43'&& 'Run' || props.item.activityType==='625d2bd5-f045-4341-a2e1-e53ef1f45104'&& 'Bicycle' || props.item.activityType==='e8b243de-fba4-4b16-bb6b-30a38e227328'&& 'Scandinavian' || props.item.activityType==='eb1f23b9-a872-43ef-8462-a513504af109'&& 'Walk' || props.item?.activityType === 'eb1f23b9-a872-43ef-8462-a513504af111' && 'ex'}></Icon>
                        </div>}
                        {props.item.title || props.item.address}
                    </div>
                    <div className="activeItemStatistic">
                        <div>
                           {props.item?.activityType !== 'eb1f23b9-a872-43ef-8462-a513504af111'? <> <div className="activeItemStatisticData">
                                {(props.item?.distance/1000).toFixed(2)}<span> км</span> 
                            </div>
                            <div className="activeItemStatisticLabel">
                                Дистанція
                            </div></>:
                             <> <div className="activeItemStatisticData">
                             {props.item?.steps}
                         </div>
                         <div className="activeItemStatisticLabel">
                             Кроків
                         </div></>
                            }
                        </div>
                        <div>
                            <div className="activeItemStatisticData" style={{textAlign:'center'}}>  

                            {props.item?.calories} <span> ккал</span> 
                            </div>
                            <div className="activeItemStatisticLabel">
                               Витрачено калорій
                            </div>
                        </div>
                        <div>
                            <div className="activeItemStatisticData">  
                                {formatTime(props.item?.time)}
                            </div>
                            <div className="activeItemStatisticLabel" style={{paddingTop:'2px',textAlign:'end'}}>
                                Тривалість
                            </div>
                        </div>

                    </div>
                </div>
        </div>
    )
}

export default ActiveItem;