import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import Loader from "../../../components/Loader/Loader";
import Tile from "../../../components/Tile";
import { getFcUserApplying, getFcUserHeads, getFcUserMembers } from "../../../redux/actions/fcUsers";


export const Users = (props) => {
  const [ready,setReady]=useState(false);
  const dispatch=useDispatch();
  const currentId = useParams().id
  const { fcUsersHead,fcUsersMember,fcUsers,fc } = useSelector((state) => state);

  const initialFunc= async()=>{
    await getFcHead();
    await getFcUsers();
    await getFcMembers();
    setReady(true);
  }

  const getFcMembers=()=>{
    let data={id:currentId}
    dispatch(getFcUserHeads(data))
  }

  const getFcUsers=()=>{
    let data={id:currentId}
    dispatch(getFcUserApplying(data))
  }

  const getFcHead=()=>{
    let data={id:currentId}
    dispatch(getFcUserMembers(data))
  }
  

  useEffect(() => {
    if(!ready){
      initialFunc()
    }
  },[ready]);

  if(!ready || !fcUsersHead || !fcUsers || !fcUsersMember ){
    return(
      <Loader></Loader>
    )

  }else{
  return (
    <div className="apFcUsers">

              <div className="apFcUsersRow">
                <h3>Керівний склад клубу</h3>
                {fcUsersHead && fcUsersHead.items && fcUsersHead.items.map(
                  (
                    el,
                    index
                  ) => {
                    return (
                      <Tile
                        setReady={setReady}
                        el={el}
                        type={"USER"}
                        img={el.photo}
                        nickname={el.nickname}
                        contact={el.email}
                        age={el.age}
                        // status={status}
                        adress={el.city}
                        // rating={rating}
                        id={el.id}
                        sex={el.sex}
                        key={index}
                      />
                    );
                  }
                )}
                </div>

                {fc && fc.clubUser && ((fc.clubUser.isCoordinator ||fc.clubUser?.isActing )||(!fc.clubUser.isCoordinator && !fc.clubUser?.isActing && !fc.clubUser.isApproved)  )&& fcUsers?.items?.length>0 &&<div className="apFcUsersRow">
                <h3>Заявки на вступ до клубу</h3>
                {fcUsers && fcUsers.items && fcUsers.items.map(
                  (
                    el,
                    index
                  ) => {
                    return (
                      <Tile
                        setReady={setReady}
                        el={el}
                        type={"USERINVITE"}
                        img={el.photo}
                        nickname={el.nickname}
                        contact={el.email}
                        age={el.age}
                        // status={status}
                        adress={el.city}
                        // rating={rating}
                        id={el.id}
                        sex={el.sex}
                        key={index}

                      />
                    );
                  }
                )}
                </div>}
                 <div className="apFcUsersRow">
                {fcUsersMember?.items?.length>0 &&<h3> Учасники клубу</h3>}
                {fcUsersMember?.items?.length>0 && fcUsersMember.items.map(
                  (
                    el,
                    index
                  ) => {
                    return (
                      <Tile
                        setReady={setReady}
                        el={el}
                        type={"USER"}
                        img={el.photo}
                        permissionUser={el?.permissionUser}
                        nickname={el.nickname}
                        contact={el.email}
                        age={el.age}
                        // status={status}
                        adress={el.city}
                        // rating={rating}
                        id={el.id}
                        sex={el.sex}
                        key={index}
                      />
                    );
                  }
                )}

                
              </div>
    </div>
  );
}
};
