export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const DONE_REFRESHING_TOKEN = "DONE_REFRESHING_TOKEN";
export const REFRESHING_TOKEN = "REFRESHING_TOKEN";
export const LOGOUT = "LOGOUT";
export const CODE_SUCCESS="CODE_SUCCESS";
export const CODE_FAIL ="CODE_FAIL";
export const RESTORE_SUCCESS="RESTORE_SUCCESS";
export const RESTORE_FAIL ="RESTORE_FAIL";
export const SET_MESSAGE = "SET_MESSAGE";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";

export const GETCURRENTUSER="GETCURRENTUSER";
export const READEEVENTQR_SUCCESS="READEEVENTQR_SUCCESS";
export const READEEVENTQR_FAIL="READEEVENTQR_FAIL";

export const CREATE_USER = "CREATE_USER";
export const RETRIEVE_USERS = "RETRIEVE_USERS";
export const UPDATE_USER = "UPDATE_USER";
export const GET_CODE ="GET_CODE"
export const UPDATEPASSWORD_SUCCESS = "UPDATEPASSWORD_SUCCESS";
export const UPDATEPASSWORD_FAIL = "UPDATEPASSWORD_FAIL";
export const CHANGEUSER = "CHANGEUSER";
export const DELETEUSER_SUCCESS = "DELETEUSER_SUCCESS";
export const DELETEUSER_FAIL = "DELETEUSER_FAIL";

export const CREATEPARK_SUCCESS = "CREATEPARK_SUCCESS";
export const CREATEPARK_FAIL ="CREATEPARK_FAIL"
export const GETPARK_SUCCESS = "GETPARK_SUCCESS";
export const GETPARK_FAIL = "GETPARK_FAIL";
export const GETPARKITEM_SUCCESS = "GETPARKITEM_SUCCESS";
export const GETPARKITEM_FAIL = "GETPARKITEM_FAIL";
export const EDITPARK_SUCCESS = "EDITPARK_SUCCESS";
export const EDITPARK_FAIL = "EDITPARK_FAIL";
export const DELETEPARK_SUCCESS = "DELETEPARK_SUCCESS";
export const DELETEPARK_FAIL = "DELETEPARK_FAIL";
export const CLONEPARK_SUCCESS = "CLONEPARK_SUCCESS";
export const CLONEPARK_FAIL = "CLONEPARK_FAIL";

export const DICTIONARIESLIST_SUCCESS = "DICTIONARIESLIST_SUCCESS";
export const DICTIONARIESLIST_FAIL = " DICTIONARIESLIST_FAIL";
export const ADDITIONALDICTIONARIESLIST_SUCCESS = "ADDITIONALDICTIONARIESLIST_SUCCESS";
export const ADDITIONALDICTIONARIESLIST_FAIL = " ADDITIONALDICTIONARIESLIST_FAIL";
export const DICTIONARIESDELETE_SUCCESS= "DICTIONARIESDELETE_SUCCESS";
export const DICTIONARIESDELETE_FAIL= "DICTIONARIESDELETE_FAIL";
export const DICTIONARIESEDIT_SUCCESS= "DICTIONARIESEDIT_SUCCESS";
export const DICTIONARIESEDIT_FAIL= "DICTIONARIESEDIT_FAIL";
export const DICTIONARIESADD_FAIL= "DICTIONARIESADD_FAIL";
export const DICTIONARIESADD_SUCCESS= "DICTIONARIESADD_SUCCESS";
export const DICTIONARIESITEM_SUCCESS= "DICTIONARIESITEM_SUCCESS";
export const DICTIONARIESITEM_FAIL= "DICTIONARIESITEM_FAIL";
export const DICTIONARIESFULLLIST_SUCCESS= "DICTIONARIESFULLLIST_SUCCESS";
export const DICTIONARIESFULLLIST_FAIL= "DICTIONARIESFULLLIST_FAIL";
export const FULLLIST_SUCCESS= "FULLLIST_SUCCESS";
export const FULLLIST_FAIL= "FULLLIST_FAIL";
export const LISTID_SUCCESS= "LISTID_SUCCESS";

export const UPLOADFILE_SUCCESS= "UPLOADFILE_SUCCESS";
export const UPLOADFILE_FAIL= "UPLOADFILE_FAIL";

export const GETCURRENTINFO= "GETCURRENTINFO";
export const GETCURRENTINFOLIST= "GETCURRENTINFOLIST";


export const GETVIDEO_SUCCESS= "GETVIDEO_SUCCESS";
export const GETVIDEO_FAIL= "GETVIDEO_FAIL";
export const EDITVIDEO_SUCCESS= "EDITVIDEO_SUCCESS";
export const EDITVIDEO_FAIL= "EDITVIDEO_FAIL";
export const DELETEVIDEO_SUCCESS= "DELETEVIDEO_SUCCESS";
export const DELETEVIDEO_FAIL= "DELETEVIDEO_FAIL";
export const GETVIDEOITEM_SUCCESS= "GETVIDEOITEM_SUCCESS";
export const GETVIDEOITEM_FAIL= "GETVIDEOITEM_FAIL";
export const CREATEVIDEO_SUCCESS= "CREATEVIDEO_SUCCESS";
export const CREATEVIDEO_FAIL= "CREATEVIDEO_FAIL";

export const GETUSERVIDEO_SUCCESS= "GETUSERVIDEO_SUCCESS";
export const GETUSERVIDEO_FAIL= "GETUSERVIDEO_FAIL";
export const EDITUSERVIDEO_SUCCESS= "EDITUSERVIDEO_SUCCESS";
export const EDITUSERVIDEO_FAIL= "EDITUSERVIDEO_FAIL";
export const DELETEUSERVIDEO_SUCCESS= "DELETEUSERVIDEO_SUCCESS";
export const DELETEUSERVIDEO_FAIL= "DELETEUSERVIDEO_FAIL";
export const GETUSERVIDEOITEM_SUCCESS= "GETUSERVIDEOITEM_SUCCESS";
export const GETUSERVIDEOITEM_FAIL= "GETUSERVIDEOITEM_FAIL";
export const CREATEUSERVIDEO_SUCCESS= "CREATEUSERVIDEO_SUCCESS";
export const CREATEUSERVIDEO_FAIL= "CREATEUSERVIDEO_FAIL";

export const CREATEPARKMESSAGES_SUCCESS = "CREATEPARKMESSAGES_SUCCESS";
export const CREATEPARKMESSAGES_FAIL ="CREATEPARKMESSAGES_FAIL"
export const GETPARKMESSAGES_SUCCESS = "GETPARKMESSAGES_SUCCESS";
export const GETPARKMESSAGES_FAIL = "GETPARKMESSAGES_FAIL";
export const GETPARKMESSAGESITEM_SUCCESS = "GETPARKMESSAGESITEM_SUCCESS";
export const GETPARKMESSAGESITEM_FAIL = "GETPARKMESSAGESITEM_FAIL";
export const EDITPARKMESSAGES_SUCCESS = "EDITPARKMESSAGES_SUCCESS";
export const EDITPARKMESSAGES_FAIL = "EDITPARKMESSAGES_FAIL";
export const DELETEPARKMESSAGES_SUCCESS = "DELETEPARKMESSAGES_SUCCESS";
export const DELETEPARKMESSAGES_FAIL = "DELETEPARKMESSAGES_FAIL";


export const ADDPARKCOORDINATORS_SUCCESS = "ADDPARKCOORDINATORS_SUCCESS";
export const ADDPARKCOORDINATORS_FAIL = "ADDPARKCOORDINATORS_FAIL";
export const DELETEPARKCOORDINATORS_SUCCESS = "DELETEPARKCOORDINATORS_SUCCESS";
export const DELETEPARKCOORDINATORS_FAIL = "DELETEPARKCOORDINATORS_FAIL";
export const GETPARKCOORDINATORS_SUCCESS = "GETPARKCOORDINATORS_SUCCESS";
export const GETPARKCOORDINATORS_FAIL = "GETPARKCOORDINATORS_FAIL";

export const CREATEVIDEOMESSAGES_SUCCESS = "CREATEVIDEOMESSAGES_SUCCESS";
export const CREATEVIDEOMESSAGES_FAIL ="CREATEVIDEOMESSAGES_FAIL"
export const GETVIDEOMESSAGES_SUCCESS = "GETVIDEOMESSAGES_SUCCESS";
export const GETVIDEOMESSAGES_FAIL = "GETVIDEOMESSAGES_FAIL";
export const GETVIDEOMESSAGESITEM_SUCCESS = "GETVIDEOMESSAGESITEM_SUCCESS";
export const GETVIDEOMESSAGESITEM_FAIL = "GETVIDEOMESSAGESITEM_FAIL";
export const EDITVIDEOMESSAGES_SUCCESS = "EDITVIDEOMESSAGES_SUCCESS";
export const EDITVIDEOMESSAGES_FAIL = "EDITVIDEOMESSAGES_FAIL";
export const DELETEVIDEOMESSAGES_SUCCESS = "DELETEVIDEOMESSAGES_SUCCESS";
export const DELETEVIDEOMESSAGES_FAIL = "DELETEVIDEOMESSAGES_FAIL";

export const LOG_SUCCESS = "LOG_SUCCESS";
export const LOG_FAIL = "LOG_FAIL";



export const GETEVENTS_SUCCESS = "GETEVENTS_SUCCESS";
export const GETEVENTS_FAIL ="GETEVENTS_FAIL"
export const EDITEVENTS_SUCCESS = "EDITEVENTS_SUCCESS";
export const EDITEVENTS_FAIL = "EDITEVENTS_FAIL";
export const DELETEEVENTS_SUCCESS = "DELETEEVENTS_SUCCESS";
export const GETEVENTSITEM_SUCCESS = "GETEVENTSITEM_SUCCESS";
export const DELETEEVENTS_FAIL = "DELETEEVENTS_FAIL";
export const GETEVENTSITEM_FAIL = "GETEVENTSITEM_FAIL";
export const CREATEEVENTS_SUCCESS = "CREATEEVENTS_SUCCESS";
export const CREATEEVENTS_FAIL = "CREATEEVENTS_FAIL";


export const GETPARKLISTCOORDINATOR_SUCCESS = "GETPARKLISTCOORDINATOR_SUCCESS";
export const GETPARKLISTCOORDINATOR_FAIL = "GETPARKLISTCOORDINATOR_FAIL";


export const CREATENEWS_SUCCESS= "CREATENEWS_SUCCESS";
export const CREATENEWS_FAIL= "CREATENEWS_FAIL";
export const GETNEWS_SUCCESS= "GETNEWS_SUCCESS";
export const GETNEWS_FAIL= "GETNEWS_FAIL";
export const GETNEWSITEM_SUCCESS= "GETNEWSITEM_SUCCESS";
export const GETNEWSITEM_FAIL= "GETNEWSITEM_FAIL";
export const EDITNEWS_SUCCESS= "EDITNEWS_SUCCESS";
export const EDITNEWS_FAIL= "EDITNEWS_FAIL";
export const DELETENEWS_SUCCESS= "DELETENEWS_SUCCESS";
export const DELETENEWS_FAIL= "DELETENEWS_FAIL";

export const CREATEINFO_SUCCESS= "CREATEINFO_SUCCESS";
export const CREATEINFO_FAIL= "CREATEINFO_FAIL";
export const GETINFO_SUCCESS= "GETINFO_SUCCESS";
export const GETINFO_FAIL= "GETINFO_FAIL";
export const GETINFOITEM_SUCCESS= "GETINFOITEM_SUCCESS";
export const GETINFOITEM_FAIL= "GETINFOITEM_FAIL";
export const EDITINFO_SUCCESS= "EDITINFO_SUCCESS";
export const EDITINFO_FAIL= "EDITINFO_FAIL";
export const DELETEINFO_SUCCESS= "DELETEINFO_SUCCESS";
export const DELETEINFO_FAIL= "DELETEINFO_FAIL";

export const GETNEWSCURRENTITEM_SUCCESS= "GETNEWSCURRENTITEM_SUCCESS";
export const GETNEWSCURRENTITEM_FAIL= "GETNEWSCURRENTITEM_FAIL";

export const GETEVENTSCURRENTITEM_SUCCESS= "GETEVENTSCURRENTITEM_SUCCESS";
export const GETEVENTSCURRENTITEM_FAIL= "GETEVENTSCURRENTITEM_FAIL";



export const CREATESUPPORT_SUCCESS= "CREATESUPPORT_SUCCESS";
export const CREATESUPPORT_FAIL= "CREATESUPPORT_FAIL";
export const GETSUPPORT_SUCCESS= "GETSUPPORT_SUCCESS";
export const GETSUPPORT_FAIL= "GETSUPPORT_FAIL";
export const GETSUPPORTITEM_SUCCESS= "GETSUPPORTITEM_SUCCESS";
export const GETSUPPORTITEM_FAIL= "GETSUPPORTITEM_FAIL";
export const EDITSUPPORT_SUCCESS= "EDITSUPPORT_SUCCESS";
export const EDITSUPPORT_FAIL= "EDITSUPPORT_FAIL";
export const DELETESUPPORT_SUCCESS= "DELETESUPPORT_SUCCESS";
export const DELETESUPPORT_FAIL= "DELETESUPPORT_FAIL";

export const SETCOORDINATES_SUCCESS= "SETCOORDINATES_SUCCESS";
export const SETCOORDINATES_FAIL= "SETCOORDINATES_FAIL";

export const CHANGEFCUSER_SUCCESS= "CHANGEFCUSER_SUCCESS";
export const GCHANGEFCUSER_FAIL= "GCHANGEFCUSER_FAIL";

export const GETFCPARTICIPANT_SUCCESS= "GETFCPARTICIPANT_SUCCESS";
export const GETFCPARTICIPANT_FAIL= "GETFCPARTICIPANT_FAIL";

export const GETFCCREATOR_SUCCESS= "GETFCCREATOR_SUCCESS";
export const GETFCCREATOR_FAIL= "GETFCCREATOR_FAIL";

export const CREATEFC_SUCCESS= "CREATEFC_SUCCESS";
export const CREATEFC_FAIL= "CREATEFC_FAIL";
export const GETFCLIST_FAIL= "GETFCLIST_FAIL";
export const GETFCLIST_SUCCESS= "GETFCLIST_SUCCESS";
export const GETFCITEM_SUCCESS= "GETFCITEM_SUCCESS";
export const GETFCITEM_FAIL= "GETFCITEM_FAIL";
export const EDITFC_SUCCESS= "EDITFC_SUCCESS";
export const EDITFC_FAIL= "EDITFC_FAIL";
export const DELETEFC_SUCCESS= "DELETEFC_SUCCESS";
export const DELETEFC_FAIL= "DELETEFC_FAIL";


export const GETEVENTSRESULT_SUCCESS= "GETEVENTSRESULT_SUCCESS";
export const GETEVENTSRESULT_FAIL= "GETEVENTSRESULT_FAIL";


export const CREATEPOINTS_SUCCESS= "CREATEPOINTS_SUCCESS";
export const CREATEPOINTS_FAIL= "CREATEPOINTS_FAIL";
export const PASSPOINTSITEM_SUCCESS= "PASSPOINTSITEM_SUCCESS";
export const PASSPOINTSITEM_FAIL= "PASSPOINTSITEM_FAIL";
export const EDITPOINTS_SUCCESS= "EDITPOINTS_SUCCESS";
export const EDITPOINTS_FAIL= "EDITPOINTS_FAIL";
export const DELETEPOINTS_FAIL= "DELETEPOINTS_FAIL";
export const DELETEPOINTS_SUCCESS= "DELETEPOINTS_SUCCESS";

export const GETUSERSHEAD_SUCCESS= "GETUSERSHEAD_SUCCESS";
export const GETUSERSHEAD_FAIL= "GETUSERSHEAD_FAIL";
export const GETUSERSMEMBERS_SUCCESS= "GETUSERSMEMBERS_SUCCESS";
export const GETUSERSMEMBERS_FAIL= "GETUSERSMEMBERS_FAIL";
export const GETUSERSAPPLYING_SUCCESS= "GETUSERSAPPLYING_SUCCESS";
export const GETUSERSAPPLYING_FAIL= "GETUSERSAPPLYING_FAIL";
export const CHANGEUSERS_SUCCESS= "CHANGEUSERS_SUCCESS";
export const CHANGEUSERS_FAIL= "CHANGEUSERS_FAIL";

export const CREATEFCNEWS_SUCCESS= "CREATEFCNEWS_SUCCESS";
export const CREATEFCNEWS_FAIL= "CREATEFCNEWS_FAIL";
export const GETFCNEWS_SUCCESS= "GETFCNEWS_SUCCESS";
export const GETFCNEWS_FAIL= "GETFCNEWS_FAIL";
export const GETFCNEWSITEM_SUCCESS= "GETFCNEWSITEM_SUCCESS";
export const GETFCNEWSITEM_FAIL= "GETFCNEWSITEM_FAIL";
export const EDITFCNEWS_SUCCESS= "EDITFCNEWS_SUCCESS";
export const EDITFCNEWS_FAIL= "EDITFCNEWS_FAIL";
export const DELETEFCNEWS_SUCCESS= "DELETEFCNEWS_SUCCESS";
export const DELETEFCNEWS_FAIL= "DELETEFCNEWS_FAIL";
export const GETFCNEWSCURRENTITEM_FAIL= "GETFCNEWSCURRENTITEM_FAIL";
export const GETFCNEWSCURRENTITEM_SUCCESS= "GETFCNEWSCURRENTITEM_SUCCESS";


export const GETNOTIFICATIONS_SUCCESS= "GETNOTIFICATIONS_SUCCESS";
export const GETNOTIFICATIONS_FAIL= "GETNOTIFICATIONS_FAIL";


export const GETCONF_SUCCESS= "GETCONF_SUCCESS";
export const GETCONF_FAIL= "GETCONF_FAIL";

export const EDITREPORTS_SUCCESS= "EDITREPORTS_SUCCESS";
export const GETREPORTS_SUCCESS= "GETREPORTS_SUCCESS";
export const GETREPORTS_FAIL= "GETREPORTS_FAIL";
export const EDITREPORTS_FAIL= "EDITREPORTS_FAIL";

export const GETWORKOUTS_SUCCESS= "GETWORKOUTS_SUCCESS";
export const GETWORKOUTS_FAIL= "GETWORKOUTS_FAIL";
export const EDITWORKOUTS_SUCCESS= "EDITWORKOUTS_SUCCESS";
export const EDITWORKOUTS_FAIL= "EDITWORKOUTS_FAIL";
export const DELETEWORKOUTS_SUCCESS= "DELETEWORKOUTS_SUCCESS";
export const DELETEWORKOUTS_FAIL= "DELETEWORKOUTS_FAIL";
export const GETWORKOUTSITEM_SUCCESS= "GETWORKOUTSITEM_SUCCESS";
export const GETWORKOUTSITEM_FAIL= "GETWORKOUTSITEM_FAIL";
export const CREATEWORKOUTSPLAN_SUCCESS= "CREATEWORKOUTSPLAN_SUCCESS";
export const CREATEWORKOUTSPLAN_FAIL= "CREATEWORKOUTSPLAN_FAIL";
export const GETWORKOUTSPLAN_SUCCESS= "GETWORKOUTSPLAN_SUCCESS";
export const GETWORKOUTSPLAN_FAIL= "GETWORKOUTSPLAN_FAIL";


export const CREATEQR_SUCCESS= "CREATEQR_SUCCESS";
export const CREATEQR_FAIL= "CREATEQR_FAIL";


export const GETEVENTEVALUATION_SUCCESS= "GETEVENTEVALUATION_SUCCESS";
export const GETEVENTEVALUATION_FAIL= "GETEVENTEVALUATION_FAIL";

export const SETCOORDINATESMY_SUCCESS= "SETCOORDINATESMY_SUCCESS";
export const SETCOORDINATESMY_FAIL= "SETCOORDINATESMY_FAIL";

export const GETALLCALENDAREVENTS_SUCCESS= "GETALLCALENDAREVENTS_SUCCESS";
export const GETALLCALENDAREVENTS_FAIL= "GETALLCALENDAREVENTS_FAIL";

export const GETCOUNTER_SUCCESS= "GETCOUNTER_SUCCESS";
export const GETCOUNTER_FAIL= "GETCOUNTER_FAIL";

export const TRACK_SUCCESS= "TRACK_SUCCESS";
export const TRACK_FAIL= "TRACK_FAIL";
export const EDITTRACK_SUCCESS= "EDITTRACK_SUCCESS";
export const EDITTRACK_FAIL= "EDITTRACK_FAIL";
export const DELETETRACK_SUCCESS= "DELETETRACK_SUCCESS";
export const DELETETRACK_FAIL= "DELETETRACK_FAIL";
export const GETTRACKITEM_SUCCESS= "GETTRACKITEM_SUCCESS";
export const GETTRACKITEM_FAIL= "GETTRACKITEM_FAIL";

export const ROUTE_SUCCESS= "ROUTE_SUCCESS";
export const ROUTE_FAIL= "ROUTE_FAIL";
export const EDITROUTE_SUCCESS= "EDITROUTE_SUCCESS";
export const EDITROUTE_FAIL= "EDITROUTE_FAIL";
export const DELETEROUTE_SUCCESS= "DELETEROUTE_SUCCESS";
export const DELETEROUTE_FAIL= "DELETEROUTE_FAIL";
export const GETROUTEITEM_SUCCESS= "GETROUTEITEM_SUCCESS";
export const GETROUTEITEM_FAIL= "GETROUTEITEM_FAIL";

export const GETGALLERYOFF_SUCCESS= "GETGALLERYOFF_SUCCESS";
export const GETGALLERYOFF_FAIL= "GETGALLERYOFF_FAIL";
export const GETGALLERY_FAIL= "GETGALLERY_FAIL";
export const GETGALLERY_SUCCESS= "GETGALLERY_SUCCESS";
export const CHANGEGALLERY_SUCCESS= "CHANGEGALLERY_SUCCESS";
export const CHANGEGALLERY_FAIL= "CHANGEGALLERY_FAIL";
export const DELETEGALLERY_SUCCESS= "DELETEGALLERY_SUCCESS";
export const DELETEGALLERY_FAIL= "DELETEGALLERY_FAIL";


export const GETSTATISTICCOMPARE_FAIL= "GETSTATISTICCOMPARE_FAIL";
export const GETSTATISTICCOMPARE_SUCCESS= "GETSTATISTICCOMPARE_SUCCESS";
export const GETSTATISTIC_SUCCESS= "GETSTATISTIC_SUCCESS";
export const GETSTATISTIC_FAIL= "GETSTATISTIC_FAIL";
export const ROUTEPOINTS_SUCCESS= "ROUTEPOINTS_SUCCESS";

export const GETPARKMAP_SUCCESS= "GETPARKMAP_SUCCESS";
export const GETPARKMAP_FAIL= "GETPARKMAP_FAIL";

export const GETSTATISTICMOTIVATION_SUCCESS= "GETSTATISTICMOTIVATION_SUCCESS";
export const GETSTATISTICMOTIVATION_FAIL= "GETSTATISTICMOTIVATION_FAIL";
