import React, { useState, useRef } from "react";

const VideoPlayer = ({ lowQualitySrc, highQualitySrc, poster,item }) => {
  const [quality, setQuality] = useState("low");
  const [playing, setPlaying] = useState(false);
  const videoRef = useRef(null);

  const handleQualityChange = (event) => {
    if (videoRef.current) {
      const currentTime = videoRef.current.currentTime;
      setPlaying(false);
      videoRef.current.src = event.target.value === "low" ? lowQualitySrc : highQualitySrc;
      videoRef.current.load();
      videoRef.current.currentTime = currentTime;
      videoRef.current.oncanplay = () => {
        setQuality(event.target.value);
        setPlaying(true);
        videoRef.current.play();
      };
    } else {
      setQuality(event.target.value);
    }
  };

  const handlePlay = () => {
    setPlaying(true);
  };

  const handlePause = () => {
    setPlaying(false);
  };

  const apStartPlay = () => {
    let elem = document.getElementById("apPlayButton");
    elem.style.display = "none";
    let playelem = document.getElementById("apVideoPlayer");
    playelem.play();
    setPlaying(true);
    if (typeof window.gtag === 'function') {
      window.gtag('event', 'play_video_start', {
        'video_title': item.title,
        'page_location': document.location.href,
        'difficulty': item.exerciseDifficultyLevelId,
        'type': item.categoryId,
      });
    }

  };

  const apEndPlay = () => {
    if (typeof window.gtag === 'function') {
      window.gtag('event', 'play_video_end', {
        'video_title': item.title,
        'page_location': document.location.href,
        'difficulty': item.exerciseDifficultyLevelId,
        'type': item.categoryId,
      });
    }
  };



  return (
    <>
      <video
        controls
        controlsList="nodownload"
        preload="metadata"
        id="apVideoPlayer"
        width="100%"
        height="100%"
        poster={poster}
        ref={videoRef}
        onPlay={handlePlay}
        onPause={handlePause}
        onEnded={apEndPlay}
      >
        <source src={lowQualitySrc} type="video/mp4" quality="low" select />
        <source src={highQualitySrc} type="video/mp4" quality="high" select />
      </video>
      {!playing && (
        <svg id="apPlayButton" onClick={apStartPlay}>
          <use href="/images/icon80PlayCircle.svg#icon80" />
        </svg>
      )}
      <div className="qualityButtons">
        {
          <>
            {quality === "high" && (
              <button
                onClick={() => {
                  setQuality("low");
                  handleQualityChange({ target: { value: "low" } });
                }}
              >
                SD
              </button>
            )}
            {quality === "low" && (
              <button
                onClick={() => {
                  setQuality("high");
                  handleQualityChange({ target: { value: "high" } });
                }}
              >
                HD
              </button>
            )}
          </>
        }
      </div>
  </>
  )}

export default VideoPlayer;
