import React, {useState,useEffect } from "react";
import { useDispatch } from "react-redux";
import { getConference } from "../../../redux/actions/events";
import { useParams } from "react-router";
import getDate from "../../../helpers/getDate";

// Д: ХЗ, почему функцию и таб с записями конференций назвали itinerary.
export const Itinerary = (props) => {
  const dispatch=useDispatch();
  const [ready, setReady] = useState(false);
  const currentId=useParams().id;
  const [form,setForm]=useState({})

  const initFunc=()=>{
    dispatch(getConference({id:currentId})).then(res=>{
      setForm(res)
      setReady(true);
    })
  }

  useEffect(() => {
    if(!ready){
      initFunc()
    }
  }, [ready]);

  const getDateFromDateTime = (datetime) => {
    let dddate = new Date(datetime);
    let date = dddate.getDate();
    let month = parseInt(dddate.getMonth())+1;
    let year = dddate.getFullYear();
    return (date+"."+month+"."+year);
  }

  const getTimeFromDateTime = (datetime) => {
    let dddate = new Date(datetime);
    let hours = dddate.getHours().toString();
    let minutes = dddate.getMinutes().toString();
    if (hours.length < 2) hours = "0" + hours;
    if (minutes.length < 2) minutes = "0" + minutes;
    return (hours+":"+minutes);
  }

  const onRowClick = (e) => {
    window.open(e.currentTarget.id);
  }
 
  return (<>
    <div className="instruction" id="recordslist">Натисніть на рядок певного запису для його перегляду</div>
    <div className="dictAllRowsContainer">
     {form?.total>0?
      form.items.map((el,index)=>{
        let recordDate = getDate(el.startTime,'dd/mm/yyyy','.');
        let recordTime = getDate(el.startTime,"hh:mm",':')+" - "+getDate(el.endTime,"hh:mm",':');
        return(
          <div className="confRecordRow" key={index} id={el.url} onClick={onRowClick}>
            <div>Запис {index+1}</div>
            <div>Дата: {recordDate}</div>
            <div>Час: {recordTime}</div>
            <div>Тривалість запису: {el.length} хв.</div>
            <div>Кількість учасників: {el.participants}</div>
          </div>
          )
      })
     :<div className="apEmptyCategory">Запис в обробці</div>}
    </div>
  </>
  );
};
