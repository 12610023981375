import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { Icon } from '../../../../../components/Icon';
import { approveGalleryStatus, deleteGallery } from '../../../../../redux/actions/gallery';


const PhotoItem =(props)=>{
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const userClick =(e)=>{
        navigate('/personalPage/'+e.target.id)
    }
    const deleteClick =()=>{
        let data={id:props.el.id,type:props.type}
        dispatch(deleteGallery(data)).then(res=>{
            props.initFunc()
        })
    }
    const downloadClick =()=>{
        const link = document.createElement("a");
        link.href = props.el.url;
        link.download = "downloaded_image.png";
        link.click();
    }
    const approveClick =()=>{
        let data={id:props.el.id}
        dispatch(approveGalleryStatus(data)).then(res=>{
            props.initFunc()
        })
    }
    
    return(
        <div className="imageItemGallery" style={{
            backgroundImage: `linear-gradient(rgba(68, 79, 96, 0.1), rgba(68, 79, 96, 0.1)), url('${props.el.url}')`,
        }}>

            <div className="imageItemGalleryButtons">
              {props.type==='user' && props.hasRights && <button onClick={approveClick}>
                    <Icon type='circleCheck'></Icon>
                </button>}
                <button className="fill"  onClick={downloadClick}>
                    <svg>
                        <use href="/images/icon24Download.svg#icon24" />
                    </svg>                                </button>
                {props.type==='official'&& props.hasRights ? <button  onClick={deleteClick}>
                    <Icon type='Trash'></Icon>
                </button> : props.type==='user' && 
                <button  onClick={deleteClick}>
                    <Icon type='Trash'></Icon>
                </button> 
                }

                <button  onClick={props.openFull}>
                    <Icon type='frame'></Icon>
                </button>
            </div>
            { props.el.user_photo?
            <div className="roundUser" style={{
                backgroundImage: `url('${props.el.user_photo}')`,
            }} id={props.el.user_id} onClick={userClick}>
            </div>
            :
            <div  className="roundUser"  id={props.el.user_id} onClick={userClick}>
                {props.el.user_last_name[0]+props.el.user_first_name[0]}
            </div>}
        </div>
    )

}

export default PhotoItem;


