import React, { useState, useEffect, useCallback } from "react";

const Timer = ({ date = "100", devider = ":", onEnd }) => {
  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [remainingTime, setRemainingTime] = useState(+date);

  const getTime = useCallback(() => {
    setDays(Math.floor(remainingTime / (1000 * 60 * 60 * 24)));
    setHours(Math.floor((remainingTime / (1000 * 60 * 60)) % 24));
    setMinutes(Math.floor((remainingTime / (1000 * 60)) % 60));
    setSeconds(Math.floor((remainingTime / 1000) % 60));
    setRemainingTime((prevTime) => prevTime - 1000);
  }, [remainingTime]);

  useEffect(() => {
    const interval = setInterval(getTime, 1000);
    return () => clearInterval(interval);
  }, [getTime]);

  useEffect(() => {
    if (remainingTime <= 0) {
      onEnd();
    }
  }, [remainingTime, onEnd]);

  useEffect(() => {
    setRemainingTime(+date);
  }, [date]);

  return (
    <div className="apTimer">
      <div className="apTimerItem">
        {days < 10 ? "0" + days : days}
        <span>днів</span>
      </div>
      {devider}
      <div className="apTimerItem">
        {hours < 10 ? "0" + hours : hours}
        <span>годин</span>
      </div>
      {devider}
      <div className="apTimerItem">
        {minutes < 10 ? "0" + minutes : minutes}
        <span>хвилин</span>
      </div>
      {devider}
      <div className="apTimerItem">
        {seconds < 10 ? "0" + seconds : seconds}
        <span>секунд</span>
      </div>
    </div>
  );
};

export default Timer;
