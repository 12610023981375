import axios from "axios";
import authHeader from "./auth-header";

import constants from '../../constants/constant.json';



const getAll =(data) =>{
    if(!data.offset){
        data.offset=0;
    } if (!data.limit){
        data.limit=10
    }
    let filter=''
    if(data.filter&& Object.keys(data.filter).length ){
        for (const [key, value] of Object.entries(data.filter)) {
            value.forEach(el => {
                filter+=`&filters[${key}]=${el}`
            });
        }
    }    
    if(data.sort&& Object.keys(data.sort).length ){
        
        for (const [key, value] of Object.entries(data.sort)) {
                filter+=`&sort[${key}]=${value}`
        }
    }  
return axios.get(constants.URL + `api/v1/users/?offset=${data.offset}&limit=${data.limit}${filter}`,{headers: authHeader()}).then(response=>{
        return response.data
    }).catch((e)=>{
        console.log(e);
      });;
 
}
const getById =(id)=>{         
        return axios
            .get(constants.URL + `api/v1/users/${id}`,{headers: authHeader()})
            .then((response) => { 
                
                return response.data;})
            .catch((e)=>{
                 
                console.log(e);
            });;
    }

const getUserFree =(id)=>{         
        return axios
            .get(constants.URL + `api/v1/users/${id}/free`,{headers: authHeader()})
            .then((response) => { 
                return response.data;})
            .catch((e)=>{
                console.log(e);
            });;
    }
const create=(data={}) =>{
        return axios.post(constants.URL + `api/v1/users`,data,{ headers: authHeader()});
    }
const change=(data) =>{
        return axios.post(constants.URL + `api/v1/users/${data.id}/change-role`,data,{ headers: authHeader(),body: JSON.stringify(data)});
}
const update= async(data) =>{
     
        return await axios.put(constants.URL + `api/v1/users/${data.id}`,{...data},{ headers: authHeader()}).then(res=>{
            return res
        }).catch(e=>{
            return e
        });
    }

    const updatePassword = (id, data) =>{  
        return axios.post(constants.URL+`api/v1/users/${id}/change-password`,data,{ headers: authHeader()});
    }


const applyForCoordinator =()=>{
    return axios.post(constants.URL + `api/v1/users/apply-for-coordinator`,{},{ headers: authHeader()}).then(res=>{
        return res
    }).catch(e=>{
        return e.response.data;
    });
}    

const updateCoordinator =(data)=>{
    return axios.post(constants.URL + `api/v1/users/${data.id}/${data.type}`,{},{ headers: authHeader()}).then(res=>{
        return res
    }).catch(e=>{
        return e.response.data;
    });
}    

const updateTrainer =(data)=>{
    return axios.post(constants.URL + `api/v1/users/trainer`,{...data},{ headers: authHeader()}).then(res=>{
        return res
    }).catch(e=>{
        return e.response.data;
    });
}    




const getCode= (data) => {
    
        return axios.post(constants.URL + `api/v1/users/email`,{...data},{ headers: authHeader()}).then(res=>{
            return res
        }).catch(e=>{
            return e.response.data;
        });
    }
const getPhoneCode= (data) => {
        return axios.post(constants.URL + `api/v1/users/phone`,{...data},{ headers: authHeader()}).then(res=>{
            return res
        }).catch(e=>{
            return e.response.data;
        });
    }
const verifyPhone= (data) => {
    return axios.post(constants.URL + `api/v1/users/${data.id}/phone-verify`,{...data},{ headers: authHeader()})
    .then(res=>{
        return res
    }).catch(e=>{
        return e.response.data;
    });
}
const verifyMail= (data) => {
    return axios.post(constants.URL + `api/v1/users/${data.id}/change-email`,{...data},{ headers: authHeader()})
    .then(res=>{ 
        return res
    }).catch(e=>{
        return e.response.data;
    });
}
const deleteUser=(data)=>{
    return axios.delete(constants.URL + `api/v1/users/${data.id}`,{ headers: authHeader()})
    .then(res=>{ 
        return res
    }).catch(e=>{
        return e.response.data;
    });
}

const coordinatorRepor = (data)=>{
    return axios.put(constants.URL + `api/v1/users/no-report-coordinator`,data,{ headers: authHeader()})
    .then(res=>{ 
        return res;
    }).catch(e=>{
        return e.response.data;
    });
}

const deactivateUser = (data)=>{
    return axios.post(constants.URL + `api/v1/users/${data.id}/${data.type}`,{},{ headers: authHeader()})
    .then(res=>{ 
        return res
    }).catch(e=>{
        return e.response.data;
    });
}

export default {
    getAll,
    getById,
    create,
    update,
    getCode,
    updatePassword,
    applyForCoordinator,
    change,
    getPhoneCode,
    verifyPhone,
    getUserFree,
    verifyMail,
    deleteUser,
    deactivateUser,
    updateCoordinator,
    updateTrainer,
    coordinatorRepor
};
