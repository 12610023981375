import React from "react";
import { useMap } from "react-leaflet";
import { useSelector } from "react-redux";

const POSITION_CLASSES = {
    bottomleft: "leaflet-bottom leaflet-left",
    bottomright: "leaflet-bottom leaflet-right",
    topleft: "leaflet-top leaflet-left",
    topright: "leaflet-top leaflet-right",
    top: "leaflet-top leaflet-center",
  };


function MyLocationMapButton(props){
    const map = useMap();
    const {coordinatesMy} =useSelector(state=>state);
  
    const positionClass =
    (props.position && POSITION_CLASSES[props.position]) ||
    POSITION_CLASSES.top;
  
    const clickEventMyLocation =()=>{
      map.setView([coordinatesMy.lat, coordinatesMy.lng]);
    }
    return(
    <div className={positionClass + " apAdmitPointList"}>
      <div className="leaflet-control leaflet-bar headline">
        <button className="primaryMediumButtonIconDefault" onClick={clickEventMyLocation}>
        <svg><use href="/images/icon24MyLocation.svg#icon24"></use></svg>
        </button>
      </div>
    </div>
    )
  }


  export default MyLocationMapButton;