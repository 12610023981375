import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import Loader from "../../components/Loader/Loader";
import VideoUpload from "./VideoUpload/VideoUpload";
import InfoVideo from "./InfoVideo/InfoVideo";
import { addUserVideo, deleteUserVideo, getUserVideoItem } from "../../redux/actions/videoUser";

const VideoUserItemPage = () => {
  const [ready, setReady] = useState(false);
  const [form, setForm] = useState({});
  const { listId } = useSelector((state) => state);
  const { currentUser } = useSelector((state) => state.currentUser);
  const { fullList } = useSelector((state) => state);
  const navigate = useNavigate();
  const currentId = useParams().id;

  const dispatch = useDispatch();
  const getItem = () => {
    setReady(false);
    dispatch(getUserVideoItem({ id: currentId }))
      .then((res) => {
        setForm(res);
        setReady(true);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const changeStatus = (e) => {
    let data = {};
    data.type = e.target.accessKey;
    data.id = currentId;

    dispatch(addUserVideo(data)).then((res) => {
      getItem();
    });
  };


  const deleteClick =(e)=>{
    let data={id:currentId};
    dispatch(deleteUserVideo(data)).then(res=>{
      navigate('/videoUserCatalog')
    })
  }



  useEffect(() => {
    if (!ready) {
      getItem();
    }
  }, []);

  if (!ready || !fullList.OwnershipTypes || !listId.list) {
    return (
      <div style={{ position: "relative" }}>
        {" "}
        <div style={{ position: "absolute", top: "50%", left: "50%" }}>
          <Loader />
        </div>
      </div>
    );
  }

  if (ready) {
    return (
      <>
        <h1>
          Картка відеоролика
          <h2 style={{'margin':'0'}}>Статус відеороліка: {listId.list[form?.statusId]}</h2>
        </h1>
        <div className="menuFunctionLine">
          <div className="actionButtonsBlock">
            <button
              className="secondaryButtonIconTextDefault"
              onClick={() => navigate("/videoUserCatalog")}
            >
              <svg>
                <use href="/images/icon24ArrowLeft.svg#icon24" />
              </svg>
              Назад
            </button>

            {form?.statusId === "def3497e-3498-4a17-a514-e99ec83676b4" &&
              currentUser.roleId === "e9789dde-94a9-4ad3-88e8-374ec41f8c1a" && (
                <button
                  className="primaryButtonIconTextDefault"
                  accessKey="publish"
                  onClick={changeStatus}
                >
                  <svg accessKey="publish">
                    <use
                      accessKey="publish"
                      href="/images/icon24CheckCircle.svg#icon24"
                    />
                  </svg>
                  Опублікувати
                </button>
              )}
            {form.statusId === "73f891c0-a79f-4afc-bd79-d529a5eb5774" && (
              <button
                className="primaryButtonIconTextDefault"
                accessKey="send"
                onClick={changeStatus}
              >
                <svg accessKey="send">
                  <use
                    accessKey="send"
                    href="/images/icon24CheckCircle.svg#icon24"
                  />
                </svg>
                На модерацію
              </button>
            )}
            {(form?.statusId === "66e2ec16-03a1-4367-834a-d6b87ee709bd" ||
              form?.statusId === "f7b77851-1d35-4ab1-9fcd-ef6cd2b88ace") && (
              <button
                accessKey="send"
                className="secondaryButtonIconTextDefault"
                onClick={changeStatus}
              >
                <svg accessKey="send">
                  <use
                    accessKey="send"
                    href="/images/icon24ArrowReturn.svg#icon24"
                  />
                </svg>
                Зняти з публікації та редагувати
              </button>
            )}
            {(form?.statusId === "def3497e-3498-4a17-a514-e99ec83676b4" ||
              form?.statusId === "73f891c0-a79f-4afc-bd79-d529a5eb5774") && (
                <button
                  accessKey="de"
                  className="secondaryButtonIconTextDefault"
                  onClick={deleteClick}
                >
                  <svg accessKey="deactivate">
                    <use
                      accessKey="deactivate"
                      href="/images/icon24Trash.svg#icon24"
                    />
                  </svg>
                  Видалити
                </button>
              )}
            
          </div>
          <div className="profileLinkVideo">
            Автор: 
            <a onClick={()=>navigate(`/personalPage/${form.createdBy.id}`)}>
            {form.createdBy.firstName &&form.createdBy.lastName? ' '+ form.createdBy.firstName + ' '+ form.createdBy.lastName  : ' '+ form.createdBy.nickname}
            </a>
          </div>
        </div>
        <div className="pageContentHeaderDivider" />
        <VideoUpload
          getItem={getItem}
          setForm={setForm}
          form={form}
        ></VideoUpload>

        {<InfoVideo></InfoVideo>}
      </>
    );
  }
};

export default VideoUserItemPage;
