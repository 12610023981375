import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../components/Loader/Loader";
import { getNews } from "../../redux/actions/news";
import Tile from "../../components/Tile/";
import { ReactComponent as Arrow } from "../../assets/images/collapseArrow2.svg";


const NewsUserListPage = () => {
  const dispatch = useDispatch();
  const [ready, setReady] = useState(false);
  const { news } = useSelector((state) => state);
  const [list, setList] = useState({items:[]});
  
  // const { filteredData } = useFilterArray(news);


  const getNewsList = (page = 1, filter,more) => {
    let data = {};
    if (filter) {
      data.filter = { ...filter };
    } else {
        data.filter = {};
    }
    data.sort = { publishedAt: "desc" };
    data.offset = (page - 1) * 9;
    data.limit = 9;
    dispatch(getNews(data)).then((res) => {
      let result={...list};
      result.total=res.total;
      result.offset=res.offset;
      result.limit=res.limit;
      result.items=[...result.items, ...res.items];
      setList(result)
      setReady(true);
    });
  };

  const moreNews=()=>{
    let page=(news.offset/news.limit)+2;
    getNewsList(page,null,'more');
  }


  useEffect(() => {
    if (!ready) {
     
        getNewsList();
      
    }
  }, [ready]);

  return (
    <>
      <div className="headerWithButtonsHeaderBlock">
        <h1>
          Блог{" "}
          <div className="headerSideText">
            Актуальні та минулі заходи, інформація про оновлення, анонси та
            звіти, та ще багато цікавого та корисного
          </div>
        </h1>
      </div>
      <div className="pageContentHeaderDivider"></div>
      {ready && (
        <div style={{ marginTop: "20px" }} className="newsList">
          {list?.items?.lendth !== 0 ?
            list?.items?.map(({ imageUrl, title, id, publishedAt,clubId }, index) => (
              <Tile
                img={imageUrl}
                title={title.length > 90 ? title.slice(0, 90) + '...' :title}
                to={clubId?`/fc/${clubId}/news/${id}`:`/newspg/${id}`}
                publishedAt={publishedAt}
                key={index}
              />
            )):
            "Немає жодного запису блогу"}
         {list.total>9 && (list.limit+list.offset)<list.total &&
         <button className="moreButton" onClick={moreNews}>
            <Arrow/>
           Завантажити ще</button>}
        </div>
        
      )}

      {!ready && <Loader />}
    </>
  );
};

export default NewsUserListPage;
