import React, { useEffect, useState } from "react";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import InfoWindow from "../../components/InfoWindow/InfoWindow";
import Loader from "../../components/Loader/Loader";
import {
  addEvents,
  deleteEvents,
  editEvents,
  getEventsItem,
} from "../../redux/actions/events";
import { getParksCoordinatorList } from "../../redux/actions/parks";
import Editor from "../../components/Editor/Editor";
import CategoryModal from "./CategoryModal/CategoryModal";
import { Table } from "./Map/Table";
import DifMap from "./DifMap/DifMap";
import getDate from "../../helpers/getDate";
import { useResponceChecker } from "../../hooks/useResponceChecker";
import FileUploadAndCrop from "../../components/FileUploadAndCrop/FileUploadAndCrop";
import axios from "axios";


const SportEventsItemPage = () => {
  const [ready, setReady] = useState(false);
  const [formSend, setFormSend] = useState({});
  const { listId } = useSelector((state) => state);
  const { currentUser } = useSelector((state) => state.currentUser);
  const { coordinatorsList } = useSelector((state) => state);
  const { fullList } = useSelector((state) => state);
  const dispatch = useDispatch();
  const currentId = useParams().id;
  const clubId = useParams().clubId;
  const navigate = useNavigate();
  const [modal, setModal] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertModal, setAlertModal] = useState(false);
  const [select, setSelect] = useState("");
  const [data, setData] = useState("");
  const [address, setAdress] = useState(null);
  const [formRes, setFormRes] = useState(null);
  const [errorCus, setErrorCus] = useState(false);

  
  let options={
    uploadType:'other_photo',
    initialAspectRatio:16 / 10,
    aspectRatio: 16 / 10,
    autoCropArea:16 / 10,
    height:'200px',
    width:'320px',
    size:10,
    type:'imageUrl',
    dragAbleZone:true,
    className:'eventCardCoverImage',
    text:'заходу',
    disabled:(formSend.statusId !== "384fc74a-254e-47d7-9fcc-cd608d7e5f9d" &&
    formSend.statusId !== "35rt3s0c-7bsa-46c7-a203-c16a4fsc3004" || formSend.statusId ==='032fd5ba-f634-469b-3c30-77a1gh63a918')
  }

const getCurrentLocation = (lat, lon) => {
  return axios
    .get(
      `https://map.technodreams.biz/reverse?lat=${lat}&lon=${lon}&accept-language=ua&format=json`
    )
    .then((res) => {
      var address = ""
      let resource =res.data.address;
      if (resource.amenity?.length>0){
        address += `${resource.amenity}, `;
        }
      if (resource.city?.length>0){
      address += `${resource.city}`;
      }
      if (!resource.city&& resource.state?.length>0){
      address += `${resource.state}, `;
      }
      if (resource.town?.length>0){
      address += `${resource.town}`;
      }
      if (resource.village?.length>0){
        address += `${resource.village}`;
        }
    
      if (resource.road?.length>0){
      address += `, ${resource.road}`;
      }
      if (resource.building?.length>0){
        address += ` ${resource.building}`;
        }

      if (resource.house_number?.length>0){
      address += ` ${resource.house_number}`;
      }
      setAdress(address);
      return;
    })
    .catch((e) => {
      console.log(e);
      setAdress(lat.toFixed(5) +' - '+ lon.toFixed(5));
      return;
    });
};

  useEffect(() => {
    if(formSend?.routePoints?.length>0 &&  formSend?.routePoints[0].location?.length>0 &&  formSend?.routePoints[0].location[0])
    getCurrentLocation(formSend.routePoints[0].location[0],formSend.routePoints[0].location[1])
  }, [formSend.routePoints]);

  const date = getDate(null, "FORMDATE_WITH_TIME");

  const {
    msg: errorMessage,
    errorFiled,
    isError,
    isServerError,
  } = useResponceChecker(formRes);


  const saveEditStart =(e)=>{
    let data = formSend;
    data.startsAt=e.target.value.replace("T", " ");
    let newData= new Date(e.target.value)
    
    let hours=newData.getHours();
    newData.setHours(hours+2);
    data.finishesAt= getDate(newData,'FORMDATE_WITH_TIME','-').replace("T", " ");
    console.log(data.startsAt)
    console.log(data.finishesAt)
    
    setFormSend(data);
    saveEdit(data);
  }

  const saveEdit = useCallback(
    (data,func) => {
      if (clubId && clubId != 0) {

          if(formSend?.routePoints?.length>0 &&  formSend?.routePoints[0].location?.length>0 &&  formSend?.routePoints[0].location[0]){
            getCurrentLocation(formSend.routePoints[0].location[0],formSend.routePoints[0].location[1]);
          }
        if (data && data.id) {
          data.clubId = clubId;
          dispatch(editEvents(data)).then((res) =>
          { 
            if(func){
              getItem();
            }
             setFormRes(res.isFeild ? res : {})}
          );
        } else {
          formSend.clubId = clubId;
          dispatch(editEvents(formSend)).then((res) =>
          { 
            if(func){
              getItem();
            }
             setFormRes(res.isFeild ? res : {})}
          );
        }
      } else {
        dispatch(editEvents(data && data.id ? data : formSend)).then((res) =>
        {
          if(func){
            getItem();
          }
          setFormRes(res.isFeild ? res : {})
        }
        );
      }

     

    },
    [formSend]
  );

  useEffect(() => {
    if (isServerError) {
      setAlertModal(true);
      setAlertMessage(errorMessage);
    }
  }, [isServerError]);

  const customFunc = (e) => {
    const data = e;
    if(data.length>2040){
      setErrorCus(true)
    }else{
      setErrorCus(false)
      setData(data);
    }
  };

  const customSave = (e) => {
    let resp = { ...formSend };
    resp.fullDescription = e;
    setFormSend({ ...formSend, fullDescription:  resp.fullDescription });
    saveEdit(resp);
  };

  const getItem = () => {
    // setReady(false);
    if(currentUser.roleId==='09efbeb2-f45a-418d-89b0-b2a4c37f6122'){
      dispatch(getParksCoordinatorList())
      .then((res) => {

      })
      .catch((e) => {
        console.log(e);
      });
    }
   
    dispatch(getEventsItem({ id: currentId }))
      .then((res) => {
        if(!res){
          setFormSend({});
          setAlertMessage('Такого заходу не знайдено')
          setAlertModal('true')
        }else{
          setFormSend(res);
        }
        setReady(true);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const changeStatus = (e) => {
    let data = {};
    data.type = e.target.accessKey;
    data.id = currentId;
    
    if(data.type==='publish' && !formSend.originalId && formSend.typeId==='e58e5c86-5ca7-412f-94f0-88effd1a45a8' && (new Date(formSend.startsAt)- new Date())<300000){
      setAlertMessage('Конференція має початись не раніше ніж через 5 хвилин')
      setAlertModal(true);
      return;
    }else{
      dispatch(addEvents(data)).then((res) => {
        if(data.type==='duplicate' && res?.id){
          
          navigate(`/eventsItem/${clubId?.length>0?clubId+'/'+res?.id:res?.id}`);
          setReady(false);
        }else if(data.type==='publish' && formSend.originalId?.length>0 ){
            
          navigate(`/eventsItem/${clubId?.length>0?clubId+'/'+formSend?.originalId:formSend?.originalId}`);
          setReady(false);
        }else if(res?.id){
          getItem();
        }else{
          setReady(false);setAlertModal(true);
          setAlertMessage('Виникла помилка, спробуйте пізніше')
        }
      });
    }
    // navigate(clubId ? `/fc/${clubId}/events` : "/events");
  };
  const selectClick = (e) => {
    
    if (e.target.id === select) {
      setSelect("");
    } else {
      setSelect(e.target.id);
    }
  };

  const selectChooseSportGround =(e)=>{
    let data = { ...formSend, [e.target.accessKey]: e.target.id };
    // let data2 = { ...form, [e.target.accessKey]: e.target.id };
    if(e.target.accessKey ==='sportsgroundId'){
      let resObj = { type: 0 };
      resObj.location= [e.target.title.split('/')[0],e.target.title.split('/')[1]];
      resObj.pointIndex = 0;
      data.routePoints=[resObj];
      // data.routePoints.push(resObj)
    }    
    setFormSend(data);
    setSelect("");
    saveEdit(data,true);

  }

  const selectChoose = (e) => {
    let data = { ...formSend, [e.target.accessKey]: e.target.id };
    if (e.target.accessKey === "categoryId") {
      data.subcategoryId = "";
    }
    setFormSend(data);
    setSelect("");
    saveEdit(data);
  };


  const changeHandler = (e) => {
    setFormSend({ ...formSend, [e.target.id]: e.target.value });
  };


  const changeDateHandler = (e) => {
    let data= new Date(e.target.value);
    

    setFormSend({
      ...formSend,
      [e.target.id]: e.target.value.replace("T", " ")
    });
    // console.log(e.target.value)
  };

  

  const deleteClick = (e) => {
    dispatch(deleteEvents({ id: currentId }))
      .then((res) => {
         
        if(clubId?.length>0 || formSend.clubId?.length>0){
          navigate(`/fc/${clubId?.length>0?clubId:formSend.clubId}/events`);
        }else{
          navigate("/events");
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    if (!ready) {
      getItem();
    }
  }, [ready]);

  if (!ready || !fullList.OwnershipTypes || !listId.list) {
    return (
      <div style={{ position: "relative" }}>
        {" "}
        <div style={{ position: "absolute", top: "50%", left: "50%" }}>
          <Loader />
        </div>{" "}
      </div>
    );
  }
  if (ready && listId.list && fullList.OwnershipTypes) {
    return (
      <>
        <h1>Картка заходу

        <div className="headerClubSub">
            Статус: <span>{listId.list[formSend.statusId]}</span>
            <span className="currentStatusDivider"></span>
            {formSend.originalId && (
              <a
                onClick={(e) => {
                  e.preventDefault();
                  navigate("/eventsItem/" + formSend.originalId);
                  setReady(false);
                }}
              >
                Не редагований оригінал заходу
              </a>
            )}
          </div>
          
        </h1>
        {/* <h2 style={{ margin: "0" }}>
          Статус заходу: {listId.list[formSend.statusId]} 
        </h2> */}
        <div className="menuFunctionLine">
          <div className="actionButtonsBlock">
            <button
              className="secondaryButtonIconTextDefault"
              onClick={() =>
                navigate(clubId?.length>1?`/fc/${clubId}/events` : "/events")
              }
            >
              <svg>
                <use href="/images/icon24ArrowLeft.svg#icon24" />
              </svg>
              Назад
            </button>

            {
              formSend.statusId === "35rt3s0c-7bsa-46c7-a203-c16a4fsc3004" && 
              <button
                className="primaryButtonIconTextDefault"
                accessKey="publish"
                onClick={changeStatus}
                disabled={ !formSend.title?.length>0 || !formSend.fullDescription?.length>0 || !formSend.shortDescription?.length>0 || !formSend.startsAt?.length>0 || !formSend.typeId?.length>0|| errorCus || !(formSend.sportsground?.id || formSend.routePoints.length>0 || formSend.typeId=='e58e5c86-5ca7-412f-94f0-88effd1a45a8')}
              >
                <svg accessKey="publish">
                  <use
                    accessKey="publish"
                    href="/images/icon24CheckCircle.svg#icon24"
                  />
                </svg>
                Опублікувати
              </button>
            }
            {/* {form.statusId === "35rt3s0c-7bsa-46c7-a203-c16a4fsc3004" &&
              !clubId && (
                <button
                  className="primaryButtonIconTextDefault"
                  accessKey="to-moderation"
                  disabled={ !form.title?.length>0 || !form.fullDescription?.length>0 || !form.shortDescription?.length>0 || !form.startsAt?.length>0 || !form.typeId?.length>0|| errorCus}
                  onClick={changeStatus}
                >
                  <svg accessKey="to-moderation">
                    <use
                      accessKey="to-moderation"
                      href="/images/icon24CheckCircle.svg#icon24"
                    />
                  </svg>
                  На модерацію
                </button>
              )} */}
            {(formSend.statusId === "99ca4f35-623c-4e0d-b9ef-f70gs23481f3" || (formSend.statusId ==='032fd5ba-f634-469b-3c30-77a1gh63a918' && currentUser.id===formSend.createdBy.id))  && formSend.holdingStatusId !=='0q8a6xc0-1nb4-1pr4-h5at-4sw3m0l387yp' && (
              <button
                accessKey="duplicate"
                className="secondaryButtonIconTextDefault"
                onClick={(e) => changeStatus(e, clubId)}
              >
                <svg accessKey="duplicate">
                  <use
                    accessKey="duplicate"
                    href="/images/icon24ArrowReturn.svg#icon24"
                  />
                </svg>
                Редагувати
              </button>
            )}
            {formSend.statusId === "032fd5ba-f634-469b-3c30-77a1gh63a918" && (
              <button
                accessKey="deactivate"
                className="secondaryButtonIconTextDefault"
                onClick={(e) => changeStatus(e, clubId)}
              >
                <svg accessKey="deactivate">
                  <use
                    accessKey="deactivate"
                    href="/images/icon24ArrowReturn.svg#icon24"
                  />
                </svg>
                Деактивувати
              </button>
            )}
            {formSend.statusId ==='d3db15d3-3207-4e1d-b87d-f2ec25aeba23' &&
                currentUser.roleId ===
                  "5dcf0363-d171-45db-9280-cb337ca5e101"&& (
                <button
                  accessKey="to-draft"
                  className="secondaryButtonIconTextDefault"
                  onClick={changeStatus}
                >
                  <svg accessKey="to-draft">
                    <use
                      accessKey="to-draft"
                      href="/images/icon24ArrowReturn.svg#icon24"
                    />
                  </svg>
                  Повернути до чорнетки
                </button>
              )}
            {formSend.statusId !== "032fd5ba-f634-469b-3c30-77a1gh63a918" && (
              <button
                className="secondaryButtonIconTextDefault"
                onClick={deleteClick}
              >
                <svg>
                  <use href="/images/icon24Trash.svg#icon24" />
                </svg>
                {formSend?.originalId?.length>0?'Скасувати редагування заходу':'Видалити'}
              </button>
            )}
             <div className="apCheckBoxWithLabel">
              <input
                id="closedGroundSwiper"
                disabled={
                  formSend.statusId === "66e2ec16-03a1-4367-834a-d6b87ee709bd"
                }
                checked={formSend.isPersonalInfo}
                type="checkbox"
                className="apCheckbox"
                onChange={() => setFormSend({ ...formSend, ["isPersonalInfo"]: !formSend.isPersonalInfo })}
                onBlur={saveEdit}
              />
              <label htmlFor="closedGroundSwiper">Запросити персональні дані</label>
            </div>
          </div>
        </div>

        <div className="groundPropertiesHeader">
          {alertModal && (
            <InfoWindow
              setModal={setAlertModal}
              message={alertMessage}
              buttonMsg={isServerError && "Оновити"}
              setClick={() => window.location.reload()}
            ></InfoWindow>
          )}

          {modal && (
            <CategoryModal
              setModal={setModal}
              form={formSend}
              sendForm={formSend}
              setSendForm={setFormSend}
              saveEdit={saveEdit}
              setForm={setFormSend}
            />
          )}
          <h2>Візуальна частина</h2>
        </div>
        <div className="videoCard2Columns">
          <div className="videoCardCoverContainer">
            <h3>Обкладинка Заходу</h3>
            <FileUploadAndCrop options={options} size='10' saveEdit={saveEdit} form={formSend} setForm={setFormSend} image={formSend.mainPhoto} setAlertMessage={setAlertMessage} setAlertModal={setAlertModal} />
          </div>
          <div className="videoCardNameDescriptionContainer">
            <h3>Назва та опис</h3>
            <div
              className={
                formSend.statusId !== "384fc74a-254e-47d7-9fcc-cd608d7e5f9d"
                  ? "apInputText apDisabled"
                  : `${formSend.title ? "apInputText apFilled" : "apInputText"}`
              }
            >
              <label htmlFor="title">Назва*</label>
              <input
                id="title"
                type="text"
                maxLength={60}
                disabled={
                  formSend.statusId !== "384fc74a-254e-47d7-9fcc-cd608d7e5f9d" &&
                  formSend.statusId !== "35rt3s0c-7bsa-46c7-a203-c16a4fsc3004"
                }
                value={formSend.title}
                onChange={changeHandler}
                onBlur={saveEdit}
              />
            </div>
            <div
              className={
                formSend.statusId !== "384fc74a-254e-47d7-9fcc-cd608d7e5f9d"
                  ? "apInputText apDisabled"
                  : `${
                    formSend.shortDescription
                        ? "apInputText apFilled"
                        : "apInputText"
                    }`
              }
            >
              <label htmlFor="shortDescription">Короткий опис*</label>
              <input
                id="shortDescription"
                type="text"
                disabled={
                  formSend.statusId !== "384fc74a-254e-47d7-9fcc-cd608d7e5f9d" &&
                  formSend.statusId !== "35rt3s0c-7bsa-46c7-a203-c16a4fsc3004"
                }
                maxLength={255}
                value={formSend.shortDescription}
                onChange={changeHandler}
                onBlur={saveEdit}
              />
            </div>

            {/* <div className={form.statusId==="66e2ec16-03a1-4367-834a-d6b87ee709bd"?"apTextarea apDisabled":`${form.fullDescription?"apTextarea apFilled":'apTextarea'}`}>
                        // <label htmlFor="fullDescription">Повний опис</label>
                        <textarea id="fullDescription" disabled={form.statusId==="66e2ec16-03a1-4367-834a-d6b87ee709bd"} maxLength='2000' value={form.fullDescription} onChange={changeHandler} onBlur={saveEdit}/>
                        <div>
                            <span />
                            <span>{form.fullDescription ? form.fullDescription.length:0}/2000</span>
                        </div>
                        </div> */}
            <div className="editorWidth">
              <label htmlFor="fullDescription">Повний опис*</label>
              <Editor
                id="fullDescription"
                onChange={customFunc}
                onBlur={customSave}
                value={formSend.fullDescription || ''}
                maxLength={2047}
                disabled={
                  formSend.statusId !== "384fc74a-254e-47d7-9fcc-cd608d7e5f9d" &&
                  formSend.statusId !== "35rt3s0c-7bsa-46c7-a203-c16a4fsc3004"
                }
              />
              {errorCus && <span className="apError">Не більше 2000 символів</span>}
              {/* {(form.statusId === "384fc74a-254e-47d7-9fcc-cd608d7e5f9d" ||
                form.statusId === "35rt3s0c-7bsa-46c7-a203-c16a4fsc3004") && (
                <button
                  style={{ width: "fit-content" }}
                  className="secondaryButtonTextDefault"
                  onClick={customSave}
                >
                  Зберегти текст
                </button>
              )} */}
            </div>
          </div>
        </div>
        <div className="groundPropertiesHeader">
          <h2>Налаштування</h2>
        </div>
        <div className="videoCard2ColumnsShort">
          <div className="videoCardSettingsFirstColumn">
            <div
              className={`apInputText 
              ${
                (formSend.statusId !== "384fc74a-254e-47d7-9fcc-cd608d7e5f9d" &&
                  "apDisabled") ||
                ""
              } 
              ${(errorFiled === "startsAt" && "apError") || ""}
              `}
            >
              <label htmlFor="startsAt">Дата початку*</label>
              <input
                id="startsAt"
                min={date}
                type="datetime-local"
                disabled={
                  formSend.statusId !== "384fc74a-254e-47d7-9fcc-cd608d7e5f9d" &&
                  formSend.statusId !== "35rt3s0c-7bsa-46c7-a203-c16a4fsc3004"
                }
                value={formSend.startsAt?.replace(" ", "T")}
                onChange={changeDateHandler}
                onBlur={saveEditStart}
              />
              {errorFiled === "startsAt" && <span>{errorMessage}</span>}
            </div>
            <div
              className={`apInputText 
                ${
                  (formSend.statusId !== "384fc74a-254e-47d7-9fcc-cd608d7e5f9d" &&
                    "apDisabled") ||
                  ""
                } 
                
                ${(errorFiled === "finishesAt" && "apError") || ""}
                `}
            >
              <label htmlFor="finishesAt">Дата закінчення*</label>
              <input
                id="finishesAt"
                min={formSend.startsAt ?? date}
                type="datetime-local"
                disabled={
                  formSend.statusId !== "384fc74a-254e-47d7-9fcc-cd608d7e5f9d" &&
                  formSend.statusId !== "35rt3s0c-7bsa-46c7-a203-c16a4fsc3004"
                }
                value={formSend.finishesAt?.replace(" ", "T")}
                onChange={changeDateHandler}
                onBlur={saveEdit}
              />
              {errorFiled === "finishesAt" && <span>{errorMessage}</span>}
            </div>
            {
              // form.statusId === "384fc74a-254e-47d7-9fcc-cd608d7e5f9d" &&
              <div className="apDropDownList" onBlur={saveEdit}>
                <label htmlFor="typeId">Вибір типу заходу*</label>
                <div
                  id="typeId"
                  onClick={
                    formSend.statusId !== "384fc74a-254e-47d7-9fcc-cd608d7e5f9d" &&
                    formSend.statusId !== "35rt3s0c-7bsa-46c7-a203-c16a4fsc3004"
                      ? ""
                      : selectClick
                  }
                >
                  <input
                    id="typeId"
                    readOnly
                    type="text"
                    disabled={
                      formSend.statusId !==
                        "384fc74a-254e-47d7-9fcc-cd608d7e5f9d" &&
                        formSend.statusId !== "35rt3s0c-7bsa-46c7-a203-c16a4fsc3004"
                    }
                    value={
                      formSend.typeId?.length > 0 ? listId.list[formSend.typeId] : ""
                    }
                  />
                  <svg id="typeId">
                    <use href="/images/icon24DropDown.svg#icon24" />
                  </svg>
                </div>
                {select === "typeId" && (
                  <div className="apOptionsList">
                    {fullList.SportEventTypes.map((el) => {
                      return (
                        <div
                          id={el.id}
                          accessKey="typeId"
                          onClick={selectChoose}
                        >
                          {el.title}
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            }

            {(coordinatorsList?.items?.length>0 || formSend.sportsground?.title?.length > 0)&& formSend.typeId === "848e3121-4a2b-413d-8a8f-ebdd4ecf2840" && (
              <div className="apDropDownList" onBlur={saveEdit}>
                <label htmlFor="sportsgroundId">Вибір майданчика</label>
                <div
                  id="sportsgroundId"
                  onClick={
                    formSend.statusId !== "384fc74a-254e-47d7-9fcc-cd608d7e5f9d" &&
                    formSend.statusId !== "35rt3s0c-7bsa-46c7-a203-c16a4fsc3004"
                      ? "" 
                      : selectClick
                  }
                >
                  <input
                    id="sportsgroundId"
                    readOnly
                    type="text"
                    disabled={
                      formSend.statusId !==
                        "384fc74a-254e-47d7-9fcc-cd608d7e5f9d" &&
                        formSend.statusId !== "35rt3s0c-7bsa-46c7-a203-c16a4fsc3004"
                    }
                    value={
                      formSend.sportsground?.title?.length > 0
                        ? formSend.sportsground?.title
                        : coordinatorsList?.items?.length>0 && formSend.sportsgroundId ? coordinatorsList.items.map((el) =>{
                            if (el.id === formSend.sportsgroundId) {
                              return el.title;
                            }else{return}
                          }):'Не вибрано майданчик'
                    }
                  />
                  <svg id="sportsgroundId">
                    <use href="/images/icon24DropDown.svg#icon24" />
                  </svg>
                </div>
                {select === "sportsgroundId" && (
                  <div className="apOptionsList">
                    {coordinatorsList?.items?.map((el) => {
                      return (
                        <div
                          id={el.id}
                          accessKey="sportsgroundId"
                          title={el.location[0]+'/'+el.location[1]}
                          onClick={selectChooseSportGround}
                        >
                          {el.title}
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            )}
            <div
              className={
                formSend.statusId !== "384fc74a-254e-47d7-9fcc-cd608d7e5f9d"
                  ? "apInputText apDisabled"
                  : `${
                    formSend.maxCountUser
                        ? "apInputText apFilled"
                        : "apInputText"
                    }`
              }
            >
              <label htmlFor="maxCountUser">Кількість учасників</label>
              <input
                id="maxCountUser"
                type="text"
                disabled={
                  formSend.statusId !== "384fc74a-254e-47d7-9fcc-cd608d7e5f9d" &&
                  formSend.statusId !== "35rt3s0c-7bsa-46c7-a203-c16a4fsc3004"
                }
                maxLength={255}
                value={formSend.maxCountUser}
                onChange={changeHandler}
                onBlur={saveEdit}
              />
            </div>
            {formSend.maxCountUser > 0 && <div
              className={
                formSend.statusId !== "384fc74a-254e-47d7-9fcc-cd608d7e5f9d"
                  ? "apInputText apDisabled"
                  : `${
                    formSend.errorText
                        ? "apInputText apFilled"
                        : "apInputText"
                    }`
              }
            >
              <label htmlFor="errorText">Повідомлення про відсутність вільних місць</label>
              <input
                id="errorText"
                type="text"
                disabled={
                  formSend.statusId !== "384fc74a-254e-47d7-9fcc-cd608d7e5f9d" &&
                  formSend.statusId !== "35rt3s0c-7bsa-46c7-a203-c16a4fsc3004"
                }
                maxLength={255}
                value={formSend.errorText}
                onChange={changeHandler}
                onBlur={saveEdit}
              />
            </div>}
          </div>
          <div className="videoCardSettingsRegions">
            <div className="profilePersonalDataHeaderWithButton">
              <h3>Список категорій</h3>
              {formSend.statusId !== "66e2ec16-03a1-4367-834a-d6b87ee709bd" && formSend.statusId !=='032fd5ba-f634-469b-3c30-77a1gh63a918' &&(
                <button
                  className="secondaryButtonIconDefault"
                  onClick={
                    formSend.statusId === "66e2ec16-03a1-4367-834a-d6b87ee709bd"
                      ? ""
                      : () => setModal(true)
                  }
                >
                  <svg>
                    <use href="/images/icon24Edit.svg#icon24"></use>
                  </svg>
                </button>
              )}
            </div>
            {formSend && formSend.categories && formSend.categories?.length > 0
              ? formSend.categories.map((el) => {
                  return <span>#{listId.list[el]}</span>;
                })
              : "Оберіть категорію"}
          </div>
          
        </div>
        {formSend.typeId === "bd09f36f-835c-49e4-88b8-4f835c1602ac" && (
          <>
            <div className="groundPropertiesHeader">
              <h2>Мапа</h2>
            </div>
            <Table
              formSend={formSend}
              // routePointx={formSend.routePoints}
              form={formSend}
              setForm={setFormSend}
              getCurrentLocation={getCurrentLocation}
              setFormSend={setFormSend}
            ></Table>
            <div
              className={
                 "apInputText"
              }
            >
              <label htmlFor="title">Адреса</label>
              <input
                id="title"
                type="text"
                disabled={
                 true
                }
                value={address}
                placeholder="Оберіть адресу на карті"
              />
            </div>
          </>
        )}
        {formSend.typeId === "848e3121-4a2b-413d-8a8f-ebdd4ecf2840" && !formSend.sportsground?.title &&(
          <>
            <div className="groundPropertiesHeader">
              <h2>Мапа</h2>
            </div>
            <DifMap
              formSend={formSend}
              getCurrentLocation={getCurrentLocation}
              form={formSend}
              disabled={formSend.statusId!=='35rt3s0c-7bsa-46c7-a203-c16a4fsc3004'}
              setForm={setFormSend}
              setFormSend={setFormSend}
            ></DifMap>
            <div
              className={
                 "apInputText"
              }
            >
              <label htmlFor="title">Адреса</label>
              <input
                id="title"
                type="text"
                disabled={
                 true
                }
                value={address}
                placeholder="Оберіть адресу на карті"
              />
            </div>

          </>
        )}
      </>
    );
  }
};

export default SportEventsItemPage;
