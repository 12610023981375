import axios from "axios";

import authHeader from "./auth-header";
import constants from '../../constants/constant.json';



const getTracks = (data)=>{
    if(!data.offset){
        data.offset=0;
    } if (!data.limit){
        data.limit=10
    }
    let filter='';
    if(data.filter&& Object.keys(data.filter).length ){
    for (const [key, value] of Object.entries(data.filter)) {
            filter+=`&filters[${key}]=${value}`
    }
    } 
    if(data.sort&& Object.keys(data.sort).length ){
        for (const [key, value] of Object.entries(data.sort)) {
                filter+=`&sort[${key}]=${value}`
        }
    }    
    return axios.get(constants.URL + `api/v1/track?offset=${data.offset}&limit=${data.limit}${filter}`,{headers: authHeader()}).then(response=>{
        return response.data
    }).catch((e)=>{
        console.log(e);
      });
}

const getTrackItem = (data)=>{
    return axios.get(constants.URL +'api/v1/track/'+data.id,{headers: authHeader()}).then(response=>{
        
        return response.data
    }).catch((e)=>{
        console.log(e);
      });;
}


const editTrack = (data)=>{
    return axios.put(constants.URL + 'api/v1/track/'+data.id,{...data},{ headers: authHeader()})
    .then((response) => { 
        return response.data})
    .catch((e)=>{
        console.log(e);
    });
}

const deleteTrack = (data)=>{
    return axios.delete(constants.URL + 'api/v1/track/' + data.id,{ headers: authHeader()})
    .then((response) => { 
        return response.data})
    .catch((e)=>{
        console.log(e);
    });
}



export default {
    getTracks,
    getTrackItem,
    editTrack,
    deleteTrack
}