import React, { useMemo, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { Swiper as Slider, SwiperSlide as Slide } from "swiper/react";
import { Navigation, Pagination, EffectFade } from "swiper";
import axios from 'axios';
import { getParksAll, getParksRoutesAll } from '../../../redux/actions/parks';
import Tile from '../../../components/Tile';

const ParksBlock =()=>{
    const [parks, setParks] = useState([]);
    const [routes, setRoutes] = useState([]);
    const [ready, setReady] = useState(false);
    const {coordinates } = useSelector((state) => state);
    const navigate = useNavigate();
    const { list } = useSelector((state) => state.listId);
    const [location, setLocation] = useState({});
    const dispatch = useDispatch();

    const openInNewTab = (url) => {
        window.open(url, "_blank", "noopener,noreferrer");
      };
    
      const getCurrentLocation = (lat, lon) => {
        return axios
          .get(
            `https://map.technodreams.biz/reverse?lat=${lat}&lon=${lon}&accept-language=ua&format=json`
          )
          .then((res) => {
            return res.data.address;
          })
          .catch((e) => {
            console.log(e);
          });
      };
    
    const myLocation = useMemo(() => {
        if (!location) return;
        const city = location.city;
        const village = location.village;
        if (!city && !village) return location.state;
        if (!city) return village;
        return city;
      }, [location]);

      const initFunc =()=>{
        let parksData = {
          offset: 0,
          limit: 5,
          sort: { distanceToPoint: "asc" },
          filter: {
            statusId: ["036735ba-f634-469b-ac30-77a164e3a918"],
            longitude: [coordinates.lng],
            latitude: [coordinates.lat],
            radius: ["20"],
          },
        };
        getCurrentLocation(coordinates.lat, coordinates.lng).then((res) => {
            setLocation(res);
            setReady(true);
          });
        dispatch(getParksRoutesAll(parksData)).then((res) => {

            let result = res.items.slice(0, 7);
            let result2 = res.itemsActive.slice(0, 7);
            setParks(result);
            setRoutes(result2);
            setReady(true);
        });
      }
    useEffect(() => {
        if(!ready){
            initFunc()
        }
      }, [ready]);


    return(  <section className="main-section main-parks">
    {parks && parks.length !== 0 && (
      <>
        <div className="headline withTitle">
          <h2>Найближчі парки:
          <p className="title">
            {coordinates.typeOfData ==='ip'&& `місцеположення визначено по IP адресі - ${myLocation || ''}`}
            {coordinates.typeOfData ==='geolocation'&& `місцеположення визначено по геолокації - ${myLocation || ''}`}
            {(coordinates.typeOfData ==='kyiv' || coordinates.typeOfData ==='') && 'місцеположення не визначено, використовуємо в якості адреси центр Києва'}
          </p>
          </h2>
         
          <button className='mobileHidden' onClick={() => navigate("/map")}>Всі парки</button>
        </div>
        <Slider
          modules={[Navigation, Pagination, EffectFade]}
          navigation
          pagination={{ clickable: true }}
          slidesPerView={1}
          spaceBetween={24}
          breakpoints={{
            640: {
              slidesPerView: 1,
            },
            720: {
              slidesPerView: 2,
            },
            991: {
              slidesPerView: 3,
            },
            1240: {
              slidesPerView: 3,
            },
          }}
        >
          {parks.map((el, index) => (
            <Slide key={index}>
              <div className="startPageItem"
              onClick={() => navigate(`/parkInfo/${el.id}`)}
              style={{
                backgroundImage: `linear-gradient(0deg, rgba(0,0,0,0.6) 10%, rgba(0,0,0,0) 70%), url('${
                  (el.photo !== null &&
                    el.photo.length !== 0 &&
                    el.photo) ||
                  "https://ap.sportforall.gov.ua/api/v1/uploads/b34604xc-02qb-46bb-9e91-fc3ffrb92w41"
                  }')`,
              }}>
                  <div className="leftsideInfoStartPage">

                    <div className="leftsideInfoStartPageHeader"> {el.title}</div>
                    <div className="leftsideInfoStartPageSubText">{list[el.typeId]}</div>
                  </div>
                 {<div  className="rightSideInfoStartPage" onClick={() =>
                          openInNewTab(
                            `https://www.google.com.ua/maps/place/${el.location[0]}+${el.location[1]}`
                          )
                        }>
                      {el.distanceToPoint?.toFixed(1)} км
                  </div>}
              </div>
            </Slide>
          ))}
        </Slider>
        <button className='mobileShow' onClick={() => navigate("/map")}>Всі парки</button>

        <div className="headline withTitle">
          <h2>Найближчі маршрути:
          <p className="title">
            {coordinates.typeOfData ==='ip'&& `місцеположення визначено по IP адресі - ${myLocation || ''}`}
            {coordinates.typeOfData ==='geolocation'&& `місцеположення визначено по геолокації - ${myLocation || ''}`}
            {(coordinates.typeOfData ==='kyiv' || coordinates.typeOfData ==='') && 'місцеположення не визначено, використовуємо в якості адреси центр Києва'}
          </p>
          </h2>
         
          <button className='mobileHidden' onClick={() => navigate("/map")}>Всі маршрути</button>
        </div>
        <Slider
          modules={[Navigation, Pagination, EffectFade]}
          navigation
          pagination={{ clickable: true }}
          slidesPerView={1}
          spaceBetween={24}
          breakpoints={{
            640: {
              slidesPerView: 1,
            },
            720: {
              slidesPerView: 2,
            },
            991: {
              slidesPerView: 3,
            },
            1240: {
              slidesPerView: 3,
            },
          }}
        >
          {routes.map((el, index) => (
            <Slide key={index}>
              <Tile
                  key={index}
                  type={"Route"}
                  el={el}
                //   nav={
                    // <>
                    //   <div className="buttonWrap">
                    //     <button
                    //       className="primaryButtonTextDefault"
                    //       onClick={() =>
                    //         openInNewTab(
                    //           `https://www.google.com.ua/maps/place/${el.location[0]}+${el.location[1]}`
                    //         )
                    //       }
                    //     >
                    //       Прокласти маршрут
                    //     </button>
                    //   </div>
                    //   <div className="buttonWrap">
                    //     <button
                    //       className="secondaryButtonTextDefault"
                    //       onClick={() => navigator(`/routeInfo/${el.id}`)}
                    //     >
                    //       Інформація
                    //     </button>
                    //   </div>
                    // </>x
                //   }
                  key={index}
                />
            </Slide>
          ))}
        </Slider>
        <button className='mobileShow' onClick={() => navigate("/map")}>Всі маршрути</button>

      </>
    )}
  </section>
)

}

export default ParksBlock;
