import React, { useState } from "react";
import { Icon } from "../Icon";

const Star = ({ selected, onClick }) => (
  <div className={selected ? "star selected" : "star"} onClick={onClick}>
    {/* &#9733; */}
    {selected?<Icon type='starFill'></Icon>:<Icon type='star'></Icon>}
  </div>
);

const RatingStar = ({ defaultRating, onChange,type }) => {
  const [rating, setRating] = useState(defaultRating);

  const handleClick = (value) => {
    setRating(value);
    onChange(value,type);
  };

  return (
    <div className="ratingList">
      {[...Array(5)].map((_, index) => (
        <Star
          key={index}
          selected={index < rating}
          onClick={() => handleClick(index + 1)}
        />
      ))}
    </div>
  );
};

export default RatingStar;