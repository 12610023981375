import React, { useCallback, useEffect, useState } from "react";
import { useMemo } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { Form, useNavigate } from "react-router-dom";
import ProfilePhotoUploader from "../../components/FileUploadAndCrop/FileUploadAndCrop";
import InfoWindow from "../../components/InfoWindow/InfoWindow";
import Loader from "../../components/Loader/Loader";
import Tile from "../../components/Tile";
import getDate from "../../helpers/getDate";
import setPhoneMask from "../../helpers/setPhoneMask";
import { logout } from "../../redux/actions/auth";
import { uploadFile } from "../../redux/actions/file";
import { getParksCoordinatorList } from "../../redux/actions/parks";
import {
  deactivateUser,
  applyForCoordinator,
  deleteUser,
  editRole,
  getCurrentUser,
  getUser,
  updateUser,
  updateTrainer,
  coordinatorRepor,
} from "../../redux/actions/users";
import EmailModal from "./EmailModal/EmailModal";
import PasswordModal from "./PasswordModal/PasswordModal";
import PhoneModal from "./PhoneModal/PhoneModal";
import RegionModal from "./RegionModal/RegionModal";



let options={
  uploadType:'avatar',
  initialAspectRatio:16 / 10,
  aspectRatio: 1,
  autoCropArea:1,
  height:'176px',
  width:'176px',
  size:3,
  type:'photo',
  dragAbleZone:false
}

const ProfilePageEdit = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [phone, setPhone] = useState(false);
  const [email, setEmail] = useState(false);
  const [check, setCheck] = useState(false);
  const [password, setPassword] = useState(false);
  const { user } = useSelector((state) => state.auth);
  const [selected, setSelected] = useState("");
  const currentId = useParams().id;
  const dispatch = useDispatch();
  const { current } = useSelector((state) => state.users);
  const { fullList } = useSelector((state) => state);
  const { currentUser } = useSelector((state) => state.currentUser);
  const { list } = useSelector((state) => state.listId);
  const [deleteModal, setDeleteModal] = useState(false);
  const [changedRole, setChangedRole] = useState(false);
  const [changedTrainer, setChangedTrainer] = useState(false);
  const [form, setForm] = useState({});
  const [ready, setReady] = useState(false);
  const [modal, setModal] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertModal, setAlertModal] = useState(false);
  const [error, setError] = useState({});
  const [actions, setActions] = useState([]);
  const [deleteTrainerModal, setDeleteTrainerModal] = useState(false);  

  const openInNewTab = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  const getUs = useCallback(() => {
    dispatch(getUser(currentId)).then((res) => {
      console.log(res);
      setForm({ ...res });
      setReady(true);
    });
  }, []);

  const getMe = () => {
    dispatch(getCurrentUser(user.payload.id)).then((res) => {
      setForm(res);
      setReady(true);
    });
  };
  const changeRoleClick = () => {
    dispatch(logout());
    window.location.reload();
  };

  const getActions = () => {
    setReady(false);
    dispatch(getParksCoordinatorList(currentUser))
      .then((res) => {
        setActions(res);
        setReady(true);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const saveEdit = (data) => {
    if (Object.keys(error).length === 0) {
      dispatch(updateUser(data.id && data.nickname ? data : form)).then(
        (res) => {
          if (res.errors) {
            setError({ ...error, [res.errors[0].param]: res.errors[0].msg });
          } else {
            initialFunction();
          }
        }
      );
    }
  };
  const changeState =(event)=>{
    event.preventDefault();
    let data=form;
    if(data[event.target.id]==1){
      data[event.target.id]=0;
    }else{
      data[event.target.id]= 1;
    }
    setForm({...data});
    saveEdit(data);
  }

  const coordinatorReportClick =(e)=>{
    let data = { id: currentId,
      isCoordinatorReport: !form.isCoordinatorReport
    };
    dispatch(coordinatorRepor(data)).then((res) => {
      initialFunction();
    });
  }
 
  const deactivateClick = (e) => {
    let data = { id: currentId };
    if (e.target.checked === true) {
      data.type = "deactivate";
    } else if (e.target.checked === false) {
      data.type = "activate";
    }
    dispatch(deactivateUser(data)).then((res) => {
      initialFunction();
    });
  };
  const deleteClick = () => {
    setDeleteModal(true);
  };
  const deleteSubmit = () => {
    let data = { id: currentId };
    dispatch(deleteUser(data)).then((res) => {
      if(currentUser.id===currentId){
        setDeleteModal(false);
        dispatch(logout());
        navigate('/start');
        window.location.reload();
      }else{
        setDeleteModal(false);
        navigate("/users");
      }

    });
  };

  const selectRole = (e) => {
    let inf = {};
    inf.id = currentId;
    inf.roleId = e.target.id;
    dispatch(editRole(inf)).then((res) => {
      setSelected("");
      initialFunction();
    });
  };
  const handleCoordClick = () => {
    dispatch(applyForCoordinator()).then((res) => {
      initialFunction();
      setChangedRole(true);
    });
  };


  const deleteTrainer=()=>{
    let data = {};
    data.id = currentId;
    data.trainer = '0';
    dispatch(updateTrainer(data)).then((res) => {
      initialFunction();
      setDeleteTrainerModal(false)
    });
  }

  const handleTrainerClick = (e) => {
    let data = {};
    data.id = currentId;
    data.trainer = e.target.id;
    dispatch(updateTrainer(data)).then((res) => {
      initialFunction();
      if(e.target.id=='1'){
        setChangedTrainer(true);
      }else{

      }
    });
  };

  const initialFunction = useCallback(() => {
    if (currentId) {
      getUs();
    } else {
      getMe();
    }
  }, [currentId]);

  const selectClick = (e) => {
    if (e.target.id == selected) {
      setSelected("");
    } else {
      setSelected(e.target.id);
    }
  };
  const setSelectClick = (e) => {
    let data = { ...form };
    data[e.target.accessKey] = e.target.id;
    
    if (e.target.accessKey === "regionId") {
      data.districtId = '';
    }
    setForm(data);
    saveEdit(data);
    setSelected("");
  };
  const changeHandler = (e) => {
    setError({})
    setForm({ ...form, [e.target.id]: e.target.value });
  };
  const changeHandlerCheck = (e) => {
    const textCheck = /^[бвгґджзклмнпрстфхцчшщйаеєиіїоуюяь\'-`]+$/;

    switch (e.target.id) {
      case "firstName":
      case "secondName":
      case "lastName":
        if (
          e.target.value.length < 1 ||
          textCheck.test(String(e.target.value).toLowerCase())
        ) {
          setError("");
        } else {
          setError({
            ...error,
            [e.target.id]: "Дозволено тільки літери українського алфавіту",
          });
        }
        setForm({ ...form, [e.target.id]: e.target.value });
        break;
      default:
        break;
    }
  };

  const editPhone = () => {
    setPhone(true);
  };
  const editMail = () => {
    setEmail(true);
  };
  const editPassword = () => {
    setPassword(true);
  };

  useEffect(() => {
    if (!ready) {
      initialFunction();
      getActions();
    }
    if (!currentId && !check) {
      setReady(false);
      setCheck(true);
    }
  }, [ready, currentId]);

  const coordinatorParams = useMemo(() => {
    return {
      status:
        currentUser &&
        currentUser.roleId === "09efbeb2-f45a-418d-89b0-b2a4c37f6122",

      data: actions,
    };
  }, [currentUser, actions]);

  if (!ready || !form || !list) {
    return (
      <div style={{ position: "relative" }}>
        {" "}
        <div style={{ position: "absolute", top: "50%", left: "50%" }}>
          <Loader />
        </div>{" "}
      </div>
    );
  }

  if (form && ready && list) {
    return (
      <>
        {alertModal && (
          <InfoWindow
            setModal={setAlertModal}
            message={alertMessage}
          ></InfoWindow>
        )}
        {modal && (
          <RegionModal
            saveEdit={saveEdit}
            setModal={setModal}
            form={form}
            setForm={setForm}
          />
        )}
        {phone && (
          <PhoneModal
            initialFunction={initialFunction}
            setModal={setPhone}
            form={form}
            setForm={setForm}
            setAlertModal={setAlertModal}
            setAlertMessage={setAlertMessage}
          />
        )}
        {email && (
          <EmailModal
            initialFunction={initialFunction}
            setModal={setEmail}
            form={form}
            setForm={setForm}
            setAlertModal={setAlertModal}
            setAlertMessage={setAlertMessage}
          />
        )}
        {password && (
          <PasswordModal setModal={setPassword} form={form} setForm={setForm} />
        )}
        {deleteModal && (
          <InfoWindow
            setClick={deleteSubmit}
            setModal={setDeleteModal}
            buttonMsg={"Видалити"}
            message={"Ви впевнені, що хочете видалити?"}
          ></InfoWindow>
        )}
        {deleteTrainerModal && (
          <InfoWindow
            setClick={deleteTrainer}
            setModal={setDeleteTrainerModal}
            buttonMsg={"Скасувати"}
            message={"Ви впевнені, що хочете видалити?"}
          ></InfoWindow>
        )}
        {changedRole && (
          <InfoWindow
            setClick={changeRoleClick}
            setModal={setChangedRole}
            buttonMsg={"Вихід"}
            message={"Після узгодження Вашої заявки, потрібно буде виконати повторну авторизацію."}
          ></InfoWindow>
        )}
        {changedTrainer && (
          <InfoWindow
            setClick={deleteTrainer}
            setModal={setChangedTrainer}
            message={"Ваша заявка успішно відправлена."}
            ></InfoWindow>
        )}



        {!currentId ? <h1>Особистий профіль</h1> : <h1>Профіль користувача</h1>}
        <div className="menuFunctionLine">
          <div className="actionButtonsBlock">
            {currentId && (
              <button
                className="secondaryButtonIconTextDefault"
                onClick={() => navigate(`/personalPage/${currentId}`)}
              >
                <svg>
                  <use href="/images/icon24ArrowLeft.svg#icon24" />
                </svg>
                Назад
              </button>
            )}
            {/* { !currentId &&<button className="primaryButtonIconTextDefault">
          <svg><use href="/images/icon24CheckCircle.svg#icon24" /></svg>
          На модерацію
        </button>} */}
            {currentId===currentUser.id && (
              <button
                className="secondaryButtonTextDefault"
                onClick={editPassword}
              >
                Встановити пароль
              </button>
            )}
           
            {currentId !==currentUser.id && (
              <div className="apCheckBoxWithLabel">
                <input
                  id="profileDeactivation"
                  type="checkbox"
                  checked={!form.isActive}
                  onClick={deactivateClick}
                  className="apCheckbox"
                />
                <label htmlFor="profileDeactivation">Деактивувати</label>
              </div>
            )}
            {currentId !==currentUser.id && form.roleId==='09efbeb2-f45a-418d-89b0-b2a4c37f6122' &&(
              <div className="apCheckBoxWithLabel">
                <input
                  id="profileDeactivation"
                  type="checkbox"
                  checked={form.isCoordinatorReport}
                  onClick={coordinatorReportClick}
                  className="apCheckbox"
                />
                <label htmlFor="profileDeactivation">Спеціаліст від області</label>
              </div>
            )}
          </div>



          
          <div className="actionTriggersBlock">
            {currentId && (
              <button
                className="secondaryButtonIconTextDefault"
                onClick={deleteClick}
              >
                <svg>
                  <use href="/images/icon24Trash.svg#icon24" />
                </svg>
                Видалити
              </button>
            )}
          </div>
        </div>
        <div className="pageContentHeaderDivider" />
        <div className="profileMainInfoRow">
          <div className="profilePhotoAndName">
           
            <ProfilePhotoUploader options={options} saveEdit={saveEdit} form={form} setForm={setForm} image={form.photo} setAlertMessage={setAlertMessage} setAlertModal={setAlertModal} />
            <div className="profileNameBlock">
              <h2>{form.lastName && form.firstName?<>{form.lastName} {form.firstName}</>:
              form.nickname}</h2>
              {list[form.roleId]}{form.isTrainer=='1' &&' - очікує на тренера'}{form.isTrainer=='2' &&' - тренер'}
              <div className="lastVisitBlock">
                <span>Останній візит:</span>
                {getDate(form.updatedAt,'dd/mm/yyyy hh:mm','.')}
              </div>
              {/* dodelat */}

              {(form.roleId === "a2c99acd-0014-4fb3-8274-ad6a842f50ac" ||
                form.roleId === "62fe0318-c64a-490c-859d-9d313eacbf41") &&
                form.id === currentUser.id && (
                  <>
                    <button
                      // disabled={!form.isPhoneVerified}
                      disabled={!form.phone?.length>0 || !form.email?.length>0}
                      className={
                        // !form.isPhoneVerified
                        !form.phone
                          ? "secondarySmallButtonTextDisabled"
                          : "secondarySmallButtonTextDefault"
                      }
                      onClick={handleCoordClick}
                    >
                      Стати координатором
                    </button>
                    <div className="apParkImagesHintArea">
                      <span>
                        Щоб подати заявку на роль координатора, потрібно
                        підтвердити номер телефону та пошту.
                      </span>
                      <svg>
                        <use href="/images/icon24Info.svg#icon24" />
                      </svg>
                    </div>
                  </>
                )}
                {/* {form.isTrainer=='0' &&
                    <button
                      className={"secondarySmallButtonTextDefault"}
                      onClick={handleTrainerClick}
                      id='1'
                    >
                      Стати тренером
                    </button>
                } */}
                {form.isTrainer!='2' && currentUser.roleId==='631db81f-fa07-42e2-b394-062c9be66b09' && 
                    <button
                      className={"secondarySmallButtonTextDefault"}
                      onClick={handleTrainerClick}
                      id='2'
                    >
                      Назначити тренера
                    </button>
                }
                {form.isTrainer=='1' &&
                    <button
                      className={"secondarySmallButtonTextDefault"}
                      onClick={()=>setDeleteTrainerModal(true)}
                      id='0'
                    >
                      Скасувати заявку на тренера
                    </button>
                }
                {form.isTrainer=='2' &&
                    <button
                      className={"secondarySmallButtonTextDefault"}
                      onClick={()=>setDeleteTrainerModal(true)}
                      id='0'
                    >
                      Скасувати тренера
                    </button>
                }
                
            </div>
          </div>
          <div className="profileContactsAndWorkArea">
            <div className="profileContacts">
              <h3>Контакти</h3>
              <div className="apInputTextIcon">
                <label htmlFor="phone">Телефон</label>
                <div>
                  <input
                    id="phone"
                    readOnly
                    type="tel"
                    value={
                      form.phone ||
                      currentUser.roleId ===
                        "631db81f-fa07-42e2-b394-062c9be66b09"
                        ? setPhoneMask(form.phone)
                        : ""
                    }
                  />
                  <svg onClick={editPhone}>
                    <use href="/images/icon24Edit.svg#icon24" />
                  </svg>
                </div>
              </div>
              <div className="apInputTextIcon">
                <label htmlFor="email">Пошта</label>
                <div>
                  <input id="email" type="text" readOnly value={form.email} />
                  <svg onClick={editMail}>
                    <use href="/images/icon24Edit.svg#icon24" />
                  </svg>
                </div>
              </div>
              {form && form.defaultPassword && (
                <div className="apInputTextIcon">
                  <label htmlFor="password">Пароль</label>
                  <div>
                    <input
                      id="password"
                      readOnly
                      type="text"
                      value={
                        currentId
                          ? "password-" + currentId.slice(-4)
                          : "password-" + form.id.slice(-4)
                      }
                    />
                  </div>
                </div>
              )}
            </div>

            <div className="profileWorkArea">
            <div className="apInputText apFilled">
              <label htmlFor="firstName">Ім’я</label>
              <input
                id="firstName"
                name="firstName"
                type="text"
                onChange={changeHandlerCheck}
                value={form.firstName}
                onBlur={saveEdit}
              />
              {error.firstName && <span>{error.firstName}</span>}
            </div>

            <div className="apInputText apFilled">
              <label htmlFor="lastName">Прізвищe</label>
              <input
                id="lastName"
                type="text"
                name="lastName"
                value={form.lastName}
                onChange={changeHandlerCheck}
                onBlur={saveEdit}
              />
              {error.lastName && <span>{error.lastName}</span>}
            </div>

            <div className="apInputText apFilled">
              <label htmlFor="secondName">По батькові</label>
              <input
                id="secondName"
                type="text"
                name="secondName"
                value={form.secondName}
                onChange={changeHandlerCheck}
                onBlur={saveEdit}
              />
              {error.secondName && <span>{error.secondName}</span>}
            </div>
            </div>
          </div>
        </div>

        {/* <h2>Детальна інформація</h2> */}
        <div className="profileDetailedInfoRow">
          <div className="profileWorkPositionColumn">
            <h3>Посада у проекті</h3>
            {currentUser.roleId === "631db81f-fa07-42e2-b394-062c9be66b09" &&
              currentId && (
                <div className="apDropDownList apFilled">
                  <label htmlFor="roleId">Роль у проекті</label>
                  <div id="roleId" onClick={selectClick}>
                    <input
                      id="roleId"
                      readOnly
                      type="text"
                      value={list[form.roleId]}
                    />
                    <svg>
                      <use href="/images/icon24DropDown.svg#icon24" />
                    </svg>
                  </div>
                  {selected == "roleId" && (
                    <div className="apOptionsList">
                      {fullList.UserRoles.map((el) => {
                        return (
                          <div
                            key={el.id}
                            className={el.id === form.roleId && "apActive"}
                            id={el.id}
                            accessKey="roleId"
                            onClick={selectRole}
                          >
                            {el.title}
                          </div>
                        );
                      })}
                    </div>
                  )}
                </div>
              )}
            <div className="apInputText">
              <label htmlFor="position">Посада, титул або ранг</label>
              <input
                id="position"
                type="text"
                name="position"
                value={form.position}
                onChange={changeHandler}
                onBlur={saveEdit}
              />
            </div>
            <div className="apInputText apDisabled">
              <label htmlFor="createdAt">Дата реєcтрації</label>
              <input
                id="createdAt"
                type="text"
                disabled
                value={getDate(form.createdAt,'dd/mm/yyyy','.')}
              />
            </div>
          </div>
          <div className="profileOwnRegionColumn">
            <h3>Особистий регіон</h3>
            <div className="apDropDownList apFilled">
              <label htmlFor="regionId">Регіон</label>
              <div id="regionId" onClick={selectClick}>
                <input
                  id="regionId"
                  readOnly
                  type="text"
                  value={list[form.regionId]}
                />
                <svg id="regionId" onClick={selectClick}>
                  <use
                    id="regionId"
                    onClick={selectClick}
                    href="/images/icon24DropDown.svg#icon24"
                  />
                </svg>
              </div>
              {selected == "regionId" && (
                <div className="apOptionsList">
                  {fullList.Regions.map((el) => {
                    return (
                      <div
                        accessKey="regionId"
                        id={el.id}
                        key={el.id}
                        onClick={setSelectClick}
                        tabIndex="0"
                        onBlur={saveEdit}
                        className={el.id === form.regionId ? "apActive" : ""}
                      >
                        {el.title}
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
            {form.regionId!=='ea0574da-fd62-413b-86eb-4289849abc4a'&&<div className="apDropDownList apFilled">
              <label htmlFor="districtId">Район</label>
              <div id="districtId" onClick={selectClick}>
                <input
                  id="districtId"
                  readOnly
                  type="text"
                  value={form.districtId ? list[form.districtId] : ""}
                />
                <svg id="districtId" onClick={selectClick}>
                  <use
                    id="districtId"
                    onClick={selectClick}
                    href="/images/icon24DropDown.svg#icon24"
                  />
                </svg>
              </div>
              {selected == "districtId" && (
                <div className="apOptionsList">
                  {fullList.Districts.map((el) => {
                    if (el.regionId == form.regionId) {
                      return (
                        <div
                          id={el.id}
                          key={el.id}
                          className={
                            el.id === form.districtId ? "apActive" : ""
                          }
                          onBlur={saveEdit}
                          accessKey="districtId"
                          onClick={setSelectClick}
                        >
                          {el.title}
                        </div>
                      );
                    }
                  })}
                </div>
              )}
            </div>}
            <div className="apInputText apFilled">
              <label htmlFor="city">Місто</label>
              <input
                id="city"
                type="text"
                value={form.city}
                onChange={changeHandler}
                onBlur={saveEdit}
              />
            </div>
          </div>
          <div className="profilePersonalDataColumn">
            <div className="profilePersonalDataHeaderWithButton">
              <h3>Особисті дані</h3>
              {/* <button className="secondarySmallButtonTextDefault">
            Редагувати
          </button> */}
            </div>
            {/* <div className="apInputText apFilled">
              <label htmlFor="firstName">Ім’я</label>
              <input
                id="firstName"
                name="firstName"
                type="text"
                onChange={changeHandlerCheck}
                value={form.firstName}
                onBlur={saveEdit}
              />
              {error.firstName && <span>{error.firstName}</span>}
            </div>

            <div className="apInputText apFilled">
              <label htmlFor="lastName">Прізвищe</label>
              <input
                id="lastName"
                type="text"
                name="lastName"
                value={form.lastName}
                onChange={changeHandlerCheck}
                onBlur={saveEdit}
              />
              {error.lastName && <span>{error.lastName}</span>}
            </div>

            <div className="apInputText apFilled">
              <label htmlFor="secondName">По батькові</label>
              <input
                id="secondName"
                type="text"
                name="secondName"
                value={form.secondName}
                onChange={changeHandlerCheck}
                onBlur={saveEdit}
              />
              {error.secondName && <span>{error.secondName}</span>}
            </div> */}

            <div className="apDropDownList apFilled">
              <label htmlFor="sex">Стать</label>
              <div id="sex" onClick={selectClick}>
                <input
                  id="sex"
                  readOnly
                  type="text"
                  value={
                    (form.sex === "male" && "Чоловіча") ||
                    (form.sex === "female" && "Жіноча") ||
                    ""
                  }
                />
                <svg id="sex" onClick={selectClick}>
                  <use
                    id="sex"
                    onClick={selectClick}
                    href="/images/icon24DropDown.svg#icon24"
                  />
                </svg>
                
              </div>
              {selected == "sex" && (
                <div className="apOptionsList">
                  <div
                    onClick={setSelectClick}
                    onBlur={saveEdit}
                    accessKey="sex"
                    id="male"
                    className={form.sex === "male" ? "apActive":''}
                  >
                    Чоловіча
                  </div>
                  <div
                    onClick={setSelectClick}
                    onBlur={saveEdit}
                    accessKey="sex"
                    id="female"
                    className={form.sex === "female" ? "apActive":''}
                  >
                    Жіноча
                  </div>
                </div>
              )}
            </div>
            <div className="apInputText apFilled">
              <label htmlFor="birthday">Дата народження</label>
              <input
                id="birthday"
                type="date"
                value={form.birthday}
                onChange={changeHandler}
                onBlur={saveEdit}
              />
              {error.birthday && <span>{error.birthday}</span>}
            </div>
            <div className="apInputText apFilled">
              <label htmlFor="weight">Вага (кг)</label>
              <input id="weight"  onChange={changeHandler} value={form.weight}  onBlur={saveEdit} type="text" name="weight" />
              {error.weight && <span>{error.weight}</span>}
              
            </div>
            <div className="apInputText apFilled" style={{flexDirection:'row', justifyContent:'space-between'}}>
            <button
              id='isInvalid'
              className={form.isInvalid?"primaryButtonTextDefault auth active":"primaryButtonTextDefault auth"}
              onClick={changeState}
            >
            Особа з інвалідністю
            </button>
            <button
              id='isVpo'
              className={form.isVpo?"primaryButtonTextDefault auth active":"primaryButtonTextDefault auth"}
              onClick={changeState}
            >
              ВПО
            </button>
            <button
              id='isVeteran'
              className={form.isVeteran?"primaryButtonTextDefault auth active":"primaryButtonTextDefault auth"}
              onClick={changeState}
            >
            Ветеран
            </button>
            </div>
            <div className="apDropDownList apFilled">
              <label htmlFor="buildId">Фізична форма</label>
              <div id="buildId" onClick={selectClick}>
                <input
                  id="buildId"
                  readOnly
                  type="text"
                  value={list[form.buildId]}
                />
                <svg id="buildId" onClick={selectClick}>
                  <use
                    id="buildId"
                    onClick={selectClick}
                    href="/images/icon24DropDown.svg#icon24"
                  />
                </svg>
              </div>
              {selected == "buildId" && (
                <div className="apOptionsList">
                  {fullList.Builds.map((el) => {
                    return (
                      <div
                        accessKey="buildId"
                        id={el.id}
                        key={el.id}
                        onClick={setSelectClick}
                        tabIndex="0"
                        onBlur={saveEdit}
                        className={el.id === form.buildId ? "apActive" : ""}
                      >
                        {el.title}
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
            <div className="apInputText apFilled">
              <label htmlFor="height">Зріст (см)</label>
              <input id="height"  onChange={changeHandler} value={form.height}   onBlur={saveEdit} type="text" name="height" />
              {error.height && <span>{error.height}</span>}
              
            </div>
            <div className="apDropDownList apFilled">
              <label htmlFor="hideBodyInfo">Дозволити доступ до особистих даних</label>
              <div id="hideBodyInfo" onClick={selectClick}>
                <input
                  id="hideBodyInfo"
                  readOnly
                  type="text"
                  value={
                    (form.hideBodyInfo == "0" && "Усім") ||
                    (form.hideBodyInfo == "1" && "Адміністрації та тренерам") ||
                    (form.hideBodyInfo == "2" && "Адміністрації") ||
                    ""
                  }
                />
                <svg id="hideBodyInfo" onClick={selectClick}>
                  <use
                    id="hideBodyInfo"
                    onClick={selectClick}
                    href="/images/icon24DropDown.svg#icon24"
                  />
                </svg>
              </div>
              {selected == "hideBodyInfo" && (
                <div className="apOptionsList">
                  <div
                    onClick={setSelectClick}
                    onBlur={saveEdit}
                    accessKey="hideBodyInfo"
                    id="0"
                    className={form.hideBodyInfo == "0"? "apActive":''}
                  >
                    Усім
                  </div>
                  <div
                    onClick={setSelectClick}
                    onBlur={saveEdit}
                    accessKey="hideBodyInfo"
                    id="1"
                    className={form.hideBodyInfo == "1" ? "apActive":''}
                  >
                    Адміністрації та тренерам
                  </div>
                  <div
                    onClick={setSelectClick}
                    onBlur={saveEdit}
                    accessKey="hideBodyInfo"
                    id="2"
                    className={form.hideBodyInfo == "2" ? "apActive":''}
                  >
                    Адміністрації
                  </div>
                </div>
              )}
            </div>
            <div className="apTextarea apFilled">
              <label htmlFor="aboutMe">Про мене:</label>
              <textarea name="aboutMe" id="aboutMe" value={form.aboutMe} onChange={changeHandler}  onBlur={saveEdit}></textarea>
              {error.aboutMe && <span>{error.aboutMe}</span>}
              
            </div>
            <div className="apTextarea apFilled">
              <label htmlFor="healthState">Стан здоров’я:</label>
              <textarea name="healthState" id="healthState" value={form.healthState} onChange={changeHandler}  onBlur={saveEdit}></textarea>
              {error.healthState && <span>{error.healthState}</span>}
              
            </div>
          </div>
        </div>

        <div>
          {coordinatorParams?.status && coordinatorParams?.data?.items && (
            <>
              <h2>Майданчики координатора</h2>
              <div className="parkItemCardList">
                {coordinatorParams.data.items.map(
                  ({ title, photo, id, range, location }, index) => (
                    <Tile
                      img={photo}
                      title={title}
                      type={"MAP"}
                      range={range}
                      key={index}
                      nav={
                        <>
                          <div className="buttonWrap">
                            <button
                              className="primaryButtonTextDefault"
                              onClick={() =>
                                openInNewTab(
                                  `https://www.google.com.ua/maps/place/${location[0]}+${location[1]}`
                                )
                              }
                            >
                              Маршрут
                            </button>
                          </div>
                          <div className="buttonWrap">
                            <button
                              className="secondaryButtonTextDefault"
                              onClick={() => navigate(`/parkInfo/${id}`)}
                            >
                              Інформація
                            </button>
                          </div>
                        </>
                      }
                    />
                  )
                )}
              </div>
            </>
          )}
        </div>
      </>
    );
  }
};

export default ProfilePageEdit;
