import React from "react";

const Petal =(props)=>{
  
  const classState ={
    'normal':' anim ',
    'disabled':'apDisabled anim ',
    'selected':'apSelected anim ',
    'subCategory':' anim ',
    'difficulty':' anim ',
    'clear':'apDisabled anim ',
    'none':'apAbsent anim '
  }

  

    return(
        <>
      {props.index==='06' && <a onClick={props.className==='subCategory'?props.subcategoryClick :props.className==='difficulty'?props.setDifClick:(props.className==='disabled'||props.className==='clear')?'':(props.className==='disabled'||props.className==='clear')?'':props.clickHandler} style={(props.className==='disabled' ||props.className==='clear'||props.className==='selected')?{'cursor':'unset'}:{}} id={props.className==='subCategory' ?props.data.id:'06'} accessKey="01">
        <svg  className={"petal6 "+classState[props.className]+`${props.className==='subCategory'?'apColorCategory'+props.category:''} ${props.className==='difficulty'?'apColorDiff01':''}`}viewBox="0 0 91.74 87.38" xmlns="http://www.w3.org/2000/svg">
          <path className="petalTextPath" d="M0.16 86.32c1.71,-36.25 21.24,-67.83 50.02,-86.19" />
          <path className="petalTextPath" id="petalTextPath1" d="M7.93 86.15c1.76,-33.38 19.79,-62.45 46.32,-79.41" />
          <path className="petalCanvas" d="M18.2 87.38c-1.24,0 -2.23,-1.02 -2.16,-2.26 1.77,-29.82 17.77,-55.82 41.31,-71.35 1.03,-0.68 2.42,-0.34 3.04,0.73l31.05 53.63c0.56,0.96 0.3,2.17 -0.52,2.9 -4.22,3.7 -7.23,8.74 -8.38,14.45 -0.22,1.08 -1.14,1.9 -2.24,1.9l-62.1 0z" />
          <text>
            <textPath href="#petalTextPath1" startOffset="50%" textAnchor="middle">
              {(props.className==='normal'|| props.className==='disabled' || props.className==='selected')&&props.data.title}
            </textPath>
            <textPath href="#petalTextPath1" startOffset="50%" textAnchor="middle">
              {props.className==='difficulty'&& 'Початківець'}
            </textPath>
          </text>
          {(props.className!=='clear' && props.className!=='normal' && props.className!=='disabled' && props.className!=='selected'&& props.className!=='difficulty') && <foreignObject x="31" y="40" width="44" height="44">
						<div>{props.data.title}</div>
					</foreignObject>}
          {props.className==='difficulty'&&
          <image x={34} y={43} width={35} height={35} href="/images/categoryDif1.svg"></image>
          // <foreignObject x="31" y="40" width="44" height="44">
					// 	<div>Майже не займаюсь спортом</div>
					// </foreignObject>
          }
          {(props.className==='normal'|| props.className==='disabled' || props.className==='selected')&& <image x={34} y={43} width={35} height={35} href="/images/category1.svg">
          </image>}
          </svg>
      </a>}
     {props.index==='02' && <a onClick={props.className==='subCategory'?props.subcategoryClick :props.className==='difficulty'?props.setDifClick:(props.className==='disabled'||props.className==='clear')?'':props.clickHandler} style={(props.className==='disabled' ||props.className==='clear'||props.className==='selected')?{'cursor':'unset'}:{}} id={props.className==='subCategory' ?props.data.id:'02'}  accessKey="03">
        <svg className={"petal2 "+classState[props.className]+`${props.className==='subCategory'?'apColorCategory'+props.category:''} ${props.className==='difficulty'?'apColorDiff03':''}`} viewBox="0 0 191.65 183.42" xmlns="http://www.w3.org/2000/svg">
          <path className="petalTextPath" d="M86.34 0.27c60.51,38.39 101.55,104.72 104.98,180.83" />
          <path className="petalTextPath" id="petalTextPath3" d="M78.11 14.25c55.69,35.53 93.52,96.59 96.98,166.69" />
          <path className="petalCanvas" d="M19.21 179.45c0.45,2.26 2.37,3.98 4.67,3.98l129.72 0c2.58,0 4.66,-2.15 4.5,-4.73 -3.7,-62.28 -37.1,-116.58 -86.28,-149.03 -2.16,-1.42 -5.05,-0.7 -6.34,1.53l-64.86 112.02c-1.16,2 -0.63,4.52 1.1,6.04 8.8,7.75 15.09,18.27 17.49,30.19z" />
          <text>
            <textPath href="#petalTextPath3" startOffset="50%" textAnchor="middle">
            {(props.className==='normal'|| props.className==='disabled' || props.className==='selected')&&props.data.title}
            </textPath>
            <textPath href="#petalTextPath3" startOffset="50%" textAnchor="middle">
              {props.className==='difficulty'&& 'Професіонал'}
            </textPath>
          </text>
          {(props.className!=='clear' && props.className!=='normal' && props.className!=='disabled' && props.className!=='selected'&& props.className!=='difficulty') && <foreignObject x="35" y="85" width="92" height="92">
            <div>{props.data.title}</div>
					</foreignObject>}
          {props.className==='difficulty'&& 
            <image x={47} y={92} width={73} height={73} href="/images/categoryDif2.svg"></image>
          // <foreignObject x="35" y="85" width="92" height="92">
					// 	<div>Регулярно займаюсь спортом</div>
					// </foreignObject>
          }
          {(props.className==='normal'|| props.className==='disabled' || props.className==='selected')&& <image x={47} y={92} width={73} height={73} href="/images/category3.svg">
          </image>}
          </svg> 
      </a>}
     {props.index==='01' && <a onClick={props.className==='subCategory'?props.subcategoryClick :props.className==='difficulty'?props.setDifClick:(props.className==='disabled'||props.className==='clear')?'':props.clickHandler} style={(props.className==='disabled' ||props.className==='clear'||props.className==='selected')?{'cursor':'unset'}:{}} id={props.className==='subCategory' ?props.data.id:'01'}  accessKey="02">
        <svg className={"petal1 "+classState[props.className]+`${props.className==='subCategory'?'apColorCategory'+props.category:''} ${props.className==='difficulty'?'apColorDiff02':''}`} viewBox="0 0 355.15 350.36" xmlns="http://www.w3.org/2000/svg">
          <path className="petalCanvas" d="M177.57 66.21c62.07,0 120.72,14.73 172.68,40.9 4.62,2.33 6.25,8.07 3.68,12.55l-129.78 225.99c-2.3,4.02 -7.24,5.65 -11.63,4.16 -10.98,-3.7 -22.73,-5.71 -34.95,-5.71 -12.21,0 -23.96,2.01 -34.94,5.71 -4.39,1.49 -9.33,-0.13 -11.64,-4.16l-129.77 -225.99c-2.58,-4.48 -0.94,-10.22 3.68,-12.55 51.96,-26.17 110.6,-40.9 172.67,-40.9z" />
          <path className="petalTextPath" id="petalTextPath2" d="M1.75 71.98c53.52,-24.57 113.07,-38.27 175.82,-38.27 62.37,0 121.59,13.54 174.86,37.83" />
          <path className="petalTextPath" d="M1.39 36.56c54.18,-22.8 113.71,-35.41 176.18,-35.41 61.7,0 120.52,12.3 174.16,34.57" />
          <text>
            <textPath href="#petalTextPath2" startOffset="50%" textAnchor="middle">
            {(props.className==='normal'|| props.className==='disabled' || props.className==='selected')&&props.data.title}
            </textPath>
            <textPath href="#petalTextPath2" startOffset="50%" textAnchor="middle">
              {props.className==='difficulty'&& 'Досвідчений'}
            </textPath>
          </text>
          {(props.className!=='clear' && props.className!=='normal' && props.className!=='disabled' && props.className!=='selected'&& props.className!=='difficulty') && <foreignObject x="85" y="96" width="186" height="186">
            <div>{props.data.title}</div>
          </foreignObject>}
          {props.className==='difficulty'&& 
            <image x={101} y={108} width={145} height={145} href="/images/categoryDif3.svg"></image>
          // <foreignObject x="85" y="96" width="186" height="186">
					// 	<div>Веду активний спосіб життя</div>
					// </foreignObject>
          }
          {(props.className==='normal'|| props.className==='disabled' || props.className==='selected')&& <image x={101} y={108} width={145} height={145} href="/images/category2.svg">
          </image>}</svg>
      </a>}
     {props.index==='05' && <a onClick={props.className==='subCategory'?props.subcategoryClick :props.className==='difficulty'?props.setDifClick:(props.className==='disabled'||props.className==='clear')?'':props.clickHandler} style={(props.className==='disabled' ||props.className==='clear'||props.className==='selected')?{'cursor':'unset'}:{}} id={props.className==='subCategory' ?props.data.id:'05'}>
        <svg className={"petal5 "+classState[props.className]+`${props.className==='subCategory'?'apColorCategory'+props.category:''}` } viewBox="0 0 91.48 87.86" xmlns="http://www.w3.org/2000/svg">
          <path className="petalTextPath" d="M50.01 87.73c-28.81,-18.42 -48.31,-50.12 -49.85,-86.46" />
          <path className="petalTextPath" id="petalTextPath4" d="M2.47 1.27c1.54,35.49 20.55,66.45 48.64,84.5" />
          <path className="petalCanvas" d="M82.31 1.9c-0.22,-1.08 -1.14,-1.9 -2.24,-1.9l-61.97 0c-1.24,0 -2.23,1.03 -2.15,2.26 1.77,29.75 17.73,55.7 41.22,71.2 1.03,0.68 2.41,0.34 3.03,-0.73l30.99 -53.52c0.55,-0.95 0.3,-2.16 -0.53,-2.89 -4.2,-3.7 -7.21,-8.72 -8.35,-14.42z" />
          <text>
            <textPath href="#petalTextPath4" startOffset="50%" textAnchor="middle">
            {(props.className==='normal'|| props.className==='disabled' || props.className==='selected')&&props.data.title}
            </textPath>
          </text>
          {(props.className!=='clear' && props.className!=='normal' && props.className!=='disabled' && props.className!=='selected') && <foreignObject x="31" y="3" width="44" height="44">
            <div>{props.data.title}</div>
					</foreignObject>}
          {(props.className==='normal'|| props.className==='disabled' || props.className==='selected')&& <image x={34} y={9} width={35} height={35} href="/images/category4.svg">
          </image>}</svg>
      </a>}
     {props.index==='03' && <a onClick={props.className==='subCategory'?props.subcategoryClick :props.className==='difficulty'?props.setDifClick:(props.className==='disabled'||props.className==='clear')?'':props.clickHandler} style={(props.className==='disabled' ||props.className==='clear'||props.className==='selected')?{'cursor':'unset'}:{}} id={props.className==='subCategory' ?props.data.id:'03'}>
        <svg className={"petal3 "+classState[props.className]+`${props.className==='subCategory'?'apColorCategory'+props.category:''}`} viewBox="0 0 190.26 182.45" xmlns="http://www.w3.org/2000/svg">
          <path className="petalTextPath" d="M189.94 2.98c-3.28,75.24 -43.54,140.88 -103.06,179.2" />
          <path className="petalTextPath" id="petalTextPath6" d="M84.35 178.27c58.11,-37.52 97.44,-101.63 100.77,-175.12" />
          <path className="petalCanvas" d="M1.71 33.95c-1.72,1.51 -2.25,4.02 -1.1,6l64.46 111.32c1.28,2.22 4.16,2.94 6.3,1.53 48.87,-32.25 82.07,-86.22 85.75,-148.1 0.15,-2.57 -1.91,-4.7 -4.48,-4.7l-128.9 0c-2.29,0 -4.2,1.71 -4.65,3.95 -2.38,11.85 -8.63,22.3 -17.38,30z" />
          <text>
            <textPath href="#petalTextPath6" startOffset="50%" textAnchor="middle">
            {(props.className==='normal'|| props.className==='disabled' || props.className==='selected')&&props.data.title}
            </textPath>
          </text>
          {(props.className!=='clear' && props.className!=='normal' && props.className!=='disabled' && props.className!=='selected') && <foreignObject x="35" y="7" width="92" height="92">
            <div>{props.data.title}</div>
					</foreignObject>}
          {(props.className==='normal'|| props.className==='disabled' || props.className==='selected')&& <image x={47} y={17} width={73} height={73} href="/images/category6.svg">
          </image>}</svg>
      </a>}
      {props.index==='04' &&<a onClick={props.className==='subCategory'?props.subcategoryClick :props.className==='difficulty'?props.setDifClick:(props.className==='disabled'||props.className==='clear')?'':props.clickHandler} style={(props.className==='disabled' ||props.className==='clear'||props.className==='selected')?{'cursor':'unset'}:{}} id={props.className==='subCategory' ?props.data.id:'04'}>
        <svg className={"petal4 "+classState[props.className]+`${props.className==='subCategory'?'apColorCategory'+props.category:''}`} viewBox="0 0 355.15 349.86" xmlns="http://www.w3.org/2000/svg">
          <path className="petalTextPath" d="M352.42 314.36c-53.82,22.45 -112.89,34.85 -174.85,34.85 -62,0 -121.1,-12.42 -174.96,-34.9" />
          <path className="petalTextPath" id="petalTextPath5" d="M3.75 304.95c53.41,22.66 112.15,35.19 173.82,35.19 62.17,0 121.37,-12.73 175.12,-35.74" />
          <path className="petalCanvas" d="M224.15 4.7c-2.3,-4.02 -7.24,-5.64 -11.63,-4.16 -10.98,3.71 -22.73,5.72 -34.94,5.72 -12.22,0 -23.97,-2.01 -34.95,-5.72 -4.39,-1.48 -9.33,0.14 -11.64,4.16l-129.77 226c-2.58,4.48 -0.94,10.22 3.68,12.54 51.96,26.17 110.6,40.9 172.67,40.9 62.07,0 120.72,-14.73 172.68,-40.9 4.62,-2.32 6.26,-8.06 3.68,-12.54l-129.78 -226z" />
          <text>
            <textPath href="#petalTextPath5" startOffset="50%" textAnchor="middle">
            {(props.className==='normal'|| props.className==='disabled' || props.className==='selected')&&props.data.title}
            </textPath>
          </text>
          {(props.className!=='clear' && props.className!=='normal' && props.className!=='disabled' && props.className!=='selected') && <foreignObject x="84" y="67" width="186" height="186">
            <div>{props.data.title}</div>
					</foreignObject>}
          {(props.className==='normal'|| props.className==='disabled' || props.className==='selected')&& <image x={101} y={98} width={145} height={145} href="/images/category5.svg">
          </image>}
          </svg>
      </a>}
      </>
    )
}

export default Petal;