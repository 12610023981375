import {
    CREATEVIDEOMESSAGES_SUCCESS,
    CREATEVIDEOMESSAGES_FAIL,
    GETVIDEOMESSAGES_SUCCESS,
    GETVIDEOMESSAGES_FAIL,
    GETVIDEOMESSAGESITEM_SUCCESS,
    GETVIDEOMESSAGESITEM_FAIL,
    EDITVIDEOMESSAGES_SUCCESS,
    EDITVIDEOMESSAGES_FAIL,
    DELETEVIDEOMESSAGES_SUCCESS,
    DELETEVIDEOMESSAGES_FAIL
} from "./types";


import VideosMessagesService from "../services/videosMessages.service"


export const addVideoMessages = (data)=> async(dispatch)=>{
    try {
        const res = await VideosMessagesService.addVideoMessages(data);
        dispatch({
          type: CREATEVIDEOMESSAGES_SUCCESS,
          item: res
        });
        return res
    }catch (error){
        dispatch({
            type: CREATEVIDEOMESSAGES_FAIL,
            item: {error: error.response},
          });
          const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
          return error.response;
    }
}


export const getVideosMessages = (data)=> async(dispatch)=>{
    try{
        const res = await VideosMessagesService.getVideosMessages(data);
        
        dispatch({
          type: GETVIDEOMESSAGES_SUCCESS,
          list: res,
        });
        
        return res
    }catch (error){
        
        dispatch({
            type: GETVIDEOMESSAGES_FAIL,
            list: {error: error.response},
          });
          const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
          return error.response
    }
}

export const editVideoMessages= (form)=>(dispatch)=>{
      
    return VideosMessagesService.editVideoMessages(form).then(
        (response)=>{
              
            dispatch({
                type:EDITVIDEOMESSAGES_SUCCESS,
                item:response.item
            });
              
            return response.item

        },
        (error)=>{
            dispatch({
                type: EDITVIDEOMESSAGES_FAIL,
              });
            console.log(error);
              return Promise.reject();
        }
    );
}



export const deleteVideoMessages= (data)=>(dispatch)=>{
      
    return VideosMessagesService.deleteVideoMessages(data).then(
        (response)=>{
            dispatch({
                type:DELETEVIDEOMESSAGES_SUCCESS,
            });
              
        },
        (error)=>{
            dispatch({
                type: DELETEVIDEOMESSAGES_FAIL,
              });
            console.log(error);
                 
              return Promise.reject();
        }
    );
}

export const getVideoMessagesItem = (data)=>(dispatch)=>{
    return VideosMessagesService.getVideoMessagesItem(data).then(
        (response)=>{
              
            dispatch({
                type:GETVIDEOMESSAGESITEM_SUCCESS,
                item:response,
            });
              
            return response

        },
        (error)=>{
            dispatch({
                type: GETVIDEOMESSAGESITEM_FAIL,
              });
            console.log(error);
                 
              return Promise.reject();
        }
    );

}