import {
    ADDPARKCOORDINATORS_SUCCESS,
    ADDPARKCOORDINATORS_FAIL,
    DELETEPARKCOORDINATORS_SUCCESS,
    DELETEPARKCOORDINATORS_FAIL,
    GETPARKCOORDINATORS_SUCCESS,
    GETPARKCOORDINATORS_FAIL,
} from "./types";


import ParksCoordinatorsService from "../services/parksCoordinators.service"

export const getParksCoordinators = (data)=> async(dispatch)=>{
    try{
        const res = await ParksCoordinatorsService.getParksCoordinators(data);
         
        dispatch({
          type: GETPARKCOORDINATORS_SUCCESS,
          list: res,
        });
        return res
    }catch (error){
        dispatch({
            type: GETPARKCOORDINATORS_FAIL,
            list: {error: error.response},
          });
          const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
          return error.response
    }
}
export const getParksCoordinatorsAll = (data)=> async(dispatch)=>{
    try{
        const res = await ParksCoordinatorsService.getParksCoordinatorsAll(data);
         
        dispatch({
          type: GETPARKCOORDINATORS_SUCCESS,
          list: res,
        });
        return res
    }catch (error){
        dispatch({
            type: GETPARKCOORDINATORS_FAIL,
            list: {error: error.response},
          });
          const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
          return error.response
    }
}

export const addParkCoordinatorsEmail = (data)=> async(dispatch)=>{
    try {
        const res = await ParksCoordinatorsService.addParkCoordinatorsEmail(data);
        dispatch({
          type: ADDPARKCOORDINATORS_SUCCESS,
          item: res
        });
        return res
    }catch (error){
        dispatch({
            type: ADDPARKCOORDINATORS_FAIL,
            item: {error: error.response},
          });
          const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
          return error.response;
    }
}

export const addParkCoordinatorsId = (data)=> async(dispatch)=>{
    try {
        const res = await ParksCoordinatorsService.addParkCoordinatorsId(data);
        dispatch({
          type: ADDPARKCOORDINATORS_SUCCESS,
          item: res
        });
        return res
    }catch (error){
        dispatch({
            type: ADDPARKCOORDINATORS_FAIL,
            item: {error: error.response},
          });
          const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
          return error.response;
    }
}

export const addParkCoordinatorsDeactivate = (data)=> async(dispatch)=>{
    try {
        const res = await ParksCoordinatorsService.addParkCoordinatorsDeactivate(data);
        dispatch({
          type: ADDPARKCOORDINATORS_SUCCESS,
          item: res
        });
        return res
    }catch (error){
        dispatch({
            type: ADDPARKCOORDINATORS_FAIL,
            item: {error: error.response},
          });
          const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
          return error.response;
    }
}
export const addParkCoordinatorsApprove = (data)=> async(dispatch)=>{
    try {
        const res = await ParksCoordinatorsService.addParkCoordinatorsApprove(data);
        dispatch({
          type: ADDPARKCOORDINATORS_SUCCESS,
          item: res
        });
        return res
    }catch (error){
        dispatch({
            type: ADDPARKCOORDINATORS_FAIL,
            item: {error: error.response},
          });
          const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
          return error.response;
    }
}


export const deleteParkCoordinators= (data)=>(dispatch)=>{
      
    return ParksCoordinatorsService.deleteParkCoordinators(data).then(
        (response)=>{
            dispatch({
                type:DELETEPARKCOORDINATORS_SUCCESS,
            });
        },
        (error)=>{
            dispatch({
                type: DELETEPARKCOORDINATORS_FAIL,
              });
            console.log(error);
              return Promise.reject();
        }
    );
}
