import React, { useEffect, useState } from "react";
import { Reorder, useDragControls } from "framer-motion";
import Map from "./Map";
import Loader from "../../../../components/Loader/Loader";
import QrComponent from "../../../SportEventsItemPage/Map/QrComponent";
import { Icon } from "../../../../components/Icon";
import { useSelector } from "react-redux";
import getDate from "../../../../helpers/getDate";

export const Table = (props) => {
  const [arrAll, setArr] = useState(props.formSend?.routePoints || []);
  const [ready, setReady] = useState(false);
  const [selected, setSelected] = useState('');

  const checkFunc=(arr)=>{
    let newArr =[];
    let counter=0;
    arr.forEach(el=>{
      newArr[el.pointIndex]=el;
    })
    newArr.forEach(el=>{
      if(el.type===0){
        counter++
      }
      el.counter=counter;
    })

    setArr(newArr)
    setReady(true)
  }

  const clickEvent=(e)=>{
    if(e===selected){
      setSelected('');
    }else{
      setSelected(e);
    }
  }

  useEffect(() => {
    if(!ready){
      checkFunc(props.formSend?.routePoints)
    }
  }, [props.formSend?.routePoints,arrAll,ready]);

  if(ready){
  return (
    <div className="apRoutingBlock">
      <Map arrAll={arrAll} selected={selected}></Map>
      <div className="apRoutingPointList">
        <h2 className="headline">Таблиця маршрутів</h2>

        <div className="apRoutingPoints">
          {arrAll?.length > 0 && (
            <ul>
              {arrAll.map((item, index) => {
                if(item.type=='1'){
                  return <></>
                }

                return(
                  <Item
                  key={item.location[0]}
                  index={item.counter}
                  pointIndex={index}
                  item={item}
                  value={item}
                  selected={selected}
                  onClickEvent={clickEvent}
                />
                )
                // в key нужно будет передать координаты точки, для уникальности
               
              })}
            </ul>
          )}
        </div>
      </div>
    </div>
  );}
  else{
    return(
      <Loader/>
    )
  }

};

const Item = (props) => {
  const controls = useDragControls();
  const [qrModal,setQrModal]=useState(false);

  const {currentUser}=useSelector(state=>state.currentUser)
  const {currentEvent}=useSelector(state=>state)

  // const [isChecked, setIsChecked] = useState(false);
  const qrClick =()=>{
    setQrModal(true);

  }
  return (
    <div
      className={"apFcRoutingPointItem" + (props.item.isPassed ? ' passed':'') +(props.selected===props.pointIndex?' selectedPoint':'')}
      onClick={()=>props.onClickEvent(props.pointIndex)}
    >
      <div className="info" >
        {(currentEvent.eventUser?.isCoordinator||currentEvent.eventUser?.isLeading)?
        <div className="point" >
          {<Icon className={"cross"} type={"qr"} onClick={qrClick} />}
          <span>{props.index}</span>
        
      </div>
        
        :<div className="point" >
          <span>
            <Icon className={"cross"} type={props.item.isPassed ?'circleCheck':'timer'}></Icon>
          </span>
          {props.index}
        </div>}
        <div className="point" style={{marginTop:'0px',display: "flex",'flexDirection': 'column'}}>
          {props.item.isPassed? getDate(props.item.timePassed,'hh:mm:ss',':'):"..."}
          <i>Час</i>
          {/* {props.item.location[0].toFixed(5) + ", " + props.item.location[1].toFixed(5)} */}
        </div>
      </div>
      {/* {currentUser?.roleId==='09efbeb2-f45a-418d-89b0-b2a4c37f6122' && <div className="controls">
        <Icon className={"cross"} type={"qr"} onClick={qrClick} />
      </div>} */}
      {qrModal && <QrComponent index={props.index-1}  link={props.item.qrCode} setModalOpen={setQrModal}></QrComponent>}
    </div>
  );
};
