import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const Filter = (props) => {
  const [ready, setReady] = useState(false);
  const { fullList } = useSelector((state) => state);
  const [form, setForm] = useState({ filter: {}, offset: 0, limit: 10 });
  let arr = { ...form };
  let initFunction = () => {
    setReady(false);
    if (localStorage.getItem("videoFilter")) {
      arr.filter = JSON.parse(localStorage.getItem("videoFilter"));
      setForm(arr);
    }
    setReady(true);
  };

  const saveFilter = (data) => {
    if (data) {
      props.setFilter(data.filter);
      props.getVideoList(1, data.filter);
    } else {
      props.setFilter(form.filter);
      props.getVideoList(1, form.filter);
    }
  };

  useEffect(() => {
    if (!ready) {
      initFunction();
    }
  }, []);

  const setSelect = (e) => {
    arr = { ...form };

    if (
      arr.filter[e.target.accessKey] &&
      arr.filter[e.target.accessKey].includes(e.target.id)
    ) {
      const index = arr.filter[e.target.accessKey].indexOf(e.target.id);
      if (index > -1) {
        arr.filter[e.target.accessKey].splice(index, 1);
      }
    } else {
      if (arr.filter[e.target.accessKey]) {
        arr.filter[e.target.accessKey].push(e.target.id);
      } else {
        arr.filter[e.target.accessKey] = [];
        arr.filter[e.target.accessKey].push(e.target.id);
      }
    }

    setForm(arr);
  };
  const leaveClick = (event) => {
    if (!event.currentTarget.contains(event.relatedTarget)) {
      localStorage.setItem("videoFilter", JSON.stringify(form.filter));
      saveFilter();
      props.setModal(false);
    }
  };
  const resetClick = () => {
    arr = { filter: [] };
    setForm(arr);
    localStorage.removeItem("videoFilter");
    saveFilter(arr);
  };

  return (
    <div className="apFilterContainer" onBlur={leaveClick} tabIndex="0">
      <div className="apFilterHeader">
        <h4>Параметри фільтра</h4>
        <div style={{ display: "flex" }}>
          <button
            style={{ marginRight: "20px" }}
            className="secondarySmallRoundedButtonTextDefault"
            onClick={resetClick}
          >
            Скинути
          </button>
          <button
            className="secondarySmallRoundedButtonTextDefault"
            onClick={leaveClick}
          >
            OK
          </button>
        </div>
      </div>
      <div className="apFilterGroupBlock">
        <span>Категорія</span>
        <div className="apFilterButtonsBlock">
          {fullList.ExerciseCategories.map((el) => {
            return (
              <button
                accessKey="categoryId"
                id={el.id}
                key={el.id}
                className={
                  form.filter.categoryId &&
                  form.filter.categoryId.length > 0 &&
                  form.filter.categoryId.includes(el.id)
                    ? "secondarySmallButtonTextActive"
                    : "secondarySmallButtonTextDefault"
                }
                onClick={setSelect}
              >
                {el.title}
              </button>
            );
          })}
        </div>
      </div>
      <div className="apFilterGroupBlock">
        <span>Складність</span>
        <div className="apFilterButtonsBlock">
          {fullList.ExerciseDifficultyLevels.map((el) => {
            return (
              <button
                accessKey="exerciseDifficultyLevelId"
                id={el.id}
                key={el.id}
                className={
                  form.filter.exerciseDifficultyLevelId &&
                  form.filter.exerciseDifficultyLevelId.length > 0 &&
                  form.filter.exerciseDifficultyLevelId.includes(el.id)
                    ? "secondarySmallButtonTextActive"
                    : "secondarySmallButtonTextDefault"
                }
                onClick={setSelect}
              >
                {el.title}
              </button>
            );
          })}
        </div>
      </div>
      <div className="apFilterGroupBlock">
        <span>Cтатус</span>
        <div className="apFilterButtonsBlock">
          {fullList.VideoStatuses.map((el) => {
            return (
              <button
                accessKey="statusId"
                id={el.id}
                key={el.id}
                className={
                  form.filter.statusId &&
                  form.filter.statusId.length > 0 &&
                  form.filter.statusId.includes(el.id)
                    ? "secondarySmallButtonTextActive"
                    : "secondarySmallButtonTextDefault"
                }
                onClick={setSelect}
              >
                {el.title}
              </button>
            );
          })}
        </div>
      </div>
      <div className="apFilterGroupBlock">
        <span>Регіони</span>
        <div className="apFilterButtonsBlock">
          {fullList.Regions.map((el) => {
            return (
              <button
                id={el.id}
                accessKey="regionId"
                className={
                  form.filter.regionId &&
                  form.filter.regionId.length > 0 &&
                  form.filter.regionId.includes(el.id)
                    ? "secondarySmallButtonTextActive"
                    : "secondarySmallButtonTextDefault"
                }
                onClick={setSelect}
              >
                {el.title}
              </button>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Filter;
