import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import EventsList from "../../../components/EventsList";
import { getMyEvents } from "../../../redux/actions/events";


export const Events = ({ data }) => {
  const [ready,setReady]=useState(false)
  const dispatch=useDispatch();
  const [list,setList]=useState([])


  const initFunc=()=>{
    let data={};
    data.sort = { startsAt: "asc" };
    dispatch(getMyEvents(data)).then(res=>{
      setList(res.items)
      setReady(true);
    })
  }
  useEffect(() => {
    if(!ready){
      initFunc()
    }
  }, [ready]);

    return(
      <>
       <EventsList
        data={list}
        fallback={"Ви не зареєстровані для участі в жодному заході"}
      />
      </>

    )
};

