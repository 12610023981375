import {
    GETGALLERYOFF_SUCCESS,
    GETGALLERYOFF_FAIL,
    GETGALLERY_FAIL,
    GETGALLERY_SUCCESS,
    CHANGEGALLERY_SUCCESS,
    CHANGEGALLERY_FAIL,
    DELETEGALLERY_SUCCESS,
    DELETEGALLERY_FAIL,
} from "./types";


import GalleryService from "../services/gallery.service"

export const getOfficial = (data)=> async(dispatch)=>{
    try{
        const res = await GalleryService.getOfficial(data);
        dispatch({
          type: GETGALLERYOFF_SUCCESS,
          list: res,
        });
        return res
    }catch (error){
        dispatch({
            type: GETGALLERYOFF_FAIL,
            list: {error: error.response},
          });
          const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
          return error.response
    }
}

export const getUserGallery = (data)=> async(dispatch)=>{
    try{
        const res = await GalleryService.getUserGallery(data);
        dispatch({
          type: GETGALLERY_SUCCESS,
          list: res,
        });
        return res
    }catch (error){
        dispatch({
            type: GETGALLERY_FAIL,
            list: {error: error.response},
          });
          const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
          return error.response
    }
}

export const changeGalleryStatus =(data)=>async(dispatch)=>{
    try{
        const res = await GalleryService.changeGalleryStatus(data);
        dispatch({
          type: CHANGEGALLERY_SUCCESS,
          list: res,
        });
        return res
    }catch (error){
        dispatch({
            type: CHANGEGALLERY_FAIL,
            list: {error: error.response},
          });
          const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
          return error.response
    }
}

export const approveGalleryStatus =(data)=>async(dispatch)=>{
    try{
        const res = await GalleryService.approveGalleryStatus(data);
        dispatch({
          type: CHANGEGALLERY_SUCCESS,
          list: res,
        });
        return res
    }catch (error){
        dispatch({
            type: CHANGEGALLERY_FAIL,
            list: {error: error.response},
          });
          const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
          return error.response
    }
}


export const deleteGallery= (data)=>(dispatch)=>{
      
    return GalleryService.deleteGallery(data).then(
        (response)=>{
            dispatch({
                type:DELETEGALLERY_SUCCESS,
            });
              
        },
        (error)=>{
            dispatch({
                type: DELETEGALLERY_FAIL,
              });
            console.log(error);
                 
              return Promise.reject();
        }
    );
}

