import axios from "axios";
import authHeader from "./auth-header";

import constants from '../../constants/constant.json';


const changeUserStatus = (data)=>{

        return axios.post(constants.URL + `api/v1/clubs/${data.id}/${data.type}`,{...data},{ headers: authHeader()})
        .then((response) => { 
            return response.data})
        .catch((e)=>{
              
            console.log(e);
        });
}

const getUserHeads = (data)=>{

    return axios.get(constants.URL + `api/v1/clubs/${data.id}/participants/heads`,{ headers: authHeader()})
    .then((response) => { 
        return response.data})
    .catch((e)=>{
          
        console.log(e);
    });

}

const getUserMembers = (data)=>{

    return axios.get(constants.URL + `api/v1/clubs/${data.id}/participants/members`,{ headers: authHeader()})
    .then((response) => { 
        return response.data})
    .catch((e)=>{
          
        console.log(e);
    });

}

const getUserApplying = (data)=>{

    return axios.get(constants.URL + `api/v1/clubs/${data.id}/participants/applying`,{ headers: authHeader()})
    .then((response) => { 
        return response.data})
    .catch((e)=>{
          
        console.log(e);
    });

}


export default{
    changeUserStatus,
    getUserApplying,
    getUserMembers,
    getUserHeads,
}