import {
    GETCOUNTER_SUCCESS,
    GETCOUNTER_FAIL,
} from "./types";

import CounterService from "../services/counter.service"


export const getCounter = (data)=> async(dispatch)=>{
        
    try{
        const res = await CounterService.getCounter(data);
        dispatch({
          type: GETCOUNTER_SUCCESS,
          item: res,
        });
        
        return res
    }catch (error){
        
        dispatch({
            type: GETCOUNTER_FAIL,
            item: {error: error.response},
          });
          const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
          return error.response
    }
}
