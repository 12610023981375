import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CommentsSlider } from "./CommentsSlider";
import { Icon as SubmitButton } from "../../../../../components/Icon";
import { useParams } from "react-router";
import { addWorkoutNote, exitWorkout, getWorkoutsItemComments, setVoteTrainig } from "../../../../../redux/actions/workouts";
import { Form } from "react-router-dom";
import RatingStar from "../../../../../components/RatingStar/RatingStar";
import getDate from "../../../../../helpers/getDate";




const WorkoutItem =({el,index})=>{
  const [commentsList,setCommentsList]=useState([]);
  const [isComentsOpen, setIsComentsOpen] = useState(false);
  const [form, setForm] = useState({});
  const [ready, setReady] = useState(false);
  const dispatch=useDispatch();
  const currentId=useParams().id;
  const { currentUser } = useSelector((state) => state.currentUser);



  const changeHandler = (e) => {
    setForm({ ...form, [e.target.id]: e.target.value });
  };

   const dismissEvent =(e)=>{
    let data={id:el.id}
    dispatch(exitWorkout(data)).then(res=>{
    })
  }
  const createComment =()=>{
      let data={
          id:el.id,
          title:form.title
      };
    dispatch(addWorkoutNote(data)).then(res=>{
        setForm({});
        console.log(res);
        getComments();
    })
  }

  const checkDate = (dateString) => {
    const [date, time] = dateString.split(' ');
    const formattedDate = `${date.replace(/-/g, '/')}${time}`;
    const targetDate = new Date(formattedDate);
    return new Date() > targetDate;
  }

  const openCommentsClick = (e) =>{ 
    setIsComentsOpen(e.target.id!==isComentsOpen?e.target.id:'');
    getComments(e.target.id);
  }

  const getComments=()=>{
    setReady(false);
    console.log('getItems');
      let data={
          id:el.id
      }
    dispatch(getWorkoutsItemComments(data)).then(res=>{
        console.log(res);
        setCommentsList(res.notes);
        setReady(true);
    })
  }

  const handleRatingChange =(value,type)=>{
    let data={
        id:el.id,
      }
      data[type]=value;    
      dispatch(setVoteTrainig(data)).then(res=>{
      })
}
    return(
        <>
        <div key={index} className="apTrainingItem">
            {/* TIMIG */}
            <div className="time">
              <div>
                {getDate(el.startsAt,'hh:mm',':') || '00:00'}<span>Початок</span>
              </div>
              <div>
                {el.finishTime?.slice(0,5)|| getDate(el.finishesAt,'hh:mm',':')  || '00:00'}<span>Кінець</span>
              </div>
            </div>
            {/* /TIMIG */}
    
            <div className="item">
              {/* HEADLINE */}
              <div className="headline">
                <h3 className="title">{el.title}</h3>
                <span className="type">{el.type}</span>

                    {currentUser.id===currentId && checkDate(el.startsAt) && <div className="workoutRating">
                        <RatingStar defaultRating={el.exerciseEvaluation||0} type={'exerciseEvaluation'} onChange={handleRatingChange} />
                        <p className="ratingText">Власна оцінка</p>
                    </div>}
                
              </div>
              {/* /HEADLINE */}
    
              {/* MAIN_CONTENT */}
              
               <div className="trainingMainContent" >
                {el.video?.length>0 &&<video style={{width:'389px', height:'220px'}} controls="controls">
                    <source src={el.video[0].url} type="video/mp4" media="all and (max-width: 720px)" data-quality="SD"/> 
                    <source type="video/mp4" src={el.video[1].url} data-quality="HD" />   
                  </video>}
                <ul>
                  {el.exercises?.length>0 && el.exercises.map((elem,index)=>(
                    <li key={index}>{elem}</li>
                  ))}
                </ul>
              </div>
              {/* /MAIN_CONTENT */}
    
              {/* BOTTOM_LINE */}
              <div className="btm-line">
                {isComentsOpen ==el.id && (
                  <div className="apInputText">
                    <input type="text" value={form.title||''} id='title' onChange={changeHandler} placeholder="Додати коментар" />
                    <SubmitButton
                      onClick={createComment}
                      type={"submitArrow"}
                    />
                  </div>
                )}
                <button
                  className={`viewComents ${isComentsOpen===el.id ? '' : ''}`}
                  id={el.id}
                 
                  onClick={openCommentsClick}
                >
                  {isComentsOpen==el.id ? "Згорнути коментарі" : "Переглянути коментарі"}
                </button>
                {currentUser.id===currentId && checkDate(el.startsAt) && <button onClick={dismissEvent} id={el.id}>Не брав участь</button>}
              </div>
              {/* /BOTTOM_LINE */}
            </div>
          </div>
           {/* COMMENTS */}
                {isComentsOpen==el.id && ready && <CommentsSlider getComments={getComments} id={el.id} comments={commentsList}/>} 
          {/* /COMMENTS */}
        </>
    )
}

export default WorkoutItem