import React from 'react';
import './ProgressBar.css'; // Стили для компонента

const LineProgressBar = (props) => {

  const percentage = (props.value / props.max) * 100;
  return (
    <div className={props.color?"progress-bar blue":"progress-bar"}>
      <div
        className={props.color?"progress-bar__fill blue":"progress-bar__fill"}
        style={{ width: `${percentage}%` }}
      ></div>
    </div>
  );
};

export default LineProgressBar;