import {
    GETCURRENTINFO
} from "../actions/types";
  
const initialState = [];

export default function infoReducer(currentInfo = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case GETCURRENTINFO:
          return payload;
        default:
          return currentInfo;
      }

}

