import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { changePassword } from '../../../redux/actions/auth';


const PasswordModal =(props)=>{
    const currentId = useParams().id;
    const [error,setError]=useState({});
    const {currentUser} = useSelector(state=>state.currentUser)
    const [validForm, setValidForm] = useState({
    password:true, oldPassword:true, newPassword:true
    })
    const [form,setForm]=useState({id:currentId})
    const [ready,setReady] = useState(false)
    const dispatch=useDispatch();
    const closeClick=()=>{
        props.setModal(false)
    }
    const changeHandler=(e)=>{
        const passwordCheck= /^(?=.*\d)(?=.*?[a-z])(?=.*?[A-Z]).{6,30}$/;
        if(passwordCheck.test(String(e.target.value))){
            setValidForm({...validForm,[e.target.id]:true})
        }else{
            setValidForm({...validForm,[e.target.id]:false})
        };
        setForm({...form, [e.target.id]: e.target.value})

      }
    const saveChange=()=>{
        if(form.newPassword && form.oldPassword){
            let data ={...props.form}
            data.oldPassword=form.oldPassword;
            data.newPassword=form.newPassword;
            
            dispatch(changePassword(data)).then(res=>{
                
                if(!res.errors){
                    closeClick();
                }else{
                    res.errors.forEach(el=>{
                        setError({...error,[el.param]:el.msg})
                    })
                }
            })
          }
    }
return(
    <div className="apLoginFormContainer">
        <svg onClick={closeClick}><use href="/images/icon24Cancel.svg#icon24" /></svg>
        <h3>Зміна пароля</h3>
        <div className="apInputTextIcon">
            <label htmlFor="oldPassword">Старий пароль</label>
            <div>
            <input id="oldPassword" type="text" onChange={changeHandler} value={form.oldPassword} />
            <svg><use href="/images/icon24Eye.svg#icon24" /></svg>
            </div>
            {!validForm.oldPassword &&   <span className=''>Поле має бути від 6 до 100 символів <br/> і містити одну літеру у верхньому реєстрі,<br/> одну літеру у нижньому реєстрі та одну цифру</span>}
            {error.oldPassword &&  <span className='apError'>{error.oldPassword}</span>}
        </div>
        <div className="apInputTextIcon">
            <label htmlFor="newPassword">Новий пароль</label>
            <div>
            <input id="newPassword" type="text" value={form.newPassword}  onChange={changeHandler}/>
            <svg><use href="/images/icon24Eye.svg#icon24" /></svg>
            </div>
            {!validForm.newPassword &&  <span className=''>Поле має бути від 6 до 100 символів <br/> і містити одну літеру у верхньому реєстрі,<br/> одну літеру у нижньому реєстрі та одну цифру</span>}
            {error.newPassword &&  <span className=''>{error.newPassword}</span>}
        </div>
        <div className="apInputTextIcon">
            <label htmlFor="password">Повторіть новий пароль</label>
            <div>
            <input id="password" type="text" value={form.password}onChange={changeHandler}  />
            <svg><use href="/images/icon24EyeAndLine.svg#icon24" /></svg>

            </div>
            {!validForm.password &&   <span className=''>Поле має бути від 6 до 100 символів <br/> і містити одну літеру у верхньому реєстрі,<br/> одну літеру у нижньому реєстрі та одну цифру</span>}
            {error.password &&  <span className=''>{error.password }</span>}
        </div>
        <button className={form.newPassword===form.password ?"primaryButtonTextDefault":"primaryButtonTextDisabled"} disabled={form.newPassword!==form.password} onClick={saveChange}>
            Зберегти зміни
        </button>
    </div>
)
}




export default PasswordModal;