import {
    GETPARKLISTCOORDINATOR_SUCCESS,
    GETPARKLISTCOORDINATOR_FAIL,
} from "../actions/types";

const initialState = [];

export default function parksCoordinatorReducer(parksCoordinator = initialState,action){
    const {list,type} = action;

    switch(type) {
        case GETPARKLISTCOORDINATOR_SUCCESS:
            
            return {...list};
        case GETPARKLISTCOORDINATOR_FAIL:
            return parksCoordinator;
        default:
            return parksCoordinator;
    }

}