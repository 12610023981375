import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import Loader from "../../components/Loader/Loader";
import { addPark, getParks } from "../../redux/actions/parks";
import Filter from "../ParkListPage/Filter/Filter";

const MapPage = () => {
  const [ready, setReady] = useState(false);
  const [mapReady, setMapReady] = useState(false);
  const [load, setLoad] = useState({ lat: 50.4393067, lon: 30.5479288 });
  const [_, setArr] = useState([]);
  const navigate = useNavigate();
  const { currentUser } = useSelector((state) => state.currentUser);
  const [selected, setSelected] = useState("");
  const { parks } = useSelector((state) => state);
  const { listId } = useSelector((state) => state);
  const { fullList } = useSelector((state) => state);
  const [modal, setModal] = useState(false);
  const [filter, setFilter] = useState(false);
  const [filterArr, setFilterArr] = useState({});
  const [data, setData] = useState({});
  const dispatch = useDispatch();
  const addClick = () => {
    dispatch(addPark())
      .then((res) => {
        navigate(`/sportsground/${res.id}/info`);
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const selectClick = (e) => {
    if (e.target.id == selected) {
      setSelected("");
    } else {
      setSelected(e.target.id);
    }
  };

  let masarr = [];

  const initFunction = () => {
    const infoWindow = new window.google.maps.InfoWindow();
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(function (position) {
        var current_latitude = position.coords.latitude;
        var current_longitude = position.coords.longitude;
        let pos = { lat: current_latitude, lon: current_longitude };
        setLoad(pos);
      });
    }
    const mapDiv = document.getElementById("google");
    let map = new window.google.maps.Map(mapDiv, {
      center: new window.google.maps.LatLng(load.lat, load.lon),
      zoomControl: false,
      fullscreenControl: true,
      fullscreenControlOptions: {
        position: window.google.maps.ControlPosition.BOTTOM_RIGHT,
      },
      zoom: 5,
      streetViewControl: false,
    });

    let customStyled = [
      {
        featureType: "all",
        elementType: "labels.icon",
        stylers: [{ visibility: "off" }],
      },
    ];
    map.set("styles", customStyled);
    let zoomInButton, zoomOutButton, myLocationButton;
    zoomInButton = document.createElement("button");
    zoomOutButton = document.createElement("button");
    myLocationButton = document.createElement("button");
    let gmLegend = document.getElementById("apGMLegend");
    zoomInButton.type = "button";
    zoomOutButton.type = "button";
    myLocationButton.type = "button";
    zoomInButton.value = "Збільшити";
    zoomOutButton.value = "Зменшити";
    myLocationButton.value = "My Location";
    zoomInButton.classList.add(
      "primaryMediumButtonIconDefault",
      "apMapZoomButton"
    );
    zoomOutButton.classList.add(
      "primaryMediumButtonIconDefault",
      "apMapZoomButton"
    );
    myLocationButton.classList.add(
      "primaryMediumButtonIconDefault",
      "apMapZoomButton"
    );
    zoomInButton.innerHTML =
      '<svg><use href="/images/icon24Plus.svg#icon24"></use></svg>';
    zoomOutButton.innerHTML =
      '<svg><use href="/images/icon24Minus.svg#icon24"></use></svg>';
    myLocationButton.innerHTML =
      '<svg><use href="/images/icon24MyLocation.svg#icon24"></use></svg>';
    map.controls[window.google.maps.ControlPosition.RIGHT_CENTER].push(
      zoomInButton
    );
    map.controls[window.google.maps.ControlPosition.RIGHT_CENTER].push(
      zoomOutButton
    );
    map.controls[window.google.maps.ControlPosition.RIGHT_BOTTOM].push(
      myLocationButton
    );
    map.controls[window.google.maps.ControlPosition.RIGHT_TOP].push(gmLegend);
    setMapReady(true);
    zoomInButton.onclick = function () {
      map.setZoom(map.getZoom() + 1);
    };

    zoomOutButton.onclick = function () {
      map.setZoom(map.getZoom() - 1);
    };
    myLocationButton.onclick = () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const pos = {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            };
            new window.google.maps.Marker({
              position: pos,
              map: map,
            });
            map.setCenter(pos);
            if (map.getZoom() < 10) {
              map.setZoom(10);
            } else if (map.getZoom() >= 18) {
              map.setZoom(17);
            } else {
              map.setZoom(map.getZoom() + 1);
            }
          },
          () => {
            handleLocationError(true, infoWindow, map.getCenter());
          }
        );
      } else {
        handleLocationError(false, infoWindow, map.getCenter());
      }
    };
    function handleLocationError(browserHasGeolocation, infoWindow, pos) {
      infoWindow.setPosition(pos);
      infoWindow.setContent(
        browserHasGeolocation
          ? "Error: The Geolocation service failed."
          : "Error: Your browser doesn't support geolocation."
      );
      infoWindow.open(map);
    }
    const iconBase = "images/iconPark";
    const icons = {
      "036735ba-f634-469b-ac30-77a164e3a918": {
        icon: iconBase + "Active.svg",
      },
      "977f1037-77ca-4e7a-90ef-dc1a974e2ff2": {
        icon: iconBase + "Closed.svg",
      },
      "3587410c-7bfa-46c7-a003-c16a408c3004": {
        icon: iconBase + "Candidate.svg",
      },
      "99ca4f35-663c-4e0a-b9ef-f70219aa8163": {
        icon: iconBase + "Inactive.svg",
      },
    };
    let array = [];
    if (!masarr.length > 0) {
      array = data.items;
    } else {
      array = masarr;
    }

    const markers = array.map((element, i) => {
      if (element.location && element.location[0]) {
        const marker = new window.google.maps.Marker({
          position: new window.google.maps.LatLng(
            element.location[0],
            element.location[1]
          ),
          icon: icons[element.statusId].icon,
          map,
          optimized: false,
        });

        marker.addListener("click", () => {
          infoWindow.close();
          if (element.statusId === "036735ba-f634-469b-ac30-77a164e3a918") {
            infoWindow.setContent(
              `<div class="apgmtt1"><div class="apgmtt2" style='background-image: URL(${
                element.photo || (element.photos?.length>0&&element.photos[0]) || "https://ap.sportforall.gov.ua/api/v1/uploads/b34604xc-02qb-46bb-9e91-fc3ffrb92w41"
              });'></div><a href='/sportsground/${
                element.id
              }/info' class="apgmtt3">${
                element.title.length > 0 ? element.title : element.code
              }</a><div class="apgmtt4"><div class="apPublished">Опубліковано</div></div></div>`
            );
          } else if (
            element.statusId === "977f1037-77ca-4e7a-90ef-dc1a974e2ff2"
          ) {
            infoWindow.setContent(
              `<div class="apgmtt1"><div class="apgmtt2" style='background-image: URL(${
                element.photo
                  ? element.photo
                  : "https://ap.sportforall.gov.ua/api/v1/uploads/b34604xc-02qb-46bb-9e91-fc3ffrb92w41"
              });'></div><a href='/sportsground/${
                element.id
              }/info' class="apgmtt3">${
                element.title.length > 0 ? element.title : element.code
              }</a><div class="apgmtt4"><div class="apDeactivated">Чорнетка</div></div></div>`
            );
          } else if (
            element.statusId === "99ca4f35-663c-4e0a-b9ef-f70219aa8163"
          ) {
            infoWindow.setContent(
              `<div class="apgmtt1"><div class="apgmtt2" style='background-image: URL(${
                element.photo
                  ? element.photo
                  : "https://ap.sportforall.gov.ua/api/v1/uploads/b34604xc-02qb-46bb-9e91-fc3ffrb92w41"
              });'></div><a href='/sportsground/${
                element.id
              }/info' class="apgmtt3">${
                element.title.length > 0 ? element.title : element.code
              }</a><div class="apgmtt4"><div class="apOnRepair">На реконструкції</div></div></div>`
            );
          } else if (
            element.statusId === "3587410c-7bfa-46c7-a003-c16a408c3004"
          ) {
            infoWindow.setContent(
              `<div class="apgmtt1"><div class="apgmtt2" style='background-image: URL(${
                element.photo
                  ? element.photo
                  : "https://ap.sportforall.gov.ua/api/v1/uploads/b34604xc-02qb-46bb-9e91-fc3ffrb92w41"
              });'></div><a href='/sportsground/${
                element.id
              }/info' class="apgmtt3">${
                element.title.length > 0 ? element.title : element.code
              }</a><div class="apgmtt4"><div class="apCandidate">Кандидат</div></div></div>`
            );
          }
          infoWindow.open(marker.getMap(), marker);
        });
      }
    });
  };

  const getData = async (amount = 0, filter = {}) => {
    // setReady(false);
    let data = { offset: amount, limit: 100, filter: filter };

    dispatch(getParks(data)).then((res) => {
      masarr = [...masarr, ...res.items];
      res.items = masarr;
      if (res.limit + res.offset < res.total) {
        getData(res.offset + res.limit, filter);
      } else {
        setArr(masarr);
        setReady(true);
        setData(res);
      }
    });
  };

  const resetFilter = () => {
    localStorage.removeItem("filter");
    setFilterArr({});
    getData();
  };
  const selectFilter = (e) => {
    let data = { ...filterArr };
    data["statusId"] = [];
    data["statusId"].push(e.target.id);
    localStorage.setItem("filter", JSON.stringify(data));
    setFilterArr(data);
    setSelected("");
    getData(1, data);
  };

  useEffect(() => {
    if (!ready) {
      if (localStorage.getItem("filter")) {
        let filter = JSON.parse(localStorage.getItem("filter"));
        getData(0, filter);
        setFilterArr(filter);
      } else {
        getData();
      }
    }
  }, [ready]);

  useEffect(() => {
    if (!ready || !data) return;
    initFunction();
  }, [ready, data]);

  if (!ready || !parks) {
    return (
      <>
        <div style={{ margin: "0 44%" }}>
          <Loader></Loader>
        </div>
      </>
    );
  } else if (ready && parks) {
    return (
      <>
        <div className="apMapPage">
          {filter && (
            <Filter
              setModal={setFilter}
              getList={getData}
              initFunction={initFunction}
              setFilter={setFilterArr}
            ></Filter>
          )}
          <div className="headerWithButtonsCommonBlock">
            <div className="headerWithButtonsHeaderBlock">
              <h1>Мої об'єкти</h1>
            </div>
            <div className="headerWithButtonsButtonBlock">
              {/* <button className="secondaryButtonIconDefault">
                  <svg><use href="/images/icon24Search.svg#icon24" /></svg>
                </button> */}
              <div className="apSwitch">
                <button
                  className="secondaryButtonIconDefault"
                  onClick={() => {
                    navigate("/parklistpage");
                  }}
                >
                  <svg>
                    <use href="/images/icon24List.svg#icon24" />
                  </svg>
                </button>
                <button className="secondaryButtonIconActive">
                  <svg>
                    <use href="/images/icon24Map.svg#icon24" />
                  </svg>
                </button>
              </div>
              {/* <button className="secondaryButtonIconTextDefault">
                  <svg><use href="/images/icon24Report.svg#icon24" /></svg>
                  Звіт
                </button> */}
            </div>
          </div>
          <div className="menuFunctionLine">
            <div className="actionButtonsBlock apMapPage">
            {currentUser.roleId!=='09efbeb2-f45a-418d-89b0-b2a4c37f6122' && currentUser.roleId!=='631db81f-fa07-42e2-b394-062c9be66b09' && <button
                className="primaryButtonIconTextDefault"
                onClick={addClick}
              >
                <svg>
                  <use href="/images/icon24Plus.svg#icon24" />
                </svg>
                Додати майданчик
              </button>}
              <div className="apDropDownList apFilled">
                <label htmlFor="dropDown1">Статус об'єктів</label>
                <div id="dropDown1" onClick={selectClick}>
                  <input
                    id="dropDown1"
                    readOnly
                    type="text"
                    defaultValue={
                      (filterArr.statusId &&
                        filterArr.statusId[0] &&
                        listId.list[filterArr.statusId[0]]) ||
                      "Статус об'єктів"
                    }
                  />
                  <svg>
                    <use href="/images/icon24DropDown.svg#icon24" />
                  </svg>
                </div>
                {selected == "dropDown1" && (
                  <div className="apOptionsList">
                    {fullList.SportsgroundStatuses.map((el) => {
                      return (
                        <div
                          key={el.id}
                          className={
                            filterArr.statusId == el.id ? "apActive" : ""
                          }
                          onClick={selectFilter}
                          id={el.id}
                        >
                          {el.title}
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            </div>
            {/* <div className="actionTriggersBlock">
                <div class="apCheckBoxWithLabel"> <input id="nonTrustedGroundSwiper" type="checkbox" className="apCheckbox" defaultChecked />
                <label htmlFor="nonTrustedGroundSwiper">Лише неперевірені об'єкти</label></div>
              </div> */}
          </div>
          <div className="filterBlock">
            <button
              className="secondaryButtonIconTextDefault"
              onClick={() => setFilter(true)}
            >
              <svg>
                <use href="/images/icon24Filter.svg#icon24" />
              </svg>
              Фільтр
            </button>
            <span>Знайдено {parks.total} об’єктів</span>
            {filterArr &&
              Object.keys(filterArr).length > 0 &&
              Object.keys(filterArr).map((el) => {
                return (
                  filterArr[el].length > 0 &&
                  filterArr[el].map((elem, index) => {
                    return (
                      <div className="apChips" key={index}>
                        {listId.list[elem]}
                        {/* <svg><use href="/images/icon24CancelSmall.svg#icon24" /></svg> */}
                      </div>
                    );
                  })
                );
              })}
            {filterArr && Object.keys(filterArr).length > 0 && (
              <button
                onClick={resetFilter}
                className="secondarySmallRoundedButtonTextDefault"
              >
                Скинути
              </button>
            )}
          </div>

          <div className="pageContentHeaderDivider">&nbsp;</div>
          <div id="google" style={{ height: "600px" }} />
          <div
            id="apGMLegend"
            style={{ display: `${!mapReady ? "none" : "flex"}` }}
            className={
              modal ? "apKeywordButtonList apOpenedList" : "apKeywordButtonList"
            }
            onClick={(e) => setModal(!modal)}
          >
            <div className="apKeywordButton">
              Легенда
              <svg className="apKeywordButtonChevronDown">
                <use href="images/icon24ChevronDown.svg#icon24"></use>
              </svg>
              <svg className="apKeywordButtonChevronUp">
                <use href="images/icon24ChevronUp.svg#icon24"></use>
              </svg>
            </div>
            <div className="apKeywordList">
              <div>
                <svg>
                  <use href="images/iconParkActive.svg#icon24"></use>
                </svg>
                Опублікований
              </div>
              <div>
                <svg>
                  <use href="images/iconParkCandidate.svg#icon24"></use>
                </svg>
                Кандидат
              </div>
              <div>
                <svg>
                  <use href="images/iconParkClosed.svg#icon24"></use>
                </svg>
                На реконстукції
              </div>
              <div>
                <svg>
                  <use href="images/iconParkInactive.svg#icon24"></use>
                </svg>
                Деактивований
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
};

export default MapPage;
