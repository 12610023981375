import { useState } from "react";
import getDate from "../../../../../helpers/getDate";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { exitWorkout } from "../../../../../redux/actions/workouts";
import WorkoutItem from "./WorkoutItem";

export const HistoryItem = (props) => {
  const [isComentsOpen, setIsComentsOpen] = useState(false);
  const { listId } = useSelector((state) => state);
  const currentId=useParams().id;
  const dispatch=useDispatch();
  const { currentUser } = useSelector((state) => state.currentUser);


  const dismissEvent =(e)=>{
    let data={id:e.target.id}
    dispatch(exitWorkout(data)).then(res=>{

    })
  }

  const checkDate=(dateString)=>{
    const dateParts = dateString.split(" ");
    const date = new Date(dateParts[0].replace(/-/g, "/") + " " + dateParts[1]);
    return new Date() > date;
  }

  const navigate =useNavigate();
  return (
    <div
      className={`apTrainingItemContainer ${
        (props.isCurrentDay && "current") || ""
      }`}
    >
      {/* DAY_OF_TRAINING */}
      <div className="apTrainingDate">
        {props.isCurrentDay ? "Сьогодні" : props.items[0]}
      </div>
      {/* /DAY_OF_TRAINING */}

      {/* ITEM  u can double this block */}
      {props.items?.length>1 && props.items?.map((el,index)=>{
        if(index==0){
          return(<></>)
        }else if(el.activityType === 'workout' || el.type==='Тренування' ){
          return(
          <>
          <WorkoutItem index={index} el={el}>

          </WorkoutItem>
            
      </>            )
        }else{
          return(
            <div className="apTrainingItem">
               {/* TIMIG */}
            <div className="time">
              <div>
                {getDate(el.startsAt,"hh:mm",':') }<span>Початок</span>
              </div>
              <div>
              {getDate(el.finishesAt,"hh:mm",':') }<span>Кінець</span>
              </div>
            </div>
            {/* /TIMIG */}
              <div className="item">
          {/* HEADLINE */}
              <div className="headline">
                <h3 className="title apLink" onClick={()=>navigate(`/fc-events/0/${el.id}`)}>{el.title}</h3>
                <span className="type">Тип заходу {listId.list[el.typeId]}</span>
              </div>
          {/* /HEADLINE */}

          {/* MAIN_CONTENT */}
            <div className="trainingMainContent" >
              <div>
                <img loading="lazy" src={el.imageUrl?.length>0?el.imageUrl :'https://ap.sportforall.gov.ua/api/v1/uploads/0bae5e09-9088-4cec-bda9-8b60738269de'} style={{width: '220px',height: '170px',borderRadius:'15px'}} alt="" />
              </div>
              <div style={{marginLeft:'24px'}}>
                {el.shortDescription}
              </div>
            </div>
          {/* /MAIN_CONTENT */}

           {/* BOTTOM_LINE */}
          <div className="btm-line">
            <a onClick={(e) => {e.preventDefault();navigate("/eventsCalendar")}}>
              Всі заходи
            </a>
            {currentUser.id=== currentId && checkDate(el.startsAt) && <button onClick={dismissEvent} id={el.id}>Не брав участь</button>}
          </div>
          {/* /BOTTOM_LINE */}


              </div>
            </div>
          )
        }
        
      })}
    
    </div>
  );
};



