import { useState } from "react";
import { useNavigate } from "react-router";
import setPhoneMask from "../../helpers/setPhoneMask";
const Coordinators = (props) => {
  const [error, setError] = useState(false);
  const navigate=useNavigate();
  const data = props.data;
  if (!data || data.length === 0) return null;
  return (
    <div className="apCoordinators">
      <h3 className="headerHelpedCustom">Координатори</h3>
      {data.map(
        ({email, firstName, lastName, phone, photo,id }, index) => (
          <div className="item" key={index}>
            <div className={`imgWrp ${photo ? "whithPhoto" : "empty"}`}>
              {photo  && error!==id &&  (
                <img loading="lazy" onError={() => setError(id)} src={photo} alt="" />
              )}
              <span >
                {firstName ? (
                  <>
                    {firstName[0]}
                    {lastName[0]}
                  </>
                ) : (
                  ":)"
                )}
              </span>
            </div>
            <ul className="contacts">
              <li style={{fontWeight:'700',cursor:'pointer'}} onClick={()=>{navigate(`/personalPage/${id}`)}}>
                {lastName ? (
                  <>
                    {firstName} {lastName}
                  </>
                ) : (
                  "Ім'я не вказано"
                )}
              </li>
              {email?.length>1 && (
                <li>
                  Email: <a href={`mailto:${email}`}>{email}</a>
                </li>
              )}
              {phone?.length>1 && (
                <li>
                  Телефон: <a href={`tel:${phone}`}>{setPhoneMask(phone)}</a>
                </li>
              )}
            </ul>
          </div>
        )
      )}
    </div>
  );
};

export default Coordinators;
