import React, { useEffect, useRef, useState } from "react";
import {
  MapContainer,
  TileLayer,
  Marker,
  Polyline,
  useMap
} from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import { useSelector } from "react-redux";

const markerIconType0 = new L.Icon({
  iconUrl: require("../../../assets/images/material-symbols_location-on.png"),
  iconSize: [24, 24],
  iconAnchor: [11, 22], //[left/right, top/bottom]
  popupAnchor: [0, 0], //[left/right, top/bottom]
});

const markerIconType1 = new L.Icon({
  iconUrl: require("../../../assets/images/material-symbols_location-on-1.png"),
  iconSize: [24, 24],
  iconAnchor: [11, 22], //[left/right, top/bottom]
  popupAnchor: [0, 0], //[left/right, top/bottom]
});

const markerIconType2 = new L.Icon({
  iconUrl: require("../../../assets/images/material-symbols_location-on-2.png"),
  iconSize: [24, 24],
  iconAnchor: [11, 22], //[left/right, top/bottom]
  popupAnchor: [0, 0], //[left/right, top/bottom]
});

const markerIconType3 = new L.Icon({
  iconUrl: require("../../../assets/images/material-symbols_location-on-3.png"),
  iconSize: [24, 24],
  iconAnchor: [11, 22], //[left/right, top/bottom]
  popupAnchor: [0, 0], //[left/right, top/bottom]
});


const POSITION_CLASSES = {
  bottomleft: "leaflet-bottom leaflet-left",
  bottomright: "leaflet-bottom leaflet-right",
  topleft: "leaflet-top leaflet-left",
  topright: "leaflet-top leaflet-right",
  top: "leaflet-top leaflet-center",
};
const markerIconCus = (index) => {
  return L.divIcon({
    html: `<div class="marker-icon">${index}</div>`,
    // iconSize: [36, 36],
    iconAnchor: [6, 22],
  });
};


const markerIconMy = new L.Icon({
  iconUrl: require("../../../assets/images/myLocation.png"),
  iconSize: [24, 24],
  iconAnchor: [12, 13], //[left/right, top/bottom]
  popupAnchor: [0, 0], //[left/right, top/bottom]
});

const markerIconPoint = new L.Icon({
  iconUrl: require("../../../assets/images/point.png"),
  iconSize: [1, 1],
  iconAnchor: [6, 7], //[left/right, top/bottom]
  popupAnchor: [0, 0], //[left/right, top/bottom]
});

const Map = (props) => {
  const [mapArr, setMapArr] = useState([]);
  const { coordinates } = useSelector((state) => state);
  const mapRef = useRef(null);

    const changeArr = (ar) => {
    props.updateArr([...ar]);
    setMapArr([...ar]);
  };
  const redOptions = { color: 'red' };

  return (
    <>
      <MapContainer
        style={{ height: '100%', width: 'auto' }}
        ref={mapRef}
        center={
          props.el?.length > 0 && props.el[0][0]
            ? [props.el[0][0], props.el[0][1]]
            : [50.4393, 30.5479]
        }
        zoom={15}
        scrollWheelZoom={true}
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://openstreetmap.pp.ua/hot/{z}/{x}/{y}.png"
        />
        <MyPolyline coordinates={props.el} />

        <Polyline pathOptions={redOptions} positions={props.el} />
        {props.el?.length > 0 &&
          props.el.map((el, index) => {
            return (
              <div key={index}>
                <DraggableMarker
                  location={[el[0], el[1]]}
                  icon={
                    props.selected === index + ''
                      ? markerIconType2
                      : index === 0
                      ? markerIconType1
                      : index === props.el?.length - 1
                      ? markerIconType3
                      : markerIconPoint
                  }
                  index={index}
                  selected={props.selected == index}
                  mapArr={props.arrAll}
                  changeArr={changeArr}
                ></DraggableMarker>
              </div>
            );
          })}
      </MapContainer>
    </>
  );
};

function MyPolyline({ coordinates }) {
  const map = useMap();
  useEffect(() => {
    if (coordinates.length > 0) {
      const polyline = L.polyline(coordinates, { color: 'green' }).addTo(map);
      const bounds = polyline.getBounds();
      map.fitBounds(bounds);
    }
  }, [coordinates, map]);

  return null;
}



function DraggableMarker(props) {
  const [location, setLocation] = useState(props.location);
  const markerRef = useRef(null);

    useEffect(()=>{
      if(props.location!==location){
        setLocation(props.location)
      }
    },[props.location,location])

  return (
    <Marker
      draggable={false}
      position={location}
      icon={props.icon}
      ref={markerRef}
    ></Marker>
  );
}





export default Map;
