import React, { useEffect, useState} from "react";
import { useLocation,useParams } from 'react-router-dom'; // Import from react-router-dom


const MobileDownload =()=>{
    const [close, setClose] = useState(false);
    const location = useLocation();
    const basePath = 'activepark://';

    const closeClick = () => {
        setClose(false);
    }

    const stopPropagation = (e) => {
        e.stopPropagation();
    }


    useEffect(() => {
        const userAgent = navigator.userAgent;
        // console.log(location.pathname.split('/'));
         if (/iPhone|iPad|iPod/.test(userAgent)) {
            setClose(true)
        } else if (/Android/.test(userAgent)) {
            setClose(true)
        }
    }, []);

            // event - "activepark://event/{id}"
                    // club - "activepark://club/{id}"
                    // news -  "activepark://news/{id}"
                    // park -  "activepark://park/{id}"
                    // routeActive -  "activepark://routeActive/{id}"

            const moveFunc =(link)=>{
                let path;
                let id;
                switch (location.pathname.split('/')[1]) {
                    case 'fc-events':
                        if(location.pathname.split('/')[location.pathname.split('/').length-1]=='about'||location.pathname.split('/')[location.pathname.split('/').length-1]=='users' || location.pathname.split('/')[location.pathname.split('/').length-1].length<8){
                            id=location.pathname.split('/')[location.pathname.split('/').length-2];
                        }else{
                            id=location.pathname.split('/')[location.pathname.split('/').length-1];
                        }
                        path = `${basePath}event/${id}`;
                        break;
                    case 'fc':
                        if(location.pathname.split('/')[location.pathname.split('/').length-1]=='about'||location.pathname.split('/')[location.pathname.split('/').length-1]=='users' || location.pathname.split('/')[location.pathname.split('/').length-1].length<8){
                            id=location.pathname.split('/')[location.pathname.split('/').length-2];
                        }else{
                            id=location.pathname.split('/')[location.pathname.split('/').length-1];
                        }
                        path = `${basePath}club/${id}`;
                        break;
                    case 'newspg':
                        id=location.pathname.split('/')[location.pathname.split('/').length-1];
                        path = `${basePath}news/${id}`;
                        break;
                    case 'parkInfo':
                        id=location.pathname.split('/')[location.pathname.split('/').length-1];
                        path = `${basePath}park/${id}`;
                        break;
                    case 'routeInfo': // Corrected typo
                        id=location.pathname.split('/')[location.pathname.split('/').length-1];
                        path = `${basePath}routeActive/${id}`;
                        break;
                    default:
                        console.warn(`Unexpected pathname: ${location.pathname}`);
                        path = `${basePath}main` // Fallback to the current pathname
                    }
                window.location.href =path;

                setTimeout(function() {
                    window.location.href = link;
                }, 1000);
            }
            
    const openAppOrFallback = () => {
        const appStoreLink = 'https://apps.apple.com/us/app/id1661291870';
        const playStoreLink = 'https://play.google.com/store/apps/details?id=com.technodreams.activeparks';
        const userAgent = navigator.userAgent;
        // var appUrl = `activepark://event/`;

        if (/Macintosh|MacIntel|MacPPC|Mac68K/.test(userAgent)) {
                        closeClick();
                    } else if (/iPhone|iPad|iPod/.test(userAgent)) {
                        moveFunc(appStoreLink)
                    } else if (/Win32|Win64|Windows|WinCE/.test(userAgent)) {
                        closeClick();
                    } else if (/Android/.test(userAgent)) {
                        moveFunc(playStoreLink)
                    } else if (/Linux/.test(userAgent)) {
                        closeClick();
                    }

        closeClick();

    }



    return(
        <>
        {close && 
        <div className="mobileMenu" onClick={closeClick}>
            <div className="mobileMenuFull" onClick={stopPropagation}>

                <div className="mobileHeaderBlock">
                    <div className="mobileLogo" style={{background:`url(${'/images/LogoMob.png'})`}}>
                    </div>
                    <div>
                    <h2>
                        Активні парки                      
                    </h2>
                    <div className="mobileSpan">Додаток</div>
                    </div>
                    <button className='mobileCloseButton appClick' onClick={() => openAppOrFallback()}>
                        Відкрити
                    </button>
                    <div className="closeMobile" onClick={closeClick}>Х</div>
                </div>
                {/* <div className="brockenLine">
                </div> */}
                {/* <div className="mobileButtonMenu">
                   
                    {isIOS ? (
                        <button className='storeButton' onClick={() => window.open('https://apps.apple.com/us/app/id1661291870')} style={{ background: `url(${'/images/appStore.png'})`, margin: '0 20px' }}></button>
                    ) : (
                        <button className='storeButton' onClick={() => window.open('https://play.google.com/store/apps/details?id=com.technodreams.activeparks')} style={{ background: `url(${'/images/googlePlay.png'})` }}></button>
                    )} 
                </div> */}
            </div>    
        </div>}
        </>
    )


}


export default MobileDownload;