import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { addSupport, getSupport } from "../../redux/actions/support";
import Filter from "./Filter/Filter";
import Pagination from "../../components/Paggination/Paggination";
import Select from "../../components/Select/";
import getDate from "../../helpers/getDate";

const sortOtions = [
  // { id: "publishedAt", value: "За датою опублікування" },
  // { id: "title", value: "За абеткою" },
  // { id: "title", value: "За назвою" },
  { id: "statusId", value: "За статусом" },
  { id: "topic", value: "За темою" },
  { id: "createdAt", value: "За датою створення" },
  { id: "lastStatusChange", value: "За релевантністю" },


];

const SupportListPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [ready, setReady] = useState(false);
  const { listId } = useSelector((state) => state);
  const [filterArr, setFilterArr] = useState({});
  const [filter, setFilter] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [sortData, setSortData] = useState("lastStatusChange");
  const [sortName, setSortName] = useState("За релевантністю");
  const [sortValue, setSortValue] = useState("desc");
  const [modal, setModal] = useState(false);
  const [searchOpen, setSearchOpen] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [intervalId,setIntervalId] = useState(null);  const { support } = useSelector((state) => state);

  const searchChange = (e) => {
      let filter = {};
      setSearchValue(e.target.value);
      if (localStorage.getItem("supportFilter")) {
        filter = JSON.parse(localStorage.getItem("supportFilter"));
      }
      filter.all = [];
        clearTimeout(intervalId);
    const newIntervalId = setTimeout(() =>{
      // Call your search function with the updated query value
      if (e.target.value.length > 2) {
        filter.all[0] = e.target.value;
        localStorage.setItem("supportFilter", JSON.stringify(filter));

        initFunc(1, filter);
      } else {
        localStorage.setItem("supportFilter", JSON.stringify(filter));

        initFunc(1, filter);
      }
    }, 1000)
    setIntervalId(newIntervalId);  ;

  };

  const AddSupport = (e) => {
    setReady(false);
    e.preventDefault();
    dispatch(addSupport())
      .then((res) => {
        navigate(`/supportedit/${res.id}`);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const sortClick = (e) => {
    setSortName(e.target.innerHTML);
    const currentIdIndex = sortOtions.findIndex(
      (x) => x.value === e.target.innerHTML
    );
    setSortData(sortOtions[currentIdIndex].id);
    let data = {};
    if (sortData === sortOtions[currentIdIndex].id && sortValue === "asc") {
      setSortValue("desc");
      data[sortOtions[currentIdIndex].id] = "desc";
    } else {
      data[sortOtions[currentIdIndex].id] = "asc";
      setSortValue("asc");
    }
    localStorage.setItem("supportSort", JSON.stringify(data));
    initFunc();
    setModal(false);
  };

  const initFunc = (page = 1, filter) => {
    let data = {};
    if (filter) {
      data.filter = { ...filter };
    } else {
      if (localStorage.getItem("supportFilter")) {
        data.filter = { ...JSON.parse(localStorage.getItem("supportFilter")) };
      } else {
        data.filter = {};
      }
    }
    if (localStorage.getItem("supportSort")) {
      data.sort = { ...JSON.parse(localStorage.getItem("supportSort")) };
    } else {
      data.sort = { lastStatusChange: "desc" };
    }
    data.offset = (page - 1) * 10;
    data.limit = 10;
    dispatch(getSupport(data)).then((res) => {
      setReady(true);
    });
  };

  const resetFilter = () => {
    localStorage.removeItem("supportFilter");
    setFilterArr({});
    initFunc();
  };

  useEffect(() => {
    if (!ready) {
      if (localStorage.getItem("supportFilter")) {
        let filter = JSON.parse(localStorage.getItem("supportFilter"));
        initFunc(1, filter);
        setFilterArr(filter);
      } else {
        initFunc();
      }
    }
  }, [ready]);
  return (
    <>
      {filter && (
        <Filter
          setModal={setFilter}
          getVideoList={initFunc}
          setFilter={setFilterArr}
        ></Filter>
      )}
      <div className="headerWithButtonsCommonBlock">
        <div className="headerWithButtonsHeaderBlock">
          <h1>Підтримка</h1>
        </div>
        <div className="headerWithButtonsButtonBlock">
          {searchOpen && (
            <div style={{ marginBottom: "4px" }} className="apInputText">
              <label htmlFor="search">Пошук</label>
              <input
                id="search"
                type="text"
                placeholder="Мінімум 3 символи"
                value={searchValue}
                onChange={searchChange}
              />
            </div>
          )}
          <button
            className="secondaryButtonIconDefault"
            onClick={() => setSearchOpen(!searchOpen)}
          >
            <svg>
              <use href="/images/icon24Search.svg#icon24" />
            </svg>
          </button>
        </div>
      </div>
      <div className="sortAndFilterBlock">
        <div className="menuFunctionLine">
          <div className="actionButtonsBlock">
            <button
              className="primaryButtonIconTextDefault"
              onClick={AddSupport}
            >
              <svg>
                <use href="/images/icon24Plus.svg#icon24" />
              </svg>
              Створити запит
            </button>
            <div className="filterBlock">
              <button
                className="secondaryButtonIconTextDefault"
                onClick={() => setFilter(true)}
              >
                <svg>
                  <use href="/images/icon24Filter.svg#icon24" />
                </svg>
                Фільтр
              </button>
              <span>Знайдено {support.total} запитів:</span>
              {filterArr &&
                Object.keys(filterArr).length > 0 &&
                Object.keys(filterArr).map((el) => {
                  return (
                    filterArr[el].length > 0 &&
                    filterArr[el].map((elem, index) => {
                      return (
                        <div key={index} className="apChips">
                          {listId.list[elem]}
                          {/* <svg><use href="/images/icon24CancelSmall.svg#icon24" /></svg> */}
                        </div>
                      );
                    })
                  );
                })}
              {filterArr && Object.keys(filterArr).length > 0 && (
                <button
                  onClick={resetFilter}
                  className="secondarySmallRoundedButtonTextDefault"
                >
                  Скинути
                </button>
              )}
            </div>
          </div>
          <div className="sortBlock">
          <Select
            label={"Сортування:"}
            value={sortName}
            options={sortOtions}
            onSet={sortClick}
          />
        </div>
        </div>
       
      </div>

      <div className="pageContentHeaderDivider" />
      <div className="dictAllRowsContainer">
        {support &&
          support.items &&
          support.items.length > 0 &&
          support.items.map((el, index) => {
            return (
              <div
                key={index}
                className="catVideosRowNormal"
                onClick={() =>
                  navigate(
                    el.statusId === "28a1b923-1b1d-45b9-8839-6bgfd1afa365"
                      ? `/supportedit/${el.id}`
                      : `/supportuseredit/${el.id}`
                  )
                }
              >
                <div className="catVideoValue">
                  <div
                    style={{
                      backgroundImage: `url("${el.createdBy?.photo}")`,
                    }}
                    className="myObjectValueImage"
                  />
                </div>
                <div className="catVideoValue">{el.topic}</div>
                <div className="catVideoValue">{listId.list[el.statusId]}</div>

                <div className="catVideoValue">
                  {getDate(el.lastStatusChange,'dd/mm/yyyy hh:mm','.')}
                  <span>змінено</span>
                </div>
              </div>
            );
          })}
      </div>

      {support.total > 10 && (
        <Pagination
          className="pagination-bar"
          currentPage={currentPage}
          totalCount={support.total.toFixed(0)}
          pageSize="10"
          onPageChange={async (page) => {
            await setCurrentPage(page);
            await initFunc(page);
          }}
        />
      )}
    </>
  );
};

export default SupportListPage;
