import {
    CREATEPARKMESSAGES_SUCCESS,
    CREATEPARKMESSAGES_FAIL,
    GETPARKMESSAGES_SUCCESS,
    GETPARKMESSAGES_FAIL,
    GETPARKMESSAGESITEM_SUCCESS,
    GETPARKMESSAGESITEM_FAIL,
    EDITPARKMESSAGES_SUCCESS,
    EDITPARKMESSAGES_FAIL,
    DELETEPARKMESSAGES_SUCCESS,
    DELETEPARKMESSAGES_FAIL
} from "./types";


import ParksMessagesService from "../services/parksMessages.service"


export const addParkMessages = (data)=> async(dispatch)=>{
    try {
        const res = await ParksMessagesService.addParkMessages(data);
        dispatch({
          type: CREATEPARKMESSAGES_SUCCESS,
          item: res
        });
        return res
    }catch (error){
        dispatch({
            type: CREATEPARKMESSAGES_FAIL,
            item: {error: error.response},
          });
          const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
          return error.response;
    }
}


export const getParksMessages = (data)=> async(dispatch)=>{
    try{
        const res = await ParksMessagesService.getParksMessages(data);
        
        dispatch({
          type: GETPARKMESSAGES_SUCCESS,
          list: res,
        });
        
        return res
    }catch (error){
        
        dispatch({
            type: GETPARKMESSAGES_FAIL,
            list: {error: error.response},
          });
          const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
          return error.response
    }
}

export const editParkMessages= (form)=>(dispatch)=>{
      
    return ParksMessagesService.editParkMessages(form).then(
        (response)=>{
              
            dispatch({
                type:EDITPARKMESSAGES_SUCCESS,
                item:response.item
            });
              
            return response.item

        },
        (error)=>{
            dispatch({
                type: EDITPARKMESSAGES_FAIL,
              });
            console.log(error);
              return Promise.reject();
        }
    );
}



export const deleteParkMessages= (data)=>(dispatch)=>{
      
    return ParksMessagesService.deleteParkMessages(data).then(
        (response)=>{
            dispatch({
                type:DELETEPARKMESSAGES_SUCCESS,
            });
              
        },
        (error)=>{
            dispatch({
                type: DELETEPARKMESSAGES_FAIL,
              });
            console.log(error);
                 
              return Promise.reject();
        }
    );
}

export const getParkMessagesItem = (data)=>(dispatch)=>{
    return ParksMessagesService.getParkMessagesItem(data).then(
        (response)=>{
              
            dispatch({
                type:GETPARKMESSAGESITEM_SUCCESS,
                item:response,
            });
              
            return response

        },
        (error)=>{
            dispatch({
                type: GETPARKMESSAGESITEM_FAIL,
              });
            console.log(error);
                 
              return Promise.reject();
        }
    );

}