import { Swiper as Slider, SwiperSlide as Slide } from "swiper/react";
import { Navigation, Pagination, EffectFade } from "swiper";
import "swiper/css";

import { Icon } from "../Icon";
import React, { useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { changeFcUserStatus } from "../../redux/actions/fcUsers";
import { changeFcEventsUserStatus } from "../../redux/actions/fcEventsUsers";
import getDate from "../../helpers/getDate";
import checkText from "../../helpers/checkText";

const Tile = (props) => {
  const [open, setOpen] = useState("");
  const { fc, currentEvent } = useSelector((state) => state);
  const { currentUser } = useSelector((state) => state.currentUser);
  const currentId = useParams().id;
  const dispatch = useDispatch();
  const navigate=useNavigate()
  const { list } = useSelector((state) => state.listId);

  const openInNewTab = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  const userClick=(e)=>{
    e.preventDefault();
    navigate(`/personalPage/${props.id}`)
  } 

  const leaveClub = (e) => {
    let data = { userId: e.target.id, id: currentId, type: "remove-user" };
    dispatch(changeFcUserStatus(data)).then((res) => {
      props.setReady(false);
    });
  };

  const leaveEvent = (e) => {
    let data = { userId: props.id, id: currentId, type: "leave" };
    dispatch(changeFcEventsUserStatus(data)).then((res) => {
      window.location.reload();
    });
  };


  const setActingTrue = (e) => {
    let data = { userId: e.target.id, id: currentId, type: "set-acting" };
    dispatch(changeFcUserStatus(data)).then((res) => {
      props.setReady(false);
    });
  };

  const setLeadingTrue = (e) => {
    let data = { userId: e.target.id, id: currentId, type: "set-leading" };
    dispatch(changeFcEventsUserStatus(data)).then((res) => {
      props.setReady(false);
    });
  };

  const setActingFalse = (e) => {
    let data = { userId: e.target.id, id: currentId, type: "remove-acting" };
    dispatch(changeFcUserStatus(data)).then((res) => {
      props.setReady(false);
    });
  };

  const setLeadingFalse = (e) => {
    let data = { userId: e.target.id, id: currentId, type: "remove-leading" };
    dispatch(changeFcEventsUserStatus(data)).then((res) => {
      props.setReady(false);
    });
  };

  const rejectUser = (e) => {
    let data = { userId: e.target.id, id: currentId, type: "reject-user" };
    dispatch(changeFcUserStatus(data)).then((res) => {
      props.setReady(false);
    });
  };
  const acceptUser = (e) => {
    let data = { userId: e.target.id, id: currentId, type: "approve-user" };
    dispatch(changeFcUserStatus(data)).then((res) => {
      props.setReady(false);
    });
  };
  const rejectEventUser = (e) => {
    let data = { userId: e.target.id, id: currentId, type: "reject" };
    dispatch(changeFcEventsUserStatus(data)).then((res) => {
      props.setReady(false);
    });
  };

  const acceptEventUser = (e) => {
    let data = { userId: e.target.id, id: currentId, type: "accept" };
    dispatch(changeFcEventsUserStatus(data)).then((res) => {
      props.setReady(false);
    });
  };
  const favouriteClick =(e)=>{

  }



  const buttonsList = useMemo(() => {
    if (!props.el || !props.el.userClub || !currentUser) {
      return [];
    }

    let obj = props.el?.userClub;
    if (props.id === currentUser?.id && !obj.isCoordinator) {
      return [{ text: "Покинути клуб", onButtonClick: leaveClub }];
    } else if (
      obj?.isActing &&
      !obj?.isCoordinator &&
      props.id !== currentUser?.id
    ) {
      return [
        { text: "Зняти організатора", onButtonClick: setActingFalse },
        { text: "Видалити користувача", onButtonClick: leaveClub },
      ];
    } else if (!obj.isActing && !obj.isCoordinator && !obj.isApproved) {
      return [
        { text: "Прийняти користувача", onButtonClick: acceptUser },
        { text: "Відхилити користувача", onButtonClick: rejectUser },
      ];
    } else if (!obj.isActing && !obj.isCoordinator && obj.isApproved) {
      return [
        { text: "Зробити організатором", onButtonClick: setActingTrue },
        { text: "Видалити користувача", onButtonClick: leaveClub },
      ];
    } else {
      return [];
    }
  }, [props.el?.userClub, open]);

  const getSex = useMemo(() => {
    if (!props || props.sex === null) return;
    switch (props.sex) {
      case "male":
        return { msg: " Чоловіча", ending: "ий" };
      case "female":
        return { msg: " Жіноча", ending: "a" };
      default:
        return;
    }
  }, [props.sex]);

  const ageStatus = useMemo(() => {
    if (!props || !props.age) return;
    const num = Number(props.age);

    if (num < 45) return `Молод${getSex?.ending ?? "(ий/a)"}`;
    if (num < 59) return `Зріл${getSex?.ending ?? "(ий/a)"}`;
    if (num >= 60) return `Літн(ій/я)`;
  }, [props.age, getSex]);

  const buttonsFcEventsList = useMemo(() => {
    if (!props.el || !props.el.userSportEvents || !currentUser) {
      return [];
    }

    let obj = props.el?.userClub;
    let objEvent =props.el?.userSportEvents[0];


    if ((props.id === currentUser?.id && !obj?.isCoordinator )||  (props.id === currentUser?.id && objEvent?.isApproved && !objEvent?.isCoordinator)) {
      return [{ text: "Покинути захід", onButtonClick: leaveEvent }];
    } else if (props.id !== currentUser?.id && (obj?.isCoordinator|| objEvent?.isCoordinator || objEvent?.isLeading)) {
      return [
        { text: "Зняти організатора", onButtonClick: setLeadingFalse },
        { text: "Видалити користувача", onButtonClick: leaveEvent },
      ];
    } else if (!objEvent?.isLeading && !objEvent?.isCoordinator && !objEvent?.isApproved) {
      return [
        { text: "Прийняти користувача", onButtonClick: acceptEventUser },
        { text: "Відхилити користувача", onButtonClick: rejectEventUser },
      ];
    } else if (!objEvent?.isLeading && !objEvent?.isCoordinator && objEvent?.isApproved) {
      return [
        { text: "Зробити організатором", onButtonClick: setLeadingTrue },
        { text: "Видалити користувача", onButtonClick: leaveEvent },
      ];
    } else {
      return [];
    }
  }, [props.el?.userClub, open]);




  switch (props.type) {

    case "MAP":
      return (
        <div className="apMapTileContainerRoute">
          <div className="apMapTile">
            <div className="startPageItem"
                onClick={() => navigate(`/parkInfo/${props.id}`)}
                style={{
                  backgroundImage: `linear-gradient(0deg, rgba(0,0,0,0.6) 10%, rgba(0,0,0,0) 70%), url('${
                    props.img || (props.photos?.length>0&&props.photos[0]) ||
                    "https://ap.sportforall.gov.ua/api/v1/uploads/b34604xc-02qb-46bb-9e91-fc3ffrb92w41"
                    }')`,
                }}>
                    <div className="leftsideInfoStartPage">
                      <div className="leftsideInfoStartPageHeader"> {checkText(props.title, 23)}</div>
                    </div>
                      {props.range && <div  className="rightSideInfoStartPage" onClick={() =>
                            openInNewTab(
                              `https://www.google.com.ua/maps/place/${props.location[0]}+${props.location[1]}`
                            )
                          }>
                        {props.range} км
                    </div>}
                </div>
            <div className="buttonsNav">{props.nav}</div>
          </div>
        </div>
      );
      case "Route":
        return (
          <div className="apMapTileContainerRoute">
            <div className="apMapTile">
              <div className="startPageItem"
                onClick={() => navigate(`/routeInfo/${props.el.id}`)}
                style={{
                  backgroundImage: `linear-gradient(0deg, rgba(0,0,0,0.6) 10%, rgba(0,0,0,0) 70%), url('${
                    (props.el.coverImage.length>0 && props.el.coverImage )||  (props.el.photos !== null &&
                      props.el.photos?.length !== 0 &&
                      props.el.photos[0]) ||
                    "https://ap.sportforall.gov.ua/api/v1/uploads/b34604xc-02qb-46bb-9e91-fc3ffrb92w41"
                    }')`,
                }}>
                    <div className="topsideInfoStartPage">
                      <div></div>
                      {/* {currentUser && <div className={props.el.favorites ?"isFilled":''} onClick={(e)=>{e.preventDefault();e.stopPropagation();favouriteClick();}}><Icon type={'Bookmark'}></Icon></div>} */}
                    </div>
                    <div className="leftsideInfoStartPage">
                     {props.el?.typeId &&<div className='leftsideInfoStartPageIcon'>
                        <Icon type={props.el.typeId==='83a70a99-f4e2-4b2d-b004-0ad75e1dbb43'&& 'Run' || props.el.typeId==='625d2bd5-f045-4341-a2e1-e53ef1f45104'&& 'Bicycle' || props.el.typeId==='e8b243de-fba4-4b16-bb6b-30a38e227328'&& 'Scandinavian' || props.el.typeId==='eb1f23b9-a872-43ef-8462-a513504af109'&& 'Walk'}></Icon>
                      </div>}
                      <div className="leftsideInfoStartPageHeader"> {checkText(props.el.name || props.el.address, 23)}</div>
                      <div className="leftsideInfoStartPageSubText">{list[props.el.typeId]}</div>
                    </div>
                  {props.el.distanceToPoint && <div  className="rightSideInfoStartPage" onClick={() =>
                            openInNewTab(
                              `https://www.google.com.ua/maps/place/${props.el.location[0]}+${props.el.location[1]}`
                            )
                          }>
                        {props.el.distanceToPoint?.toFixed(1)} км
                    </div>}
                </div>
             {props.nav && <div className="buttonsNav">{props.nav}</div>}
            </div>

          </div>
        );
    case "FC":
      return (
        <div className="fcNewstItem">
          <h3 onClick={props.onDetailsClick}>{props.title}</h3>
          {/* <div>Опубліковано: {getDate(props.publishedAt,'dd/mmmm/yyyy hh:mm',' ')}</div> */}
          
          {props.statusId =='384fc74a-254e-47d7-9fcc-cd608d7e5f9d'?<div className="date" style={{background:'gray',color:'white'}}>Чорнетка</div>:<div className="date">Опубліковано: {getDate(props.publishedAt,'dd/mmmm/yyyy hh:mm',' ')}</div>}
          {props.img.length !== 0 && (
            <div className="imgWrp">
              {props.img.length === 1 ? (
                <img
                  src={props.img[0]}
                  alt={`${props.title}`}
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = props.onError;
                  }}
                />
              ) : (
                <Slider
                  modules={[Navigation, Pagination, EffectFade]}
                  navigation
                  pagination={{ clickable: true }}
                >
                  {props.img.map((el, index) => (
                    <Slide key={index}>
                      <img
                        src={el}
                        alt={props.title}
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src = props.onError;
                        }}
                      />
                    </Slide>
                  ))}
                </Slider>
              )}
            </div>
          )}
          <div
            style={{ marginTop: "10px" }}
            dangerouslySetInnerHTML={{ __html: props.text }}
          ></div>

          <div className="buttonWrap">
            <p onClick={props.onDetailsClick} className="more">
              {"Докладніше"}
            </p>
            {(props.isCurrentUser || currentUser.roleId==='d379ecaa-fee7-48a4-84df-a176f47820e6') && (
              <button onClick={props.onCreationButtonClick}>
                <Icon type={"edit"} />
                Редагувати
              </button>
            )}
          </div>
        </div>
      );
    case "USER":
      return (
        <div className="apUserTile">
          <div className="apUserTileItem">
            {(fc &&
              fc.clubUser &&
              (fc.clubUser.isCoordinator || fc.clubUser?.isActing) &&
              // !props.el.userClub.isCoordinator)||(currentEvent && currentEvent.eventUser && (currentEvent.eventUser.isCoordinator||currentEvent.eventUser.isActing) ) && (
              !props.el?.userClub?.isCoordinator)&& (

                <div
                  className="popUp"
                  onClick={(e) =>
                    open === props.id ? setOpen("") : setOpen(props.id)
                  }
                >
                  <svg id={props.id} style={{ marginBottom: "8px" }}>
                    <use href="/images/icon24MoreVertical.svg#icon24"></use>
                  </svg>
                  {open === props.id && (
                    <div className="apSettingsList">
                      {buttonsList.map((el) => {
                        return (
                          <div id={props.id} onClick={el.onButtonClick}>
                            {el.text}
                          </div>
                        );
                      })}
                    </div>
                  )}
                </div>
              )}
              {currentEvent &&
              props.el.isCoordinator && (
                <div
                  className="popUp"
                  onClick={(e) =>
                    open === props.id ? setOpen("") : setOpen(props.id)
                  }
                >
                  <svg id={props.id} style={{ marginBottom: "8px" }}>
                    <use href="/images/icon24MoreVertical.svg#icon24"></use>
                  </svg>
                  {open === props.id && (
                    <div className="apSettingsList">
                      {buttonsList.map((el) => {
                        return (
                          <div id={props.id} onClick={el.onButtonClick}>
                            {el.text}
                          </div>
                        );
                      })}
                    </div>
                  )}
                </div>
              )}
            <div className="top">
              <div className="imgWrp">
                <img
                  src={props.img !== null ? props.img : "https://ap.sportforall.gov.ua/api/v1/uploads/b34604xc-02qb-46bb-9e91-fc3ffrb92w41"}
                  alt={`${props.nickname}`}
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src =
                      "https://ap.sportforall.gov.ua/api/v1/uploads/b34604xc-02qb-46bb-9e91-fc3ffrb92w41";
                  }}
                />
              </div>
              <div className="main">
                <a className="name" onClick={userClick}>{props.el.lastName && props.el.firstName?
                    <>{props.el.firstName} {props.el.lastName}</>
                  :  <>{props.nickname}</>}
                </a>
                {props.contact?.length>1 && <a href={`mailto:${props.contact}`} className="contact">
                  <Icon type={"at"} />
                  {checkText(props.contact,18)}
                </a>}
               {props.age && <div className="age">
                  <Icon type={"cake"} />
                    {props.age && ageStatus}
                </div>}
                {/* <span className={`status ${props.status.id}`}>
                  {props.status.text}
                </span> */}
              </div>
            </div>
            <div className="bottom">
              {props.adress &&<span>
                <Icon type={"pin"} />
                {'  '+props.adress}
              </span>}
              <div style={{display:'flex'}}>
                {props.permissionUser &&<span className="rating">
                  <Icon type={"weight"} />
                </span>}
                {/* {props.rating>0 &&<span className="rating">
                  <Icon type={"star"} />
                  {props.rating || 0}
                </span>} */}
              </div>
             
            </div>
          </div>
        </div>
      );
    case "USERINVITE":
      return(
        <div className="apUserTile">
          <div className="apUserTileItem apUserTileinvite">
           {(fc?.clubUser?.isCoordinator || fc?.clubUser?.isActing)&& <div className="apUserTileinviteController">
              <p>Прийняти заявку?</p>
              <div className="apUserTileinviteControllerButton">
                    <button className="tetriaryButtonIconDefault" onClick={acceptUser} id={props.el.id} > 
                                <svg id={props.el.id}>
                                  <use
                                    id={props.el.id}
                                    href="/images/icon24CheckCircle.svg#icon24"
                                  />
                                </svg></button>
                    <button className="tetriaryButtonIconDefault"  onClick={rejectUser} id={props.el.id} > 
                          <svg id={props.el.id}>
                            <use
                              id={props.el.id}
                              href="/images/icon24CancelCircle.svg#icon24"
                            />
                          </svg></button>
                         
                      <button className="tetriaryButtonIconDefault" onClick={userClick} id={props.el.id} > 
                          <svg id={props.el.id}>
                            <use
                              id={props.el.id}
                              href="/images/icon24User.svg#icon24"
                            />
                          </svg></button>
              </div>
            </div>}
                {/* <div
                  className="popUp"
                  onClick={(e) =>
                    open === props.id ? setOpen("") : setOpen(props.id)
                  }
                >
                  <svg id={props.id} style={{ marginBottom: "8px" }}>
                    <use href="/images/icon24MoreVertical.svg#icon24"></use>
                  </svg>
                  {open === props.id && (
                    <div className="apSettingsList">
                      {buttonsList.map((el) => {
                        return (
                          <div id={props.id} onClick={el.onButtonClick}>
                            {el.text}
                          </div>
                        );
                      })}
                    </div>
                  )}
                </div> */}
            <div className="top">
              <div className="imgWrp">
                <img
                  src={props.img !== null ? props.img : "https://ap.sportforall.gov.ua/api/v1/uploads/b34604xc-02qb-46bb-9e91-fc3ffrb92w41"}
                  alt={`${props.nickname}`}
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src =
                      "https://ap.sportforall.gov.ua/api/v1/uploads/b34604xc-02qb-46bb-9e91-fc3ffrb92w41";
                  }}
                />
              </div>
              <div className="main">
                <a className="name" onClick={userClick}>{props.el.lastName && props.el.firstName?
                    <>{props.el.firstName} {props.el.lastName}</>
                  :  <>{props.nickname}</>}
                </a>
                {props.contact?.length>1 && <a href={`mailto:${props.contact}`} className="contact">
                  <Icon type={"at"} />
                  {checkText(props.contact,18)}
                </a>}
               {props.age &&  <div className="age">
                  <Icon type={"cake"} />
                    {props.age && ageStatus}
                </div>}
              </div>
            </div>
            <div className="bottom">
             
              <div style={{display:'flex'}}>
                {props.permissionUser &&<span className="rating">
                  <Icon type={"weight"} />
                </span>}
                {/* {props.rating>0 &&<span className="rating">
                  <Icon type={"star"} />
                  {props.rating || 0}
                </span>} */}
              </div>
             
            </div>
          </div>
        </div>
      );
    
    case "USERFC":
        return (
          <div className="apUserTile">
            <div className="apUserTileItem">
              {currentEvent && currentEvent.eventUser && (currentEvent.eventUser.isCoordinator||currentEvent.eventUser.isLeading) && (
                  <div
                    className="popUp"
                    onClick={(e) =>
                      open === props.id ? setOpen("") : setOpen(props.id)
                    }
                  >
                    <svg id={props.id} style={{ marginBottom: "8px" }}>
                      <use href="/images/icon24MoreVertical.svg#icon24"></use>
                    </svg>
                    {open === props.id && (
                      <div className="apSettingsList">
                        {buttonsFcEventsList.length>0 && buttonsFcEventsList.map((el) => {
                          
                          return (
                            <div id={props.id} onClick={el.onButtonClick}>
                              {el.text}
                            </div>
                          );
                        })}
                      </div>
                    )}
                  </div>
                )}
                {currentEvent &&
                props.el.isCoordinator && (
                  <div
                    className="popUp"
                    onClick={(e) =>
                      open === props.id ? setOpen("") : setOpen(props.id)
                    }
                  >
                    <svg id={props.id} style={{ marginBottom: "8px" }}>
                      <use href="/images/icon24MoreVertical.svg#icon24"></use>
                    </svg>
                    {open === props.id && (
                      <div className="apSettingsList">
                        {buttonsList.map((el) => {
                          return (
                            <div id={props.id} onClick={el.onButtonClick}>
                              {el.text}
                            </div>
                          );
                        })}
                      </div>
                    )}
                  </div>
                )}
              <div className="top">
                <div className="imgWrp">
                  <img
                    src={props.img !== null ? props.img : "https://ap.sportforall.gov.ua/api/v1/uploads/b34604xc-02qb-46bb-9e91-fc3ffrb92w41"}
                    alt={`${props.nickname}`}
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src =
                        "https://ap.sportforall.gov.ua/api/v1/uploads/b34604xc-02qb-46bb-9e91-fc3ffrb92w41";
                    }}
                  />
                </div>
                <div className="main">
                  <a className="name" onClick={userClick}>{props.el.lastName && props.el.firstName ?
                    <>{checkText(props.el.firstName,18)} <br /> {checkText(props.el.lastName,18)}</>
                  :  <>{checkText(props.nickname,18)}</>}</a>
                  <div className="role"> {list[props.el.roleId]} </div>

                  {/* {props.contact?.length>1 && <a href={`mailto:${props.contact}`} className="contact">
                    <Icon type={"at"} />
                    {checkText(props.contact,18)}
                  </a>} */}
                 {/* { props.age &&  <div className="age">
                  <Icon type={"cake"} />
                    {props.age && ageStatus}
                  </div>} */}
                 {/* {props.adress && <div className="age">
                  <Icon type={"pin"} />
                    {checkText(props.adress,22)}
                </div>} */}
                  {/* <span className={`status ${props.status.id}`}>
                    {props.status.text}
                  </span> */}
                </div>
              </div>
              {/* <div className="bottom"> */}
                {/* <a>
                  <Icon type={"pin"} />
                  {props.adress}
                </a> */}
                {/* <span className="rating">
                  <Icon type={"star"} />
                  {props.rating || 0}
                </span> */}
              {/* </div> */}
            </div>
          </div>
        );
        case "USERFCINVITE":
          return (
            <div className="apUserTile">
              <div className="apUserTileItem apUserTileinvite">
             {(currentEvent?.eventUser?.isCoordinator||currentEvent?.eventUser?.isLeading) &&<div className="apUserTileinviteController">
              <p>Прийняти заявку?</p>
              <div className="apUserTileinviteControllerButton">
                    <button className="tetriaryButtonIconDefault" onClick={acceptEventUser} id={props.el.id} > 
                                <svg id={props.el.id}>
                                  <use
                                    id={props.el.id}
                                    href="/images/icon24CheckCircle.svg#icon24"
                                  />
                                </svg></button>
                    <button className="tetriaryButtonIconDefault"  onClick={rejectEventUser} id={props.el.id} > 
                          <svg id={props.el.id}>
                            <use
                              id={props.el.id}
                              href="/images/icon24CancelCircle.svg#icon24"
                            />
                          </svg></button>
                         
                      <button className="tetriaryButtonIconDefault" onClick={userClick} id={props.el.id} > 
                          <svg id={props.el.id}>
                            <use
                              id={props.el.id}
                              href="/images/icon24User.svg#icon24"
                            />
                          </svg></button>
              </div>
            </div>}
                <div className="top">
                  <div className="imgWrp">
                    <img
                      src={props.img !== null ? props.img : "https://ap.sportforall.gov.ua/api/v1/uploads/b34604xc-02qb-46bb-9e91-fc3ffrb92w41"}
                      alt={`${props.nickname}`}
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src =
                          "https://ap.sportforall.gov.ua/api/v1/uploads/b34604xc-02qb-46bb-9e91-fc3ffrb92w41";
                      }}
                    />
                  </div>
                  <div className="main">
                    <a className="name" onClick={userClick}>{props.el.lastName && props.el.firstName ?
                      <>{checkText(props.el.firstName,18)} <br /> {checkText(props.el.lastName,18)}</>
                    :  <>{checkText(props.nickname,18)}</>}</a>
                    {props.contact?.length>1 && <a href={`mailto:${props.contact}`} className="contact">
                      <Icon type={"at"} />
                      {checkText(props.contact,18)}
                    </a>}
                   {props.age &&<div className="age">
                    <Icon type={"cake"} />
                      {props.age && ageStatus}
                    </div>}
                    {props.adress && <div className="age">
                    <Icon type={"pin"} />
                      {checkText(props.adress,22)}
                  </div>}
                    {/* <span className={`status ${props.status.id}`}>
                      {props.status.text}
                    </span> */}
                  </div>
                </div>
                <div className="bottom">
                  {/* <a>
                    <Icon type={"pin"} />
                    {props.adress}
                  </a> */}
                  {/* <span className="rating">
                    <Icon type={"star"} />
                    {props.rating || 0}
                  </span> */}
                </div>
              </div>
            </div>
          );
      default:
      return (
        <div className="apTileContainer">
          <a
            onClick={props.onClick || null}
            href={props.to || null}
            className="apTile"
          >
            <span>{props.title}</span>
            <div>Опубліковано: {getDate(props.publishedAt,'dd/mmmm/yyyy hh:mm',' ')}</div>
            <img
              src={
                props.img !== null || props.img !== undefined
                  ? props.img
                  : "https://ap.sportforall.gov.ua/api/v1/uploads/b34604xc-02qb-46bb-9e91-fc3ffrb92w41"
              }
              onError={(e) => {
                e.target.onerror = null;
                e.target.src =
                  "https://ap.sportforall.gov.ua/api/v1/uploads/b34604xc-02qb-46bb-9e91-fc3ffrb92w41";
              }}
              alt={`${props.title}`}
            />
          </a>
        </div>
      );
  }
};

export default Tile;
