import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  MapContainer,
  TileLayer,
  Marker,
  useMap,
  Polyline,
} from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import axios from "axios";
import { Icon } from "../../../components/Icon";
import { useSelector } from "react-redux";
import MyLocationMapButton from "../../../components/MyLocation/MyLocationMapButton";

const markerIcon = new L.Icon({
  iconUrl: require("../../../assets/images/map_icon_mini.png"),
  iconSize: [24, 24],
  iconAnchor: [12, 24], //[left/right, top/bottom]
  popupAnchor: [0, 0], //[left/right, top/bottom]
});

const markerIconType0 = new L.Icon({
  iconUrl: require("../../../assets/images/material-symbols_location-on.png"),
  iconSize: [24, 24],
  iconAnchor: [11, 22], //[left/right, top/bottom]
  popupAnchor: [0, 0], //[left/right, top/bottom]
});

const markerIconType1 = new L.Icon({
  iconUrl: require("../../../assets/images/material-symbols_location-on-1.png"),
  iconSize: [24, 24],
  iconAnchor: [11, 22], //[left/right, top/bottom]
  popupAnchor: [0, 0], //[left/right, top/bottom]
});

const markerIconType2 = new L.Icon({
  iconUrl: require("../../../assets/images/material-symbols_location-on-2.png"),
  iconSize: [24, 24],
  iconAnchor: [11, 22], //[left/right, top/bottom]
  popupAnchor: [0, 0], //[left/right, top/bottom]
});

const markerIconType3 = new L.Icon({
  iconUrl: require("../../../assets/images/material-symbols_location-on-3.png"),
  iconSize: [24, 24],
  iconAnchor: [11, 22], //[left/right, top/bottom]
  popupAnchor: [0, 0], //[left/right, top/bottom]
});


const POSITION_CLASSES = {
  bottomleft: "leaflet-bottom leaflet-left",
  bottomright: "leaflet-bottom leaflet-right",
  topleft: "leaflet-top leaflet-left",
  topright: "leaflet-top leaflet-right",
  top: "leaflet-top leaflet-center",
};
const markerIconCus = (index) => {
  return L.divIcon({
    html: `<div class="marker-icon">${index}</div>`,
    // iconSize: [36, 36],
    iconAnchor: [6, 22],
  });
};


const markerIconMy = new L.Icon({
  iconUrl: require("../../../assets/images/myLocation.png"),
  iconSize: [24, 24],
  iconAnchor: [12, 13], //[left/right, top/bottom]
  popupAnchor: [0, 0], //[left/right, top/bottom]
});

const markerIconPoint = new L.Icon({
  iconUrl: require("../../../assets/images/point.png"),
  iconSize: [12, 12],
  iconAnchor: [6, 7], //[left/right, top/bottom]
  popupAnchor: [0, 0], //[left/right, top/bottom]
});

const Map = (props) => {
  const [mapArr, setMappArr] = useState([]);
  const {coordinates} =useSelector(state=>state);
  const mapRef = useRef(null);

  const updateArr = () => {
    setMappArr(props.arrAll);
  };
  const changeArr = (ar) => {
    props.updateArr([...ar]);
    setMappArr([...ar]);
  };
  const redOptions = { color: "red" };

  const connectDots=(data)=> {
    let c = [];
    let i;
    for (i = 0; i < data.length; i += 1) {
      if(data[i]?.location){
        let coords = data[i].location;
        c.push(coords);
      }
    }
    return c;
  }

  useEffect(() => {
    updateArr();
  }, [props.arrAll, mapArr]);

  useEffect(() => {
    if (props.selected) {
      const selectedPoint = props.arrAll[props.selected];
      if(selectedPoint){
        const { location } = selectedPoint;
        mapRef.current.setView(location, 18); 
      }
      
    }
  }, [props.selected, props.arrAll]);

  return (
    <>
      <MapContainer
        style={{ height: 600, width: "60%" }}
        ref={mapRef}
        center={ 
          props.selected?[props.arrAll[+props.selected]?.location[0],props.arrAll[+props.selected]?.location[1]]:
          props.arrAll?.length > 0
          ? [props.arrAll[0]?.location[0], props.arrAll[0]?.location[1]]
          : coordinates.lat?[coordinates?.lat,coordinates?.lng] :[50.4393, 30.5479]}
        zoom={15}
        scrollWheelZoom={true}
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://openstreetmap.pp.ua/hot/{z}/{x}/{y}.png"
        />
       {props.el.statusId === "35rt3s0c-7bsa-46c7-a203-c16a4fsc3004" && <CurrentPosition
          mapArr={mapArr}
          setArr={changeArr}
          position="topright"
        />}
         {props.el.statusId === "35rt3s0c-7bsa-46c7-a203-c16a4fsc3004" && <SearchTab
          mapArr={mapArr}
          setArr={changeArr}
          position="top"
        />
        }
å
        <Polyline pathOptions={redOptions} positions={connectDots(props.arrAll)} />
        { props.arrAll?.length > 0 &&
           props.arrAll.map((el, index) => {
            return (
              <div key={index}>
               <Marker
                position={[el.location[0], el.location[1]]}
                icon={markerIconCus(index+1)}
              />
              <DraggableMarker
                location={[el.location[0], el.location[1]]}
                icon={props.selected===index+''?markerIconType2:(index===0||index===props.arrAll.length-1)?markerIconType3:(el.type === 0 ? markerIconType0 : markerIconPoint)}
                index={index}
                selected={props.selected==index}
                mapArr={props.arrAll}
                changeArr={changeArr}
              ></DraggableMarker>
              </div>
            );
          })}
          <MyLocationMapButton position='bottomright'/>

      </MapContainer>
    </>
  );
};

function CurrentPosition(props) {
  const map = useMap();

  const clickEvent = () => {
    let a = map.getCenter();
    let resArr = props.mapArr;
    let resObj = { type: 0 };
    resObj.location = [a.lat.toFixed(5), a.lng.toFixed(5)];
    resObj.pointIndex = resArr.length;
    // if(resArr.length<2){
    //   resObj.type=0;
    // }else if(resArr.length>=2){
    //   resArr[resArr.length-1].type=1;
    //   resObj.type=0;
    // }
    resArr.push(resObj);
    props.setArr(resArr);
    // dispatch(setCoordinate(data));
    // props.getMyCoordinates(data)
  };
  const positionClass =
    (props.position && POSITION_CLASSES[props.position]) ||
    POSITION_CLASSES.top;
  return (
    <div className={positionClass + " apAdmitPointList"}>
      <div className="leaflet-control leaflet-bar headline">
        <button onClick={clickEvent}>
          <Icon type={"pin"} /> Додати точку маршруту
        </button>
       
      </div>
    </div>
  );
}




function SearchTab(props){
  const [searchList, setSearchList] = useState([]);
  const map = useMap();
  const [searchOpen, setSearchOpen] = useState(false);
  const [city, setCity] = useState("");

  const cityChangeSelector = (e) => {
    setSearchOpen(true);
    setCity(e.target.value);
    if (e.target.value.length > 2) {
      axios
      .get(`https://map.technodreams.biz/search.php?q=${e.target.value}&accept-language=ua`)
      .then((res) => {
          setSearchList(res.data);
        });
    }
  }

  const setSearchListClick = (e) => {
    setSearchOpen(false);
    let a={lat:e.target.id.split("/")[0],lng:e.target.id.split("/")[1]}
    map.setView([a.lat, a.lng]);
    let resArr = props.mapArr;
    let resObj = { type: 0 };
    resObj.location = [a.lat, a.lng];
    resObj.pointIndex = resArr.length;
    resArr.push(resObj);
    props.setArr(resArr);
  };
  const positionClass =
  (props.position && POSITION_CLASSES[props.position]) ||
  POSITION_CLASSES.top;

  return (
    <div className={positionClass + " apAdmitPointList"}>
          <div className="leaflet-control leaflet-bar headline apInputText" style={{ position: "relative" }}>
            <label htmlFor="city">Пошук адреси</label>
            <input
              className="searchMapTabInput"
              onChange={cityChangeSelector}
              value={city}
              id="city"
              type="text"
            />
            {
              <div className="apOptionsList searchAdress searchMapTabList ">
                {searchList &&
                  searchList.length > 0 &&
                  searchList.map((el, index) => {
                    if(el.display_name.includes("Россия") || el.display_name.includes("Россія")){
                      return;
                    }else{
                      return (
                        <div
                          key={index}
                          id={el.lat + "/" + el.lon}
                          onClick={setSearchListClick}
                        >
                          {el.display_name}
                        </div>
                      );
                    }
                  })}
              </div>
            }
          </div>
        </div>
  )
}


function DraggableMarker(props) {
  const {events}=useSelector(state=>state)
  const [location, setLocation] = useState(props.location);
  const markerRef = useRef(null);
  const eventHandlers = useMemo(
    () => ({
      dragend() {
        const marker = markerRef.current;
        if (marker != null) {
          let newArr = props.mapArr;
          newArr[props.index].location = [
            marker.getLatLng().lat.toFixed(5),
            marker.getLatLng().lng.toFixed(5),
          ];
          props.changeArr(newArr);
          setLocation(marker.getLatLng());
        }
      },
    }),
    [props.mapArr]
  );

    useEffect(()=>{
      if(props.location!==location){
        setLocation(props.location)
      }
    },[props.location,location])

  return (
    <Marker
      draggable={events.statusId==='032fd5ba-f634-469b-3c30-77a1gh63a918'? false:true}
      eventHandlers={eventHandlers}
      position={location}
      icon={props.icon}
      ref={markerRef}
    ></Marker>
  );
}





export default Map;
