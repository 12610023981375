import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from "react-redux";



const PageNonAuthorized = ()=>{
    const navigate = useNavigate();
    let location = window.location.search.substring(1,);
    const { auth } = useSelector((state) => state);


    function handleAuthClick() {
      sessionStorage.setItem("link", location);
        navigate("/login");
      }
    function handleRegisterClick() {
      sessionStorage.setItem("link", location);
      navigate("/registration");
    }

    useEffect(() => {
      if(auth.isLoggedIn){
        navigate(location);
      }
    }, [auth, auth.isLoggedIn]);

    return(
    <div className="apFullHeight">
      <h1 style={{marginTop:'96px'}}>Для перегляду даної сторінки</h1>
      {/* <h4>Сторінка яку ви шукаєте не знайдена.<br />Можливо вона переміщена або не існує.</h4> */}
      <div>
      <button style={{marginTop:'60px'}} onClick={handleAuthClick} className="primaryButtonTextDefault">Авторизуйтеся</button>
      <h4  style={{marginTop:'20px'}}>Або</h4>
      <button style={{marginTop:'20px'}} onClick={handleRegisterClick} className="secondaryButtonTextDefault">Зареєструйтеся</button>
      </div>
      </div>
    )

}

export default PageNonAuthorized;