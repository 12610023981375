import {
  TRACK_SUCCESS,
  TRACK_FAIL,
  EDITTRACK_SUCCESS,
  EDITTRACK_FAIL,
  DELETETRACK_SUCCESS,
  DELETETRACK_FAIL,
  GETTRACKITEM_SUCCESS,
  GETTRACKITEM_FAIL,
  } from "../actions/types";


  
  const initialState = [];

  export default function (state = initialState, action) {
    const { type,item, payload } = action;

    switch (type) {
      case TRACK_SUCCESS:
        return { ...payload };
      case  TRACK_FAIL:
        return  state;
      case GETTRACKITEM_SUCCESS:
          return {...item};
      case GETTRACKITEM_FAIL:
          return state;
      case EDITTRACK_SUCCESS:
          return {...item};
      case EDITTRACK_FAIL:
          return state;
      case DELETETRACK_SUCCESS:
          return state;
      case DELETETRACK_FAIL:
          return state;
  
      default:
        return state;
    }
  }