import React, { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { FcHeader } from "./components/FcHeader";
import { Info } from "./components/Info";
import { FcNews } from "./components/News";
import { Events } from "./components/Events";

import { Tabbar } from "../../components/Tabbar";
import { useDispatch, useSelector } from "react-redux";
import { getFcItem } from "../../redux/actions/fc";
import Loader from "../../components/Loader/Loader";
import { Users } from "./components/Users";
import { addFcNews } from "../../redux/actions/fcNews";
import { Icon } from "../../components/Icon";
import { changeFcUserStatus } from "../../redux/actions/fcUsers";
import QrComponent from "./components/QrComponent";
import { addEvents } from "../../redux/actions/events";

const FCInnerPage = () => {
  const { currentUser } = useSelector((state) => state.currentUser);
  const { fc } = useSelector((state) => state);
  const { auth } = useSelector((state) => state);
  const contentId = useParams().contentId;
  const currentId = useParams().id;
  const [ready, setReady] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();


  const navbar = [
    {
      icon: "news",
      children: "Блог",
      route: "about",
    },
    {
      icon: "rocket",
      children:"Заходи",
      route: "events",
     
    },
    {
      icon: "users",
      children: "Учасники",
      route: "users",
    },
  ];

  const content = useMemo(() => {
    switch (contentId) {
      case "events":
        return <Events />;
      case "users":
        return <Users />;
      case "news":
      default:
        return <FcNews />;
    }
  }, [contentId]);

  const additionalClick = (e) => {
    let data = { id: currentId };
    dispatch(addFcNews(data)).then((res) => {
      setReady(false);
      navigate(`/fcEdit/${currentId}/news/${res.id}`);
    });
  };

  const AddEvents = (e) => {
    setReady(false);
    e.preventDefault();
    let data={};
    data.clubId=currentId;
    dispatch(addEvents(data))
      .then((res) => {
        navigate(`/eventsItem/${currentId}/${res.id}`);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const qrClick = (e) => {
    setModalOpen(true);
  };

  const leaveClub = () => {
    let data = { userId: currentUser.id, id: currentId, type: "remove-user" };
    dispatch(changeFcUserStatus(data)).then((res) => {
      setReady(false);
    });
  };

  const enterClub = () => {
    let data = { userId: currentUser.id, id: currentId, type: "apply-user" };
    dispatch(changeFcUserStatus(data)).then((res) => {
      setReady(false);
    });
  };

  const cancelClick = () => {
    let data = { userId: currentUser.id, id: currentId, type: "reject-user" };
    dispatch(changeFcUserStatus(data)).then((res) => {
      setReady(false);
    });
  };

  const buttonByRole = useMemo(() => {
    // if (contentId === "events"&& fc && fc.clubUser && (fc.clubUser.isCoordinator||fc.clubUser.isActing)) {
    
      if (
        contentId === "about" &&
        fc &&
        fc.clubUser &&
        (fc.clubUser?.isCoordinator || fc.clubUser?.isActing)
      ) {
        return {
          icon: "plus",
          text: "Створити блог",
          onButtonClick: additionalClick,
        };
      }
    
    if (
      contentId === "events" &&
      fc &&
      fc.clubUser &&
      (fc.clubUser?.isCoordinator || fc.clubUser?.isActing)
    ) {
      return { icon: "plus", text: "Створити захід", onButtonClick: AddEvents };
    }

    if (
      contentId === "users" &&
      fc &&
      fc.clubUser &&
      (fc.clubUser?.isCoordinator || fc.clubUser?.isActing)
    ) {
      return { icon: "qr", text: "Показати QR для вступу в клуб", onButtonClick: qrClick };
    }

    if (contentId === "users" && !auth.isLoggedIn) {
      return {
        icon: "login",
        text: "Авторизуватися",
        onButtonClick: () => {
          navigate("/login");
        },
      };
    }

    if (fc.clubUser === null ) {
      return {
        icon: "login",
        text: "Приєднатись до клубу",
        onButtonClick: enterClub,
      };
    }

    if (
      contentId === "users" &&
      fc &&
      fc.clubUser &&
      !fc.clubUser?.isApproved &&
      !fc.clubUser?.isCoordinator &&
      !fc.clubUser?.isActing
    ) {
      return {
        icon: "logout",
        text: "Відмінити заявку",
        onButtonClick: cancelClick,
      };
    }
    if (contentId === "users" && fc && fc.clubUser && fc.clubUser?.isApproved) {
      return {
        icon: "logout",
        text: "Вийти з клубу",
        onButtonClick: leaveClub,
      };
    }

    
    return;
  }, [contentId, fc.clubUser]);

  const initialFunc = () => {
    let data = {};
    data.id = currentId;
    dispatch(getFcItem(data)).then((res) => {
      setReady(true);
    });
  };

  useEffect(() => {
    if(!currentUser && !auth.isLoggedIn && !ready ){
      let url=window.location.pathname;
      navigate('/pagenonauthorized?'+url)
    }else {
      if (!ready) {
        initialFunc();
      }
    }
  }, [ready, contentId, auth]);

  if (!ready) {
    return <Loader />;
  } else if (ready) {
    return (
      <>
        <FcHeader
          title={fc.name}
          slogan={fc.tagline}
          statusId={fc.statusId}
          links={{
            youtubeUrl: fc.youtubeUrl,
            telegramUrl: fc.telegramUrl,
            instagramUrl: fc.instagramUrl,
            facebookUrl: fc.facebookUrl,
          }}
          tel={fc.phone}
          creationDate={fc.establishedAt}
          participants={fc.memberAmount}
          event={
            fc.soonestEvent !== null
              ? {
                  title: fc.soonestEvent && fc.soonestEvent.title,
                  date: fc.soonestEvent && fc.soonestEvent.startsAt,
                }
              : null
          }
          logo={fc.logoUrl}
        />
        <Info
          id={fc.id}
          logo={fc.logoUrl}
          createdBy={fc.createdBy?.id}
          creationDate={fc.establishedAt}
          participants={fc.memberAmount}
          about={<>{fc.description}</>}
        />
        {(fc.statusId === "e19aa583-d4c7-476c-8be7-37fa5f5710f6" ||
          currentUser?.roleId === "d379ecaa-fee7-48a4-84df-a176f47820e6") && (
          <>
            <Tabbar
              contentId={contentId}
              currentId={currentId}
              data={navbar}
              additional={
                buttonByRole ? (
                  <button
                    //className={`additional ${contentId ?? ""}`}
                    className = "primaryButtonIconTextDefault"
                    onClick={buttonByRole.onButtonClick}
                  >
                    <Icon type={buttonByRole.icon} />
                    {buttonByRole.text}
                  </button>
                ) : null
              }
              path={`/fc`}
            />
            {content}
          </>
        )}
        {modalOpen && (
          <QrComponent fc={fc} setModalOpen={setModalOpen}></QrComponent>
        )}
      </>
    );
  }
};
export default FCInnerPage;
