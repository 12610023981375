import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  useMap,
  Polyline,
} from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import MyLocationMapButton from "../../../../components/MyLocation/MyLocationMapButton";
import { useSelector } from "react-redux";

const markerIcon = new L.Icon({
  iconUrl: require("../../../../assets/images/map_icon_mini.png"),
  iconSize: [24,24],
  iconAnchor: [12, 24], //[left/right, top/bottom]
  popupAnchor: [0, 0], //[left/right, top/bottom]
});

const markerIconCus = (index) => {
  return L.divIcon({
    html: `<div class="marker-icon">${index}</div>`,
    // iconSize: [36, 36],
    iconAnchor: [6, 22],
  });
};


const markerIconMy = new L.Icon({
  iconUrl: require("../../../../assets/images/myLocation.png"),
  iconSize: [24, 24],
  iconAnchor: [12, 13], //[left/right, top/bottom]
  popupAnchor: [0, 0], //[left/right, top/bottom]
});

const markerIconPoint = new L.Icon({
  iconUrl: require("../../../../assets/images/point.png"),
  iconSize: [12, 12],
  iconAnchor: [6, 7], //[left/right, top/bottom]
  popupAnchor: [0, 0], //[left/right, top/bottom]
});

const markerIconType0 = new L.Icon({
  iconUrl: require("../../../../assets/images/material-symbols_location-on.png"),
  iconSize: [24, 24],
  iconAnchor: [11, 22], //[left/right, top/bottom]
  popupAnchor: [0, 0], //[left/right, top/bottom]
});

const markerIconType1 = new L.Icon({
  iconUrl: require("../../../../assets/images/material-symbols_location-on-1.png"),
  iconSize: [24, 24],
  iconAnchor: [11, 22], //[left/right, top/bottom]
  popupAnchor: [0, 0], //[left/right, top/bottom]
});

const markerIconType2 = new L.Icon({
  iconUrl: require("../../../../assets/images/material-symbols_location-on-2.png"),
  iconSize: [24, 24],
  iconAnchor: [11, 22], //[left/right, top/bottom]
  popupAnchor: [0, 0], //[left/right, top/bottom]
});

const markerIconType3 = new L.Icon({
  iconUrl: require("../../../../assets/images/material-symbols_location-on-3.png"),
  iconSize: [24, 24],
  iconAnchor: [11, 22], //[left/right, top/bottom]
  popupAnchor: [0, 0], //[left/right, top/bottom]
});

const redOptions = { color: "red" };


const MapChild = (props) => {
  const maps = useMap();

  function connectDots(data = []) {
    let c = [];
    let i;
    for (i = 0; i < data.length; i += 1) {
      if( data[i]?.location){
        let coords = data[i].location;
        c.push(coords);
      }

    }
    return c;
  }

  
  useEffect(() => {
    if(props.arrAll){
      const newCenter = [props.arrAll[props.selected||0].location[0],props.arrAll[props.selected||0].location[1]];
      maps.setView(newCenter,18);
    }
    
  },[props.selected,props.arrAll])

  return (
    <>
      <Polyline
        pathOptions={redOptions}
        positions={connectDots(props.arrAll)}
      />
      {props.arrAll?.length > 0 &&
        props.arrAll.map((el, index) => {
          return (
            <>
            <Marker
              position={[el.location[0], el.location[1]]}
              icon={props.selected===el.pointIndex?markerIconType2:(index===0||index===props.arrAll.length-1)?markerIconType3:(el.type === 0 ? markerIconType0 : markerIconPoint)}
              index={index}
            ></Marker>
           {el.type === 0 && (
              <Marker
                key={index}
                position={[el.location[0], el.location[1]]}
                icon={markerIconCus(el.counter)}
              />
            )}

            </>
          );
        })}
    </>
  );
};

const Map = (props) => {
  const [mapArr, setMappArr] = useState(props.arrAll?props.arrAll:[]);
  const {coordinates} =useSelector(state=>state);

  const updateArr = () => {
    setMappArr(props.arrAll);
  };

  useEffect(() => {
    updateArr();
  }, [props.arrAll,props.selected]);

  

  return (
    <>
      <MapContainer
        style={{ height: 600, width: "100%" }}
        center={
          props.selected?[props.arrAll[props.selected-1]?.location[0],props.arrAll[props.selected-1]?.location[1]]:
          (props.arrAll?.length > 0
            ? [props.arrAll[0]?.location[0], props.arrAll[0]?.location[1]]
            : coordinates.lat?[coordinates.lat,coordinates.lng] 
            :[50.4393, 30.5479])
        }
        zoom={18}
        scrollWheelZoom={true}
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://openstreetmap.pp.ua/hot/{z}/{x}/{y}.png"
        />
        <MapChild arrAll={mapArr} selected={props.selected} />
        <MyLocationMapButton position='bottomright'/>
      </MapContainer>
    </>
  );
};



export default Map;
