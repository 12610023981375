import React, { useState } from "react";
import { Icon } from "../../../../components/Icon";


const Item = (props) => {
  const [modalOpen, setModalOpen]=useState(false);

  const ItemClick=(e)=>{
    e.preventDefault();
    e.stopPropagation();
    let data=props.item;
    data.index=props.index;
    props.setSelected(data);
  }

  return (
      <div className="apFcAdmitPointItem" style={{background:`${props.index >=props.selectedFrom-1 && props.index< props.selectedTo?'rgba(57, 208, 211, 0.2)':''}`}} onClick={ItemClick}>
        <div className="info">
          <div className="point">
            {props.index + 1}
          </div>
          <div className="date">
            {( props.item?.latitude.toFixed(7)) + ", " + (props.item?.longitude.toFixed(7))}
            <span>Координати</span>
          </div>
        </div>
        <div className="controls">
          {<> 
            {(props.item?.turn==='up'||props.item?.turn==='') && <Icon className={"cross"} type={"turn"} onClick={()=>setModalOpen(!modalOpen)} />}
            {props.item?.turn==='left' && <Icon className={"cross"} type={"turnLeft"} onClick={()=>setModalOpen(!modalOpen)} />}
            {props.item?.turn==='right' && <Icon className={"cross"} type={"turnRight"} onClick={()=>setModalOpen(!modalOpen)}/>}
            <Icon className={"cross"} type={"Trash"}  onClick={()=>props.onDelete(props.index)} />
          </>}
          {modalOpen && 
          <div className="modalwithButtons">
            <div><Icon className={"cross"} type={"cross"} onClick={()=>{setModalOpen(!modalOpen);props.onChangeTurn(props.index, '')}} /></div>
            <div><Icon className={"cross"} type={"turnLeft"} onClick={()=>{setModalOpen(!modalOpen);props.onChangeTurn(props.index, 'left')}} /></div>
            <div><Icon className={"cross"} type={"turn"} onClick={()=>{setModalOpen(!modalOpen);props.onChangeTurn(props.index, 'up')}} /></div>
            <div><Icon className={"cross"} type={"turnRight"} onClick={()=>{setModalOpen(!modalOpen);props.onChangeTurn(props.index, 'right')}} /></div>
          </div>}
        </div>
      </div>
  );
};


export default Item;