import { useMemo } from "react";
import { useLocation, useNavigate, useParams } from "react-router";

export const BreadCrumbs = (props) => {
  const navigate = useNavigate();
  const currentRoute = useLocation().pathname;
  const clubid = useParams().clubid;

  const links = useMemo(() => {
    let def = [
      { title: "Головна", url: "/" },
    ];
    if (clubid === "0") {
      return [...def, { title: "Заходи", url: "/eventsCalendar" }].concat({
        title: "Сторінка заходу",
        url: "/",
      });
    }
    return [...def, { title: "Клуби", url: "/fcList" }].concat(props?.fc);
  }, [props]);

  const handleClick = (url) => {
    if (currentRoute === `${url}`) return;
    navigate(`${url}`);
  };

  return (
    (links && (
      <ul className="apBreadCrumbs">
        {links.map(({ title, url }, index) => (
          <li key={`${title}.${url}.${index}`}>
            <button
              onClick={() => handleClick(url)}
              className={index === links.length - 1 ? "active" : null}
              disabled={index === links.length - 1}
            >
              {title}
            </button>
            {index !== links.length - 1 && <div className="arrow" />}
          </li>
        ))}
      </ul>
    )) ||
    null
  );
};
