import {
    CREATEFC_SUCCESS,
    CREATEFC_FAIL,
    GETFCPARTICIPANT_SUCCESS,
    GETFCPARTICIPANT_FAIL,
    GETFCCREATOR_SUCCESS,
    GETFCCREATOR_FAIL,
    GETFCITEM_SUCCESS,
    GETFCITEM_FAIL,
    EDITFC_SUCCESS,
    EDITFC_FAIL,
    DELETEFC_SUCCESS,
    DELETEFC_FAIL,
    GETFCLIST_SUCCESS,
    GETFCLIST_FAIL,
    CREATEQR_SUCCESS,
    CREATEQR_FAIL
} from "./types";

import FcService from "../services/fc.service"


export const getFcParticipant = (data) => async (dispatch) => {
    try {
        const res = await FcService.getFcParticipant(data);
        dispatch({
            type: GETFCPARTICIPANT_SUCCESS,
            list: res,
        });

        return res
    } catch (error) {

        dispatch({
            type: GETFCPARTICIPANT_FAIL,
            list: {
                error: error.response
            },
        });
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        return error.response
    }
}




export const getFcCreator = (data) => async (dispatch) => {
    try {
        const res = await FcService.getFcCreator(data);
        dispatch({
            type: GETFCCREATOR_SUCCESS,
            list: res,
        });

        return res
    } catch (error) {
        dispatch({
            type: GETFCCREATOR_FAIL,
            list: {
                error: error.response
            },
        });
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        return error.response
    }
}
export const getFcDeacticvate = (data) => async (dispatch) => {
    try {
        const res = await FcService.getFcDeacticvate(data);
        return res
    } catch (error) {
        return error.response
    }
}




export const getFcModerator = (data) => async (dispatch) => {
    try {
        const res = await FcService.getFcModerator(data);
        dispatch({
            type: GETFCCREATOR_SUCCESS,
            list: res,
        });

        return res
    } catch (error) {

        dispatch({
            type: GETFCCREATOR_FAIL,
            list: {
                error: error.response
            },
        });
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        return error.response
    }
}

export const getFcList = (data) => async (dispatch) => {

    try {
        const res = await FcService.getFcList(data);

        dispatch({
            type: GETFCLIST_SUCCESS,
            list: res,
        });

        return res
    } catch (error) {

        dispatch({
            type: GETFCLIST_FAIL,
            list: {
                error: error.response
            },
        });
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        return error.response
    }
}


export const editFc = (form) => (dispatch) => {

    return FcService.editFc(form).then(
        (response) => {

            dispatch({
                type: EDITFC_SUCCESS,
                item: response
            });

            return response

        },
        (error) => {
            dispatch({
                type: EDITFC_FAIL,
                item: {
                    error: error.response
                }
            });
            console.log(error);
            return {
                ...error.response.data?.errors?. [0] || error.response.data,
                isFeild: true
            };
        }
    );
}


export const createQr = (data) => (dispatch) => {

    return FcService.createQr(data).then(
        (response) => {
            dispatch({
                type: CREATEQR_SUCCESS,
            });
            return response;

        },
        (error) => {
            dispatch({
                type: CREATEQR_FAIL,
            });
            console.log(error);

            return Promise.reject();
        }
    );
}
export const readeQr = (data) => (dispatch) => {

    return FcService.readeQr(data).then(
        (response) => {
            dispatch({
                type: CREATEQR_SUCCESS,
            });
            return response;

        },
        (error) => {
            dispatch({
                type: CREATEQR_FAIL,
            });
            console.log(error);

            return Promise.reject();
        }
    );
}


export const deleteFc = (data) => (dispatch) => {

    return FcService.deleteFc(data).then(
        (response) => {
            dispatch({
                type: DELETEFC_SUCCESS,
            });

        },
        (error) => {
            dispatch({
                type: DELETEFC_FAIL,
            });
            console.log(error);

            return Promise.reject();
        }
    );
}

export const getFcItem = (data) => (dispatch) => {
    return FcService.getFcItem(data).then(
        (response) => {

            dispatch({
                type: GETFCITEM_SUCCESS,
                item: response,
            });

            return response

        },
        (error) => {
            dispatch({
                type: GETFCITEM_FAIL,
            });
            console.log(error);

            return Promise.reject();
        }
    );

}

export const addFc = (data) => async (dispatch) => {
    try {
        const res = await FcService.addFc(data);
        dispatch({
            type: CREATEFC_SUCCESS,
            item: res
        });
        return res
    } catch (error) {
        dispatch({
            type: CREATEFC_FAIL,
            item: {
                error: error.response
            },
        });
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        return error.response;
    }
}