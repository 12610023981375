import { useState } from "react";
import { Swiper as Slider, SwiperSlide as Slide } from "swiper/react";
import { Navigation, EffectFade } from "swiper";
import { v4 as uuid } from "uuid";

import "swiper/css";
import { Icon as Button } from "../../../../../components/Icon";
import getDate from "../../../../../helpers/getDate";
import { useDispatch } from "react-redux";
import { editWorkoutNote } from "../../../../../redux/actions/workouts";

export const CommentsSlider = (props) => {
  const [isEdit, setIsEdit] = useState(false);
  const [elementId, setElementId] = useState(0);
  const [currentSlideIndex, setCurrentSlideIndex] = useState(1);
  const [list, setList] = useState(props.comments||[]);
  const [form, setForm] = useState({});
  const dispatch = useDispatch();
  const id = uuid();


  const changeHandler = (e) => {
    setForm({ ...form, [e.target.id]: e.target.value });
    console.log(elementId);

  };
 
  const handleSaveClick = () => {
    const data = { id: list[elementId].id, workoutId: props.id, ...form };
    dispatch(editWorkoutNote(data)).then(() => {
      let arr = list;
      arr[elementId].title=form.title;
      setForm({});
      setElementId(0);
      setIsEdit(false);
      setList(arr);
    });

  };

  return (
    <div className="apCommentsSlider">
      <h3>Коментарі до заняття:</h3>
      {list?.length == 0 && (
        <div className="apComment">
          Жодного коментаря
        </div>
      )}
        {isEdit && <div className="apComment edit">
                      <span>{list[elementId].createdAt}</span>
                      <div className="apTextarea">
                        <label htmlFor={id}>Коментар</label>
                        <textarea name={id} id='title' value={form.title||props.comments[elementId].title||''} onChange={changeHandler}>
                        </textarea>
                      </div>
                      <div className="apCommentBtmLine">
                        <Button type={"file"} id={id} onClick={() => handleSaveClick(elementId)}>
                          Зберегти зміни
                        </Button>
                        <Button type={"roundCross"} onClick={() => {setIsEdit(false);setForm({})}}>
                          Скасувати
                        </Button>
                      </div>
                    </div>}

      {list?.length > 0 && (
        <>
          <Slider
            modules={[Navigation, EffectFade]}
            effect={"fade"}
            navigation
            initialSlide={list.length - 1}
            onSlideChange={(s) => {
              setIsEdit(false);
              setCurrentSlideIndex(s.realIndex + 1);
              setElementId(s.realIndex)
            }}
          >
            {list.map((el, index) => {
              return (
              <Slide key={el.id} className='mySwiperSlide'>
                {!isEdit  && (
                  <>
                    <button id={index} onClick={(e) => {setIsEdit(true)}}>
                      Редагувати
                    </button>
                    <div className="apComment">
                      <span>{getDate(el.createdAt, 'dd/mm/yyyy hh:mm', '.')}</span>
                      <p>{`${el.title}`}</p>
                    </div>
                  </>
                )}
               
          </Slide>)
        })}
        </Slider>
        <div className="apSlideCounter">
          {currentSlideIndex}/{list?.length}
        </div>
        </>
        ) 
        
      }
</div>
)
}
