import React, { useState } from 'react';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import { jwtDecode } from "jwt-decode";
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { loginGoogle } from '../../../redux/actions/auth';


function GoogleProvider(props) {
    const [eror, setError] = useState(false);
    const {auth} = useSelector((state) => state);
    const dispatch= useDispatch();
    const navigate = useNavigate();

  const handleGoogleSignInSuccess = async (response) => {
    props.setReady(false);
    console.log('Google sign-in success:', response);
    const { credential } = response;
    localStorage.setItem('GoogleLogSuccess',JSON.stringify(response));
    try {
        dispatch(loginGoogle({googleToken:credential})).then(res=>{
            if(res.error==='Користувача не знайдено, потрібна реєстрація'){
                navigate('/registration');
            }else{
                if(auth.isLoggedIn || (res.token && res.refreshToken)){
                    window.location.href = '/start';
                }
                else{
                    navigate('/registration');
                }
            }
        })
    } catch (error) {
      console.error('Error fetching user information:', error);
    }
  };

  const handleGoogleSignInFailure = (error) => {
    // Handle Google sign-in error here
    console.error('Google sign-in error:', error);
  };

  return (
    <GoogleOAuthProvider clientId="97103190835-1ucn7psnnptna8otqpdvirt5ua3c2sba.apps.googleusercontent.com">
        <GoogleLogin
        type="icon"
        shape='pill'
        size='large'
        width={48}
        clientId="YOUR_CLIENT_ID"
        onSuccess={handleGoogleSignInSuccess}
        onFailure={handleGoogleSignInFailure}
        >
        </GoogleLogin>
    </GoogleOAuthProvider>
  );
}

export default GoogleProvider;
