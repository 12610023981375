import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import Loader from "../../components/Loader/Loader";
import { getInfo } from "../../redux/actions/info";

const InfoListUserPage = () => {
  const dispatch = useDispatch();
  const [ready, setReady] = useState(false);
  const [openMobile, setOpenMobile] = useState(false);
  const { fullList } = useSelector((state) => state);
  const { info } = useSelector((state) => state);
  const currentId = useParams().id;
  const navigate = useNavigate();
  const { currentUser } = useSelector((state) => state.currentUser);


  
  const initFunc = () => {
    let data = {};
    data.filter = {
      sectionId: [
        `${
          currentId && currentId.length === 36
            ? currentId
            : "f460cc15-c64c-4d01-976a-6b46f1de37f2"
        }`,
      ],
    };

    dispatch(getInfo(data)).then((res) => {
      setReady(true);
    });
  };

  const linkClick = (e) => {
    navigate(e.currentTarget.id);
    setReady(false);
  };

  useEffect(() => {
    if (!ready) {
      initFunc();
    }
  }, [ready]);

  return (
    <>
      <div className="headerWithButtonsHeaderBlock">
        <h1>
          База знань{" "}
          <div className="headerSideText">
            Дізнайтесь про усі можливості порталу, щоб максимально
            використовувати потенціал сайту
          </div>
        </h1>
      </div>
      <div className="pageContentHeaderDivider">&nbsp;</div>
      {ready && (
        <div className="infoPage">
          <div className={openMobile?"infoPageSidebar mobCheck":"infoPageSidebar"} onClick={()=>{setOpenMobile(!openMobile)}}>
            <>{fullList.InfoPageSections &&
              fullList.InfoPageSections.map((el, index) => {
                if(index!=2 || (currentUser?.roleId!=='a2c99acd-0014-4fb3-8274-ad6a842f50ac' && currentUser?.roleId!=='62fe0318-c64a-490c-859d-9d313eacbf41' && currentUser))
                {
                return (
                  <div
                    key={index}
                    className={el.id === currentId ? "active":''}
                    id={`/infolist/${el.id}`}
                    onClick={linkClick}
                  >
                    {el.title.substr(3,)}
                  </div>
                )}
              })}
              <div className="infoSide" onClick={()=>{setOpenMobile(!openMobile)}}>
                X
              </div>
              </>
          </div>
          <div className="infoPageContent">
            {info?.items?.map((el, index) => {
              return (
                <div
                  key={index}
                  className="dictRowNormal"
                  id={"/info/" + el.id}
                  onClick={linkClick}
                >
                  <div className="dictRowValuesContainer">{el.title}</div>
                </div>
              );
            })}
          </div>
        </div>
      )}
      {!ready && <Loader />}
    </>
  );
};

export default InfoListUserPage;
