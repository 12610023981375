import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  MapContainer,
  TileLayer,
  Marker,
  useMap,
  Polyline,
} from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import { useSelector } from "react-redux";

const markerIconType0 = new L.Icon({
  iconUrl: require("../../../../assets/images/material-symbols_location-on.png"),
  iconSize: [24, 24],
  iconAnchor: [11, 22], //[left/right, top/bottom]
  popupAnchor: [0, 0], //[left/right, top/bottom]
});

const markerIconType2 = new L.Icon({
  iconUrl: require("../../../../assets/images/material-symbols_location-on-2.png"),
  iconSize: [24, 24],
  iconAnchor: [11, 22], //[left/right, top/bottom]
  popupAnchor: [0, 0], //[left/right, top/bottom]
});

const markerIconType3 = new L.Icon({
  iconUrl: require("../../../../assets/images/material-symbols_location-on-3.png"),
  iconSize: [24, 24],
  iconAnchor: [11, 22], //[left/right, top/bottom]
  popupAnchor: [0, 0], //[left/right, top/bottom]
});

const markerIconCus = (index) => {
  return L.divIcon({
    html: `<div class="marker-icon">${index}</div>`,
    // iconSize: [36, 36],
    iconAnchor: [6, 22],
  });
};

const markerIconPoint = new L.Icon({
  iconUrl: require("../../../../assets/images/point.png"),
  iconSize: [12, 12],
  iconAnchor: [6, 7], //[left/right, top/bottom]
  popupAnchor: [0, 0], //[left/right, top/bottom]
});

const Map = (props) => {
  const [mapArr, setMappArr] = useState([]);
  const {coordinates} =useSelector(state=>state);
  const mapRef = useRef(null);

  const changeArr = (ar) => {
    props.updateArr([...ar]);
    setMappArr([...ar]);
  };
  const redOptions = { color: "red" };
  const greenOptions = { color: "rgb(30, 189, 193)" };

  const connectDots=(data)=> {
    // let c = [[50.4393, 30.5479],[51.4393, 31.5479]];
    let c = [];
    let i;

    for (i = 0; i < data?.length; i += 1) {
      if(data[i]?.latitude){
        let coords = [data[i].latitude,data[i].longitude];
        c.push(coords);
      }
    }
    return c;
  }

  return (
    <>
      <MapContainer
        style={{ height: 430, width: "60%" }}
        ref={mapRef}
        center={ 
          props.el?.length>0 && props.el[0].latitude ? [props.el[0].latitude,props.el[0].longitude]:[50.4393, 30.5479]}
        zoom={15}
        scrollWheelZoom={true}
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://openstreetmap.pp.ua/hot/{z}/{x}/{y}.png"
        />
        <MyPolyline coordinates={connectDots(props.el)} />
        <Polyline pathOptions={redOptions} positions={connectDots(props.el)} />
        <Polyline pathOptions={greenOptions} positions={connectDots(props.el.slice(props.selectedFrom-1,props.selectedTo))} />
          {props.selected?.index&&
            <CenterMap selected={props.selected}></CenterMap>
          }
        { props.el?.length > 0 &&
           props.el.map((el, index) => {
            return (
              <div key={index}>
              <DraggableMarker
                location={[el.latitude, el.longitude]}
                icon={props.selected===index+''?markerIconType2:(index===0||index===props.el?.length-1)?markerIconType3:(el.type === 0 ? markerIconType0 : markerIconPoint)}
                index={index}
                selected={props.selected==index}
                mapArr={props.arrAll}
                changeArr={changeArr}
              ></DraggableMarker>
              <Marker
                position={[el.latitude, el.longitude]}
                icon={markerIconCus(index+1)}
              />
              </div>
            );
          })}

      </MapContainer>
    </>
  );
};


function DraggableMarker(props) {
  const [location, setLocation] = useState(props.location);
  const markerRef = useRef(null);
  const eventHandlers = useMemo(
    () => ({
      dragend() {
        const marker = markerRef.current;
        if (marker != null) {
          let newArr = props.mapArr;
          newArr[props.index].location = [
            marker.getLatLng().lat.toFixed(5),
            marker.getLatLng().lng.toFixed(5),
          ];
          props.changeArr(newArr);
          setLocation(marker.getLatLng());
        }
      },
    }),
    [props.mapArr]
  );

    useEffect(()=>{
      if(props.location!==location){
        setLocation(props.location)
      }
    },[props.location,location])

  return (
    <Marker
      draggable={true}
      eventHandlers={eventHandlers}
      position={location}
      icon={props.icon}
      ref={markerRef}
    ></Marker>
  );
}

function CenterMap(props){
  const map = useMap();
  useEffect(() => {
    map.setView([props.selected.latitude, props.selected.longitude], 20);
  }, [props.selected]);

  return null;
}

function MyPolyline({ coordinates }) {
  const [ready,setReady] = useState(false)
  const map = useMap();
  useEffect(() => {
    if (coordinates.length > 0 && !ready) {
      setReady(true);
      const polyline = L.polyline(coordinates, { color: 'transperent' }).addTo(map);
      const bounds = polyline.getBounds();
      map.fitBounds(bounds);
    }
  }, [coordinates, map]);

  return null;
}



export default Map;
