import {
    GETFCPARTICIPANT_SUCCESS,
    GETFCPARTICIPANT_FAIL,

    
} from "../actions/types";

const initialState = [];

export default function fcReducer(FClist = initialState,action){
    const {list,type} = action;

    switch(type) {
        case GETFCPARTICIPANT_SUCCESS:
            return {...list};
        case GETFCPARTICIPANT_FAIL:
            return FClist;
        default:
            return FClist;
    }

}