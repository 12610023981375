import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router";
import Loader from "../../components/Loader/Loader";
// import { editVideoVote } from "../../redux/actions/video";
import { getUserVideos } from "../../redux/actions/videoUser";

const VideoUserPage = ()=>{
    const dispatch = useDispatch();
    const [ready,setReady]=useState(false)
    const navigate =useNavigate();
    const {listId} = useSelector((state) => state);
    const {userVideos:videos} = useSelector((state) => state);

  const voteClick = ()=>{
    let type = '';
    if(videos.items[0].isLiked===0){
      type='like'
    }else{
      type='like-removal'
    }

    let data = {id:videos.items[0].id, type:type}

    // dispatch(editVideoVote(data)).then(res=>{
    //   getVideoList()
    //     })
  }

    const backClick =()=>{
        navigate('/mainpage')
    }
    const mapClick=()=>{
      navigate('/map')
        // navigate('/parkmappage')
    } 
    
    const idRegion = useParams().idregion;
    let idCategory ='';

    switch ( useParams().idcategory) {
      case '01':
        idCategory="fb7b81dc-b6dc-4e14-a43d-56839be90c5c"
        break;
      case '02':
        idCategory="76f644fd-04ca-400a-b767-1b4370c9a5ec"
        break;
      case '03':
        idCategory="7e1937d2-3a07-4473-a1bb-a225d77ffd45"
        break;
      case '04':
        idCategory="748b6683-0968-45d0-91ac-893d69525a6e"
        break;
      case '05':
        idCategory='571f4d2c-cfea-476f-966b-c8e27b3cdac9'
        break;
      case '06':
        idCategory="a2f3d609-3775-4fcc-bad0-4d8351830e1d"
      break; 
      case '07':
        idCategory="4ae46939-5c80-46f9-8441-96082ba197d3" 
      break; 
      case '08':
        idCategory="4f914054-caf8-41ff-8854-fc7ec2187128"
      break; 
      case '09':
        idCategory="a0ad6f28-8562-41c9-93dc-b114aa9626c3"
      break;       
      default:
        break;
    }
    let idDifficulty=''
    switch (useParams().iddifficulty) {
      case '01':
        idDifficulty="6525eebc-e697-4d33-a25d-6816a54ee618"  
        break;
      case '02':
        idDifficulty="a807ff9d-152c-4505-b619-aaa59b7f9cd7"
        break;
      case '03':
        idDifficulty="99ed1a53-72d3-429c-829d-3d6e3001547e"
        break;
      case '04':
        idDifficulty="43378ef5-e55d-45ee-b610-31a4d25e4193"
        break;
      default:
        idDifficulty=''
        break;
    }

    let params = useParams();
    
    const idSubCategory = useParams().idsubcategory;

    const idVideo = useParams().idvideo;

    const getVideoList = ()=>{
      let data={filter:{statusId:["66e2ec16-03a1-4367-834a-d6b87ee709bd"],categoryId:[idCategory],exerciseDifficultyLevelId:[idDifficulty],}}
      if (idVideo !=='0'){
        data.id=idVideo;
      }
      if(idSubCategory.length>1){
        
        data.filter.subcategoryId=[idSubCategory]
      }
      dispatch(getUserVideos(data)).then(res=>{
        setReady(true);
      }).catch((error) => {
        console.error(error);
        setReady(true);
      });
    }
    const apStartPlay=()=> {
        
        let elem = document.getElementById("apPlayButton");
        elem.style.display = "none";
        let playelem = document.getElementById("apVideoPlayer");
        playelem.play();
    }

    const selectClick =(e)=>{
      setReady(false)
      navigate(`/video/${params.idregion}/${params.idcategory}/${params.iddifficulty}/${params.idsubcategory}/${e.target.id}`)
      // getVideoList();
    }


  useEffect(()=>{
    if(!ready){
      getVideoList();
    }
  },[ready])
  if(!ready){
      return(
        <div style={{'position':'relative'}}>  <div style={{'position':'absolute','top':'50%','left':'50%'}}>
          <Loader/>
          </div> </div>
        )
    }

else if(ready && videos && videos.items && videos.items.length>0){
return(
<>
    <div className="headerWithButtonsCommonBlock">
      <div className="headerWithButtonsHeaderBlock">
        <h1>{listId.list[idCategory]}, {idDifficulty?listId.list[idDifficulty]:'будь який рівень складності'}</h1>
      </div>
      <div className="headerWithButtonsHintBlock">
        {/* <div className="apParkImagesHintArea">
          <span>Скануй QR-code на майданчику, щоб відфільтрувати відео під нього.</span>
          <svg><use  href="/images/icon24Info.svg#icon24" /></svg>
        </div> */}
      </div>
    </div>
    <div className="videoPlayerAndThumbnailsBlock">
      <div className="videoPlayerColumn">
        <div className="videoPlayerContainer">
          <iframe width="100%" height="500px"
              src={'https://www.youtube.com/embed/'+videos.items[0].sourceUrl}>
           </iframe>
        </div>
        <div className="videoNameAndLikes">
          <div className="videoNameBlock">
            <h2>{videos.items[0].title}</h2>
          </div>
          {/* Закомментировано по Согласованным изменениям функционала от Савчука
          <div className="videoLikesBlock">
            <button className="secondaryGhostButtonIconTextDefault" onClick={voteClick}>
              <svg><use href="/images/icon24Like.svg#icon24" /></svg>
              {videos.items[0].likes}
            </button> */}
            {/* <button className="secondaryGhostButtonIconTextDefault">
              <svg><use href="/images/icon24Dislike.svg#icon24" /></svg>
              2
            </button> */}
          {/* </div> */}
        </div>
        <div className="videoPrimaryButtonsBlock">
          <button className="primaryButtonIconTextDefault" onClick={backClick}>
            <svg><use href="/images/icon24ArrowLeft.svg#icon24" /></svg>
            Рухові активності
          </button>
          <button className="primaryButtonIconTextDefault" onClick={mapClick}>
            <svg><use href="/images/icon24MapPin.svg#icon24" /></svg>
            Майданчики на мапі
          </button>
        </div>
        <div className="videoDescriptionBlock">
          {videos.items[0].description}
          
        </div>
      </div>

      <div className="videoThumbnailsColumn">

        {videos && videos.items.length>0 && videos.items.map((el,index)=>{
          if(index>0){
          return(
            <div className="videoThumbnailWithText" id={el.id} key={el.id} onClick={selectClick}>
              <div id={el.id}  style={{backgroundImage: `url("${el.mainPhoto}")`}} className="videoThumbnailImage">
                {el.duration && <span id={el.id} >{Math.floor(el.duration/60/60)>0 && Math.floor(el.duration/60/60)} { Math.floor(el.duration / 60) - (Math.floor(el.duration/60/60)*60)}:{(el.duration%60).toFixed(0).toString().padStart(2, "0")}</span>}
              </div>
              <div id={el.id}  className="videoThumbnailText">
                {el.title}
              </div>
            </div>
          )
        }
        })}


       
      </div>
    </div>
    </>

)
} else if(ready && videos.items && videos.items.length==0){
  return(
    <> 
    <h2> Жодного відео в даній категорії</h2>

    </>
  )
}

else if(ready){
  return(
    <> 
    <h2> Жодного відео в даній категорії</h2>

    </>)
}
}

export default VideoUserPage;