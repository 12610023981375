import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import ReactToPrint from 'react-to-print';
import Loader from "../../../components/Loader/Loader";
import QrPrintComponent from "../../../components/PrintComponent/QrPrintComponent";
import { createQr } from "../../../redux/actions/fc";


const QrComponent =(props)=>{
    const componentRef = useRef();
    const contentId = useParams().contentId;
    const currentId = useParams().id;
    const [alertWindow,setAlertWindow]=useState(false)
    const [ready,setReady]=useState(false);
    const [link,setLink]=useState(false);
    const dispatch= useDispatch();

    const leaveClick=(event)=>{
        if (!event.currentTarget.contains(event.relatedTarget)) {
            props.setModalOpen(false)
        }
    }
    const copyClick=()=>{
        let copyText = document.getElementById("copyInput");
        // Select the text field
        copyText.select();
        copyText.setSelectionRange(0, 99999); // For mobile devices
        // Copy the text inside the text field
        navigator.clipboard.writeText(copyText.value);
        setAlertWindow(true)
    }
    const getQrLink=()=>{

        let newDate = new Date();
        newDate.setDate(newDate.getDate() + 1);
        let newNewdate= newDate.toISOString().replace('T',' ').substring(0, 16);

        let data={
            "title": "Клуб",
            "clubId": currentId,
            "useLimit": 10, // Max amount of qr-code uses
            "endDateOfUse":newNewdate
        }
        dispatch(createQr(data)).then(res=>{
            setLink(res.qrCode);
            setReady(true);
        }).catch(e=>{
            setReady(true);
        })
       
    }


    useEffect(() => {
        if(alertWindow){
                setTimeout(() =>{
                setAlertWindow(false);
            }, 500);
        }
    }, [alertWindow]);

    useEffect(() => {
        if(!ready){
            getQrLink()
        }
       
    }, [ready]);

    if(ready){
    return(
        <div className="apFilterContainer"  onBlur={leaveClick} tabIndex="0">
				<button className="secondarySmallRoundedButtonTextDefault apQr" onClick={leaveClick}>X</button>
			<div className="apFilterGroupBlock">
                <QrPrintComponent 
                    name={`Одноразовий QR-код для вступу в клуб ${props.fc.name}`}
                    ref={componentRef}
                    currentId={currentId}
                    link={link}
                />
                {/* <QRCode 
                    size={256}
                    style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                    value={`https://ap.sportforall.gov.ua/qr?backUrl=/clubs/${currentId}`}
                    title={"Вступити до клубу "+props.fc.name}
                    viewBox={`0 0 256 256`}
                    /> */}
			</div>
            <div className="apFilterGroupBlock apQr">
                
                {/* <input type="text" readOnly={true} style={{visibility:"hidden"}} value={`https://ap.sportforall.gov.ua/qr?backUrl=/clubs/${currentId}`} id="copyInput"/>
                <button className="primaryButtonTextActive" onClick={copyClick}>Copy text</button> */}
                {/*  //////////////////////////////////////  */}
                <div className={'apInputTextIcon apDisabled'}>
                    <label htmlFor="copyInput">Посилання</label>
                    <div>
                        <input
                        id="copyInput"
                        type="text"
                        disabled={true}
                        readOnly={true}
                        value={link}
                        />
                        <svg className="copyHover" onClick={copyClick}>
                            <use href="/images/icon24Copy.svg#icon24" />
                        </svg>
                    </div>
                </div>
                <ReactToPrint
                    trigger={() => (
                    <button style={{'marginRight':'20px'}} className="primaryButtonTextDefault">
                        Друкувати
                    </button>
                    )}
                    content={() => componentRef.current}
                />
               {alertWindow && <div className="infoSmallText">
                    Скопійовано
                </div>}
			</div>
			
		</div>

)}else{
    return(
        <Loader/>
    )
}

}


export default QrComponent;
