import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import mainImg1 from "../../../assets/images/1.png";
import mainImg2 from "../../../assets/images/2.png";
import mainImg3 from "../../../assets/images/3.png";


const ActionBlock=()=>{
    const [selected, setSelected] = useState(1);
    const [progress, setProgress] = useState(0);
    const [autoUpdate, setAutoUpdate] = useState(true);
    
    const navigate=useNavigate();

    useEffect(() => {
        if(autoUpdate){
            setTimeout(() => {
                if(progress>99){
                    setProgress(0);
                    if(selected===3){
                        setSelected(1);
    
                    }else{
                        setSelected(selected+1)
                    }
                }else{
                    setProgress(progress+2);
                }
            }, 100);
        }
       
    }, [selected,progress]);

    const linkClick = (e) => {
        navigate(e.target.id);
    };

    return(
    <section className="main-section main-hero">
        <div className="text">          
          <div className="startHeader">
          {selected === 1 && (
            <>
              <h2>Спортивні майданчики з тренажерами по всій Україні</h2>
              <button
                className="primaryButtonIconTextDefault"
                id="/map"
                onClick={linkClick}
              >
                <svg>
                  <use href="/images/icon24MapPin.svg#icon24" />
                </svg>
                Майданчики
              </button>
            </>
          )}
          {selected === 2 && (
            <>
              <h2>
                Різні заходи, спрямовані на розвиток культури спорту для
                населення
              </h2>
              <button
                className="primaryButtonIconTextDefault"
                id="/eventsCalendar"
                onClick={linkClick}
              >
                <svg>
                  <use href="/images/icon24File.svg#icon24" />
                </svg>
                Календар
              </button>
            </>
          )}
          {selected === 3 && (
            <>
              <h2>Довголіття починається з першої вправи</h2>
              <button
                className="primaryButtonIconTextDefault"
                id="/info/nYgAAWMV"
                onClick={linkClick}
              >
                <svg>
                  <use href="/images/icon24Info.svg#icon24" />
                </svg>
                Про&nbsp;проєкт
              </button>
            </>
          )}
        <div className="img-wrp img-wrp-mob">
          {selected === 1 && <img loading="lazy" src={mainImg1} alt="mainImg" />}
          {selected === 2 &&<img loading="lazy" src={mainImg2} alt="mainImg" />}
          {selected === 3 &&<img loading="lazy" src={mainImg3} alt="mainImg" />}
        </div>

          <div className="startList">
            <div
              id='1'
              onClick={() => {setSelected(1);setAutoUpdate(false);setProgress(100)}}
              className={selected === 1 || selected === 2|| selected === 3? "selectedStart" : ""}
              style={
                selected === 1 ? {
                  background: `linear-gradient(90deg, rgba(27, 168, 178, 1) 0%, rgba(27, 168, 178, 1) ${progress}%,
                              rgba(149, 149, 149, 1) ${(progress + 1)}%, rgba(149, 149, 149, 1) 100%)`
                } : {}
              }            
           >
            </div>
            <div
              id="2"
              onClick={() => {setSelected(2);setAutoUpdate(false);setProgress(100)}}
              className={selected === 2|| selected === 3 ? "selectedStart" : ""}
              style={
                selected === 2 ? {
                  background: `linear-gradient(90deg, rgba(27, 168, 178, 1) 0%, rgba(27, 168, 178, 1) ${progress}%,
                              rgba(149, 149, 149, 1) ${(progress + 1)}%, rgba(149, 149, 149, 1) 100%)`
                } : {}
              }
            >
            </div>
            <div
              id="3"
              onClick={() => {setSelected(3);setAutoUpdate(false);setProgress(100)}}
              className={selected === 3 ? "selectedStart" : ""}
              style={
                selected === 3 ? {
                  background: `linear-gradient(90deg, rgba(27, 168, 178, 1) 0%, rgba(27, 168, 178, 1) ${progress}%,
                              rgba(149, 149, 149, 1) ${(progress + 1)}%, rgba(149, 149, 149, 1) 100%)`
                } : {}
              }
            >
            </div>
          </div>
        </div>
        </div>
        <div className="img-wrp">
          {selected === 1 && <img loading="lazy" src={mainImg1} alt="mainImg" />}
          {selected === 2 &&<img loading="lazy" src={mainImg2} alt="mainImg" />}
          {selected === 3 &&<img loading="lazy" src={mainImg3} alt="mainImg" />}
        </div>
      </section>
    )
}


export default ActionBlock;