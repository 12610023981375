import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { uploadFile } from "../../../redux/actions/file";
import { deleteVideo, editVideo } from "../../../redux/actions/video";

const VideoUpload = (props) => {
  const currentId = useParams().id;
  const chunkSize = 1048576 * 1; //its  MB, increase the number measure in mb
  const [ready, setReady] = useState(false);
  const dispatch = useDispatch();
  const { fullList } = useSelector((state) => state);
  const [resolution, setResolution] = useState("");
  const [counter, setCounter] = useState(1);
  const [fileToBeUpload, setFileToBeUpload] = useState({});
  const [beginingOfTheChunk, setBeginingOfTheChunk] = useState(0);
  const [endOfTheChunk, setEndOfTheChunk] = useState(chunkSize);
  const [progress, setProgress] = useState(0);
  const [fileGuid, setFileGuid] = useState("");
  const [fileSize, setFileSize] = useState(0);
  const [chunkCount, setChunkCount] = useState(0);
  const [chunkSet, setChunkSet] = useState();

  function apStartPlay() {
    var elem = document.getElementById("apPlayButton");
    elem.style.display = "none";
    elem = document.getElementById("apVideoPlayer");
    elem.play();
  }
  function apStartPlay2() {
    var elem = document.getElementById("apPlayButton2");
    elem.style.display = "none";
    elem = document.getElementById("apVideoPlayer2");
    elem.play();
  }

  const getFileContext = (e) => {
    resetChunkProperties();
    const _file = e.target.files[0];
    setFileSize(_file.size);
    if (e.target.id === "fileUpload") {
      setResolution("high");
    } else if (e.target.id === "fileUpload2") {
      setResolution("low");
    }

    const _totalCount =
      _file.size % chunkSize == 0
        ? _file.size / chunkSize
        : Math.floor(_file.size / chunkSize) + 1; // Total count of chunks will have been upload to finish the file
    setChunkCount(_totalCount);
    setFileToBeUpload(_file);
    const _fileID = _file.name;
    setFileGuid(_fileID + Math.floor(1000 + Math.random() * 9000));
    // fileUpload();
  };
  const fileUpload = () => {
    setCounter(counter + 1);
    if (counter > 0 && counter <= chunkCount) {
      let chunk = fileToBeUpload;
      uploadChunk(chunk);
    } else if (counter > 0 && counter > chunkCount) {
      setCounter(0);
    }
  };

  const deleteClick = (e) => {
    let data = {};
    if (props.form.files[e.target.id].includes("media/")) {
      data.id = props.form.files[e.target.id].split("media/")[1];
    } else if ((data.id = props.form.files[e.target.id].includes("uploads/"))) {
      data.id = props.form.files[e.target.id].split("uploads/")[1];
    }

    dispatch(deleteVideo(data)).then((res) => {
      props.getItem();
    });
  };

  const uploadChunk = async (chunk) => {
    try {
      let formData = new FormData();
      formData.append("fileName", chunk.name);
      formData.append("size", fileSize);
      formData.append("chunkIndex", counter);
      formData.append("totalChunk", chunkCount);
      formData.append(
        "file",
        chunk.slice(beginingOfTheChunk, endOfTheChunk, fileToBeUpload.type)
      );
      formData.append("uploadId", chunk.name + chunkSize);
      formData.append("uploadType", "video");
      formData.append("videoId", currentId);
      formData.append("resolution", resolution);
      setChunkSet(chunk);
      let response = await dispatch(uploadFile(formData));
      const data = response.data;

      if (response.status === 200) {
        setBeginingOfTheChunk(endOfTheChunk);
        setEndOfTheChunk(endOfTheChunk + chunkSize);
        if (counter == chunkCount) {
          // await uploadCompleted();
        } else {
          let percentage = (counter / chunkCount) * 100;
          setProgress(percentage);
        }
      } else if (response.status === 201) {
        setFileToBeUpload({});
        setProgress(100);
        // props.setFrom(props.form);
        // dispatch(editVideo(props.form)).then(res=>{
        props.getItem();
        // props.setFrom(props.form)
        // })
      } else {
        console.log("Error Occurred:", data.errorMessage);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const resetChunkProperties = () => {
    // setShowProgress(true);
    setProgress(0);
    setFileGuid("");
    if (counter === 1) {
      setCounter(1);
    } else {
      setCounter(0);
    }
    setResolution("");
    setBeginingOfTheChunk(0);
    setEndOfTheChunk(chunkSize);
    setFileSize(0);
  };

  function dragOverHandler(ev) {
    // Prevent default behavior (Prevent file from being opened)
    ev.preventDefault();
  }

  useEffect(() => {
    if (fileSize > 0 && counter > 0) {
      fileUpload(counter);
    }
  }, [fileToBeUpload, progress, props.form]);
  return (
    <div className="videoCard2Columns">
      <div className="videoCardVideoWithTextBlock">
        <div className="videoCardVideoDropAndPlayerArea">
          {props.form.statusId === "66e2ec16-03a1-4367-834a-d6b87ee709bd" ? (
            <></>
          ) : (

            
            <input
              type="file"
              // accept={fullList.FileTypes.map(
              //   (el) =>
              //     (el.id === "7d22f440-c95b-4a15-9ebe-e6460915ae7f" && (
              //       <React.Fragment key={el.id}>el.title</React.Fragment>
              //     )) ||
              //     null
              // )}
              accept={fullList.FileTypes.map((el) => {
                if (el.id === "7d22f440-c95b-4a15-9ebe-e6460915ae7f") {
                  return el.title;
                }
              })}
              id="fileUpload"
              onChange={getFileContext}
              style={{ display: "none" }}
            />
          )}
          {props.form && props.form.files && !props.form.files.high && (
            <>
              <label
                id="drop_zone"
                htmlFor="fileUpload"
                className={
                  props.form.statusId === "66e2ec16-03a1-4367-834a-d6b87ee709bd"
                    ? "videoCardDropArea apDisabled"
                    : "videoCardDropArea"
                }
                onDrop={getFileContext}
                onDragOver={dragOverHandler}
              >
                {((!resolution && (progress === 100 || progress === 0)) ||
                  resolution === "low") && (
                  <>
                    <svg>
                      <use href="/images/icon24Download.svg#icon24" />
                    </svg>
                    <span>
                      {props.form.statusId !==
                      "66e2ec16-03a1-4367-834a-d6b87ee709bd"
                        ? `Перетягніть файли сюди або натисніть, щоб обрати.`
                        : "Опубліковане відео не можливо редагувати"}
                    </span>
                  </>
                )}
                {resolution === "high" &&
                  !(progress === 100 || progress === 0) && (
                    <div className="apSpinner">
                      <div className="cancelUploadButton">
                        <svg>
                          <use href="/images/icon24Cancel.svg#icon24"></use>
                        </svg>
                      </div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                  )}
              </label>
            </>
          )}
          {/* {resolution==='fileUpload' &&
                <label id="drop_zone"  className="videoCardDropArea " >
                    {progress}
                </label>
            } */}
          {props.form && props.form.files && props.form.files.high && (
            <div className="videoPlayerContainer">
              <video
                id="apVideoPlayer"
                width="100%"
                height="100%"
                controls="controls"
              >
                <source
                  type="video/mp4"
                  src={props.form.files.high}
                  data-quality="SD"
                />
              </video>
              <svg id="apPlayButton" onClick={apStartPlay}>
                <use href="/images/icon80PlayCircle.svg#icon80" />
              </svg>
            </div>
          )}
        </div>
        <div className="videoCardTextUnderVideo">
          Для відео з високою роздільною здатністю
          <br />
          1080p до 1Gb (mp4)
          {/* <button className="secondaryButtonIconDefault apInputTypeFile">
                <label id="dat" htmlFor='fileUpload' onDrop={getFileContext}>
                <svg><use href="/images/icon24Attach.svg#icon24" /></svg>
                </label>
              </button> */}
          {props.form.statusId !== "66e2ec16-03a1-4367-834a-d6b87ee709bd" && (
            <button
              className="secondaryGhostButtonIconTextDefault"
              id="high"
              onClick={deleteClick}
            >
              <svg id="high">
                <use id="high" href="/images/icon24Trash.svg#icon24" />
              </svg>
            </button>
          )}
        </div>
      </div>
      <div className="videoCardVideoWithTextBlock">
        <div className="videoCardVideoDropAndPlayerArea">
          {props.form.statusId === "66e2ec16-03a1-4367-834a-d6b87ee709bd" ? (
            <></>
          ) : (
            <input
              type="file"
              accept={fullList.FileTypes.map((el) => {
                if (el.id === "7d22f440-c95b-4a15-9ebe-e6460915ae7f") {
                  return el.title;
                }
              })}
              id="fileUpload2"
              onChange={getFileContext}
              style={{ display: "none" }}
            />
          )}
          {props.form && props.form.files && !props.form.files.low && (
            <>
              <label
                id="drop_zone"
                htmlFor="fileUpload2"
                className={
                  props.form.statusId === "66e2ec16-03a1-4367-834a-d6b87ee709bd"
                    ? "videoCardDropArea apDisabled"
                    : "videoCardDropArea"
                }
                onDrop={getFileContext}
                onDragOver={dragOverHandler}
              >
                {((!resolution && (progress === 100 || progress === 0)) ||
                  resolution === "high") && (
                  <>
                    <svg>
                      <use href="/images/icon24Download.svg#icon24" />
                    </svg>
                    <span>
                      {props.form.statusId !==
                      "66e2ec16-03a1-4367-834a-d6b87ee709bd"
                        ? `Перетягніть файли сюди або натисніть, щоб обрати.`
                        : "Опубліковане відео не можливо редагувати"}
                    </span>
                  </>
                )}
                {resolution === "low" && !(progress === 100 || progress === 0) && (
                  <div class="apSpinner">
                    <div class="cancelUploadButton">
                      <svg>
                        <use href="/images/icon24Cancel.svg#icon24"></use>
                      </svg>
                    </div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                )}
              </label>
            </>
          )}
          {props.form && props.form.files && props.form.files.low && (
            <div className="videoPlayerContainer">
              <video
                id="apVideoPlayer2"
                width="100%"
                height="100%"
                controls="controls"
              >
                <source
                  type="video/mp4"
                  src={props.form.files.low}
                  data-quality="SD"
                />
              </video>
              <svg id="apPlayButton2" onClick={apStartPlay2}>
                <use href="/images/icon80PlayCircle.svg#icon80" />
              </svg>
            </div>
          )}
        </div>
        <div className="videoCardTextUnderVideo">
          Для відео з низькою роздільною здатністю
          <br />
          480p до 1Gb (mp4)
          {/* <button className="secondaryButtonIconDefault apInputTypeFile">
              <label id="dat" htmlFor='fileUpload2' onDrop={getFileContext}>
              <svg><use href="/images/icon24Attach.svg#icon24" /></svg>
              </label>
              </button> */}
          {props.form.statusId !== "66e2ec16-03a1-4367-834a-d6b87ee709bd" && (
            <button
              className="secondaryGhostButtonIconTextDefault"
              id="low"
              onClick={deleteClick}
            >
              <svg id="low">
                <use id="low" href="/images/icon24Trash.svg#icon24" />
              </svg>
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default VideoUpload;
