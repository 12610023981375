import React, { useEffect, useMemo, useRef, useState } from "react";
import { MapContainer, TileLayer, Marker } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";

const markerIcon = new L.Icon({
  iconUrl: require("../../../../assets/images/map_icon_mini.png"),
  // iconSize: [24, 24],
  iconAnchor: [12, 24], //[left/right, top/bottom]
  popupAnchor: [0, 0], //[left/right, top/bottom]
});

const markerIconMy = new L.Icon({
  iconUrl: require("../../../../assets/images/myLocation.png"),
  iconSize: [24, 24],
  iconAnchor: [12, 13], //[left/right, top/bottom]
  popupAnchor: [0, 0], //[left/right, top/bottom]
});

const MapHeader = (props) => {
  const [mapArr, setMappArr] = useState([]);

  const updateArr = () => {
    setMappArr(props.arrAll);
  };

  useEffect(() => {
    updateArr();
  }, [props.arrAll]);

  return (
    <>
      <MapContainer
        style={{ height: '175px',borderRadius:'8px' }}
        center={
          props.marker?.location?.length > 0
            ? [props.marker.location[0], props.marker.location[1]]
            : [50.4393, 30.5479]
        }
        zoom={9}
        scrollWheelZoom={true}
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://openstreetmap.pp.ua/hot/{z}/{x}/{y}.png"
        />
        {/* <CurrentPosition mapArr={mapArr} setArr={changeArr} position="topright"/> */}
        {/* <Routing setArr={setMappArr}></Routing> */}
        {/* <Routing massArr={mapArr}></Routing> */}
        {props.marker?.location?.length > 0 && (
          <Marker
            position={[props.marker.location[0], props.marker.location[1]]}
            icon={props.marker.type === 0 ? markerIcon : markerIconMy}
            index={props.marker.index}
            onClick={()=>{window.open(`https://www.google.com.ua/maps/place/${props.marker.location[0]}+${props.marker.location[1]}`)}}
          ></Marker>
        )}
      </MapContainer>
    </>
  );
};


export default MapHeader;
