import {
    LOG_SUCCESS,
    LOG_FAIL
} from "./types";

  import LogService from "../services/log.service";

  export const getLog = (data)=> (dispatch) => {
    return LogService.getLog(data).then(
      (response) => {
          dispatch({
          type: LOG_SUCCESS,
          payload:response
        });
        return response;
      },
      (error) => {
        dispatch({
          type: LOG_FAIL,
        });
    
        return Promise.reject();
      }
    );
  }
  