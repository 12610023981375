import Collapse from "../../../components/Collapse";

import { ReactComponent as Arrow } from "../../../assets/images/collapseArrow.svg";
import getDate from "../../../helpers/getDate";
import useCurrentMediaType from "../../../hooks/currentMediaType";
import { useNavigate, useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { changeAccess } from "../../../redux/actions/workouts";

export const Info = (props) => {

  const navigate = useNavigate();
  const { isCurrentSize: isMobile } = useCurrentMediaType(775);
  const { currentUser } = useSelector((state) => state.currentUser);
  const { fc } = useSelector((state) => state);
  const [checked,setChecked]=useState(fc?.permissionUser|| false);
  const [hasRights,setHasRights]=useState(false);
  const currentId =useParams().id;
  const dispatch= useDispatch();

  const changeWorkoutApprove = (e)=>{
    setChecked(!checked);
    let data={
      id:currentId,
      clubId:currentId,
      type:!checked&&'provide-access'||'remove-access'
    }

    dispatch(changeAccess(data)).then(res=>{
      setChecked(!checked);
    })
  }


  useEffect(() => {
    if(currentUser.permissions){
      const isFound = currentUser.permissions?.some(str => str === 'clubActing-'+currentId);
      if(isFound){
        setHasRights(true);
      }
    }
  }, []);

  
  

  return (
    <Collapse
      button={{
        className: "fcCollapseButton",
        inner: (
          <>
            <Arrow /> {!isMobile ? "Розгорнути інформацію" : ""}
          </>
        ),
        isOpen: (
          <>
            <Arrow />
            {!isMobile ? "Згорнути інформацію" : ""}
          </>
        ),
      }}
    >
      <div className="apFcInfoBlock">
        {!isMobile && (
          <div className="info">
            <div className="imgWrp">
              <div
                className="img"
                style={{
                  backgroundImage: `url(${
                    props.logo ||
                    "https://ap.sportforall.gov.ua/api/v1/uploads/b34604xc-02qb-46bb-9e91-fc3ffrb92w41"
                  })`,
                }}
              ></div>
            </div>
            <div className="art">
              <span>Дата створення:</span>
              {getDate(props.creationDate,'dd/mm/yyyy','.')}
            </div>
            <div className="art">
              <span>Кількість учасників:</span>
              {`${props.participants}`}
            </div>
          </div>
        )}

        <div className="text">
          {(currentUser?.id === props.createdBy || hasRights||
            currentUser?.roleId === "d379ecaa-fee7-48a4-84df-a176f47820e6") && (
            <button
              className="primaryButtonIconTextDefault"
              onClick={() => navigate(`/fcEdit/${props.id}`)}
              style={{ float: "right" }}
            >
              <svg>
                <use href="/images/icon24Edit.svg#icon24" />
              </svg>
              Редагувати
            </button>
          )}
          {fc?.clubUser?.isApproved &&!fc?.clubUser?.isCoordinator &&  !fc?.clubUser?.isActing &&
            <div className="apCheckBoxWithLabel" 
            style={{ float: "right" }}
            >
              <label htmlFor="closedGroundSwiper"></label>
              <input id="closedGroundSwiper" checked={checked} type="checkbox" className="apCheckbox" onChange={changeWorkoutApprove}/>
              <label htmlFor="closedGroundSwiper"> Надати доступ для редагування тренувань</label>
            </div>
          }
          <h3>Про клуб:</h3>
          <p>{props.about}</p>
        </div>
      </div>
    </Collapse>
  );
};
