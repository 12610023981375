import {
    CREATENEWS_SUCCESS,
    CREATENEWS_FAIL,
    GETNEWS_SUCCESS,
    GETNEWS_FAIL,
    GETNEWSITEM_SUCCESS,
    GETNEWSITEM_FAIL,
    EDITNEWS_SUCCESS,
    EDITNEWS_FAIL,
    DELETENEWS_SUCCESS,
    DELETENEWS_FAIL,
    GETNEWSCURRENTITEM_SUCCESS,
    GETNEWSCURRENTITEM_FAIL
} from "./types";

import NewsService from "../services/news.service"


export const getNews = (data)=> async(dispatch)=>{
        
    try{
        const res = await NewsService.getNews(data);
        
        dispatch({
          type: GETNEWS_SUCCESS,
          list: res,
        });
        
        return res
    }catch (error){
        
        dispatch({
            type: GETNEWS_FAIL,
            list: {error: error.response},
          });
          const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
          return error.response
    }
}


export const editNews= (form)=>(dispatch)=>{
      
    return NewsService.editNews(form).then(
        (response)=>{
              
            dispatch({
                type:EDITNEWS_SUCCESS,
                item:response
            });
            
            return response

        },
        (error)=>{
            dispatch({
                type: EDITNEWS_FAIL,
              });
            console.log(error);
              return Promise.reject();
        }
    );
}



export const deleteNews= (data)=>(dispatch)=>{
      
    return NewsService.deleteNews(data).then(
        (response)=>{
            dispatch({
                type:DELETENEWS_SUCCESS,
            });
              
        },
        (error)=>{
            dispatch({
                type: DELETENEWS_FAIL,
              });
            console.log(error);
                 
              return Promise.reject();
        }
    );
}

export const getNewsItem = (data)=>(dispatch)=>{
    return NewsService.getNewsItem(data).then(
        (response)=>{
              
            dispatch({
                type:GETNEWSITEM_SUCCESS,
                item:response,
            });
              
            return response

        },
        (error)=>{
            dispatch({
                type: GETNEWSITEM_FAIL,
              });
            console.log(error);
                 
              return Promise.reject();
        }
    );

}


export const getNewsCurrentItem = (data)=>(dispatch)=>{
    return NewsService.getNewsItem(data).then(
        (response)=>{
              
            dispatch({
                type:GETNEWSCURRENTITEM_SUCCESS,
                item:response,
            });
              
            return response

        },
        (error)=>{
            dispatch({
                type: GETNEWSCURRENTITEM_FAIL,
              });
            console.log(error);
                 
              return Promise.reject();
        }
    );

}


export const addNews = (data)=> async(dispatch)=>{
    try {
        const res = await NewsService.addNews(data);
        dispatch({
          type: CREATENEWS_SUCCESS,
          item: res
        });
        return res
    }catch (error){
        dispatch({
            type: CREATENEWS_FAIL,
            item: {error: error.response},
          });
          const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
          return error.response;
    }
}