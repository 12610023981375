import { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import useCurrentUser from "../../../hooks/isCurrentUser";
import Tile from "../../../components/Tile";
import { ReactComponent as Arrow } from "../../../assets/images/collapseArrow.svg";
import { getFcNews } from "../../../redux/actions/fcNews";
import { useEffect } from "react";
import Loader from "../../../components/Loader/Loader";
import { useSlicedData } from "../../../hooks/useSlicesData";

export const FcNews = () => {
  const [ready, setReady] = useState(false);
  const dispatch = useDispatch();
  const { currentUser } = useSelector((state) => state.currentUser);
  const { fcNews } = useSelector((state) => state);
  const { fc } = useSelector((state) => state);
  const currentId = useParams().id;
  const [list, setList] = useState({items:[]});
  const navigate = useNavigate();

  const isOwner = useMemo(() => {
    if (!currentUser) return;
    if (fc.createdBy?.id === currentUser.id || fc.clubUser?.isActing|| fc.clubUser?.isCoordinator) {
      return true;
    }
  }, [currentUser]);

  const isCurrentUser = useCurrentUser(currentUser?.roleId, [
    "631db81f-fa07-42e2-b394-062c9be66b09",
    "631db81f-fa07-42e2-b394-062c9be66b01",
  ]);

  const { array1: news1, array2: news2 } = useSlicedData(list.items);

  const initFunc = (page = 1, filter, more) => {
    let data = { id: currentId };
    if (filter) {
      data.filter = { ...filter };
    } else {
      if (localStorage.getItem("newsFcFilter")) {
        data.filter = { ...JSON.parse(localStorage.getItem("newsFcFilter")) };
      } else {
        data.filter = {};
      }
    }
    if (localStorage.getItem("newsFcSort")) {
      data.sort = { ...JSON.parse(localStorage.getItem("newsFcSort")) };
    } else {
      data.sort = { publishedAt: "desc" };
    }
    data.offset = (page - 1) * 10;
    data.limit = 10;

    dispatch(getFcNews(data)).then((res) => {
      let result={...list};
      result.total=res.total;
      result.offset=res.offset;
      result.limit=res.limit;
      result.items=[...result.items, ...res.items];
      setList(result)
      setReady(true);
    });
  };
  const moreNews=()=>{
    let page=(fcNews.offset/fcNews.limit)+2;
    initFunc(page,null,'more');
  }
  
  useEffect(() => {
    if (!ready) {
      initFunc();
    }
  }, [ready]);


  if (!ready) {
    return <Loader />;
  } else {
    return (
      <div className="fcNews">
        {fcNews.items && fcNews.items?.length ? (
          <>
           {news2 &&
            news2.length > 0 && <div className="apFcNewsItemContainer">
              {news2 &&
                news2.length > 0 &&
                news2.map(({ title, photos, body, id, publishedAt, statusId }) => (
                  <Tile
                    type={"FC"}
                    title={title}
                    publishedAt={publishedAt}
                    img={photos}
                    statusId={statusId}
                    onError={
                      "https://ap.sportforall.gov.ua/api/v1/uploads/b34604xc-02qb-46bb-9e91-fc3ffrb92w41"
                    }
                    // date={date}
                    text={body}
                    isCurrentUser={isCurrentUser || isOwner}
                    onDetailsClick={() =>
                      navigate(`/fc/${currentId}/news/${id}`)
                    }
                    onCreationButtonClick={() =>
                      navigate(`/fcEdit/${currentId}/news/${id}`)
                    }
                    key={id}
                  />
                ))}
            </div>}
            {news1 &&
                news1.length &&<div className="apFcNewsItemContainer">
              {news1 &&
                news1.length > 0 &&
                news1.map(({ title, photos, body, id , publishedAt, statusId}) => (
                  <Tile
                    type={"FC"}
                    title={title}
                    publishedAt={publishedAt}
                    statusId={statusId}
                    img={photos}
                    onError={
                      "https://ap.sportforall.gov.ua/api/v1/uploads/b34604xc-02qb-46bb-9e91-fc3ffrb92w41"
                    }
                    // date={date}
                    text={body}
                    isCurrentUser={isCurrentUser || isOwner}
                    onDetailsClick={() =>
                      navigate(`/fc/${currentId}/news/${id}`)
                    }
                    onCreationButtonClick={() =>
                      navigate(`/fcEdit/${currentId}/news/${id}`)
                    }
                    key={id}
                  />
                ))}
            </div>}
          </>
        ) : (
          <div className="apEmptyCategory">Не створено жодного запису в блогу</div>
        )}

        {fcNews.total >  list.offset+list.limit && (
          <button className="apLoadMore" onClick={moreNews}>
            <Arrow />
            Завантажити більше записів блогу
          </button>
        )}
      </div>
    );
  }
};
