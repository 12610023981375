import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { Icon } from "../../../../components/Icon";
import { changeGalleryStatus, getOfficial, getUserGallery } from "../../../../redux/actions/gallery";
import PhotoItem from "./PhotoItem/PhotoItem";
import PhotoUpload from "./PhotoUpload/PhotoUpload";


const Gallery =()=>{
    const [ready,setReady] = useState(false);
    const dispatch=useDispatch();
    const currentId=useParams().id;
    const navigate = useNavigate();
    const { gallery } = useSelector((state) => state);
    const { currentEvent } = useSelector((state) => state);
    const { auth } = useSelector((state) => state);

    
    const initFunc =()=>{
        let data={
            sportEventId:currentId
        }
        dispatch(getOfficial(data)).then(res=>{
            console.log('getOfficial');
            console.log(res);
        })
        dispatch(getUserGallery(data)).then(res=>{
            console.log('getUserGallery');
            console.log(res);

        })

    }

    const changeStatus=(e)=>{
        let data={type:e.target.accessKey,
            sportEventId:currentId
        };
        dispatch(changeGalleryStatus(data)).then(res=>{
            initFunc();
        })
    }

    const openFull =()=>{

    }


    useEffect(() => {
       if(!ready){
           initFunc()
       }
    }, [ready]);

    return(
        <>
            <div className="galleryList">
                <div className="headerRoute" style={{width:'100%'}}>
                    <h2 style={{marginTop:'0px'}}>Офіційна галерея</h2>
                    {(currentEvent?.eventUser?.isCoordinator || currentEvent?.eventUser?.isLeading) &&<div className="galleryList">
                    {gallery?.official?.items?.length>0 && gallery?.official?.items[0].status==='28e09d0e-bf38-4c6f-a787-3ec7e222f554'&& 
                        <button
                            accessKey="publish"
                            className="secondaryButtonIconTextDefault"
                            onClick={changeStatus}
                        >
                            <svg accessKey="publish">
                            <use
                                accessKey="publish"
                                href="/images/icon24CheckCircle.svg#icon24"
                            />
                            </svg>
                            Опублікувати
                        </button>}
                        {gallery?.official?.items?.length>0 && gallery?.official?.items[0].status!=='28e09d0e-bf38-4c6f-a787-3ec7e222f554'&&

                        <button
                            accessKey="to-draft"
                            className="secondaryButtonIconTextDefault"
                            onClick={changeStatus}
                        >
                            <svg accessKey="to-draft">
                            <use
                                accessKey="to-draft"
                                href="/images/icon24ArrowReturn.svg#icon24"
                            />
                            </svg>
                            Деактивувати
                        </button>}

                    </div>
                    }
                </div>
                {gallery?.official?.items?.length>0 ? gallery?.official.items.map((el,index)=>{
                    return(
                       <PhotoItem el={el} eventUser={currentEvent?.eventUser} hasRights={(currentEvent?.eventUser?.isCoordinator || currentEvent?.eventUser?.isLeading)} initFunc={initFunc} index={index} type='official' openFull={openFull}/>
                    )
                })
                : (!currentEvent?.eventUser?.isCoordinator && !currentEvent?.eventUser?.isLeading) && 
                <div>Не було додано жодного зображення</div>}
                {(currentEvent?.eventUser?.isCoordinator || currentEvent?.eventUser?.isLeading) && ( gallery?.official?.items?.length>0 && gallery?.official?.items[0].status==='28e09d0e-bf38-4c6f-a787-3ec7e222f554' || !gallery?.official?.items[0] ) &&<PhotoUpload initFunc={initFunc} currentId={currentId}></PhotoUpload>}
            </div>
            {auth.isLoggedIn && (currentEvent?.eventUser?.isActing || currentEvent?.eventUser?.isApproved || currentEvent?.eventUser?.isCoordinator || currentEvent?.eventUser?.isLeading) && (gallery?.official?.items?.length>0 && gallery?.official?.items[0].status==='28e09d0e-bf38-4c6f-a787-3ec7e222f554' || !gallery?.official?.items?.length>0) && 
                <div className="galleryList">
                <h2 style={{width:'100%'}}>Користувацька галерея</h2>

                {gallery?.user?.items?.length>0 && gallery?.user.items.map((el,index)=>{
                    return(
                        <PhotoItem el={el} eventUser={currentEvent?.eventUser} hasRights={(currentEvent?.eventUser?.isCoordinator || currentEvent?.eventUser?.isLeading)} initFunc={initFunc} type='user' index={index} openFull={openFull}/>
                    )
                })}
                  {!(gallery?.user?.items?.length>0) && (currentEvent?.eventUser?.isCoordinator || currentEvent?.eventUser?.isLeading) &&  <div>Не було додано жодного зображення</div>}
                {currentEvent?.eventUser?.isApproved && !currentEvent?.eventUser?.isCoordinator && !currentEvent?.eventUser?.isLeading &&<PhotoUpload initFunc={initFunc} currentId={currentId}></PhotoUpload>}
            </div>}
            
        </>
    )
}

export default Gallery;