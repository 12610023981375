import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import InfoWindow from "../../../../components/InfoWindow/InfoWindow";
import Loader from "../../../../components/Loader/Loader";
import { uploadFile } from "../../../../redux/actions/file";
import { editPark } from "../../../../redux/actions/parks";

const ModalFiles = (props) => {
  const [loading, setLoading] = useState("");
  const dispatch = useDispatch();
  const { fullList } = useSelector((state) => state);
  const [ready, setReady] = useState(false);
  const [form, setForm] = useState({});
  const [alertMessage, setAlertMessage] = useState("");
  const [alertModal, setAlertModal] = useState(false);

  const leaveClick = (event) => {
    if (!event.currentTarget.contains(event.relatedTarget)) {
      props.setForm(form);
      dispatch(editPark(form));
      props.setModal(false);
    }
  };
  const initialFunc = () => {
    setForm(props.form);
    setReady(true);
  };
  const getFileContext = async (e) => {
    let arr = [...fullList.FileTypes[0].title.split(" ")];
    const _file = e.target.files[0];
    if (!_file) {
      return;
    }
    if (_file.size > 10000000) {
      setAlertMessage("Не більше 10МБ");
      setAlertModal(true);
      return;
    }
    if (
      !arr.find((a) =>
        a.includes(
          _file.name.split(".")[_file.name.split(".").length - 1].toLowerCase()
        )
      )
    ) {
      setAlertMessage("Не вірний формат файлу");
      setAlertModal(true);
      return;
    }
    setLoading(e.target.id);
    let formData = new FormData();
    formData.append("fileName", _file.name);
    formData.append("size", _file.size);
    formData.append("chunkIndex", 1);
    formData.append("totalChunk", 1);
    formData.append("uploadType", "other_document");
    formData.append("file", _file);
    formData.append("uploadId", _file.name + _file.size);

    let response = await dispatch(uploadFile(formData));
    const data = response.data;

    if (response.status === 201) {
      let res = { ...form };

      res[e.target.id] = data.url;

      dispatch(editPark(res));
      setForm({ ...res });
      props.setForm({ ...res });
      setLoading("");
    }
  };
  const deleteClick = (e) => {
    let data = { ...form };
    data[e.target.id] = "";
    setForm(data);
    props.setForm(data);

    dispatch(editPark(data));
  };
  useEffect(() => {
    if (!ready) {
      initialFunc();
    }
  }, []);

  if (!ready) {
    initialFunc();
  }

  if (ready) {
    return (
      <>
        {alertModal && (
          <InfoWindow
            setModal={setAlertModal}
            message={alertMessage}
          ></InfoWindow>
        )}

        <div className="apFilterContainer" tabIndex="0">
          <div className="apFilterHeader">
            <h4>Прикріплені файли</h4>
            <svg onClick={leaveClick}>
              <use href="/images/icon24Cancel.svg#icon24"></use>
            </svg>
          </div>

          <div className="apFilterGroupBlock">
            <span>Прикріплені файли</span>
            {form.registryCertificate || form.ownershipCertificate ? (
              <>
                {form.registryCertificate && (
                  <div className="videoCardTextUnderVideo">
                    <a href={form.registryCertificate}>
                      Cвiдоцтво про реєстрацiю юридичної особи
                    </a>
                    {form.statusId !== "99ca4f35-663c-4e0a-b9ef-f70219aa8163" &&
                      form.statusId !==
                        "036735ba-f634-469b-ac30-77a164e3a918" && (
                        <button
                          className="secondaryGhostButtonIconTextDefault"
                          id="registryCertificate"
                          onClick={deleteClick}
                        >
                          <svg id="registryCertificate">
                            <use
                              id="registryCertificate"
                              href="/images/icon24Trash.svg#icon24"
                            ></use>
                          </svg>
                        </button>
                      )}
                  </div>
                )}
                {form.ownershipCertificate && (
                  <div className="videoCardTextUnderVideo">
                    <a href={form.ownershipCertificate}>
                      Підтвердження права власності
                    </a>
                    {form.statusId !== "99ca4f35-663c-4e0a-b9ef-f70219aa8163" &&
                      form.statusId !==
                        "036735ba-f634-469b-ac30-77a164e3a918" && (
                        <button
                          className="secondaryGhostButtonIconTextDefault"
                          id="ownershipCertificate"
                          onClick={deleteClick}
                        >
                          <svg id="ownershipCertificate">
                            <use
                              id="ownershipCertificate"
                              href="/images/icon24Trash.svg#icon24"
                            ></use>
                          </svg>
                        </button>
                      )}
                  </div>
                )}
              </>
            ) : (
              <span className="apEmptyFileList">Файлів немає</span>
            )}
          </div>
          {!form.registryCertificate &&
            form.statusId !== "99ca4f35-663c-4e0a-b9ef-f70219aa8163" &&
            form.statusId !== "036735ba-f634-469b-ac30-77a164e3a918" && (
              <div className="apFilterGroupBlock" tabIndex="0">
                <span>
                  Додати файл свiдоцтво про реєстрацiю юридичної особи{" "}
                </span>
                <input
                  type="file"
                  accept=".doc, .docx, .xls, .xlsx, .rtf, .pdf, .txt"
                  id="registryCertificate"
                  style={{ display: "none" }}
                  onChange={getFileContext}
                  tabIndex="0"
                />
                <label
                  htmlFor="registryCertificate"
                  className="secondaryButtonIconTextDefault label"
                  onDrop={getFileContext}
                  tabIndex="0"
                >
                  <svg>
                    <use href="/images/icon24Download.svg#icon24"></use>
                  </svg>
                  {loading === "registryCertificate" ? (
                    <Loader />
                  ) : (
                    "Обрати та завантажити файл"
                  )}
                </label>
              </div>
            )}

          {!form.ownershipCertificate &&
            form.statusId !== "99ca4f35-663c-4e0a-b9ef-f70219aa8163" &&
            form.statusId !== "036735ba-f634-469b-ac30-77a164e3a918" && (
              <div className="apFilterGroupBlock" tabIndex="0">
                <span>Додати файл підтвердження права власності</span>
                <input
                  type="file"
                  accept=".doc, .docx, .xls, .xlsx, .rtf, .pdf, .txt"
                  id="ownershipCertificate"
                  style={{ display: "none" }}
                  onChange={getFileContext}
                  tabIndex="0"
                />
                <label
                  htmlFor="ownershipCertificate"
                  className="secondaryButtonIconTextDefault label"
                  onDrop={getFileContext}
                  tabIndex="0"
                >
                  <svg>
                    <use href="/images/icon24Download.svg#icon24"></use>
                  </svg>
                  {loading === "ownershipCertificate" ? (
                    <Loader />
                  ) : (
                    "Обрати та завантажити файл"
                  )}
                </label>
              </div>
            )}
        </div>
      </>
    );
  }
};

export default ModalFiles;
