import { useMemo } from "react";

import { ReactComponent as Telegram } from "../../assets/images/telegram.svg";
import { ReactComponent as DiiaLogo } from "../../assets/images/DiiaLogo.svg";
import { ReactComponent as Apple } from "../../assets/images/Apple.svg";
import { ReactComponent as Facebook1 } from "../../assets/images/Facebook1.svg";
import { ReactComponent as Google } from "../../assets/images/Google.svg";
import { ReactComponent as Youtube } from "../../assets/images/youtube.svg";
import { ReactComponent as Instagram } from "../../assets/images/instagram.svg";
import { ReactComponent as Facebook } from "../../assets/images/facebook.svg";
import { ReactComponent as News } from "../../assets/images/newsIcon.svg";
import { ReactComponent as Rocket } from "../../assets/images/rocketIcon.svg";
import { ReactComponent as Users } from "../../assets/images/usersIcon.svg";
import { ReactComponent as Search } from "../../assets/images/search.svg";
import { ReactComponent as Plus } from "../../assets/images/plus.svg";
import { ReactComponent as Qr } from "../../assets/images/qrIcon.svg";
import { ReactComponent as Male } from "../../assets/images/genderIcon1.svg";
import { ReactComponent as Female } from "../../assets/images/genderIcon2.svg";
import { ReactComponent as Edit } from "../../assets/images/EditIcon.svg";
import { ReactComponent as At } from "../../assets/images/atIcon.svg";
import { ReactComponent as Pin } from "../../assets/images/pin.svg";
import { ReactComponent as Star } from "../../assets/images/star.svg";
import { ReactComponent as StarFill } from "../../assets/images/starFill.svg";
import { ReactComponent as Info } from "../../assets/images/infoIcon.svg";
import { ReactComponent as Cross } from "../../assets/images/crossIcon.svg";
import { ReactComponent as Calendar } from "../../assets/images/calendarIcon.svg";
import { ReactComponent as Copy } from "../../assets/images/copyIcon.svg";
import { ReactComponent as List } from "../../assets/images/list.svg";
import { ReactComponent as Frame } from "../../assets/images/frameIcon.svg";
import { ReactComponent as Logout } from "../../assets/images/Login.svg";
import { ReactComponent as Login } from "../../assets/images/Logout.svg";
import { ReactComponent as CircleCheck } from "../../assets/images/circleCheck.svg";
import { ReactComponent as User } from "../../assets/images/userIcon.svg";
import { ReactComponent as Timer } from "../../assets/images/timerIco.svg";
import { ReactComponent as Cake } from "../../assets/images/cakeIco.svg";
import { ReactComponent as Hamburger } from "../../assets/images/Hamburger.svg";
import { ReactComponent as Notification } from "../../assets/images/notification.svg";
import { ReactComponent as NotificationOff } from "../../assets/images/notificationsOff.svg";
import { ReactComponent as Log } from "../../assets/images/log.svg";
import { ReactComponent as Plan } from "../../assets/images/plan.svg";
import { ReactComponent as SubmitArrow } from "../../assets/images/submitArrowIcon.svg";
import { ReactComponent as File } from "../../assets/images/fileIcon.svg";
import { ReactComponent as RoundCross } from "../../assets/images/roundCrossIcon.svg";
import { ReactComponent as Time } from "../../assets/images/timeIcon.svg";

import { ReactComponent as Blank } from "../../assets/images/blankico.svg";
import { ReactComponent as Weight } from "../../assets/images/weightIco.svg";
import { ReactComponent as Ex } from "../../assets/images/exIco.svg";
import { ReactComponent as Message } from "../../assets/images/messageIco.svg";
import { ReactComponent as Tel } from "../../assets/images/telIco.svg";

import { ReactComponent as Alarm } from "../../assets/images/Alarm.svg";
import { ReactComponent as AvarageTemp } from "../../assets/images/avarageTemp.svg";
import { ReactComponent as AvarageHeartRate } from "../../assets/images/AvarageHeartRate.svg";
import { ReactComponent as Calories } from "../../assets/images/Calories.svg";
import { ReactComponent as Distance } from "../../assets/images/Distance.svg";
import { ReactComponent as Hight_Down } from "../../assets/images/Hight_Down.svg";
import { ReactComponent as Hight_Up } from "../../assets/images/Hight_Up.svg";
import { ReactComponent as Speed } from "../../assets/images/Speed.svg";
import { ReactComponent as TotalTrainings } from "../../assets/images/totalTrainings.svg";
import { ReactComponent as TotalEvents } from "../../assets/images/totalEvents.svg";

import { ReactComponent as Turn } from "../../assets/images/turn.svg";
import { ReactComponent as TurnRight } from "../../assets/images/turnRight.svg";
import { ReactComponent as TurnLeft } from "../../assets/images/turnLeft.svg";
import { ReactComponent as Trash } from "../../assets/images/Trash.svg";

import { ReactComponent as Sword } from "../../assets/images/Sword.svg";
import { ReactComponent as UsersThree } from "../../assets/images/UsersThree.svg";
import { ReactComponent as FilmStrip } from "../../assets/images/FilmStrip.svg";

import { ReactComponent as Ic_sun } from "../../assets/images/ic_sun.svg";
import { ReactComponent as Ic_cloud } from "../../assets/images/ic_cloud.svg";
import { ReactComponent as Ic_cloud_rain } from "../../assets/images/ic_cloud_rain.svg";
import { ReactComponent as Ic_snowflake } from "../../assets/images/ic_snowflake.svg";


import { ReactComponent as Ic_smile_five } from "../../assets/images/ic_smile_five.svg";
import { ReactComponent as Ic_smile_four } from "../../assets/images/ic_smile_four.svg";
import { ReactComponent as Ic_smile_three } from "../../assets/images/ic_smile_three.svg";
import { ReactComponent as Ic_smile_two } from "../../assets/images/ic_smile_two.svg";
import { ReactComponent as Ic_smile_one } from "../../assets/images/ic_smile_one.svg";
import { ReactComponent as Ic_smile_blank } from "../../assets/images/ic_smiley_blank.svg";
import { ReactComponent as Ic_smile } from "../../assets/images/ic_smiley.svg";

import { ReactComponent as Bicycle } from "../../assets/images/Bicycle.svg";
import { ReactComponent as Bookmark } from "../../assets/images/Bookmark.svg";
import { ReactComponent as PersonSimpleRun } from "../../assets/images/PersonSimpleRun.svg";
import { ReactComponent as PersonSimpleScandinavian } from "../../assets/images/PersonSimpleScandinavian.svg";
import { ReactComponent as PersonSimpleWalk } from "../../assets/images/PersonSimpleWalk.svg";
import { ReactComponent as Steps } from "../../assets/images/Steps.svg";
import { ReactComponent as IMT } from "../../assets/images/Icon_IMT.svg";
import { ReactComponent as DIA } from "../../assets/images/diia_logo.svg";
import { ReactComponent as Tryzub } from "../../assets/images/Tryzub2.svg";





export const Icon = (props) => {
  const icon = useMemo(() => {
    switch (props.type) {
      case "IMT":
        return <IMT />; 
      case "DIA":
        return <DIA />; 
      case "Walk":
        return <PersonSimpleWalk />; 
      case "steps":
        return <Steps />;
      case "Scandinavian":
        return <PersonSimpleScandinavian />;
      case "Run":
        return <PersonSimpleRun />;
      case "Bookmark":
        return <Bookmark />;
        case "Bicycle":
          return <Bicycle />;
      case "Ic_smile_five":
        return <Ic_smile_five />;
      case "Ic_smile_four":
        return <Ic_smile_four />;
      case "Ic_smile_three":
        return <Ic_smile_three />;
      case "Ic_smile_two":
        return <Ic_smile_two />;
      case "Ic_smile_one":
        return <Ic_smile_one />;
      case "Ic_smile_blank":
        return <Ic_smile_blank />;
      case "Ic_smile":
        return <Ic_smile />;
      case "ic_sun":
        return <Ic_sun />;
      case "ic_cloud":
        return <Ic_cloud />;
      case "ic_cloud_rain":
        return <Ic_cloud_rain />;
      case "ic_snowflake":
        return <Ic_snowflake />;
      case "sword":
        return <Sword />;
      case "usersThree":
        return <UsersThree />;
      case "filmStrip":
        return <FilmStrip />;
      case "telegram":
        return <Telegram />;
      case "Trash":
        return <Trash />;
      case "Alarm":
        return <Alarm />;
      case "avarageTemp":
        return <AvarageTemp />;
      case "turnLeft":
        return <TurnLeft />;
      case "turnRight":
        return <TurnRight />;
      case "turn":
        return <Turn />;
      case "AvarageHeartRate":
        return <AvarageHeartRate />;
      case "Calories":
        return <Calories />;
      case "Distance":
        return <Distance />;
      case "Hight_Down":
        return <Hight_Down />;
      case "Hight_Up":
        return <Hight_Up />;
      case "Speed":
        return <Speed />;
      case "TotalTrainings":
        return <TotalTrainings />;
      case "TotalEvents":
        return <TotalEvents />;
      case "apple":
        return <Apple />;
      case "diia":
        return <DiiaLogo />;
      case "facebook1":
        return <Facebook1 />;
      case "google":
        return <Google />;
      case "youtube":
        return <Youtube />;
      case "instagram":
        return <Instagram />;
      case "facebook":
        return <Facebook />;
      case "news":
        return <News />;
      case "rocket":
        return <Rocket />;
      case "users":
        return <Users />;
      case "search":
        return <Search />;
      case "plus":
        return <Plus />;
      case "qr":
        return <Qr />;
      case "edit":
        return <Edit />;
      case "gender1":
        return <Male />;
      case "gender2":
        return <Female />;
      case "at":
        return <At />;
      case "pin":
        return <Pin />;
      case "star":
        return <Star />;
      case "starFill":
        return <StarFill />;
      case "info":
        return <Info />;
      case "cross":
        return <Cross />;
      case "calendar":
        return <Calendar />;
      case "copy":
        return <Copy />;
      case "list":
        return <List />;
      case "frame":
        return <Frame />;
      case "circleCheck":
        return <CircleCheck />;
      case "login":
        return <Login />;
      case "logout":
        return <Logout />;
      case "user":
        return <User />;
      case "cake":
        return <Cake />;
      case "timer":
        return <Timer />;
      case "hamburger":
        return <Hamburger />;
      case "notification":
        return <Notification />;
      case "notificationOff":
        return <NotificationOff />;
      case "log":
        return <Log />;
      case "plan":
        return <Plan />;
      case "submitArrow":
        return <SubmitArrow />;
      case "file":
        return <File />;
      case "roundCross":
        return <RoundCross />;
      case "time":
        return <Time />;
      case "blank":
        return <Blank />;
      case "weight":
        return <Weight />;
      case "ex":
        return <Ex />;
      case "message":
        return <Message />;
      case "tel":
        return <Tel />;
      case "Tryzub":
        return <Tryzub />;
  
        
      default:
        return null;
    }
  }, [props.type]);

  if (props.link)
    return (
      <a className="apSvgIcon" href={props.link} target="_blank">
        {icon}
      </a>
    );

  if (props.onClick || props.isButton)
    return (
      <button onClick={props.onClick} id={props.id} className={props.className || null}>
        {icon}
        {props.children ?? null}
      </button>
    );

  return icon;
};
