import {
    GETSTATISTICCOMPARE_FAIL,
    GETSTATISTICCOMPARE_SUCCESS,
    GETSTATISTIC_SUCCESS,
    GETSTATISTIC_FAIL,
    GETSTATISTICMOTIVATION_SUCCESS,
    GETSTATISTICMOTIVATION_FAIL
} from "./types";

import StatisticService from "../services/statistic.service"


export const getStatistic = (data)=> async(dispatch)=>{
        
    try{
        const res = await StatisticService.getStatistic(data);
        
        dispatch({
          type: GETSTATISTIC_SUCCESS,
          list: res,
        });
        
        return res
    }catch (error){
        
        dispatch({
            type: GETSTATISTIC_FAIL,
            list: {error: error.response},
          });
          const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
          return error.response
    }
}
export const getStatisticCompare = (data)=> async(dispatch)=>{
        
    try{
        const res = await StatisticService.getStatisticCompare(data);
        
        dispatch({
          type: GETSTATISTICCOMPARE_SUCCESS,
          list: res,
        });
        
        return res
    }catch (error){
        
        dispatch({
            type: GETSTATISTICCOMPARE_FAIL,
            list: {error: error.response},
          });
          const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
          return error.response
    }
}

export const getMotivation = (data)=> async(dispatch)=>{
        
    try{
        const res = await StatisticService.getMotivation(data);
        
        dispatch({
          type: GETSTATISTICMOTIVATION_SUCCESS,
          list: res,
        });
        
        return res
    }catch (error){
        
        dispatch({
            type: GETSTATISTICMOTIVATION_FAIL,
            list: {error: error.response},
          });
          const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
          return error.response
    }
}

