export const PlanItem = (props) => {
  return (
    <div className={`apTrainingItemContainer`}>
      {/* DAY_OF_TRAINING */}
      <div style={{display:'flex', justifyContent:'space-between',width:'100%'}}>
      <div className="apTrainingDate">{props.el?.startTime?.slice(0,-3) || '00:00'} - {props.el?.finishTime?.slice(0,-3)|| '00:00'}</div>
      <div style={{display:'flex', justifyContent:'space-between',width:'160px'}}>
        <a id={props.el.id} onClick={()=>props.editClick(props.el)} >Редагувати</a>
        <a id={props.el.id} onClick={props.deleteClick} >Видалити</a>
      </div>
      </div>
      {/* /DAY_OF_TRAINING */}

      {/* ITEM  u can double this block */}
      <div className="apTrainingItem">
        <div className="item">
          {/* HEADLINE */}
          <div className="headline">
            <h3 className="title">{props.el.title}</h3>
            <span className="type">{props.el.type}</span>
          </div>
          {/* /HEADLINE */}

          {/* MAIN_CONTENT */}
          <>
            <ul>
              {props.el.exercises?.map(el=>{
                return(
                   <li key={el}>{el}</li>
                )
              })}
            </ul>
          </>
          {/* /MAIN_CONTENT */}

        </div>
      </div>
      {/* /ITEM */}
    </div>
  );
};
