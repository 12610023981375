import {
    useEffect,
    useState
} from "react"

function getCurrentDeviceSize() {
    const {
        innerWidth,
        innerHeight
    } = window;
    return {
        innerWidth,
        innerHeight
    };
}

const useCurrentMediaType = (data = 677) => {
    const [currentDeviceSize, setCurrentDeviceSize] = useState(getCurrentDeviceSize());
    const [isCurrentSize, setIsCurrentSize] = useState(false);



    useEffect(() => {
        function handleWindowResize() {
            setCurrentDeviceSize(getCurrentDeviceSize());
        }

        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);

    useEffect(() => {
        if (currentDeviceSize.innerWidth < data) {
            setIsCurrentSize(true)
            return
        }
        setIsCurrentSize(false)
    }, [currentDeviceSize, data])


    return {
        currentDeviceSize,
        isCurrentSize
    }
}

export default useCurrentMediaType