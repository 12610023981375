import {
    CREATEFCNEWS_SUCCESS,
    CREATEFCNEWS_FAIL,
    GETFCNEWS_SUCCESS,
    GETFCNEWS_FAIL,
    GETFCNEWSITEM_SUCCESS,
    GETFCNEWSITEM_FAIL,
    EDITFCNEWS_SUCCESS,
    EDITFCNEWS_FAIL,
    DELETEFCNEWS_SUCCESS,
    DELETEFCNEWS_FAIL,
} from "../actions/types";

const initialState = [];

export default function newsReducer(news = initialState,action){
    const {list,item,type} = action;

    switch(type) {
        case  CREATEFCNEWS_SUCCESS:
            return {currentNews:item}
        case CREATEFCNEWS_FAIL:
            return news;
        case GETFCNEWS_SUCCESS:
            return {...list};
        case GETFCNEWS_FAIL:
            return news;
        case GETFCNEWSITEM_SUCCESS:
            return {...item};
        case GETFCNEWSITEM_FAIL:
            return news;
        case EDITFCNEWS_SUCCESS:
            return news;
        case EDITFCNEWS_FAIL:
            return news;
        case DELETEFCNEWS_SUCCESS:
            return news;
        case DELETEFCNEWS_FAIL:
            return news;
        default:
            return news;
    }

}