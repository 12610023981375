import React, { useEffect, useState } from "react";
import { MapContainer, TileLayer, Marker, Popup, useMap,Polyline, useMapEvents } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import { useDispatch, useSelector } from "react-redux";
import L from "leaflet";
import MarkerClusterGroup from "./MarkerClasterGroup/MarkerClasterGroup";
import "leaflet/dist/leaflet.css";
import "leaflet.markercluster/dist/MarkerCluster.css";
import "leaflet.markercluster/dist/MarkerCluster.Default.css";
import MyLocationMapButton from "../../../components/MyLocation/MyLocationMapButton";
import { useNavigate } from "react-router-dom"; // Import the useNavigate hook
import { getRoutePoints } from "../../../redux/actions/route";
import debounce from 'lodash/debounce';
import { getParksRoutesAll } from "../../../redux/actions/parks";


const markerIcon = new L.Icon({
  iconUrl: require("../../../assets/images/map_icon_mini.png"),
  // iconSize: [24, 24],
  iconAnchor: [12, 28], //[left/right, top/bottom]
  popupAnchor: [0, 0], //[left/right, top/bottom]
});

const markerIconMy = new L.Icon({
  iconUrl: require("../../../assets/images/myLocation.png"),
  iconSize: [24, 24],
  iconAnchor: [12, 13], //[left/right, top/bottom]
  // popupAnchor: [0, 0], //[left/right, top/bottom]
});

const markerIconType1 = new L.Icon({
  iconUrl: require("../../../assets/images/material-symbols_location-on-1.png"),
  iconSize: [24, 24],
  iconAnchor: [11, 22], //[left/right, top/bottom]
  popupAnchor: [0, 0], //[left/right, top/bottom]
});

const markerIconType2 = new L.Icon({
  iconUrl: require("../../../assets/images/material-symbols_location-on-2.png"),
  iconSize: [24, 24],
  iconAnchor: [11, 22], //[left/right, top/bottom]
  popupAnchor: [0, 0], //[left/right, top/bottom]
});

const markerIconType3 = new L.Icon({
  iconUrl: require("../../../assets/images/material-symbols_location-on-3.png"),
  iconSize: [24, 24],
  iconAnchor: [11, 22], //[left/right, top/bottom]
  popupAnchor: [0, 0], //[left/right, top/bottom]
});


const POSITION_CLASSES = {
  bottomleft: "leaflet-bottom leaflet-left",
  bottomright: "leaflet-bottom leaflet-right",
  topleft: "leaflet-top leaflet-left",
  topright: "leaflet-top leaflet-right",
  top: "leaflet-top leaflet-center",
};

const openInNewTab = (url) => {
  window.open(url, "_blank", "noopener,noreferrer");
};

function MyComponent() {
  const { coordinates } = useSelector((state) => state);
  const map = useMap();
  useEffect(() => {
    map.flyTo(coordinates, map.getZoom());
  }, [coordinates]);
  return null;
}

  function CurrentPosition2(props){
    const map = useMap();
    const [lastData, setLastData] = useState({});
    const dispatch = useDispatch();


    const calculateRadius = (zoomLevel) => {
      if (zoomLevel <= 7) {
        return 2000; // Example: Set a fixed radius for low zoom levels
      } else if (zoomLevel == 8) {
        return 350; // Example: Set a different radius for medium zoom levels
      }else if (zoomLevel == 9) {
        return 170; // Example: Set a different radius for medium zoom levels
      }else if (zoomLevel == 10) {
        return 140; // Example: Set a different radius for medium zoom levels
      }else if (zoomLevel == 11) {
        return 120; // Example: Set a different radius for medium zoom levels
      }else if (zoomLevel == 12) {
        return 100; // Example: Set a different radius for medium zoom levels
      }else if (zoomLevel == 13) {
        return 80; // Example: Set a different radius for medium zoom levels
      }else if (zoomLevel == 14) {
        return 60; // Example: Set a different radius for medium zoom levels
      }else if (zoomLevel == 15) {
        return 40; // Example: Set a different radius for medium zoom levels
      }
       else {
        return 10; // Example: Set another radius for high zoom levels
      }
    };

    const getNewList=(data)=>{
      setLastData(data);
      let req ={
        offset: 0,
        limit: 100,
        filter: {},
        sort: { distanceToPoint: "asc" }
      }
      req.filter.longitude=[data.lng];
      req.filter.latitude=[data.lat];
      req.filter.radius = [data.radius];
      req.filter.statusId = ["036735ba-f634-469b-ac30-77a164e3a918"];
      dispatch(getParksRoutesAll(req)).then((res) => {
        console.log('Getted Parks')
      });

    }

    const handleChangeZoom = () => {
      const newZoomLevel = map.getZoom();
      const newRadius = calculateRadius(newZoomLevel);
      const a = map.getCenter();
      let data = {
        lat: a.lat,
        lng: a.lng,
        radius: newRadius,
      };
      if(lastData==data || (lastData.radius == data.radius && lastData.lat ==data.lat)){
      }else{
        getNewList(data);
      }
    };


    useEffect(() => {
      map.on('moveend', () => {
        handleChangeZoom();
      });
      map.on('zoomend', handleChangeZoom);
      return () => {
        map.off('moveend',handleChangeZoom);
        map.off('zoomend', handleChangeZoom);
      };
    }, [map]);
  }

  
const Map = (props) => {
  const { coordinates } = useSelector((state) => state);
  const { parksMap } = useSelector((state) => state);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [currentRoute,setCurrentRoute]=useState([]);

  const iconBase = "images/iconPark";
  const icons = {
    "036735ba-f634-469b-ac30-77a164e3a918": {
      icon: iconBase + "Active.svg",
    },
    "977f1037-77ca-4e7a-90ef-dc1a974e2ff2": {
      icon: iconBase + "Closed.svg",
    },
    "3587410c-7bfa-46c7-a003-c16a408c3004": {
      icon: iconBase + "Candidate.svg",
    },
    "99ca4f35-663c-4e0a-b9ef-f70219aa8163": {
      icon: iconBase + "Inactive.svg",
    },
    "route": {
      icon: iconBase + "Candidate.svg",
    },
  };

  const handleMarkerClick = (id) => {
    const markerId = id;
    setCurrentRoute(undefined);
    dispatch(getRoutePoints({id:markerId})).then(res=>{
      setCurrentRoute(res.pointsActiveRoutes);
    })
    console.log(`Marker clicked with id: ${markerId}`);
  };

  const redOptions = { color: "red" };
  const connectDots=(data)=> {
    // let c = [[50.4393, 30.5479],[51.4393, 31.5479]];
    let c = [];
    let i;

    for (i = 0; i < data?.length; i += 1) {
      if(data[i]?.latitude){
        let coords = [data[i].latitude,data[i].longitude];
        c.push(coords);
      }
    }
    return c;
  }

  return (
      <MapContainer
        style={{ height: 600 }}
        center={
          props.coordinates
            ? [props.coordinates.lat, props.coordinates.lng]
            : [coordinates.lat, coordinates.lng]
        }
        zoom={9}
        scrollWheelZoom={true}
      >
         <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://openstreetmap.pp.ua/hot/{z}/{x}/{y}.png"
        />
        {/* <RangeInput
          position="topright"
        /> */}

        <MyComponent />
       

        {/* https://tile.openstreetmap.org.ua/data/v3/5/13/9.pbf */}
        <Marker
          position={ props.coordinates
            ? [props.coordinates.lat, props.coordinates.lng]
            : [coordinates.lat, coordinates.lng]}
          icon={markerIconMy}
        ></Marker>
        <MarkerClusterGroup>
          {props.parksActive && parksMap &&
            parksMap.items.length > 0 &&
            parksMap.items.map((element, idx) => {
              return (
                <Marker
                  position={[element.location[0], element.location[1]]}
                  icon={markerIconType2}
                >
                  <Popup>
                    <div
                      className="apgmtt2"
                      style={{ backgroundImage: `URL(${element.photo || (element.photos?.length>0&&element.photos[0])})` }}
                    ></div>
                    <a
                      onClick={() => navigate(`/parkInfo/${element.id}`)}
                      className="apgmtt3"
                    >
                      {element.title.length > 0 ? element.title : element.code}
                    </a>

                    <div className="buttonControl">
                      <button
                        className="primaryButtonTextDefault"
                        onClick={() =>
                          openInNewTab(
                            `https://www.google.com.ua/maps/place/${element.location[0]}+${element.location[1]}`
                          )
                        }
                      >
                        Маршрут
                      </button>
                      <button
                        className="secondaryButtonTextDefault"
                        onClick={() => navigate(`/parkInfo/${element.id}`)}
                      >
                        Інформація
                      </button>
                    </div>

                    <div className="apgmtt4">
                      <div className="apPublished">Опубліковано</div>
                    </div>
                  </Popup>
                </Marker>
              );
            })}
        </MarkerClusterGroup>
        <MarkerClusterGroup>
          {props.routesActive &&  parksMap &&
            parksMap.itemsActive.length > 0 &&
            parksMap.itemsActive.map((element, idx) => {
              if(element.location[0]){
              return (
                <Marker
                  position={[element.location[0], element.location[1]]}
                  icon={markerIconType3}
                  eventHandlers={{
                    click: () => handleMarkerClick(element.id),
                  }}
                >
                  <Popup>
                    <div
                      className="apgmtt2"
                      style={{ backgroundImage: `URL(${element.photo || (element.photos?.length>0&&element.photos[0])})` }}
                    ></div>
                    <a
                      onClick={() => navigate(`/parkInfo/${element.id}`)}
                      className="apgmtt3"
                    >
                      {element.name.length > 0 ? element.name : element.address}
                    </a>

                    <div className="buttonControl">
                      <button
                        className="primaryButtonTextDefault"
                        onClick={() =>
                          openInNewTab(
                            `https://www.google.com.ua/maps/place/${element.location[0]}+${element.location[1]}`
                          )
                        }
                      >
                        Маршрут
                      </button>
                      <button
                        className="secondaryButtonTextDefault"
                        onClick={() => navigate(`/routeInfo/${element.id}`)}
                      >
                        Інформація
                      </button>
                    </div>

                    <div className="apgmtt4">
                      <div className="apPublished">Опубліковано</div>
                    </div>
                  </Popup>
                </Marker>
              );}
            })}
        </MarkerClusterGroup>
        {props.routesActive && <Polyline pathOptions={redOptions} positions={connectDots(currentRoute)} />
        }
        <MyLocationMapButton position='bottomright'/>
        {/* <CurrentPosition
          getMyCoordinates={props.getMyCoordinates}
          // changeRange={props.changeRange}
          // range={props.range}
        /> */}
        <CurrentPosition2></CurrentPosition2>
      </MapContainer>
  );
};

export default Map;
