import React, { useState } from "react";
import { Icon } from "../../../components/Icon";

export const Search = (props) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleSearchClick = () => {
    setIsOpen((s) => !s);
  };

  return (
    <div className="apSearch" style={{marginLeft:'10px'}}>
      {isOpen && (
        <div className="apInputTextIcon">
          <label htmlFor="search">Пошук</label>
          <div>
            <input
              onChange={props.onChangeComponent}
              id="search"
              type="text"
              placeholder="Назва клубу (від 3 символів)"
            />
          </div>
        </div>
      )}
      {isOpen ? (
        <button
          onClick={() => {
            props.onClose();
            handleSearchClick();
          }}
          className={"active"}
        >
          <Icon type={"cross"} />
        </button>
      ) : (
        <button onClick={handleSearchClick}>
          <Icon type={"search"} />
        </button>
      )}
    </div>
  );
};
