import { useMemo,useEffect,useState   } from "react";

export const useSlicedData = (arr) => {
  const { array1, array2 } = useMemo(() => {
    const array1 = arr?.length > 0 ? arr?.slice(arr?.length / 2) : [];
    const array2 = arr?.length > 0 ? arr?.slice(0, arr?.length / 2) : [];
    return {
      array1,
      array2,
    };
  }, [JSON.stringify(arr)]);

  const dataArray = [array1, array2];


  const [readyArray, setReadyArray] = useState([]);
  useEffect(() => {
    const reversedDataArray = dataArray.map((arr) => [...arr].reverse());
    const flattenedArray = [].concat(...reversedDataArray);
    setReadyArray(flattenedArray);
  }, [arr]);


  console.log(readyArray);
  console.log(dataArray);


  return {
    array1,
    array2,
    dataArray,
    readyArray
  };
};
