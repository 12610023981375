import React, { useCallback, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import InfoWindow from "../../components/InfoWindow/InfoWindow";
import Loader from "../../components/Loader/Loader";
import Editor from "../../components/Editor/Editor";
import { uploadFile } from "../../redux/actions/file";
import { addInfo, deleteInfo, editInfo, getInfoItem } from "../../redux/actions/info";

const InfoPage = () => {
  const [selected, setSelected] = useState("");
  const [ready, setReady] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [form, setForm] = useState({});
  const [data, setData] = useState("");
  const { fullList } = useSelector((state) => state);
  const { listId } = useSelector((state) => state);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertModal, setAlertModal] = useState(false);
  const currentId = useParams().id;
  
  const customFunc = (data) => {
      setData(data);
  };


  const customSave = (e) => {
    let resp = { ...form };
    resp.body = e;
    setForm({ ...form, body: resp.body });
    saveEdit(resp);
  };

  const changeHandler = (e) => {
    setForm({ ...form, [e.target.id]: e.target.value });
  };
  const changeStatus = (e) => {
    let data = {};
    data.type = e.target.accessKey;
    data.id = currentId;

    dispatch(addInfo(data)).then((res) => {
      getItem();
    });
  };
  const setSelectClick = (e) => {
    setForm({ ...form, [e.target.accessKey]: e.target.id });
    form[e.target.accessKey] = e.target.id;
    setSelected("");
    saveEdit(form);
  };
  const openSelect = (e) => {
    if (e.target.id === selected) {
      setSelected("");
      saveEdit();
    } else {
      setSelected(e.target.id);
    }
  };
  const getItem = () => {
    dispatch(getInfoItem({ id: currentId }))
      .then((res) => {
        setForm({ ...res });
        setReady(true);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const saveEdit = useCallback(
    (data) => {
      dispatch(editInfo(data && data.body ? data : form)).then((res) => {
        getItem();
      });
    },
    [form]
  );

  const deleteClick =(e)=>{
    dispatch(deleteInfo({id:currentId})).then(res=>{
      navigate('/infolist')
    }).catch(e=>{
      console.log(e)
    })
  }
  useEffect(() => {
    if (!ready) {
      getItem();
    }
  }, [ready]);

  if (ready) {
    return (
      <>
        {alertModal && (
          <InfoWindow
            setModal={setAlertModal}
            message={alertMessage}
          ></InfoWindow>
        )}
        <h1>Створення інформаційної сторінки</h1>
        <div className="menuFunctionLine">
          <div className="actionButtonsBlock">
            <button
              className="secondaryButtonIconTextDefault"
              onClick={() => navigate("/infolist")}
            >
              <svg>
                <use href="/images/icon24ArrowLeft.svg#icon24" />
              </svg>
              Назад
            </button>
            {form.statusId === "35rt3s0c-7bfa-46c7-a003-c16a408c3004" && (
              <button
                className="primaryButtonIconTextDefault"
                accessKey="publish"
                onClick={changeStatus}
              >
                <svg>
                  <use href="/images/icon24Rocket.svg#icon24" />
                </svg>
                {"Опублікувати"}
              </button>
            )}
            {form.statusId === "032345ba-f634-469b-ac30-77a164e3a918" && (
              <button
                className="primaryButtonIconTextDefault"
                accessKey="to-draft"
                onClick={changeStatus}
              >
                <svg>
                  <use href="/images/icon24Rocket.svg#icon24" />
                </svg>
                {"Зняти з публікації та редагувати"}
              </button>
            )}
            {form.statusId === "35rt3s0c-7bfa-46c7-a003-c16a408c3004" && <button className="secondaryButtonIconTextDefault" onClick={deleteClick}>
              <svg><use href="/images/icon24Trash.svg#icon24" /></svg>
              Видалити
            </button>}
          </div>
        </div>
        <div className="pageContentHeaderDivider"></div>

        <div className="videoCard2Columns">
          <div className="videoCardCoverContainer">
            <h3>Назва та категорія</h3>
            <div
              style={{ width: "100%" }}
              className={
                form.statusId === "032345ba-f634-469b-ac30-77a164e3a918"
                  ? "apInputText apDisabled"
                  : `${form.title ? "apInputText apFilled" : "apInputText"}`
              }
            >
              <label htmlFor="title">Назва</label>
              <input
                id="title"
                type="text"
                disabled={
                  form.statusId === "032345ba-f634-469b-ac30-77a164e3a918"
                }
                value={form.title}
                onChange={changeHandler}
                onBlur={saveEdit}
              />
            </div>
            <div className={
                form.statusId === "032345ba-f634-469b-ac30-77a164e3a918"
                  ? "apDropDownList apDisabled"
                  : `${form.title ? "apDropDownList apFilled" : "apDropDownList"}`
              }>
              <label htmlFor="sectionId">Категорія</label>
              <div id="sectionId" onClick={form.statusId !== "032345ba-f634-469b-ac30-77a164e3a918" ? openSelect: undefined}>
                <input
                  id="sectionId"
                  readOnly
                  disabled={
                    form.statusId === "032345ba-f634-469b-ac30-77a164e3a918"
                  }
                  value={form?.sectionId?.length>0 ? listId.list[form.sectionId] : ""}
                  type="text"
                />
                <svg id="sectionId">
                  <use
                    id="sectionId"
                    href="/images/icon24DropDown.svg#icon24"
                  />
                </svg>
              </div>
              {selected == "sectionId" && (
                <div className="apOptionsList">
                  {fullList.InfoPageSections.map((el) => {
                    return (
                      <div
                        id={el.id}
                        accessKey="sectionId"
                        key={el.id}
                        onClick={setSelectClick}
                      >
                        {el.title}
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="groundPropertiesHeader">
          <h2>Контент</h2>
        </div>

        <Editor
          id="body"
          url={"content-pages"}
          onChange={customFunc}
          onBlur={customSave}
          value={form.body}
          disabled={
            form.statusId === "032345ba-f634-469b-ac30-77a164e3a918"
          }
        />
      </>
    );
  } else {
    return <Loader />;
  }
};

export default InfoPage;
