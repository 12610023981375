import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { Icon } from "../Icon";
import useCurrentMediaType from "../../hooks/currentMediaType";
import getDate from "../../helpers/getDate";

import { Status } from "../../components/Status";

export const FcTile = ({
  id,
  logo,
  title,
  slogan,
  address,
  location,
  place,
  members,
  eventDate,
  contact,
  status,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const { isCurrentSize: isMobile } = useCurrentMediaType(991);
  const navigate = useNavigate();
  const handleRoute = () => {
    navigate(`/fc/${id}/about`);
  };
  return isMobile ? (
    <div className="apFcItemTileContainer" onClick={handleRoute}>
      <div className="apFcItemTile" onClick={() => setIsOpen((s) => !s)}>
        <div className="img-wrp">
          <div
            className="img"
            style={{
              backgroundImage: `url(${
                logo ||
                "https://ap.sportforall.gov.ua/api/v1/uploads/b34604xc-02qb-46bb-9e91-fc3ffrb92w41"
              })`,
            }}
          ></div>
        </div>
        <div className="text">
          <span className="title">{title}</span>
          <span className="slogan">{slogan}</span>
          <Status type={"club"} statusId={status?.id?status.id:status} statusText={status?.text} />
          {/* <div className={`status ${status.id}`}>{status.text}</div> */}
        </div>
      </div>
      {isOpen && (
        <div className="apFcItemTileInner">
          <div className="item">
            <Icon type={"rocket"} />
            <span>
              {eventDate ?? "---"}
              <span>Найближчий захід</span>
            </span>
          </div>
          <div className="item">
            <Icon type={"users"} />
            <span>
              {members}
              <span>Учасники</span>
            </span>
          </div>
          <div className="btm-line">
            <button onClick={handleRoute}>Детальніше</button>
          </div>
        </div>
      )}
    </div>
  ) : (
    <div className="apFcItemTileContainer" onClick={handleRoute}>
      <div className="apFcItemTile">
        <div className="col img-wrp">
          <div
            className="img"
            style={{
              backgroundImage: `url(${
                logo ||
                "https://ap.sportforall.gov.ua/api/v1/uploads/b34604xc-02qb-46bb-9e91-fc3ffrb92w41"
              })`,
            }}
          ></div>
        </div>
        <div className="col title-col">
          <span>{title}</span>
          {slogan}
        </div>
        <div className="col address-col">
          <a
            href={`https://www.google.com.ua/maps/search/${location}/${place}`}
            target="_blank"
          >
            <span>{address || location && location[0].toFixed(4)+' ' +location[1].toFixed(4) }</span>
            {place}
          </a>
        </div>
        <div className="col members-col">
          <span>{members}</span>
          Учасники
        </div>
        <div className="col event-col">
          <span>
            {" "}
            {eventDate?.startsAt?.length>0
              ? getDate(eventDate.startsAt, "dd/mm/yyyy", ".")
              : "Невідомо"}
          </span>
          Найближчий захід
        </div>
        <div className="col contect-col">
          <span>
            {contact ? <a href={`tel:${contact}`}>{contact}</a> : "---"}
          </span>
          Контакти
        </div>
        <div className="col status-col">
          <Status type={"club"} statusId={status?.id?status.id:status} statusText={status?.text} />

          <Icon onClick={handleRoute} type={"info"} />
        </div>
      </div>
    </div>
  );
};
