import axios from "axios";

import constants from '../../constants/constant.json';
import authHeader from "./auth-header";


const getDictionariesList = (data)=>{
      
    return axios.get(constants.URL + 'api/v1/dictionaries/'+data+'?offset=0&limit=100&sort[title]=asc').then((response) => { 
        
        return response.data;})
    .catch((e)=>{
        console.log(e);
    });
}
const getAdditionalDictionariesList = (data)=>{
    return axios.get(constants.URL + 'api/v1/dictionaries/regions/'+data+'?offset=0&limit=15&sort[title]=asc').then((response) => { 
        return response.data;})
    .catch((e)=>{
        console.log(e);
    });
}

const deleteDictionariesItem = (data)=>{
    return axios.delete(constants.URL + 'api/v1/dictionaries/'+data.category +'/'+data.id,{ headers: authHeader()})
    .then((response) => { 
          
        return response.data})
    .catch((e)=>{
          
        console.log(e);
    });
}
const editDictionariesItem = (data)=>{
    let body
    body = {title:data.title};

      
    return axios.put(constants.URL + 'api/v1/dictionaries/'+data.category +'/'+data.id,{...data},{ headers: authHeader()})
    .then((response) => { 
          
        return response.data})
    .catch((e)=>{
        console.log(e);
    });
}

const addDictionariesItem = (data)=>{
          
    return axios.post(constants.URL + 'api/v1/dictionaries/'+data.category,{...data},{ headers: authHeader()})
    .then((response) => { 
        return response.data})
    .catch((e)=>{
          
        console.log(e);
    });
}

const getDictionariesItem = (data)=>{
    return axios.get(constants.URL + 'api/v1/dictionaries/'+data.category+'/'+data.id) .then((response) => { 
        return response.data;})
    .catch((e)=>{
           
        console.log(e);
    });
}

const getFullList = ()=>{
    return axios.get(constants.URL + 'api/v1/dictionaries').then((response) => { 
        
        return response.data})
    .catch((e)=>{
        console.log(e);
    });
}



export default{
    getDictionariesList,
    deleteDictionariesItem,
    editDictionariesItem,
    addDictionariesItem,
    getDictionariesItem,
    getAdditionalDictionariesList,
    getFullList
}