import React, { useState } from "react";
import FacebookLogin from '@greatsumini/react-facebook-login';
import { Icon } from "../../Icon";
import InfoWindow from "../../InfoWindow/InfoWindow";
import { useDispatch, useSelector } from "react-redux";
import { loginFacebook } from "../../../redux/actions/auth";
import { useNavigate } from "react-router";


const FacebookProvider = (props)=>{
    const [eror, setError] = useState(false);
    const {auth} = useSelector((state) => state);
    const dispatch= useDispatch();
    const navigate = useNavigate();


    const checkAuth=(response)=>{
        let data={
            "facebookToken": response.accessToken,
            "deviceId": "1",
            "typeId": 0,

        }
        dispatch(loginFacebook(data)).then(res=>{
            if(res.error==='Користувача не знайдено, потрібна реєстрація'){
                setError(true)
                navigate('/registration');
            }else{
                if(auth.isLoggedIn || (res.token && res.refreshToken)){
                    window.location.href = '/start';
                }
                else{
                    setError(true)
                    navigate('/registration');
                }
            }
           
        })
    }
    
    return(<>
    {eror && <InfoWindow   setModal={setError}
            message={'Не вдалося отримати дані з фейсбуку'}>
        </InfoWindow>}

    <FacebookLogin
    children={<Icon type='facebook1'/>}
    style={{
        backgroundColor: '#fff',
        padding: '5px 8px 5px 8px;',
        width:'41px',
        border: '1px solid #E8E8E8',
        borderRadius: '50%',
    }
    }
    appId="860919992102549"
    onSuccess={(response) => {
        props.setReady(false);
        localStorage.setItem('FBLogSuccess',JSON.stringify(response));
        checkAuth(response);
    }}
    onFail={(error) => {
        setError(true);
        console.log('Login Failed!', error);
    }}
    onProfileSuccess={(response) => {
        localStorage.setItem('FBProfSuccess',JSON.stringify(response));
    }}
    />
    </>)
}

export default FacebookProvider;