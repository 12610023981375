import {
    CREATE_USER,
    RETRIEVE_USERS,
    UPDATE_USER,
    UPDATEPASSWORD_SUCCESS,
    UPDATEPASSWORD_FAIL,
    CHANGEUSER,
    DELETEUSER_SUCCESS,
    DELETEUSER_FAIL
} from "../actions/types";
  
const initialState = [];

export default function usersReducer(users = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case CREATE_USER:
          return payload;
        case RETRIEVE_USERS:
          return payload;
        case  UPDATEPASSWORD_SUCCESS:
          return payload;
          case  CHANGEUSER:
          return users;
        case UPDATEPASSWORD_FAIL:
          return payload;
        case DELETEUSER_SUCCESS:
          return payload;
        case DELETEUSER_FAIL:
          return payload;
        case UPDATE_USER:
          return  payload;
        default:
          return users;
      }

}

