import React from "react";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { getAllCallendarEvents, getAllEvents, getDayCallendarEvents } from "../../redux/actions/events";
import Calendar from "../../components/Calendar";
import EventsList from "../../components/EventsList";
import Loader from "../../components/Loader/Loader";

const SportEventsCalendarPage = () => {
  const [ready, setReady] = useState(false);
  const dispatch = useDispatch();
  const { events } = useSelector((state) => state);
  const { coordinatesMy } = useSelector((state) => state);
  const [calendarForm, setCalendarForm] = useState({});
  
  
  
  const [form, setForm] = useState({ filter: {},sort:{'startsAt':'asc'}, offset: 0, limit: 30 });
  const navigate = useNavigate();

  const handleDateChange = (date) => {
    let yourDate = new Date(Date.parse(date));
    const offset = yourDate.getTimezoneOffset();
    yourDate = new Date(yourDate.getTime() - offset * 60 * 1000);
    let specDate = yourDate.toISOString().split("T")[0];

    let arr = { ...form };
    arr.filter["startsFrom"] = [];
    arr.filter["startsTo"] = [];
    arr.filter["startsFrom"].push(specDate);
    arr.filter["startsTo"].push(specDate);
    setForm(arr);
    getEventsList(1, arr.filter, arr.sort);
  };

  const sortClick =(e)=>{
    let sortName = e.target.accessKey;
    let arr = { ...form };
    arr.sort={};
    arr.sort[sortName]='asc';
    if(sortName==='distancetopointsort'){
      arr.filter['longitude']=[];
      arr.filter['latitude']=[];
      arr.filter['longitude'].push(coordinatesMy.lng);
      arr.filter['latitude'].push(coordinatesMy.lat);
    }else{
      arr.filter['longitude']=[];
      arr.filter['latitude']=[];
    }
    setForm(arr);

    getEventsList(1, arr.filter, arr.sort);
  }

  const filterClick =(e)=>{
    let filterName = e.target.accessKey;
    let arr = { ...form };
    if(arr.filter[filterName]?.length>0&& arr.filter[filterName][0]==='active'){
      arr.filter[filterName]=[];
    }else{
      arr.filter[filterName]=['active'];
    }
    setForm(arr);

    getEventsList(1, arr.filter, arr.sort);
  }





  const getCalendarEventsList=(filter)=>{
    let data = {};
    let yourDate = new Date();
    let yourDate2 = new Date();

    yourDate.setDate(1);
    yourDate2.setDate(31);
    const offset = yourDate.getTimezoneOffset();
    yourDate = new Date(yourDate.getTime() - offset * 60 * 1000);
    yourDate2=new Date(yourDate2.getTime() - offset * 60 * 1000);
    let specDate = yourDate.toISOString().split("T")[0];
    let specDate2 = yourDate2.toISOString().split("T")[0];

    if (filter) {
      data.filter = { ...filter };
    } else {
        data.filter = { startsFrom: [specDate],startsTo:[specDate2]  };
    }
      data.sort = { startsAt: "asc" };
    if (
      (data.filter && !data.filter.startsFrom) ||
      data.filter.startsFrom.length == 0
    ) {
      data.filter = { startsFrom: [specDate],startsTo:[specDate2] };
    }
    dispatch(getAllCallendarEvents(data)).then((res) => {
      setCalendarForm(res)
      setReady(true);
    });
  }

  const getEventsList = (page = 1, filter, sort) => {
    let data = {};
    let yourDate = new Date();
    const offset = yourDate.getTimezoneOffset();
    yourDate = new Date(yourDate.getTime() - offset * 60 * 1000);
    let specDate = yourDate.toISOString().split("T")[0];

    if (filter) {
      data.filter = { ...filter };
    } else {
        data.filter = { startsFrom: [specDate],startsTo:[specDate] };
    }
     
    if(sort){
      data.sort = { ...sort };
    } else {
      data.sort = { startsAt: "asc" };
    }
      // data.sort = { startsAt: "asc" };
    if (
      (data.filter && !data.filter.startsFrom) ||
      data.filter.startsFrom.length == 0
    ) {
      data.filter.startsFrom=[specDate]
      data.filter.startsTo=[specDate]
    }

    data.offset = (page - 1) * 30;
    data.limit = 30;
    dispatch(getDayCallendarEvents(data)).then((res) => {
      setReady(true);
    });
  };

  // const navigate = useNavigate();

  // const clickEvent = (e) => {
  //   navigate(e.currentTarget.id);
  // };

  useEffect(() => {
    if (!ready) {
      getEventsList();
      getCalendarEventsList();
    }
  }, [ready]);

  if(ready){
  return (
    <>
      <div className="headerWithButtonsHeaderBlock zahody">
        <h1>
          Заходи{" "}
          <div className="headerSideText">
            Обирайте захід, <br /> який ви хотіли би відвідати, плануйте свій час
          </div>
        </h1>
        <div className="apSwitch">
          <button
            className="secondaryButtonIconDefault"
            onClick={() => {
              navigate("/eventslist");
            }}
          >
            <svg>
              <use href="/images/icon24List.svg#icon24" />
            </svg>
          </button>
          <button className="secondaryButtonIconActive">
            <svg>
              <use href="/images/icon24Map.svg#icon24" />
            </svg>
          </button>
        </div>
      </div>

      <div className="pageContentHeaderDivider">&nbsp;</div>

      <Calendar onChange={handleDateChange} getCalendarEventsList={getCalendarEventsList} events={calendarForm.items} />

      {/* <h2>Перелік заходів
      </h2>  */}
      <div className="calendarFilter actionButtonsBlock">
        <div className='calendarFilterHeader'>
          Перелік заходів
        </div>
        <div className="buttonFullWidthScroll">
        <button  accessKey="distancetopointsort" disabled={coordinatesMy.length<1} onClick={sortClick} className={coordinatesMy.length<1?"calendarFilterItem secondaryButtonIconTextDefault disabled" :form.sort?.distancetopointsort?'primaryButtonIconTextDefault calendarFilterItem':"calendarFilterItem secondaryButtonIconTextDefault"}>
          <svg accessKey="distancetopointsort">
            <use
              accessKey="distancetopointsort"
              href="/images/icon24MapPin.svg#icon24"
              />
          </svg>
          За відстанню</button>
          <button accessKey="startsAt"  onClick={sortClick} className={form.sort?.startsAt?'primaryButtonIconTextDefault calendarFilterItem':"calendarFilterItem secondaryButtonIconTextDefault"}>
          <svg accessKey="startsAt">
              <use
                accessKey="startsAt"
                href="/images/icon24Report.svg#icon24"
                />
            </svg>
          За часом</button>
        {/* <button className="calendarFilterItem secondaryButtonIconTextDefault">
          <svg accessKey="to-moderation">
            <use
              accessKey="to-moderation"
              href="/images/icon24Camera.svg#icon24"
              />
          </svg>
          Онлайн</button> */}
          <button accessKey="holdingstatus" onClick={filterClick} className={form.filter?.holdingstatus?.length>0 && form.filter?.holdingstatus[0]==='active'?'primaryButtonIconTextDefault calendarFilterItem':"calendarFilterItem secondaryButtonIconTextDefault"}>
          <svg accessKey="holdingstatus">
            <use
              accessKey="holdingstatus"
              href="/images/icon24Rocket.svg#icon24"
              />
          </svg>
          Актуальне</button>
          </div>
      </div>  
      
      <div className="pageContentHeaderDivider"></div>
      <EventsList
        data={events.items}
        fallback={"Жодного запланованого заходу"}
      />
    </>
  );    
}else{
  return(
    <Loader/>
  )
}
};

export default SportEventsCalendarPage;
