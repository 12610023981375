import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router";
import axios from "axios";
import getDate from "../../helpers/getDate";
import { Status } from "../Status";

const EventsList = (props) => {
  const sortedData = useMemo(() => {
    const arr = props.data;
    if (!arr || arr.length === 0){return;}else if(arr.length>0){
      const dates = arr
      .map(({ startsAt }) => [getDate(startsAt, "dd/mmmm/yyyy", " ")])
      .filter((el, index) => {
        return (
          index === 0 ||
          el[0] !== getDate(arr[index - 1].startsAt, "dd/mmmm/yyyy", " ")
        );
      });

    arr.map((el) =>
      dates.map(
        (elem, index) =>
          dates[index] &&
          getDate(el.startsAt, "dd/mmmm/yyyy", " ") === elem[0] &&
          elem.push(el)
      )
    );
    return dates;
    }else{
      return;
    }
  }, [props.data]);

  return (
    <div className="apEventsGlobalContainer" style={{marginTop:'40px'}}>
      {(sortedData &&
        sortedData.map((el, index) => (
          <React.Fragment key={index}>
            <div className="CalendarListDate">{el[0].toLowerCase()}</div>
            <div className="apEventsContainer">
              {el &&
                el.map(
                  (
                    {
                      startsAt,
                      finishesAt,
                      sportsground,
                      shortDescription,
                      holdingStatusId,
                      id,
                      club,
                      title,
                      routePoints,
                      distanceToPoint,
                      startAdressPoint,
                      totalUsers,
                      maxCountUser
                    },
                    index
                  ) =>
                    index !== 0 && (
                      <EventItem
                        startsAt={getDate(startsAt, "hh:mm", ":")}
                        finishesAt={getDate(finishesAt, "hh:mm", ":")}
                        sportsground={sportsground}
                        shortDescription={shortDescription}
                        holdingStatusId={holdingStatusId}
                        totalUsers={totalUsers}
                        maxCountUser={maxCountUser}
                        id={id}
                        club={club}
                        title={title}
                        distanceToPoint={distanceToPoint}
                        routePoints={routePoints}
                        startAdressPoint={startAdressPoint}
                        key={`el.${id}.${index}`}
                      />
                    )
                )}
            </div>
          </React.Fragment>
        ))) || (
        <div className="apEventsContainer fallback">{props.fallback}</div>
      )}
    </div>
  );
};

export default EventsList;

const EventItem = (props) => {
  const navigate = useNavigate();
  const [adress,setAdress] =useState('');
  const [ready,setReady] =useState(false);
  const clickEvent = (e) => {
    navigate(e.currentTarget.id);
  };
  const locationClick =(e)=>{
    e.stopPropagation();
    window.open(`https://www.google.com.ua/maps/place/${props.routePoints[0]?.location[0]}+${props.routePoints[0]?.location[1]}`, "_blank", "noopener,noreferrer");
  }
  
  const getCurrentLocation = (lat, lon) => {
    return axios
      .get(
        `https://map.technodreams.biz/reverse?lat=${lat}&lon=${lon}&accept-language=ua&format=json`
      )
      .then((res) => {
        var address = ""
        let resource =res.data.address;
        if (resource.amenity?.length>0){
          address += `${resource.amenity}, `;
          }
        if (resource.city?.length>0){
        address += `${resource.city}`;
        }
        if (!resource.city&& resource.state?.length>0){
        address += `${resource.state}, `;
        }
        if (resource.town?.length>0){
        address += `${resource.town}`;
        }
        if (resource.village?.length>0){
          address += `${resource.village}`;
          }
      
        if (resource.road?.length>0){
        address += `, ${resource.road}`;
        }
        if (resource.building?.length>0){
          address += ` ${resource.building}`;
          }

        if (resource.house_number?.length>0){
        address += ` ${resource.house_number}`;
        }
          // if resource.historic?.length>0{
        // address += "\(resource.historic!), ";
        // }
        // if (resource.neighbourhood?.length>0){
        // address += `${resource.neighbourhood}, `;
        // }
        // if (resource.borough?.length>0){
        // address += `${resource.borough}, `;
        // }
        // if (resource.district?.length>0){
        // address += `${resource.district}, `;
        // }
        // if (resource.suburb?.length>0){
        // address += `${resource.suburb}, `;
        // }

        setAdress(address);
        setReady(true);
        return;
      })
      .catch((e) => {
        console.log(e);
        setAdress(lat.toFixed(5) +' - '+ lon.toFixed(5));
        setReady(true);
        return;
      });
  };

  useEffect(() => {
    if(!ready && props?.sportsground?.address){
      setAdress(props.sportsground.address);
      setReady(true);
    }else if(!ready &&props?.startAdressPoint?.length>0){
      setAdress(props.startAdressPoint);
      setReady(true);
    }
    else if(!ready && props.routePoints?.length>0){
      getCurrentLocation(props.routePoints[0]?.location[0], props.routePoints[0]?.location[1])
    }else{
      setReady(true);
    }
  }, [ready]);

    return (
      <div
        className={props.holdingStatusId==='0q8a6xc0-1nb4-1pr4-h5at-4sw3m0l387yp' ?'finishedEvent' +" apEventItem":  props.holdingStatusId==='tg2po97g-96r3-36hr-74ty-6tfgj1p8dzxq' ? 'holdingEvent' +" apEventItem": 'futureEvent'+" apEventItem"}
        id={`/fc-events/${props.club?.id||"0"}/${props.id}/about`}
        onClick={clickEvent}
      >
       {props.distanceToPoint&& <div className="distance">
          <span>{props.distanceToPoint.toFixed(1)+' км'}</span> 
          {/* Час проведення */}
        </div>}
        <div className="date">
          <span>{props.startsAt} - {props.finishesAt}</span> 
          <br />
          <span style={{fontWeight:'400', fontSize:'12px'}}>
          {props.totalUsers>0 &&<>Учасників: {props.totalUsers}</>}
          {props.maxCountUser > 0 &&<> із {props.maxCountUser}</>}
          </span>
          {/* Час проведення */}
        </div>
        {/* <div className="date">
          <span></span> Дата закінчення
        </div> */}
        <div className="title"><span>{props.title}</span></div>
        <div className="geo">
         {props.sportsground?.title ? <> <span>{props.sportsground?.title}</span>
          {/* Місце проведення заходу */}
          </>
         : props.routePoints?.length>0 && ready && <> {adress?.length>0?<span onClick={locationClick}> 
            {adress}
          {/* {props.routePoints[0]?.location[0] +' - '+ props.routePoints[0]?.location[1]} */}
          </span>:
            <button style={{marginBottom:'5px'}} className="primaryButtonTextDefault" onClick={locationClick}>
              Перейти до локації
            </button>
          }
          {/* Місце проведення заходу */}
          </>}
        </div>
        <div className="text">{props.shortDescription}</div>{" "}
        {/* <div className="status">
          <Status type={"eventHolding"} statusId={props.holdingStatusId} />
        </div> */}
      </div>
    );
  
};
