import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const RegionModal = (props) => {
  const [ready, setReady] = useState(false);
  const { fullList } = useSelector((state) => state);
  const [form, setForm] = useState([]);
  // const {videos} = useSelector(state=>state);
  let arr = props.form.regions;
  let initFunction = () => {
    setForm([...arr]);
    setReady(true);
  };

  useEffect(() => {
    if (!ready) {
      initFunction();
    }
  }, []);

  const setSelect = (e) => {
    arr = [...form];

    if (arr && arr.includes(e.target.id)) {
      const index = arr.indexOf(e.target.id);
      if (index > -1) {
        arr.splice(index, 1);
      }
    } else {
      if (arr) {
        arr.push(e.target.id);
      } else {
        arr = [];
        arr.push(e.target.id);
      }
    }
    setForm(arr);
  };
  const leaveClick = (event) => {
    if (!event.currentTarget.contains(event.relatedTarget)) {
      let data = { ...props.form };
      data.regions = form;
      props.setForm(data);
      props.saveEdit(data);
      props.setModal(false);
    }
  };
  const chooseAll = () => {
    let all = [];
    fullList.Regions.map((el) => {
      all.push(el.id);
    });
    setForm(all);
    arr = all;
  };
  const resetClick = () => {
    arr = [];
    setForm(arr);
  };

  return (
    <div className="apFilterContainer" onBlur={leaveClick} tabIndex="0">
      <div className="apFilterHeader">
        <h4>Регіони</h4>
        <div style={{ display: "flex" }}>
          <button
            style={{ marginRight: "20px" }}
            className="secondarySmallRoundedButtonTextDefault"
            onClick={chooseAll}
          >
            Вибрати все
          </button>
          <button
            style={{ marginRight: "20px" }}
            className="secondarySmallRoundedButtonTextDefault"
            onClick={resetClick}
          >
            Скинути
          </button>
          <button
            className="secondarySmallRoundedButtonTextDefault"
            onClick={leaveClick}
          >
            OK
          </button>
        </div>
      </div>
      <div className="apFilterGroupBlock">
        <div className="apFilterButtonsBlock">
          {fullList.Regions.map((el) => {
            return (
              <button
                id={el.id}
                key={el.id}
                accessKey="regionId"
                className={
                  form.length > 0 && form.includes(el.id)
                    ? "secondarySmallButtonTextActive"
                    : "secondarySmallButtonTextDefault"
                }
                onClick={setSelect}
              >
                {el.title}
              </button>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default RegionModal;
