import React, { useCallback, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import InfoWindow from "../../components/InfoWindow/InfoWindow";
import Loader from "../../components/Loader/Loader";
import Editor from "../../components/Editor/Editor";
import {
  addFcNews,
  deleteFcNews,
  editFcNews,
  getFcNewsItem,
} from "../../redux/actions/fcNews";
import PhotoUploud from "./PhotoUpload/PhotoUploud";
import useCurrentMediaType from "../../hooks/currentMediaType";

const FCNewsEditPage = () => {
  const [ready, setReady] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [form, setForm] = useState({});
  const [data, setData] = useState("");
  const { fullList } = useSelector((state) => state);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertModal, setAlertModal] = useState(false);
  const currentId = useParams().id;
  const newsCurrentId = useParams().newsId;
  const [isEmpty, setIsEmpty] = useState(false);
  const {currentUser} = useSelector(state=>state.currentUser);
  const [errorCus, setErrorCus] = useState(false);


  const { isCurrentSize } = useCurrentMediaType(1174);

  const customFunc = (e) => {
    const data = e;
    if(data.length>2040){
      setErrorCus(true)
    }else{
      setErrorCus(false)
      setData(data);
    }

  };
  const customSave = (e) => {
    let resp = { ...form };
    resp.body = e;
    setForm(resp);
    saveEdit(resp);
  };

  const changeHandler = (e) => {
    if(e.target.id==='title'){
      setIsEmpty(false)
      setAlertMessage('')
      setAlertModal(false);
    }
    setForm({ ...form, [e.target.id]: e.target.value });
  };
  const changeStatus = (e) => {
    if(form.statusId === "384fc74a-254e-47d7-9fcc-cd608d7e5f9d"){
      saveEdit();
    }
    
    if(form.title.length>0 || form.statusId !== "384fc74a-254e-47d7-9fcc-cd608d7e5f9d"){
      let data = {};
      data.type = e.target.accessKey;
      data.id = currentId;
      data.newsId = newsCurrentId;
      
      dispatch(addFcNews(data)).then((res) => {
        getItem();
      });
    }else{
      setAlertMessage('Заповніть поле "Назва" для публікації запису блогу')
      setIsEmpty(true)
      setAlertModal(true);
    }
  };

  const getItem = () => {
    dispatch(getFcNewsItem({ id: currentId, newsId: newsCurrentId }))
      .then((res) => {
        setForm({ ...res });
        setReady(true);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const saveEdit =(data) => {

      setIsEmpty(false);
      dispatch(editFcNews(data && data.body ? data : form)).then((res) => {});
    };

  const deleteClick = (e) => {
    dispatch(deleteFcNews({ id: currentId, newsId: newsCurrentId }))
      .then((res) => {
        navigate("/fc/" + currentId);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    if (!ready) {
      getItem();
    }
  }, [ready]);

  if (ready) {
    return (
      <>
        {alertModal && (
          <InfoWindow
            setModal={setAlertModal}
            message={alertMessage}
          ></InfoWindow>
        )}
        <h1>Створення блогу</h1>

        <div className="menuFunctionLine">
          <div className="actionButtonsBlock">
            <button
              className="secondaryButtonIconTextDefault"
              onClick={() => navigate(`/fc/${currentId}`)}
            >
              <svg>
                <use href="/images/icon24ArrowLeft.svg#icon24" />
              </svg>
              Назад
            </button>

            {form.statusId === "384fc74a-254e-47d7-9fcc-cd608d7e5f9d" && (
              <button
                className="primaryButtonIconTextDefault"
                accessKey="publish"
                onClick={changeStatus}
                disabled={isEmpty}
              >
                <svg>
                  <use href="/images/icon24Rocket.svg#icon24" />
                </svg>
                {"Опублікувати"}
              </button>
            )}
            {(form.statusId === "28e09d0e-bf38-4c6f-a787-3ec7e222f227" || (form.statusId ==='62e465ac-dc6b-4243-a7b6-d217e6aef2fb' && currentUser.roleId==='d379ecaa-fee7-48a4-84df-a176f47820e6')) && (
              <button
                className="primaryButtonIconTextDefault"
                accessKey="to-draft"
                onClick={changeStatus}
              >
                <svg>
                  <use href="/images/icon24Rocket.svg#icon24" />
                </svg>
                {"Зняти з публікації та редагувати"}
              </button>
            )}
            {form.statusId === "28e09d0e-bf38-4c6f-a787-3ec7e222f227" && (
              <button
                accessKey="deactivate"
                className="secondaryButtonIconTextDefault"
                onClick={changeStatus}
              >
                <svg accessKey="deactivate">
                  <use
                    accessKey="deactivate"
                    href="/images/icon24Trash.svg#icon24"
                  />
                </svg>
                Деактивувати
              </button>
            )}
            {form.statusId === "384fc74a-254e-47d7-9fcc-cd608d7e5f9d" && (
              <button
                className="secondaryButtonIconTextDefault"
                onClick={deleteClick}
              >
                <svg>
                  <use href="/images/icon24Trash.svg#icon24" />
                </svg>
                Видалити
              </button>
            )}
          </div>
        </div>
        <div className="pageContentHeaderDivider"></div>

        <div className="videoCard2Columns">
          <div className="videoCardCoverContainer">
            <h3>Назва та обкладинка</h3>
            <div
              style={{ width: "100%" }}
              className={
                form.statusId !== "384fc74a-254e-47d7-9fcc-cd608d7e5f9d"
                  ? "apInputText apDisabled"
                  : `${
                      form.title
                        ? "apInputText apFilled"
                        : isEmpty
                        ? "apInputText apError"
                        : "apInputText"
                    }`
              }
            >
              <label htmlFor="title">Назва *</label>
              <input
                id="title"
                type="text"
                disabled={
                  form.statusId !== "384fc74a-254e-47d7-9fcc-cd608d7e5f9d"
                }
                placeholder={'Максимальна кількість символів 255'}
                maxLength={'255'}
                value={form.title}
                onChange={changeHandler}
                onBlur={saveEdit}
                className={isEmpty ? "apInputError" : null}
              />
              {isEmpty && <span>Заповніть поле </span>}
            </div>

            <PhotoUploud  disabled={
            form.statusId !== "384fc74a-254e-47d7-9fcc-cd608d7e5f9d"
          } setForm={setForm} form={form}></PhotoUploud>
          </div>
        <div className="groundPropertiesHeaderNews">
          <h2>Контент</h2>
        <Editor
          id="body"
          url={"content-pages"}
          onChange={customFunc}
          maxLength={2047}
          onBlur={customSave}
          value={form.body}
          disabled={
            form.statusId !== "384fc74a-254e-47d7-9fcc-cd608d7e5f9d"
          }
        />
         {errorCus && <span className="apError">Не більше 2000 символів</span>}
        </div>
        </div>
        
        {/* <button
          style={{ width: "fit-content" }}
          className="secondaryButtonTextDefault"
          onClick={customSave}
        >
          Зберегти текст блогу
        </button> */}
      </>
    );
  } else {
    return <Loader />;
  }
};

export default FCNewsEditPage;
