import axios from "axios";
import authHeader from "./auth-header";

import constants from '../../constants/constant.json';


const getNews = (data)=>{
     
    if(!data.offset){
        data.offset=0;
    } if (!data.limit){
        data.limit=10
    }
    let filter=''
     
    if(data.filter&& Object.keys(data.filter).length ){
         
    for (const [key, value] of Object.entries(data.filter)) {
         
        value.forEach(el => {
             
            filter+=`&filters[${key}]=${el}`
        });
    }
    } 
    if(data.sort&& Object.keys(data.sort).length ){
        for (const [key, value] of Object.entries(data.sort)) {
                filter+=`&sort[${key}]=${value}`
        }
    }    
     
    return axios.get(constants.URL + `api/v1/news?offset=${data.offset}&limit=${data.limit}${filter}`,{headers: authHeader()}).then(response=>{
         
        return response.data
    }).catch((e)=>{
         
        console.log(e);
      });;
}

const addNews = (data)=>{
    
    if(data){
        return axios.post(constants.URL + `api/v1/news/${data.id}/${data.type}`,{},{ headers: authHeader()})
        .then((response) => { 
            return response.data})
        .catch((e)=>{
              
            console.log(e);
        });
    }else{
        return axios.post(constants.URL + `api/v1/news`,{},{ headers: authHeader()})
        .then((response) => { 
            return response.data})
        .catch((e)=>{
              
            console.log(e);
        });
    }
    
}

const editNews = (data)=>{
    return axios.put(constants.URL + 'api/v1/news/'+data.id,{...data},{ headers: authHeader()})
    .then((response) => { 
        return response.data})
    .catch((e)=>{
          
        console.log(e);
    });
}

const getNewsItem = (data)=>{
    return axios.get(constants.URL +'api/v1/news/'+data.id,{headers: authHeader()}).then(response=>{
        
        return response.data
    }).catch((e)=>{
        console.log(e);
      });;
}

const deleteNews = (data)=>{
    return axios.delete(constants.URL + 'api/v1/news/' + data.id,{ headers: authHeader()})
    .then((response) => { 
        
        return response.data})
    .catch((e)=>{
        console.log(e);
    });
}


export default{
    getNews,
    addNews,
    editNews,
    getNewsItem,
    deleteNews
}