import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Icon } from "../../../components/Icon";
import { Reorder, useDragControls } from "framer-motion";
import { editEvents } from "../../../redux/actions/events";
import Map from "./Map";
import QrComponent from "./QrComponent";
import Loader from "../../../components/Loader/Loader";
import { useCallback } from "react";

export const Table = (props) => {
  const dispatch = useDispatch();
  const [arrAll, setArr] = useState([]);
  const [closePoints, setClosePoints] = useState([]);
  const [ready, setReady] = useState(false);
  const handleClick = () => {
    alert("click");
  };
  const [selected, setSelected] = useState('');


  const checkFunc=(arr)=>{
    let newArr =[];
    arr.forEach(el=>{
      newArr[el.pointIndex]=el;
    })
    setArr(newArr)
    setReady(true)
  }

  const updateArr = (ar) => {
    setArr(ar);
    editEventItem(ar);
  };

  const editEventItem = (arr) => {
    let form = props.form;
    let formSend = props.formSend;
    form.routePoints = arr;
    formSend.routePoints = arr;
    if(formSend?.routePoints?.length>0 &&  formSend?.routePoints[0].location?.length>0 &&  formSend?.routePoints[0].location[0]){
      props.getCurrentLocation(formSend.routePoints[0].location[0],formSend.routePoints[0].location[1]);
    }
    dispatch(editEvents(formSend)).then((res) => {});
  };
  const reorderClick =useCallback((e) => {
    e.map((el, index) => {
      el.pointIndex = index;
    });
    if(e!=arrAll){
      console.log(arrAll)
      console.log(e)
      setArr(e);
      editEventItem(e);
    }
  },[arrAll]);

  const changeType =(index)=>{
    let arr=arrAll;
    let type=  arr[index].type
    if( type=='0'){
      arr[index].type=1;
    }else{
      arr[index].type=0;
    }
    setArr(arr);
    editEventItem(arr);
    checkFunc(arr);
  }

  const heandleRemovePoint = (index) => {
    const newArr = [...arrAll.slice(0, index), ...arrAll.slice(index + 1)];
    let newArr2=[]
    newArr.map((el,index)=>{
      let data=el;
      data.pointIndex=index
      newArr2.push(data)
    })

    setArr(newArr2);
    editEventItem(newArr2);

  };


  const checkFunction = useMemo(() => {
    let checkArr=[]
    for (let i = 0; i < arrAll.length - 1; i++) {
      for (let j = i + 1; j < arrAll.length; j++) {
        const R = 6371000;
        const lat1 = arrAll[i]?.location[0];
        const lon1 = arrAll[i]?.location[1];
        const lat2 = arrAll[j]?.location[0];
        const lon2 = arrAll[j]?.location[1];
        const dLat = (lat2 - lat1) * Math.PI / 180; 
        const dLon = (lon2 - lon1) * Math.PI / 180; 
        const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
                  Math.cos(lat1 * Math.PI / 180) * Math.cos(lat2 * Math.PI / 180) *
                  Math.sin(dLon / 2) * Math.sin(dLon / 2);
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        const distance = R * c;
        if (distance <= 30) {
          if(checkArr[arrAll[i]?.pointIndex]?.length>0 ){
            checkArr[arrAll[i]?.pointIndex]+= ', ' + (arrAll[j].pointIndex + 1) +'';
          }else{
            checkArr[arrAll[i]?.pointIndex]=('Точка ' + (arrAll[i].pointIndex+1) + ' знаходиться занадто близько до точки ' + (arrAll[j].pointIndex + 1)) +'';
          }
          if(checkArr[arrAll[j]?.pointIndex]?.length>0 ){
            checkArr[arrAll[j]?.pointIndex]+= ', ' + (arrAll[i].pointIndex + 1)  +'';
          }else{
            checkArr[arrAll[j]?.pointIndex]=('Точка ' + (arrAll[j].pointIndex+1) + ' знаходиться занадто близько до точки ' + (arrAll[i].pointIndex + 1))  +'';
          }
        } 
      }
    }
    if (checkArr.length > 0) {
      console.log(checkArr);
      setClosePoints(checkArr);
      // alert(`Близкие точки: ${checkArr.join(", ")}`);
    }else{
      setClosePoints([]);
    }
  }, [arrAll]);


  const clickEvent=(e)=>{
    if(e==selected){
      setSelected('');
    }else{
      setSelected(e);
    }
  }


  // useEffect(() => {
  //   if (ready) {
  //     checkFunction();
  //   }
  // }, [arrAll, ready]);

  useEffect(() => {
    if(!ready){
      checkFunc(props.formSend?.routePoints);
    }
  }, [props.formSend?.routePoints,arrAll,ready]);

if(ready){
  return (
    <div className="apItineraryAdmin">
      <Map updateArr={updateArr} selected={selected} el={props.form} arrAll={arrAll}></Map>
      <div className="apAdmitPointList apTableList">
        <div className="headline">Таблиця маршрутів</div>

        <div className="apFcEventsAdmitPoints">
          {arrAll?.length > 0 && (
            <Reorder.Group axis="y" values={arrAll} onReorder={reorderClick}>
              {arrAll.map((item, index) =>{
                
                return(
                <Item
                  key={index+item.location[0]+item.location[1]}
                  index={index}
                  isLast={arrAll.length===index+1?true:false}
                  item={item}
                  closePoints={closePoints}
                  value={item}
                  type={item?.type}
                  onDelete={() => heandleRemovePoint(index)}
                  handleClick={handleClick}
                  changeType={() =>changeType(index)}
                  onClickEvent={clickEvent}
                />
              )})}
            </Reorder.Group>
          )}
        </div>
      </div>
     
    </div>
  );
}
else{
  return(
    <Loader/>
  )
}
};

const Item = (props) => {
  const controls = useDragControls();
  const [isChecked, setIsChecked] = useState(false);
  const [qrModal,setQrModal]=useState(false);
  const {events}=useSelector(state=>state)
  const qrClick =()=>{
    setQrModal(true);
  }


  return (
    <Reorder.Item
      className="apFcAdmitPoint"
      value={props.value}
      dragListener={false}
      dragControls={controls}
    >
      <div className="apFcAdmitPointItem" >
        <div className="info" onClick={()=>props.onClickEvent(props.index+'')}>
          {/* <Icon
            type={
              props.status === "finished" || isChecked ? "circleCheck" : "frame"
            }
            onClick={() => setIsChecked((s) => !s)}
            className={`resultIcon ${props.status} ${
              (isChecked && `finished`) || ""
            }`}
          /> */}
          <div className="point">
            {props.index + 1} <span>Точка</span>
          </div>
          <div className="date">
            {( props.item?.location[0]) + ", " + (props.item?.location[1])}
            <span>Координати</span>
          </div>
        </div>
        <div className="controls">
        {events.statusId!=='032fd5ba-f634-469b-3c30-77a1gh63a918' && props.index!=0 && !props.isLast && <Icon className={`cross ${props.item.type=='0'?'active':''}`} type={"pin"} onClick={props.changeType} />}
        {events.statusId==='032fd5ba-f634-469b-3c30-77a1gh63a918' &&props.item.type=='0'&& <Icon className={"cross"} type={"qr"} onClick={qrClick} />}
        {events.statusId!=='032fd5ba-f634-469b-3c30-77a1gh63a918' && <> <Icon className={"cross"} type={"cross"}  onClick={props.onDelete} />
          <button className="dragButton" onPointerDown={(e) => controls.start(e)}>
            <Icon type={"list"} />
          </button>
          </>}
        </div>
      </div>
      
      {props.closePoints.length>0&& props.closePoints[props.item?.pointIndex]?.length>0&&<span>{props.closePoints[props.item?.pointIndex]}</span>}
      {qrModal && <QrComponent index={props.item.pointIndex}  link={props.item.qrCode} setModalOpen={setQrModal}></QrComponent>}
    </Reorder.Item>
  );
};
