import {
  LOG_SUCCESS,
  LOG_FAIL
  } from "../actions/types";


  
  const initialState = [];

  export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
      case LOG_SUCCESS:
        return { ...payload };
      case  LOG_FAIL:
        return  state;
  
      default:
        return state;
    }
  }