import {
    CREATEFC_SUCCESS,
    CREATEFC_FAIL,
    GETFCLIST_SUCCESS,
    GETFCLIST_FAIL,
    GETFCITEM_SUCCESS,
    GETFCITEM_FAIL,
    EDITFC_SUCCESS,
    EDITFC_FAIL,
    DELETEFC_SUCCESS,
    DELETEFC_FAIL,
    CREATEQR_SUCCESS,
    CREATEQR_FAIL
} from "../actions/types";

const initialState = [];

export default function fcReducer(FC = initialState,action){
    const {list,item,type} = action;

    switch(type) {
        case  CREATEFC_SUCCESS:
            return {currentFC:item}
        case CREATEFC_FAIL:
            return FC;
        case GETFCLIST_SUCCESS:
                return {...list};
        case GETFCLIST_FAIL:
                return FC;
        case GETFCITEM_SUCCESS:
            return {...item};
        case GETFCITEM_FAIL:
            return FC;
        case EDITFC_SUCCESS:
            return FC;
        case EDITFC_FAIL:
            return FC;
        case DELETEFC_SUCCESS:
            return FC;
        case CREATEQR_SUCCESS:
                return FC;
        case CREATEQR_FAIL:
                return FC;
        case DELETEFC_FAIL:
            return FC;
        default:
            return FC;
    }

}