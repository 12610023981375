import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { editPark, getParkItem } from "../../../redux/actions/parks";
import PhotoUploud from "./PhotoUpload/PhotoUploud";
import ModalFiles from "./ModalFiles/ModalFiles";
import ModalEquipment from "./ModalEquipment/ModalEquipment";
import Loader from "../../../components/Loader/Loader";

const InfoPage = () => {
  const [selected, setSelected] = useState("");
  const [parkForm, setParkForm] = useState({
    title: "",
    description: "",
    ownerDetails: "",
    workHours: "",
  });
  const [ready, setReady] = useState(false);
  const navigate = useNavigate();
  const [coordinates, setCoordinates] = useState({});
  const { fullList } = useSelector((state) => state);
  const { listId } = useSelector((state) => state);
  const dispatch = useDispatch();
  const currentId = useParams().id;
  const [equipment, setEquipment] = useState(false);
  const [files, setFiles] = useState(false);
  const changeHandler = (e) => {
    setParkForm({ ...parkForm, [e.target.id]: e.target.value });
  };
  const changeCoordinates = (e) => {
    setCoordinates({ ...coordinates, [e.target.id]: e.target.value });
  };
  const changeRegion = (e) => {
    let val = { ...coordinates };
    if (val.administrative_area_level_1 === e.target.id) {
    } else {
      val.administrative_area_level_1 = e.target.id;
      val.administrative_area_level_2 = "";
    }
    setCoordinates({ ...val });
    setSelected("");
    editItem(val);
  };
  const setSelectCoordClick = (e) => {
    setCoordinates({
      ...coordinates,
      ["administrative_area_level_2"]: e.target.id,
    });
    coordinates.administrative_area_level_2 = e.target.id;
    setSelected("");
    editItem(coordinates);
  };
  const changeLocation = (e) => {
    let arr = e.target.value.split(",");
    arr[0] = +arr[0];
    arr[1] = +arr[1];
    setCoordinates({ ...coordinates, ["location"]: arr });
  };
  function getKeyByValue(object, value) {
    let val = Object.keys(object).find((key) => object[key] === value);
    return val;
  }

  const editItem = useCallback(
    (coord) => {
      let result = {};

      if (
        coord &&
        !coord.administrative_area_level_1 &&
        !coord.administrative_area_level_2 &&
        !coord.target
      ) {
        result = { ...coord };
      } else {
        result = { ...parkForm };
      }

      if (
        coord &&
        (coord.administrative_area_level_1 || coord.administrative_area_level_2)
      ) {
        result.street = coord.route;
        result.city = coord.locality;
        result.regionId = "";
        result.districtId = "";
        if (
          coord.administrative_area_level_1 &&
          coord.administrative_area_level_1.split(" ")[1] === "область"
        ) {
          let str = coord.administrative_area_level_1.split(" ");
          let val = str[1].slice(0, 3);
          val += ".";
          let newStr = str[0] + " " + val;
          result.regionId = getKeyByValue(listId.list, newStr);
        } else if (coord.administrative_area_level_1) {
          result.regionId = getKeyByValue(
            listId.list,
            coord.administrative_area_level_1
          );
        }

        if (coord.location && coord.location[0]) {
          result.location = coord.location;
        }
        if (!result.regionId) {
          result.regionId = getKeyByValue(
            listId.list,
            coord.administrative_area_level_2
          );
          result.districtId = "";
        } else if (!result.districtId) {
          result.districtId = getKeyByValue(
            listId.list,
            coord.administrative_area_level_2
          );
        }

        // else if(result.regionId){
        //   result.districtId= getKeyByValue(listId.list, coord.administrative_area_level_2)
        // }
      } else {
        result.street = coordinates.route;
        result.city = coordinates.locality;
        if (
          coordinates.administrative_area_level_1 &&
          coordinates.administrative_area_level_1.split(" ")[1] === "область"
        ) {
          let str = coordinates.administrative_area_level_1.split(" ");
          let val = str[1].slice(0, 3);
          val += ".";
          let newStr = str[0] + " " + val;
          result.regionId = getKeyByValue(listId.list, newStr);
        } else if (coordinates.administrative_area_level_1) {
          result.regionId = getKeyByValue(
            listId.list,
            coordinates.administrative_area_level_1
          );
        }
        if (coordinates.location && coordinates.location[0]) {
          result.location = coordinates.location;
        }
        if (!result.regionId) {
          result.regionId = getKeyByValue(
            listId.list,
            coordinates.administrative_area_level_2
          );
          result.districtId = "";
        } else if (!result.districtId) {
          result.districtId = getKeyByValue(
            listId.list,
            coordinates.administrative_area_level_2
          );
        }

        // else if(result.regionId){
        //   result.districtId= getKeyByValue(listId.list, coordinates.administrative_area_level_2)
        // }
      }
      if(result.districtId===result.regionId){
        result.districtId=''
      }
      result.id = currentId;

      if(result.districtId==='undefined'){
        result.districtId='';
      }
      
      dispatch(editPark(result))
        .then((res) => {
          setParkForm(result);
          getItem("type");
          // getItem()
        })
        .catch((e) => {
          console.log(e);
        });
    },
    [coordinates, parkForm]
  );

  const getItem = (type) => {
    if (!type) {
      setReady(false);
    }
    dispatch(getParkItem({ id: currentId }))
      .then((res) => {
        let data = { ...coordinates };
        data.location = res.location;
        data.administrative_area_level_1 = listId.list[res.regionId];
        data.administrative_area_level_2 = listId.list[res.districtId];
        // data.administrative_area_level_3;
        data.locality = res.city;
        data.route = res.street;
        setCoordinates(data);
        setParkForm(res);
        setReady(true);
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const setSelectClick = (e) => {
    setParkForm({ ...parkForm, [e.target.accessKey]: e.target.id });
    parkForm[e.target.accessKey] = e.target.id;
    setSelected("");
    editItem(parkForm);
  };

  const openSelect = (e) => {
    if (e.target.id === selected) {
      setSelected("");
      editItem();
    } else {
      setSelected(e.target.id);
    }
  };

  const resetCoordinates = useCallback(() => {
    if (coordinates.location) {
      const pos = {
        lat: +coordinates.location[0].toFixed(7),
        lng: +coordinates.location[1].toFixed(7),
      };
      new window.google.maps.Geocoder()
        .geocode({ location: pos })
        .then((result) => {
          const { results } = result;
          let coord = { location: [pos.lat, pos.lng] };
          results[0].address_components.forEach((el) => {
            let field = el.types[0];
            coord[field] = el.short_name;
          });
          if (coord.route) {
            coord.route = coord.route + " " + coord.street_number;
          } else {
            coord.route = "";
          }
          setCoordinates({ ...coordinates, ...coord });
          editItem(coord);
          // sessionStorage.setItem('coordinates',JSON.stringify(pos))
        })
        .catch((e) => {
          alert("Geocode was not successful for the following reason: " + e);
        });
    }
    // let parse= JSON.parse(sessionStorage.getItem('coordinates'));
    //  setCoordinates(parse);
  }, [coordinates]);
  const getCurrentPos = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        const pos = {
          lat: +position.coords.latitude.toFixed(7),
          lng: +position.coords.longitude.toFixed(7),
        };
        new window.google.maps.Geocoder()
          .geocode({ location: pos })
          .then((result) => {
            const { results } = result;
            let coord = { location: [pos.lat, pos.lng] };
            results[0].address_components.forEach((el) => {
              let field = el.types[0];
              coord[field] = el.short_name;
            });
            if (coord.route) {
              coord.route += coord.street_number;
            } else {
              coord.route = "";
            }
            setCoordinates({ ...coordinates, ...coord });

            editItem(coord);
            // sessionStorage.setItem('coordinates',JSON.stringify(pos))
          })
          .catch((e) => {
            alert("Geocode was not successful for the following reason: " + e);
          });
        // geocode( {location:pos});
      });
    }
  };
  const chooseEquipment = () => {
    setEquipment(true);
  };

  const chooseFiles = () => {
    setFiles(true);
  };
  useEffect(() => {
    if (!ready) {
      getItem();
    }
  }, []);
  if (!ready || !fullList.OwnershipTypes || !listId.list) {
    return (
      <div style={{ position: "relative" }}>
        {" "}
        <div style={{ position: "absolute", top: "50%", left: "50%" }}>
          <Loader />
        </div>{" "}
      </div>
    );
  }

  if (ready && fullList.OwnershipTypes) {
    return (
      <>
        {equipment && (
          <ModalEquipment
            setModal={setEquipment}
            setForm={setParkForm}
            form={parkForm}
          ></ModalEquipment>
        )}
        {files && (
          <ModalFiles
            setModal={setFiles}
            setForm={setParkForm}
            form={parkForm}
          ></ModalFiles>
        )}
        <div className="groundPropertiesHeader">
          <h2>Обов'язкова інформація</h2>
          <span>(Необхідний мінімум для відправки на модерацію)</span>
        </div>
        <div className="groundPropertiesMainBlock">
          <div className="groundPropertiesAddressAndTrains">
            <div className="groundPropertiesAddressBlock">
              <div className="headerWithButtons">
                <div>
                  <h3>Адреса</h3>
                </div>
                <div>
                  {parkForm.statusId !==
                    "99ca4f35-663c-4e0a-b9ef-f70219aa8163" &&
                    parkForm.statusId !==
                      "036735ba-f634-469b-ac30-77a164e3a918" && (
                      <button
                        className="secondaryButtonIconDefault"
                        onClick={getCurrentPos}
                      >
                        <svg>
                          <use href="/images/icon24MyLocation.svg#icon24" />
                        </svg>
                      </button>
                    )}
                  <button
                    className="secondaryButtonIconDefault"
                    onClick={() => navigate(`/map/${currentId}`)}
                  >
                    <svg>
                      <use href="/images/icon24Map.svg#icon24" />
                    </svg>
                  </button>
                  {parkForm.statusId !==
                    "99ca4f35-663c-4e0a-b9ef-f70219aa8163" &&
                    parkForm.statusId !==
                      "036735ba-f634-469b-ac30-77a164e3a918" && (
                      <button
                        className="secondaryButtonIconDefault"
                        onClick={resetCoordinates}
                      >
                        <svg>
                          <use href="/images/icon24Refresh.svg#icon24" />
                        </svg>
                      </button>
                    )}
                </div>
              </div>
              <div
                className={
                  !(
                    parkForm.statusId !==
                      "99ca4f35-663c-4e0a-b9ef-f70219aa8163" &&
                    parkForm.statusId !== "036735ba-f634-469b-ac30-77a164e3a918"
                  )
                    ? "apInputText apDisabled"
                    : "apInputText"
                }
              >
                <label htmlFor="location">Координати *</label>
                <input
                  id="location"
                  type="text"
                  disabled={
                    !(
                      parkForm.statusId !==
                        "99ca4f35-663c-4e0a-b9ef-f70219aa8163" &&
                      parkForm.statusId !==
                        "036735ba-f634-469b-ac30-77a164e3a918"
                    )
                  }
                  value={coordinates.location}
                  onBlur={editItem}
                  onChange={changeLocation}
                />
              </div>
              {/* <div className="apInputText">
            <label htmlFor="administrative_area_level_1">Область *</label>
            <input id="administrative_area_level_1" type="text" value={parkForm.regionId?listId.list[parkForm.regionId]:''} onChange={changeRegion} />
          </div> */}

              <div
                className={
                  !(
                    parkForm.statusId !==
                      "99ca4f35-663c-4e0a-b9ef-f70219aa8163" &&
                    parkForm.statusId !== "036735ba-f634-469b-ac30-77a164e3a918"
                  )
                    ? "apDropDownList apDisabled"
                    : "apDropDownList "
                }
              >
                <label htmlFor="administrative_area_level_1">Регіон *</label>
                <div
                  id="administrative_area_level_1"
                  onClick={
                    parkForm.statusId !==
                      "99ca4f35-663c-4e0a-b9ef-f70219aa8163" &&
                    parkForm.statusId !== "036735ba-f634-469b-ac30-77a164e3a918"
                      ? openSelect
                      : ""
                  }
                >
                  <input
                    id="administrative_area_level_1"
                    disabled={
                      !(
                        parkForm.statusId !==
                          "99ca4f35-663c-4e0a-b9ef-f70219aa8163" &&
                        parkForm.statusId !==
                          "036735ba-f634-469b-ac30-77a164e3a918"
                      )
                    }
                    readOnly
                    value={
                      parkForm.regionId ? listId.list[parkForm.regionId] : ""
                    }
                    type="text"
                  />
                  <svg id="administrative_area_level_1">
                    <use
                      id="administrative_area_level_1"
                      href="/images/icon24DropDown.svg#icon24"
                    />
                  </svg>
                </div>
                {selected == "administrative_area_level_1" && (
                  <div className="apOptionsList">
                    {fullList.Regions.map((el, index) => {
                      return (
                        <div
                          key={index}
                          id={el.alterTitle}
                          accessKey="administrative_area_level_1"
                          onClick={changeRegion}
                        >
                          {el.title}
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>

              <div
                className={
                  !(
                    parkForm.statusId !==
                      "99ca4f35-663c-4e0a-b9ef-f70219aa8163" &&
                    parkForm.statusId !== "036735ba-f634-469b-ac30-77a164e3a918"
                  )
                    ? "apDropDownList apDisabled"
                    : "apDropDownList "
                }
              >
                <label htmlFor="administrative_area_level_2">Район</label>
                <div
                  id="administrative_area_level_2"
                  onClick={
                    parkForm.statusId !==
                      "99ca4f35-663c-4e0a-b9ef-f70219aa8163" &&
                    parkForm.statusId !== "036735ba-f634-469b-ac30-77a164e3a918"
                      ? openSelect
                      : ""
                  }
                >
                  <input
                    id="administrative_area_level_2"
                    disabled={
                      !(
                        parkForm.statusId !==
                          "99ca4f35-663c-4e0a-b9ef-f70219aa8163" &&
                        parkForm.statusId !==
                          "036735ba-f634-469b-ac30-77a164e3a918"
                      )
                    }
                    readOnly
                    value={
                      parkForm.districtId
                        ? listId.list[parkForm.districtId]
                        : ""
                    }
                    type="text"
                  />
                  <svg id="administrative_area_level_2">
                    <use
                      id="administrative_area_level_2"
                      href="/images/icon24DropDown.svg#icon24"
                    />
                  </svg>
                </div>
                {selected == "administrative_area_level_2" && (
                  <div className="apOptionsList">
                    {fullList.Districts.map((el) => {
                      if (el.regionId == parkForm.regionId) {
                        return (
                          <div
                            key={el.title}
                            id={el.title}
                            accessKey="administrative_area_level_2"
                            onClick={setSelectCoordClick}
                          >
                            {el.title}
                          </div>
                        );
                      }
                    })}
                  </div>
                )}
              </div>

              <div
                className={
                  !(
                    parkForm.statusId !==
                      "99ca4f35-663c-4e0a-b9ef-f70219aa8163" &&
                    parkForm.statusId !== "036735ba-f634-469b-ac30-77a164e3a918"
                  )
                    ? "apInputText apDisabled"
                    : "apInputText"
                }
              >
                <label htmlFor="locality">Місто *</label>
                <input
                  id="locality"
                  disabled={
                    !(
                      parkForm.statusId !==
                        "99ca4f35-663c-4e0a-b9ef-f70219aa8163" &&
                      parkForm.statusId !==
                        "036735ba-f634-469b-ac30-77a164e3a918"
                    )
                  }
                  type="text"
                  value={coordinates.locality ? coordinates.locality : ""}
                  onChange={changeCoordinates}
                  onBlur={editItem}
                />
              </div>
              <div
                className={
                  !(
                    parkForm.statusId !==
                      "99ca4f35-663c-4e0a-b9ef-f70219aa8163" &&
                    parkForm.statusId !== "036735ba-f634-469b-ac30-77a164e3a918"
                  )
                    ? "apInputText apDisabled"
                    : "apInputText"
                }
              >
                <label htmlFor="route">Вулиця</label>
                {
                  <input
                    id="route"
                    type="text"
                    disabled={
                      !(
                        parkForm.statusId !==
                          "99ca4f35-663c-4e0a-b9ef-f70219aa8163" &&
                        parkForm.statusId !==
                          "036735ba-f634-469b-ac30-77a164e3a918"
                      )
                    }
                    value={coordinates.route}
                    onChange={changeCoordinates}
                    onBlur={editItem}
                  />
                }
              </div>
            </div>
            <div className="groundPropertiesOwnerBlock">
              <div className="headerWithButtons">
                <div>
                  <h3>Власник</h3>
                </div>
                <div>
                  <button
                    className="secondaryButtonIconDefault"
                    onClick={chooseFiles}
                  >
                    <svg>
                      <use href="/images/icon24Download.svg#icon24" />
                    </svg>
                  </button>
                </div>
              </div>
              <div
                className={
                  !(
                    parkForm.statusId !==
                      "99ca4f35-663c-4e0a-b9ef-f70219aa8163" &&
                    parkForm.statusId !== "036735ba-f634-469b-ac30-77a164e3a918"
                  )
                    ? "apDropDownList apDisabled"
                    : "apDropDownList "
                }
              >
                <label htmlFor="ownershipTypeId">Форма власності</label>
                <div
                  onClick={
                    parkForm.statusId !==
                      "99ca4f35-663c-4e0a-b9ef-f70219aa8163" &&
                    parkForm.statusId !==
                      "036735ba-f634-469b-ac30-77a164e3a918" &&
                    openSelect
                  }
                  id="ownershipTypeId"
                >
                  <input
                    id="ownershipTypeId"
                    readOnly
                    disabled={
                      !(
                        parkForm.statusId !==
                          "99ca4f35-663c-4e0a-b9ef-f70219aa8163" &&
                        parkForm.statusId !==
                          "036735ba-f634-469b-ac30-77a164e3a918"
                      )
                    }
                    value={
                      parkForm.ownershipTypeId
                        ? listId.list[parkForm.ownershipTypeId]
                        : ""
                    }
                    type="text"
                  />
                  <svg id="ownershipTypeId">
                    <use
                      id="ownershipTypeId"
                      href="/images/icon24DropDown.svg#icon24"
                    />
                  </svg>
                </div>
                {selected === "ownershipTypeId" && (
                  <div className="apOptionsList">
                    {fullList.OwnershipTypes.map((el) => {
                      return (
                        <div
                          id={el.id}
                          key={el.id}
                          accessKey="ownershipTypeId"
                          onClick={setSelectClick}
                        >
                          {el.title}
                        </div>
                      );
                    })}
                    {/* <div>Значение 1 стандартное</div>
                <div>Значение 2, длинный текст, который не помещается в одну строку</div>
                <div className="apActive">Значение 3, активное</div> */}
                  </div>
                )}
              </div>
              <div
                className={
                  !(
                    parkForm.statusId !==
                      "99ca4f35-663c-4e0a-b9ef-f70219aa8163" &&
                    parkForm.statusId !== "036735ba-f634-469b-ac30-77a164e3a918"
                  )
                    ? "apInputText apDisabled"
                    : "apInputText"
                }
              >
                <label htmlFor="ownerName">
                  Найменування юридичної особи *
                </label>
                <input
                  id="ownerName"
                  disabled={
                    !(
                      parkForm.statusId !==
                        "99ca4f35-663c-4e0a-b9ef-f70219aa8163" &&
                      parkForm.statusId !==
                        "036735ba-f634-469b-ac30-77a164e3a918"
                    )
                  }
                  type="text"
                  value={parkForm.ownerName}
                  onChange={changeHandler}
                  onBlur={editItem}
                />
              </div>
              <div
                className={
                  !(
                    parkForm.statusId !==
                      "99ca4f35-663c-4e0a-b9ef-f70219aa8163" &&
                    parkForm.statusId !== "036735ba-f634-469b-ac30-77a164e3a918"
                  )
                    ? "apInputText apDisabled"
                    : "apInputText"
                }
              >
                <label htmlFor="ownerPhone">Телефони юридичної особи *</label>
                <input
                  id="ownerPhone"
                  disabled={
                    !(
                      parkForm.statusId !==
                        "99ca4f35-663c-4e0a-b9ef-f70219aa8163" &&
                      parkForm.statusId !==
                        "036735ba-f634-469b-ac30-77a164e3a918"
                    )
                  }
                  type="text"
                  value={parkForm.ownerPhone}
                  onChange={changeHandler}
                  onBlur={editItem}
                />
              </div>
              <div
                className={
                  !(
                    parkForm.statusId !==
                      "99ca4f35-663c-4e0a-b9ef-f70219aa8163" &&
                    parkForm.statusId !== "036735ba-f634-469b-ac30-77a164e3a918"
                  )
                    ? "apTextarea apDisabled"
                    : "apTextarea "
                }
              >
                <label htmlFor="ownerDetails">Реквізити юридичної особи</label>
                <textarea
                  disabled={
                    !(
                      parkForm.statusId !==
                        "99ca4f35-663c-4e0a-b9ef-f70219aa8163" &&
                      parkForm.statusId !==
                        "036735ba-f634-469b-ac30-77a164e3a918"
                    )
                  }
                  id="ownerDetails"
                  maxLength="1000"
                  value={parkForm.ownerDetails}
                  onChange={changeHandler}
                  onBlur={editItem}
                />
                <div>
                  <span></span>
                  <span>{parkForm.ownerDetails.length}/1000</span>
                </div>
              </div>
            </div>
          </div>
          <PhotoUploud
            form={parkForm}
            noForm={parkForm}
            getItem={getItem}
            setForm={setParkForm}
          ></PhotoUploud>
        </div>
        <div className="groundPropertiesHeader">
          <h2>Додаткова інформація</h2>
          (Підвищує привабливість для користувачів)
        </div>
        <div className="groundPropertiesExtraBlock">
          <div className="groundPropertiesNameBlock">
            <h3>Назва і опис об’єкта </h3>
            <div
              className={
                !(
                  parkForm.statusId !==
                    "99ca4f35-663c-4e0a-b9ef-f70219aa8163" &&
                  parkForm.statusId !== "036735ba-f634-469b-ac30-77a164e3a918"
                )
                  ? "apInputText apDisabled"
                  : "apInputText"
              }
            >
              <label htmlFor="title">Повна назва об’єкта {parkForm.code}</label>
              <input
                disabled={
                  !(
                    parkForm.statusId !==
                      "99ca4f35-663c-4e0a-b9ef-f70219aa8163" &&
                    parkForm.statusId !== "036735ba-f634-469b-ac30-77a164e3a918"
                  )
                }
                id="title"
                type="text"
                placeholder={"Майданчик " + parkForm.code}
                value={parkForm.title}
                onChange={changeHandler}
                onBlur={editItem}
              />
            </div>
            {/*	<div className='apTextarea'>
              <label htmlFor='inputText10'>className="apTextarea"</label>
              <textarea id='inputText10'>Обычная textarea</textarea>
          </div>
          <div className='apTextarea apDisabled'>
              <label htmlFor='inputText11'>apTextarea</label>
              <textarea id='inputText11' disabled>Disabled textarea</textarea>

          </div>		*/}
            <div
              className={
                !(
                  parkForm.statusId !==
                    "99ca4f35-663c-4e0a-b9ef-f70219aa8163" &&
                  parkForm.statusId !== "036735ba-f634-469b-ac30-77a164e3a918"
                )
                  ? "apTextarea apDisabled"
                  : "apTextarea "
              }
            >
              <label htmlFor="description">Опис</label>
              <textarea
                disabled={
                  !(
                    parkForm.statusId !==
                      "99ca4f35-663c-4e0a-b9ef-f70219aa8163" &&
                    parkForm.statusId !== "036735ba-f634-469b-ac30-77a164e3a918"
                  )
                }
                id="description"
                maxLength="2000"
                value={parkForm.description}
                onChange={changeHandler}
                onBlur={editItem}
              />
              <div>
                <span></span>
                <span>{parkForm.description.length}/2000</span>
              </div>
            </div>
            <div
              className={
                !(
                  parkForm.statusId !==
                    "99ca4f35-663c-4e0a-b9ef-f70219aa8163" &&
                  parkForm.statusId !== "036735ba-f634-469b-ac30-77a164e3a918"
                )
                  ? "apTextarea apDisabled"
                  : "apTextarea "
              }
            >
              <label htmlFor="workHours">Режим роботи</label>
              <textarea
                disabled={
                  !(
                    parkForm.statusId !==
                      "99ca4f35-663c-4e0a-b9ef-f70219aa8163" &&
                    parkForm.statusId !== "036735ba-f634-469b-ac30-77a164e3a918"
                  )
                }
                id="workHours"
                maxLength="1000"
                value={parkForm.workHours}
                onChange={changeHandler}
                onBlur={editItem}
                className="apTextarea"
              />
              <div>
                <span></span>
                <span>{parkForm.workHours.length}/1000</span>
              </div>
            </div>
          </div>

          <div className="groundPropertiesOtherParamsCommonBlock">
            <div className="groundPropertiesOtherParamsLeftBlock">
              <div className="profilePersonalDataHeaderWithButton">
                <h3>Набір тренажерів</h3>
                {parkForm.statusId !== "99ca4f35-663c-4e0a-b9ef-f70219aa8163" &&
                  parkForm.statusId !==
                    "036735ba-f634-469b-ac30-77a164e3a918" && (
                    <button
                      className="secondaryButtonIconDefault"
                      onClick={chooseEquipment}
                    >
                      <svg>
                        <use href="/images/icon24Edit.svg#icon24"></use>
                      </svg>
                    </button>
                  )}
              </div>
              <p>
                {parkForm.fitnessEquipment &&
                parkForm.fitnessEquipment.length > 0
                  ? parkForm.fitnessEquipment.map((el, index) => {
                      if (listId.list[el])
                        return (
                          <span
                            key={index}
                            style={{ marginRight: "16px", display: "block" }}
                          >
                            {listId.list[el]};
                          </span>
                        );
                    })
                  : "Оберіть тренажери зі списку"}
              </p>
            </div>
            <div className="groundPropertiesOtherParamsRightBlock">
              <h3>Інші параметри</h3>
              <div
                className={
                  !(
                    parkForm.statusId !==
                      "99ca4f35-663c-4e0a-b9ef-f70219aa8163" &&
                    parkForm.statusId !== "036735ba-f634-469b-ac30-77a164e3a918"
                  )
                    ? "apInputText apDisabled"
                    : "apInputText"
                }
              >
                <label htmlFor="facebookUrl">Посилання на Facebook</label>
                <input
                  id="facebookUrl"
                  disabled={
                    !(
                      parkForm.statusId !==
                        "99ca4f35-663c-4e0a-b9ef-f70219aa8163" &&
                      parkForm.statusId !==
                        "036735ba-f634-469b-ac30-77a164e3a918"
                    )
                  }
                  type="text"
                  value={parkForm.facebookUrl}
                  onChange={
                    parkForm.statusId !==
                      "99ca4f35-663c-4e0a-b9ef-f70219aa8163" &&
                    parkForm.statusId !==
                      "036735ba-f634-469b-ac30-77a164e3a918" &&
                    changeHandler
                  }
                  onBlur={
                    parkForm.statusId !==
                      "99ca4f35-663c-4e0a-b9ef-f70219aa8163" &&
                    parkForm.statusId !==
                      "036735ba-f634-469b-ac30-77a164e3a918" &&
                    editItem
                  }
                />
              </div>
              <div
                className={
                  !(
                    parkForm.statusId !==
                      "99ca4f35-663c-4e0a-b9ef-f70219aa8163" &&
                    parkForm.statusId !== "036735ba-f634-469b-ac30-77a164e3a918"
                  )
                    ? "apDropDownList apDisabled"
                    : "apDropDownList "
                }
              >
                <label htmlFor="inputText18">Освітлення</label>
                <div
                  id="hasLighting"
                  onClick={
                    parkForm.statusId !==
                      "99ca4f35-663c-4e0a-b9ef-f70219aa8163" &&
                    parkForm.statusId !==
                      "036735ba-f634-469b-ac30-77a164e3a918" &&
                    openSelect
                  }
                >
                  {
                    <input
                      readOnly
                      disabled={
                        !(
                          parkForm.statusId !==
                            "99ca4f35-663c-4e0a-b9ef-f70219aa8163" &&
                          parkForm.statusId !==
                            "036735ba-f634-469b-ac30-77a164e3a918"
                        )
                      }
                      id="hasLighting"
                      value={
                        parkForm.hasLighting == 1 ? "Присутнє" : "Відсутнє"
                      }
                      type="text"
                    />
                  }
                  <svg id="hasLighting">
                    <use
                      id="hasLighting"
                      href="/images/icon24DropDown.svg#icon24"
                    />
                  </svg>
                </div>
                {selected === "hasLighting" && (
                  <div className="apOptionsList">
                    <div
                      id="1"
                      accessKey="hasLighting"
                      onClick={setSelectClick}
                    >
                      Присутнє
                    </div>
                    <div
                      id="0"
                      accessKey="hasLighting"
                      onClick={setSelectClick}
                    >
                      Відсутнє
                    </div>
                  </div>
                )}
              </div>
              <div
                className={
                  !(
                    parkForm.statusId !==
                      "99ca4f35-663c-4e0a-b9ef-f70219aa8163" &&
                    parkForm.statusId !== "036735ba-f634-469b-ac30-77a164e3a918"
                  )
                    ? "apDropDownList apDisabled"
                    : "apDropDownList "
                }
              >
                <label htmlFor="conditionId">Технічний стан</label>
                <div
                  id="conditionId"
                  onClick={
                    parkForm.statusId !==
                      "99ca4f35-663c-4e0a-b9ef-f70219aa8163" &&
                    parkForm.statusId !==
                      "036735ba-f634-469b-ac30-77a164e3a918" &&
                    openSelect
                  }
                >
                  <input
                    id="conditionId"
                    disabled={
                      !(
                        parkForm.statusId !==
                          "99ca4f35-663c-4e0a-b9ef-f70219aa8163" &&
                        parkForm.statusId !==
                          "036735ba-f634-469b-ac30-77a164e3a918"
                      )
                    }
                    readOnly
                    value={
                      parkForm.conditionId
                        ? listId.list[parkForm.conditionId]
                        : ""
                    }
                    type="text"
                  />
                  <svg id="conditionId">
                    <use
                      id="conditionId"
                      href="/images/icon24DropDown.svg#icon24"
                    />
                  </svg>
                </div>
                {selected == "conditionId" && (
                  <div className="apOptionsList">
                    {fullList.SportsgroundConditions.map((el, index) => {
                      return (
                        <div
                          key={index}
                          id={el.id}
                          accessKey="conditionId"
                          onClick={setSelectClick}
                        >
                          {el.title}
                        </div>
                      );
                    })}
                    {/* <div>Значение 1 стандартное</div>
                <div>Значение 2, длинный текст, который не помещается в одну строку</div>
                <div className="apActive">Значение 3, активное</div> */}
                  </div>
                )}
              </div>
              <div
                className={
                  !(
                    parkForm.statusId !==
                      "99ca4f35-663c-4e0a-b9ef-f70219aa8163" &&
                    parkForm.statusId !== "036735ba-f634-469b-ac30-77a164e3a918"
                  )
                    ? "apDropDownList apDisabled"
                    : "apDropDownList "
                }
              >
                <label htmlFor="typeId">Вид майданчика</label>
                <div
                  id="typeId"
                  onClick={
                    parkForm.statusId !==
                      "99ca4f35-663c-4e0a-b9ef-f70219aa8163" &&
                    parkForm.statusId !==
                      "036735ba-f634-469b-ac30-77a164e3a918" &&
                    openSelect
                  }
                >
                  <input
                    id="typeId"
                    readOnly
                    disabled={
                      !(
                        parkForm.statusId !==
                          "99ca4f35-663c-4e0a-b9ef-f70219aa8163" &&
                        parkForm.statusId !==
                          "036735ba-f634-469b-ac30-77a164e3a918"
                      )
                    }
                    value={parkForm.typeId ? listId.list[parkForm.typeId] : ""}
                    type="text"
                  />
                  <svg id="typeId">
                    <use id="typeId" href="/images/icon24DropDown.svg#icon24" />
                  </svg>
                </div>
                {selected == "typeId" && (
                  <div className="apOptionsList">
                    {fullList.SportsgroundTypes.map((el) => {
                      return (
                        <div
                          id={el.id}
                          key={el.id}
                          accessKey="typeId"
                          onClick={setSelectClick}
                        >
                          {el.title}
                        </div>
                      );
                    })}
                    {/* <div>Значение 1 стандартное</div>
                <div>Значение 2, длинный текст, который не помещается в одну строку</div>
                <div className="apActive">Значение 3, активное</div> */}
                  </div>
                )}
              </div>
              <div
                className={
                  !(
                    parkForm.statusId !==
                      "99ca4f35-663c-4e0a-b9ef-f70219aa8163" &&
                    parkForm.statusId !== "036735ba-f634-469b-ac30-77a164e3a918"
                  )
                    ? "apDropDownList apDisabled"
                    : "apDropDownList "
                }
              >
                <label htmlFor="inputText21">Місткість</label>
                <div
                  id="capacityId"
                  onClick={
                    parkForm.statusId !==
                      "99ca4f35-663c-4e0a-b9ef-f70219aa8163" &&
                    parkForm.statusId !==
                      "036735ba-f634-469b-ac30-77a164e3a918" &&
                    openSelect
                  }
                >
                  <input
                    id="capacityId"
                    readOnly
                    disabled={
                      !(
                        parkForm.statusId !==
                          "99ca4f35-663c-4e0a-b9ef-f70219aa8163" &&
                        parkForm.statusId !==
                          "036735ba-f634-469b-ac30-77a164e3a918"
                      )
                    }
                    value={
                      parkForm.capacityId
                        ? listId.list[parkForm.capacityId]
                        : ""
                    }
                    type="text"
                  />
                  <svg id="capacityId">
                    <use
                      id="capacityId"
                      href="/images/icon24DropDown.svg#icon24"
                    />
                  </svg>
                </div>
                {selected == "capacityId" && (
                  <div className="apOptionsList">
                    {fullList.SportsgroundCapacities.map((el) => {
                      return (
                        <div
                          id={el.id}
                          key={el.id}
                          accessKey="capacityId"
                          onClick={setSelectClick}
                        >
                          {el.title}
                        </div>
                      );
                    })}
                    {/* <div>Значение 1 стандартное</div>
                <div>Значение 2, длинный текст, который не помещается в одну строку</div>
                <div className="apActive">Значение 3, активное</div> */}
                  </div>
                )}
              </div>
              <div
                className={
                  !(
                    parkForm.statusId !==
                      "99ca4f35-663c-4e0a-b9ef-f70219aa8163" &&
                    parkForm.statusId !== "036735ba-f634-469b-ac30-77a164e3a918"
                  )
                    ? "apDropDownList apDisabled"
                    : "apDropDownList "
                }
              >
                <label htmlFor="accessTypeId">Тип доступу</label>
                <div
                  onClick={
                    parkForm.statusId !==
                      "99ca4f35-663c-4e0a-b9ef-f70219aa8163" &&
                    parkForm.statusId !==
                      "036735ba-f634-469b-ac30-77a164e3a918" &&
                    openSelect
                  }
                  id="accessTypeId"
                >
                  <input
                    id="accessTypeId"
                    readOnly
                    disabled={
                      !(
                        parkForm.statusId !==
                          "99ca4f35-663c-4e0a-b9ef-f70219aa8163" &&
                        parkForm.statusId !==
                          "036735ba-f634-469b-ac30-77a164e3a918"
                      )
                    }
                    value={
                      parkForm.accessTypeId
                        ? listId.list[parkForm.accessTypeId]
                        : ""
                    }
                    type="text"
                  />
                  <svg id="accessTypeId">
                    <use
                      id="accessTypeId"
                      href="/images/icon24DropDown.svg#icon24"
                    />
                  </svg>
                </div>
                {selected == "accessTypeId" && (
                  <div className="apOptionsList">
                    {fullList.SportsgroundAccessTypes.map((el) => {
                      return (
                        <div
                          id={el.id}
                          key={el.id}
                          accessKey="accessTypeId"
                          onClick={setSelectClick}
                        >
                          {el.title}
                        </div>
                      );
                    })}
                    {/* <div>Значение 1 стандартное</div>
                <div>Значение 2, длинный текст, который не помещается в одну строку</div>
                <div className="apActive">Значение 3, активное</div> */}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
};

export default InfoPage;
