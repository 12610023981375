import {
  ROUTE_SUCCESS,
  ROUTE_FAIL,
  EDITROUTE_SUCCESS,
  EDITROUTE_FAIL,
  DELETEROUTE_SUCCESS,
  DELETEROUTE_FAIL,
  GETROUTEITEM_SUCCESS,
  GETROUTEITEM_FAIL,
  } from "../actions/types";


  
  const initialState = [];

  export default function (state = initialState, action) {
    const { type,item, payload } = action;

    switch (type) {
      case ROUTE_SUCCESS:
        return { ...payload };
      case  ROUTE_FAIL:
        return  state;
      case GETROUTEITEM_SUCCESS:
          return {...item};
      case GETROUTEITEM_FAIL:
          return state;
      case EDITROUTE_SUCCESS:
          return {...item};
      case EDITROUTE_FAIL:
          return state;
      case DELETEROUTE_SUCCESS:
          return state;
      case DELETEROUTE_FAIL:
          return state;
  
      default:
        return state;
    }
  }