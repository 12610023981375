import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import Loader from "../../components/Loader/Loader";
import { getInfoCurrent } from "../../redux/actions/info";

const InfoUserPage = () => {
  const id = useParams().id;
  const [ready, setReady] = useState(false);
  const dispatch = useDispatch();
  const { currentUser } = useSelector((state) => state.currentUser);
  const [openMobile, setOpenMobile] = useState(false);
  const [form, setForm] = useState({});
  const { fullList } = useSelector((state) => state);
  const navigate = useNavigate();
  const linkClick = (e) => {
    navigate(e.target.id);
    setReady(false);
  };

  const initFunct = () => {
    let data = {};
    data.id = id;
    dispatch(getInfoCurrent(data)).then((res) => {
      setForm(res);
      setReady(true);
    });
  };

  useEffect(() => {
    if (!ready) {
      initFunct();
    }
  }, [ready]);

  if (ready) {
    return (
      <div className="apIinnerPage">
        <div className="headerWithButtonsHeaderBlock">
          <h1>
            База знань{" "}
            <div className="headerSideText">
              Дізнайтесь про усі можливості порталу, щоб максимально
              використовувати потенціал сайту
            </div>
          </h1>
        </div>
        <div className="pageContentHeaderDivider">&nbsp;</div>
        <div className="infoPage">
          <div className={openMobile?"infoPageSidebar mobCheck":"infoPageSidebar"} onClick={()=>{setOpenMobile(!openMobile)}}>
          <>{fullList.InfoPageSections &&
              fullList.InfoPageSections.map((el, index) => {
                if(index!=2 || (currentUser?.roleId!=='a2c99acd-0014-4fb3-8274-ad6a842f50ac' && currentUser?.roleId!=='62fe0318-c64a-490c-859d-9d313eacbf41' && currentUser))
                {
                return (
                  <div
                    key={index}
                    className={el.id === id ? "active":''}
                    id={`/infolist/${el.id}`}
                    onClick={linkClick}
                  >
                    {el.title.substr(3,)}
                  </div>
                );}
              })}
               <div className="infoSide" onClick={()=>{setOpenMobile(!openMobile)}}>
               X
              </div>
              </>
          </div>
          <div className="infoPageContent" style={{ maxWidth: "80vw" }}>
            <h1 style={{ marginTop: "20px" }}>{form?.title}</h1>
            <div dangerouslySetInnerHTML={{ __html: form.body }}></div>
          </div>
        </div>
        {/* {JSON.parse(form.body)} */}
      </div>
    );
  } else {
    <Loader />;
  }
};

export default InfoUserPage;
