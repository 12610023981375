import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { getAllEvents, getEventsCurrentItem } from "../../redux/actions/events";
import Loader from "../../components/Loader/Loader";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";

import "leaflet/dist/leaflet.css";
import L from "leaflet";

const markerIconMy = new L.Icon({
  iconUrl: require("../../assets/images/myLocation.png"),
  iconSize: [24, 24],
  iconAnchor: [12, 13], //[left/right, top/bottom]
  popupAnchor: [0, 0], //[left/right, top/bottom]
});

const SportEventUserCardItemPage = () => {
  const [ready, setReady] = useState(false);
  const currentId = useParams().id;
  const dispatch = useDispatch();
  const { listId } = useSelector((state) => state);
  const { currentEvent: events } = useSelector((state) => state);
  const { events: eventsList } = useSelector((state) => state);
  const navigate = useNavigate();
  useEffect(() => {
    if (!ready) {
      getItem();
    }
  }, []);
  const openInNewTab = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  const getItem = () => {
    let data = {};
    let yourDate = new Date();
    const offset = yourDate.getTimezoneOffset();
    yourDate = new Date(yourDate.getTime() - offset * 60 * 1000);
    let specDate = yourDate.toISOString().split("T")[0];
    data.filter = { startsFrom: [specDate] };
    dispatch(getAllEvents(data)).then((res) => {});
    dispatch(getEventsCurrentItem({ id: currentId }))
      .then((res) => {
        setReady(true);
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const linkClick = (e) => {
    navigate(e.target.id);
  };

  return (
    <>
      <div className="headerWithButtonsHeaderBlock zahody">
        <h1>
          Заходи{" "}
          <div className="headerSideText">
            Обирайте захід, який ви хотіли би відвідати, плануйте свій час.
          </div>
        </h1>
      </div>
      <div className="pageContentHeaderDivider">&nbsp;</div>
      {ready ? (
        <div className="sportEvents">
          <div className="sportEventsBlock">
            <h2>{events.title}</h2>
            <div
              className="sportEventsBlockImage"
              style={{ backgroundImage: `URL(${events.imageUrl})` }}
            ></div>
            {events.sportsground !== null && (
              <div
                className="sportEventsCardTimeLocation"
                id={`/parkInfo/${events.sportsground?.id}`}
                onClick={linkClick}
              >
                {events.sportsground?.title}
              </div>
            )}

            <div className="sportEventsLine">
              <div className="sportEventsLineItem">
                <h3>Тип заходу</h3>
                <div>
                  {events.categories &&
                    events.categories.length > 0 &&
                    events.categories.map((el, index) => {
                      return (
                        <React.Fragment key={index}>
                          {listId.list[el]}
                          {index + 1 !== events.categories.length && ", "}
                        </React.Fragment>
                      );
                    })}
                </div>
              </div>
              <div className="sportEventsLineItem">
                <h3>Початок</h3>
                <div>
                  {new Date(
                    events.startsAt.replace(/-/g, "/")
                  ).toLocaleString()}
                </div>
              </div>
              <div className="sportEventsLineItem last">
                <h3>Закінчення</h3>
                <div>
                  {new Date(
                    events.finishesAt.replace(/-/g, "/")
                  ).toLocaleString()}
                </div>
              </div>
            </div>

            <div>
              <h3>Координатор</h3>
              <div className="sportEventsCoordinator">
                <div>
                  {events.createdBy.photo ? (
                    <div
                      className="userValueImage"
                      style={{
                        backgroundImage: `url(${events.createdBy.photo})`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                      }}
                    ></div>
                  ) : (
                    <div className="userValueImage">
                      {events.createdBy.nickname[0].toUpperCase() +
                        events.createdBy.nickname[1].toUpperCase()}
                    </div>
                  )}
                </div>
                <div>
                  <div>{events.createdBy.lastName && events.createdBy.firstName?<>{events.createdBy.firstName} {events.createdBy.lastName}</> :events.createdBy.nickname}</div>
                 { events.createdBy.email && <div>Email:{events.createdBy.email}</div>}
                </div>
              </div>
            </div>
            <div className="apIinnerPage">
              <h3>Опис заходу</h3>
              <div
                style={{ marginTop: "20px" }}
                dangerouslySetInnerHTML={{
                  __html: events.fullDescription,
                }}
              ></div>
            </div>
            <div>
              <h3>Локація</h3>
              <div style={{ marginBottom: "20px" }}>
                {events.sportsground !== null && (
                  <MapContainer
                    style={{ height: 600 }}
                    center={[
                      events.sportsground?.location[0],
                      events.sportsground?.location[1],
                    ]}
                    zoom={15}
                    scrollWheelZoom={true}
                  >
                    <TileLayer
                      attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                      url="https://openstreetmap.pp.ua/hot/{z}/{x}/{y}.png"
                      />
                    <Marker
                      position={[
                        events.sportsground?.location[0],
                        events.sportsground?.location[1],
                      ]}
                      icon={markerIconMy}
                    >
                      <Popup>
                        <div
                          className="apgmtt2"
                          style={{
                            backgroundImage: `URL(${events.sportsground?.photo})`,
                          }}
                        ></div>

                        <a
                          onClick={() =>
                            navigator(`/parkInfo/${events.sportsground?.id}`)
                          }
                          className="apgmtt3"
                        >
                          {events.sportsground?.title.length > 0
                            ? events.sportsground?.title
                            : events.sportsground?.code}
                        </a>

                        <div className="buttonControl">
                          <button
                            className="primaryButtonTextDefault"
                            onClick={() =>
                              openInNewTab(
                                `https://www.google.com.ua/maps/place/${events.sportsground?.location[0]}+${events.sportsground?.location[1]}`
                              )
                            }
                          >
                            Маршрут
                          </button>
                          <button
                            className="secondaryButtonTextDefault"
                            onClick={() =>
                              navigator(`/parkInfo/${events.sportsground?.id}`)
                            }
                          >
                            Інформація
                          </button>
                        </div>

                        <div className="apgmtt4">
                          <div className="apPublished">Опубліковано</div>
                        </div>
                      </Popup>
                    </Marker>
                  </MapContainer>
                )}
              </div>
            </div>
          </div>
          <div className="sportEventsListBlock">
            <h2>Незабаром</h2>

            {ready &&
              eventsList.items &&
              eventsList.items.length > 0 &&
              eventsList.items.map((el, index) => {
                return (
                  <React.Fragment key={index}>
                    <div className="sportEventsListBlockCard">
                      <div
                        className="sportEventsListBlockCardImage"
                        style={{ backgroundImage: `URL(${el.imageUrl})` }}
                      ></div>
                      <h5>{el.title}</h5>
                      <div>{el.startsAt}</div>
                      <div>
                        {el.categories &&
                          el.categories.length > 0 &&
                          el.categories.map((elem, index) => {
                            return (
                              <React.Fragment key={index}>
                                {listId.list[elem]}
                                {index + 1 !== el.categories.length && ", "}
                              </React.Fragment>
                            );
                          })}
                      </div>
                      {events.sportsground !== null && (
                        <div
                          className="sportEventsCardTimeLocation"
                          id={`/parkInfo/${el.sportsground?.id}`}
                          onClick={linkClick}
                        >
                          {events.sportsground?.title}
                        </div>
                      )}
                    </div>
                  </React.Fragment>
                );
              })}
          </div>
        </div>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default SportEventUserCardItemPage;
