import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import Loader from "../../components/Loader/Loader";
import Tile from "../../components/Tile/";
import { getNews, getNewsCurrentItem } from "../../redux/actions/news";

import useFilterArray from "../../hooks/filterArray";
import getDate from "../../helpers/getDate";

const NewsUserPage = () => {
  const [ready, setReady] = useState(false);
  const [form, setForm] = useState({});
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const currentId = useParams().id;
  const { news } = useSelector((state) => state);
  const { filteredData } = useFilterArray(news, { id: currentId });

  const getNewsList = (page = 1, filter) => {
    let data = {};
    if (filter) {
      data.filter = { ...filter };
    } else {
      if (localStorage.getItem("newsFilter")) {
        data.filter = { ...JSON.parse(localStorage.getItem("newsFilter")) };
      } else {
        data.filter = {};
      }
    }
    if (localStorage.getItem("newsFilter")) {
      data.sort = { ...JSON.parse(localStorage.getItem("newsFilter")) };
    } else {
      data.sort = { updatedAt: "asc" };
    }
    data.offset = (page - 1) * 9;
    data.limit = 9;
    dispatch(getNews(data)).then((res) => {
      setReady(true);
    });
  };

  const getItem = () => {
    dispatch(getNewsCurrentItem({ id: currentId }))
      .then((res) => {
        setForm({ ...res });
        getNewsList();
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const linkClick = (e) => {
    navigate(e.target.id);
  };
  useEffect(() => {
    if (!ready) {
      getItem();
    }
  }, [ready]);

  return (
    <div className="apIinnerPage">
      <div className="headerWithButtonsHeaderBlock dateOfPublish">
        <h1>
          Блог{" "}
          <div className="headerSideText">
            Актуальні та минулі заходи, інформація про оновлення, анонси та
            звіти, та ще багато цікавого та корисного
          </div>
        </h1>
        <div style={{alignSelf:'center'}}>Опубліковано:&nbsp;
          {form&& getDate(form.publishedAt,"dd/mm/yyyy hh:mm",'.')}</div>
      </div>

      <div className="pageContentHeaderDivider">&nbsp;</div>
      {ready ? (
        <div style={{ marginTop: "20px" }}>
          <div>
            <img loading="lazy" src={form.imageUrl} width="100%" alt="" />
          </div>
          <h2 style={{ marginTop: "20px", marginBottom: "10px" }}>
            {form.title}
          </h2>
          <div
            style={{ marginTop: "20px" }}
            dangerouslySetInnerHTML={{ __html: form.body }}
          ></div>
          <button className="startsButton" id="/newspage" onClick={linkClick}>
            <svg>
              <use href="/images/icon24Info.svg#icon24" />
            </svg>
            Читати більше записів блогу
          </button>
          <h2>Інші записи блогу активних парків</h2>
          <div style={{ marginTop: "20px" }} className="newsList">
            {filteredData.map(({ imageUrl, title, id,clubId }, index) => (
              <Tile
                img={imageUrl}
                title={title}
                to={clubId?`/fc/${clubId}/news/${id}`:`/newspg/${id}`}
                key={index}
              />
            ))}
          </div>
        </div>
      ) : (
        <Loader></Loader>
      )}
    </div>
  );
};

export default NewsUserPage;
