import React from "react";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { getAllEvents } from "../../redux/actions/events";
import Filter from "./Filter/Filter";
import Select from "../../components/Select/";
import getDate from "../../helpers/getDate";

const sortOtions = [
  { id: "title", value: "За абеткою" },
  { id: "createdAt", value: "За датою cтворення" },
  { id: "startsAt", value: "За датою початку" },
  { id: "updatedAt", value: "За датою редагування" },
];

const SportEventsUserListPage = () => {
  const [ready, setReady] = useState(false);
  const dispatch = useDispatch();
  const { events } = useSelector((state) => state);
  const navigate = useNavigate();
  const [sortData, setSortData] = useState("startsAt");
  const [sortName, setSortName] = useState("За датою початку");
  const [sortValue, setSortValue] = useState("desc");
  const [filter, setFilter] = useState(false);
  const [filterArr, setFilterArr] = useState({});
  const [searchOpen, setSearchOpen] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [intervalId,setIntervalId] = useState(null);
  const resetFilter = () => {
    localStorage.removeItem("eventsFilter");
    setFilterArr({});
    getEventsList();
  };
  const sortClick = (e) => {
    setSortName(e.target.innerHTML);
    const currentIdIndex = sortOtions.findIndex(
      (x) => x.value === e.target.innerHTML
    );
    setSortData(sortOtions[currentIdIndex].id);
    let data = {};
    if (sortData === sortOtions[currentIdIndex].id && sortValue === "asc") {
      setSortValue("desc");
      data[sortOtions[currentIdIndex].id] = "desc";
    } else {
      data[sortOtions[currentIdIndex].id] = "asc";
      setSortValue("asc");
    }
    localStorage.setItem("eventsSort", JSON.stringify(data));
    getEventsList();
  };
  const searchChange = (e) => {
    let filter = {};
    setSearchValue(e.target.value);
    if (localStorage.getItem("eventsFilter")) {
      filter = JSON.parse(localStorage.getItem("eventsFilter"));
    }
    filter.all = [];


        clearTimeout(intervalId);
    const newIntervalId = setTimeout(() =>{
      // Call your search function with the updated query value
    
      if (e.target.value.length > 2) {
        filter.all[0] = e.target.value;
        localStorage.setItem("eventsFilter", JSON.stringify(filter));
        getEventsList(1, filter);
      } else {
        localStorage.setItem("eventsFilter", JSON.stringify(filter));

        getEventsList(1, filter);
      }
    }, 1000)
    setIntervalId(newIntervalId);  ;

    
  };

  const getEventsList = (page = 1, filter) => {
    let data = {};
    let yourDate = new Date();
    const offset = yourDate.getTimezoneOffset();
    yourDate = new Date(yourDate.getTime() - offset * 60 * 1000);
    let specDate = yourDate.toISOString().split("T")[0];

    if (filter) {
      data.filter = { ...filter };
    } else {
      if (localStorage.getItem("eventsFilter")) {
        data.filter = { ...JSON.parse(localStorage.getItem("eventsFilter")) };
      } else {
        data.filter = { startsFrom: [specDate] };
      }
    }

    if (localStorage.getItem("eventsSort")) {
      data.sort = { ...JSON.parse(localStorage.getItem("eventsSort")) };
    } else {
      data.sort = { startsAt: "asc" };
    }
    if (
      (data.filter && !data.filter.startsFrom) ||
      data.filter.startsFrom.length == 0
    ) {
      data.filter = { startsFrom: [specDate] };
    }

    data.offset = (page - 1) * 10;
    data.limit = 10;

    dispatch(getAllEvents(data)).then((res) => {
      setReady(true);
    });
  };
  const linkClick = (e) => {
    navigate(e.target.id);
  };

  useEffect(() => {
    if (!ready) {
      getEventsList();
    }
  }, [ready]);

  return (
    <>
      {filter && (
        <Filter
          setModal={setFilter}
          getEventsList={getEventsList}
          setFilter={setFilterArr}
        ></Filter>
      )}
      <div className="headerWithButtonsCommonBlock">
        <div className="headerWithButtonsHeaderBlock">
          <h1>Заходи</h1>
        </div>
        <div className="headerWithButtonsButtonBlock">
          {searchOpen && (
            <div style={{ marginBottom: "4px" }} className="apInputText">
              <label htmlFor="search">Пошук</label>
              <input
                id="search"
                type="text"
                placeholder="Мінімум 3 символи"
                value={searchValue}
                onChange={searchChange}
              />
            </div>
          )}
          {/* <button
            className="secondaryButtonIconDefault"
            onClick={() => setSearchOpen(!searchOpen)}
          >
            <svg>
              <use href="/images/icon24Search.svg#icon24" />
            </svg>
          </button> */}
          <div className="apSwitch">
            <button className="secondaryButtonIconActive">
              <svg>
                <use href="/images/icon24List.svg#icon24" />
              </svg>
            </button>
            <button
              className="secondaryButtonIconDefault"
              onClick={() => {
                navigate("/eventsCalendar");
              }}
            >
              <svg>
                <use href="/images/icon24Map.svg#icon24" />
              </svg>
            </button>
          </div>
        </div>
      </div>
      <div className="sortAndFilterBlock">
        <div className="menuFunctionLine">
          <div className="actionButtonsBlock">
            <div className="filterBlock">
              <button
                className="secondaryButtonIconTextDefault"
                onClick={() => setFilter(true)}
              >
                <svg>
                  <use href="/images/icon24Filter.svg#icon24" />
                </svg>
                Фільтр
              </button>
              <span>Знайдено {events.total} заходів:</span>
              {filterArr &&
                Object.keys(filterArr).length > 0 &&
                Object.keys(filterArr).map((el) => {
                  return (
                    filterArr[el].length > 0 &&
                    filterArr[el].map((elem, index) => {
                      return (
                        <div className="apChips" key={index}>
                          {el === "createdFrom" && "Створено з"}
                          {el === "createdTo" && "Створено по"}
                          {el === "startsFrom" && "Розпочато з"}
                          {el === "startsTo" && "Розпочато по"}
                          {el === "finishesFrom" && "Закінчено з"}
                          {el === "finishesTo" && "Закінчено по"} {elem}
                          {/* <svg><use href="/images/icon24CancelSmall.svg#icon24" /></svg> */}
                        </div>
                      );
                    })
                  );
                })}
              {filterArr && Object.keys(filterArr).length > 0 && (
                <button
                  onClick={resetFilter}
                  className="secondarySmallRoundedButtonTextDefault"
                >
                  Скинути
                </button>
              )}
            </div>
          </div>
        </div>
        <div className="sortBlock">
          <Select
            label={"Сортування:"}
            value={sortName}
            options={sortOtions}
            onSet={sortClick}
          />
        </div>
      </div>

      <div className="pageContentHeaderDivider">&nbsp;</div>
      {ready && events.items?.length > 0
        ? events.items.map((el, index) => {
            return (
              <React.Fragment key={index}>
                <div className="sportEventsCard">
                  <h4 id={`/fc-events/0/${el.id}/about`} onClick={linkClick}>
                    {el.title}
                  </h4>
                  <div
                    id={`/fc-events/0/${el.id}/about`}
                    onClick={linkClick}
                    className="sportEventsCardTime"
                  >
                    <div className="sportEventsCardTimeTime">
                      {getDate(el.startsAt, "hh:mm", ":") + ' - '+ getDate(el.finishesAt, "hh:mm", ":")}
                      <br />
                      <span>Час проведення</span>
                    </div>
                    <div
                      className="sportEventsCardTimeLocation"
                      id={`/parkInfo/${el.sportsground.id}`}
                      onClick={linkClick}
                    >
                      {el.sportsground.title}
                    </div>
                  </div>
                  <div className="sportEventsCardBody">
                    <div
                      className="sportEventsCardBodyImage"
                      style={{ backgroundImage: `URL(${el.imageUrl|| "https://ap.sportforall.gov.ua/api/v1/uploads/b34604xc-02qb-46bb-9e91-fc3ffrb92w41"})` }}
                    ></div>
                    <div className="sportEventsCardBodyText">
                      {el.shortDescription}
                    </div>
                  </div>
                  <button
                    style={{ float: "right" }}
                    className="primaryButtonTextDefault"
                    id={`/fc-events/0/${el.id}/about`}
                    onClick={linkClick}
                  >
                    Детальніше
                  </button>

                  <div className="sportEventsCardDate">
                    {getDate(el.startsAt,'dd/mm/yyyy',' ')
                      .split(" ")
                      .map((elem, index) => {
                        return (
                          <span key={index}>
                            {elem}
                            <br />
                          </span>
                        );
                      })}
                  </div>
                </div>
              </React.Fragment>
            );
          })
        : "Немає запланованих заходів"}
    </>
  );
};

export default SportEventsUserListPage;
