import {
    GETUSERSHEAD_SUCCESS,
    GETUSERSHEAD_FAIL,
    GETUSERSMEMBERS_SUCCESS,
    GETUSERSMEMBERS_FAIL,
    GETUSERSAPPLYING_SUCCESS,
    GETUSERSAPPLYING_FAIL,
    CHANGEUSERS_SUCCESS,
    CHANGEUSERS_FAIL,
  } from "./types";

  import FcEventsUsersService from "../services/fc-events-user.service"

  export const getFcEventsUserHeads = (data)=> async(dispatch)=>{
    try{
        const res = await FcEventsUsersService.getUserHeads(data);
        dispatch({
          type: GETUSERSHEAD_SUCCESS,
          list: res,
        });
        
        return res
    }catch (error){
        
        dispatch({
            type: GETUSERSHEAD_FAIL,
            list: {error: error.response},
          });
          const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
          return error.response
    }
}


export const getFcEventsUserMembers = (data)=> async(dispatch)=>{
    try{
        const res = await FcEventsUsersService.getUserMembers(data);
        dispatch({
          type: GETUSERSMEMBERS_SUCCESS,
          list: res,
        });
        
        return res
    }catch (error){
        
        dispatch({
            type: GETUSERSMEMBERS_FAIL,
            list: {error: error.response},
          });
          const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
          return error.response
    }
}
export const getFcEventsUserParticipants = (data)=> async(dispatch)=>{
  try{
      const res = await FcEventsUsersService.getUserParticipants(data);
      dispatch({
        type: GETUSERSMEMBERS_SUCCESS,
        list: res,
      });
      
      return res
  }catch (error){
      
      dispatch({
          type: GETUSERSMEMBERS_FAIL,
          list: {error: error.response},
        });
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        return error.response
  }
}

export const getFcEventsUserApplying = (data)=> async(dispatch)=>{
    try{
        const res = await FcEventsUsersService.getUserApplying(data);
        dispatch({
          type: GETUSERSAPPLYING_SUCCESS,
          list: res,
        });
        
        return res
    }catch (error){
        
        dispatch({
            type: GETUSERSAPPLYING_FAIL,
            list: {error: error.response},
          });
          const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
          return error.response
    }
}

export const getEventCountUsers = (data)=> async (dispatch) => {
  try {
    const res = await FcEventsUsersService.getEventCountUsers(data);
    dispatch({
      type: CHANGEUSERS_SUCCESS,
      payload: data,
    });

    return res;
  } catch (err) {
      dispatch({
          type:   CHANGEUSERS_FAIL,
        });
    return err.response.data;
  }
};

export const changeFcEventsUserStatus = (data) => async (dispatch) => {
    try {
      const res = await FcEventsUsersService.changeUserStatus(data);
       

      dispatch({
        type: CHANGEUSERS_SUCCESS,
        payload: data,
      });
       

      return res;
    } catch (err) {
       
        dispatch({
            type:   CHANGEUSERS_FAIL,
          });
           

      return err.response.data;
    }
  };