import axios from "axios";
import authHeader from "./auth-header";

import constants from '../../constants/constant.json';


const getInfoPages = (data)=>{
     
    if(!data.offset){
        data.offset=0;
    } if (!data.limit){
        data.limit=100
    }
    let filter=''
     
    if(data.filter&& Object.keys(data.filter).length ){
         
    for (const [key, value] of Object.entries(data.filter)) {
         
        value.forEach(el => {
             
            filter+=`&filters[${key}]=${el}`
        });
    }
    } 
    if(data.sort&& Object.keys(data.sort).length ){
        for (const [key, value] of Object.entries(data.sort)) {
                filter+=`&sort[${key}]=${value}`
        }
    }else{
        filter+=`&sort[title]=asc`
    }  
     
    return axios.get(constants.URL + `api/v1/info-pages?offset=${data.offset}&limit=${data.limit}${filter}`,{headers: authHeader()}).then(response=>{
         
        return response.data
    }).catch((e)=>{
         
        console.log(e);
      });;
}

const addInfoPages = (data)=>{
    
    if(data){
        return axios.post(constants.URL + `api/v1/info-pages/${data.id}/${data.type}`,{},{ headers: authHeader()})
        .then((response) => { 
            return response.data})
        .catch((e)=>{
              
            console.log(e);
        });
    }else{
        return axios.post(constants.URL + `api/v1/info-pages`,{},{ headers: authHeader()})
        .then((response) => { 
            return response.data})
        .catch((e)=>{
              
            console.log(e);
        });
    }
    
}

const editInfoPages = (data)=>{
    return axios.put(constants.URL + 'api/v1/info-pages/'+data.id,{...data},{ headers: authHeader()})
    .then((response) => { 
        return response.data})
    .catch((e)=>{
          
        console.log(e);
    });
}

const getInfoPagesItem = (data)=>{
    return axios.get(constants.URL +'api/v1/info-pages/'+data.id,{headers: authHeader()}).then(response=>{
        
        return response.data
    }).catch((e)=>{
        console.log(e);
      });;
}

const deleteInfoPages = (data)=>{
    return axios.delete(constants.URL + 'api/v1/info-pages/' + data.id,{ headers: authHeader()})
    .then((response) => { 
        
        return response.data})
    .catch((e)=>{
        console.log(e);
    });
}


export default{
    getInfoPages,
    addInfoPages,
    editInfoPages,
    getInfoPagesItem,
    deleteInfoPages
}