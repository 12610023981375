import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { Icon } from "../../components/Icon";
import InfoWindow from "../../components/InfoWindow/InfoWindow";
import Loader from "../../components/Loader/Loader";
import getDate from "../../helpers/getDate";
import {
  editReports,
  getPdfReports,
  getReportsUser,
} from "../../redux/actions/reports";

const UsersPageReportCurrent = () => {
  const dispatch = useDispatch();
  // const { currentUser } = useSelector((state) => state.currentUser);
  const { listId } = useSelector((state) => state);
  const { fullList } = useSelector((state) => state);
  const [ready, setReady] = useState(false);
  const [openEditId, setOpenEditId] = useState("");
  const [openEditForm, setOpenEditForm] = useState({});
  const [selected, setSelected] = useState("");
  const [modal,setModal] =useState(false)
  const [clocks,setClocks] =useState(false)
  const [changeTimer,setChangeTimer] =useState(false);
  const [receive,setReceive] =useState(false);
  const currentId = useParams().id;
  const navigate = useNavigate();
  
  let date = new Date();
  date.setDate(1);
  date.setMonth(date.getMonth() - 1);
  let finDate = new Date();
  finDate.setDate(0);
  const storedData = localStorage.getItem("data");
  const [searchForm, setSearchForm] = useState(
    (storedData && JSON.parse(storedData))|| {
      filter: {
        startsFrom: [date.toISOString().split("T")[0]],
        startsTo: [finDate.toISOString().split("T")[0]],
      },
      offset: 0,
      limit: 100,
    }
  );
  const { reports } = useSelector((state) => state);

  const changeRegion = (e) => {
    setSelected("");
    setOpenEditForm({ ...openEditForm, [e.target.accessKey]: e.target.id });
  };

  const initFunc = () => {
    getDateClick();
  };
  
  const changeDateHandler = (date) => {
    let specDate = date.currentTarget.value;
    let arr = { ...searchForm };
    arr.filter[date.currentTarget.id] = [];
    arr.filter[date.currentTarget.id].push(specDate);
    setSearchForm(arr);
    localStorage.setItem("data", JSON.stringify(arr));
  };

  const changeHandler = (e) => {
    setOpenEditForm({ ...openEditForm, [e.target.name]: e.target.value });
  };

  const changeEditSave = () => {
    setChangeTimer(true)
    dispatch(editReports(openEditForm)).then((res) => {
      setOpenEditId("");
      setOpenEditForm({});
      setChangeTimer(false)
    });
  };

  const getPdfReport = () => {
      setClocks(true);
      setModal(false);

    let arr = [];
    if (reports.items.length > 0) {
      reports.items.map((el) => arr.push(el.id));
    }
    let data = {
      userId: currentId,
      startsFrom:
        (searchForm.filter?.startsFrom?.length > 0 &&
          searchForm.filter?.startsFrom[0]) ||
        "",
        startsTo:
        (searchForm.filter?.startsTo?.length > 0 &&
          searchForm.filter?.startsTo[0]) ||
        "",
      eventIds: arr,
    };

    dispatch(getPdfReports(data)).then((res) => {
        setClocks(false);
        if(res.success){
            window.open(res.link)
        }else{
            setModal(true);
        }
    });
  };

  const getDateClick = () => {
    setReady(false);
    let data = { ...searchForm };
    data.filter.userId = [currentId];
    dispatch(getReportsUser(data)).then((res) => {
      setReceive(true);
      setReady(true);

    });
  };

  useEffect(() => {
    if (!ready) {
      initFunc();
    }
  }, [ready]);

  return (
    <>
        {modal && (
          <InfoWindow
            setModal={setModal}
            message={"Не вдалось згенерувати звіт."}
          ></InfoWindow>
        )}
      <div className="headerWithButtonsCommonBlock">
        <div className="headerWithButtonsHeaderBlock">
          <h1>
            Звіт координатора:{reports && reports.user &&  " " + reports.user.lastName + " " + reports.user.firstName}
          </h1>
        </div>
      </div>
      <div className="menuFunctionLine forReport">
        <button
              className="secondaryButtonIconTextDefault"
              onClick={() =>
                navigate(`/usersReportsList`)
              }
            >
              <svg>
                <use href="/images/icon24ArrowLeft.svg#icon24" />
              </svg>
              Назад
        </button>
        <div className="apInputText">
          <label htmlFor="startsFrom">Дата початку звіту</label>
          <input
            id="startsFrom"
            onChange={changeDateHandler}
            type="date"
            max={getDate(new Date(),'yyyy/mm/dd','-')}
            value={
              searchForm.filter?.startsFrom?.length > 0
                ? searchForm.filter.startsFrom[0]
                : ""
            }
          />
        </div>
        <div className="apInputText">
          <label htmlFor="startsTo">Дата кінця звіту</label>
          <input
            id="startsTo"
            onChange={changeDateHandler}
            min={ searchForm?.filter?.startsFrom[0]}
            max={getDate(new Date(),'yyyy/mm/dd','-')}
            type="date"
            value={
              searchForm.filter?.startsTo?.length > 0
                ? searchForm.filter?.startsTo[0]
                : ""
            }
          />
        </div>
        <div className="actionButtonsBlock forReport">
          <button
            className="primaryButtonIconTextDefault"
            onClick={getDateClick}
          >
            <svg>
              <use href="/images/icon24Refresh.svg#icon24"/>
            </svg>
            Завантажити дані
          </button>
          {/* {clocks?
          <button className="primaryButtonIconTextDefault"><Icon type={"timer"}/>Іде завантаження PDF</button>
          :<button
            className="primaryButtonIconTextDefault"
            onClick={getPdfReport}
            disabled={!receive || reports?.items.length < 1 }
          >
            <svg>
              <use href="images/icon24Download.svg#icon24" />
            </svg>
            Створити PDF
          </button>} */}
        </div>
        {ready && reports?.items && reports?.items.length > 0 && <div>Всього заходів: {reports.total}
        <br />
          Всього користувачів: {reports.totalUsers}
        </div>}
      </div>
      <div className="pageContentHeaderDivider" />
      {ready && reports?.items && reports?.items.length > 0 && (
        <div className="dictAllRowsContainer">
          {reports.items.map((el) => {
            return (
              <div key={el.id}>
                {openEditId === el.id ? (
                  <div className="reportRowNormal inEdit">
                    <div className="reportRowValuesContainer">
                      <div className="reportRowValue">
                        <h4>
                          {getDate(el.startsAt, "dd/mm/yyyy hh:mm", ".")} -{" "}
                          {getDate(el.finishesAt, "hh:mm", ":")}
                        </h4>
                        <span>
                        {listId.list[el.typeId] || "Тип заходу не зазначено"} {el.eventRole || ''}
                        </span>
                      </div>
                      <div className="reportRowValue">
                        <h4 className="hoverHeader"  onClick={()=>navigate('/fc-events/0/'+el.id+'/about')}>{el.title}</h4>
                      </div>
                      <div className="reportRowValue">
                        <div className="apInputText">
                          <label htmlFor="reportMemberAmount">Учасників: ({el.memberAmount || 0})</label>
                          <input
                            type="number"
                            id="reportMemberAmount"
                            name="reportMemberAmount"
                            onChange={changeHandler}
                            value={openEditForm.reportMemberAmount || 0}
                          />
                        </div>
                      </div>

                      {/* <div className="reportRowValue">

                        <h4>Учасників: {el.reportMemberAmount} ({el.memberAmount || 0})</h4>
                      </div> */}
                      <div className="reportRowValue">
                        <div className="apInputText">
                          <label htmlFor="c486a8b3e43f13">Похилого віку</label>
                          <input
                            type="number"
                            id="c486a8b3e43f13"
                            name="reportElderlyAmount"
                            onChange={changeHandler}
                            value={openEditForm.reportElderlyAmount || 0}
                          />
                        </div>
                      </div>
                      <div className="reportRowValue">
                        <div className="apInputText">
                          <label htmlFor="c486a8b3e43f12">Дітей</label>
                          <input
                            type="number"
                            id="c486a8b3e43f12"
                            name="reportChildrenAmount"
                            onChange={changeHandler}
                            value={openEditForm.reportChildrenAmount || 0}
                          />
                        </div>
                      </div>
                      <div className="reportRowValue">
                        <div className="apInputText">
                          <label htmlFor="c486a8b3e43f14">ВПО</label>
                          <input
                            type="number"
                            id="c486a8b3e43f14"
                            name="reportIdpAmount"
                            onChange={changeHandler}
                            value={openEditForm.reportIdpAmount || 0}
                          />
                        </div>
                      </div>
                      <div className="reportRowValue">
                        <div className="apInputText">
                          <label htmlFor="c486a8b3e43f">
                            Жінок
                          </label>
                          <input
                            type="number"
                            id="c486a8b3e43f"
                            name="reportFemale"
                            onChange={changeHandler}
                            value={
                              openEditForm.reportFemale || 0
                            }
                          />
                        </div>
                      </div>
                      <div className="reportRowValue">
                        <div className="apInputText">
                          <label htmlFor="c486a8b3e43f17">
                            Чоловіків
                          </label>
                          <input
                            type="number"
                            id="c486a8b3e43f17"
                            name="reportMale"
                            onChange={changeHandler}
                            value={
                              openEditForm.reportMale || 0
                            }
                          />
                        </div>
                      </div>
                      <div className="reportRowValue">
                        <div className="apInputText">
                          <label htmlFor="c486a8b3e43f13">
                            Осіб з інвалідністю
                          </label>
                          <input
                            type="number"
                            id="c486a8b3e43f13"
                            name="reportInvalidAmount"
                            onChange={changeHandler}
                            value={
                              openEditForm.reportInvalidAmount || 0
                            }
                          />
                        </div>
                      </div>
                      <div className="reportRowValue">
                        <div className="apInputText">
                          <label htmlFor="c486a8b3e43f23">
                            Ветеранів
                          </label>
                          <input
                            type="number"
                            id="c486a8b3e43f23"
                            name="reportVeteranAmount"
                            onChange={changeHandler}
                            value={
                              openEditForm.reportVeteranAmount || 0
                            }
                          />
                        </div>
                      </div>
                      <div className="reportRowValue">
                        <div className="apDropDownList">
                          <label htmlFor="c486a8b3e43f2">Регіон України</label>
                          <div
                            id="c486a8b3e43f2"
                            onClick={() => {
                              if (selected !== "reportRegionId") {
                                setSelected("reportRegionId");
                              } else {
                                setSelected("");
                              }
                            }}
                          >
                            <input
                              readOnly=""
                              type="text"
                              name="reportRegionId"
                              value={
                                openEditForm.reportRegionId
                                  ? listId.list[openEditForm.reportRegionId]
                                  : ""
                              }
                            />
                            <svg id="typeId">
                              <use href="images/icon24DropDown.svg#icon24" />
                            </svg>
                          </div>
                          {selected == "reportRegionId" && (
                            <div className="apOptionsList">
                              {fullList.Regions.map((el, index) => {
                                return (
                                  <div
                                    key={index}
                                    id={el.id}
                                    accessKey="reportRegionId"
                                    onClick={changeRegion}
                                  >
                                    {el.title}
                                  </div>
                                );
                              })}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="reportRowValue">
                        <div className="apInputText">
                          <label htmlFor="c486a8b3e43f3">
                            Територіальна громада
                          </label>
                          <input
                            type="text"
                            id="c486a8b3e43f3"
                            name="reportTerritorialCommunity"
                            onChange={changeHandler}
                            value={
                              openEditForm.reportTerritorialCommunity || ""
                            }
                          />
                        </div>
                      </div>
                      <div className="reportRowValue">
                        <div className="apInputText">
                          <label htmlFor="c486a8b3e43f4">
                            Адреса або місце проведення
                          </label>
                          <input
                            type="text"
                            id="c486a8b3e43f4"
                            name="reportAddress"
                            onChange={changeHandler}
                            value={openEditForm.reportAddress || ""}
                          />
                        </div>
                      </div>
                      <div className="reportRowValue">
                        <div className="apInputText">
                          <label htmlFor="c486a8b3e43f15">
                            Залучено експертів
                          </label>
                          <input
                            type="number"
                            id="c486a8b3e43f15"
                            name="reportSportExpertAmount"
                            onChange={changeHandler}
                            value={openEditForm.reportSportExpertAmount || 0}
                          />
                        </div>
                      </div>
                      <div className="reportRowValue">
                        <div className="apInputText">
                          <label htmlFor="c486a8b3e43f16">
                            Залучено організацій
                          </label>
                          <input
                            type="number"
                            id="c486a8b3e43f16"
                            name="reportSportOrganizationAmount"
                            onChange={changeHandler}
                            value={
                              openEditForm.reportSportOrganizationAmount || 0
                            }
                          />
                        </div>
                      </div>
                      <div className="reportRowValue">
                        <div className="apInputText">
                          <label htmlFor="c486a8b3e43f17">
                          Посилання на Instagram
                          </label>
                          <input
                            type="text"
                            id="c486a8b3e43f17"
                            name="reportInstagramPublicationLink"
                            onChange={changeHandler}
                            value={
                              openEditForm.reportInstagramPublicationLink || ""
                            }
                          />
                        </div>
                      </div>
                      <div className="reportRowValue">
                        <div className="apInputText">
                          <label htmlFor="c486a8b3e43f17">
                            Посилання на сторінку публікації
                          </label>
                          <input
                            type="text"
                            id="c486a8b3e43f17"
                            name="reportExternalPublicationLink"
                            onChange={changeHandler}
                            value={
                              openEditForm.reportExternalPublicationLink || ""
                            }
                          />
                        </div>
                      </div>
                      <div className="reportRowValue">
                        <div className="apInputText">
                          <label htmlFor="c486a8b3e43f18">Коментар</label>
                          <input
                            type="text"
                            id="c486a8b3e43f18"
                            name="reportComment"
                            onChange={changeHandler}
                            value={openEditForm.reportComment || ""}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="reportRowButtonsContainer">
                      {!changeTimer ?<button
                        className="tetriaryButtonIconDefault"
                        name="exercise-categories"
                        onClick={changeEditSave}
                      >
                        <svg name="exercise-categories">
                          <use href="/images/icon24CheckCircle.svg#icon24" />
                        </svg>
                      </button>:
                      <button
                        className="tetriaryButtonIconDefault"
                        name="exercise-categories"
                       
                      >
                        <Icon type={"timer"}/>
                      </button>}
                      <button
                        className="tetriaryButtonIconDefault"
                        onClick={() => {
                          setOpenEditId("");
                          setOpenEditForm({});
                        }}
                      >
                        <svg>
                          <use href="/images/icon24CancelCircle.svg#icon24" />
                        </svg>
                      </button>
                    </div>
                  </div>
                ) : (
                  <div className="reportRowNormal">
                    <div className="reportRowValuesContainer">
                      <div className="reportRowValue">
                        <h4>
                          {" "}
                          {getDate(el.startsAt, "dd/mm/yyyy hh:mm", ".")} -{" "}
                          {getDate(el.finishesAt, "hh:mm", ":")}
                        </h4>
                        <span>
                        {listId.list[el.typeId] || "Тип заходу не зазначено"}, {el.eventRole}
                        </span>
                      </div>
                      <div className="reportRowValue">
                        <h4 className="hoverHeader" onClick={()=>navigate('/fc-events/0/'+el.id+'/about')}>{el.title}</h4>
                      </div>
                      <div className="reportRowValue">
                        <h4 style={{width:'150px'}}>Учасників: {el.reportMemberAmount || el.memberAmount}</h4>
                      </div>
                      <div className="reportRowValue">
                        <div className="apInputText">
                          <label htmlFor="c486a8b3e43f13">Похилого віку</label>
                          <input
                            disabled={true}
                            type="text"
                            id="c486a8b3e43f13"
                            name="elderlyAmount"
                            value={el.reportElderlyAmount || 0}
                          />
                        </div>
                      </div>
                      <div className="reportRowValue">
                        <div className="apInputText">
                          <label htmlFor="c486a8b3e43f12">Дітей</label>
                          <input
                            disabled={true}
                            type="text"
                            id="c486a8b3e43f12"
                            name="childrenAmount"
                            value={el.reportChildrenAmount || 0}
                          />
                        </div>
                      </div>
                      <div className="reportRowValue">
                        <div className="apInputText">
                          <label htmlFor="c486a8b3e43f14">ВПО</label>
                          <input
                            disabled={true}
                            type="text"
                            id="c486a8b3e43f14"
                            name="idpAmount"
                            value={el.reportIdpAmount || 0}
                          />
                        </div>
                      </div>
                      <div className="reportRowValue">
                        <div className="apInputText">
                          <label htmlFor="c486a8b3e43f13">
                          Жінок
                          </label>
                          <input
                            type="number"
                            id="c486a8b3e43f13"
                            name="reportFemale"
                            value={
                              el.reportFemale || 0
                            }
                          />
                        </div>
                      </div>
                      <div className="reportRowValue">
                        <div className="apInputText">
                          <label htmlFor="c486a8b3e43f13">
                          Чоловіків
                          </label>
                          <input
                            type="number"
                            id="c486a8b3e43f13"
                            name="reportMale"
                            value={
                              el.reportMale || 0
                            }
                          />
                        </div>
                      </div>
                      <div className="reportRowValue">
                        <div className="apInputText">
                          <label htmlFor="c486a8b3e43f13">
                            Осіб з інвалідністю
                          </label>
                          <input
                            type="number"
                            id="c486a8b3e43f13"
                            name="reportInvalidAmount"
                            value={
                              el.reportInvalidAmount || 0
                            }
                          />
                        </div>
                      </div>
                      <div className="reportRowValue">
                        <div className="apInputText">
                          <label htmlFor="c486a8b3e43f23">
                            Ветеранів
                          </label>
                          <input
                            type="number"
                            id="c486a8b3e43f23"
                            name="reportVeteranAmount"
                            value={
                              el.reportVeteranAmount || 0
                            }
                          />
                        </div>
                      </div>
                      
                      <div className="reportRowValue">
                        <div className="apDropDownList">
                          <label htmlFor="c486a8b3e43f2">Регіон України</label>
                          <div id="c486a8b3e43f2">
                            <input
                              disabled={true}
                              readOnly=""
                              type="text"
                              name="region"
                              value={
                                el.reportRegionId
                                  ? listId.list[el.reportRegionId]
                                  : ""
                              }
                            />
                            <svg id="typeId">
                              <use href="images/icon24DropDown.svg#icon24" />
                            </svg>
                          </div>
                        </div>
                      </div>
                      <div className="reportRowValue">
                        <div className="apInputText">
                          <label htmlFor="c486a8b3e43f3">
                            Територіальна громада
                          </label>
                          <input
                            disabled={true}
                            type="text"
                            id="c486a8b3e43f3"
                            name="community"
                            value={el.reportTerritorialCommunity || ""}
                          />
                        </div>
                      </div>
                      <div className="reportRowValue">
                        <div className="apInputText">
                          <label htmlFor="c486a8b3e43f4">
                            Адреса або місце проведення
                          </label>
                          <input
                            disabled={true}
                            type="text"
                            id="c486a8b3e43f4"
                            name="address"
                            value={el.reportAddress || ""}
                          />
                        </div>
                      </div>
                      <div className="reportRowValue">
                        <div className="apInputText">
                          <label htmlFor="c486a8b3e43f15">
                            Залучено експертів
                          </label>
                          <input
                            disabled={true}
                            type="text"
                            id="c486a8b3e43f15"
                            name="expertAmount"
                            value={el.reportSportExpertAmount || 0}
                          />
                        </div>
                      </div>
                      <div className="reportRowValue">
                        <div className="apInputText">
                          <label htmlFor="c486a8b3e43f16">
                            Залучено організацій
                          </label>
                          <input
                            disabled={true}
                            type="text"
                            id="c486a8b3e43f16"
                            name="organizationAmount"
                            value={el.reportSportOrganizationAmount || 0}
                          />
                        </div>
                      </div>
                      <div className="reportRowValue">
                        <div className="apInputText">
                          <label htmlFor="c486a8b3e43f17">
                            Посилання на Instagram
                          </label>
                          <input
                            disabled={true}
                            type="text"
                            id="c486a8b3e43f17"
                            name="reportInstagramPublicationLink"
                            value={el.reportInstagramPublicationLink || ""}
                          />
                        </div>
                      </div>
                      <div className="reportRowValue">
                        <div className="apInputText">
                          <label htmlFor="c486a8b3e43f17">
                            Посилання на сторінку публікації
                          </label>
                          <input
                            disabled={true}
                            type="text"
                            id="c486a8b3e43f17"
                            name="reportExternalPublicationLink"
                            value={el.reportExternalPublicationLink || ""}
                          />
                        </div>
                      </div>
                      <div className="reportRowValue">
                        <div className="apInputText">
                          <label htmlFor="c486a8b3e43f18">Коментар</label>
                          <input
                            disabled={true}
                            type="text"
                            id="c486a8b3e43f18"
                            name="comment"
                            value={el.reportComment || ""}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="reportRowButtonsContainer">
                      <button
                        className="tetriaryButtonIconDefault"
                        name="exercise-categories"
                        onClick={() => {
                          setOpenEditId(el.id);
                          setOpenEditForm(el);
                        }}
                      >
                        <svg name="exercise-categories">
                          <use href="/images/icon24Edit.svg#icon24" />
                        </svg>
                      </button>
                    </div>
                  </div>
               )}
                </div>
            );
          })}
        </div>
        
      )}
      {ready && reports?.items && reports?.items?.length == 0 && (
        <>
          <div className="apEmptyCategory">Заходів не знайдено</div>
        </>
      )}
      {!ready && <Loader />}
    </>
  );
};

export default UsersPageReportCurrent;


