import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import Loader from "../../../components/Loader/Loader";
import setPhoneMask from "../../../helpers/setPhoneMask";
import {
  addParkCoordinatorsApprove,
  addParkCoordinatorsId,
  deleteParkCoordinators,
  getParksCoordinators,
} from "../../../redux/actions/parkCoordinators";

const CoordinatorPage = () => {
  const navigate = useNavigate();
  const currentId = useParams().id;
  const dispatch = useDispatch();
  const [ready, setReady] = useState(false);
  const { currentUser } = useSelector((state) => state.currentUser);
  const { parks } = useSelector((state) => state);
  const { listId } = useSelector((state) => state);
  const { fullList } = useSelector((state) => state);
  const { parksCoordinators } = useSelector((state) => state);

  const initFunction = () => {
    let data = { id: currentId };
    dispatch(getParksCoordinators(data)).then((res) => {
      setReady(true);
    });
  };
  const addCoordinatorsHandler = () => {
    navigate(`/addCoordinator/${currentId}`);
  };
  const approveClick = (e) => {
    let data = { id: currentId, userId: e.target.id };
    dispatch(addParkCoordinatorsApprove(data)).then((res) => {
      initFunction();
    });
  };
  const deleteClick = (e) => {
    let data = { id: currentId, userId: e.target.id };
    dispatch(deleteParkCoordinators(data)).then((res) => {
      initFunction();
    });
  };
  const addCoordinators = (e) => {
    let data = { id: currentId, userId: currentUser.id };
    dispatch(addParkCoordinatorsId(data)).then((res) => {
      initFunction();
    });
  };
  useEffect(() => {
    if (!ready) {
      initFunction();
    }
  }, []);
  if (!ready || !fullList || !listId.list || !parksCoordinators.owner) {
    return (
      <div style={{ position: "relative" }}>
        {" "}
        <div style={{ position: "absolute", top: "50%", left: "50%" }}>
          <Loader />
        </div>{" "}
      </div>
    );
  }

  if (ready && parksCoordinators && parksCoordinators.owner) {
    return (
      <>
        <div className="groundPropertiesHeader">
          <h2>Відповідальний за майданчик</h2>
        </div>
        <div className="dictAllRowsContainer">
          <div className="coordinatorsRowNormal">
            {parksCoordinators.owner.photo ? (
              <div
                className="coordinatorValueImage"
                style={{
                  backgroundImage: `URL("${parksCoordinators.owner.photo}")`,
                }}
              ></div>
            ) : (
              <div className="coordinatorValueImage">
                {parksCoordinators.owner.lastName[0] &&
                  parksCoordinators.owner.lastName[0].toUpperCase() +
                    parksCoordinators.owner.firstName[0].toUpperCase()}
              </div>
            )}
            <div className="coordinatorAllSecPropsBlock">
              <div className="coordinatorValueName">
                <span>
                  {parksCoordinators.owner.lastName +
                    " " +
                    parksCoordinators.owner.firstName}
                </span>
                {listId.list[parksCoordinators.owner.roleId]}
              </div>
              <div className="coordinatorRegionAndPhoneAndMailBlock">
                <div className="coordinatorRegionBlock">
                  <div>{listId.list[parksCoordinators.owner.regionId]}</div>
                  <div>{listId.list[parksCoordinators.owner.districtId]}</div>
                  <div>{parksCoordinators.owner.city}</div>
                </div>
                <div className="coordinatorPhoneAndMailBlock">
                  {parksCoordinators?.owner?.phone && <div>{setPhoneMask(parksCoordinators?.owner?.phone)}</div>}
                  {parksCoordinators.owner?.email && <div>{parksCoordinators.owner?.email}</div>}
                </div>
              </div>
              <div className="coordinatorStatusIndicatorBlock">
                {parksCoordinators.owner.isDeleted && (
                  <div className="apDeleted">Видалений</div>
                )}
                {!parksCoordinators.owner.isActive && (
                  <div className="apDeactivated">Деактивований</div>
                )}
              </div>
            </div>
            <div className="coordinatorButtonsBlock">
              {/* <button className="tetriaryButtonIconDefault">
                <svg><use href="/images/icon24Edit.svg#icon24" /></svg>
              </button>
              <button className="tetriaryButtonIconDefault">
                <svg><use href="/images/icon24Edit.svg#icon24" /></svg>
              </button> */}
            </div>
          </div>
        </div>
        {parksCoordinators.active && (
          <>
            <div className="dictionaryHeaderWithButton">
              <div className="groundPropertiesHeader">
                <h2>Призначені координатори</h2>{" "}
                {parksCoordinators.active.length < 1 && (
                  <nobr>(Координатори ще не призначені)</nobr>
                )}
              </div>
              {(currentUser.roleId === "631db81f-fa07-42e2-b394-062c9be66b09" ||
                currentUser.roleId ===
                  "66d22cb4-2cf9-4aa7-806b-c50b50f27020") && (
                <button
                  className="secondaryButtonIconTextDefault"
                  onClick={addCoordinatorsHandler}
                >
                  <svg>
                    <use href="/images/icon24Plus.svg#icon24" />
                  </svg>
                  Додати координатора
                </button>
              )}
            </div>
            <div className="dictAllRowsContainer">
              {parksCoordinators.active.length < 1 ? (
                <div className="coordinatorsRowNormal apDisabled">
                  <div className="coordinatorValueImage">ІП</div>
                  <div className="coordinatorAllSecPropsBlock">
                    <div className="coordinatorValueName">
                      <span>Ім'я Прізвище</span>
                      Посада
                    </div>
                    <div className="coordinatorRegionAndPhoneAndMailBlock">
                      <div className="coordinatorRegionBlock">
                        <div>Регіон в роботі</div>
                      </div>
                      <div className="coordinatorPhoneAndMailBlock">
                        <div>+38 000 000-00-00</div>
                        <div>email@gmail.com</div>
                      </div>
                    </div>
                    <div className="coordinatorStatusIndicatorBlock">
                      {/* пустой div для индикатора особого статуса учётки */}
                    </div>
                  </div>
                  <div className="coordinatorButtonsBlock">
                    <button className="tetriaryButtonIconDefault">
                      <svg>
                        <use href="/images/icon24Cancel.svg#icon24" />
                      </svg>
                    </button>
                  </div>
                </div>
              ) : (
                parksCoordinators.active.map((el, index) => {
                  return (
                    <div key={index} className="coordinatorsRowNormal">
                      {el.photo ? (
                        <div
                          className="coordinatorValueImage"
                          style={{ backgroundImage: `URL("${el.photo}")` }}
                        ></div>
                      ) : (
                        <div className="coordinatorValueImage">
                          {el.lastName
                            ? el.lastName[0].toUpperCase() +
                              el.firstName[0].toUpperCase()
                            : ""}
                        </div>
                      )}
                      <div className="coordinatorAllSecPropsBlock">
                        <div className="coordinatorValueName">
                          <span>
                            {el.lastName && el.firstName
                              ? el.lastName + " " + el.firstName
                              : ""}
                          </span>
                          {listId.list[el.roleId]}
                        </div>
                        <div className="coordinatorRegionAndPhoneAndMailBlock">
                          <div className="coordinatorRegionBlock">
                            <div>{listId.list[el.regionId]}</div>
                            <div>{listId.list[el.districtId]}</div>
                            <div>{el.city}</div>
                          </div>
                          <div className="coordinatorPhoneAndMailBlock">
                            {el.phone && <div>{setPhoneMask(el.phone)}</div>}
                            {el.email && <div>{el.email}</div>}
                          </div>
                        </div>
                        <div className="coordinatorStatusIndicatorBlock">
                          {el.isDeleted && (
                            <div className="apDeleted">Видалений</div>
                          )}
                          {/* {el.isActive &&<div className="apDeactivated">Деактивований</div>} */}
                        </div>
                      </div>
                      {(currentUser.roleId ===
                        "631db81f-fa07-42e2-b394-062c9be66b09" ||
                        currentUser.roleId ===
                          "66d22cb4-2cf9-4aa7-806b-c50b50f27020" ||
                        (currentUser.id === el.userId &&
                          parksCoordinators.active.length > 1)) && (
                        <div className="coordinatorButtonsBlock">
                          <button
                            className="tetriaryButtonIconDefault"
                            id={el.userId}
                            onClick={deleteClick}
                          >
                            <svg id={el.userId}>
                              <use
                                id={el.userId}
                                href="/images/icon24Cancel.svg#icon24"
                              />
                            </svg>
                          </button>
                        </div>
                      )}
                    </div>
                  );
                })
              )}
            </div>
          </>
        )}
        <div className="dictionaryHeaderWithButton">
          <div className="groundPropertiesHeader">
            <h2>Заявки на призначення</h2>{" "}
            {parksCoordinators.pending.length > 0 ? "" : "(Заявок ще немає)"}
          </div>
          {currentUser.roleId === "09efbeb2-f45a-418d-89b0-b2a4c37f6122" &&
            !parksCoordinators.pending.find(
              (o) => o.userId === currentUser.id
            ) && (
              <button
                className="secondaryButtonIconTextDefault"
                onClick={addCoordinators}
              >
                <svg>
                  <use href="/images/icon24Plus.svg#icon24" />
                </svg>
                Стати координатором
              </button>
            )}
        </div>
        <div className="dictAllRowsContainer coordinatorRequests">
          {parksCoordinators.pending.length < 1 ? (
            <div className="coordinatorsRowNormal apDisabled">
              <div className="coordinatorValueImage">ІП</div>
              <div className="coordinatorAllSecPropsBlock">
                <div className="coordinatorValueName">
                  <span>Ім'я Прізвище</span>
                  Посада
                </div>
                <div className="coordinatorRegionAndPhoneAndMailBlock">
                  <div className="coordinatorRegionBlock">
                    <div>Регіон в роботі</div>
                  </div>
                  <div className="coordinatorPhoneAndMailBlock">
                    <div>+38 000 000-00-00</div>
                    <div>email@gmail.com</div>
                  </div>
                </div>
                <div className="coordinatorStatusIndicatorBlock">
                  {/* пустой div для индикатора особого статуса учётки */}
                </div>
              </div>
              <div className="coordinatorButtonsBlock">
                <button className="tetriaryButtonIconDefault">
                  <svg>
                    <use href="/images/icon24CheckCircle.svg#icon24" />
                  </svg>
                </button>
                <button className="tetriaryButtonIconDefault">
                  <svg>
                    <use href="/images/icon24Cancel.svg#icon24" />
                  </svg>
                </button>
              </div>
            </div>
          ) : (
            parksCoordinators.pending.map((el, index) => {
              return (
                <div key={index} className="coordinatorsRowNormal">
                  {el.photo ? (
                    <div
                      className="coordinatorValueImage"
                      style={{ backgroundImage: `URL("${el.photo}")` }}
                    ></div>
                  ) : (
                    <div className="coordinatorValueImage">
                      {el.lastName[0] &&
                        el.lastName[0].toUpperCase() +
                          el.firstName[0].toUpperCase()}
                    </div>
                  )}
                  <div className="coordinatorAllSecPropsBlock">
                    <div className="coordinatorValueName">
                      <span>{el.lastName + " " + el.firstName}</span>
                      {listId.list[el.roleId]}
                    </div>
                    <div className="coordinatorRegionAndPhoneAndMailBlock">
                      <div className="coordinatorRegionBlock">
                        <div>{listId.list[el.regionId]}</div>
                        <div>{listId.list[el.districtId]}</div>
                        <div>{el.city}</div>
                      </div>
                      <div className="coordinatorPhoneAndMailBlock">
                        {el.phone && <div>{setPhoneMask(el.phone)}</div>}
                        {el.email && <div>{el.email}</div>}
                      </div>
                    </div>
                    <div className="coordinatorStatusIndicatorBlock">
                      {el.isDeleted && (
                        <div className="apDeleted">Видалений</div>
                      )}
                      {/* {!el.isActive &&<div className="apDeactivated">Деактивований</div>} */}
                    </div>
                  </div>
                  <div className="coordinatorButtonsBlock">
                    {(currentUser.roleId ===
                      "631db81f-fa07-42e2-b394-062c9be66b09" ||
                      parksCoordinators.owner.id === currentUser.id ||
                      currentUser.roleId ===
                        "66d22cb4-2cf9-4aa7-806b-c50b50f27020") && (
                      <button
                        className="tetriaryButtonIconDefault"
                        id={el.userId}
                        onClick={approveClick}
                      >
                        <svg id={el.userId}>
                          <use
                            id={el.userId}
                            href="/images/icon24CheckCircle.svg#icon24"
                          />
                        </svg>
                      </button>
                    )}
                    {(currentUser.roleId ===
                      "631db81f-fa07-42e2-b394-062c9be66b09" ||
                      currentUser.roleId ===
                        "66d22cb4-2cf9-4aa7-806b-c50b50f27020" ||
                      currentUser.id === el.id ||
                      parksCoordinators.owner.id === currentUser.id) && (
                      <button
                        className="tetriaryButtonIconDefault"
                        id={el.userId}
                        onClick={deleteClick}
                      >
                        <svg id={el.userId}>
                          <use
                            id={el.userId}
                            href="/images/icon24Cancel.svg#icon24"
                          />
                        </svg>
                      </button>
                    )}
                  </div>
                </div>
              );
            })
          )}
        </div>
      </>
    );
  }
};

export default CoordinatorPage;
