import React, {Suspense, useEffect, useCallback, useState } from 'react'
import UseRouts from './routes';
import { useDispatch, useSelector } from 'react-redux';
import { getCurrentUser, getUser } from "./redux/actions/users";
import { logout, setCoordinate, setCoordinateMy } from "./redux/actions/auth";
import { useNavigate } from 'react-router';
import Loader from './components/Loader/Loader';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import { getDictionariesList, getFullList } from './redux/actions/dictionaries';
import axios from "axios";
import InfoWindow from './components/InfoWindow/InfoWindow';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga';
import MobileDownload from './components/MobileDownload/MobileDownload';

function App() {

  var currentUrl = window.location.href.toLowerCase();
  if (currentUrl.includes('ap-dev.sportforall')) {
    var gaIds = ['G-X39N313NYM']; // Сервер для розробки
  } else {
    var gaIds = ['G-LDB74KQJSF']; // Production. дивитись джиру https://td19.atlassian.net/jira/software/c/projects/AP1/issues/AP1-378?jql=project%20%3D%20%22AP1%22%20AND%20status%20IN%20%28%22To%20Do%22%29%20ORDER%20BY%20created%20DESC
  }
  gaIds.forEach(gaId =>{
    ReactGA.initialize(gaId);
  }
  );
  const location = useLocation();

  useEffect(() => {
    ReactGA.pageview(location.pathname + location.search);
  }, [location]);

 
const { user} = useSelector((state) => state.auth);
const {currentUser} = useSelector((state) => state.currentUser);
const { fullList} = useSelector((state) => state);
const {dictionaries}=useSelector(state=>state);
const [ready,setReady]=useState(false);
const dispatch = useDispatch();
const navigate = useNavigate();
const [alertMessage, setAlertMessage] = useState("");
const [alertModal, setAlertModal] = useState(false);

const logOut = useCallback(() => {
  // navigate("/applications");
  dispatch(logout())
}, [dispatch]);

  const getDict=()=>{
    dispatch(getFullList()).then(res=>{
      
      if(res.TimeZone[0]!==(new Date().getTimezoneOffset()/60)){
        setAlertMessage('Ваш часовий пояс відрізняється від сайту. Всі дані на сайті наведено за Київським часом. Звертаємо Вашу увагу, що час початку або завершення заходу може не співпадати з вашим локальним часом.')
        setAlertModal(true);
      }
      dispatch(getDictionariesList('regions')).then(res=>{
        setReady(true)
      })
    })
  }
  
  const setCoordinates =()=>{
    let pos={};     
     
      navigator.geolocation.getCurrentPosition(
        (position)=>{
          pos={
            lat:+position.coords.latitude.toFixed(7),
            lng:+position.coords.longitude.toFixed(7),
          }
          dispatch(setCoordinate(pos,'geolocation'))
          dispatch(setCoordinateMy(pos,'ip'));     
        },(error)=>{
          axios.get("https://ipinfo.io/json?token=6de5360645668e").then(resp=>{
          const pos = {
            lat:+(+resp.data.loc.split(',')[0]).toFixed(7),
            lng:+(+resp.data.loc.split(',')[1]).toFixed(7),
          };
          dispatch(setCoordinate(pos,'ip'));     
          dispatch(setCoordinateMy(pos,'ip'));     
          }).catch(e=>{
            const pos = {
              lat:50.4500512,
              lng:30.5233329,
            };
            dispatch(setCoordinate(pos,'kyiv'));  
            dispatch(setCoordinateMy(pos,'kyiv'));  
          })
        }
        )
  }


  if(window.location.hostname.split('.')[0]==='web'){
    document.location.href='https://ap.sportforall.gov.ua'+window.location.pathname;
  }


useEffect(() => {
  if(!ready){
    setCoordinates();
    if (user) {
      setReady(false);
      dispatch(getCurrentUser(user.payload.id)).then(res=>{
      
        setReady(true);
        // checkGeolocation();

      })
        .catch((e) => {
          logOut();
        })
    } else {
      logOut();
    }
    getDict();}
}, [user, logOut, dispatch ]);


  return (
    <div className="App">
       {alertModal && (
          <InfoWindow
            setModal={setAlertModal}
            message={alertMessage}
          ></InfoWindow>
        )}
    <Suspense fallback={<Loader />}>
      <Header/>
      {/* {<Sidebar unRead={unRead} user={user}/>} */}
      {/* <Loader /> */}
      <div className="mainLine">
        <div className="mainCommonBlock">
        {ready&& <UseRouts user={user}/>}
        {!ready && <Loader/>}
      </div></div>
      <Footer/>
      <MobileDownload/>
    </Suspense>
  </div>
  );
}

export default App;
