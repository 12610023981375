import {
    GETEVENTS_SUCCESS,
    GETEVENTS_FAIL,
    EDITEVENTS_SUCCESS,
    EDITEVENTS_FAIL,
    DELETEEVENTS_SUCCESS,
    DELETEEVENTS_FAIL,
    GETEVENTSITEM_SUCCESS,
    GETEVENTSITEM_FAIL,
    CREATEEVENTS_SUCCESS,
    CREATEEVENTS_FAIL
} from "../actions/types";

const initialState = [];

export default function eventsReducer(events = initialState, action) {
    const {
        list,
        item,
        type
    } = action;

    switch (type) {
        case CREATEEVENTS_SUCCESS:
            return {
                currentPark: item
            }
            case CREATEEVENTS_FAIL:
                return events;
            case GETEVENTS_SUCCESS:
                return {
                    ...list
                };
            case GETEVENTS_FAIL:
                return events;
            case GETEVENTSITEM_SUCCESS:
                return {
                    ...item
                };
            case GETEVENTSITEM_FAIL:
                return events;
            case EDITEVENTS_SUCCESS:
                return events;
            case EDITEVENTS_FAIL:
                return {
                    ...events, error: item?.error?.data?.errors
                };
            case DELETEEVENTS_SUCCESS:
                return events;
            case DELETEEVENTS_FAIL:
                return events;
            default:
                return events;
    }

}