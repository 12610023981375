import React, { useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { uploadFile } from "../../redux/actions/file";
import Loader from "../../components/Loader/Loader";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";

function FileUploadAndCrop(props) {
  const [loading, setLoading] = useState(false);
  const { fullList } = useSelector((state) => state);
  const [croppedImage, setCroppedImage] = useState(null);
  const [file, setFile] = useState(null);
  const [resize, setResize] = useState(true);
  const [fileInfo, setFileInfo] = useState(null);
  const [edit, setEdit] = useState(false);
  const dispatch = useDispatch();
  const cropperRef = useRef(null);

  function dragOverHandler(ev) {
    ev.preventDefault();
    console.log(ev);
  }

  const DropOn = (e) => {
    e.preventDefault();
    const _file = e.dataTransfer?.files[0];
    getFileContext(_file);
  };

  const inputPut = (e) => {
    const _file = e.target?.files[0];
    getFileContext(_file);
  };

  const getFileContext = (e) => {
    let arr = [...fullList.FileTypes[1].title.split(" ")];
    const _file = e;

    if (!_file) {
      return;
    }

    if (_file.size > (props.size || 10) * 1000000) {
      props.setAlertMessage(`Максимальний розмір до ${props.size || 10}МБ`);
      props.setAlertModal(true);
      return;
    }

    if (arr.find((a) => a.includes(_file.name.split(".").pop().toLowerCase()))) {
      console.log(_file);
      setEdit(true);
      let src = URL.createObjectURL(_file);
      setFileInfo(_file);
      setFile(src);
    } else {
      props.setAlertMessage("Не вірний формат файлу");
      props.setAlertModal(true);
      return;
    }
  };

  const onCrop = () => {
    const cropper = cropperRef.current?.cropper;
    cropper.getCroppedCanvas().toBlob((blob) => {
      const croppedImage = new File([blob], 'new-image.jpg', { type: 'image/jpeg', lastModified: new Date().getTime() });
      setCroppedImage(croppedImage);
    }, 'image/jpeg');
  };

  const saveCropChange = () => {
    if (!croppedImage) {
      props.setAlertMessage("Будь ласка, оберіть область фото для обрізки");
      props.setAlertModal(true);
      return;
    }

    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    const img = new Image();

    img.onload = () => {
      const maxDimension = 1600;
      let width = img.width;
      let height = img.height;

      if (width > height) {
        if (width > maxDimension) {
          height *= maxDimension / width;
          width = maxDimension;
        }
      } else {
        if (height > maxDimension) {
          width *= maxDimension / height;
          height = maxDimension;
        }
      }

      canvas.width = width;
      canvas.height = height;
      ctx.drawImage(img, 0, 0, width, height);

      canvas.toBlob((blob) => {
        if (!blob) {
          props.setAlertMessage("Помилка при створенні Blob зображення");
          props.setAlertModal(true);
          return;
        }

        let compressedImage = new File([blob], croppedImage.name, {
          type: 'image/jpeg',
          lastModified: new Date().getTime()
        });

        let formData = new FormData();
        formData.append("fileName", compressedImage.name);
        formData.append("size", compressedImage.size);
        formData.append("chunkIndex", 1);
        formData.append("totalChunk", 1);
        formData.append("file", compressedImage);
        formData.append("uploadId", `${compressedImage.name}_${compressedImage.size}`);
        formData.append("uploadType", props.options?.uploadType || "avatar");

        setLoading(true);
        dispatch(uploadFile(formData)).then(response => {
          const data = response.data;

          if (response.status === 201) {
            let res = { ...props.form };
            if (props.options.type === 'photo') {
              res.photo = data.url;
            } else if (props.options.type === 'mainPhoto') {
              res.mainPhoto = data.url;
            } else if (props.options.type === 'imageUrl') {
              res.imageUrl = data.url;
            } else if (props.options.type === 'logoUrl') {
              res.logoUrl = data.url;
            }
            console.log(res);

            props.saveEdit(res);
            props.setForm({ ...res });
            setLoading(false);
            setEdit(false);
          }
        });
      }, 'image/jpeg', 0.5);
    };

    const objectURL = URL.createObjectURL(croppedImage);
    img.src = objectURL;
  };

  return (
    <>
      {!props.options.dragAbleZone && (
        <div className="profilePhotoImageAndChangeBlock">
          {edit && (
            <div className="modalForm">
              <div className="apFilterContainer apFilterContainerHeight" onBlur={() => { setEdit(false); }} tabIndex="0">
                <div className="apFilterHeader">
                  <h4>Додавання зображення</h4>
                  <div style={{ display: "flex" }}>
                    <button className="secondarySmallRoundedButtonTextDefault" onClick={() => { setEdit(false); }}>
                      X
                    </button>
                  </div>
                </div>
                <div className="apFilterGroupBlock">
                  {!loading ? (
                    <Cropper
                      src={file}
                      style={{ height: props.options?.height || "176px", width: props.options?.width || "176px" }}
                      initialAspectRatio={props.options?.initialAspectRatio || 16 / 10}
                      aspectRatio={props.options?.aspectRatio || 1}
                      autoCropArea={props.options?.autoCropArea || 1}
                      guides={false}
                      viewMode={1}
                      dragMode="move"
                      scalable={resize}
                      cropBoxResizable={resize}
                      cropBoxMovable={resize}
                      crop={onCrop}
                      ref={cropperRef}
                    />
                  ) : <Loader />}
                  <button onClick={saveCropChange} disabled={loading} className='primaryButtonIconTextDefault' style={{ marginTop: '5px' }}>{"Зберегти зображення"} </button>
                  <button onClick={() => { setEdit(false); }} className='primaryButtonIconTextDefault' style={{ marginTop: '5px' }}>Скасувати додавання фото</button>
                </div>
              </div>
            </div>
          )}
          {!props.options.dragAbleZone && (
            <>
              <div className="profilePhotoImageDiv" style={{ backgroundImage: `URL(${props.form?.photo || props.form?.mainPhoto || props.form?.imageUrl || props.form?.logoUrl ? props.form?.photo || props.form?.mainPhoto || props.form?.imageUrl || props.form?.logoUrl : "/images/emptyImage.png" })`, border: `${props.form?.photo ? "initial" : ""}` }} />
              <input
                type="file"
                accept=".jpg, .jpeg, .png, .gif"
                id="photo"
                style={{ display: "none" }}
                onChange={props.options.disabled ? "" : inputPut}
                tabIndex="0"
              />
              {!props.options.disabled && (
                <label
                  htmlFor="photo"
                  className="secondaryButtonIconTextDefault label"
                  onDrop={props.options.disabled ? "" : DropOn}
                  onDragOver={props.options.disabled ? "" : dragOverHandler}
                  tabIndex="0"
                >
                  <svg>
                    <use href="/images/icon24Edit.svg#icon24"></use>
                  </svg>
                  {"Замінити фото"}
                </label>
              )}
            </>
          )}
        </div>
      )}

      {props.options.dragAbleZone && (
        <div className="videoCardCoverImageAndDrop">
          <div className="videoCardCoverImageAndText">
            {edit && (
              <div className="modalForm" onMouseOver={(e) => { e.stopPropagation(); e.preventDefault(); }}>
                <div className="apFilterContainer apFilterContainerHeight" onBlur={() => { setEdit(false); }} tabIndex="0">
                  <div className="apFilterHeader">
                    <h4>Додавання зображення</h4>
                    <div style={{ display: "flex" }}>
                      <button className="secondarySmallRoundedButtonTextDefault" onClick={() => { setEdit(false); }}>
                        X
                      </button>
                    </div>
                  </div>
                  <div className="apFilterGroupBlock">
                    {!loading ? (
                      <Cropper
                        src={file}
                        style={{ height: props.options?.height || "176px", width: props.options?.width || "176px" }}
                        initialAspectRatio={props.options?.initialAspectRatio || 16 / 10}
                        aspectRatio={props.options?.aspectRatio || 1}
                        autoCropArea={props.options?.autoCropArea || 1}
                        guides={false}
                        viewMode={1}
                        dragMode="move"
                        scalable={resize}
                        cropBoxResizable={resize}
                        cropBoxMovable={resize}
                        crop={onCrop}
                        ref={cropperRef}
                      />
                    ) : <Loader />}
                    <button onClick={saveCropChange} disabled={loading} className='primaryButtonIconTextDefault' style={{ marginTop: '5px' }}>{"Зберегти зображення"} </button>
                    <button onClick={() => { setEdit(false); }} className='primaryButtonIconTextDefault' style={{ marginTop: '5px' }}>Скасувати додавання фото</button>
                  </div>
                </div>
              </div>
            )}
            {(!props.form.mainPhoto && !props.form.imageUrl) && <div className={props.options.className ? props.options.className : "videoCardCoverImage"} />}
            {(props.form.mainPhoto || props.form.imageUrl) && (
              <div className={props.options.className ? props.options.className : "videoCardCoverImage"} style={{ backgroundImage: `url(${props.form.mainPhoto || props.form.imageUrl})` }} />
            )}
            <div className="videoCardCoverText">
              Завантажте обкладинку для {props.options.text}
            </div>
          </div>
          <div className="videoCardDropAreaAndHint">
            {props.form.statusId === "66e2ec16-03a1-4367-834a-d6b87ee709bd" ? (
              <></>
            ) : (
              <input
                type="file"
                accept=".jpg, .jpeg, .png"
                id="mainPhoto"
                onChange={props.options.disabled ? "" : inputPut}
                disabled={props.options.disabled}
                style={{ display: "none" }}
              />
            )}
            <label
              id="drop_zone"
              htmlFor="mainPhoto"
              className={props.form.statusId === "66e2ec16-03a1-4367-834a-d6b87ee709bd" ? "videoCardDropArea apDisabled" : "videoCardDropArea"}
              onDrop={props.options.disabled ? "" : DropOn}
              onDragOver={props.options.disabled ? "" : dragOverHandler}
            >
              <svg>
                <use href="/images/icon24Download.svg#icon24" />
              </svg>
              <span>
                {props.options.disabled ? `Опубліковане ${props.options.text} не можливо редагувати` : `Перетягніть файли сюди або натисніть, щоб обрати.`}
              </span>
            </label>
            <div className="apParkImagesHintArea">
              <span>
                Завантажуйте файл jpg, jpeg, png до&nbsp;10&nbsp;мегабайт.
              </span>
              <svg>
                <use href="/images/icon24Info.svg#icon24" />
              </svg>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default FileUploadAndCrop;
