import { combineReducers } from "redux";
import auth from "./auth";
import users from "./users";
import parks from "./parks";
import currentUser from "./currentUser";
import currentInfo from "./currentInfo";
import currentNews from "./currentNews";
import currentEvent from "./currentEvent";
import currentInfoList from "./currentInfoList";
import dictionaries from "./dictionary";
import fullList from "./fullList";
import listId from "./listId";
import dictionariesAdditional from "./dictionaryAdditional";
import videos from "./video"
import userVideos from "./videoUser"
import parksMessages from "./parksMessages"
import videosMessages from "./videosMessages"
import parksCoordinators from "./parksCoordinators"
import coordinatorsList from "./coordinatorsList"
import events from "./events"
import info from "./info"
import news from "./news"
import log from "./log"
import support from "./support"
import coordinates from "./coordinates"
import coordinatesMy from "./coordinatesMy"
import fc from "./fc"
import fcParticipant from "./fcParticipant"
import fcCreator from "./fcCreator"
import fcUsers from "./fcUsers"
import eventEval from "./eventEval"
import fcUsersHead from "./fcUserHead"
import fcUsersMember from "./fcUserMember"
import fcNews from "./fcNews"
import eventsResults from "./eventsResults"
import points from "./routePoints"
import notifications from "./notifications"
import conf from "./currentConf"
import reports from "./reports"
import workouts from "./workouts"
import workoutsPlan from "./workoutsPlan"
import counter from "./counter"
import track from "./track"
import route from "./route"
import gallery from "./gallery"
import routePoint from "./routePoint"
import parksMap from "./parksMap"

export default combineReducers({
  auth,
  currentUser,
  users,
  parks,
  parksMap,
  parksMessages,
  parksCoordinators,
  coordinatorsList,
  videos,
  userVideos,
  info,
  track,
  route,
  support,
  news,
  currentInfo,
  currentNews,
  currentInfoList,
  currentEvent,
  gallery,
  videosMessages,
  events,
  fc,
  fcParticipant,
  fcCreator,
  fcUsers,
  fcUsersHead,
  fcUsersMember,
  fcNews,
  eventsResults,
  routePoint,
  points,
  conf,
  reports,
  notifications,
  dictionaries,
  dictionariesAdditional,
  coordinates,
  coordinatesMy,
  workouts,
  workoutsPlan,
  eventEval,
  counter,
  fullList,
  listId,
  log
  });
