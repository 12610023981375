import React, { useState,useEffect} from "react";
import { useDispatch} from "react-redux";
import { useParams } from "react-router";
import { Icon } from "../../../../../components/Icon";
import Loader from "../../../../../components/Loader/Loader";
import getDate from "../../../../../helpers/getDate";
import { getWorkouts } from "../../../../../redux/actions/workouts";
import { HistoryItem } from "./HistoryItem";

import { ReactComponent as Arrow } from '../../../../../assets/images/collapseArrow2.svg'

const History = (props) => {
  const [list,setList]=useState([]);
  const [listFull,setListFull]=useState([]);
  const dispatch=useDispatch();
  const [ready,setReady]=useState(false)
  const [isFinal,setIsFinal]=useState(false);
  const [readyArray,setReadyArray]=useState([]);
  const currentId=useParams().id;
    const initFunc=(date=new Date().toISOString().split("T")[0],type='next')=>{
        let data= {date:date,id:currentId,type:type}
        console.log(data);
        dispatch(getWorkouts(data)).then(res=>{
          if(type=='prev'){
            setIsFinal(res.isFinal);
          }
            let arr = [];
            if(type==='next'){
              arr=[...listFull,...res.items]
            }else if(type==='prev'){
              arr=[...res.items,...listFull]
            }else{
              arr=res.items;
            }

              const dates = [];
              arr.forEach((el) => {
                const day = getDate(el.startsAt, "dd/mm/yyyy", ".");
                const index = dates.findIndex((date) => date[0] === day);
                if (index === -1) {
                  dates.push([day, el]);
                } else {

                  const elUpdatedAt = new Date(el.lastUpdatedAt);
                  const existingEl = dates[index].find((e) => e.id === el.id);
                  if (existingEl) {
                    const existingElUpdatedAt = new Date(existingEl.lastUpdatedAt);
                    if (elUpdatedAt > existingElUpdatedAt) {
                      const index2 = dates[index].indexOf(existingEl);
                      dates[index][index2] = el;
                    }
                  } else {
                    dates[index].push(el);
                  }
                }
              });
              console.log('arr')
              console.log(arr)
              console.log('listFull')
              console.log(listFull)
  
        console.log('dates')
        console.log(dates)
        setListFull(arr);
        setList(dates)
        props.setReady(true);
        setReady(true);
        })
    }

    const getPrev=(e)=>{
      let date= list[0][0];
      let d=date.split('.')[2]+'-'+date.split('.')[1]+'-'+date.split('.')[0];
      initFunc(d,e.target.id)
    }
  
    const getNext=(e)=>{
      let date= list[list.length-1][0];
      let d= date.split('.')[2]+'-'+date.split('.')[1]+'-'+date.split('.')[0];
      initFunc(d,e.target.id)
    }

    useEffect(() => {
        if(!props.ready || !ready){
            initFunc()
        }
    }, [props.ready,ready]);


    useEffect(() => {
      const array1 = list?.length > 0 ? list?.slice(list?.length / 2) : [];
      const array2 = list?.length > 0 ? list?.slice(0, list?.length / 2) : [];
      // const arr1 = listFull.slice(0, Math.ceil(listFull.length / 2));
      // const arr2 = listFull.slice(Math.ceil(listFull.length / 2));
      setReadyArray([array2,array1]);
    }, [list]);

  return (
    <>
      <h2 style={{'display': 'flex', 'justifyContent': 'space-between'}}>Журнал тренувань: <button className="primaryButtonIconTextDefault addClass" onClick={() => props.setModalOpen((s) => !s)}> <Icon type={'plus'} /> Додати тренування</button></h2> 
      {!isFinal && props.ready && list?.length>0 &&<button className="moreButtonBefore" onClick={getPrev} id={'prev'} style={{margin:'20px auto'}}>
        <Arrow/>
        Завантажити попередні</button>}
      <div className="apTrainingsHistoryContainer"> 
        {props.ready &&ready && list?.length>0 && readyArray?.length>0&& readyArray.map((el, i) => 
         { 
            return (
         <div className={`apColumn apColumn-${i}`} key={i}>
            {el.map((item, index) => {
                return (
              <HistoryItem items={item} isCurrentDay={ item[0]=== new Date()} key={index} />
            )})}
          </div>)}
        )}

        {props.ready &&ready &&list.length===0&&
            <div className="apEmptyCategory">Не заплановано тренувань</div>
        }
        {!props.ready&& <Loader/>}
      </div>
     {props.ready &&ready &&list.length>0 &&<button className="moreButton" onClick={getNext} id={'next'} style={{margin:'20px auto'}}>
        <Arrow/>
        Завантажити ще
      </button>}
    </>
  );
};

export default History;
