import {
    GETGALLERYOFF_SUCCESS,
    GETGALLERYOFF_FAIL,
    GETGALLERY_FAIL,
    GETGALLERY_SUCCESS,
    CHANGEGALLERY_SUCCESS,
    CHANGEGALLERY_FAIL,
    DELETEGALLERY_SUCCESS,
    DELETEGALLERY_FAIL
} from "../actions/types";

const initialState = [];

export default function galleryReducer(gallery = initialState,action){
    const {list,type} = action;

    switch(type) {
        case  GETGALLERYOFF_SUCCESS:
            return {...gallery,official:list}
        case GETGALLERYOFF_FAIL:
            return gallery;
        case GETGALLERY_SUCCESS:
            return {...gallery,user:list};
        case GETGALLERY_FAIL:
            return gallery;
        case CHANGEGALLERY_SUCCESS:
            return gallery;
        case CHANGEGALLERY_FAIL:
            return gallery;
        case DELETEGALLERY_SUCCESS:
            return gallery;
        case DELETEGALLERY_FAIL:
            return gallery;
        default:
            return gallery;
    }

}