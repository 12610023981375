import {
  GETEVENTSCURRENTITEM_SUCCESS,
  GETEVENTSCURRENTITEM_FAIL
} from "../actions/types";
  
const initialState = [];

export default function infoReducer(currentNews = initialState, action) {
    const { type, item } = action;

    switch (type) {
      case GETEVENTSCURRENTITEM_SUCCESS:
        return {...item};
    case GETEVENTSCURRENTITEM_FAIL:
        return currentNews;
      default:
        return currentNews;
      }

}

