import React from "react";
import { useSelector } from "react-redux";

const About =()=>{
  const { currentEvent } = useSelector((state) => state);

    return(
        <>
            {currentEvent?.fullDescription?.length>0 && <div
                style={{ marginTop: "20px" }}
                dangerouslySetInnerHTML={{ __html: currentEvent.fullDescription }}
            ></div>}
        </>
    )
}

export default About;

