import {
    CREATEINFO_SUCCESS,
    CREATEINFO_FAIL,
    GETINFO_SUCCESS,
    GETINFO_FAIL,
    GETINFOITEM_SUCCESS,
    GETINFOITEM_FAIL,
    EDITINFO_SUCCESS,
    EDITINFO_FAIL,
    DELETEINFO_SUCCESS,
    DELETEINFO_FAIL,
} from "../actions/types";

const initialState = [];

export default function infoReducer(info = initialState,action){
    const {list,item,type} = action;

    switch(type) {
        case  CREATEINFO_SUCCESS:
            return {currentInfo:item}
        case CREATEINFO_FAIL:
            return info;
        case GETINFO_SUCCESS:
            return {...list};
        case GETINFO_FAIL:
            return info;
        case GETINFOITEM_SUCCESS:
            return {...item};
        case GETINFOITEM_FAIL:
            return info;
        case EDITINFO_SUCCESS:
            return info;
        case EDITINFO_FAIL:
            return info;
        case DELETEINFO_SUCCESS:
            return info;
        case DELETEINFO_FAIL:
            return info;
        default:
            return info;
    }

}