import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';


const RegionModal=(props)=>{
    const dispatch=useDispatch();
    const [ready,setReady]=useState(false)
    const {fullList}=useSelector(state=>state);
    const [form,setForm]=useState([]);
    let arr=props.form.regions;
    let initFunction =()=>{
        setForm([...arr]);
        setReady(true)
    }

    const saveFilter=(data)=>{
        if(data){
            props.setForm({...props.form,['regions']:form})
        }else{
            props.setForm({...props.form,['regions']:form})
        }
    }

    useEffect(()=>{
        if(!ready){
            initFunction();
        }
    },[])

    const setSelect=(e)=>{   
        arr=[...form]
        
        if(arr && arr.includes(e.target.id)){
            const index = arr.indexOf(e.target.id);
            if (index > -1) {
                arr.splice(index, 1);
            }
        }else{
            if(arr){
                arr.push(e.target.id);
            }else{
                arr=[]
                arr.push(e.target.id);
            }  
        }
        setForm(arr)
    }
    const leaveClick =(event)=>{
        if (!event.currentTarget.contains(event.relatedTarget)) {
            saveFilter();
            props.setModal(false);
            let data = {...props.form}
            data.regions=form;
            props.setForm(data);
            props.saveEdit(data);
        }
    }
    const resetClick =()=>{
        props.setForm({...props.form,['regions']:[]})
        setForm([]);
    }
   
return(
<div className="apFilterContainer"  onBlur={leaveClick} tabIndex="0">
			<div className="apFilterHeader">
				<h4>Регіони</h4>
                <div style={{'display':'flex'}}>
				<button style={{'marginRight':'20px'}}  className="secondarySmallRoundedButtonTextDefault" onClick={resetClick}>Скинути</button>
				<button className="secondarySmallRoundedButtonTextDefault" onClick={leaveClick}>OK</button>
                </div>
			</div>
			<div className="apFilterGroupBlock">
				<div className="apFilterButtonsBlock">
                    {fullList.Regions.map(el=>{
                        return(
                            <button id={el.id} accessKey='regionId'className={form.length>0 && form.includes(el.id)?"secondarySmallButtonTextActive" :"secondarySmallButtonTextDefault"}  onClick={setSelect}>{el.title}</button>
                        )
                    })}
					
				</div>
			</div>
		</div>

    )
}


export default RegionModal;