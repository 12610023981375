import {
    CREATEPARKMESSAGES_SUCCESS,
    CREATEPARKMESSAGES_FAIL,
    GETPARKMESSAGES_SUCCESS,
    GETPARKMESSAGES_FAIL,
    GETPARKMESSAGESITEM_SUCCESS,
    GETPARKMESSAGESITEM_FAIL,
    EDITPARKMESSAGES_SUCCESS,
    EDITPARKMESSAGES_FAIL,
    DELETEPARKMESSAGES_SUCCESS,
    DELETEPARKMESSAGES_FAIL
} from "../actions/types";

const initialState = [];

export default function parksMessagesReducer(parksMessages = initialState,action){
    const {list,item,type} = action;

    switch(type) {
        case  CREATEPARKMESSAGES_SUCCESS:
            return {currentPark:item}
        case CREATEPARKMESSAGES_FAIL:
            return parksMessages;
        case GETPARKMESSAGES_SUCCESS:
            return {...list};
        case GETPARKMESSAGES_FAIL:
            return parksMessages;
        case GETPARKMESSAGESITEM_SUCCESS:
            return {...item};
        case GETPARKMESSAGESITEM_FAIL:
            return parksMessages;
        case EDITPARKMESSAGES_SUCCESS:
            return parksMessages;
        case EDITPARKMESSAGES_FAIL:
            return parksMessages;
        case DELETEPARKMESSAGES_SUCCESS:
            return parksMessages;
        case DELETEPARKMESSAGES_FAIL:
            return parksMessages;
        default:
            return parksMessages;
    }

}