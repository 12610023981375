import {
    CREATEVIDEOMESSAGES_SUCCESS,
    CREATEVIDEOMESSAGES_FAIL,
    GETVIDEOMESSAGES_SUCCESS,
    GETVIDEOMESSAGES_FAIL,
    GETVIDEOMESSAGESITEM_SUCCESS,
    GETVIDEOMESSAGESITEM_FAIL,
    EDITVIDEOMESSAGES_SUCCESS,
    EDITVIDEOMESSAGES_FAIL,
    DELETEVIDEOMESSAGES_SUCCESS,
    DELETEVIDEOMESSAGES_FAIL
} from "../actions/types";

const initialState = [];

export default function videosMessagesReducer(videosMessages = initialState,action){
    const {list,item,type} = action;

    switch(type) {
        case  CREATEVIDEOMESSAGES_SUCCESS:
            return {currentvideo:item}
        case CREATEVIDEOMESSAGES_FAIL:
            return  videosMessages;
        case GETVIDEOMESSAGES_SUCCESS:
            return {...list};
        case GETVIDEOMESSAGES_FAIL:
            return  videosMessages;
        case GETVIDEOMESSAGESITEM_SUCCESS:
            return {...item};
        case GETVIDEOMESSAGESITEM_FAIL:
            return  videosMessages;
        case EDITVIDEOMESSAGES_SUCCESS:
            return  videosMessages;
        case EDITVIDEOMESSAGES_FAIL:
            return  videosMessages;
        case DELETEVIDEOMESSAGES_SUCCESS:
            return  videosMessages;
        case DELETEVIDEOMESSAGES_FAIL:
            return  videosMessages;
        default:
            return  videosMessages;
    }

}