import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import useFilterArray from "../../../hooks/filterArray";
import { Navigation, Pagination, EffectFade } from "swiper";
import { Swiper as Slider, SwiperSlide as Slide } from "swiper/react";
import getDate from "../../../helpers/getDate";
import { getNews } from "../../../redux/actions/news";

const NewsBlock =()=>{
    const { news } = useSelector((state) => state);
    const { filteredData } = useFilterArray(news);

    const navigate = useNavigate();
    const [ready, setReady] = useState(false);
    const dispatch = useDispatch();


    const initFunc=()=>{
        let data = {};
        data.filter = {};
        data.limit = 5;
        data.sort = { publishedAt: "desc" };

        dispatch(getNews(data)).then((res) => {
            setReady(true);
        });
    }

    useEffect(() => {
        if(!ready){
            initFunc();
        }
    }, [ready]);
    
    return(
        <section className="main-section main-news">
        {ready && filteredData && filteredData.length !== 0 && (
          <>
            <div className="headline">
              <h2>Останній блог:</h2>
              <button className='mobileHidden' onClick={() => navigate("/newspage")}>Всі записи блогу</button>
            </div>
            <Slider
              modules={[Navigation, Pagination, EffectFade]}
              navigation
              pagination={{ clickable: true }}
              slidesPerView={1}
              breakpoints={{
                640: {
                  slidesPerView: 1,
                },
                768: {
                  slidesPerView: 2,
                },
                991: {
                  slidesPerView: 3,
                },
              }}
              spaceBetween={24}
            >
              {filteredData.map((el, index) => (
                <Slide key={index}>
                <div className="startPageItem"
                    onClick={() => navigate(el.clubId?`/fc/${el.clubId}/news/${el.id}`:`/newspg/${el.id}`)}
                  style={{
                    backgroundImage: `linear-gradient(0deg, rgba(0,0,0,0.6) 10%, rgba(0,0,0,0) 70%), url('${
                      (el.imageUrl !== null &&
                        el.imageUrl.length !== 0 &&
                        el.imageUrl) ||
                      "https://ap.sportforall.gov.ua/api/v1/uploads/b34604xc-02qb-46bb-9e91-fc3ffrb92w41"
                      }')`,
                  }}>
                      <div className="leftsideInfoStartPage">
                        <div>{el.clubId?el.clubName :'Активні парки'}</div>
                        <div className="leftsideInfoStartPageHeader"  > {el.title.length > 90 ? el.title.slice(0, 90) + '...' :el.title}</div>
                        <div className="leftsideInfoStartPageSubText">{getDate(el.publishedAt,'dd/mm/yyyy hh:mm')}</div>
                      </div>
                     
                  </div>
                </Slide>
              ))}
            </Slider>
            <button className='mobileShow' onClick={() => navigate("/newspage")}>Всі записи блогу</button>
          </>
        )}
      </section>
    )
}

export default NewsBlock;