import axios from "axios";
import authHeader from "./auth-header";

import constants from '../../constants/constant.json';


const getEvents = (data) => {
    console.log(data);
    if (!data.offset) {
        data.offset = 0;
    }
    if (!data.limit) {
        data.limit = 10
    }
    let filter = ''
    if (data.filter && Object.keys(data.filter).length) {
        for (const [key, value] of Object.entries(data.filter)) {

            value.forEach(el => {
                filter += `&filters[${key}]=${el}`
            });
        }
    }
    if (data.sort && Object.keys(data.sort).length) {
        for (const [key, value] of Object.entries(data.sort)) {
            filter += `&sort[${key}]=${value}`
        }
    }
    return axios.get(constants.URL + `api/v1/sport-events/my?offset=${data.offset}&limit=${data.limit}${filter}`, {
        headers: authHeader()
    }).then(response => {
        return response.data
    }).catch((e) => {
        console.log(e);
    });;

}

const getAllEvents = (data) => {
    if (!data.offset) {
        data.offset = 0;
    }
    if (!data.limit) {
        data.limit = 10
    }
    let filter = ''
    if (data.filter && Object.keys(data.filter).length) {
        for (const [key, value] of Object.entries(data.filter)) {
            value.forEach(el => {
                filter += `&filters[${key}]=${el}`
            });
        }
    }
    if (data.sort && Object.keys(data.sort).length) {
        for (const [key, value] of Object.entries(data.sort)) {
            filter += `&sort[${key}]=${value}`
        }
    }
    return axios.get(constants.URL + `api/v1/sport-events?offset=${data.offset}&limit=${data.limit}${filter}`, {
        headers: authHeader()
    }).then(response => {
        return response.data
    }).catch((e) => {
        console.log(e);
    });
}

const getAllCallendarEvents = (data) => {
    let filter = ''
    if (data.filter && Object.keys(data.filter).length) {
        for (const [key, value] of Object.entries(data.filter)) {
            value.forEach(el => {
                filter += `&filters[${key}]=${el}`
            });
        }
    }
    if (data.sort && Object.keys(data.sort).length) {
        for (const [key, value] of Object.entries(data.sort)) {
            filter += `&sort[${key}]=${value}`
        }
    }
    return axios.get(constants.URL + `api/v1/sport-events/all?offset=0&${filter}`, {
        headers: authHeader()
    }).then(response => {
        return response.data
    }).catch((e) => {
        console.log(e);
    });
}

const getDayCallendarEvents = (data) => {
    let filter = ''
    if (data.filter && Object.keys(data.filter).length) {
        for (const [key, value] of Object.entries(data.filter)) {
            value.forEach(el => {
                filter += `&filters[${key}]=${el}`
            });
        }
    }
    if (data.sort && Object.keys(data.sort).length) {
        
        for (const [key, value] of Object.entries(data.sort)) {
            if(key!= undefined){
                filter += `&sort[${key}]=${value}`
            }
        }
    }
    return axios.get(constants.URL + `api/v1/sport-events/day?offset=0${filter}`, {
        headers: authHeader()
    }).then(response => {
        return response.data
    }).catch((e) => {
        console.log(e);
    });
}

const getMyEvents = (data) => {
    let filter = '';
    if (data.filter && Object.keys(data.filter).length) {
        for (const [key, value] of Object.entries(data.filter)) {
            value.forEach(el => {
                filter += `&filters[${key}]=${el}`
            });
        }
    }
    if (data.sort && Object.keys(data.sort).length) {
        for (const [key, value] of Object.entries(data.sort)) {
            filter += `&sort[${key}]=${value}`
        }
    }
    return axios.get(constants.URL + `api/v1/sport-events/future-my-sport-event?offset=0${filter}`, {
        headers: authHeader()
    }).then(response => {
        return response.data
    }).catch((e) => {
        console.log(e);
    });
}

const getNearest = (data) => {
    let filter = '';
    if (data.filter && Object.keys(data.filter).length) {
        for (const [key, value] of Object.entries(data.filter)) {
            value.forEach(el => {
                filter += `&filters[${key}]=${el}`
            });
        }
    }
    if (data.sort && Object.keys(data.sort).length) {
        for (const [key, value] of Object.entries(data.sort)) {
            filter += `&sort[${key}]=${value}`
        }
    }
    return axios.get(constants.URL + `api/v1/sport-events/five-sport-event?${filter}`, {
        headers: authHeader()
    }).then(response => {
        return response.data
    }).catch((e) => {
        console.log(e);
    });
}





const getCalendarEvents=(data)=>{
    if (!data.offset) {
        data.offset = 0;
    }
    if (!data.limit) {
        data.limit = 10
    }
    let filter = ''
    if (data.filter && Object.keys(data.filter).length) {
        for (const [key, value] of Object.entries(data.filter)) {
            value.forEach(el => {
                filter += `&filters[${key}]=${el}`
            });
        }
    }
    if (data.sort && Object.keys(data.sort).length) {
        for (const [key, value] of Object.entries(data.sort)) {
            filter += `&sort[${key}]=${value}`
        }
    }
    return axios.get(constants.URL + `/api/v1/sport-events/all?offset=${data.offset}${filter}`, {
        headers: authHeader()
    }).then(response => {
        return response.data
    }).catch((e) => {
        console.log(e);
    });
}

const addEvents = (data) => {

    if (data && data?.id) {
        return axios.post(constants.URL + `api/v1/sport-events/${data.id}/${data.type}`, {}, {
                headers: authHeader()
            })
            .then((response) => {
                return response.data
            })
            .catch((e) => {

                console.log(e);
            });
    } else if(data?.clubId){
        return axios.post(constants.URL + `api/v1/sport-events`, data, {
                headers: authHeader()
            })
            .then((response) => {
                return response.data
            })
            .catch((e) => {

                console.log(e);
            });
    }else{
        return axios.post(constants.URL + `api/v1/sport-events`, {}, {
            headers: authHeader()
        })
        .then((response) => {
            return response.data
        })
        .catch((e) => {

            console.log(e);
        });
    }

}


const readeEventQr =(data)=>{
    return axios.get(constants.URL + `api/v1/qr-code-point/${data.id}`, {
        headers: authHeader()
    })
    .then((response) => {
        return response.data
    })
    .catch((response) => {
        console.log(response);
        return response.data
    });
}

const readeEventActionQr =(data)=>{

    return axios.get(constants.URL + `api/v1/qr-code-sport-events/${data.id}/apply`, {
        headers: authHeader()
    })
    .then((response) => {
        return response.data
    })
    .catch((response) => {
        console.log(response);
        return response.data
    });
}



const editEvents = (data) => {
    return axios.put(constants.URL + 'api/v1/sport-events/' + data.id, {
            ...data
        }, {
            headers: authHeader()
        })
        .then((response) => {
            return response.data
        })
        .catch((e) => {
            console.log(e);
            return Promise.reject(e);
        });
}

const getEventsItem = (data) => {
    return axios.get(constants.URL + 'api/v1/sport-events/' + data.id, {
        headers: authHeader()
    }).then(response => {

        return response.data
    }).catch((e) => {
        console.log(e);
    });;
}

const deleteEvents = (data) => {
    return axios.delete(constants.URL + 'api/v1/sport-events/' + data.id, {
            headers: authHeader()
        })
        .then((response) => {
            return response.data
        })
        .catch((e) => {
            console.log(e);
        });
}



const getEventsResults = (data) => {
    return axios.get(constants.URL + 'api/v1/sport-events/' + data.id + '/results', {
        headers: authHeader()
    }).then(response => {
        return response.data
    }).catch((e) => {
        console.log(e);
    });;
}

const getMyEventsResults = () => {
    return axios.post(constants.URL + 'api/v1/sport-events/my-results',{}, {
        headers: authHeader()
    }).then(response => {
        return response.data
    }).catch((e) => {
        console.log(e);
    });;
}


const getConfList = (data) => {
    return axios.get(constants.URL + 'api/v1/meetings/meeting_records.php?id=' + data.id, {
        headers: authHeader()
    }).then(response => {
        return response.data
    }).catch((e) => {
        console.log(e);
    });;
}

const setVoteEvent =(data)=>{
    return axios.put(constants.URL+`api/v1/sport-events/${data.id}/set-estimation`,data,{
    headers: authHeader()
}).then(response => {
    return response.data
}).catch((e) => {
    console.log(e);
});;
}



const getEventsEvaluations =(data)=>{
    return axios.get(constants.URL+`api/v1/users/${data.id}/event-evaluation`,{
    headers: authHeader()
}).then(response => {
    return response.data
}).catch((e) => {
    console.log(e);
});;
}




export default {
    getEvents,
    addEvents,
    editEvents,
    getEventsItem,
    deleteEvents,
    getAllEvents,
    getEventsResults,
    getMyEventsResults,
    getConfList,
    readeEventQr,
    setVoteEvent,
    getEventsEvaluations,
    getCalendarEvents,
    getAllCallendarEvents,
    getDayCallendarEvents,
    readeEventActionQr,
    getMyEvents,
    getNearest
}