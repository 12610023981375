import {
    GETWORKOUTS_SUCCESS,
    GETWORKOUTS_FAIL,
    EDITWORKOUTS_SUCCESS,
    EDITWORKOUTS_FAIL,
    DELETEWORKOUTS_SUCCESS,
    DELETEWORKOUTS_FAIL,
    GETWORKOUTSITEM_SUCCESS,
    GETWORKOUTSITEM_FAIL,
    CREATEWORKOUTSPLAN_SUCCESS,
    CREATEWORKOUTSPLAN_FAIL,
    GETWORKOUTSPLAN_SUCCESS,
    GETWORKOUTSPLAN_FAIL
    
} from "./types";

import WorkoutService from "../services/workout.service"


export const getWorkouts = (data)=> async(dispatch)=>{
        
    try{
        const res = await WorkoutService.getWorkouts(data);
        
        dispatch({
          type: GETWORKOUTS_SUCCESS,
          list: res,
        });
        
        return res
    }catch (error){
        
        dispatch({
            type: GETWORKOUTS_FAIL,
            list: {error: error.response},
          });
          const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
          return error.response
    }
}


export const getWorkoutsPlan = (data)=> async(dispatch)=>{
        
    try{
        const res = await WorkoutService.getWorkoutsPlan(data);
        
        dispatch({
          type: GETWORKOUTSPLAN_SUCCESS,
          list: res,
        });
        
        return res
    }catch (error){
        
        dispatch({
            type: GETWORKOUTSPLAN_FAIL,
            list: {error: error.response},
          });
          const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
          return error.response
    }
}



export const editWorkouts= (form)=>(dispatch)=>{
      
    return WorkoutService.editWorkouts(form).then(
        (response)=>{
              
            dispatch({
                type:EDITWORKOUTS_SUCCESS,
                item:response
            });
            
            return response

        },
        (error)=>{
            dispatch({
                type: EDITWORKOUTS_FAIL,
              });
            console.log(error);
              return Promise.reject();
        }
    );
}



export const deleteWorkouts= (data)=>(dispatch)=>{
      
    return WorkoutService.deleteWorkouts(data).then(
        (response)=>{
            dispatch({
                type:DELETEWORKOUTS_SUCCESS,
            });
              
        },
        (error)=>{
            dispatch({
                type: DELETEWORKOUTS_FAIL,
              });
            console.log(error);
                 
              return Promise.reject();
        }
    );
}

export const getWorkoutsItem = (data)=>(dispatch)=>{
    return WorkoutService.getWorkoutsItem(data).then(
        (response)=>{
              
            dispatch({
                type:GETWORKOUTSITEM_SUCCESS,
                item:response,
            });
              
            return response

        },
        (error)=>{
            dispatch({
                type: GETWORKOUTSITEM_FAIL,
              });
            console.log(error);
                 
              return Promise.reject();
        }
    );

}


export const addWorkouts = (data)=> async(dispatch)=>{
    try {
        const res = await WorkoutService.addWorkouts(data);
        dispatch({
          type: CREATEWORKOUTSPLAN_SUCCESS,
          item: res
        });
        return res
    }catch (error){
        dispatch({
            type: CREATEWORKOUTSPLAN_FAIL,
            item: {error: error.response},
          });
          const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
          return error.response;
    }
}

export const addWorkoutsVideo = (data)=> async(dispatch)=>{
    try {
        const res = await WorkoutService.addWorkoutsVideo(data);
        dispatch({
          type: CREATEWORKOUTSPLAN_SUCCESS,
          item: res
        });
        return res
    }catch (error){
        dispatch({
            type: CREATEWORKOUTSPLAN_FAIL,
            item: {error: error.response},
          });
          const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
          return error.response;
    }
}



export const changeAccess = (data)=> async(dispatch)=>{
    try {
        const res = await WorkoutService.changeAccess(data);
        return res
    }catch (error){
        dispatch({
            type: CREATEWORKOUTSPLAN_FAIL,
            item: {error: error.response},
          });
          const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
          return error.response;
    }
}



export const exitWorkout = (data)=> async(dispatch)=>{
    try {
        const res = await WorkoutService.exitWorkout(data);
        return res
    }catch (error){
        dispatch({
            type: CREATEWORKOUTSPLAN_FAIL,
            item: {error: error.response},
          });
          const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
          return error.response;
    }
}



export const addWorkoutNote = (data)=> async(dispatch)=>{
    try {
        const res = await WorkoutService.addWorkoutNote(data);
        return res
    }catch (error){
        dispatch({
            type: CREATEWORKOUTSPLAN_FAIL,
            item: {error: error.response},
          });
          const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
          return error.response;
    }
}




export const editWorkoutNote = (data)=> async(dispatch)=>{
    try {
        const res = await WorkoutService.editWorkoutNote(data);
        return res
    }catch (error){
        dispatch({
            type: CREATEWORKOUTSPLAN_FAIL,
            item: {error: error.response},
          });
          const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
          return error.response;
    }
}


export const deleteWorkoutNote = (data)=> async(dispatch)=>{
    try {
        const res = await WorkoutService.deleteWorkoutNote(data);
        return res
    }catch (error){
        dispatch({
            type: CREATEWORKOUTSPLAN_FAIL,
            item: {error: error.response},
          });
          const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
          return error.response;
    }
}


export const getWorkoutsItemComments = (data)=>(dispatch)=>{
    return WorkoutService.getWorkoutsItem(data).then(
        (response)=>{
              
            return response

        },
        (error)=>{
            dispatch({
                type: GETWORKOUTSITEM_FAIL,
              });
            console.log(error);
                 
              return Promise.reject();
        }
    );

}

export const setVoteTrainig = (data)=>(dispatch)=>{
    return WorkoutService.setVoteTrainig(data).then(
        (response)=>{
              
            return response

        },
        (error)=>{
            dispatch({
                type: GETWORKOUTSITEM_FAIL,
              });
            console.log(error);
                 
              return Promise.reject();
        }
    );

}




