import { useState, useMemo } from "react";
import { v4 as uuid } from "uuid";

const Select = (props) => {
  const id = uuid();
  const [isOpen, setIsOpen] = useState(false);

  const options = useMemo(() => {
    const arr = props.options;
    if (!arr) return [];
    return arr;
  }, [props.options]);

  return (
    <div
      className={`apDropDownList ${(isOpen && "apFilled") || ""} ${
        props.className ?? ""
      }`}
    >
      <label htmlFor={id}>{props.label}</label>
      <div
        onClick={() => {
          setIsOpen((s) => !s);
        }}
      >
        <input
          id={id}
          value={props.value || props.label}
          type="text"
          readOnly
        />
        <svg>
          <use href="/images/icon24DropDown.svg#icon24" />
        </svg>
      </div>
      {options.length !== 0 && isOpen && (
        <div className="apOptionsList">
          {options.map(({ key, value, id }, index) => (
            <div
              className={(props.value === value && "apActive") || ""}
              id={key||id}
              onClick={(e) => {
                props.onSet(e);
                setIsOpen((s) => !s);
              }}
              key={index}
            >
              {value}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
export default Select;
