import React, { useCallback, useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import FileUploadAndCrop from "../../../components/FileUploadAndCrop/FileUploadAndCrop";
import InfoWindow from "../../../components/InfoWindow/InfoWindow";
import Loader from "../../../components/Loader/Loader";
import { uploadFile } from "../../../redux/actions/file";
import {
  editUserVideo,
  getUserVideoItem,
} from "../../../redux/actions/videoUser";




const InfoVideo = () => {
  const [ready, setReady] = useState(false);
  const [form, setForm] = useState({});
  const { listId } = useSelector((state) => state);
  const { fullList } = useSelector((state) => state);
  const currentId = useParams().id;
  const [select, setSelect] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [alertModal, setAlertModal] = useState(false);

  let options={
    uploadType:'other_photo',
    initialAspectRatio:16 / 10,
    aspectRatio: 16 / 10,
    autoCropArea:16 / 10,
    height:'200px',
    width:'320px',
    size:10,
    type:'mainPhoto',
    dragAbleZone:true,
    text:'відео',
    disabled:(form.statusId === "66e2ec16-03a1-4367-834a-d6b87ee709bd") 
  }
  
   
  const dispatch = useDispatch();
  const getItem = () => {
    setReady(false);
    dispatch(getUserVideoItem({ id: currentId }))
      .then((res) => {
        setForm(res);
        setReady(true);
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const saveEdit = useCallback(
    (data) => {
      dispatch(editUserVideo(data && data.id ? data : form)).then((res) => {});
    },
    [form]
  );

  const changeHandler = (e) => {
    setForm({ ...form, [e.target.id]: e.target.value });
  };
  const selectClick = (e) => {
    if (e.target.id === select) {
      setSelect("");
    } else {
      setSelect(e.target.id);
    }
  };

  const selectChoose = (e) => {
    let data = { ...form, [e.target.accessKey]: e.target.id };
    if (e.target.accessKey === "categoryId") {
      data.subcategoryId = "";
    }
    setForm(data);
    setSelect("");
    saveEdit(data);
  };


  const diffArray = useMemo(() => {
    const arr = fullList?.ExerciseDifficultyLevels || [];
    return new Array(arr[0], arr[2], arr[1], arr[3]);
  }, [fullList]);

  useEffect(() => {
    if (!ready) {
      getItem();
    }
  }, []);

  if (!ready || !fullList.OwnershipTypes || !listId.list) {
    return (
      <div style={{ position: "relative" }}>
        {" "}
        <div style={{ position: "absolute", top: "50%", left: "50%" }}>
          <Loader />
        </div>{" "}
      </div>
    );
  }

  return (
    <>
      <div className="groundPropertiesHeader">
        {alertModal && (
          <InfoWindow
            setModal={setAlertModal}
            message={alertMessage}
          ></InfoWindow>
        )}
        <h2>Візуальна частина</h2>
        <span>(Додану інформацію побачать користувачі)</span>
      </div>
      <div className="videoCard2Columns">
        <div className="videoCardCoverContainer">
          <h3>Обкладинка відеоролика</h3>
         
            
            <FileUploadAndCrop options={options} saveEdit={saveEdit} form={form} setForm={setForm} image={form.mainPhoto} setAlertMessage={setAlertMessage} setAlertModal={setAlertModal} />
         
        </div>
        <div className="videoCardNameDescriptionContainer">
          <h3>Назва та опис</h3>
          <div
            className={
              form.statusId === "66e2ec16-03a1-4367-834a-d6b87ee709bd"
                ? "apInputText apDisabled"
                : `${form.title ? "apInputText apFilled" : "apInputText"}`
            }
          >
            <label htmlFor="title">Назва</label>
            <input
              id="title"
              type="text"
              disabled={
                form.statusId === "66e2ec16-03a1-4367-834a-d6b87ee709bd"
              }
              value={form.title}
              onChange={changeHandler}
              onBlur={saveEdit}
            />
          </div>
          <div
            className={
              form.statusId === "66e2ec16-03a1-4367-834a-d6b87ee709bd"
                ? "apTextarea apDisabled"
                : `${form.description ? "apTextarea apFilled" : "apTextarea"}`
            }
          >
            <label htmlFor="description">Опис</label>
            <textarea
              id="description"
              disabled={
                form.statusId === "66e2ec16-03a1-4367-834a-d6b87ee709bd"
              }
              maxLength="2000"
              value={form.description}
              onChange={changeHandler}
              onBlur={saveEdit}
            />
            <div>
              <span />
              <span>{form.description ? form.description.length : 0}/2000</span>
            </div>
          </div>
        </div>
      </div>
      <div className="groundPropertiesHeader">
        <h2>Налаштування</h2>
      </div>
      <div className="videoCard2ColumnsShort">
        <div className="videoCardSettingsFirstColumn">
          <div className="apDropDownList" onBlur={saveEdit}>
            <label htmlFor="categoryId">Категорія вправ</label>
            <div
              id="categoryId"
              onClick={
                form.statusId === "66e2ec16-03a1-4367-834a-d6b87ee709bd"
                  ? ""
                  : selectClick
              }
            >
              <input
                id="categoryId"
                readOnly
                disabled={
                  form.statusId === "66e2ec16-03a1-4367-834a-d6b87ee709bd"
                }
                type="text"
                value={form.categoryId ? listId.list[form.categoryId] : ""}
              />
              <svg id="categoryId">
                <use href="/images/icon24DropDown.svg#icon24" />
              </svg>
            </div>
            {select === "categoryId" && (
              <div className="apOptionsList">
                {fullList.ExerciseCategories.map((el) => {
                  return (
                    <div
                      id={el.id}
                      key={el.id}
                      accessKey="categoryId"
                      onClick={selectChoose}
                    >
                      {el.title}
                    </div>
                  );
                })}
              </div>
            )}
          </div>
          <div className="apDropDownList" onBlur={saveEdit}>
            <label htmlFor="exerciseDifficultyLevelId">Рівень складності</label>
            <div
              id="exerciseDifficultyLevelId"
              onClick={
                form.statusId === "66e2ec16-03a1-4367-834a-d6b87ee709bd"
                  ? ""
                  : selectClick
              }
            >
              <input
                id="exerciseDifficultyLevelId"
                readOnly
                type="text"
                disabled={
                  form.statusId === "66e2ec16-03a1-4367-834a-d6b87ee709bd"
                }
                value={
                  form.exerciseDifficultyLevelId
                    ? listId.list[form.exerciseDifficultyLevelId]
                    : ""
                }
              />
              <svg id="exerciseDifficultyLevelId">
                <use href="/images/icon24DropDown.svg#icon24" />
              </svg>
            </div>
            {select === "exerciseDifficultyLevelId" && (
              <div className="apOptionsList">
                {diffArray.map((el) => {
                  return (
                    <div
                      id={el.id}
                      key={el.id}
                      accessKey="exerciseDifficultyLevelId"
                      onClick={selectChoose}
                    >
                      {el.title}
                    </div>
                  );
                })}
              </div>
            )}
          </div>
          { form.categoryId==="748b6683-0968-45d0-91ac-893d69525a6e"&&  <div className="apDropDownList" onBlur={saveEdit}>
            <label htmlFor="subcategoryId">Підкатегорія вправ</label>
            <div
              id="subcategoryId"
              onClick={
                form.statusId === "66e2ec16-03a1-4367-834a-d6b87ee709bd"
                  ? ""
                  : selectClick
              }
            >
              <input
                id="subcategoryId"
                readOnly
                disabled={
                  form.statusId === "66e2ec16-03a1-4367-834a-d6b87ee709bd"
                }
                type="text"
                value={
                  form.subcategoryId ? listId.list[form.subcategoryId] : ""
                }
              />
              <svg id="subcategoryId">
                <use href="/images/icon24DropDown.svg#icon24" />
              </svg>
            </div>
            {select === "subcategoryId" && (
              <div className="apOptionsList">
                {fullList.ExerciseSubcategories.map((el) => {
                  if (el.parentId === form.categoryId) {
                    return (
                      <div
                        id={el.id}
                        key={el.id}
                        accessKey="subcategoryId"
                        onClick={selectChoose}
                      >
                        {el.title}
                      </div>
                    );
                  }
                })}
              </div>
            )}
          </div>}
        </div>
        {/* <div className="videoCardSettingsRegions">
          <div className="profilePersonalDataHeaderWithButton">
            <h3>Регіони показу</h3>
            {form.statusId!=="66e2ec16-03a1-4367-834a-d6b87ee709bd"&& <button className="secondaryButtonIconDefault" onClick={form.statusId==="66e2ec16-03a1-4367-834a-d6b87ee709bd"?'':()=>setModal(true)}>
            <svg><use href="/images/icon24Edit.svg#icon24"></use></svg>
            </button>}
          </div>
          {form && form.regions && form.regions.length>0?form.regions.map(el=>{
            return(
              <span>{listId.list[el]}</span>
            )
          }):"Оберіть регіони зі списку"}
          </div> */}
      </div>
    </>
  );
};

export default InfoVideo;
