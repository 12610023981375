import {
    useMemo,
} from "react";

export const useResponceChecker = (responce) => {
    const {
        msg,
        errorFiled,
        isError,
        isServerError
    } = useMemo(() => {
        let msg = null;
        let errorFiled = undefined;
        let isError = false;
        let isServerError = false;

        if (responce?.isFeild) {
            msg = responce?.msg;
            errorFiled = responce?.param;
            isError = true
            if (!responce.msg) {
                isServerError = true;
                msg = 'Сталася помилка, оновіть , будь ласка, сторінку'
            }
        } else {
            isError = false

        }
        return {
            msg,
            errorFiled,
            isError,
            isServerError
        };

    }, [responce]);

    return {
        errorFiled,
        msg,
        isError,
        isServerError
    }
}