import { Contacts } from "./Contacts";
import { ReactComponent as Calendar } from "../../../assets/images/calendar.svg";
import useCurrentMediaType from "../../../hooks/currentMediaType";
import { Status } from "../../../components/Status";
import getDate from "../../../helpers/getDate";

export const FcHeader = (props) => {
  const { isCurrentSize: isMobile } = useCurrentMediaType(775);
  return (
    <div className="apFcHeader">
      {props.title && (
        <div><h2>
          {props.title}
          <span>{props.slogan}</span>
          {props.statusId !== "e19aa583-d4c7-476c-8be7-37fa5f5710f6" && (
            <Status type={"club"} statusId={props.statusId} />
          )}
        </h2></div>
      )}
      <Contacts
        logo={props.logo}
        creationDate={props.creationDate}
        participants={props.participants}
        links={props.links}
        tel={props.tel}
      />
      {!isMobile && (
        <div className="apFcEventStatusContainer"><div className={`apFcEvent ${(!props.event && "empty") || ""}`}>
          {(props.event !== null && (
            <>
              <Calendar />
              {props.event.title}
              <span> {getDate(props.event.date, "dd/mmmm/yyyy hh:mm",' ')}
              </span>
            </>
          )) || <>Немає активних заходів</>}
        </div></div>
      )}
    </div>
  );
};
