import React, { useEffect, useMemo, useRef, useState } from "react";
import { MapContainer, TileLayer, Marker, useMap } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import axios from "axios";
import L from "leaflet";
import { Icon } from "../../../components/Icon";
import { useSelector } from "react-redux";
import MyLocationMapButton from "../../../components/MyLocation/MyLocationMapButton";

const markerIcon = new L.Icon({
  iconUrl: require("../../../assets/images/map_icon_mini.png"),
  // iconSize: [24, 24],
  iconAnchor: [12, 24], //[left/right, top/bottom]
  popupAnchor: [0, 0], //[left/right, top/bottom]
});

const POSITION_CLASSES = {
  bottomleft: "leaflet-bottom leaflet-left",
  bottomright: "leaflet-bottom leaflet-right",
  topleft: "leaflet-top leaflet-left",
  topright: "leaflet-top leaflet-right",
  top: "leaflet-top leaflet-center",
};

const markerIconMy = new L.Icon({
  iconUrl: require("../../../assets/images/myLocation.png"),
  iconSize: [24, 24],
  iconAnchor: [12, 13], //[left/right, top/bottom]
  popupAnchor: [0, 0], //[left/right, top/bottom]
});

const Map = (props) => {
  const [mapArr, setMappArr] = useState([]);
  const coordinates =useSelector(state=>state)
  const updateArr = () => {
    setMappArr(props.arrAll);
  };
  const changeArr = (ar) => {
    props.updateArr(ar);
    setMappArr([]);
  };

  useEffect(() => {
    updateArr();
  }, [props.arrAll[0],mapArr]);

  return (
    <>
      <MapContainer
        style={{ height: 600, width: "100%" }}
        center={ props.arrAll.length > 0 && props.arrAll[0]?.location[0] && props.arrAll[0]?.location[1]
          ? [props.arrAll[0]?.location[0], props.arrAll[0]?.location[1]]
          : coordinates.lat?[coordinates.lat,coordinates.lng] :[50.4393, 30.5479]}
        zoom={15}
        scrollWheelZoom={true}
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://openstreetmap.pp.ua/hot/{z}/{x}/{y}.png"
        />
        {!props.disabled && <CurrentPosition
          mapArr={mapArr}
          setArr={changeArr}
          position="topright"
        />}
        {!props.disabled && <SearchTab 
          setArr={changeArr}
          position="top"
        />}
        {mapArr?.length > 0 &&
          mapArr.map((el, index) => {
            return (
            <>
              <DraggableMarker
                location={[el.location[0], el.location[1]]}
                icon={el.type === 0 ? markerIcon : markerIconMy}
                index={index}
                mapArr={mapArr}
                disabled={props.disabled}
                changeArr={changeArr}
              ></DraggableMarker>
               
            </>
            );
          })}
           <MyLocationMapButton position='bottomright'/>
      </MapContainer>
    </>
  );
};



function SearchTab(props){
  const [searchList, setSearchList] = useState([]);
  const map = useMap();
  const [searchOpen, setSearchOpen] = useState(false);
  const [city, setCity] = useState("");

  const cityChangeSelector = (e) => {
    setSearchOpen(true);
    setCity(e.target.value);
    if (e.target.value.length > 2) {
      axios
      .get(`https://map.technodreams.biz/search.php?q=${e.target.value}&accept-language=ua`)
      .then((res) => {
          setSearchList(res.data);
        });
    }
  }

  const setSearchListClick = (e) => {
    setSearchOpen(false);
    let a={lat:e.target.id.split("/")[0],lng:e.target.id.split("/")[1]}
    map.setView([a.lat, a.lng]);
    let resArr = [];
    let resObj = { type: 0 };
    resObj.location = [a.lat, a.lng];
    resObj.pointIndex = 0;
    resArr.push(resObj);
    props.setArr(resArr);
  };
  const positionClass =
  (props.position && POSITION_CLASSES[props.position]) ||
  POSITION_CLASSES.top;

  return (
    <div className={positionClass + " apAdmitPointList"}>
          <div className="leaflet-control leaflet-bar headline apInputText" style={{ position: "relative" }}>
            <label htmlFor="city">Пошук адреси</label>
            <input
              className="searchMapTabInput"
              onChange={cityChangeSelector}
              value={city}
              id="city"
              type="text"
            />
            {
              <div className="apOptionsList searchAdress searchMapTabList ">
                {searchList &&
                  searchList.length > 0 &&
                  searchList.map((el, index) => {
                    if(el.display_name.includes("Россия") || el.display_name.includes("Россія")){
                      return;
                    }else{
                      return (
                        <div
                          key={index}
                          id={el.lat + "/" + el.lon}
                          onClick={setSearchListClick}
                        >
                          {el.display_name}
                        </div>
                      );
                    }
                  })}
              </div>
            }
          </div>
        </div>
  )
}



function CurrentPosition(props) {
  const map = useMap();
  const clickEvent = () => {
    let a = map.getCenter();
    let resArr = [];
    let resObj = { type: 0 };
    resObj.location = [a.lat, a.lng];
    resObj.pointIndex = 0;
    resArr.push(resObj);
    props.setArr(resArr);
  };
  const positionClass =
    (props.position && POSITION_CLASSES[props.position]) ||
    POSITION_CLASSES.top;
  return (
    <div className={positionClass + " apAdmitPointList"}>
      <div className="leaflet-control leaflet-bar headline">
        <button onClick={clickEvent}>
          <Icon type={"plus"} /> Вибрати місце проведення
        </button>
      </div>
    </div>
  );
}

function DraggableMarker(props) {
  const [location, setLocation] = useState(props.location);
  const markerRef = useRef(null);
  const eventHandlers = useMemo(
    () => ({
      dragend() {
        const marker = markerRef.current;
        if (marker != null) {
          let newArr = props.mapArr;
          newArr[0].location = [marker.getLatLng().lat, marker.getLatLng().lng];
          props.changeArr(newArr);
          setLocation(marker.getLatLng());
        }
      },
    }),
    [props.mapArr]
  );

  return (
    <Marker
      draggable={!props.disabled}
      eventHandlers={eventHandlers}
      position={location}
      icon={props.icon}
      ref={markerRef}
    ></Marker>
  );
}

export default Map;


