import {
  GETNOTIFICATIONS_SUCCESS,
  GETNOTIFICATIONS_FAIL,

} from "../actions/types";

const initialState = [];

export default function notificationsReducer(notidications = initialState,action){
  const {list,type} = action;

  switch(type) {
      case GETNOTIFICATIONS_SUCCESS:
          return {...list};
      case GETNOTIFICATIONS_FAIL:
          return notidications;
      default:
          return notidications;
  }

}