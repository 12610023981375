

export const publicMenuListItems=[
    {
        text: "Рухові активності",
        to: "/mainpage",
        role: [],
    },
    {
        text: "Заходи",
        to: "/eventsCalendar",
        role: [],
    },
    {
        text: "Блог",
        to: "/newspage",
        role: [],
    },
    {
        text: "Локації",
        to: "/map",
        role: [],
    },
]

export const menuListItems = [
    {
        text: "Клуби",
        to: "/fcList",
        role: [],
        isHidden:true,
    },
    {
        text: "Мої об'єкти",
        to: "/parklistpage",
        role: [
            "62fe0318-c64a-490c-859d-9d313eacbf41",
            "460553c9-3c42-443c-afb3-24308587fe62",
            "66d22cb4-2cf9-4aa7-806b-c50b50f27020",
            "09efbeb2-f45a-418d-89b0-b2a4c37f6122",
            "631db81f-fa07-42e2-b394-062c9be66b09",
        ],
    },
    {
        text: "Відео користувачів",
        to: "/videoUserCatalog",
        role: [
            "631db81f-fa07-42e2-b394-062c9be66b09",
            "e9789dde-94a9-4ad3-88e8-374ec41f8c1a",
        ],
    },
    {
        text: "Відеокаталог",
        to: "/videocatalog",
        role: [
            "2f5a84bc-52b5-4777-8790-e2c32daa6a34",
            "3fbea050-7049-4682-92f6-15c2bad91972",
            "631db81f-fa07-42e2-b394-062c9be66b09",
        ],
    },
   
    {
        text: "Підтримка",
        to: "/supportlist",
        role: [
            '48633388-3277-4c7b-8d39-d421e9e90599',
            "631db81f-fa07-42e2-b394-062c9be66b09",
            "9952ac5a-fd43-4e8f-86ff-2bf9cb2a7804",
        ],
    },
    {
        text: "Блог Адмін",
        to: "/news",
        role: [
            "631db81f-fa07-42e2-b394-062c9be66b09",
            "9952ac5a-fd43-4e8f-86ff-2bf9cb2a7804",
        ],
    },
    {
        text: "Каталог заходів",
        to: "/events",
        role: [
            "631db81f-fa07-42e2-b394-062c9be66b09",
            "09efbeb2-f45a-418d-89b0-b2a4c37f6122",
            "5dcf0363-d171-45db-9280-cb337ca5e101",
        ],
    },
    {
        text: "Користувачі",
        to: "/users",
        role: ["631db81f-fa07-42e2-b394-062c9be66b09"],
    },
    {
        text: "Бібліотека треків",
        to: "/tracklibrary",
        role: ["631db81f-fa07-42e2-b394-062c9be66b09"],
        isTrainer:true
    },
    {
        text: "Бібліотека маршрутів",
        to: "/routelibrary",
        role: ["631db81f-fa07-42e2-b394-062c9be66b09","09efbeb2-f45a-418d-89b0-b2a4c37f6122"],
        isTrainer:true
    },
    {
        text: "Звіти",
        to: "/reports",
        role: [
            "09efbeb2-f45a-418d-89b0-b2a4c37f6122"
        ],
    },
    {
        text: "Звіти Координаторів",
        to: "/usersReportsList",
        role: ["631db81f-fa07-42e2-b394-062c9be66b09"
        ],
        // isTrainer:true
    },
    {
        text: "Рейтинг користувачів",
        to: "/reportsList",
        role: ["631db81f-fa07-42e2-b394-062c9be66b09"
        ],
        // isTrainer:true
    },
    
    {
        text: "Довідник",
        to: "/directory",
        role: ["631db81f-fa07-42e2-b394-062c9be66b09"],
    },
    {
        text: "Журнал подій",
        to: "/log",
        role: ["631db81f-fa07-42e2-b394-062c9be66b09"],
    },
    {
        text: "База знань",
        to: "/infoelistedit",
        role: [
            "631db81f-fa07-42e2-b394-062c9be66b09",
            "9952ac5a-fd43-4e8f-86ff-2bf9cb2a7804",
        ],
    },

];