import { useState, useMemo, useEffect, useRef } from "react";

const Collapse = (props) => {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(true);
  const [elHeight, setElHeight] = useState(0);

  useEffect(() => {
    const el = ref.current.clientHeight;
    if (isOpen) {
      setElHeight(el);
      return;
    }
    setElHeight(0);
  }, [isOpen, ref]);

  const handleOpen = () => {
    setIsOpen((s) => !s);
  };

  const button = useMemo(() => {
    if (props.button.isOpen && isOpen) return props.button.isOpen;
    return props.button.inner;
  }, [props.button.isOpen, isOpen]);

  return (
    <div className="apCollapseContainer">
      <button
        onClick={() => handleOpen()}
        className={`${isOpen && "isOpen" || ""} ${
          props.button.className || "apCollapseButton"
        }`}
      >
        {button}
      </button>
      <div
        style={{ maxHeight: `${elHeight}px` }}
        className={`apCollapseInner ${(isOpen && "isOpen") || ""}`}
      >
        <div ref={ref}>{props.children}</div>
      </div>
    </div>
  );
};

export default Collapse;
