import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { Icon } from "../../../components/Icon";
import { getTrackItem } from "../../../redux/actions/track";
import Item from "./Item/Item";
import Map from "./Map/Map";

const MapBlock =(props)=>{
    const [arrAll, setArr] = useState(props.form?.pointsTrack ||[]);
    const [selectedTo, setSelectedTo] = useState('');
    const [selected, setSelected] = useState({});
    const [selectedFrom, setSelectedFrom] = useState('');
    const [modalOpen, setModalOpen]=useState(false);
    const changeHandler=(e)=>{
        if(e.target.id=='selectedTo'){
            setSelectedTo(e.target.value);
        }else {
            setSelectedFrom(e.target.value);
        }
    }
    console.log(props.selected);
    const onChangeTurn=(index,turn)=>{
        const newArr = [...arrAll];
        newArr[index].turn=turn;
        setArr(newArr);
        let form = props.form;
        form.pointsTrack = newArr;
        props.setForm(form);
    }
    const onChangeTurnList=(turn)=>{
        const newArr = [...arrAll];
        for(let i = selectedFrom-1; i<selectedTo; i++){
            newArr[i].turn=turn;
        }
        setArr(newArr);
        let form = props.form;
        form.pointsTrack = newArr;
        props.setForm(form);
    }
    const heandleRemovePoint = (index) => {
        const newArr = [...arrAll.slice(0, index), ...arrAll.slice(index + 1)];
        let newArr2=[]
        newArr.map((el,index)=>{
          let data=el;
          data.pointIndex=index
          newArr2.push(data)
        })
        setArr(newArr2);
        let form = props.form;
        form.pointsTrack = newArr2;
        props.setForm(form)
        // editEventItem(newArr2);
    };
    const heandleRemovePointList = () => {
        const newArr = [...arrAll.slice(0, selectedFrom-1), ...arrAll.slice(selectedTo)];
        setSelectedFrom('');
        setSelectedTo('');
        let newArr2=[]
        newArr.map((el,index)=>{
          let data=el;
          data.pointIndex=index
          newArr2.push(data)
        })
        setArr(newArr2);
        let form = props.form;
        form.pointsTrack = newArr2;
        props.setForm(form)
        // editEventItem(newArr2);
    };



    return(
        <>

            <h2>Мітки треку</h2>
            <div className="apItineraryAdmin" style={{paddingTop:'0'}}>
            <Map selected={selected}  selectedFrom={selectedFrom} selectedTo={selectedTo} el={arrAll}></Map>
            <div className="apAdmitPointList apTableList">
                <div style={{display:'flex', gap:'10px', position:'relative'}}>
                    <div
                        className={"apInputText"}
                        style={{width:'30%'}}
                    >
                        <label htmlFor="selectedFrom">Від </label>
                        <input
                            id="selectedFrom"
                            type="text"
                            placeholder="Номер точки"
                            maxLength={10}
                            value={selectedFrom?selectedFrom:''}
                            onChange={changeHandler}
                        />
                    </div>
                    <div
                        className={ "apInputText"}
                        style={{width:'30%'}}
                    >
                        <label htmlFor="selectedTo">До</label>
                        <input
                            id="selectedTo"
                            type="text"
                            placeholder="Номер точки"
                            maxLength={10}
                            value={selectedTo?selectedTo:''}
                            onChange={changeHandler}
                        />
                    </div>
                    <button
                        className="iconButtonTrack"
                        onClick={()=>setModalOpen(!modalOpen)} 
                        disabled={!selectedFrom|| !selectedTo}
                    >
                        <Icon type={"turn"}></Icon>
                    </button>
                    {modalOpen && 
                        <div className="modalwithButtonsMap">
                            <div><Icon className={"cross"} type={"cross"} onClick={()=>{setModalOpen(!modalOpen);onChangeTurnList('')}} /></div>
                            <div><Icon className={"cross"} type={"turnLeft"} onClick={()=>{setModalOpen(!modalOpen);onChangeTurnList('left')}} /></div>
                            <div><Icon className={"cross"} type={"turn"} onClick={()=>{setModalOpen(!modalOpen);onChangeTurnList('up')}} /></div>
                            <div><Icon className={"cross"} type={"turnRight"} onClick={()=>{setModalOpen(!modalOpen);onChangeTurnList('right')}} /></div>
                        </div>}
                    <button
                        className="iconButtonTrack"
                        onClick={heandleRemovePointList}
                        disabled={!selectedFrom|| !selectedTo}
                    >
                        <Icon type={"Trash"}></Icon>
                    </button>

                </div>
            <div className="apFcEventsAdmitPoints" style={{height:'360px', marginBottom:'32px'}}>
                { arrAll?.map((item, index) =>{
                        return(
                        <Item
                        key={index+item.latitude+item.longitude}
                        index={index}
                        item={item}
                        selectedFrom={selectedFrom}
                        selectedTo={selectedTo}
                        value={item}
                        onChangeTurn={onChangeTurn}
                        onDelete={heandleRemovePoint}
                        setSelected={setSelected}
                        selected={selected}
                        type={item?.type}
                        />
                    )})
                }
                </div>
            </div>
            </div>
            
        </>
    )
}

export default MapBlock;