import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { useParams } from "react-router-dom";
import Loader from "../../components/Loader/Loader";
import Filter from "./Filter/Filter";
import Pagination from "../../components/Paggination/Paggination";
import {
  addParkCoordinatorsApprove,
  addParkCoordinatorsId,
  getParksCoordinatorsAll,
} from "../../redux/actions/parkCoordinators";
import Select from "../../components/Select/";
import setPhoneMask from "../../helpers/setPhoneMask";

const sortOtions = [
  { id: "publishedAt", value: "За датою опублікування" },
  { id: "title", value: "За абеткою" },
];

const AddCoordinatorPage = () => {
  const [select, setSelect] = useState(false);
  const [selected, setSelected] = useState("");
  const [ready, setReady] = useState(false);
  const navigate = useNavigate();
  const [searchOpen, setSearchOpen] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [intervalId,setIntervalId] = useState(null);  
  const [sortData, setSortData] = useState("updatedAt");
  const [sortName, setSortName] = useState("За датою редагування");
  const [sortValue, setSortValue] = useState("desc");
  const dispatch = useDispatch();
  const currentId = useParams().id;
  const { users } = useSelector((state) => state);
  const { parksCoordinators } = useSelector((state) => state);
  const { listId } = useSelector((state) => state);
  const [filter, setFilter] = useState(false);
  const [filterArr, setFilterArr] = useState({});
  const [currentPage, setCurrentPage] = useState(1);

  const resetFilter = () => {
    localStorage.removeItem("coordinatorsFilter");
    setFilterArr({});
    initFunction();
  };
  const addSave = () => {
    let data = { id: currentId, userId: selected };
    setReady(false);
    dispatch(addParkCoordinatorsId(data)).then((res) => {
      dispatch(addParkCoordinatorsApprove(data)).then((res) => {
        navigate(`/sportsground/${currentId}/coordinators`);
      });
    });
  };
  const searchChange = (e) => {
    let filter = {};
    setSearchValue(e.target.value);
    if (localStorage.getItem("coordinatorsFilter")) {
      filter = JSON.parse(localStorage.getItem("coordinatorsFilter"));
    }
    filter.all = [];


        clearTimeout(intervalId);
    const newIntervalId = setTimeout(() =>{
      // Call your search function with the updated query value
    
      if (e.target.value.length > 2) {
        filter.all[0] = e.target.value;
        localStorage.setItem("coordinatorsFilter", JSON.stringify(filter));
        initFunction(1, filter);
      } else {
        localStorage.setItem("coordinatorsFilter", JSON.stringify(filter));
        initFunction(1, filter);
      }
    }, 1000)
    setIntervalId(newIntervalId);  ;


  };
  const selectedClick = (e) => {
    if (e.target.id) {
      if (selected === e.target.id) {
        setSelected("");
      } else {
        setSelected(e.target.id);
      }
    } else if (e.target.parentElement.id) {
      if (selected === e.target.parentElement.id) {
        setSelected("");
      } else {
        setSelected(e.target.parentElement.id);
      }
    } else {
      if (selected === e.target.parentElement.parentElement.id) {
        setSelected("");
      } else {
        setSelected(e.target.parentElement.parentElement.id);
      }
    }
  };
  const initFunction = (page = 1, filter) => {
    let data = {};
    if (filter) {
      data.filter = { ...filter };
    } else {
      if (localStorage.getItem("coordinatorsFilter")) {
        data.filter = {
          ...JSON.parse(localStorage.getItem("coordinatorsFilter")),
        };
      } else {
        data.filter = {};
      }
    }
    if (localStorage.getItem("coordinatorsSort")) {
      data.sort = { ...JSON.parse(localStorage.getItem("coordinatorsSort")) };
    } else {
      data.sort = { updatedAt: "asc" };
    }
    data.offset = (page - 1) * 10;
    data.limit = 10;
    data.id = currentId;
    dispatch(getParksCoordinatorsAll(data)).then((res) => {
      setReady(true);
    });
  };
  const sortClick = (e) => {
    setSortName(e.target.innerHTML);
    const currentIdIndex = sortOtions.findIndex(
      (x) => x.value === e.target.innerHTML
    );
    setSortData(sortOtions[currentIdIndex].id);
    let data = {};
    if (sortData === sortOtions[currentIdIndex].id && sortValue === "asc") {
      setSortValue("desc");
      data[sortOtions[currentIdIndex].id] = "desc";
    } else {
      data[sortOtions[currentIdIndex].id] = "asc";
      setSortValue("asc");
    }
    localStorage.setItem("coordinatorsSort", JSON.stringify(data));
    initFunction();
    setSelect(false);
  };

  useEffect(() => {
    if (localStorage.getItem("coordinatorsFilter")) {
      let filter = JSON.parse(localStorage.getItem("coordinatorsFilter"));
      initFunction(1, filter);
      setFilterArr(filter);
    } else {
      initFunction();
    }
  }, []);

  if (!ready || !listId.list) {
    return (
      <div style={{ position: "relative" }}>
        {" "}
        <div style={{ position: "absolute", top: "50%", left: "50%" }}>
          <Loader />
        </div>{" "}
      </div>
    );
  }
  if (ready && listId.list) {
    return (
      <>
        {filter && (
          <Filter setModal={setFilter} setFilter={setFilterArr}></Filter>
        )}
        <div className="headerWithButtonsCommonBlock">
          <div className="headerWithButtonsHeaderBlock">
            <h1>Додати координатора</h1>
          </div>
          <div className="headerWithButtonsButtonBlock">
            {searchOpen && (
              <div style={{ marginBottom: "4px" }} className="apInputText">
                <label htmlFor="search">Пошук</label>
                <input
                  id="search"
                  type="text"
                  value={searchValue}
                  placeholder="Мінімум 3 символи"
                  onChange={searchChange}
                />
              </div>
            )}
            <button
              className="secondaryButtonIconDefault"
              onClick={() => setSearchOpen(!searchOpen)}
            >
              <svg>
                <use href="/images/icon24Search.svg#icon24" />
              </svg>
            </button>
          </div>
        </div>
        <div className="menuFunctionLine">
          <div className="actionButtonsBlock">
            <button
              className="secondaryButtonIconTextDefault"
              onClick={() =>
                navigate(`/sportsground/${currentId}/coordinators`)
              }
            >
              <svg>
                <use href="/images/icon24ArrowLeft.svg#icon24" />
              </svg>
              Назад
            </button>
            {((parksCoordinators &&
              parksCoordinators.items &&
              parksCoordinators.items.length > 0) ||
              filterArr) && (
              <>
                <button
                  className={
                    selected
                      ? "primaryButtonIconTextDefault"
                      : "primaryButtonIconTextDisabled"
                  }
                  disabled={!selected}
                  onClick={addSave}
                >
                  <svg>
                    <use href="/images/icon24UserPlus.svg#icon24" />
                  </svg>
                  Додати координатора
                </button>
                <div className="filterBlock">
                  <button
                    className="secondaryButtonIconTextDefault"
                    onClick={() => setFilter(true)}
                  >
                    <svg>
                      <use href="/images/icon24Filter.svg#icon24" />
                    </svg>
                    Фільтр
                  </button>
                  <span>Знайдено {users.total} осіб:</span>
                  {filterArr &&
                    Object.keys(filterArr).length > 0 &&
                    Object.keys(filterArr).map((el) => {
                      if (el === "roleId") {
                      } else {
                        return (
                          filterArr[el].length > 0 &&
                          filterArr[el].map((elem, index) => {
                            return (
                              <div key={index} className="apChips">
                                {listId.list[elem]}
                                {/* <svg><use href="/images/icon24CancelSmall.svg#icon24" /></svg> */}
                              </div>
                            );
                          })
                        );
                      }
                    })}
                  {filterArr && Object.keys(filterArr).length > 0 && (
                    <button
                      onClick={resetFilter}
                      className="secondarySmallRoundedButtonTextDefault"
                    >
                      Скинути
                    </button>
                  )}
                </div>
              </>
            )}
          </div>
          <div className="sortBlock">
            <Select
              label={"Сортування:"}
              value={sortName}
              options={sortOtions}
              onSet={sortClick}
            />
          </div>
        </div>
        <div className="pageContentHeaderDivider" />
        <div className="dictAllRowsContainer">
          {parksCoordinators &&
          parksCoordinators.items &&
          parksCoordinators.items.length > 0 ? (
            parksCoordinators.items.map((el) => {
              return (
                <div
                  className="usersRowNormal"
                  id={el.id}
                  onClick={selectedClick}
                >
                  <div className="userValue" id={el.id}>
                    {el.photo ? (
                      <div
                        className="userValueImage"
                        style={{
                          background: `url(${el.photo})`,
                          "background-size": "cover",
                          "background-position": "center",
                        }}
                      ></div>
                    ) : (
                      <div className="userValueImage">
                        {el.nickname[0] &&
                          el.nickname[0].toUpperCase() +
                            el.nickname[1].toUpperCase()}
                      </div>
                    )}
                    <div className="userValueName">
                      <span>
                        {el.firstName && el.lastName
                          ? el.firstName + " " +  el.lastName 
                          : el.nickname}
                      </span>
                      {listId.list[el.roleId]}
                    </div>
                  </div>
                  <div className="userValue" id={el.id}>
                    <span> {listId.list[el.regionId]}</span>
                    <span> {listId.list[el.districtId]}</span>
                    <span>{el.city}</span>
                  </div>
                  <div className="userValue" id={el.id}>
                    <span className="userValueNumbers">{el.updatedAt}</span>
                    Змінено
                  </div>
                  <div className="userValue" id={el.id}>
                    <span className="userValueNumbers">{setPhoneMask(el.phone)}</span>
                    <span className="userValueEmail">{el.email}</span>
                  </div>
                  <div
                    className="userValue"
                    id={el.id}
                    style={{ "justify-content": "flex-end" }}
                  >
                    {
                      <svg
                        style={{
                          fill: `${
                            selected === el.id ? "" : "var(--apColorDarkGray2)"
                          }`,
                        }}
                      >
                        <use href="/images/icon24CheckCircle.svg#icon24"></use>
                      </svg>
                    }
                  </div>
                </div>
              );
            })
          ) : (
            <h3 style={{ "margin-left": "35px" }}>
              Жодного Вільного Координатора
            </h3>
          )}
        </div>
        {parksCoordinators.total > 10 && (
          <Pagination
            className="pagination-bar"
            currentPage={currentPage}
            totalCount={parksCoordinators.total.toFixed(0)}
            pageSize="10"
            onPageChange={async (page) => {
              await setCurrentPage(page);
              await initFunction(page);
            }}
          />
        )}
      </>
    );
  }
};

export default AddCoordinatorPage;
