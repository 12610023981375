import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  MapContainer,
  TileLayer,
  Marker,
  useMap,
  Polyline,
} from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import axios from "axios";
import { useSelector } from "react-redux";
import { Icon } from "../../../../components/Icon";

const markerIconType0 = new L.Icon({
  iconUrl: require("../../../../assets/images/material-symbols_location-on.png"),
  iconSize: [24, 24],
  iconAnchor: [11, 22], //[left/right, top/bottom]
  popupAnchor: [0, 0], //[left/right, top/bottom]
});

const markerIconType1 = new L.Icon({
  iconUrl: require("../../../../assets/images/material-symbols_location-on-1.png"),
  iconSize: [24, 24],
  iconAnchor: [11, 22], //[left/right, top/bottom]
  popupAnchor: [0, 0], //[left/right, top/bottom]
});

const markerIconType2 = new L.Icon({
  iconUrl: require("../../../../assets/images/material-symbols_location-on-2.png"),
  iconSize: [24, 24],
  iconAnchor: [11, 22], //[left/right, top/bottom]
  popupAnchor: [0, 0], //[left/right, top/bottom]
});

const markerIconType3 = new L.Icon({
  iconUrl: require("../../../../assets/images/material-symbols_location-on-3.png"),
  iconSize: [24, 24],
  iconAnchor: [11, 22], //[left/right, top/bottom]
  popupAnchor: [0, 0], //[left/right, top/bottom]
});


const POSITION_CLASSES = {
  bottomleft: "leaflet-bottom leaflet-left",
  bottomright: "leaflet-bottom leaflet-right",
  topleft: "leaflet-top leaflet-left",
  topright: "leaflet-top leaflet-right",
  top: "leaflet-top leaflet-center",
};
const markerIconCus = (index) => {
  return L.divIcon({
    html: `<div class="marker-icon">${index}</div>`,
    // iconSize: [36, 36],
    iconAnchor: [6, 22],
  });
};


const markerIconMy = new L.Icon({
  iconUrl: require("../../../../assets/images/myLocation.png"),
  iconSize: [24, 24],
  iconAnchor: [12, 13], //[left/right, top/bottom]
  popupAnchor: [0, 0], //[left/right, top/bottom]
});

const markerIconPoint = new L.Icon({
  iconUrl: require("../../../../assets/images/point.png"),
  iconSize: [1, 1],
  iconAnchor: [6, 7], //[left/right, top/bottom]
  popupAnchor: [0, 0], //[left/right, top/bottom]
});

const Map = (props) => {
  const [mapArr, setMappArr] = useState([]);
  const {coordinates} =useSelector(state=>state);
  const mapRef = useRef(null);


  const changeArr = (ar) => {
    props.updateArr([...ar]);
    setMappArr([...ar]);
  };
  const redOptions = { color: "red" };

  const connectDots=(data)=> {
    // let c = [[50.4393, 30.5479],[51.4393, 31.5479]];
    let c = [];
    let i;

    for (i = 0; i < data?.length; i += 1) {
      if(data[i]?.latitude){
        let coords = [data[i].latitude,data[i].longitude];
        c.push(coords);
      }
    }
    return c;
  }

  return (
    <>
      <MapContainer
        style={{ height: props.hight || 360, width: "100%" }}
        ref={mapRef}
        center={ 
          props.el?.length>0 && props.el[0].latitude ? [props.el[0].latitude,props.el[0].longitude]:[50.4393, 30.5479]}
        zoom={15}
        scrollWheelZoom={true}
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://openstreetmap.pp.ua/hot/{z}/{x}/{y}.png"
        />
        <MyPolyline coordinates={connectDots(props.el)} />
        <Polyline pathOptions={redOptions} positions={connectDots(props.el)} />
        { props.el?.length > 0 &&
           props.el.map((el, index) => {
            return (
              <div key={index}>
               {/* <Marker
                position={[el.latitude, el.longitude]}
                // icon={markerIconCus(index+1)}
              /> */}
              <DraggableMarker
                location={[el.latitude, el.longitude]}
                icon={props.selected===index+''?markerIconType2:index==0?markerIconType1:index==props.el?.length-1?markerIconType3: markerIconPoint}
                index={index}
                selected={props.selected==index}
                mapArr={props.arrAll}
                changeArr={changeArr}
              ></DraggableMarker>
              </div>
            );
          })}

      </MapContainer>
    </>
  );
};

function MyPolyline({ coordinates }) {
  const map = useMap();
  useEffect(() => {
    if (coordinates.length > 0) {
      const polyline = L.polyline(coordinates, { color: 'green' }).addTo(map);
      const bounds = polyline.getBounds();
      map.fitBounds(bounds);
    }
  }, [coordinates, map]);

  return null;
}

function DraggableMarker(props) {
  const [location, setLocation] = useState(props.location);
  const markerRef = useRef(null);

    useEffect(()=>{
      if(props.location!==location){
        setLocation(props.location)
      }
    },[props.location,location])

  return (
    <Marker
      draggable={false}
      position={location}
      icon={props.icon}
      ref={markerRef}
    ></Marker>
  );
}





export default Map;
