import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import Loader from "../../components/Loader/Loader";
import MessageItem from "../../components/MessageItem/MessageItem";
import getDate from "../../helpers/getDate";
import {
  addSupport,
  getSupportItem,
  sendSupportMessage,
} from "../../redux/actions/support";



const SupportItemUserPage = () => {
  const [ready, setReady] = useState(false);
  const dispatch = useDispatch();
  const currentId = useParams().id;
  const navigate = useNavigate();
  const [form, setForm] = useState({});
  const [userForm, setUserForm] = useState({});
  const { listId } = useSelector((state) => state);
  const { currentUser } = useSelector((state) => state.currentUser);
  const [isActive, setIsActive] = useState(false);
  const changeStatus = (e) => {
    let data = {};
    data.type = e.target.accessKey;
    data.id = currentId;
    dispatch(addSupport(data)).then((res) => {
      initFunc();
    });
  };

  const initFunc = () => {
    dispatch(getSupportItem({ id: currentId })).then((res) => {
      setForm(res);
      setReady(true);
    });
  };

  // const changeHandler = (e) => {
  //   setUserForm({ ...userForm, [e.target.id]: e.target.value });
  // };


  const changeHandler = (e) => {
    setUserForm({ ...userForm, [e.target.id]: e.target.value });
  };
  
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      setUserForm({ ...userForm, [e.target.id]: userForm.text + "\n" });
    }
  };


  const sendMessage = (e) => {
    let data = {};
    data.id = currentId;
    data.text = userForm.text;
    dispatch(sendSupportMessage(data)).then((res) => {
      setUserForm({ ...userForm, text: "" });
      setReady(false)
      if(res?.statusId){
        setForm(res);
      }else{
        initFunc();
      }
    });
  };

  useEffect(() => {
    if (!ready) {
      initFunc();
    }
  }, [ready]);

  useEffect(() => {
    setIsActive(false);
    if (
      ((currentUser?.roleId === "631db81f-fa07-42e2-b394-062c9be66b09" ||
        currentUser?.roleId === "48633388-3277-4c7b-8d39-d421e9e90599") &&
      form?.statusId === "45a1b233-1b5d-45b9-8539-6bg7d1afa323") || (form?.createdBy?.id===currentUser.id && form?.messages[form.messages?.length-1]?.createdBy?.id!==currentUser.id)
    ) {
      setIsActive(true);
    }
  }, [form, currentUser]);


  if (ready && form?.statusId) {
    return (
      <>
        <h1>{form?.topic}</h1>
        <div className="menuFunctionLine">
          <div className="actionButtonsBlock">
            <button
              className="secondaryButtonIconTextDefault"
              onClick={() => navigate("/supportlist")}
            >
              <svg>
                <use href="/images/icon24ArrowLeft.svg#icon24" />
              </svg>
              Назад
            </button>

            {form.statusId !== "28a1b923-1b1d-45b9-8839-6bgfd1afa365" &&
              form.statusId !== "78a1b2f3-1b5d-34b9-8gf9-6bg7f4lfare3" && 
              currentUser.roleId !=='48633388-3277-4c7b-8d39-d421e9e90599'
              &&
              form.statusId !== "hga12f33-13gd-4gb9-8539-63g7d1afau53"&&
              (
                <button
                  className="secondaryButtonIconTextDefault"
                  accessKey="to-resolved"
                  onClick={changeStatus}
                >
                  <svg accessKey="to-resolved">
                    <use
                      accessKey="to-resolved"
                      href="/images/icon24CheckCircle.svg#icon24"
                    />
                  </svg>
                  Вирішено
                </button>
              )}

            {form.statusId !== "28a1b923-1b1d-45b9-8839-6bgfd1afa365" && form.statusId !== "78a1b2f3-1b5d-34b9-8gf9-6bg7f4lfare3" &&
              (currentUser.roleId === "631db81f-fa07-42e2-b394-062c9be66b09" ||
                currentUser.roleId ===
                  "48633388-3277-4c7b-8d39-d421e9e90599") && (
                <button
                  className="secondaryButtonIconTextDefault"
                  accessKey="to-spam"
                  onClick={changeStatus}
                >
                  <svg accessKey="to-spam">
                    <use
                      accessKey="to-spam"
                      href="/images/icon24CheckCircle.svg#icon24"
                    />
                  </svg>
                  Спам
                </button>
              )}

            {form.statusId === "bha1bfd3-1b5d-45b9-8239-6bg7d4jfa32" &&
              currentUser.roleId !== "631db81f-fa07-42e2-b394-062c9be66b09" &&
              currentUser.roleId !== "48633388-3277-4c7b-8d39-d421e9e90599" && (
                <button
                  className="secondaryButtonIconTextDefault"
                  accessKey="send"
                  onClick={sendMessage}
                >
                  <svg accessKey="send">
                    <use
                      accessKey="send"
                      href="/images/icon24CheckCircle.svg#icon24"
                    />
                  </svg>
                  Відправити
                </button>
              )}
          </div>
        </div>
        <div className="pageContentHeaderDivider" />

        <div>
          <div>
            {form.messages &&
              form.messages.map((el, index) => {
                return (
                  <React.Fragment key={index}>
                    {(index == 0 ||
                      el.createdAt.split(" ")[0] !==
                        form?.messages[index - 1].createdAt.split(" ")[0]) && (
                      <div className="messagesRow">
                        <div className="messagesRowUserPhotoCell"></div>
                        <div className="messagesRowContentCell">
                          <div className="messagesRowDate">
                            {getDate(el.createdAt,'dd/mm/yyyy','.')}
                          </div>
                        </div>
                      </div>
                    )}
                    <div className="messagesRow">
                      <div className="messagesRowUserPhotoCell">
                        {el?.createdBy?.photo ? (
                          <div
                            style={{
                              backgroundImage: `URL(${el?.createdBy?.photo})`,
                            }}
                            className="userValueImage"
                          ></div>
                        ) : (
                          <div className="userValueImage">
                            {el?.createdBy?.lastName && el.createdBy?.firstName
                              ? el.createdBy.lastName[0] +
                                el.createdBy.firstName[0]
                              : el.createdBy && el?.createdBy?.nickname[0]}
                          </div>
                        )}
                      </div>
                      <div className="messagesRowContentCell">
                        {!el.file ? (
                          <div className="messagesRowTextCell">
                            <div className="messagesRowTextCellUserName">
                              <h4 onClick={()=>{navigate(`/personalPage/${el.createdBy?.id}`)}}>
                                {el?.createdBy?.lastName && el.createdBy?.firstName
                                  ? el.createdBy.firstName  +
                                    " " +
                                    el.createdBy?.lastName
                                  : el?.createdBy && el?.createdBy?.nickname}
                              </h4>
                              <span>{listId?.list[el?.createdBy?.roleId]}</span>
                            </div>
                            <div className="messagesRowTextCellMessage">
                             <MessageItem text={el.text}></MessageItem>
                            </div>
                            <div className="messagesRowTextCellOrigin">
                              <span></span>
                              <span>
                                {el.createdAt.split(" ")[1].split(":")[0] +
                                  ":" +
                                  el.createdAt.split(" ")[1].split(":")[1]}
                              </span>
                            </div>
                          </div>
                        ) : (
                          <div className="messagesRowTextCell apCurrentUserMessage">
                            <div className="messagesRowTextCellFile">
                              <a href={el.file}>
                                <div>
                                  <svg>
                                    <use href="/images/icon24File.svg#icon24"></use>
                                  </svg>
                                </div>
                              </a>
                              <div>
                                <h4>
                                  <a href={el.file}>{el.text}</a>
                                </h4>
                                {/* <span>1.2 MB</span> */}
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </React.Fragment>
                );
              })}
          </div>
       
            <>
              <div className="apTextarea">
                <textarea
                  id="text"
                  value={userForm.text}
                  onChange={changeHandler}
                  onKeyDown={handleKeyDown}
                />
              </div>

              <button
                className="secondaryButtonIconTextDefault"
                accessKey="send"
                onClick={sendMessage}
              >
                <svg accessKey="send">
                  <use
                    accessKey="send"
                    href="/images/icon24CheckCircle.svg#icon24"
                  />
                </svg>
                Відправити
              </button>
            </>
        </div>
      </>
    );
  }else{
    return(
      <Loader></Loader>
    )
  }
};

export default SupportItemUserPage;
