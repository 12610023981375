import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router";
import InfoWindow from "../../components/InfoWindow/InfoWindow";
import Loader from "../../components/Loader/Loader";
import {
  addSupport,
  editSupport,
  getSupportItem,
} from "../../redux/actions/support";

const SupportItemPage = () => {
  const [ready, setReady] = useState(false);
  const dispatch = useDispatch();
  const currentId = useParams().id;
  const navigate = useNavigate();
  const [form, setForm] = useState({});
  const [alertMessage, setAlertMessage] = useState("");
  const [alertModal, setAlertModal] = useState(false);

  const changeStatus = (e) => {
    let data = {};
    data.type = e.target.accessKey;
    data.id = currentId;
    dispatch(addSupport(data)).then((res) => {
      setAlertMessage('Повідомлення відправлено')
      initFunc();
    });
  };
  const editItem = () => {
    dispatch(editSupport(form))
      .then((res) => {})
      .catch((e) => {
        console.log(e);
      });
  };

  const initFunc = () => {
    dispatch(getSupportItem({ id: currentId })).then((res) => {
      setForm(res);
      setReady(true);
    });
  };

  const changeHandler = (e) => {
    setForm({ ...form, [e.target.id]: e.target.value });
  };

  useEffect(() => {
    if (!ready) {
      initFunc();
    }
  }, [ready]);

  if (!ready) {
    return (
      <>
        <Loader></Loader>
      </>
    );
  }

  if (ready) {
    return (
      <>
       {alertModal && (
          <InfoWindow
            setModal={setAlertModal}
            message={alertMessage}
          ></InfoWindow>
        )}
        <h1>Створення заявки</h1>
        <div className="menuFunctionLine">
          <div className="actionButtonsBlock">
            <button
              className="secondaryButtonIconTextDefault"
              onClick={() => navigate("/supportlist")}
            >
              <svg>
                <use href="/images/icon24ArrowLeft.svg#icon24" />
              </svg>
              Назад
            </button>
            {form.statusId === "28a1b923-1b1d-45b9-8839-6bgfd1afa365" && (
              <button
                className="secondaryButtonIconTextDefault"
                accessKey="send"
                onClick={changeStatus}
              >
                <svg accessKey="send">
                  <use
                    accessKey="send"
                    href="/images/icon24CheckCircle.svg#icon24"
                  />
                </svg>
                Відправити
              </button>
            )}
          </div>
        </div>
        <div className="pageContentHeaderDivider" />

        <div style={{marginTop:'20px'}}>
          <div className="apInputText">
            <label htmlFor="topic">Тема</label>
            <input
              id="topic"
              type="text"
              value={form.topic}
              onChange={changeHandler}
              onBlur={editItem}
            />
          </div>

          <div className="apTextarea">
            <label htmlFor="text">Текст</label>
            <textarea
              id="text"
              value={form.text}
              onChange={changeHandler}
              onBlur={editItem}
            />
            <div></div>
          </div>
        </div>
      </>
    );
  }
};

export default SupportItemPage;
