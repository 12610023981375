import axios from "axios";
import authHeader from "./auth-header";

import constants from '../../constants/constant.json';

const getParksMessages = (data)=>{
    if(!data.offset){
        data.offset=0;
    } if (!data.limit){
        data.limit=100
    }
    let filter=''
if(data.filter&& Object.keys(data.filter).length ){
    for (const [key, value] of Object.entries(data.filter)) {
        value.forEach(el => {
            
            filter+=`&filters[${key}]=${el}`
        });
    }
}    
    return axios.get(constants.URL + `api/v1/sportsgrounds/${data.id}/messages?offset=${data.offset}&limit=${data.limit}${filter}&sort[created_at]=asc`,{headers: authHeader()}).then(response=>{
        return response.data
    }).catch((e)=>{
        console.log(e);
      });;
}

const addParkMessages = (data)=>{
    
    if(data){
        return axios.post(constants.URL + `api/v1/sportsgrounds/${data.id}/messages`,{...data},{ headers: authHeader()})
        .then((response) => { 
            return response.data})
        .catch((e)=>{
              
            console.log(e);
        });
    }    
}

const editParkMessages = (data)=>{
    return axios.put(constants.URL + 'api/v1/sportsgrounds/'+data.id+'/messages',{...data},{ headers: authHeader()})
    .then((response) => { 
        return response.data})
    .catch((e)=>{
          
        console.log(e);
    });
}

const getParkMessagesItem = (data)=>{
    return axios.get(constants.URL +'api/v1/sportsgrounds/'+data.id+'/messages',{headers: authHeader()}).then(response=>{
        
        return response.data
    }).catch((e)=>{
        console.log(e);
      });;
}

const deleteParkMessages = (data)=>{
    return axios.delete(constants.URL + 'api/v1/sportsgrounds/' + data.id +'/messages/'+data.messageId,{ headers: authHeader()})
    .then((response) => { 
        
        return response.data})
    .catch((e)=>{
        console.log(e);
    });
}

export default{
    getParksMessages,
    addParkMessages,
    editParkMessages,
    getParkMessagesItem,
    deleteParkMessages
}