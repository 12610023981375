import {
  TRACK_SUCCESS,
  TRACK_FAIL,
  EDITTRACK_SUCCESS,
  EDITTRACK_FAIL,
  DELETETRACK_SUCCESS,
  DELETETRACK_FAIL,
  GETTRACKITEM_SUCCESS,
  GETTRACKITEM_FAIL,
} from "./types";

  import TrackService from "../services/track.service";

  export const getTracks = (data)=> (dispatch) => {
    return TrackService.getTracks(data).then(
      (response) => {
          dispatch({
          type: TRACK_SUCCESS,
          payload:response
        });
        return response;
      },
      (error) => {
        dispatch({
          type: TRACK_FAIL,
        });
    
        return Promise.reject();
      }
    );
  }



  export const editTrack= (form)=>(dispatch)=>{
      
    return TrackService.editTrack(form).then(
        (response)=>{
              
            dispatch({
                type:EDITTRACK_SUCCESS,
                item:response
            });
            
            return response

        },
        (error)=>{
            dispatch({
                type: EDITTRACK_FAIL,
              });
            console.log(error);
              return Promise.reject();
        }
    );
}

export const deleteTrack= (data)=>(dispatch)=>{
      
  return TrackService.deleteTrack(data).then(
      (response)=>{
          dispatch({
              type:DELETETRACK_SUCCESS,
          });
            
      },
      (error)=>{
          dispatch({
              type: DELETETRACK_FAIL,
            });
          console.log(error);
               
            return Promise.reject();
      }
  );
}

export const getTrackItem = (data)=>(dispatch)=>{
  return TrackService.getTrackItem(data).then(
      (response)=>{
            
          dispatch({
              type:GETTRACKITEM_SUCCESS,
              item:response,
          });
            
          return response

      },
      (error)=>{
          dispatch({
              type: GETTRACKITEM_FAIL,
            });
          console.log(error);
               
            return Promise.reject();
      }
  );

}
