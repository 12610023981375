import {
    ADDPARKCOORDINATORS_SUCCESS,
    ADDPARKCOORDINATORS_FAIL,
    DELETEPARKCOORDINATORS_SUCCESS,
    DELETEPARKCOORDINATORS_FAIL,
    GETPARKCOORDINATORS_SUCCESS,
    GETPARKCOORDINATORS_FAIL,
} from "../actions/types";

const initialState = [];

export default function parksCoordinatorsReducer(parksCoordinators = initialState,action){
    const {list,item,type} = action;

    switch(type) {
        case  ADDPARKCOORDINATORS_SUCCESS:
            return parksCoordinators
        case ADDPARKCOORDINATORS_FAIL:
            return parksCoordinators;
        case GETPARKCOORDINATORS_SUCCESS:
            return {...list};
        case GETPARKCOORDINATORS_FAIL:
            return parksCoordinators;
        case DELETEPARKCOORDINATORS_SUCCESS:
            return parksCoordinators;
        case DELETEPARKCOORDINATORS_FAIL:
            return parksCoordinators;
        default:
            return parksCoordinators;
    }

}