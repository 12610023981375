import React, { useEffect, useState } from 'react';
import axios from "axios";
import { useDispatch, useSelector } from 'react-redux';
import { uploadFile } from '../../../redux/actions/file';
import InfoWindow from '../../../components/InfoWindow/InfoWindow';
import authHeader from '../../../redux/services/auth-header';
import { editFcNews } from '../../../redux/actions/fcNews';
import EditModal from './EditModal/EditModal';
import Loader from '../../../components/Loader/Loader';

const chunkSize = 1048576 * 1;//its  MB, increase the number measure in mb
// const chunkSize = 10;
const  PhotoUploud =(props)=>{
const [ready,setReady]=useState(false);
//   const [showProgress, setShowProgress] = useState(false);
const dispatch= useDispatch();
const {fullList} = useSelector(state=>state)
const [counter, setCounter] = useState(1);
const [multipleFiles,setMultipleFiles] = useState([]);
const [fileToBeUpload, setFileToBeUpload] = useState({});
const [beginingOfTheChunk, setBeginingOfTheChunk] = useState(0);
const [endOfTheChunk, setEndOfTheChunk] = useState(chunkSize);
const [progress, setProgress] = useState(0);
const [fileGuid, setFileGuid] = useState("");
const [fileSize, setFileSize] = useState(0);
const [chunkCount, setChunkCount] = useState(0);
const [open,setOpen] = useState('')
const [fileList, setFileList]=useState([]);
const [chunkSet, setChunkSet]=useState();
const [alertMessage,setAlertMessage]=useState('');
const [alertModal,setAlertModal]=useState(false);
const [modal,setModal]=useState(false);
const [editElem,setEditElem]=useState({});
const [deleteElement,setDeleteElement]=useState('');
const [deleteModal, setDeleteModal]=useState(false);

let val = 9 - (fileList.length+multipleFiles.length+ (!!fileToBeUpload.name));
let arr = [];

const initFunction=()=>{
  let newArr = []
  props.form.photos && props.form.photos.length>0 && props.form.photos.forEach(el=>{
    newArr.push({link:el})
  })
  setFileList(newArr);
  setReady(true);
}

let options={
  uploadType:'other_photo',
  initialAspectRatio:16 / 10,
  aspectRatio: 16 / 10,
  autoCropArea:16 / 10,
  height:'200px',
  width:'320px',
  size:10,
  type:'imageUrl',
  dragAbleZone:true,
  text:"блог",
  disabled:false
}



if(val>=0){
  arr.push([])
  for(let i=0;i<val;i++){
    arr.push([]);
  }
  arr.length=val;
}
useEffect(() => {
  if (fileSize > 0 && counter>0) {
    fileUpload(counter);
  }
  if(!ready){
    initFunction();
  }
}, [fileToBeUpload, progress,multipleFiles])

const getFileContext = (e) => {
  let types=[...fullList.FileTypes[1].title.split(' ')]
  resetChunkProperties();
  let fileListArr = [];

  if(e.target && e.target.files){
    fileListArr= [...e.target.files];
  }else if(e.dataTransfer && e.dataTransfer.files){
  e.preventDefault();
    fileListArr= [...e.dataTransfer.files];
  }
  else{
    fileListArr=multipleFiles;
    setCounter(1);
  }
  
  // if(fileListArr.length>9 || fileList.length+fileListArr.length>9){
  //   setAlertMessage('Не більше 9 зображень');
  //   setAlertModal(true);
  //   return
  // }

  if(fileListArr.length>9 || fileList.length+fileListArr.length>9){
    let amount = 9-fileList.length;
    fileListArr = fileListArr.slice(0, amount);
  }


  if(fileListArr.length>0 && fileListArr.length<10 && fileList.length<9){
    const _file = fileListArr[0];
    fileListArr.splice(0, 1);
    setMultipleFiles(fileListArr);
    if(_file.size>10000000){
    setAlertMessage('Не більше 10МБ')
    setAlertModal(true);
      return
    }
    if(!types.find(a =>a.includes(_file.name.split('.')[_file.name.split('.').length - 1].toLowerCase()))){
      setAlertMessage('Не вірний формат файлу')
      setAlertModal(true);
      return 
    }

    setFileSize(_file.size)
    const _totalCount = _file.size % chunkSize == 0 ? _file.size / chunkSize : Math.floor(_file.size / chunkSize) + 1; // Total count of chunks will have been upload to finish the file
    setChunkCount(_totalCount)
    setFileToBeUpload(_file)
    const _fileID = _file.name;
    setFileGuid(_fileID+Math.floor(1000 + Math.random() * 9000));
    // fileUpload();
  }else {
    setMultipleFiles([]);
  }
  if(fileList.length>=9){
    setAlertMessage('Вже завантажено 9 зображень');
    setAlertModal(true);
    return
  }
}
const removeByAttr = function(arr, attr, value){
  let i = arr.length;
  while(i--){
     if( arr[i] 
         && arr[i].hasOwnProperty(attr) 
         && (arguments.length > 2 && arr[i][attr] === value ) ){ 
         arr.splice(i,1);
     }
  }
  return arr;
}

const fileUpload = () => {
  setCounter(counter + 1);
  if (counter>0 && counter <= chunkCount) {
    let chunk = fileToBeUpload;
    uploadChunk(chunk)
  }else if(counter>0 && counter>chunkCount){
    setCounter(0);
  }
}

const uploadChunk = async (chunk) => {
  try {
    let formData = new FormData();
    formData.append('fileName', chunk.name);
    formData.append('size', fileSize);
    formData.append('chunkIndex', counter);
    formData.append('totalChunk', chunkCount);
    formData.append('file',chunk.slice(beginingOfTheChunk,endOfTheChunk,fileToBeUpload.type));
    formData.append('uploadId',chunk.name+chunkSize);
    formData.append('uploadType','sportsground_photo');
    setChunkSet(chunk);   
  let response = await dispatch(uploadFile(formData))
    const data = response.data;

    if (response.status === 200) {
      setBeginingOfTheChunk(endOfTheChunk);
      setEndOfTheChunk(endOfTheChunk + chunkSize);
      if (counter == chunkCount) {
        console.log('Process is complete, counter', counter)
        // await uploadCompleted();
      } else {
        let percentage = (counter / chunkCount) * 100;
        setProgress(percentage);
      }
    } else if(response.status ===201){
      setFileToBeUpload({})
      setProgress(100);
      let res = [...fileList];
      
      let obj = {
        name: fileGuid.split('.')[0],
        size: fileSize,
        type:  fileGuid.split('.')[1],
        link: data.url
      }
      res.push(obj);
      let arr = []
      res.forEach((el,index)=>{
       arr.push({photo_url:el.link,photo_index:index});
      })
      props.form.photos=arr;
      // props.setFrom(props.form);
      dispatch(editFcNews(props.form)).then(res=>{
        // props.setFrom(props.form)
      })
      setFileList(res);
      getFileContext(multipleFiles);
    }
    else{
      console.log('Error Occurred:', data.errorMessage)
    }

  } catch (error) {
    
    console.log('error', error)
  }
}


const handleCancel = async()=>{
 try {
    setFileToBeUpload({});
    let formData = new FormData();
    formData.append('chunkIndex', chunkCount);
    formData.append('totalChunk', chunkCount);
    formData.append('chunkUpload', chunkSet);
    setCounter(chunkCount+1);
    formData.append('toDelete', true);
    formData.append('uploadType','sportsground_photo');
  //   formData.append('chunkUpload', chunk);
    formData.append('uploadId',fileGuid+chunkSize);
    
  let response = await axios({
      method: "post",
      url: `https://ap.sportforall.gov.ua/api/v1/upload`,
      data: formData,
      headers: { "Content-Type": "multipart/form-data" },
    })
    const data = response.data;
    getFileContext(multipleFiles);
  }
  catch (error) {
    
    console.log('error', error)
  }
}

const handleEdit=(e)=>{
  setModal(true);
  let data={url:e.target.id,index:e.target.accessKey}
  setEditElem(data);

}
const saveEdit=(e)=>{
  try{
  setModal(false);
  setEditElem(false);
  let arrCur =fileList; 
  console.log(arrCur[editElem.index]);
  arrCur[editElem.index].link=e;
  let arr=[];
  arrCur.forEach((el,index)=>{
    arr.push({photo_url:el.link,photo_index:index});
   })

  props.form.photos=arr;
  dispatch(editFcNews(props.form)).then(res=>{
    setOpen(false)
  })
  setFileList(arrCur)
  } catch (error) {
    
  console.log('error', error)
  }
}


const handleDelete =(e)=>{
  setDeleteModal(true);
  setDeleteElement(e.target.id);
}

const handleDeleteSubmit = async()=>{
  try{
      
    let response = await axios({
      method: "delete",
      url: deleteElement,
      headers:authHeader()
    })
      
    const data = response.data;
    let arr = [...fileList]
    removeByAttr(arr,'link',deleteElement)
    let photos = []
    arr.forEach((el,index)=>{
      photos.push({photo_url:el.link,photo_index:index})
    })
    props.form.photos=photos;
    // props.setFrom(props.form);
    dispatch(editFcNews(props.form)).then(res=>{
      setDeleteElement('');
      setOpen(false);
      setDeleteModal(false);
    })
    setFileList(arr)
  }
  catch (error) {
    
    console.log('error', error)
  }
}

function dragOverHandler(ev) {
  // Prevent default behavior (Prevent file from being opened)
  ev.preventDefault();
}

const resetChunkProperties = () => {
  // setShowProgress(true);
  setProgress(0);
  setFileGuid('');
  if(counter===1){
      setCounter(1);
  }else{
      setCounter(0);
  }
  setBeginingOfTheChunk(0);
  setEndOfTheChunk(chunkSize);
  setFileSize(0);
}

const handleMain =(e)=>{
  let data= {...props.form}
  data.photo=e.target.id;
  props.setForm({...data});
  dispatch(editFcNews(data)).then(res=>{
    setOpen('');
    props.setForm({...data});
  })
}

useEffect(()=>{

},[props.form])

  return (
    <>
{alertModal && <InfoWindow setModal={setAlertModal} message={alertMessage}></InfoWindow>}
{deleteModal && <InfoWindow setClick={handleDeleteSubmit} setModal={setDeleteModal} buttonMsg={'Видалити'} message={'Ви впевнені, що хочете видалити?'}></InfoWindow>}
{/* {modal && <FileUploadAndCrop options={options} form={editElem} image={editElem.url} setAlertMessage={setAlertMessage} setAlertModal={setAlertModal} />} */}
{modal && <EditModal options={options} setEdit={setModal} saveEdit={saveEdit} element={editElem}></EditModal>}
<div className="groundPropertiesPhotosBlock">
        <h3>Фото об'єкта</h3>
        <div className="apParkCoverImageContainer">
            {props.form && props.form.photo&&  props.form.photo.length>0 ?<div style={{backgroundImage: `linear-gradient(rgba(68, 79, 96, 0.1), rgba(68, 79, 96, 0.1)), url('${props.form.photo}')`}}><span></span></div>:<div style={{backgroundImage: `linear-gradient(rgba(68, 79, 96, 0.1), rgba(68, 79, 96, 0.1)), url('${fileList && fileList.length>0?fileList[0].link:''}')`}}><span>1</span></div>}
          <span>обкладинка</span>
        </div>
        <div className="apParkImagesContainer">
        {fileList && fileList.length>0 && fileList.map((element,index) => {
            return(
                // <div key={index} style={{backgroundImage: `linear-gradient(rgba(68, 79, 96, 0.1), rgba(68, 79, 96, 0.1)), url('${element.link}')`}}><span id={element.link} onClick={handleDelete}>{index+1}</span></div>
                <React.Fragment key={index}>
                <div style={{backgroundImage: `linear-gradient(rgba(68, 79, 96, 0.1), rgba(68, 79, 96, 0.1)), url('${element.link}')`}} className="apParkImage">
                  <div className='apParkImageTools'>
                   {(props.form.statusId!=="99ca4f35-663c-4e0a-b9ef-f70219aa8163"&& props.form.statusId!=="036735ba-f634-469b-ac30-77a164e3a918") && !props.disabled ?<svg id={element} onClick={e=>open===index?setOpen(''):setOpen(index)} style={{marginBottom:'8px'}}><use href="/images/icon24MoreVertical.svg#icon24"></use></svg>:<span></span>}
                    <span>{index+1}</span>
                    {open===index && <div className={ index===0||index===3||index===6?"apSettingsList apNewsSettingsListStart":'apSettingsList'}>
                      <div id={element.link} accessKey={index} onClick={handleEdit}>Редагувати</div>
                      <div id={element.link} onClick={handleMain}>Зробити обкладинкою</div>
                      <div id={element.link} onClick={handleDelete}>Видалити</div>
                    </div>}
                  </div>
                </div>    
                </React.Fragment>
                )
            })
        }
        {fileToBeUpload && fileToBeUpload.name && 
            <div className="apParkImage">
            <div className='apSpinner'>
              <div className="cancelUploadButton">
                <svg onClick={handleCancel}><use href="/images/icon24Cancel.svg#icon24"></use></svg>
              </div>
              <div></div><div></div><div></div><div></div>
            </div>
          </div>
         
         
        }

         {multipleFiles &&  multipleFiles.length>0 &&  multipleFiles.map(element => {
            
            return(
            <div className='apParkImage' style={{backgroundImage: `linear-gradient(rgba(68, 79, 96, 0.1), rgba(68, 79, 96, 0.1)), url('${element.link}')`,paddingTop: '18px', paddingLeft:'18px'}}  id={element.link}  onClick={handleCancel}> <Loader /></div>               
                )
            })
        }
        {arr.map((el,index)=>{
            return(
                <div className='apParkImage' key={index}>
                  <div className='apParkImageTools'>
                    <div></div>
                    <span>{(9-arr.length)+index+1}</span> 
                  </div>
                  
                  </div>
            )
        })}  
        </div>
        <input disabled={props.disabled}  type='file' accept='.jpg, .jpeg, .png, .gif' id='fileUpload' onChange={getFileContext} multiple style={{'display':'none'}} />
        <label id="drop_zone" htmlFor='fileUpload' className="apParkImagesDropArea " onDrop={getFileContext} onDragOver={dragOverHandler}>
                <svg><use href="/images/icon24Download.svg#icon24" /></svg>
                <span>Перетягніть файли сюди<br />або натисніть, щоб обрати. <br/> До&nbsp;10&nbsp;мегабайт, лише 9 найкращих.</span>
        </label>


        {/* <div className="apParkImagesHintArea">
          <span>Завантажуйте файли jpg, jpeg, png до&nbsp;10&nbsp;мегабайт, лише 9 найкращих.</span>
          <svg><use href="/images/icon24Info.svg#icon24" /></svg>
        </div> */}
      </div>
    </>
  );
}


export default PhotoUploud;