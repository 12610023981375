import {
    GETWORKOUTS_SUCCESS,
    GETWORKOUTS_FAIL,
    EDITWORKOUTS_SUCCESS,
    EDITWORKOUTS_FAIL,
    DELETEWORKOUTS_SUCCESS,
    DELETEWORKOUTS_FAIL,
    GETWORKOUTSITEM_SUCCESS,
    GETWORKOUTSITEM_FAIL,

} from "../actions/types";

const initialState = [];

export default function workoutsReducer(workouts = initialState,action){
    const {list,item,type} = action;

    switch(type) {

        case GETWORKOUTS_SUCCESS:
            return {...list};
        case GETWORKOUTS_FAIL:
            return workouts;
        case GETWORKOUTSITEM_SUCCESS:
            return {...item};
        case GETWORKOUTSITEM_FAIL:
            return workouts;
        case EDITWORKOUTS_SUCCESS:
            return workouts;
        case EDITWORKOUTS_FAIL:
            return workouts;
        case DELETEWORKOUTS_SUCCESS:
            return workouts;
        case DELETEWORKOUTS_FAIL:
            return workouts;
        default:
            return workouts;
    }

}