import {
    CREATEPOINTS_SUCCESS,
    CREATEPOINTS_FAIL,
    PASSPOINTSITEM_SUCCESS,
    PASSPOINTSITEM_FAIL,
    EDITPOINTS_SUCCESS,
    EDITPOINTS_FAIL,
    DELETEPOINTS_SUCCESS,
    DELETEPOINTS_FAIL,
} from "../actions/types";

const initialState = [];

export default function pointsReducer(points = initialState,action){
    const {list,item,type} = action;

    switch(type) {
        case  CREATEPOINTS_SUCCESS:
            return {item}
        case CREATEPOINTS_FAIL:
            return points;
        case PASSPOINTSITEM_SUCCESS:
            return points;
        case PASSPOINTSITEM_FAIL:
            return points;
        case EDITPOINTS_SUCCESS:
            return points;
        case EDITPOINTS_FAIL:
            return points;
        case DELETEPOINTS_SUCCESS:
            return points;
        case DELETEPOINTS_FAIL:
            return points;
        default:
            return points;
    }

}