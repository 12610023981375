import React, { useState, useEffect } from "react";

function YearSelector(props) {
  const [selectedYear, setSelectedYear] = useState("");
  const currentYear = new Date().getFullYear();
  const years = Array.from({ length: 4 }, (_, index) => currentYear - index);

  const handleYearChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedYear(selectedValue);
    props.getStatistics(selectedValue+'-01-01/'+selectedValue+'-12-31');
  };
  return (
    <div>
      <select id="yearSelector" className="statisticSelector" onChange={handleYearChange} value={selectedYear}>
        { props.compare && <option>Обрати рік</option>}
        {years.map((year, index) => (
          <option key={index} value={year}>
            {year}
          </option>
        ))}
      </select>
    </div>
  );
}

export default YearSelector;
