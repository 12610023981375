import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { editEvents } from "../../../redux/actions/events";
import Map from "./Map";

const DifMap = (props) => {
  const dispatch = useDispatch();
  const [arrAll, setArr] = useState(props.formSend?.routePoints || []);
  const updateArr = (ar) => {
    setArr(ar);
    editEventItem(ar);
  };

  const editEventItem = (arr) => {
    let form = props.form;
    let formSend = props.formSend;
    form.routePoints = arr;
    formSend.routePoints = arr;
    if(formSend?.routePoints?.length>0 &&  formSend?.routePoints[0].location?.length>0 &&  formSend?.routePoints[0].location[0]){
      props.getCurrentLocation(formSend.routePoints[0].location[0],formSend.routePoints[0].location[1]);
    }
    dispatch(editEvents(formSend)).then((res) => {});
  };

  return (
    <div className="apItineraryAdmin">
      <Map updateArr={updateArr} disabled={props.disabled} arrAll={arrAll}></Map>
    </div>
  );
};

export default DifMap;
