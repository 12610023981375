


import {
    GETEVENTEVALUATION_SUCCESS,
    GETEVENTEVALUATION_FAIL
} from "../actions/types";

const initialState = [];

export default function eventEvalReducer(eventEval = initialState, action) {
    const {
        list,
        type
    } = action;

    switch (type) {
        case GETEVENTEVALUATION_SUCCESS:
            return {
                ...list
            };
            case GETEVENTEVALUATION_FAIL:
                return eventEval;
            default:
                return eventEval;
    }

}