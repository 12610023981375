import React, { useState,useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { getCode, editInfo } from "../../redux/actions/auth";
import { jwtDecode } from "jwt-decode";

const RegistrationNewPage2 = () => {
  const navigate = useNavigate();
  const [error, setError] = useState([]);
  const [seconds, setSeconds] = useState(0);
  const [code, setCode] = useState(false);
  const [readyForChange, setReadyForChange] = useState(false);
  // const [ errMsg, setErrMsg ] = useState(null);
  const [selected, setSelected] = useState("");
  const [form, setForm] = useState({});
  const currentId=useParams().id;
  if(localStorage.getItem('FBProfSuccess')){
    let data=JSON.parse(localStorage.getItem('FBProfSuccess'));
    form.email=data.email;
    form.firstName=data.name?.split(' ')[0];
    form.lastName=data.name?.split(' ')[1];
    form.facebookToken=data.id;
  }
  if(localStorage.getItem('GoogleLogSuccess')){
    let data=JSON.parse(localStorage.getItem('GoogleLogSuccess')).credential;
    let parsed =JSON.parse(atob(data));
    let parsed2= jwtDecode(data);
    console.log(parsed);
    console.log(parsed2);
    form.email=parsed.email;
    form.firstName=parsed.name?.split(' ')[0];
    form.lastName=parsed.name?.split(' ')[1];
    form.googleToken=data;
  }


  const authResult = window.location.search;
  if (authResult.split("=")[1]) {
    const backUrl = authResult.split("=")[1];
    sessionStorage.setItem("backUrl", backUrl);
  }

  const [err, setErr] = useState(false);
  if (document.getElementsByClassName("sidebar")[0]) {
    document.getElementsByClassName("sidebar")[0].hidden = false;
  }

  const [_, setLoading] = useState(false);
  const { isLoggedIn } = useSelector((state) => state.auth);

  const dispatch = useDispatch();

  const changeHandler = (event) => {
    setForm({ ...form, [event.target.name]: event.target.value });
  };
  const changeState =(event)=>{
    event.preventDefault();
    if(form[event.target.id]==true){
      setForm({ ...form, [event.target.id]: false });
    }else{
      setForm({ ...form, [event.target.id]: true });
    }
  }
  const resiveCode = async (event) => {
    try {
      event.preventDefault();
      setSeconds(60);
      setReadyForChange(true);
      dispatch(getCode({ email: form.email }))
        .then((res) => {
          if (res && res.errors && res.errors.length > 0) {
            setError(res.errors)
            setReadyForChange(false);
          }
          setCode(true);
        })
        .catch((e) => {
          console.log(e);
        });
    } catch (e) {
      console.log(e);
    }
  };

  const setSelectClick = (e) => {
    let data = { ...form };
    data[e.target.accessKey] = e.target.id;
    setForm(data);
    setSelected("");
  };


  const selectClick = (e) => {
    if (e.target.id == selected) {
      setSelected("");
    } else {
      setSelected(e.target.id);
    }
  };

  const signHandler = (e) => {
    e.preventDefault();
    setLoading(true);
    let data=form;
    data.id=currentId;
    dispatch(editInfo(data)).then(res=>{
      if (res.error) {
              setErr(true);
            } else {
              if (sessionStorage.getItem("qr")) {
                navigate(`/qr${sessionStorage.getItem("qr")}`);
              } else if(sessionStorage.getItem("link")){
                let url = sessionStorage.getItem("link")
                navigate(`${url}`);
                window.location.reload();
              }
              else {
                navigate("/start");
                window.location.reload();
              }
        }
      })
    };

  useEffect(() => {
    if (seconds > 0) {
      console.log(seconds);
      setTimeout(() => setSeconds(seconds - 1), 1000);
    }
  }, [seconds]);


  if (isLoggedIn) {
    // navigate("/applications")
  }

  return (
    <div className="authContainer">
      <form className="authForm">
        <h1 className="">
        Реєстрація
          <h4>Приєднуйтесь</h4>
        </h1>
        <div
        className={
          error.param === "email" ? "apInputText apFilled" : "apInputText"
        }
      >
        <label htmlFor="email">Пошта</label>
        <input
          id="email"
          type="email"
          name="email"
          onChange={changeHandler}
          value={form.email}
        />
        {error.param === "email" && (
          <span className="apError">{error.msg}</span>
        )}
      </div>

      {form.email?.length>2 && !form.facebookToken && !form.googleToken &&   
      <div className="codeBlock">
         <button
          style={{ marginTop: "6px" }}
          className="secondaryButtonTextDefault"
          disabled={seconds > 0}
          onClick={resiveCode}
        >
          Отримати&nbsp;код
        </button>

        <div
          style={{ minWidth: "130px", maxWidth:'140px', marginRight: "10px" }}
          className={
            error.param === "code" ? "apInputText apFilled" : "apInputText"
          }
        >
          <label htmlFor="code">Код</label>
          <input
            id="code"
            type="text"
            name="code"
            onChange={changeHandler}
            value={form.code}
          />
          {error.param === "code" && (
            <span className="apError">{error.msg}</span>
          )}
        </div>
        {readyForChange || seconds > 0 && (
            <span>Отримати код повторно через {seconds}</span>
          )}
      </div>}

        <div
        className={
          error.param === "firstName" ? "apInputText apFilled" : "apInputText"
        }
      >
        <label htmlFor="firstName">Імʼя</label>
        <input
          id="firstName"
          type="text"
          name="firstName"
          value={form.firstName}
          onChange={changeHandler}
        />
         {/* <span className="subtitle">
          
        </span> */}
        {error.param === "firstName" && <span className="apError">{error.msg}</span>}
      </div>
      <div
        className={
          error.param === "lastName" ? "apInputText apFilled" : "apInputText"
        }
      >
        <label htmlFor="lastName">Прізвище</label>
        <input
          id="lastName"
          type="text"
          name="lastName"
          value={form.lastName}
          onChange={changeHandler}
        />
         {/* <span className="subtitle">
          
        </span> */}
        {error.param === "lastName" && <span className="apError">{error.msg}</span>}
      </div>

      <div className="codeBlock cus">
        <div
          style={{ minWidth: "130px", marginRight: "20px" }}
          className={
            error.param === "weight" ? "apInputText apFilled" : "apInputText"
          }
        >
          <label htmlFor="weight">Вага</label>
          <input
            id="weight"
            type="text"
            name="weight"
            onChange={changeHandler}
            value={form.weight}
          />
          {error.param === "weight" && (
            <span className="apError">{error.msg}</span>
          )}
        </div>

        <div
          style={{ minWidth: "130px"}}
          className={
            error.param === "height" ? "apInputText apFilled" : "apInputText"
          }
        >
          <label htmlFor="height">Ріст</label>
          <input
            id="height"
            type="text"
            name="height"
            onChange={changeHandler}
            value={form.height}
          />
          {error.param === "height" && (
            <span className="apError">{error.msg}</span>
          )}
        </div>
      </div>

      <div className="codeBlock cus">
        <div
          style={{ maxWidth: "140px",minWidth: 'auto',
          marginRight: "20px" }}
          className={
            error.param === "sex" ? "apInputText apFilled" : "apInputText"
          }
        >
          <label htmlFor="sex">Стать</label>
              <div style={{ maxWidth: "140px",minWidth: '140px', marginRight: "20px", position:'relative' }} id="sex" onClick={selectClick}>
                <input
                  id="sex"
                  readOnly
                  type="text"
                  value={
                    (form.sex === "male" && "Чоловіча") ||
                    (form.sex === "female" && "Жіноча") ||
                    ""
                  }
                />
                <svg id="sex" style={{position:'absolute',width:'20px', right:'8px', height:'20px'}} onClick={selectClick}>
                  <use
                    id="sex"
                    onClick={selectClick}
                    href="/images/icon24DropDown.svg#icon24"
                  />
                </svg>
                
              </div>
              {selected == "sex" && (
                <div className="apOptionsList">
                  <div
                    onClick={setSelectClick}
                    accessKey="sex"
                    id="male"
                    className={form.sex === "male" ? "apActive":''}
                  >
                    Чоловіча
                  </div>
                  <div
                    onClick={setSelectClick}
                    accessKey="sex"
                    id="female"
                    className={form.sex === "female" ? "apActive":''}
                  >
                    Жіноча
                  </div>
                </div>
              )}
        </div>

        <div
          style={{ minWidth: "130px"}}
          className={
            error.param === "birthday" ? "apInputText apFilled" : "apInputText"
          }
        >
          <label htmlFor="birthday">Дата народження</label>
          <input
            id="birthday"
            type="date"
            value={form.birthday}
            name="birthday"
            onChange={changeHandler}
          />
          {error.param === "birthday" && (
            <span className="apError">{error.msg}</span>
          )}
        </div>
      </div>


      <div className="codeBlock cus" >
        <button
            id='isVpo'
            style={{ marginTop: "6px", marginRight:'20px', minWidth: '140px'}}
            className={form.isVpo?"primaryButtonTextDefault auth active":"primaryButtonTextDefault auth"}
            onClick={changeState}
          >
            ВПО
          </button>
        <button
          id='isVeteran'
          style={{ marginTop: "6px",minWidth: '140px' }}
          className={form.isVeteran?"primaryButtonTextDefault auth active":"primaryButtonTextDefault auth"}
          onClick={changeState}
        >
         Ветеран
        </button>
      </div>
        <button
          id='isInvalid'
          style={{ marginTop: "6px",minWidth: '140px' }}
          className={form.isInvalid?"primaryButtonTextDefault auth active":"primaryButtonTextDefault auth"}
          onClick={changeState}
        >
        Особа з інвалідністю
        </button>

        <button
          className="primaryButtonTextDefault"
          type="submit"
          onClick={signHandler}
        >
          Зберегти
        </button>
      </form>
      <div className="rightSide">
        <img loading="lazy" src="/images/LoginImage.svg" alt="Registration2 Not Found" />
        <div>ЗДОРОВА&nbsp;УКРАЇНА</div>
        <div>
          <span>ПРОГРАМА&nbsp;ПРЕЗИДЕНТА&nbsp;УКРАЇНИ</span>
        </div>
      </div>
    </div>
  );
};

export default RegistrationNewPage2;
