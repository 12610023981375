import React, {  useState } from "react";
import Timer from "../../../../components/Timer";
import getDate from "../../../../helpers/getDate";

const Conference = (props) => {
  const [isActive, setIsActive] = useState(false);
  console.log('--------------------------');
  console.log(isActive);
  console.log('--------------------------');

  return (
    <div className="apConferenceBlock">
      {isActive && props.status!=='0q8a6xc0-1nb4-1pr4-h5at-4sw3m0l387yp'?
        <div className="apConfereceStart">
          <p>Трансляція вже почалась!</p>
          <button onClick={props.conferenceLink?props.onClick:''}>{props.conferenceLink?'Дивитись':'Очікуйте створення кімнати'}</button>
        </div>
        : (isActive && props.status==='0q8a6xc0-1nb4-1pr4-h5at-4sw3m0l387yp' || props.status==='0q8a6xc0-1nb4-1pr4-h5at-4sw3m0l387yp') &&
        <div className="apConfereceStart">
          <p>Трансляція закінчилась!</p>
          <button onClick={props.onClick}>{props.meetingRecordsCount==0?'Запис в обробці':'Дивитись запис'}</button>
        </div>
      }


      {/* {!isActive && props.status=='6h8ad9c0-fd34-8kr4-h8ft-43vdm3n7do3p' && */}
      {!isActive && 
        <div className="apConfereceTimer">
          <p>Трансляція почнеться через:</p>
            <Timer date={(+props.date*(-1))} onEnd={() => setIsActive(true)} />
        </div>
      }
    </div>
  );
};

export default Conference;
