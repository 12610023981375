import {
  GETCONF_SUCCESS,
  GETCONF_FAIL
} from "../actions/types";
  
const initialState = [];

export default function infoReducer(currentNews = initialState, action) {
    const { type, list } = action;

    switch (type) {
      case GETCONF_SUCCESS:
        return {...list};
    case GETCONF_FAIL:
        return currentNews;
      default:
        return currentNews;
      }

}

