import {
  GETNEWSCURRENTITEM_SUCCESS,
  GETNEWSCURRENTITEM_FAIL
} from "../actions/types";
  
const initialState = [];

export default function newsReducer(currentNews = initialState, action) {
    const { type, item } = action;

    switch (type) {
      case GETNEWSCURRENTITEM_SUCCESS:
        return {...item};
    case GETNEWSCURRENTITEM_FAIL:
        return currentNews;
      default:
        return currentNews;
      }

}

