function tempCacl(temp) {
        // Input validation (optional):
        if (isNaN(temp) || temp < 0) {
          throw new Error("Invalid input: Pace value must be a non-negative number.");
        }
      
        // Extract whole minutes:
        const paceMinutes = Math.floor(temp);
        const paceMinutesResult = paceMinutes.toString().padStart(2, '0')
      
        // Calculate remaining seconds (using Math.trunc for whole number truncation):
        const paceSeconds = Math.trunc((temp - paceMinutes) * 60);
      
        // Ensure valid seconds range (0 to 59):
        const correctedSeconds = paceSeconds % 60; // Modulo operator for remainder within 0-59
      
        // Ensure two-digit formatting for seconds:
        const formattedSeconds = correctedSeconds.toString().padStart(2, '0'); // Zero-pad seconds
      
        // Return formatted pace string:
        return `${paceMinutesResult}:${formattedSeconds} `;
      }
export default tempCacl;