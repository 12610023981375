import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { getInfoCurrentList } from "../../redux/actions/info";

const Footer = () => {
  const [ready, setReady] = useState(false);
  const dispatch = useDispatch();
  const [form, setForm] = useState({});

  const initFunct = () => {
    let data = {};
    data.filter = { sectionId: ["72da8d63-8541-478e-9543-b0068aa7cf05"] };
    dispatch(getInfoCurrentList(data)).then((res) => {
      setForm(res);
      setReady(true);
    });
  };
  useEffect(() => {
    if (!ready) {
      initFunct();
    }
  }, [ready]);

  return (
    <>
      <div className="apFooterFullHeightContainer">
        <div className="apFooter">
          <div className="apFooterLinksBlock">
            {ready &&
              form &&
              form.items &&
              form.items.map((el, index) => {
                return (
                  <div key={`${el}.${index}`}>
                    <a
                      target="_blank"
                      href={"https://ap.sportforall.gov.ua/info/" + el.id}
                    >
                      {el.title}
                    </a>
                  </div>
                );
              })}
            {/* <div><a target="_blank" href="https://parks.sportforall.gov.ua/polityka-konfidentsiinosti">Політика конфіденційності</a></div>
                    <div><a target="_blank" href="https://parks.sportforall.gov.ua/obrobka-personalnykh-danykh">Обробка персональних даних</a></div> */}
            <div>
              <a
                target="_blank"
                href="https://ap.sportforall.gov.ua/infolist/start"
              >
                База знань
              </a>
            </div>
            <div>
              <a href="mailto:user@provider.net">info@sportforall.gov.ua</a>
            </div>
            <div>
              <a
                target="_blank"
                href="https://www.facebook.com/ap.sportforall.gov.ua/"
              >
                Facebook
              </a>
            </div>
            <div>
              <a
                target="_blank"
                href="https://www.instagram.com/aktyvni_parky/"
              >
                Instagram
              </a>
            </div>
          </div>
          <div className="storeButtonCollection" style={{marginTop:'10px'}}>

           <a
                target="_blank"
                href="https://technodreams.biz/"
              >
                
          <div className="footerlogo">
            
                {/* TechnoDreams */}
                {/* <img src="https://technodreams.biz/bitrix/templates/landing/images/logo.svg" alt="" /> */}
                <img src="/images/TD-made_by.svg" alt="" />
          </div>
          </a>
            <div style={{display:'flex'}}>
                <button className='storeButton' onClick={()=>window.open('https://apps.apple.com/us/app/id1661291870')} style={{background:`url(${'/images/appStore.png'})`,margin:'0 20px'}}></button>
                <button className='storeButton' onClick={()=>window.open('https://play.google.com/store/apps/details?id=com.technodreams.activeparks')} style={{background:`url(${'/images/googlePlay.png'})`}}></button>
            </div>
          </div>

          <div className="apFooterCopyright">
            ©&nbsp;2024 ВЦФЗН <nobr>«Спорт для всіх»</nobr>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
