import React, { useCallback, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import InfoWindow from "../../components/InfoWindow/InfoWindow";
import Loader from "../../components/Loader/Loader";
import { uploadFile } from "../../redux/actions/file";
import {
  addNews,
  deleteNews,
  editNews,
  getNewsItem,
} from "../../redux/actions/news";
import Editor from "../../components/Editor/Editor";
import FileUploadAndCrop from "../../components/FileUploadAndCrop/FileUploadAndCrop";



const NewsPage = () => {
  const [ready, setReady] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [form, setForm] = useState({});
  const [data, setData] = useState("");
  const [isEmpty, setIsEmpty] = useState(false);
  const [errorCus, setErrorCus] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
  const [alertModal, setAlertModal] = useState(false);
  const currentId = useParams().id;
  let options={
    uploadType:'other_photo',
    initialAspectRatio:16 / 10,
    aspectRatio: 16 / 10,
    autoCropArea:16 / 10,
    height:'200px',
    width:'320px',
    size:10,
    type:'imageUrl',
    dragAbleZone:true,
    text:"блог",
    disabled:(form.statusId === "66e2ec16-03a1-4367-834a-d6b87ee709bd")
  }
  
  const customFunc = (e) => {
    const data = e;
    if(data.length>2040){
      setErrorCus(true)
    }else{
      setErrorCus(false)
      setData(data);
    }
  };

  const customSave = (e) => {
    let resp = { ...form };
    resp.body = e;
    setForm({ ...form, body: resp.body });
    saveEdit(resp);
  };
  const changeHandler = (e) => {
    setForm({ ...form, [e.target.id]: e.target.value });
  };
  const changeStatus = (e) => {
    let data = {};
    data.type = e.target.accessKey;
    data.id = currentId;

    if(data.type==='to-draft'){
      dispatch(addNews(data)).then((res) => {
        getItem();
      });
    }else{
      if(!form.imageUrl || !form.title || !form.body){
        setIsEmpty(true);
        return;
      }
      dispatch(addNews(data)).then((res) => {
        getItem();
      });
    }
    

    
  };

  const getItem = () => {
    dispatch(getNewsItem({ id: currentId }))
      .then((res) => {
        setForm(res);
        setReady(true);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const saveEdit =(data) => {
      setIsEmpty(false);
      dispatch(editNews(data && data.body ? data : form)).then((res) => {
        
      });
    };

  const deleteClick = (e) => {
    dispatch(deleteNews({ id: currentId }))
      .then((res) => {
        navigate("/news");
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    if (!ready) {
      getItem();
    }
  }, [ready]);

  if (ready) {
    return (
      <>
        {alertModal && (
          <InfoWindow
            setModal={setAlertModal}
            message={alertMessage}
          ></InfoWindow>
        )}
        <h1>Створення блогу</h1>

        <div className="menuFunctionLine">
          <div className="actionButtonsBlock">
            <button
              className="secondaryButtonIconTextDefault"
              onClick={() => navigate("/news")}
            >
              <svg>
                <use href="/images/icon24ArrowLeft.svg#icon24" />
              </svg>
              Назад
            </button>
            {form.statusId === "ab653s32-73ba-bcc7-aab3-34ba408c3fg4" && (
              <button
                className="primaryButtonIconTextDefault"
                accessKey="publish"
                onClick={changeStatus}
                disabled={isEmpty}
              >
                <svg>
                  <use href="/images/icon24Rocket.svg#icon24" />
                </svg>
                {"Опублікувати"}
              </button>
            )}
            {form.statusId === "fb5631a2-vg5a-34q7-23b3-111afn8abcg4" && (
              <button
                className="primaryButtonIconTextDefault"
                accessKey="to-draft"
                onClick={changeStatus}
              >
                <svg>
                  <use href="/images/icon24Rocket.svg#icon24" />
                </svg>
                {"Зняти з публікації та редагувати"}
              </button>
            )}
            {form.statusId === "ab653s32-73ba-bcc7-aab3-34ba408c3fg4" && (
              <button
                className="secondaryButtonIconTextDefault"
                onClick={deleteClick}
              >
                <svg>
                  <use href="/images/icon24Trash.svg#icon24" />
                </svg>
                Видалити
              </button>
            )}
          </div>
        </div>
        <div className="pageContentHeaderDivider"></div>

        <div className="videoCard2Columns">
          <div className="videoCardCoverContainer">
            <h3>Назва та обкладинка</h3>
            <div
              style={{ width: "100%" }}
              className={
                form.statusId === "66e2ec16-03a1-4367-834a-d6b87ee709bd"
                  ? "apInputText apDisabled"
                  : `${
                      form.title
                        ? "apInputText apFilled"
                        : isEmpty
                        ? "apInputText apError"
                        : "apInputText"
                    }`
              }
            >
              <label htmlFor="title">Назва *</label>
              <input
                id="title"
                type="text"
                disabled={
                  form.statusId === "66e2ec16-03a1-4367-834a-d6b87ee709bd"
                }
                maxLength={'255'}
                value={form.title}
                onChange={changeHandler}
                onBlur={saveEdit}
                className={isEmpty ? "apInputError" : null}
              />
              {isEmpty && <span className="apError">Заповніть поле </span>}
            </div>
              <FileUploadAndCrop options={options} saveEdit={saveEdit} form={form} setForm={setForm} image={form.imageUrl} setAlertMessage={setAlertMessage} setAlertModal={setAlertModal} />
          </div>
          <div className="videoCardNameDescriptionContainer">
            <img loading="lazy" src="/images/LoginImage.svg" alt="LoginImage Not Found" />
          </div>
        </div>
        <div className="groundPropertiesHeader">
          <h2>Контент</h2>
        </div>
        <Editor
          id="body"
          url={"content-pages"}
          onChange={customFunc}
          maxLength={25000}
          onBlur={customSave}
          value={form.body}
          disabled={
            form.statusId === "fb5631a2-vg5a-34q7-23b3-111afn8abcg4"
          }
        />
         {errorCus && <span className="apError">Не більше 25000 символів</span>}
        {/* <button
          style={{ width: "fit-content" }}
          className="secondaryButtonTextDefault"
          onClick={customSave}
          disabled={form.statusId === "fb5631a2-vg5a-34q7-23b3-111afn8abcg4"}
        >
          Зберегти текст
        </button> */}
      </>
    );
  } else {
    return <Loader />;
  }
};

export default NewsPage;
