import React,{useState} from "react";
import History from "./History/History";
import Plan from "./Plan/Plan";

const Trainings = () => {
  const [modalOpen, setModalOpen] = useState(false);

  const [ready,setReady]=useState(false);

  return (
    <div className="trainingBlock">
      <History setReady={setReady} ready={ready} modalOpen={modalOpen} setModalOpen={setModalOpen}/>
      <Plan modalOpen={modalOpen}  setReady={setReady} setModalOpen={setModalOpen}/>
    </div>
  );
};

export default Trainings;
