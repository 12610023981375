import { useMemo,useState } from "react";
import { useNavigate } from "react-router";
import { Icon } from "../Icon";
import { AdditionalButton } from "../../pages/FCInnerPage/components/AdditionalButton";
import RatingStar from "../RatingStar/RatingStar";
import { useDispatch } from "react-redux";
import { setVoteEvent } from "../../redux/actions/events";

export const Tabbar = (props) => {
  const navigate = useNavigate();
  const dispatch=useDispatch();


  const handleRatingChange = (value,type) => {
    let data={
      id:props.currentId,
    }
    data[type]=value;    
    dispatch(setVoteEvent(data)).then(res=>{
    })
  };


  const data = useMemo(() => {
    if (!props.data || props.data.length === 0) return [];
    return props.data;
  }, [props.data]);

  const path = useMemo(() => {
    if (!props.path) return "/";
    return props.path;
  }, [props]);

  const onClick = (route) => {
    if (props.contentId === route) return;
    navigate(
      `${path}/${props.clubId ? props.clubId + "/" : ""}${
        props.currentId
      }/${route}`
    );
  };
  
  return (
    <div className="apTabbar">
      <nav>
        {data.map(({ icon, children, route, hidden }, index) => {
          
          if (hidden) {
            return <></>;
          } else {
            return (
              <button
                onClick={() => onClick(route)}
                key={index}
                className={props.contentId === route ? "active secondaryGhostButtonIconTextDefault" : "secondaryGhostButtonIconTextDefault"}
                disabled={props.contentId === route}
              >
                <Icon type={icon} />
                {children}
              </button>
            );
          }
        })}
        {props.additional ?? (
          <AdditionalButton additionalClick={props.additionalClick} />
        )}
        {props.additional2 ?? (
          <AdditionalButton additionalClick={props.additionalClick2} />
        )}
        {props.additional3 ?? (
          <AdditionalButton additionalClick={props.additionalClick3} />
        )}
      </nav>
      { props.rating &&
        <div className="tabRating">
          <div>
            <RatingStar defaultRating={props.currentEvent?.eventEstimation||0} type={'eventEstimation'} onChange={handleRatingChange} />
            <p className="ratingText">Оцінка заходу</p>
          </div>
          {/* <div>
            <RatingStar defaultRating={props.currentEvent?.selfEstimation||0} type={'selfEstimation'} onChange={handleRatingChange} />
            <p className="ratingText">Власна оцінка</p>
          </div> */}
        </div>
      }

      {/* {props.additional ? undefined : (
        <button className="apSearchButton">
          <Icon type={"search"} />
        </button>
      )} */}
    </div>
  );
};
