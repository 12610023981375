import React from 'react';
import { useNavigate } from 'react-router-dom';



const Page404 = ()=>{
    const navigate = useNavigate();

    function handleClick() {
        navigate("/");
      }
    return(
    <div className="apFullHeight">
      <h1>Щось пішло не так</h1>
      <h4>Сторінка яку ви шукаєте не знайдена.<br />Можливо вона переміщена або не існує.</h4>
      <button onClick={handleClick} className="primaryButtonTextDefault">Перейти на головну</button>
    </div>
    )

}

export default Page404;