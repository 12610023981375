import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCode } from "../../../redux/actions/auth";



const ModalAcceptComponent =(props)=>{
    const [error, setError] = useState([]);
    const {currentUser}=useSelector(state=>state);
    const [form, setForm] = useState(currentUser.currentUser);
    const [seconds, setSeconds] = useState(0);
    const [selected, setSelected] = useState("");
    const [readyForChange, setReadyForChange] = useState(false);
    const [code, setCode] = useState(false);
    const [checked, setChecked] = useState(false);

    const dispatch = useDispatch();

    const setSelectClick = (e) => {
        let data = { ...form };
        data[e.target.accessKey] = e.target.id;
        props.setForm(data);
        setForm(data);
        setSelected("");
      };
      const changeState =(event)=>{
        event.preventDefault();
        if(form[event.target.id]==1){
          props.setForm({ ...form, [event.target.id]: 0 });
          setForm({ ...form, [event.target.id]: 0 });
        }else{
          props.setForm({ ...form, [event.target.id]: 1 });
          setForm({ ...form, [event.target.id]: 1 });
        }
      }
    
      const selectClick = (e) => {
        if (e.target.id == selected) {
          setSelected("");
        } else {
          setSelected(e.target.id);
        }
      };
    

    const resiveCode = async (event) => {
        try {
          event.preventDefault();
          setSeconds(60);
          setReadyForChange(true);
          dispatch(getCode({ email: form.email }))
            .then((res) => {
              if (res && res.errors && res.errors.length > 0) {
                setError(res.errors)
                setReadyForChange(false);
              }
              setCode(true);
            })
            .catch((e) => {
              console.log(e);
            });
        } catch (e) {
          console.log(e);
        }
      };


    const changeHandler = (event) => {
        props.setForm({ ...form, [event.target.name]: event.target.value });
        setForm({ ...form, [event.target.name]: event.target.value });
      };


    return(<div className="apFilterContainer apFilterContainerHeight" style={{backgroundColor:'var(--apColorLightGray)',maxHeight:'500px', padding:'24px', minWidth:'325px'}} tabIndex="0">
        <div className="authForm">
        <h1 className="" style={{textAlign:'center', fontSize:'16px', margin:'0 0 8px 0', textAlign:'left'}}>
          Уточнення даних для реєстрації
          {/* <h4>Приєднуйтесь</h4> */}
        </h1>
        <div style={{
          'font-size': '22px',
          'position': 'absolute',
          'right': '12px',
          'top': '12px',
          'cursor': 'pointer',
          'font-weight': 'bold'
          }} onClick={()=>props.setModalAccept(false)}>X</div>

        <div style={{fontSize:'15px', marginBottom:'24px'}}>
          Для того, щоб взяти участь у заході просимо надати наступні дані:
        </div>
        <div
        className={
          error.param === "email" ? "apInputText apFilled" : "apInputText"
        }
      >
        <label htmlFor="email">Пошта</label>
        <input
          id="email"
          type="email"
          name="email"
          onChange={changeHandler}
          value={form.email}
        />
        {error.param === "email" && (
          <span className="apError">{error.msg}</span>
        )}
      </div>

      {form.email?.length>2 && form.email!==currentUser.currentUser.email &&   
      <div className="codeBlock">
         <button
          style={{ marginTop: "6px" }}
          className="secondaryButtonTextDefault"
          disabled={seconds > 0}
          onClick={resiveCode}
        >
          Отримати&nbsp;код
        </button>

        <div
          style={{ minWidth: "130px", maxWidth:'140px', marginRight: "10px" }}
          className={
            error.param === "code" ? "apInputText apFilled" : "apInputText"
          }
        >
          <label htmlFor="code">Код</label>
          <input
            id="code"
            type="text"
            name="code"
            onChange={changeHandler}
            value={form.code}
          />
          {error.param === "code" && (
            <span className="apError">{error.msg}</span>
          )}
        </div>
        {readyForChange || seconds > 0 && (
            <span>Отримати код повторно через {seconds}</span>
          )}
      </div>}

        <div
        className={
          error.param === "firstName" ? "apInputText apFilled" : "apInputText"
        }
      >
        <label htmlFor="firstName">Імʼя</label>
        <input
          id="firstName"
          type="text"
          name="firstName"
          value={form.firstName}
          onChange={changeHandler}
        />
         {/* <span className="subtitle">
          
        </span> */}
        {error.param === "firstName" && <span className="apError">{error.msg}</span>}
      </div>
      <div
        className={
          error.param === "lastName" ? "apInputText apFilled" : "apInputText"
        }
      >
        <label htmlFor="lastName">Прізвище</label>
        <input
          id="lastName"
          type="text"
          name="lastName"
          value={form.lastName}
          onChange={changeHandler}
        />
         {/* <span className="subtitle">
          
        </span> */}
        {error.param === "lastName" && <span className="apError">{error.msg}</span>}
      </div>

      <div className="codeBlock cus">
        <div
          style={{ maxWidth: "140px",minWidth: 'auto',
          marginRight: "20px" }}
          className={
            error.param === "sex" ? "apInputText apFilled" : "apInputText"
          }
        >
          <label htmlFor="sex">Стать</label>
              <div style={{ maxWidth: "140px",minWidth: '140px', marginRight: "20px", position:'relative' }} id="sex" onClick={selectClick}>
                <input
                  id="sex"
                  readOnly
                  type="text"
                  value={
                    (form.sex === "male" && "Чоловіча") ||
                    (form.sex === "female" && "Жіноча") ||
                    ""
                  }
                />
                <svg id="sex" style={{position:'absolute',width:'20px', right:'8px', height:'20px'}} onClick={selectClick}>
                  <use
                    id="sex"
                    onClick={selectClick}
                    href="/images/icon24DropDown.svg#icon24"
                  />
                </svg>
                
              </div>
              {selected == "sex" && (
                <div className="apOptionsList">
                  <div
                    onClick={setSelectClick}
                    accessKey="sex"
                    id="male"
                    className={form.sex === "male" ? "apActive":''}
                  >
                    Чоловіча
                  </div>
                  <div
                    onClick={setSelectClick}
                    accessKey="sex"
                    id="female"
                    className={form.sex === "female" ? "apActive":''}
                  >
                    Жіноча
                  </div>
                </div>
              )}
        </div>
        <div
          style={{ minWidth: "130px"}}
          className={
            error.param === "birthday" ? "apInputText apFilled" : "apInputText"
          }
        >
          <label htmlFor="birthday">Дата народження</label>
          <input
            id="birthday"
            type="date"
            value={form.birthday}
            name="birthday"
            onChange={changeHandler}
          />
          {error.param === "birthday" && (
            <span className="apError">{error.msg}</span>
          )}
        </div>
      </div>
      <div style={{marginBottom:'8px', marginTop:'16px'}}>
      Статус особи <span style={{fontWeight:'700', color:'var(--apColorAccent)'}}>(за наявності)</span>
      </div>
      
        <button
          id='isInvalid'
          style={{ marginTop: "6px",minWidth: '140px',marginBottom:'8px' }}
          className={form.isInvalid?"primaryButtonTextDefault auth active":"primaryButtonTextDefault auth"}
          onClick={changeState}
        >
        Особа з інвалідністю
        </button>
       
      <div className="codeBlock cus" >
        <button
            id='isVpo'
            style={{ marginTop: "6px", marginRight:'20px', minWidth: '140px'}}
            className={form.isVpo?"primaryButtonTextDefault auth active":"primaryButtonTextDefault auth"}
            onClick={changeState}
          >
            ВПО
          </button>
        <button
          id='isVeteran'
          style={{ marginTop: "6px",minWidth: '140px' }}
          className={form.isVeteran?"primaryButtonTextDefault auth active":"primaryButtonTextDefault auth"}
          onClick={changeState}
        >
         Ветеран
        </button>
      </div>
      <div className="" style={{ marginBottom: "15px" }}>
        <input
          type="checkbox"
          id="agree"
          onClick={() => setChecked(!checked)}
        />
        <label htmlFor="agree">
          {" "}
          Згода{" "}
          <a
            href="https://ap.sportforall.gov.ua/info/iSdIifLP"
            target="_blank"
          >
            {" "}
            на проведення фотозйомки та публікацію фотографій
          </a>
        </label>
      </div>
      
        <button
          className="primaryButtonTextDefault"
          disabled={!form.birthday || !checked || !form.sex || !form.lastName || !form.firstName || !form.email} 
          onClick={props.joinEvent}
        >
          Приєднатися
        </button>
      </div>

    
    </div>)
}

export default ModalAcceptComponent;