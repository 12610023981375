import {
    CREATEPARK_SUCCESS,
    CREATEPARK_FAIL,
    GETPARK_SUCCESS,
    GETPARK_FAIL,
    GETPARKITEM_SUCCESS,
    GETPARKITEM_FAIL,
    EDITPARK_SUCCESS,
    EDITPARK_FAIL,
    DELETEPARK_SUCCESS,
    DELETEPARK_FAIL,
    CLONEPARK_SUCCESS,
    CLONEPARK_FAIL,
    GETPARKLISTCOORDINATOR_SUCCESS,
    GETPARKLISTCOORDINATOR_FAIL,
    GETPARKMAP_SUCCESS,
    GETPARKMAP_FAIL,
} from "./types";


import ParksService from "../services/parks.service"


export const addPark = (data)=> async(dispatch)=>{
    try {
        const res = await ParksService.addPark(data);
        dispatch({
          type: CREATEPARK_SUCCESS,
          item: res
        });
        return res
    }catch (error){
        dispatch({
            type: CREATEPARK_FAIL,
            item: {error: error.response},
          });
          const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
          return error.response;
    }
}


export const getParks = (data)=> async(dispatch)=>{
    try{
         
        const res = await ParksService.getParks(data);
         
        dispatch({
          type: GETPARK_SUCCESS,
          list: res,
        });
        
        return res
    }catch (error){
         
        dispatch({
            type: GETPARK_FAIL,
            list: {error: error.response},
          });
          const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
          return error.response
    }
}

export const getParksAll = (data)=> async(dispatch)=>{
    try{
        const res = await ParksService.getParksAll(data);
        dispatch({
            type: GETPARK_SUCCESS,
            list: res,
        });
        return res
        
    }catch (error){
         
        dispatch({
            type: GETPARK_FAIL,
            list: {error: error.response},
          });
          const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
          return error.response
    }
}
export const getParksRoutesAll = (data)=> async(dispatch)=>{
    try{
        const res = await ParksService.getParksRoutesAll(data);
        dispatch({
            type: GETPARK_SUCCESS,
            list: res,
        });
        return res
        
    }catch (error){
         
        dispatch({
            type: GETPARK_FAIL,
            list: {error: error.response},
          });
          const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
          return error.response
    }
}
export const getParksRoutesAllMap = (data)=> async(dispatch)=>{
    try{
        const res = await ParksService.getParksRoutesAll(data);
        dispatch({
            type: GETPARKMAP_SUCCESS,
            list: res,
        });
        return res
        
    }catch (error){
         
        dispatch({
            type: GETPARKMAP_FAIL,
            list: {error: error.response},
          });
          const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
          return error.response
    }
}




export const getParksCoordinatorList = (data)=> async(dispatch)=>{
    try{
        
        const res = await ParksService.getParksCoordinatorList(data);
        
        dispatch({
          type: GETPARKLISTCOORDINATOR_SUCCESS,
          list: res,
        });
        
        return res
    }catch (error){
        
        dispatch({
            type: GETPARKLISTCOORDINATOR_FAIL,
            list: {error: error.response},
          });
          const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
          return error.response
    }
}

export const editPark= (form)=>(dispatch)=>{
      
    return ParksService.editPark(form).then(
        (response)=>{
              
            dispatch({
                type:EDITPARK_SUCCESS,
                item:response
            });
              
            return response

        },
        (error)=>{
            dispatch({
                type: EDITPARK_FAIL,
              });
            console.log(error);
              return Promise.reject();
        }
    );
}

export const clonePark= (form)=>(dispatch)=>{
      
    return ParksService.clonePark(form).then(
        (response)=>{
              
            dispatch({
                type:CLONEPARK_SUCCESS,
                item:response
            });
              
            return response

        },
        (error)=>{
            dispatch({
                type: CLONEPARK_FAIL,
              });
            console.log(error);
              return Promise.reject();
        }
    );
}


export const deletePark= (data)=>(dispatch)=>{
      
    return ParksService.deletePark(data).then(
        (response)=>{
            dispatch({
                type:DELETEPARK_SUCCESS,
            });
              
        },
        (error)=>{
            dispatch({
                type: DELETEPARK_FAIL,
              });
            console.log(error);
                 
              return Promise.reject();
        }
    );
}

export const getParkItem = (data)=>(dispatch)=>{
    return ParksService.getParkItem(data).then(
        (response)=>{
              
            dispatch({
                type:GETPARKITEM_SUCCESS,
                item:response,
            });
              
            return response

        },
        (error)=>{
            dispatch({
                type: GETPARKITEM_FAIL,
              });
            console.log(error);
                 
              return Promise.reject();
        }
    );

}