import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import Loader from "../../components/Loader/Loader";
import { getParkItem } from "../../redux/actions/parks";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import Coordinators from "../../components/Coordinators";
import EventsList from "../../components/EventsList";

import "leaflet/dist/leaflet.css";
import L from "leaflet";
import checkText from "../../helpers/checkText";
import getDate from "../../helpers/getDate";

const markerIconMy = new L.Icon({
  iconUrl: require("../../assets/images/myLocation.png"),
  iconSize: [24, 24],
  iconAnchor: [12, 13], //[left/right, top/bottom]
  popupAnchor: [0, 0], //[left/right, top/bottom]
});

const ParkInfoPage = () => {
  const [ready, setReady] = useState(false);
  const dispatch = useDispatch();
  const currentLocationId = useParams().id;
  // const { parks: parkForm } = useSelector((state) => state);
  const { listId } = useSelector((state) => state);
  const [selected, setSelected] = useState("");
  const [parkForm, setParkForm] = useState({});
  const [selectedIndex, setSelectedIndex] = useState(0);
  const navigate = useNavigate();
  const { currentEvent: events } = useSelector((state) => state);
  const currentId = useParams().id;

  const initialFunction = () => {
    dispatch(getParkItem({ id: currentLocationId })).then((res) => {
      setParkForm(res);
      setReady(true);
    });
  };
  const openInNewTab = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  useEffect(() => {
    if (!ready) {
      initialFunction();
    }
  }, [ready]);

  if (ready) {
    return (
      <div className="parkInfoPage">
        <div className="menuFunctionLine">
          <div className="actionButtonsBlock">
            <button
              className="secondaryButtonIconTextDefault"
              onClick={() => navigate("/map")}
            >
              <svg>
                <use href="/images/icon24ArrowLeft.svg#icon24" />
              </svg>
              Назад
            </button>
            <button
              className="primaryButtonTextDefault"
              onClick={() =>
                openInNewTab(
                  `https://www.google.com.ua/maps/place/${parkForm.location[0]}+${parkForm.location[1]}`
                )
              }
            >
              Прокласти маршрут
            </button>
          </div>
        </div>
        <h1 className="apInnerH1">{parkForm.title}
          <div className="headerClubSub">
            {parkForm.capacityId && <> Місткість: <span>{listId.list[parkForm.capacityId]}</span><span className="currentStatusDivider"></span></>} 
            {parkForm.hasLighting==true && <> Освітлення: <span>Присутнє</span><span className="currentStatusDivider"></span></>} 
            {parkForm.conditionId && <> Технічний стан: <span>{listId.list[parkForm.conditionId]}</span><span className="currentStatusDivider"></span></>} 
            {parkForm.facebookUrl && <> Посилання на Facebook: <span> <a href={parkForm.facebookUrl} target="_blank">{checkText(parkForm.facebookUrl)}</a></span><span className="currentStatusDivider"></span></>} 
            {parkForm.accessTypeId && <> Тип доступу: <span>{listId.list[parkForm.accessTypeId]}</span><span className="currentStatusDivider"></span></>} 
            {parkForm.typeId && <> Вид майданчику: <span>{listId.list[parkForm.typeId]}</span><span className="currentStatusDivider"></span></>} 
            {parkForm.ownershipTypeId && <> Форма власності: <span>{listId.list[parkForm.ownershipTypeId]}</span><span className="currentStatusDivider"></span></>} 
            {parkForm.rating && <> Рейтинг користувачів: <span>{parkForm.rating}</span><span className="currentStatusDivider"></span></>} 
            {parkForm.workHours && <> Режим роботи: <span>{parkForm.workHours}</span><span className="currentStatusDivider"></span></>} 
            {parkForm.fitnessEquipment?.length>0 && <> Набір тренажерів: <span>{parkForm.fitnessEquipment.map((el) => listId.list[el] + " ")}</span><span className="currentStatusDivider"></span></>} 
          </div>
        </h1>
       

        <div className="groundPropertiesImageSection">
            <div className="apParkCoverImageContainer parkItem">
              {(parkForm && parkForm.photo && parkForm.photo.length > 0) || (parkForm.photos?.length>0) ? (
                <div
                  onClick={()=>{setSelected('selected');setSelectedIndex(selectedIndex<parkForm.photos?.length-1 ?selectedIndex+1:0)}}
                  style={{
                    backgroundImage: `linear-gradient(rgba(68, 79, 96, 0.1), rgba(68, 79, 96, 0.1)), url('${
                      selected ? parkForm.photos[selectedIndex] : (parkForm.photo || (parkForm.photos?.length>0 &&  parkForm.photos[0]) || "https://ap.sportforall.gov.ua/api/v1/uploads/b34604xc-02qb-46bb-9e91-fc3ffrb92w41")
                    }')`,
                  }}
                >
                  <span></span>
                </div>
              ) : (
                ""
              )}
            </div>
            <div className="parkItemImageList">
              {parkForm &&
                parkForm.photos &&
                parkForm.photos.length > 0 &&
                parkForm.photos.map((element, index) => {
                  return (
                    <div
                      key={index}
                      onClick={() => {setSelected(element);setSelectedIndex(index)}}
                      style={{
                        cursor: "pointer",
                      }}
                      className="apParkImage"
                    >
                      <img loading="lazy" src={element} alt={`${element}.${index}`} />
                    </div>
                  );
                })}
            </div>
          </div>
          <h3 style={{ marginTop: "20px!important" }}>Локація:
            <div className="headerClubSub">
              {parkForm.regionId && <> Адреса: <span> {(parkForm.regionId
                  ? listId.list[parkForm.regionId] + " "
                  : "") +
                  (parkForm.districtId
                    ? listId.list[parkForm.districtId] + " "
                    : "") +
                  (parkForm.city ? parkForm.city + " " : "") +
                  (parkForm.street ? parkForm.street + " " : "")}
                  </span><span className="currentStatusDivider"></span></>} 
            {parkForm.location && <> Координати: <span> {parkForm.location &&
                  parkForm.location.length > 0 &&
                  <a href={'https://www.google.com/maps/place/'+parkForm.location[0]+'+'+parkForm.location[1]} target="_blank">{parkForm.location[0] + " " + parkForm.location[1]}</a>
                  }</span><span className="currentStatusDivider"></span></>} 

            </div>
          </h3>

          <MapContainer
                  style={{ height: 600 }}
                  center={
                    parkForm.location
                      ? [parkForm.location[0], parkForm.location[1]]
                      : [50.4393, 30.5479]
                  }
                  zoom={15}
                  scrollWheelZoom={true}
                >
                  <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://openstreetmap.pp.ua/hot/{z}/{x}/{y}.png"
                    />
                  {parkForm.location && (
                    <Marker
                      position={[parkForm.location[0], parkForm.location[1]]}
                      icon={markerIconMy}
                    >
                      <Popup>
                        <div
                          className="apgmtt2"
                          style={{
                            backgroundImage: `URL(${parkForm.photo||(parkForm.photos?.length>0 && parkForm.photos[0])||"https://ap.sportforall.gov.ua/api/v1/uploads/b34604xc-02qb-46bb-9e91-fc3ffrb92w41"})`,
                          }}
                        ></div>

                        <a
                          onClick={() => navigator(`/parkInfo/${parkForm.id}`)}
                          className="apgmtt3"
                        >
                          {parkForm.title.length > 0
                            ? parkForm.title
                            : parkForm.code}
                        </a>

                        <div className="buttonControl">
                          <button
                            className="primaryButtonTextDefault"
                            onClick={() =>
                              openInNewTab(
                                `https://www.google.com.ua/maps/place/${parkForm.location[0]}+${parkForm.location[1]}`
                              )
                            }
                          >
                            Прокласти маршрут
                          </button>
                          <button
                            className="secondaryButtonTextDefault"
                            onClick={() =>
                              navigator(`/parkInfo/${parkForm.id}`)
                            }
                          >
                            Інформація
                          </button>
                        </div>

                        <div className="apgmtt4">
                          <div className="apPublished">Опубліковано</div>
                        </div>
                      </Popup>
                    </Marker>
                  )}
                </MapContainer>
        
            {parkForm?.coordinators?.length !== 0 && (
              <Coordinators data={parkForm.coordinators} />
            )}

        <h3 className="headerHelpedCustom" style={{ marginTop:"20px" }}>Заходи</h3>

        <EventsList data={parkForm.sportEvents} fallback={"Жодного запланованого заходу"} />
      </div>
    );
  } else {
    return (
      <div style={{ position: "relative" }}>
        <div style={{ position: "absolute", top: "50%", left: "50%" }}>
          <Loader />
        </div>
      </div>
    );
  }
};

export default ParkInfoPage;
