import {
    ADDITIONALDICTIONARIESLIST_SUCCESS,
    ADDITIONALDICTIONARIESLIST_FAIL
}from "../actions/types";

const initialState = [];

export default function dictionariesReducer(dictionaryAdditional = initialState, action) {
    const { type,list } = action;
      
    switch (type) {
          case ADDITIONALDICTIONARIESLIST_FAIL:
          return dictionaryAdditional;
          case ADDITIONALDICTIONARIESLIST_SUCCESS:
              
          return [...list];
        default:
          return dictionaryAdditional;
    }
}