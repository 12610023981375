import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { retrieveUsers, updateCoordinator } from "../../redux/actions/users";
import Loader from "../../components/Loader/Loader";
import Filter from "./Filter/Filter";
import Pagination from "../../components/Paggination/Paggination";
import { useNavigate } from "react-router";
import Select from "../../components/Select/";
import getDate from "../../helpers/getDate";
import setPhoneMask from "../../helpers/setPhoneMask";

const sortOtions = [
  { id: "updatedAt", value: "За датою редагування" },
  { id: "lastName", value: "За абеткою" },
];

const UsersCoordinatorApplyPage = () => {
  const [select, setSelect] = useState(false);
  const dispatch = useDispatch();
  const [searchOpen, setSearchOpen] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [intervalId,setIntervalId] = useState(null);  const [ready, setReady] = useState(false);
  const { users } = useSelector((state) => state);
  const [sortData, setSortData] = useState("updatedAt");
  const [sortName, setSortName] = useState("За датою редагування");
  const [sortValue, setSortValue] = useState("asc");
  const [filter, setFilter] = useState(false);
  const [filterArr, setFilterArr] = useState({});
  const { listId } = useSelector((state) => state);
  const [currentPage, setCurrentPage] = useState(1);
  const navigate = useNavigate();

  const searchChange = (e) => {
    let filter = {};
    setSearchValue(e.target.value);
    if (localStorage.getItem("usersCoordinatorsFilter")) {
      filter = JSON.parse(localStorage.getItem("usersCoordinatorsFilter"));
    }
    filter.all = [];
        clearTimeout(intervalId);
    const newIntervalId = setTimeout(() =>{
      // Call your search function with the updated query value
    
      if (e.target.value.length > 2) {
        filter.all[0] = e.target.value;
        localStorage.setItem("usersCoordinatorsFilter", JSON.stringify(filter));
        initFunction(1, filter);
      } else {
        localStorage.setItem("usersCoordinatorsFilter", JSON.stringify(filter));
        initFunction(1, filter);
      }
    }, 1000)
    setIntervalId(newIntervalId);  ;
  };

  const resetFilter = () => {
    localStorage.removeItem("usersCoordinatorsFilter");
    setFilterArr({});
    initFunction();
  };
  const initFunction = (page = 1, filter) => {
    let data = {};
    if (filter) {
      data.filter = { ...filter };
    } else {
      if (localStorage.getItem("usersCoordinatorsFilter")) {
        data.filter = { ...JSON.parse(localStorage.getItem("usersCoordinatorsFilter")) };
      } else {
        data.filter = {};
      }
    }
    if (localStorage.getItem("usersCoordinatorsSort")) {
      data.sort = { ...JSON.parse(localStorage.getItem("usersCoordinatorsSort")) };
    } else {
      data.sort = { updatedAt: "asc" };
    }
    data.filter.roleId = ["a9329d2e-42b1-4ad3-88e8-442ea4123cfa"];
    data.offset = (page - 1) * 10;
    data.limit = 10;
    dispatch(retrieveUsers(data)).then((res) => {
      setReady(true);
    });
  };
  const sortClick = (e) => {
    setSortName(e.target.innerHTML);
    const currentIdIndex = sortOtions.findIndex(
      (x) => x.value === e.target.innerHTML
    );
    setSortData(sortOtions[currentIdIndex].id);
    let data = {};
    if (sortData === sortOtions[currentIdIndex].id && sortValue === "asc") {
      setSortValue("desc");
      data[sortOtions[currentIdIndex].id] = "desc";
    } else {
      data[sortOtions[currentIdIndex].id] = "asc";
      setSortValue("asc");
    }
    localStorage.setItem("usersCoordinatorsSort", JSON.stringify(data));
    initFunction();
    setSelect(false);
  };

  const sendClick = (e) => {
    let data = {};
    data.id = e.target?.id;
    data.type = e.currentTarget?.accessKey;
    dispatch(updateCoordinator(data)).then((res) => {
      setCurrentPage(1);
      initFunction(1);
    });
  };

  useEffect(() => {
    if (localStorage.getItem("usersCoordinatorsFilter")) {
      let filter = JSON.parse(localStorage.getItem("usersCoordinatorsFilter"));
      initFunction(1, filter);
      setFilterArr(filter);
    } else {
      initFunction();
    }
  }, []);

  if (!ready || !users || !users.items || !listId.list) {
    return (
      <div style={{ position: "relative" }}>
        {" "}
        <div style={{ position: "absolute", top: "50%", left: "50%" }}>
          <Loader />
        </div>{" "}
      </div>
    );
  }

  if (ready && users && users.items) {
    return (
      <>
        {filter && (
          <Filter
            setModal={setFilter}
            setFilter={setFilterArr}
            getList={initFunction}
          ></Filter>
        )}
        <div className="headerWithButtonsCommonBlock">
          <div className="headerWithButtonsHeaderBlock">
            <h1>Користувачі</h1>
          </div>
          <div className="headerWithButtonsButtonBlock">
            {searchOpen && (
              <div style={{ marginBottom: "4px" }} className="apInputText">
                <label htmlFor="search">Пошук</label>
                <input
                  id="search"
                  type="text"
                  value={searchValue}
                  placeholder="Мінімум 3 символи"
                  onChange={searchChange}
                />
              </div>
            )}
            <button
              className="secondaryButtonIconDefault"
              onClick={() => setSearchOpen(!searchOpen)}
            >
              <svg>
                <use href="/images/icon24Search.svg#icon24" />
              </svg>
            </button>
          </div>
        </div>
        <div className="sortAndFilterBlock">
          <div className="filterBlock">
            <div className="filterButtonWrap">
              <button
                className="secondaryButtonIconTextDefault"
                onClick={() => setFilter(true)}
              >
                <svg>
                  <use href="/images/icon24Filter.svg#icon24" />
                </svg>
                Фільтр
              </button>
              {filterArr && Object.keys(filterArr).length > 0 && (
                <button
                  onClick={resetFilter}
                  className="secondarySmallRoundedButtonTextDefault filterClearButton"
                />
              )}
            </div>
            {filterArr && Object.keys(filterArr).length > 0 && (
              <div className="apFilterWithSelected">
                <span>Знайдено {users.total} осіб:</span>
                <div className="apFilterSelectedValues">
                  {Object.keys(filterArr).map((el) => {
                    return (
                      filterArr[el].length > 0 &&
                      filterArr[el].map((elem, index) => {
                        return (
                          <div className="apChipsWrap" key={elem.id || index}>
                            <div className="apChips">{listId.list[elem]}</div>
                          </div>
                        );
                      })
                    );
                  })}
                </div>
              </div>
            )}
          </div>
          <div className="sortBlock">
            <Select
              label={"Сортування:"}
              value={sortName}
              options={sortOtions}
              onSet={sortClick}
            />
          </div>
        </div>
        <div className="pageContentHeaderDivider" />
        <div className="dictAllRowsContainer">
          {users &&
            users.items.map((el) => {
              return (
                <div key={el.id} className="usersRowNormal">
                  <div className="userValue">
                    {el.photo ? (
                      <div
                        className="userValueImage"
                        style={{
                          backgroundImage: `url(${el.photo})`,
                          backgroundSize: "cover",
                          backgroundPosition: "center",
                        }}
                      ></div>
                    ) : (
                      <div className="userValueImage">
                        {el.nickname[0].toUpperCase() +
                          el.nickname[1].toUpperCase()}
                      </div>
                    )}
                    <div
                      className="userValueName"
                      onClick={() => navigate(`/personalPage/${el.id}`)}
                    >
                      <span>
                        {el.firstName && el.lastName
                          ? el.firstName  + " " + el.lastName
                          : el.nickname}
                      </span>
                      {listId.list[el.roleId]}
                    </div>
                  </div>
                  <div className="userValue">
                    <span> {listId.list[el.regionId]}</span>
                    <span> {listId.list[el.districtId]}</span>
                    <span>{el.city}</span>
                  </div>
                  <div className="userValue">
                    <span className="userValueNumbers">{getDate(el.updatedAt,'dd/mm/yyyy hh:mm','.')}</span>
                    Змінено
                  </div>
                  <div className="userValue">
                    <span className="userValueNumbers">{setPhoneMask(el.phone)}</span>
                    <span className="userValueEmail">{el.email}</span>
                  </div>
                  <div className="userValue">
                    <button
                      onClick={sendClick}
                      id={el.id}
                      accessKey="confirm-to-coordinators"
                      name="sportsground-conditions"
                      className="tetriaryButtonIconDefault"
                    >
                      <svg id={el.id} name="sportsground-conditions">
                        <use href="/images/icon24CheckCircle.svg#icon24" />
                      </svg>
                    </button>
                    <button
                      id={el.id}
                      accessKey="reject-from-coordinators"
                      onClick={sendClick}
                      className="tetriaryButtonIconDefault"
                    >
                      <svg id={el.id}>
                        <use href="/images/icon24CancelCircle.svg#icon24" />
                      </svg>
                    </button>
                  </div>
                </div>
              );
            })}
        </div>
        {users.total > 10 && (
          <Pagination
            className="pagination-bar"
            currentPage={currentPage}
            totalCount={users.total.toFixed(0)}
            pageSize="10"
            onPageChange={async (page) => {
              await setCurrentPage(page);
              await initFunction(page);
            }}
          />
        )}
      </>
    );
  }
};

export default UsersCoordinatorApplyPage;
