import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../components/Loader/Loader";
import Pagination from "../../components/Paggination/Paggination";
import { getLog } from "../../redux/actions/log";

const LogPage = () => {
  const [ready, setReady] = useState(false);
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const { listId } = useSelector((state) => state);
  const { log } = useSelector((state) => state);
  const [filterArr, setFilterArr] = useState({});
  const [selected, setSelected] = useState("");
  const [form, setForm] = useState({});
  const [select, setSelect] = useState(false);

  const getList = (page = 1, filter) => {
    setReady(false);
    let data = { offset: (page - 1) * 10, limit: 10 };
    if (filter) {
      data.filter = { ...filter };
      localStorage.setItem("logFilter", JSON.stringify(filter));
    } else {
      if (localStorage.getItem("logFilter")) {
        data.filter = { ...JSON.parse(localStorage.getItem("logFilter")) };
      } else {
        data.filter = {};
      }
    }
    if (localStorage.getItem("logSort")) {
      data.sort = { ...JSON.parse(localStorage.getItem("logSort")) };
    } else {
      data.sort = { timestamp: "desc" };
    }
    dispatch(getLog(data))
      .then((res) => {
        setReady(true);
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const changeHandler = (e) => {
    setForm({ ...form, [e.target.id]: e.target.value });
  };
  const changeSelect = (e) => {
    setForm({ ...form, ["object_type"]: e.target.id });
  };
  const resetClick = () => {
    setForm({});
    setFilterArr({});
    setCurrentPage(1);
    setReady(false);
    getList(1, {});
  };
  const selectClick = (e) => {
    setSelected(e.target.id);
  };
  const objectClick = (e) => {
    e.preventDefault();
    let link = `${window.location.origin}/`;
    if (
      e.target.accessKey === "sportsground" ||
      e.target.accessKey === "coordinator"
    ) {
      link += "sportsground/" + e.target.id + "/info";
    } else if (e.target.accessKey === "video") {
      link += "videoItem/" + e.target.id + "/info";
    } else if (e.target.accessKey === "user") {
      link += "personalPage/" + e.target.id;
    }
    window.open(link);
  };
  useEffect(() => {
    if (!ready) {
      if (localStorage.getItem("logFilter")) {
        let filter = JSON.parse(localStorage.getItem("logFilter"));
        getList(1, filter);
        setFilterArr(filter);
        setForm(filter);
      } else {
        getList();
      }
    }
  }, []);

  if (!ready || !listId.list || !log.items) {
    return (
      <div style={{ position: "relative" }}>
        {" "}
        <div style={{ position: "absolute", top: "50%", left: "50%" }}>
          <Loader />
        </div>{" "}
      </div>
    );
  } else if (ready && log) {
    return (
      <>
        <div className="headerWithButtonsCommonBlock">
          <div className="headerWithButtonsHeaderBlock">
            <h1>Журнал подій</h1>
          </div>
        </div>
        <div className="menuFunctionLine">
          <div className="logActionButtonsBlock">
            <div className="apInputText">
              <label htmlFor="user_id">userId</label>
              <input
                id="user_id"
                type="text"
                value={form.user_id}
                onChange={changeHandler}
              />
            </div>
            <div className="apDropDownList" onClick={() => setSelect(!select)}>
              <label htmlFor="object_type ">Тип об'єкту</label>
              <div>
                <input
                  id="object_type "
                  readOnly
                  type="text"
                  value={form.object_type}
                />
                <svg>
                  <use href="images/icon24DropDown.svg#icon24" />
                </svg>
              </div>
              {select && (
                <div className="apOptionsList">
                  <div onClick={changeSelect} id="coordinator">
                    coordinator
                  </div>
                  <div onClick={changeSelect} id="user">
                    user
                  </div>
                  <div onClick={changeSelect} id="video">
                    video
                  </div>
                  <div onClick={changeSelect} id="sportsground">
                    sportsground
                  </div>
                </div>
              )}
            </div>
            <div className="apInputText">
              <label htmlFor="object_id">objectId</label>
              <input
                id="object_id"
                type="text"
                value={form.object_id}
                onChange={changeHandler}
              />
            </div>
            <button
              disabled={!(form.object_id || form.object_type || form.user_id)}
              className="primaryButtonIconTextDefault"
              onClick={() => getList(1, form)}
            >
              <svg>
                <use href="images/icon24Search.svg#icon24" />
              </svg>
              Пошук
            </button>
            {(form.object_id || form.object_type || form.user_id) && (
              <button
                className="primaryButtonIconTextDefault"
                style={{ "margin-left": "5px" }}
                onClick={resetClick}
              >
                <svg>
                  <use href="images/icon24Cancel.svg#icon24" />
                </svg>
                Скинути
              </button>
            )}
          </div>
        </div>
        <div className="pageContentHeaderDivider" />
        <div className="dictAllRowsContainer">
          {log &&
            log.items.map((el, index) => {
              return (
                <React.Fragment key={index}>
                  <div className="logRowNormal">
                    <div className="apLogValue">
                      <span>
                        {new Date(el.timestamp).toLocaleDateString("uk-UA", {
                          day: "numeric",
                          month: "numeric",
                          year: "numeric",
                          hour: "2-digit",
                          minute: "2-digit",
                          second: "2-digit",
                        })}
                      </span>
                      (дата та час)
                    </div>
                    <div className="apLogValue">
                      <a onClick={objectClick} accessKey="user" id={el.userId}>
                        {el.user?.nickname || 'Невідомий нікнейм'}
                      </a>
                      (userId користувача, що виконав дію)
                    </div>
                    <div className="apLogValue">
                      <span>{el.action}</span>
                      (дія)
                    </div>
                    <div className="apLogValue">
                      <span>{el.objectType}</span>
                      (тип об'єкту)
                    </div>
                    <div className="apLogValue">
                      {el.action === "deleted" ? (
                        <span>{el.objectId}</span>
                      ) : (
                        <a
                          onClick={objectClick}
                          accessKey={el.objectType}
                          id={el.objectId}
                        >
                          {el.objectId}
                        </a>
                      )}
                      (objectId обєкту, що було змінено)
                    </div>
                    <div className="apLogValue">
                      <button
                        className="secondaryButtonIconDefault"
                        id={el.timestamp}
                        onClick={selectClick}
                      >
                        <svg id={el.timestamp}>
                          <use
                            id={el.timestamp}
                            href="images/icon24Info.svg#icon24"
                          />
                        </svg>
                      </button>
                    </div>
                  </div>

                  {selected === el.timestamp && (
                    <div className="apFilterContainer" id="logRecordFullData">
                      <div className="apFilterHeader">
                        <h4>Перегляд збережених даних</h4>
                        <svg onClick={() => setSelected("")}>
                          <use href="images/icon24Cancel.svg#icon24" />
                        </svg>
                      </div>
                      <div
                        className="apFilterGroupBlock"
                        style={{ "overflow-wrap": "anywhere" }}
                      >
                        {el.data.replace(/","/g, '", "')}
                        {/* «id»: «0e8e1dfd-fc0b-487c-aaf2-0c46154dea07»,<br />
                «city»: «с. Матвіївка»,<br />
                «code»: «AP0015»,<br />
                «owner»: {'{'}«id»:«5a24efa8-eb40-4beb-a07d-acad36bc6a43», «lastName»:«admin», «firstName»:«admin», «secondName»:«admin»{'}'},<br />
                «photo»: «https://activeparks-api.minsport.org.ua/api/v1/uploads/4d13efed-aff1-4117-b46b-d8c8da1ec2c1.jpg»,<br />
                «title»: «»,<br />
                «photos»: [«https://activeparks-api.minsport.org.ua/api/v1/uploads/85fccd87-94b6-40b2-bd1c-172b74ecbe38.jpg», «https://activeparks-api.minsport.org.ua/api/v1/uploads/8b5011ff-4f9b-435a-893d-f279a6d9b5dc.jpg», «https://activeparks-api.minsport.org.ua/api/v1/uploads/bdf5c7fb-942b-4c04-b4f7-6e05049e523b.jpg», «https://activeparks-api.minsport.org.ua/api/v1/uploads/4d13efed-aff1-4117-b46b-d8c8da1ec2c1.jpg», «https://activeparks-api.minsport.org.ua/api/v1/uploads/00546257-f750-4a74-ad5e-849644f3b4ec.jpg»],<br />
                «rating»: null,<br />
                «street»: «вул. Центральна, 77А (Паркова зона)»,<br />
                «typeId»: «9276fa87-b14a-4dc1-ac1f-df8b8ed0c4f8»,<br />
                «location»: [47.9024072, 35.2924645],<br />
                «regionId»: «e5b3d0e2-e66a-48ff-86c6-d9b43852a08e»,<br />
                «statusId»: «036735ba-f634-469b-ac30-77a164e3a918»,<br />
                «createdAt»: «2021-11-22 14:57:05»,<br />
                «createdBy»: «5a24efa8-eb40-4beb-a07d-acad36bc6a43»,<br />
                «deletedAt»: null,<br />
                «deletedBy»: null,<br />
                «ownerName»: «»,<br />
                «updatedAt»: «2021-12-10 12:59:23»,<br />
                «updatedBy»: «5a24efa8-eb40-4beb-a07d-acad36bc6a43»,<br />
                «workHours»: «»,<br />
                «capacityId»: «»,<br />
                «districtId»: «b34be16b-f8fc-490c-96eb-fd790cbca890»,<br />
                «ownerPhone»: «»,<br />
                «conditionId»: «54701233-ab2b-4eab-b4c2-9eef9fa3c5d5»,<br />
                «description»: «»,<br />
                «facebookUrl»: «»,<br />
                «hasLighting»: 1,<br />
                «accessTypeId»: «aef1943a-f244-401d-956e-65c4b0ae5ef8»,<br />
                «coordinators»: [],<br />
                «ownerDetails»: «»,<br />
                «messagesCount»: 0,<br />
                «ownershipTypeId»: «»,<br />
                «fitnessEquipment»: [«8d8d1172-eb18-490f-83fb-5501ef087393», «c33cdc91-384a-4873-9159-2648a25d373f», «ae5d9720-428e-4e44-bae2-f2539db7ae2e», «81c8ae6e-56c2-423f-b8eb-d85c2c91dfb7», «37a04d9e-3ceb-4436-8be9-53a68722f523», «34e25282-27f8-4aeb-88f3-602e26b1a1b8», «54be2f1d-8cdf-442e-afe2-07944c2939a4», «87391b7b-ac74-41a4-a9d0-0e7e2388a3af»],<br />
                «onReconstruction»: false,<br />
                «coordinatorsCount»: 0,<br />
                «registryCertificate»: «»,<br />
                «ownershipCertificate»: «» */}
                      </div>
                    </div>
                  )}
                </React.Fragment>
              );
            })}
        </div>
        <div className="apPaginator">
          {log.total > 10 && (
            <Pagination
              className="pagination-bar"
              currentPage={currentPage}
              totalCount={log.total}
              pageSize="10"
              onPageChange={async (page) => {
                await setCurrentPage(page);
                await getList(page);
              }}
            />
          )}
        </div>
      </>
    );
  }
};

export default LogPage;
