import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import InfoWindow from "../../components/InfoWindow/InfoWindow";
import {
  addDictionariesItem,
  deleteDictionariesItem,
  editDictionariesItem,
  getDictionariesList,
  getAdditionalDictionariesList,
} from "../../redux/actions/dictionaries";
import Loader from "./../../components/Loader/Loader";

const DirectoryPage = () => {
  const [selectedType, setSelectedType] = useState("");
  const [selectedAdditionalType, setSelectedAdditionalType] = useState("");
  const [newElement, setNewElement] = useState("");
  const [newAdditionalElement, setNewAdditionalElement] = useState("");
  const dispatch = useDispatch();
  const [ready, setReady] = useState(false);
  const [readyAdd, setReadyAdd] = useState(false);
  const { dictionaries } = useSelector((state) => state);
  const { dictionariesAdditional } = useSelector((state) => state);
  const [form, setForm] = useState({});
  const [editElement, setEditElement] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteAdditionalModal, setDeleteAdditionalModal] = useState(false);
  const [deleteElement, setDeleteElement] = useState("");
  const clickHandler = (e) => {
    setSelectedAdditionalType("");
    if (e.target.id === selectedType) {
      setSelectedType("");
    } else if (e.target.id !== selectedType) {
      setReady(false);
      setSelectedType(e.target.id);
      dispatch(getDictionariesList(e.target.id))
        .then((res) => {
          setReady(true);
        })
        .catch((e) => {
          console.log(e);
          setReady(true);
        });
    }
  };
  const additionalClick = (e) => {
    if (e.target.id === selectedAdditionalType) {
      setSelectedAdditionalType("");
    } else if (e.target.id !== selectedAdditionalType) {
      setSelectedAdditionalType(e.target.id);
      setReadyAdd(false);
      dispatch(getAdditionalDictionariesList(e.target.id))
        .then((res) => {
          setReadyAdd(true);
        })
        .catch((e) => {
          console.log(e);
          setReadyAdd(true);
        });
    }
  };

  const additionalSubClick = (e) => {
    if (e.target.id === selectedAdditionalType) {
      setSelectedAdditionalType("");
    } else if (e.target.id !== selectedAdditionalType) {
      setSelectedAdditionalType(e.target.id);
    }
  };

  const getAdditionalList = () => {
    setReadyAdd(false);
    dispatch(getAdditionalDictionariesList(selectedAdditionalType))
      .then((res) => {
        setReadyAdd(true);
      })
      .catch((e) => {
        console.log(e);
        setReadyAdd(true);
      });
  };

  const getList = (e) => {
    setReady(false);
    dispatch(getDictionariesList(selectedType))
      .then((res) => {
        setReady(true);
      })
      .catch((e) => {
        console.log(e);
        setReady(true);
      });
  };

  const addHandler = (e) => {
    if (e.target.id == newElement) {
      setNewElement("");
    } else if (e.target.id != newElement) {
      setNewElement(e.target.id);
    }
  };

  const addaAdditionalHandler = (e) => {
    if (e.target.id == newAdditionalElement) {
      setNewAdditionalElement("");
      setSelectedAdditionalType("");
    } else if (e.target.id != newAdditionalElement) {
      setNewAdditionalElement(e.target.id);
      setSelectedAdditionalType(e.target.id);
      setForm({});
    }
  };

  const changeHandler = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const saveAdd = (e) => {
    let data = { ...form };
    data.category = newElement;
    dispatch(addDictionariesItem(data)).then((res) => {
      addHandler(e);
      getList();
      setForm({});
    });
  };
  const saveAddAdditional = (e) => {
    let data = { ...form };
    data.category = "districts";
    data.regionId = newAdditionalElement;
    dispatch(addDictionariesItem(data)).then((res) => {
      // addaAdditionalHandler(e)
      setForm({});
      getAdditionalList();
    });
  };
  const saveAddAdditionalCategory = (e) => {
    let data = { ...form };
    data.category = e.target.id;
    data.parentId = selectedAdditionalType;

    dispatch(addDictionariesItem(data)).then((res) => {
      // addaAdditionalHandler(e)
      setForm({});
      getList();
    });
  };

  const editClick = (e) => {
    setEditElement(e.target.id);
    setForm({});
  };

  const saveEdit = (e) => {
    let data = { ...form };
    data.category = selectedType;
    data.id = e.target.id;
    dispatch(editDictionariesItem(data))
      .then((res) => {
        setEditElement("");
        getList();
        setForm({});
      })
      .catch((e) => {
        console.log(e);
        setReady(true);
      });
  };
  const saveEditAdditional = (e) => {
    let data = { ...form };
    data.category = "districts";
    data.id = e.target.id;
    dispatch(editDictionariesItem(data))
      .then((res) => {
        setEditElement("");
        getList();
        setForm({});
        getAdditionalList();
      })
      .catch((e) => {
        console.log(e);
        setReady(true);
      });
  };
  const saveEditAdditionalSub = (e) => {
    let data = { ...form };
    data.category = "exercise-subcategories";
    data.id = e.target.id;
    data.parentId = e.target.slot;
    dispatch(editDictionariesItem(data))
      .then((res) => {
        setEditElement("");
        getList();
        setForm({});
      })
      .catch((e) => {
        console.log(e);
        setReady(true);
      });
  };

  const deleteClick = (e) => {
    setDeleteModal(true);
    setDeleteElement(e.target.id);
  };
  const deleteAdditionalClick = (e) => {
    setDeleteAdditionalModal(true);
    setDeleteElement(e.target.id);
  };
  const deleteSubmit = (e) => {
    let data = {};
    data.category = selectedType;
    data.id = deleteElement;
    dispatch(deleteDictionariesItem(data)).then((res) => {
      setDeleteModal(false);
      getList();
    });
  };
  const deleteAdditionalSubmit = (e) => {
    let data = {};
    data.category = "districts";
    data.id = deleteElement;
    dispatch(deleteDictionariesItem(data)).then((res) => {
      setDeleteAdditionalModal(false);
      getList();
      getAdditionalList();
    });
  };
  const deleteAdditionalSubSubmit = (e) => {
    let data = {};
    data.category = "exercise-subcategories";
    data.id = deleteElement;
    dispatch(deleteDictionariesItem(data)).then((res) => {
      setDeleteAdditionalModal(false);
      getList();
      getAdditionalList();
    });
  };

  return (
    <>
      {deleteModal && (
        <InfoWindow
          setClick={deleteSubmit}
          setModal={setDeleteModal}
          buttonMsg={"Видалити"}
          message={"Ви впевнені, що хочете видалити?"}
        ></InfoWindow>
      )}
      {deleteAdditionalModal && selectedType === "regions" && (
        <InfoWindow
          setClick={deleteAdditionalSubmit}
          setModal={setDeleteAdditionalModal}
          buttonMsg={"Видалити"}
          message={"Ви впевнені, що хочете видалити?"}
        ></InfoWindow>
      )}
      {deleteAdditionalModal && selectedType !== "regions" && (
        <InfoWindow
          setClick={deleteAdditionalSubSubmit}
          setModal={setDeleteAdditionalModal}
          buttonMsg={"Видалити"}
          message={"Ви впевнені, що хочете видалити?"}
        ></InfoWindow>
      )}
      <h1>Довідники</h1>
      <div className="pageContentHeaderDivider">&nbsp;</div>
      <div
        className="dictionaryHeaderWithButton"
        id="file-types"
        onClick={clickHandler}
      >
        <h2
          id="file-types"
          className={selectedType === "file-types" && "active" || ''}
          onClick={clickHandler}
        >
          Типи файлів
        </h2>
        {/* <button id='file-types' onClick={addHandler} className="secondaryButtonIconTextDefault">
              <svg><use  href="/images/icon24Plus.svg#icon24" /></svg>
              Елемент довідника
            </button> */}
      </div>

      {selectedType === "file-types" && (
        <div className="dictAllRowsContainer">
          {!ready && (
            <div style={{ position: "relative" }}>
              {" "}
              <div style={{ position: "absolute", top: "50%", left: "50%" }}>
                <Loader />
              </div>{" "}
            </div>
          )}
          {dictionaries.length > 0 ? (
            <>
              {dictionaries.map((el, index) => {
                return (
                  <div key={index} className="dictRowNormal">
                    <div className="dictRowValuesContainer">
                      {editElement !== el.id ? (
                        <div className="dictRowValue">{el.title}</div>
                      ) : (
                        <div className="dictRowValue">
                          <div className="apInputText">
                            <input
                              type="text"
                              id={el.id}
                              name="title"
                              value={form.title ? form.title : el.title}
                              onChange={changeHandler}
                            />
                          </div>
                        </div>
                      )}
                      {editElement !== el.id ? (
                        <div className="dictRowValue">{el.type}</div>
                      ) : (
                        <div className="dictRowValue">
                          <div className="apInputText">
                            <input
                              type="text"
                              id={el.id}
                              name="type"
                              value={form.type ? form.type : el.type}
                              onChange={changeHandler}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="dictRowButtonsContainer">
                      {editElement !== el.id ? (
                        <button
                          id={el.id}
                          onClick={editClick}
                          className="tetriaryButtonIconDefault"
                        >
                          <svg id={el.id}>
                            <use
                              id={el.id}
                              href="/images/icon24Edit.svg#icon24"
                            />
                          </svg>
                        </button>
                      ) : (
                        <button
                          onClick={saveEdit}
                          id={el.id}
                          name="file-types"
                          className="tetriaryButtonIconDefault"
                        >
                          <svg id={el.id} name="file-types">
                            <use
                              id={el.id}
                              href="/images/icon24CheckCircle.svg#icon24"
                            />
                          </svg>
                        </button>
                      )}
                      {editElement === el.id && (
                        <button
                          onClick={() => {
                            setEditElement("");
                          }}
                          className="tetriaryButtonIconDefault"
                        >
                          <svg>
                            <use href="/images/icon24CancelCircle.svg#icon24" />
                          </svg>
                        </button>
                      )}
                    </div>
                  </div>
                );
              })}
            </>
          ) : (
            <div className="dictRowEmpty">Довідник не заповнено</div>
          )}
        </div>
      )}

      <div
        className="dictionaryHeaderWithButton"
        id="coordinator-statuses"
        onClick={clickHandler}
      >
        <h2
          id="coordinator-statuses"
          className={selectedType === "coordinator-statuses" && "active" || ''}
          onClick={clickHandler}
        >
          Статус координатора
        </h2>
        {/* <button id='coordinator-statuses' onClick={addHandler} className="secondaryButtonIconTextDefault">
              <svg><use  href="/images/icon24Plus.svg#icon24" /></svg>
              Елемент довідника
            </button> */}
      </div>
      {/* {newElement=='coordinator-statuses' &&<div className="dictRowNormal">
              <div className="dictRowValuesContainer">
                <div className="dictRowValue">
                  <div className="apInputText">
                    <input type="text" name='title' value={form.value} onChange={changeHandler}/>
                  </div>
                </div>
                <div className="dictRowButtonsContainer">
                  <button onClick={saveAdd} id='coordinator-statuses' className="tetriaryButtonIconDefault"><svg id='coordinator-statuses'><use href="/images/icon24CheckCircle.svg#icon24" /></svg></button>
                  <button onClick={addHandler} id='coordinator-statuses' className="tetriaryButtonIconDefault"><svg ><use href="/images/icon24Cancel.svg#icon24" /></svg></button>
                </div>
              </div>
            </div>} */}
      {selectedType === "coordinator-statuses" && (
        <div className="dictAllRowsContainer">
          {!ready && (
            <div style={{ position: "relative" }}>
              {" "}
              <div style={{ position: "absolute", top: "50%", left: "50%" }}>
                <Loader />
              </div>{" "}
            </div>
          )}
          {dictionaries.length > 0 ? (
            <>
              {dictionaries.map((el, index) => {
                return (
                  <div key={index} className="dictRowNormal">
                    <div className="dictRowValuesContainer">
                      {editElement !== el.id ? (
                        <input
                          type="text"
                          id={el.id}
                          className="dictRowValue"
                          disabled={editElement !== el.id}
                          value={el.title}
                        />
                      ) : (
                        <div className="dictRowValue">
                          <div className="apInputText">
                            <input
                              type="text"
                              id={el.id}
                              name="title"
                              value={form.title ? form.title : el.title}
                              onChange={changeHandler}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="dictRowButtonsContainer">
                      {editElement !== el.id ? (
                        <button
                          id={el.id}
                          onClick={editClick}
                          className="tetriaryButtonIconDefault"
                        >
                          <svg id={el.id}>
                            <use
                              id={el.id}
                              href="/images/icon24Edit.svg#icon24"
                            />
                          </svg>
                        </button>
                      ) : (
                        <button
                          onClick={saveEdit}
                          id={el.id}
                          name="coordinator-statuses"
                          className="tetriaryButtonIconDefault"
                        >
                          <svg id={el.id} name="coordinator-statuses">
                            <use href="/images/icon24CheckCircle.svg#icon24" />
                          </svg>
                        </button>
                      )}
                      {editElement !== el.id ? (
                        <button
                          id={el.id}
                          onClick={deleteClick}
                          className="tetriaryButtonIconDefault"
                        >
                          <svg id={el.id}>
                            <use
                              id={el.id}
                              href="/images/icon24Cancel.svg#icon24"
                            />
                          </svg>
                        </button>
                      ) : (
                        <button
                          onClick={() => {
                            setEditElement("");
                          }}
                          className="tetriaryButtonIconDefault"
                        >
                          <svg>
                            <use href="/images/icon24CancelCircle.svg#icon24" />
                          </svg>
                        </button>
                      )}
                    </div>
                  </div>
                );
              })}
            </>
          ) : (
            <div className="dictRowEmpty">Довідник не заповнено</div>
          )}
        </div>
      )}

      <div
        className="dictionaryHeaderWithButton"
        id="video-statuses"
        onClick={clickHandler}
      >
        <h2
          id="video-statuses"
          className={selectedType === "video-statuses" && "active" || ''}
          onClick={clickHandler}
        >
          Статус відеоролика
        </h2>
        {/* <button id='video-statuses' onClick={addHandler} className="secondaryButtonIconTextDefault">
              <svg><use  href="/images/icon24Plus.svg#icon24" /></svg>
              Елемент довідника
            </button> */}
      </div>
      {/* {newElement=='video-statuses' &&<div className="dictRowNormal">
              <div className="dictRowValuesContainer">
                <div className="dictRowValue">
                  <div className="apInputText">
                    <input type="text" name='title' value={form.value} onChange={changeHandler}/>
                  </div>
                </div>
                <div className="dictRowButtonsContainer">
                  <button onClick={saveAdd} id='video-statuses' className="tetriaryButtonIconDefault"><svg id='video-statuses'><use href="/images/icon24CheckCircle.svg#icon24" /></svg></button>
                  <button onClick={addHandler} id='video-statuses' className="tetriaryButtonIconDefault"><svg ><use href="/images/icon24Cancel.svg#icon24" /></svg></button>
                </div>
              </div>
            </div>} */}
      {selectedType === "video-statuses" && (
        <div className="dictAllRowsContainer">
          {!ready && (
            <div style={{ position: "relative" }}>
              {" "}
              <div style={{ position: "absolute", top: "50%", left: "50%" }}>
                <Loader />
              </div>{" "}
            </div>
          )}
          {dictionaries.length > 0 ? (
            <>
              {dictionaries.map((el, index) => {
                return (
                  <div key={index} className="dictRowNormal">
                    <div className="dictRowValuesContainer">
                      {editElement !== el.id ? (
                        <input
                          type="text"
                          id={el.id}
                          className="dictRowValue"
                          disabled={editElement !== el.id}
                          value={el.title}
                        />
                      ) : (
                        <div className="dictRowValue">
                          <div className="apInputText">
                            <input
                              type="text"
                              id={el.id}
                              name="title"
                              value={form.title ? form.title : el.title}
                              onChange={changeHandler}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="dictRowButtonsContainer">
                      {editElement !== el.id ? (
                        <button
                          id={el.id}
                          onClick={editClick}
                          className="tetriaryButtonIconDefault"
                        >
                          <svg id={el.id}>
                            <use
                              id={el.id}
                              href="/images/icon24Edit.svg#icon24"
                            />
                          </svg>
                        </button>
                      ) : (
                        <button
                          onClick={saveEdit}
                          id={el.id}
                          name="video-statuses"
                          className="tetriaryButtonIconDefault"
                        >
                          <svg id={el.id} name="video-statuses">
                            <use href="/images/icon24CheckCircle.svg#icon24" />
                          </svg>
                        </button>
                      )}
                      {editElement !== el.id ? (
                        <button
                          id={el.id}
                          onClick={deleteClick}
                          className="tetriaryButtonIconDefault"
                        >
                          <svg id={el.id}>
                            <use
                              id={el.id}
                              href="/images/icon24Cancel.svg#icon24"
                            />
                          </svg>
                        </button>
                      ) : (
                        <button
                          onClick={() => {
                            setEditElement("");
                          }}
                          className="tetriaryButtonIconDefault"
                        >
                          <svg>
                            <use href="/images/icon24CancelCircle.svg#icon24" />
                          </svg>
                        </button>
                      )}
                    </div>
                  </div>
                );
              })}
            </>
          ) : (
            <div className="dictRowEmpty">Довідник не заповнено</div>
          )}
        </div>
      )}

      <div
        className="dictionaryHeaderWithButton"
        id="sportsground-statuses"
        onClick={clickHandler}
      >
        <h2
          id="sportsground-statuses"
          className={selectedType === "sportsground-statuses" && "active" || ''}
          onClick={clickHandler}
        >
          Статус майданчика
        </h2>
        {/* <button id='sportsground-statuses' onClick={addHandler} className="secondaryButtonIconTextDefault">
              <svg><use  href="/images/icon24Plus.svg#icon24" /></svg>
              Елемент довідника
            </button> */}
      </div>
      {/* {newElement=='sportsground-statuses' &&<div className="dictRowNormal">
              <div className="dictRowValuesContainer">
                <div className="dictRowValue">
                  <div className="apInputText">
                    <input type="text" name='title' value={form.value} onChange={changeHandler}/>
                  </div>
                </div>
                <div className="dictRowButtonsContainer">
                  <button onClick={saveAdd} id='sportsground-statuses' className="tetriaryButtonIconDefault"><svg id='sportsground-statuses'><use href="/images/icon24CheckCircle.svg#icon24" /></svg></button>
                  <button onClick={addHandler} id='sportsground-statuses' className="tetriaryButtonIconDefault"><svg ><use href="/images/icon24Cancel.svg#icon24" /></svg></button>
                </div>
              </div>
            </div>} */}
      {selectedType === "sportsground-statuses" && (
        <div className="dictAllRowsContainer">
          {!ready && (
            <div style={{ position: "relative" }}>
              {" "}
              <div style={{ position: "absolute", top: "50%", left: "50%" }}>
                <Loader />
              </div>{" "}
            </div>
          )}
          {dictionaries.length > 0 ? (
            <>
              {dictionaries.map((el, index) => {
                return (
                  <div key={index} className="dictRowNormal">
                    <div className="dictRowValuesContainer">
                      {editElement !== el.id ? (
                        <input
                          type="text"
                          id={el.id}
                          className="dictRowValue"
                          disabled={editElement !== el.id}
                          value={el.title}
                        />
                      ) : (
                        <div className="dictRowValue">
                          <div className="apInputText">
                            <input
                              type="text"
                              id={el.id}
                              name="title"
                              value={form.title ? form.title : el.title}
                              onChange={changeHandler}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="dictRowButtonsContainer">
                      {editElement !== el.id ? (
                        <button
                          id={el.id}
                          onClick={editClick}
                          className="tetriaryButtonIconDefault"
                        >
                          <svg id={el.id}>
                            <use
                              id={el.id}
                              href="/images/icon24Edit.svg#icon24"
                            />
                          </svg>
                        </button>
                      ) : (
                        <button
                          onClick={saveEdit}
                          id={el.id}
                          name="sportsground-statuses"
                          className="tetriaryButtonIconDefault"
                        >
                          <svg id={el.id} name="sportsground-statuses">
                            <use href="/images/icon24CheckCircle.svg#icon24" />
                          </svg>
                        </button>
                      )}
                      {editElement !== el.id ? (
                        <button
                          id={el.id}
                          onClick={deleteClick}
                          className="tetriaryButtonIconDefault"
                        >
                          <svg id={el.id}>
                            <use
                              id={el.id}
                              href="/images/icon24Cancel.svg#icon24"
                            />
                          </svg>
                        </button>
                      ) : (
                        <button
                          onClick={() => {
                            setEditElement("");
                          }}
                          className="tetriaryButtonIconDefault"
                        >
                          <svg>
                            <use href="/images/icon24CancelCircle.svg#icon24" />
                          </svg>
                        </button>
                      )}
                    </div>
                  </div>
                );
              })}
            </>
          ) : (
            <div className="dictRowEmpty">Довідник не заповнено</div>
          )}
        </div>
      )}

      <div
        className="dictionaryHeaderWithButton"
        id="sportsground-conditions"
        onClick={clickHandler}
      >
        <h2
          id="sportsground-conditions"
          className={selectedType === "sportsground-conditions" && "active" || ''}
          onClick={clickHandler}
        >
          Стан майданчика
        </h2>
        <button
          id="sportsground-conditions"
          onClick={addHandler}
          className="secondaryButtonIconTextDefault"
        >
          <svg>
            <use href="/images/icon24Plus.svg#icon24" />
          </svg>
          Елемент довідника
        </button>
      </div>
      {newElement == "sportsground-conditions" && (
        <div className="dictRowNormal">
          <div className="dictRowValuesContainer">
            <div className="dictRowValue">
              <div className="apInputText">
                <input
                  type="text"
                  name="title"
                  value={form.value}
                  onChange={changeHandler}
                />
              </div>
            </div>
            <div className="dictRowButtonsContainer">
              <button
                onClick={saveAdd}
                id="sportsground-conditions"
                className="tetriaryButtonIconDefault"
              >
                <svg id="sportsground-conditions">
                  <use href="/images/icon24CheckCircle.svg#icon24" />
                </svg>
              </button>
              <button
                onClick={addHandler}
                id="sportsground-conditions"
                className="tetriaryButtonIconDefault"
              >
                <svg>
                  <use href="/images/icon24Cancel.svg#icon24" />
                </svg>
              </button>
            </div>
          </div>
        </div>
      )}
      {selectedType === "sportsground-conditions" && (
        <div className="dictAllRowsContainer">
          {!ready && (
            <div style={{ position: "relative" }}>
              {" "}
              <div style={{ position: "absolute", top: "50%", left: "50%" }}>
                <Loader />
              </div>{" "}
            </div>
          )}
          {dictionaries.length > 0 ? (
            <>
              {dictionaries.map((el, index) => {
                return (
                  <div key={index} className="dictRowNormal">
                    <div className="dictRowValuesContainer">
                      {editElement !== el.id ? (
                        <input
                          type="text"
                          id={el.id}
                          className="dictRowValue"
                          disabled={editElement !== el.id}
                          value={el.title}
                        />
                      ) : (
                        <div className="dictRowValue">
                          <div className="apInputText">
                            <input
                              type="text"
                              id={el.id}
                              name="title"
                              value={form.title ? form.title : el.title}
                              onChange={changeHandler}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="dictRowButtonsContainer">
                      {editElement !== el.id ? (
                        <button
                          id={el.id}
                          onClick={editClick}
                          className="tetriaryButtonIconDefault"
                        >
                          <svg id={el.id}>
                            <use
                              id={el.id}
                              href="/images/icon24Edit.svg#icon24"
                            />
                          </svg>
                        </button>
                      ) : (
                        <button
                          onClick={saveEdit}
                          id={el.id}
                          name="sportsground-conditions"
                          className="tetriaryButtonIconDefault"
                        >
                          <svg id={el.id} name="sportsground-conditions">
                            <use href="/images/icon24CheckCircle.svg#icon24" />
                          </svg>
                        </button>
                      )}
                      {editElement !== el.id ? (
                        <button
                          id={el.id}
                          onClick={deleteClick}
                          className="tetriaryButtonIconDefault"
                        >
                          <svg id={el.id}>
                            <use
                              id={el.id}
                              href="/images/icon24Cancel.svg#icon24"
                            />
                          </svg>
                        </button>
                      ) : (
                        <button
                          onClick={() => {
                            setEditElement("");
                          }}
                          className="tetriaryButtonIconDefault"
                        >
                          <svg>
                            <use href="/images/icon24CancelCircle.svg#icon24" />
                          </svg>
                        </button>
                      )}
                    </div>
                  </div>
                );
              })}
            </>
          ) : (
            <div className="dictRowEmpty">Довідник не заповнено</div>
          )}
        </div>
      )}

      <div
        className="dictionaryHeaderWithButton"
        id="sportsground-capacities"
        onClick={clickHandler}
      >
        <h2
          id="sportsground-capacities"
          className={selectedType === "sportsground-capacities" && "active" || ''}
          onClick={clickHandler}
        >
          Місткість майданчика
        </h2>
        <button
          id="sportsground-capacities"
          onClick={addHandler}
          className="secondaryButtonIconTextDefault"
        >
          <svg>
            <use href="/images/icon24Plus.svg#icon24" />
          </svg>
          Елемент довідника
        </button>
      </div>
      {newElement == "sportsground-capacities" && (
        <div className="dictRowNormal">
          <div className="dictRowValuesContainer">
            <div className="dictRowValue">
              <div className="apInputText">
                <input
                  type="text"
                  name="title"
                  value={form.value}
                  onChange={changeHandler}
                />
              </div>
            </div>
            <div className="dictRowButtonsContainer">
              <button
                onClick={saveAdd}
                id="sportsground-capacities"
                className="tetriaryButtonIconDefault"
              >
                <svg id="sportsground-capacities">
                  <use href="/images/icon24CheckCircle.svg#icon24" />
                </svg>
              </button>
              <button
                onClick={addHandler}
                id="sportsground-capacities"
                className="tetriaryButtonIconDefault"
              >
                <svg>
                  <use href="/images/icon24Cancel.svg#icon24" />
                </svg>
              </button>
            </div>
          </div>
        </div>
      )}
      {selectedType === "sportsground-capacities" && (
        <div className="dictAllRowsContainer">
          {!ready && (
            <div style={{ position: "relative" }}>
              {" "}
              <div style={{ position: "absolute", top: "50%", left: "50%" }}>
                <Loader />
              </div>{" "}
            </div>
          )}
          {dictionaries.length > 0 ? (
            <>
              {dictionaries.map((el, index) => {
                return (
                  <div key={index} className="dictRowNormal">
                    <div className="dictRowValuesContainer">
                      {editElement !== el.id ? (
                        <input
                          type="text"
                          id={el.id}
                          className="dictRowValue"
                          disabled={editElement !== el.id}
                          value={el.title}
                        />
                      ) : (
                        <div className="dictRowValue">
                          <div className="apInputText">
                            <input
                              type="text"
                              id={el.id}
                              name="title"
                              value={form.title ? form.title : el.title}
                              onChange={changeHandler}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="dictRowButtonsContainer">
                      {editElement !== el.id ? (
                        <button
                          id={el.id}
                          onClick={editClick}
                          className="tetriaryButtonIconDefault"
                        >
                          <svg id={el.id}>
                            <use
                              id={el.id}
                              href="/images/icon24Edit.svg#icon24"
                            />
                          </svg>
                        </button>
                      ) : (
                        <button
                          onClick={saveEdit}
                          id={el.id}
                          name="sportsground-capacities"
                          className="tetriaryButtonIconDefault"
                        >
                          <svg id={el.id} name="sportsground-capacities">
                            <use href="/images/icon24CheckCircle.svg#icon24" />
                          </svg>
                        </button>
                      )}
                      {editElement !== el.id ? (
                        <button
                          id={el.id}
                          onClick={deleteClick}
                          className="tetriaryButtonIconDefault"
                        >
                          <svg id={el.id}>
                            <use
                              id={el.id}
                              href="/images/icon24Cancel.svg#icon24"
                            />
                          </svg>
                        </button>
                      ) : (
                        <button
                          onClick={() => {
                            setEditElement("");
                          }}
                          className="tetriaryButtonIconDefault"
                        >
                          <svg>
                            <use href="/images/icon24CancelCircle.svg#icon24" />
                          </svg>
                        </button>
                      )}
                    </div>
                  </div>
                );
              })}
            </>
          ) : (
            <div className="dictRowEmpty">Довідник не заповнено</div>
          )}
        </div>
      )}

      <div
        className="dictionaryHeaderWithButton"
        id="sportsground-access-types"
        onClick={clickHandler}
      >
        <h2
          id="sportsground-access-types"
          className={selectedType === "sportsground-access-types" && "active" || ''}
          onClick={clickHandler}
        >
          Тип доступу до майданчика
        </h2>
        <button
          id="sportsground-access-types"
          onClick={addHandler}
          className="secondaryButtonIconTextDefault"
        >
          <svg>
            <use href="/images/icon24Plus.svg#icon24" />
          </svg>
          Елемент довідника
        </button>
      </div>
      {newElement == "sportsground-access-types" && (
        <div className="dictRowNormal">
          <div className="dictRowValuesContainer">
            <div className="dictRowValue">
              <div className="apInputText">
                <input
                  type="text"
                  name="title"
                  value={form.value}
                  onChange={changeHandler}
                />
              </div>
            </div>
            <div className="dictRowButtonsContainer">
              <button
                onClick={saveAdd}
                id="sportsground-access-types"
                className="tetriaryButtonIconDefault"
              >
                <svg id="sportsground-access-types">
                  <use href="/images/icon24CheckCircle.svg#icon24" />
                </svg>
              </button>
              <button
                onClick={addHandler}
                id="sportsground-access-types"
                className="tetriaryButtonIconDefault"
              >
                <svg>
                  <use href="/images/icon24Cancel.svg#icon24" />
                </svg>
              </button>
            </div>
          </div>
        </div>
      )}
      {selectedType === "sportsground-access-types" && (
        <div className="dictAllRowsContainer">
          {!ready && (
            <div style={{ position: "relative" }}>
              {" "}
              <div style={{ position: "absolute", top: "50%", left: "50%" }}>
                <Loader />
              </div>{" "}
            </div>
          )}
          {dictionaries.length > 0 ? (
            <>
              {dictionaries.map((el, index) => {
                return (
                  <div key={index} className="dictRowNormal">
                    <div className="dictRowValuesContainer">
                      {editElement !== el.id ? (
                        <input
                          type="text"
                          id={el.id}
                          className="dictRowValue"
                          disabled={editElement !== el.id}
                          value={el.title}
                        />
                      ) : (
                        <div className="dictRowValue">
                          <div className="apInputText">
                            <input
                              type="text"
                              id={el.id}
                              name="title"
                              value={form.title ? form.title : el.title}
                              onChange={changeHandler}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="dictRowButtonsContainer">
                      {editElement !== el.id ? (
                        <button
                          id={el.id}
                          onClick={editClick}
                          className="tetriaryButtonIconDefault"
                        >
                          <svg id={el.id}>
                            <use
                              id={el.id}
                              href="/images/icon24Edit.svg#icon24"
                            />
                          </svg>
                        </button>
                      ) : (
                        <button
                          onClick={saveEdit}
                          id={el.id}
                          name="sportsground-access-types"
                          className="tetriaryButtonIconDefault"
                        >
                          <svg id={el.id} name="sportsground-access-types">
                            <use href="/images/icon24CheckCircle.svg#icon24" />
                          </svg>
                        </button>
                      )}
                      {editElement !== el.id ? (
                        <button
                          id={el.id}
                          onClick={deleteClick}
                          className="tetriaryButtonIconDefault"
                        >
                          <svg id={el.id}>
                            <use
                              id={el.id}
                              href="/images/icon24Cancel.svg#icon24"
                            />
                          </svg>
                        </button>
                      ) : (
                        <button
                          onClick={() => {
                            setEditElement("");
                          }}
                          className="tetriaryButtonIconDefault"
                        >
                          <svg>
                            <use href="/images/icon24CancelCircle.svg#icon24" />
                          </svg>
                        </button>
                      )}
                    </div>
                  </div>
                );
              })}
            </>
          ) : (
            <div className="dictRowEmpty">Довідник не заповнено</div>
          )}
        </div>
      )}

      <div
        className="dictionaryHeaderWithButton"
        id="ownership-types"
        onClick={clickHandler}
      >
        <h2
          id="ownership-types"
          className={selectedType === "ownership-types" && "active" || ''}
          onClick={clickHandler}
        >
          Форма власності майданчика
        </h2>
        <button
          id="ownership-types"
          onClick={addHandler}
          className="secondaryButtonIconTextDefault"
        >
          <svg>
            <use href="/images/icon24Plus.svg#icon24" />
          </svg>
          Елемент довідника
        </button>
      </div>
      {newElement == "ownership-types" && (
        <div className="dictRowNormal">
          <div className="dictRowValuesContainer">
            <div className="dictRowValue">
              <div className="apInputText">
                <input
                  type="text"
                  name="title"
                  value={form.value}
                  onChange={changeHandler}
                />
              </div>
            </div>
            <div className="dictRowButtonsContainer">
              <button
                onClick={saveAdd}
                id="ownership-types"
                className="tetriaryButtonIconDefault"
              >
                <svg id="ownership-types">
                  <use href="/images/icon24CheckCircle.svg#icon24" />
                </svg>
              </button>
              <button
                onClick={addHandler}
                id="ownership-types"
                className="tetriaryButtonIconDefault"
              >
                <svg>
                  <use href="/images/icon24Cancel.svg#icon24" />
                </svg>
              </button>
            </div>
          </div>
        </div>
      )}
      {selectedType === "ownership-types" && (
        <div className="dictAllRowsContainer">
          {!ready && (
            <div style={{ position: "relative" }}>
              {" "}
              <div style={{ position: "absolute", top: "50%", left: "50%" }}>
                <Loader />
              </div>{" "}
            </div>
          )}
          {dictionaries.length > 0 ? (
            <>
              {dictionaries.map((el, index) => {
                return (
                  <div key={index} className="dictRowNormal">
                    <div className="dictRowValuesContainer">
                      {editElement !== el.id ? (
                        <input
                          type="text"
                          id={el.id}
                          className="dictRowValue"
                          disabled={editElement !== el.id}
                          value={el.title}
                        />
                      ) : (
                        <div className="dictRowValue">
                          <div className="apInputText">
                            <input
                              type="text"
                              id={el.id}
                              name="title"
                              value={form.title ? form.title : el.title}
                              onChange={changeHandler}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="dictRowButtonsContainer">
                      {editElement !== el.id ? (
                        <button
                          id={el.id}
                          onClick={editClick}
                          className="tetriaryButtonIconDefault"
                        >
                          <svg id={el.id}>
                            <use
                              id={el.id}
                              href="/images/icon24Edit.svg#icon24"
                            />
                          </svg>
                        </button>
                      ) : (
                        <button
                          onClick={saveEdit}
                          id={el.id}
                          name="ownership-types"
                          className="tetriaryButtonIconDefault"
                        >
                          <svg id={el.id} name="ownership-types">
                            <use href="/images/icon24CheckCircle.svg#icon24" />
                          </svg>
                        </button>
                      )}
                      {editElement !== el.id ? (
                        <button
                          id={el.id}
                          onClick={deleteClick}
                          className="tetriaryButtonIconDefault"
                        >
                          <svg id={el.id}>
                            <use
                              id={el.id}
                              href="/images/icon24Cancel.svg#icon24"
                            />
                          </svg>
                        </button>
                      ) : (
                        <button
                          onClick={() => {
                            setEditElement("");
                          }}
                          className="tetriaryButtonIconDefault"
                        >
                          <svg>
                            <use href="/images/icon24CancelCircle.svg#icon24" />
                          </svg>
                        </button>
                      )}
                    </div>
                  </div>
                );
              })}
            </>
          ) : (
            <div className="dictRowEmpty">Довідник не заповнено</div>
          )}
        </div>
      )}

      <div
        className="dictionaryHeaderWithButton"
        id="fitness-equipment"
        onClick={clickHandler}
      >
        <h2
          id="fitness-equipment"
          className={selectedType === "fitness-equipment" && "active" || ''}
          onClick={clickHandler}
        >
          Види тренажерів
        </h2>
        <button
          id="fitness-equipment"
          onClick={addHandler}
          className="secondaryButtonIconTextDefault"
        >
          <svg>
            <use href="/images/icon24Plus.svg#icon24" />
          </svg>
          Елемент довідника
        </button>
      </div>
      {newElement == "fitness-equipment" && (
        <div className="dictRowNormal">
          <div className="dictRowValuesContainer">
            <div className="dictRowValue">
              <div className="apInputText">
                <input
                  type="text"
                  name="title"
                  value={form.value}
                  onChange={changeHandler}
                />
              </div>
            </div>
            <div className="dictRowButtonsContainer">
              <button
                onClick={saveAdd}
                id="fitness-equipment"
                className="tetriaryButtonIconDefault"
              >
                <svg id="fitness-equipment">
                  <use href="/images/icon24CheckCircle.svg#icon24" />
                </svg>
              </button>
              <button
                onClick={addHandler}
                id="fitness-equipment"
                className="tetriaryButtonIconDefault"
              >
                <svg>
                  <use href="/images/icon24Cancel.svg#icon24" />
                </svg>
              </button>
            </div>
          </div>
        </div>
      )}
      {selectedType === "fitness-equipment" && (
        <div className="dictAllRowsContainer">
          {!ready && (
            <div style={{ position: "relative" }}>
              {" "}
              <div style={{ position: "absolute", top: "50%", left: "50%" }}>
                <Loader />
              </div>{" "}
            </div>
          )}
          {dictionaries.length > 0 ? (
            <>
              {dictionaries.map((el, index) => {
                return (
                  <div key={index} className="dictRowNormal">
                    <div className="dictRowValuesContainer">
                      {editElement !== el.id ? (
                        <input
                          type="text"
                          id={el.id}
                          className="dictRowValue"
                          disabled={editElement !== el.id}
                          value={el.title}
                        />
                      ) : (
                        <div className="dictRowValue">
                          <div className="apInputText">
                            <input
                              type="text"
                              id={el.id}
                              name="title"
                              value={form.title ? form.title : el.title}
                              onChange={changeHandler}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="dictRowButtonsContainer">
                      {editElement !== el.id ? (
                        <button
                          id={el.id}
                          onClick={editClick}
                          className="tetriaryButtonIconDefault"
                        >
                          <svg id={el.id}>
                            <use
                              id={el.id}
                              href="/images/icon24Edit.svg#icon24"
                            />
                          </svg>
                        </button>
                      ) : (
                        <button
                          onClick={saveEdit}
                          id={el.id}
                          name="fitness-equipment"
                          className="tetriaryButtonIconDefault"
                        >
                          <svg id={el.id} name="fitness-equipment">
                            <use href="/images/icon24CheckCircle.svg#icon24" />
                          </svg>
                        </button>
                      )}
                      {editElement !== el.id ? (
                        <button
                          id={el.id}
                          onClick={deleteClick}
                          className="tetriaryButtonIconDefault"
                        >
                          <svg id={el.id}>
                            <use
                              id={el.id}
                              href="/images/icon24Cancel.svg#icon24"
                            />
                          </svg>
                        </button>
                      ) : (
                        <button
                          onClick={() => {
                            setEditElement("");
                          }}
                          className="tetriaryButtonIconDefault"
                        >
                          <svg>
                            <use href="/images/icon24CancelCircle.svg#icon24" />
                          </svg>
                        </button>
                      )}
                    </div>
                  </div>
                );
              })}
            </>
          ) : (
            <div className="dictRowEmpty">Довідник не заповнено</div>
          )}
        </div>
      )}

      <div
        className="dictionaryHeaderWithButton"
        id="sportsground-types"
        onClick={clickHandler}
      >
        <h2
          id="sportsground-types"
          className={selectedType === "sportsground-types" && "active" || ''}
          onClick={clickHandler}
        >
          Вид майданчика
        </h2>
        <button
          id="sportsground-types"
          onClick={addHandler}
          className="secondaryButtonIconTextDefault"
        >
          <svg>
            <use href="/images/icon24Plus.svg#icon24" />
          </svg>
          Елемент довідника
        </button>
      </div>
      {newElement == "sportsground-types" && (
        <div className="dictRowNormal">
          <div className="dictRowValuesContainer">
            <div className="dictRowValue">
              <div className="apInputText">
                <input
                  type="text"
                  name="title"
                  value={form.value}
                  onChange={changeHandler}
                />
              </div>
            </div>
            <div className="dictRowButtonsContainer">
              <button
                onClick={saveAdd}
                id="sportsground-types"
                className="tetriaryButtonIconDefault"
              >
                <svg id="sportsground-types">
                  <use href="/images/icon24CheckCircle.svg#icon24" />
                </svg>
              </button>
              <button
                onClick={addHandler}
                id="sportsground-types"
                className="tetriaryButtonIconDefault"
              >
                <svg>
                  <use href="/images/icon24Cancel.svg#icon24" />
                </svg>
              </button>
            </div>
          </div>
        </div>
      )}
      {selectedType === "sportsground-types" && (
        <div className="dictAllRowsContainer">
          {!ready && (
            <div style={{ position: "relative" }}>
              {" "}
              <div style={{ position: "absolute", top: "50%", left: "50%" }}>
                <Loader />
              </div>{" "}
            </div>
          )}
          {dictionaries.length > 0 ? (
            <>
              {dictionaries.map((el, index) => {
                return (
                  <div key={index} className="dictRowNormal">
                    <div className="dictRowValuesContainer">
                      {editElement !== el.id ? (
                        <input
                          type="text"
                          id={el.id}
                          className="dictRowValue"
                          disabled={editElement !== el.id}
                          value={el.title}
                        />
                      ) : (
                        <div className="dictRowValue">
                          <div className="apInputText">
                            <input
                              type="text"
                              id={el.id}
                              name="title"
                              value={form.title ? form.title : el.title}
                              onChange={changeHandler}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="dictRowButtonsContainer">
                      {editElement !== el.id ? (
                        <button
                          id={el.id}
                          onClick={editClick}
                          className="tetriaryButtonIconDefault"
                        >
                          <svg id={el.id}>
                            <use
                              id={el.id}
                              href="/images/icon24Edit.svg#icon24"
                            />
                          </svg>
                        </button>
                      ) : (
                        <button
                          onClick={saveEdit}
                          id={el.id}
                          name="sportsground-types"
                          className="tetriaryButtonIconDefault"
                        >
                          <svg id={el.id} name="sportsground-types">
                            <use href="/images/icon24CheckCircle.svg#icon24" />
                          </svg>
                        </button>
                      )}
                      {editElement !== el.id ? (
                        <button
                          id={el.id}
                          onClick={deleteClick}
                          className="tetriaryButtonIconDefault"
                        >
                          <svg id={el.id}>
                            <use
                              id={el.id}
                              href="/images/icon24Cancel.svg#icon24"
                            />
                          </svg>
                        </button>
                      ) : (
                        <button
                          onClick={() => {
                            setEditElement("");
                          }}
                          className="tetriaryButtonIconDefault"
                        >
                          <svg>
                            <use href="/images/icon24CancelCircle.svg#icon24" />
                          </svg>
                        </button>
                      )}
                    </div>
                  </div>
                );
              })}
            </>
          ) : (
            <div className="dictRowEmpty">Довідник не заповнено</div>
          )}
        </div>
      )}

      <div
        className="dictionaryHeaderWithButton"
        id="exercise-difficulty-levels"
        onClick={clickHandler}
      >
        <h2
          id="exercise-difficulty-levels"
          className={selectedType === "exercise-difficulty-levels" && "active" || ''}
          onClick={clickHandler}
        >
          Рівень складності вправ
        </h2>
        {/* <button id='exercise-difficulty-levels' onClick={addHandler} className="secondaryButtonIconTextDefault">
              <svg><use  href="/images/icon24Plus.svg#icon24" /></svg>
              Елемент довідника
            </button> */}
      </div>
      {/* {newElement=='exercise-difficulty-levels' &&<div className="dictRowNormal">
              <div className="dictRowValuesContainer">
                <div className="dictRowValue">
                  <div className="apInputText">
                    <input type="text" name='title' value={form.value} onChange={changeHandler}/>
                  </div>
                </div>
                <div className="dictRowButtonsContainer">
                  <button onClick={saveAdd} id='exercise-difficulty-levels' className="tetriaryButtonIconDefault"><svg id='exercise-difficulty-levels'><use href="/images/icon24CheckCircle.svg#icon24" /></svg></button>
                  <button onClick={addHandler} id='exercise-difficulty-levels' className="tetriaryButtonIconDefault"><svg ><use href="/images/icon24Cancel.svg#icon24" /></svg></button>
                </div>
              </div>
            </div>} */}
      {selectedType === "exercise-difficulty-levels" && (
        <div className="dictAllRowsContainer">
          {!ready && (
            <div style={{ position: "relative" }}>
              {" "}
              <div style={{ position: "absolute", top: "50%", left: "50%" }}>
                <Loader />
              </div>{" "}
            </div>
          )}
          {dictionaries.length > 0 ? (
            <>
              {dictionaries.map((el, index) => {
                return (
                  <div key={index} className="dictRowNormal">
                    <div className="dictRowValuesContainer">
                      {editElement !== el.id ? (
                        <input
                          type="text"
                          id={el.id}
                          className="dictRowValue"
                          disabled={editElement !== el.id}
                          value={el.title}
                        />
                      ) : (
                        <div className="dictRowValue">
                          <div className="apInputText">
                            <input
                              type="text"
                              id={el.id}
                              name="title"
                              value={form.title ? form.title : el.title}
                              onChange={changeHandler}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="dictRowButtonsContainer">
                      {editElement !== el.id ? (
                        <button
                          id={el.id}
                          onClick={editClick}
                          className="tetriaryButtonIconDefault"
                        >
                          <svg id={el.id}>
                            <use
                              id={el.id}
                              href="/images/icon24Edit.svg#icon24"
                            />
                          </svg>
                        </button>
                      ) : (
                        <button
                          onClick={saveEdit}
                          id={el.id}
                          name="exercise-difficulty-levels"
                          className="tetriaryButtonIconDefault"
                        >
                          <svg id={el.id} name="exercise-difficulty-levels">
                            <use href="/images/icon24CheckCircle.svg#icon24" />
                          </svg>
                        </button>
                      )}
                      {editElement !== el.id ? (
                        <button
                          id={el.id}
                          onClick={deleteClick}
                          className="tetriaryButtonIconDefault"
                        >
                          <svg id={el.id}>
                            <use
                              id={el.id}
                              href="/images/icon24Cancel.svg#icon24"
                            />
                          </svg>
                        </button>
                      ) : (
                        <button
                          onClick={() => {
                            setEditElement("");
                          }}
                          className="tetriaryButtonIconDefault"
                        >
                          <svg>
                            <use href="/images/icon24CancelCircle.svg#icon24" />
                          </svg>
                        </button>
                      )}
                    </div>
                  </div>
                );
              })}
            </>
          ) : (
            <div className="dictRowEmpty">Довідник не заповнено</div>
          )}
        </div>
      )}

      <div
        className="dictionaryHeaderWithButton"
        id="exercise-categories"
        onClick={clickHandler}
      >
        <h2
          id="exercise-categories"
          className={selectedType === "exercise-categories" && "active" || ''}
          onClick={clickHandler}
        >
          Категорії вправ
        </h2>
        {/* <button id='exercise-categories' onClick={addHandler} className="secondaryButtonIconTextDefault">
              <svg><use  href="/images/icon24Plus.svg#icon24" /></svg>
              Елемент довідника
            </button> */}
      </div>
      {/* {newElement=='exercise-categories' &&<div className="dictRowNormal">
              <div className="dictRowValuesContainer">
                <div className="dictRowValue">
                  <div className="apInputText">
                    <input type="text" name='title' value={form.value} onChange={changeHandler}/>
                  </div>
                </div>
                <div className="dictRowButtonsContainer">
                  <button onClick={saveAdd} id='exercise-categories' className="tetriaryButtonIconDefault"><svg id='exercise-categories'><use href="/images/icon24CheckCircle.svg#icon24" /></svg></button>
                  <button onClick={addHandler} id='exercise-categories' className="tetriaryButtonIconDefault"><svg ><use href="/images/icon24Cancel.svg#icon24" /></svg></button>
                </div>
              </div>
            </div>} */}
      {selectedType === "exercise-categories" && (
        <div className="dictAllRowsContainer">
          {!ready && (
            <div style={{ position: "relative" }}>
              {" "}
              <div style={{ position: "absolute", top: "50%", left: "50%" }}>
                <Loader />
              </div>{" "}
            </div>
          )}
          {dictionaries.length > 0 ? (
            <>
              {dictionaries.map((el, index) => {
                return (
                  <React.Fragment key={index}>
                    <div
                      className={
                        el.id === newAdditionalElement ||
                        el.id === selectedAdditionalType
                          ? "dict2ndLevelRowsGroupOpen"
                          : "dict2ndLevelRowsGroup"
                      }
                    >
                      <div className="dictRowNormal">
                        <div
                          className="dictRowValuesContainer"
                          id={el.id}
                          onClick={additionalSubClick}
                        >
                          {editElement !== el.id ? (
                            <input
                              type="text"
                              id={el.id}
                              className="dictRowValue"
                              disabled={editElement !== el.id}
                              value={el.title}
                            />
                          ) : (
                            <div className="dictRowValue">
                              <div className="apInputText">
                                <input
                                  type="text"
                                  id={el.id}
                                  name="title"
                                  value={form.title ? form.title : el.title}
                                  onChange={changeHandler}
                                />
                              </div>
                            </div>
                          )}
                        </div>
                        <div className="dictRowButtonsContainer">
                          {editElement !== el.id ? (
                            <button
                              id={el.id}
                              onClick={addaAdditionalHandler}
                              className="tetriaryButtonIconDefault"
                            >
                              <svg id={el.id} onClick={addaAdditionalHandler}>
                                <use href="/images/icon24Plus.svg#icon24" />
                              </svg>
                            </button>
                          ) : (
                            <button
                              onClick={saveEdit}
                              id={el.id}
                              name="exercise-categories"
                              className="tetriaryButtonIconDefault"
                            >
                              <svg id={el.id} name="exercise-categories">
                                <use href="/images/icon24CheckCircle.svg#icon24" />
                              </svg>
                            </button>
                          )}
                          {editElement !== el.id ? (
                            <button
                              id={el.id}
                              onClick={editClick}
                              className="tetriaryButtonIconDefault"
                            >
                              <svg id={el.id}>
                                <use
                                  id={el.id}
                                  href="/images/icon24Edit.svg#icon24"
                                />
                              </svg>
                            </button>
                          ) : (
                            <button
                              onClick={() => {
                                setEditElement("");
                              }}
                              className="tetriaryButtonIconDefault"
                            >
                              <svg>
                                <use href="/images/icon24CancelCircle.svg#icon24" />
                              </svg>
                            </button>
                          )}
                        </div>
                      </div>
                    </div>

                    {selectedAdditionalType === el.id &&
                      dictionariesAdditional && (
                        <>
                          {newAdditionalElement === el.id && (
                            <>
                              <div className="dictRowNormal">
                                <div className="dictRowLeftSpace">&nbsp;</div>
                                <div className="dictRowValuesContainer">
                                  <div className="dictRowValue">
                                    <div className="apInputText">
                                      <input
                                        placeholder="Назва"
                                        name="title"
                                        type="text"
                                        value={form.title}
                                        onChange={changeHandler}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="dictRowButtonsContainer">
                                  <button
                                    id="exercise-subcategories"
                                    onClick={saveAddAdditionalCategory}
                                    className="secondaryButtonIconDefault"
                                  >
                                    <svg id="exercise-subcategories">
                                      <use
                                        id="exercise-subcategories"
                                        href="/images/icon24CheckCircle.svg#icon24"
                                      />
                                    </svg>
                                  </button>
                                  <button
                                    className="tetriaryButtonIconDefault"
                                    id={el.id}
                                    onClick={addaAdditionalHandler}
                                  >
                                    <svg>
                                      <use href="/images/icon24CancelCircle.svg#icon24" />
                                    </svg>
                                  </button>
                                </div>
                              </div>
                            </>
                          )}
                          {!readyAdd && (
                            <div
                              style={{
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                              }}
                            >
                              <Loader />
                            </div>
                          )}
                          {el.subcategories.length > 0 ? (
                            el.subcategories.map((elem, index) => {
                              return (
                                <div key={index} className="dictRowNormal">
                                  <div className="dictRowLeftSpace">&nbsp;</div>
                                  <div className="dictRowValuesContainer">
                                    {/* <div className="dictRowValue">
                    {elem.title}
                  </div>
                  <div className="dictRowValueSplit">
                    {elem.districtCenter}
                  </div> */}
                                    {editElement !== elem.id ? (
                                      <div
                                        id={elem.id}
                                        className="dictRowValue"
                                      >
                                        {elem.title}
                                      </div>
                                    ) : (
                                      <div className="dictRowValue">
                                        <div className="apInputText">
                                          <input
                                            type="text"
                                            id={elem.id}
                                            name="title"
                                            value={
                                              form.title
                                                ? form.title
                                                : elem.title
                                            }
                                            onChange={changeHandler}
                                          />
                                          <span>Назва</span>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                  <div className="dictRowButtonsContainer">
                                    {/* <button className="tetriaryButtonIconDefault"><svg><use href="/images/icon24CheckCircle.svg#icon24" /></svg></button>
                  <button id={elem.id} className="tetriaryButtonIconDefault" onClick={deleteAdditionalClick}><svg id={elem.id}><use id={elem.id} href="/images/icon24Cancel.svg#icon24" /></svg></button> */}

                                    {editElement !== elem.id ? (
                                      <button
                                        id={elem.id}
                                        onClick={editClick}
                                        className="tetriaryButtonIconDefault"
                                      >
                                        <svg id={elem.id}>
                                          <use
                                            id={elem.id}
                                            href="/images/icon24Edit.svg#icon24"
                                          />
                                        </svg>
                                      </button>
                                    ) : (
                                      <button
                                        onClick={() => {
                                          setEditElement("");
                                        }}
                                        className="tetriaryButtonIconDefault"
                                      >
                                        <svg>
                                          <use href="/images/icon24CancelCircle.svg#icon24" />
                                        </svg>
                                      </button>
                                    )}
                                    {editElement !== elem.id ? (
                                      <button
                                        id={elem.id}
                                        className="tetriaryButtonIconDefault"
                                        onClick={deleteAdditionalClick}
                                      >
                                        <svg id={elem.id}>
                                          <use
                                            id={elem.id}
                                            href="/images/icon24Cancel.svg#icon24"
                                          />
                                        </svg>
                                      </button>
                                    ) : (
                                      <button
                                        onClick={saveEditAdditionalSub}
                                        id={elem.id}
                                        slot={elem.parentId}
                                        className="tetriaryButtonIconDefault"
                                      >
                                        <svg id={elem.id} slot={elem.parentId}>
                                          <use
                                            id={elem.id}
                                            slot={elem.parentId}
                                            href="/images/icon24CheckCircle.svg#icon24"
                                          />
                                        </svg>
                                      </button>
                                    )}
                                  </div>
                                </div>
                              );
                            })
                          ) : (
                            <div className="dictRowEmpty">
                              Довідник не заповнено
                            </div>
                          )}
                        </>
                      )}
                  </React.Fragment>
                );
              })}
            </>
          ) : (
            <div className="dictRowEmpty">Довідник не заповнено</div>
          )}
        </div>
      )}

      <div
        className="dictionaryHeaderWithButton"
        id="user-roles"
        onClick={clickHandler}
      >
        <h2
          id="user-roles"
          className={selectedType === "user-roles" && "active" || ''}
          onClick={clickHandler}
        >
          Ролі користувачів
        </h2>
        {/* <button id='user-roles' onClick={addHandler} className="secondaryButtonIconTextDefault">
              <svg><use  href="/images/icon24Plus.svg#icon24" /></svg>
              Елемент довідника
            </button> */}
      </div>
      {/* {newElement=='user-roles' &&<div className="dictRowNormal">
              <div className="dictRowValuesContainer">
                <div className="dictRowValue">
                  <div className="apInputText">
                    <input type="text" name='title' value={form.value} onChange={changeHandler}/>
                  </div>
                </div>
                <div className="dictRowButtonsContainer">
                  <button onClick={saveAdd} id='user-roles' className="tetriaryButtonIconDefault"><svg id='user-roles'><use href="/images/icon24CheckCircle.svg#icon24" /></svg></button>
                  <button onClick={addHandler} id='user-roles' className="tetriaryButtonIconDefault"><svg ><use href="/images/icon24Cancel.svg#icon24" /></svg></button>
                </div>
              </div>
            </div>} */}
      {selectedType === "user-roles" && (
        <div className="dictAllRowsContainer">
          {!ready && (
            <div style={{ position: "relative" }}>
              {" "}
              <div style={{ position: "absolute", top: "50%", left: "50%" }}>
                <Loader />
              </div>{" "}
            </div>
          )}
          {dictionaries.length > 0 ? (
            <>
              {dictionaries.map((el, index) => {
                return (
                  <div key={index} className="dictRowNormal">
                    <div className="dictRowValuesContainer">
                      {editElement !== el.id ? (
                        <input
                          type="text"
                          id={el.id}
                          className="dictRowValue"
                          disabled={editElement !== el.id}
                          value={el.title}
                        />
                      ) : (
                        <div className="dictRowValue">
                          <div className="apInputText">
                            <input
                              type="text"
                              id={el.id}
                              name="title"
                              value={form.title ? form.title : el.title}
                              onChange={changeHandler}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="dictRowButtonsContainer">
                      {editElement !== el.id ? (
                        <button
                          id={el.id}
                          onClick={editClick}
                          className="tetriaryButtonIconDefault"
                        >
                          <svg id={el.id}>
                            <use
                              id={el.id}
                              href="/images/icon24Edit.svg#icon24"
                            />
                          </svg>
                        </button>
                      ) : (
                        <button
                          onClick={saveEdit}
                          id={el.id}
                          name="user-roles"
                          className="tetriaryButtonIconDefault"
                        >
                          <svg id={el.id} name="user-roles">
                            <use href="/images/icon24CheckCircle.svg#icon24" />
                          </svg>
                        </button>
                      )}
                      {editElement !== el.id ? (
                        <button
                          id={el.id}
                          onClick={deleteClick}
                          className="tetriaryButtonIconDefault"
                        >
                          <svg id={el.id}>
                            <use
                              id={el.id}
                              href="/images/icon24Cancel.svg#icon24"
                            />
                          </svg>
                        </button>
                      ) : (
                        <button
                          onClick={() => {
                            setEditElement("");
                          }}
                          className="tetriaryButtonIconDefault"
                        >
                          <svg>
                            <use href="/images/icon24CancelCircle.svg#icon24" />
                          </svg>
                        </button>
                      )}
                    </div>
                  </div>
                );
              })}
            </>
          ) : (
            <div className="dictRowEmpty">Довідник не заповнено</div>
          )}
        </div>
      )}

      <div
        className="dictionaryHeaderWithButton"
        id="regions"
        onClick={clickHandler}
      >
        <h2
          id="regions"
          className={selectedType === "regions" && "active" || ''}
          onClick={clickHandler}
        >
          Регіони України
        </h2>
        <button
          id="regions"
          onClick={addHandler}
          className="secondaryButtonIconTextDefault"
        >
          <svg>
            <use href="/images/icon24Plus.svg#icon24" />
          </svg>
          Елемент довідника
        </button>
      </div>
      {newElement == "regions" && (
        <div className="dictRowNormal">
          <div className="dictRowNormal">
            <div className="dictRowValuesContainer">
              <div className="dictRowValue">
                <div className="apInputText">
                  <input
                    placeholder="Назва"
                    name="title"
                    type="text"
                    value={form.title}
                    onChange={changeHandler}
                  />
                </div>
              </div>
              <div className="dictRowValue">
                <div className="apInputText">
                  <input
                    placeholder="Альтернативна назва"
                    name="alterTitle"
                    type="text"
                    value={form.alterTitle}
                    onChange={changeHandler}
                  />
                </div>
              </div>
              <div className="dictRowValue">
                <div className="apInputText">
                  <input
                    placeholder="Код"
                    name="code"
                    type="text"
                    value={form.code}
                    onChange={changeHandler}
                  />
                </div>
              </div>
            </div>
            <div className="dictRowButtonsContainer">
              <button onClick={saveAdd} className="secondaryButtonIconDefault">
                <svg>
                  <use href="/images/icon24CheckCircle.svg#icon24" />
                </svg>
              </button>
              <button
                className="tetriaryButtonIconDefault"
                id="regions"
                onClick={addHandler}
              >
                <svg>
                  <use href="/images/icon24CancelCircle.svg#icon24" />
                </svg>
              </button>
            </div>
          </div>
        </div>
      )}
      {selectedType === "regions" && (
        <div className="dictAllRowsContainer">
          {!ready && (
            <div style={{ position: "relative" }}>
              {" "}
              <div style={{ position: "absolute", top: "50%", left: "50%" }}>
                <Loader />
              </div>{" "}
            </div>
          )}
          {dictionaries.length > 0 ? (
            <>
              {dictionaries.map((el, index) => {
                return (
                  <React.Fragment key={index}>
                    <div className="dictAllRowsContainer">
                      <div
                        className={
                          el.id === newAdditionalElement ||
                          el.id === selectedAdditionalType
                            ? "dict2ndLevelRowsGroupOpen"
                            : "dict2ndLevelRowsGroup"
                        }
                      >
                        <div className="dictRowNormal">
                          <div
                            className="dictRowValuesContainer"
                            id={el.id}
                            onClick={additionalClick}
                          >
                            {editElement !== el.id ? (
                              <div id={el.id} className="dictRowValue">
                                {el.title}
                              </div>
                            ) : (
                              <div className="dictRowValue">
                                <div className="apInputText">
                                  <input
                                    type="text"
                                    id={el.id}
                                    name="title"
                                    value={form.title ? form.title : el.title}
                                    onChange={changeHandler}
                                  />
                                  <span>Назва</span>
                                </div>
                              </div>
                            )}
                            {editElement !== el.id ? (
                              <div id={el.id} className="dictRowValue">
                                {el.alterTitle}
                              </div>
                            ) : (
                              <div className="dictRowValueSplit">
                                <div className="apInputText">
                                  <input
                                    type="text"
                                    id={el.id}
                                    name="alterTitle"
                                    value={
                                      form.alterTitle
                                        ? form.alterTitle
                                        : el.alterTitle
                                    }
                                    onChange={changeHandler}
                                  />
                                  <span>Google назва</span>
                                </div>
                              </div>
                            )}
                            {editElement !== el.id ? (
                              <div id={el.id} className="dictRowValue">
                                {el.code}
                              </div>
                            ) : (
                              <div className="dictRowValueSplit">
                                <div className="apInputText">
                                  <input
                                    type="text"
                                    id={el.id}
                                    name="code"
                                    value={form.code ? form.code : el.code}
                                    onChange={changeHandler}
                                  />
                                  <span>Код регiона</span>
                                </div>
                              </div>
                            )}
                          </div>
                          <div className="dictRowButtonsContainer">
                            {editElement !== el.id ? (
                              <button
                                id={el.id}
                                onClick={addaAdditionalHandler}
                                className="tetriaryButtonIconDefault"
                              >
                                <svg id={el.id} onClick={addaAdditionalHandler}>
                                  <use href="/images/icon24Plus.svg#icon24" />
                                </svg>
                              </button>
                            ) : (
                              <button
                                onClick={saveEdit}
                                id={el.id}
                                name="exercise-categories"
                                className="tetriaryButtonIconDefault"
                              >
                                <svg id={el.id} name="exercise-categories">
                                  <use href="/images/icon24CheckCircle.svg#icon24" />
                                </svg>
                              </button>
                            )}
                            {editElement !== el.id ? (
                              <button
                                id={el.id}
                                onClick={editClick}
                                className="tetriaryButtonIconDefault"
                              >
                                <svg id={el.id}>
                                  <use
                                    id={el.id}
                                    href="/images/icon24Edit.svg#icon24"
                                  />
                                </svg>
                              </button>
                            ) : (
                              <button
                                onClick={() => {
                                  setEditElement("");
                                }}
                                className="tetriaryButtonIconDefault"
                              >
                                <svg>
                                  <use href="/images/icon24CancelCircle.svg#icon24" />
                                </svg>
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    {selectedAdditionalType === el.id &&
                      dictionariesAdditional && (
                        <>
                          {newAdditionalElement === el.id && (
                            <>
                              <div className="dictRowNormal">
                                <div className="dictRowLeftSpace">&nbsp;</div>
                                <div className="dictRowValuesContainer">
                                  <div className="dictRowValue">
                                    <div className="apInputText">
                                      <input
                                        placeholder="Назва"
                                        name="title"
                                        type="text"
                                        value={form.title}
                                        onChange={changeHandler}
                                      />
                                    </div>
                                  </div>
                                  <div className="dictRowValue">
                                    <div className="apInputText">
                                      <input
                                        placeholder="Районний центр"
                                        name="districtCenter"
                                        type="text"
                                        value={form.districtCenter}
                                        onChange={changeHandler}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="dictRowButtonsContainer">
                                  <button
                                    id={el.id}
                                    onClick={saveAddAdditional}
                                    className="secondaryButtonIconDefault"
                                  >
                                    <svg id={el.id}>
                                      <use
                                        id={el.id}
                                        href="/images/icon24CheckCircle.svg#icon24"
                                      />
                                    </svg>
                                  </button>
                                  <button
                                    className="tetriaryButtonIconDefault"
                                    id={el.id}
                                    onClick={addaAdditionalHandler}
                                  >
                                    <svg>
                                      <use href="/images/icon24CancelCircle.svg#icon24" />
                                    </svg>
                                  </button>
                                </div>
                              </div>
                            </>
                          )}
                          {!readyAdd && (
                            <div
                              style={{
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                              }}
                            >
                              <Loader />
                            </div>
                          )}
                          {dictionariesAdditional.length > 0 ? (
                            dictionariesAdditional.map((elem, index) => {
                              return (
                                <div key={index} className="dictRowNormal">
                                  <div className="dictRowLeftSpace">&nbsp;</div>
                                  <div className="dictRowValuesContainer">
                                    {/* <div className="dictRowValue">
                    {elem.title}
                  </div>
                  <div className="dictRowValueSplit">
                    {elem.districtCenter}
                  </div> */}
                                    {editElement !== elem.id ? (
                                      <div
                                        id={elem.id}
                                        className="dictRowValue"
                                      >
                                        {elem.title}
                                      </div>
                                    ) : (
                                      <div className="dictRowValue">
                                        <div className="apInputText">
                                          <input
                                            type="text"
                                            id={elem.id}
                                            name="title"
                                            value={
                                              form.title
                                                ? form.title
                                                : elem.title
                                            }
                                            onChange={changeHandler}
                                          />
                                          <span>Назва</span>
                                        </div>
                                      </div>
                                    )}
                                    {editElement !== elem.id ? (
                                      <div
                                        id={elem.id}
                                        className="dictRowValue"
                                      >
                                        {elem.districtCenter}
                                      </div>
                                    ) : (
                                      <div className="dictRowValueSplit">
                                        <div className="apInputText">
                                          <input
                                            type="text"
                                            id={elem.id}
                                            name="districtCenter"
                                            value={
                                              form.districtCenter
                                                ? form.districtCenter
                                                : elem.districtCenter
                                            }
                                            onChange={changeHandler}
                                          />
                                          <span>Районний центр</span>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                  <div className="dictRowButtonsContainer">
                                    {/* <button className="tetriaryButtonIconDefault"><svg><use href="/images/icon24CheckCircle.svg#icon24" /></svg></button>
                  <button id={elem.id} className="tetriaryButtonIconDefault" onClick={deleteAdditionalClick}><svg id={elem.id}><use id={elem.id} href="/images/icon24Cancel.svg#icon24" /></svg></button> */}

                                    {editElement !== elem.id ? (
                                      <button
                                        id={elem.id}
                                        onClick={editClick}
                                        className="tetriaryButtonIconDefault"
                                      >
                                        <svg id={elem.id}>
                                          <use
                                            id={elem.id}
                                            href="/images/icon24Edit.svg#icon24"
                                          />
                                        </svg>
                                      </button>
                                    ) : (
                                      <button
                                        onClick={() => {
                                          setEditElement("");
                                        }}
                                        className="tetriaryButtonIconDefault"
                                      >
                                        <svg>
                                          <use href="/images/icon24CancelCircle.svg#icon24" />
                                        </svg>
                                      </button>
                                    )}
                                    {editElement !== elem.id ? (
                                      <button
                                        id={elem.id}
                                        className="tetriaryButtonIconDefault"
                                        onClick={deleteAdditionalClick}
                                      >
                                        <svg id={elem.id}>
                                          <use
                                            id={elem.id}
                                            href="/images/icon24Cancel.svg#icon24"
                                          />
                                        </svg>
                                      </button>
                                    ) : (
                                      <button
                                        onClick={saveEditAdditional}
                                        id={elem.id}
                                        name="exercise-categories"
                                        className="tetriaryButtonIconDefault"
                                      >
                                        <svg
                                          id={elem.id}
                                          name="exercise-categories"
                                        >
                                          <use href="/images/icon24CheckCircle.svg#icon24" />
                                        </svg>
                                      </button>
                                    )}
                                  </div>
                                </div>
                              );
                            })
                          ) : (
                            <div className="dictRowEmpty">
                              Довідник не заповнено
                            </div>
                          )}
                        </>
                      )}
                  </React.Fragment>
                );
              })}
            </>
          ) : (
            <div className="dictRowEmpty">Довідник не заповнено</div>
          )}
        </div>
      )}

      <div
        className="dictionaryHeaderWithButton"
        id="event-categories"
        onClick={clickHandler}
      >
        <h2
          id="event-categories"
          className={selectedType === "event-categories" && "active" || ''}
          onClick={clickHandler}
        >
          Категорії заходів
        </h2>
        <button
          id="event-categories"
          onClick={addHandler}
          className="secondaryButtonIconTextDefault"
        >
          <svg>
            <use href="/images/icon24Plus.svg#icon24" />
          </svg>
          Елемент довідника
        </button>
      </div>
      {newElement == "event-categories" && (
        <div className="dictRowNormal">
          <div className="dictRowValuesContainer">
            <div className="dictRowValue">
              <div className="apInputText">
                <input
                  type="text"
                  name="title"
                  value={form.value}
                  onChange={changeHandler}
                />
              </div>
            </div>
            <div className="dictRowButtonsContainer">
              <button
                onClick={saveAdd}
                id="event-categories"
                className="tetriaryButtonIconDefault"
              >
                <svg id="event-categories">
                  <use href="/images/icon24CheckCircle.svg#icon24" />
                </svg>
              </button>
              <button
                onClick={addHandler}
                id="event-categories"
                className="tetriaryButtonIconDefault"
              >
                <svg>
                  <use href="/images/icon24Cancel.svg#icon24" />
                </svg>
              </button>
            </div>
          </div>
        </div>
      )}
      {selectedType === "event-categories" && (
        <div className="dictAllRowsContainer">
          {!ready && (
            <div style={{ position: "relative" }}>
              {" "}
              <div style={{ position: "absolute", top: "50%", left: "50%" }}>
                <Loader />
              </div>{" "}
            </div>
          )}

          {dictionaries.length > 0 ? (
            <>
              {dictionaries.map((el, index) => {
                return (
                  <div key={index} className="dictRowNormal">
                    <div className="dictRowValuesContainer">
                      {editElement !== el.id ? (
                        <input
                          type="text"
                          id={el.id}
                          className="dictRowValue"
                          disabled={editElement !== el.id}
                          value={el.title}
                        />
                      ) : (
                        <div className="dictRowValue">
                          <div className="apInputText">
                            <input
                              type="text"
                              id={el.id}
                              name="title"
                              value={form.title ? form.title : el.title}
                              onChange={changeHandler}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="dictRowButtonsContainer">
                      {editElement !== el.id ? (
                        <button
                          id={el.id}
                          onClick={editClick}
                          className="tetriaryButtonIconDefault"
                        >
                          <svg id={el.id}>
                            <use
                              id={el.id}
                              href="/images/icon24Edit.svg#icon24"
                            />
                          </svg>
                        </button>
                      ) : (
                        <button
                          onClick={saveEdit}
                          id={el.id}
                          name="event-categories"
                          className="tetriaryButtonIconDefault"
                        >
                          <svg id={el.id} name="event-categories">
                            <use href="/images/icon24CheckCircle.svg#icon24" />
                          </svg>
                        </button>
                      )}
                      {editElement !== el.id ? (
                        <button
                          id={el.id}
                          onClick={deleteClick}
                          className="tetriaryButtonIconDefault"
                        >
                          <svg id={el.id}>
                            <use
                              id={el.id}
                              href="/images/icon24Cancel.svg#icon24"
                            />
                          </svg>
                        </button>
                      ) : (
                        <button
                          onClick={() => {
                            setEditElement("");
                          }}
                          className="tetriaryButtonIconDefault"
                        >
                          <svg>
                            <use href="/images/icon24CancelCircle.svg#icon24" />
                          </svg>
                        </button>
                      )}
                    </div>
                  </div>
                );
              })}
            </>
          ) : (
            <div className="dictRowEmpty">Довідник не заповнено</div>
          )}
        </div>
      )}

      <div
        className="dictionaryHeaderWithButton"
        id="sport-event-statuses"
        onClick={clickHandler}
      >
        <h2
          id="sport-event-statuses"
          className={selectedType === "sport-event-statuses" && "active" || ''}
          onClick={clickHandler}
        >
          Статуси заходів
        </h2>
        {/* <button id='sport-event-statuses' onClick={addHandler} className="secondaryButtonIconTextDefault">
              <svg><use  href="/images/icon24Plus.svg#icon24" /></svg>
              Елемент довідника
            </button> */}
      </div>

      {selectedType === "sport-event-statuses" && (
        <div className="dictAllRowsContainer">
          {!ready && (
            <div style={{ position: "relative" }}>
              {" "}
              <div style={{ position: "absolute", top: "50%", left: "50%" }}>
                <Loader />
              </div>{" "}
            </div>
          )}
          {dictionaries.length > 0 ? (
            <>
              {dictionaries.map((el, index) => {
                return (
                  <div key={index} className="dictRowNormal">
                    <div className="dictRowValuesContainer">
                      {editElement !== el.id ? (
                        <div className="dictRowValue">{el.title}</div>
                      ) : (
                        <div className="dictRowValue">
                          <div className="apInputText">
                            <input
                              type="text"
                              id={el.id}
                              name="title"
                              value={form.title ? form.title : el.title}
                              onChange={changeHandler}
                            />
                          </div>
                        </div>
                      )}
                      {editElement !== el.id ? (
                        <div className="dictRowValue">{el.type}</div>
                      ) : (
                        <div className="dictRowValue">
                          <div className="apInputText">
                            <input
                              type="text"
                              id={el.id}
                              name="type"
                              value={form.type ? form.type : el.type}
                              onChange={changeHandler}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="dictRowButtonsContainer">
                      {editElement !== el.id ? (
                        <button
                          id={el.id}
                          onClick={editClick}
                          className="tetriaryButtonIconDefault"
                        >
                          <svg id={el.id}>
                            <use
                              id={el.id}
                              href="/images/icon24Edit.svg#icon24"
                            />
                          </svg>
                        </button>
                      ) : (
                        <button
                          onClick={saveEdit}
                          id={el.id}
                          name="sport-event-statuses"
                          className="tetriaryButtonIconDefault"
                        >
                          <svg id={el.id} name="sport-event-statuses">
                            <use
                              id={el.id}
                              href="/images/icon24CheckCircle.svg#icon24"
                            />
                          </svg>
                        </button>
                      )}
                      {editElement === el.id && (
                        <button
                          onClick={() => {
                            setEditElement("");
                          }}
                          className="tetriaryButtonIconDefault"
                        >
                          <svg>
                            <use href="/images/icon24CancelCircle.svg#icon24" />
                          </svg>
                        </button>
                      )}
                    </div>
                  </div>
                );
              })}
            </>
          ) : (
            <div className="dictRowEmpty">Довідник не заповнено</div>
          )}
        </div>
      )}

      <div
        className="dictionaryHeaderWithButton"
        id="info-page-sections"
        onClick={clickHandler}
      >
        <h2
          id="info-page-sections"
          className={selectedType === "info-page-sections" && "active" || ''}
          onClick={clickHandler}
        >
          Категорії бази знань
        </h2>
        <button
          id="info-page-sections"
          onClick={addHandler}
          className="secondaryButtonIconTextDefault"
        >
          <svg>
            <use href="/images/icon24Plus.svg#icon24" />
          </svg>
          Елемент довідника
        </button>
      </div>
      {newElement == "info-page-sections" && (
        <div className="dictRowNormal">
          <div className="dictRowValuesContainer">
            <div className="dictRowValue">
              <div className="apInputText">
                <input
                  type="text"
                  name="title"
                  value={form.value}
                  onChange={changeHandler}
                />
              </div>
            </div>
            <div className="dictRowButtonsContainer">
              <button
                onClick={saveAdd}
                id="info-page-sections"
                className="tetriaryButtonIconDefault"
              >
                <svg id="info-page-sections">
                  <use href="/images/icon24CheckCircle.svg#icon24" />
                </svg>
              </button>
              <button
                onClick={addHandler}
                id="info-page-sections"
                className="tetriaryButtonIconDefault"
              >
                <svg>
                  <use href="/images/icon24Cancel.svg#icon24" />
                </svg>
              </button>
            </div>
          </div>
        </div>
      )}
      {selectedType === "info-page-sections" && (
        <div className="dictAllRowsContainer">
          {!ready && (
            <div style={{ position: "relative" }}>
              {" "}
              <div style={{ position: "absolute", top: "50%", left: "50%" }}>
                <Loader />
              </div>{" "}
            </div>
          )}
          {dictionaries.length > 0 ? (
            <>
              {dictionaries.map((el, index) => {
                return (
                  <div key={index} className="dictRowNormal">
                    <div className="dictRowValuesContainer">
                      {editElement !== el.id ? (
                        <input
                          type="text"
                          id={el.id}
                          className="dictRowValue"
                          disabled={editElement !== el.id}
                          value={el.title}
                        />
                      ) : (
                        <div className="dictRowValue">
                          <div className="apInputText">
                            <input
                              type="text"
                              id={el.id}
                              name="title"
                              value={form.title ? form.title : el.title}
                              onChange={changeHandler}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="dictRowButtonsContainer">
                      {editElement !== el.id ? (
                        <button
                          id={el.id}
                          onClick={editClick}
                          className="tetriaryButtonIconDefault"
                        >
                          <svg id={el.id}>
                            <use
                              id={el.id}
                              href="/images/icon24Edit.svg#icon24"
                            />
                          </svg>
                        </button>
                      ) : (
                        <button
                          onClick={saveEdit}
                          id={el.id}
                          name="info-page-sections"
                          className="tetriaryButtonIconDefault"
                        >
                          <svg id={el.id} name="info-page-sections">
                            <use href="/images/icon24CheckCircle.svg#icon24" />
                          </svg>
                        </button>
                      )}
                      {editElement !== el.id ? (
                        <button
                          id={el.id}
                          onClick={deleteClick}
                          className="tetriaryButtonIconDefault"
                        >
                          <svg id={el.id}>
                            <use
                              id={el.id}
                              href="/images/icon24Cancel.svg#icon24"
                            />
                          </svg>
                        </button>
                      ) : (
                        <button
                          onClick={() => {
                            setEditElement("");
                          }}
                          className="tetriaryButtonIconDefault"
                        >
                          <svg>
                            <use href="/images/icon24CancelCircle.svg#icon24" />
                          </svg>
                        </button>
                      )}
                    </div>
                  </div>
                );
              })}
            </>
          ) : (
            <div className="dictRowEmpty">Довідник не заповнено</div>
          )}
        </div>
      )}

      <div
        className="dictionaryHeaderWithButton"
        id="info-page-statuses"
        onClick={clickHandler}
      >
        <h2
          id="info-page-statuses"
          className={selectedType === "info-page-statuses" && "active" || ''}
          onClick={clickHandler}
        >
          {" "}
          Статуси інформаційних сторінок
        </h2>
        {/* <button id='info-page-statuses' onClick={addHandler} className="secondaryButtonIconTextDefault">
              <svg><use  href="/images/icon24Plus.svg#icon24" /></svg>
              Елемент довідника
            </button> */}
      </div>
      {/* {newElement=='info-page-statuses' &&<div className="dictRowNormal">
              <div className="dictRowValuesContainer">
                <div className="dictRowValue">
                  <div className="apInputText">
                    <input type="text" name='title' value={form.value} onChange={changeHandler}/>
                  </div>
                </div>
                <div className="dictRowButtonsContainer">
                  <button onClick={saveAdd} id='info-page-statuses' className="tetriaryButtonIconDefault"><svg id='info-page-statuses'><use href="/images/icon24CheckCircle.svg#icon24" /></svg></button>
                  <button onClick={addHandler} id='info-page-statuses' className="tetriaryButtonIconDefault"><svg ><use href="/images/icon24Cancel.svg#icon24" /></svg></button>
                </div>
              </div>
            </div>} */}
      {selectedType === "info-page-statuses" && (
        <div className="dictAllRowsContainer">
          {!ready && (
            <div style={{ position: "relative" }}>
              {" "}
              <div style={{ position: "absolute", top: "50%", left: "50%" }}>
                <Loader />
              </div>{" "}
            </div>
          )}

          {dictionaries.length > 0 ? (
            <>
              {dictionaries.map((el, index) => {
                return (
                  <div key={index} className="dictRowNormal">
                    <div className="dictRowValuesContainer">
                      {editElement !== el.id ? (
                        <input
                          type="text"
                          id={el.id}
                          className="dictRowValue"
                          disabled={editElement !== el.id}
                          value={el.title}
                        />
                      ) : (
                        <div className="dictRowValue">
                          <div className="apInputText">
                            <input
                              type="text"
                              id={el.id}
                              name="title"
                              value={form.title ? form.title : el.title}
                              onChange={changeHandler}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="dictRowButtonsContainer">
                      {editElement !== el.id ? (
                        <button
                          id={el.id}
                          onClick={editClick}
                          className="tetriaryButtonIconDefault"
                        >
                          <svg id={el.id}>
                            <use
                              id={el.id}
                              href="/images/icon24Edit.svg#icon24"
                            />
                          </svg>
                        </button>
                      ) : (
                        <button
                          onClick={saveEdit}
                          id={el.id}
                          name="info-page-statuses"
                          className="tetriaryButtonIconDefault"
                        >
                          <svg id={el.id} name="info-page-statuses">
                            <use href="/images/icon24CheckCircle.svg#icon24" />
                          </svg>
                        </button>
                      )}
                      {editElement !== el.id ? (
                        <button
                          id={el.id}
                          onClick={deleteClick}
                          className="tetriaryButtonIconDefault"
                        >
                          <svg id={el.id}>
                            <use
                              id={el.id}
                              href="/images/icon24Cancel.svg#icon24"
                            />
                          </svg>
                        </button>
                      ) : (
                        <button
                          onClick={() => {
                            setEditElement("");
                          }}
                          className="tetriaryButtonIconDefault"
                        >
                          <svg>
                            <use href="/images/icon24CancelCircle.svg#icon24" />
                          </svg>
                        </button>
                      )}
                    </div>
                  </div>
                );
              })}
            </>
          ) : (
            <div className="dictRowEmpty">Довідник не заповнено</div>
          )}
        </div>
      )}

      <div
        className="dictionaryHeaderWithButton"
        id="news-statuses"
        onClick={clickHandler}
      >
        <h2
          id="news-statuses"
          className={selectedType === "news-statuses" && "active" || ''}
          onClick={clickHandler}
        >
          Статуси блогу
        </h2>
        
      </div>

      {selectedType === "news-statuses" && (
        <div className="dictAllRowsContainer">
          {!ready && (
            <div style={{ position: "relative" }}>
              {" "}
              <div style={{ position: "absolute", top: "50%", left: "50%" }}>
                <Loader />
              </div>{" "}
            </div>
          )}

          {dictionaries.length > 0 ? (
            <>
              {dictionaries.map((el,index) => {
                return (
                  <div key={index} className="dictRowNormal">
                    <div className="dictRowValuesContainer">
                      {editElement !== el.id ? (
                        <input
                          type="text"
                          id={el.id}
                          className="dictRowValue"
                          disabled={editElement !== el.id}
                          value={el.title}
                        />
                      ) : (
                        <div className="dictRowValue">
                          <div className="apInputText">
                            <input
                              type="text"
                              id={el.id}
                              name="title"
                              value={form.title ? form.title : el.title}
                              onChange={changeHandler}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="dictRowButtonsContainer">
                      {editElement !== el.id ? (
                        <button
                          id={el.id}
                          onClick={editClick}
                          className="tetriaryButtonIconDefault"
                        >
                          <svg id={el.id}>
                            <use
                              id={el.id}
                              href="/images/icon24Edit.svg#icon24"
                            />
                          </svg>
                        </button>
                      ) : (
                        <button
                          onClick={saveEdit}
                          id={el.id}
                          name="news-statuses"
                          className="tetriaryButtonIconDefault"
                        >
                          <svg id={el.id} name="news-statuses">
                            <use href="/images/icon24CheckCircle.svg#icon24" />
                          </svg>
                        </button>
                      )}
                      {editElement !== el.id ? (
                        <button
                          id={el.id}
                          onClick={deleteClick}
                          className="tetriaryButtonIconDefault"
                        >
                          <svg id={el.id}>
                            <use
                              id={el.id}
                              href="/images/icon24Cancel.svg#icon24"
                            />
                          </svg>
                        </button>
                      ) : (
                        <button
                          onClick={() => {
                            setEditElement("");
                          }}
                          className="tetriaryButtonIconDefault"
                        >
                          <svg>
                            <use href="/images/icon24CancelCircle.svg#icon24" />
                          </svg>
                        </button>
                      )}
                    </div>
                  </div>
                );
              })}
            </>
          ) : (
            <div className="dictRowEmpty">Довідник не заповнено</div>
          )}
        </div>
      )}

<div
        className="dictionaryHeaderWithButton"
        id="club-statuses"
        onClick={clickHandler}
      >
        <h2
          id="club-statuses"
          className={selectedType === "club-statuses" && "active" || ''}
          onClick={clickHandler}
        >
          Статуси клубів
        </h2>
        
      </div>

      {selectedType === "club-statuses" && (
        <div className="dictAllRowsContainer">
          {!ready && (
            <div style={{ position: "relative" }}>
              {" "}
              <div style={{ position: "absolute", top: "50%", left: "50%" }}>
                <Loader />
              </div>{" "}
            </div>
          )}

          {dictionaries.length > 0 ? (
            <>
              {dictionaries.map((el,index) => {
                return (
                  <div key={index} className="dictRowNormal">
                    <div className="dictRowValuesContainer">
                      {editElement !== el.id ? (
                        <input
                          type="text"
                          id={el.id}
                          className="dictRowValue"
                          disabled={editElement !== el.id}
                          value={el.title}
                        />
                      ) : (
                        <div className="dictRowValue">
                          <div className="apInputText">
                            <input
                              type="text"
                              id={el.id}
                              name="title"
                              value={form.title ? form.title : el.title}
                              onChange={changeHandler}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="dictRowButtonsContainer">
                      {editElement !== el.id ? (
                        <button
                          id={el.id}
                          onClick={editClick}
                          className="tetriaryButtonIconDefault"
                        >
                          <svg id={el.id}>
                            <use
                              id={el.id}
                              href="/images/icon24Edit.svg#icon24"
                            />
                          </svg>
                        </button>
                      ) : (
                        <button
                          onClick={saveEdit}
                          id={el.id}
                          name="news-statuses"
                          className="tetriaryButtonIconDefault"
                        >
                          <svg id={el.id} name="news-statuses">
                            <use href="/images/icon24CheckCircle.svg#icon24" />
                          </svg>
                        </button>
                      )}
                      {editElement !== el.id ? (
                        <button
                          id={el.id}
                          onClick={deleteClick}
                          className="tetriaryButtonIconDefault"
                        >
                          <svg id={el.id}>
                            <use
                              id={el.id}
                              href="/images/icon24Cancel.svg#icon24"
                            />
                          </svg>
                        </button>
                      ) : (
                        <button
                          onClick={() => {
                            setEditElement("");
                          }}
                          className="tetriaryButtonIconDefault"
                        >
                          <svg>
                            <use href="/images/icon24CancelCircle.svg#icon24" />
                          </svg>
                        </button>
                      )}
                    </div>
                  </div>
                );
              })}
            </>
          ) : (
            <div className="dictRowEmpty">Довідник не заповнено</div>
          )}
        </div>
      )}



<div
        className="dictionaryHeaderWithButton"
        id="club-event-statuses"
        onClick={clickHandler}
      >
        <h2
          id="club-event-statuses"
          className={selectedType === "club-event-statuses" && "active" || ''}
          onClick={clickHandler}
        >
          Статуси заходів клубів
        </h2>
        
      </div>

      {selectedType === "club-event-statuses" && (
        <div className="dictAllRowsContainer">
          {!ready && (
            <div style={{ position: "relative" }}>
              {" "}
              <div style={{ position: "absolute", top: "50%", left: "50%" }}>
                <Loader />
              </div>{" "}
            </div>
          )}

          {dictionaries.length > 0 ? (
            <>
              {dictionaries.map((el,index) => {
                return (
                  <div key={index} className="dictRowNormal">
                    <div className="dictRowValuesContainer">
                      {editElement !== el.id ? (
                        <input
                          type="text"
                          id={el.id}
                          className="dictRowValue"
                          disabled={editElement !== el.id}
                          value={el.title}
                        />
                      ) : (
                        <div className="dictRowValue">
                          <div className="apInputText">
                            <input
                              type="text"
                              id={el.id}
                              name="title"
                              value={form.title ? form.title : el.title}
                              onChange={changeHandler}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="dictRowButtonsContainer">
                      {editElement !== el.id ? (
                        <button
                          id={el.id}
                          onClick={editClick}
                          className="tetriaryButtonIconDefault"
                        >
                          <svg id={el.id}>
                            <use
                              id={el.id}
                              href="/images/icon24Edit.svg#icon24"
                            />
                          </svg>
                        </button>
                      ) : (
                        <button
                          onClick={saveEdit}
                          id={el.id}
                          name="news-statuses"
                          className="tetriaryButtonIconDefault"
                        >
                          <svg id={el.id} name="news-statuses">
                            <use href="/images/icon24CheckCircle.svg#icon24" />
                          </svg>
                        </button>
                      )}
                      {editElement !== el.id ? (
                        <button
                          id={el.id}
                          onClick={deleteClick}
                          className="tetriaryButtonIconDefault"
                        >
                          <svg id={el.id}>
                            <use
                              id={el.id}
                              href="/images/icon24Cancel.svg#icon24"
                            />
                          </svg>
                        </button>
                      ) : (
                        <button
                          onClick={() => {
                            setEditElement("");
                          }}
                          className="tetriaryButtonIconDefault"
                        >
                          <svg>
                            <use href="/images/icon24CancelCircle.svg#icon24" />
                          </svg>
                        </button>
                      )}
                    </div>
                  </div>
                );
              })}
            </>
          ) : (
            <div className="dictRowEmpty">Довідник не заповнено</div>
          )}
        </div>
      )}

<div
        className="dictionaryHeaderWithButton"
        id="event-holding-statuses"
        onClick={clickHandler}
      >
        <h2
          id="event-holding-statuses"
          className={selectedType === "event-holding-statuses" && "active" || ''}
          onClick={clickHandler}
        >
          Холдингові статуси клубів
         {/* event-holdingstatuses */}
        </h2>
        
      </div>

      {selectedType === "event-holding-statuses" && (
        <div className="dictAllRowsContainer">
          {!ready && (
            <div style={{ position: "relative" }}>
              {" "}
              <div style={{ position: "absolute", top: "50%", left: "50%" }}>
                <Loader />
              </div>{" "}
            </div>
          )}

          {dictionaries.length > 0 ? (
            <>
              {dictionaries.map((el,index) => {
                return (
                  <div key={index} className="dictRowNormal">
                    <div className="dictRowValuesContainer">
                      {editElement !== el.id ? (
                        <input
                          type="text"
                          id={el.id}
                          className="dictRowValue"
                          disabled={editElement !== el.id}
                          value={el.title}
                        />
                      ) : (
                        <div className="dictRowValue">
                          <div className="apInputText">
                            <input
                              type="text"
                              id={el.id}
                              name="title"
                              value={form.title ? form.title : el.title}
                              onChange={changeHandler}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="dictRowButtonsContainer">
                      {editElement !== el.id ? (
                        <button
                          id={el.id}
                          onClick={editClick}
                          className="tetriaryButtonIconDefault"
                        >
                          <svg id={el.id}>
                            <use
                              id={el.id}
                              href="/images/icon24Edit.svg#icon24"
                            />
                          </svg>
                        </button>
                      ) : (
                        <button
                          onClick={saveEdit}
                          id={el.id}
                          name="news-statuses"
                          className="tetriaryButtonIconDefault"
                        >
                          <svg id={el.id} name="news-statuses">
                            <use href="/images/icon24CheckCircle.svg#icon24" />
                          </svg>
                        </button>
                      )}
                      {editElement !== el.id ? (
                        <button
                          id={el.id}
                          onClick={deleteClick}
                          className="tetriaryButtonIconDefault"
                        >
                          <svg id={el.id}>
                            <use
                              id={el.id}
                              href="/images/icon24Cancel.svg#icon24"
                            />
                          </svg>
                        </button>
                      ) : (
                        <button
                          onClick={() => {
                            setEditElement("");
                          }}
                          className="tetriaryButtonIconDefault"
                        >
                          <svg>
                            <use href="/images/icon24CancelCircle.svg#icon24" />
                          </svg>
                        </button>
                      )}
                    </div>
                  </div>
                );
              })}
            </>
          ) : (
            <div className="dictRowEmpty">Довідник не заповнено</div>
          )}
        </div>
      )}


{/* <div
        className="dictionaryHeaderWithButton"
        id="support-topics"
        onClick={clickHandler}
      >
        <h2
          id="support-topics"
          className={selectedType === "support-topics" && "active" || ''}
          onClick={clickHandler}
        >
        Допоміжні теми
        </h2>
        
      </div>

      {selectedType === "support-topics" && (
        <div className="dictAllRowsContainer">
          {!ready && (
            <div style={{ position: "relative" }}>
              {" "}
              <div style={{ position: "absolute", top: "50%", left: "50%" }}>
                <Loader />
              </div>{" "}
            </div>
          )}

          {dictionaries.length > 0 ? (
            <>
              {dictionaries.map((el,index) => {
                return (
                  <div key={index} className="dictRowNormal">
                    <div className="dictRowValuesContainer">
                      {editElement !== el.id ? (
                        <input
                          type="text"
                          id={el.id}
                          className="dictRowValue"
                          disabled={editElement !== el.id}
                          value={el.title}
                        />
                      ) : (
                        <div className="dictRowValue">
                          <div className="apInputText">
                            <input
                              type="text"
                              id={el.id}
                              name="title"
                              value={form.title ? form.title : el.title}
                              onChange={changeHandler}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="dictRowButtonsContainer">
                      {editElement !== el.id ? (
                        <button
                          id={el.id}
                          onClick={editClick}
                          className="tetriaryButtonIconDefault"
                        >
                          <svg id={el.id}>
                            <use
                              id={el.id}
                              href="/images/icon24Edit.svg#icon24"
                            />
                          </svg>
                        </button>
                      ) : (
                        <button
                          onClick={saveEdit}
                          id={el.id}
                          name="news-statuses"
                          className="tetriaryButtonIconDefault"
                        >
                          <svg id={el.id} name="news-statuses">
                            <use href="/images/icon24CheckCircle.svg#icon24" />
                          </svg>
                        </button>
                      )}
                      {editElement !== el.id ? (
                        <button
                          id={el.id}
                          onClick={deleteClick}
                          className="tetriaryButtonIconDefault"
                        >
                          <svg id={el.id}>
                            <use
                              id={el.id}
                              href="/images/icon24Cancel.svg#icon24"
                            />
                          </svg>
                        </button>
                      ) : (
                        <button
                          onClick={() => {
                            setEditElement("");
                          }}
                          className="tetriaryButtonIconDefault"
                        >
                          <svg>
                            <use href="/images/icon24CancelCircle.svg#icon24" />
                          </svg>
                        </button>
                      )}
                    </div>
                  </div>
                );
              })}
            </>
          ) : (
            <div className="dictRowEmpty">Довідник не заповнено</div>
          )}
        </div>
      )} */}


{/* // ///////////// */}
<div
        className="dictionaryHeaderWithButton"
        id="builds"
        onClick={clickHandler}
      >
        <h2
          id="builds"
          className={selectedType === "builds" && "active" || ''}
          onClick={clickHandler}
        >
         Фізична форма
        </h2>
        <button
          id="builds"
          onClick={addHandler}
          className="secondaryButtonIconTextDefault"
        >
          <svg>
            <use href="/images/icon24Plus.svg#icon24" />
          </svg>
          Елемент довідника
        </button>
      </div>
      {newElement == "builds" && (
        <div className="dictRowNormal">
          <div className="dictRowValuesContainer">
            <div className="dictRowValue">
              <div className="apInputText">
                <input
                  type="text"
                  name="title"
                  value={form.value}
                  onChange={changeHandler}
                />
              </div>
            </div>
            <div className="dictRowButtonsContainer">
              <button
                onClick={saveAdd}
                id="builds"
                className="tetriaryButtonIconDefault"
              >
                <svg id="builds">
                  <use href="/images/icon24CheckCircle.svg#icon24" />
                </svg>
              </button>
              <button
                onClick={addHandler}
                id="builds"
                className="tetriaryButtonIconDefault"
              >
                <svg>
                  <use href="/images/icon24Cancel.svg#icon24" />
                </svg>
              </button>
            </div>
          </div>
        </div>
      )}
      {selectedType === "builds" && (
        <div className="dictAllRowsContainer">
          {!ready && (
            <div style={{ position: "relative" }}>
              {" "}
              <div style={{ position: "absolute", top: "50%", left: "50%" }}>
                <Loader />
              </div>{" "}
            </div>
          )}
          {dictionaries.length > 0 ? (
            <>
              {dictionaries.map((el, index) => {
                return (
                  <div key={index} className="dictRowNormal">
                    <div className="dictRowValuesContainer">
                      {editElement !== el.id ? (
                        <input
                          type="text"
                          id={el.id}
                          className="dictRowValue"
                          disabled={editElement !== el.id}
                          value={el.title}
                        />
                      ) : (
                        <div className="dictRowValue">
                          <div className="apInputText">
                            <input
                              type="text"
                              id={el.id}
                              name="title"
                              value={form.title ? form.title : el.title}
                              onChange={changeHandler}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="dictRowButtonsContainer">
                      {editElement !== el.id ? (
                        <button
                          id={el.id}
                          onClick={editClick}
                          className="tetriaryButtonIconDefault"
                        >
                          <svg id={el.id}>
                            <use
                              id={el.id}
                              href="/images/icon24Edit.svg#icon24"
                            />
                          </svg>
                        </button>
                      ) : (
                        <button
                          onClick={saveEdit}
                          id={el.id}
                          name="builds"
                          className="tetriaryButtonIconDefault"
                        >
                          <svg id={el.id} name="builds">
                            <use href="/images/icon24CheckCircle.svg#icon24" />
                          </svg>
                        </button>
                      )}
                      {editElement !== el.id ? (
                        <button
                          id={el.id}
                          onClick={deleteClick}
                          className="tetriaryButtonIconDefault"
                        >
                          <svg id={el.id}>
                            <use
                              id={el.id}
                              href="/images/icon24Cancel.svg#icon24"
                            />
                          </svg>
                        </button>
                      ) : (
                        <button
                          onClick={() => {
                            setEditElement("");
                          }}
                          className="tetriaryButtonIconDefault"
                        >
                          <svg>
                            <use href="/images/icon24CancelCircle.svg#icon24" />
                          </svg>
                        </button>
                      )}
                    </div>
                  </div>
                );
              })}
            </>
          ) : (
            <div className="dictRowEmpty">Довідник не заповнено</div>
          )}
        </div>
      )}



    </>
  );
};

export default DirectoryPage;

// SupportTicketStatuses,
// ClubStatuses,
// EventHoldingStatuses,
// NewsStatuses,
