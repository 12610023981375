import {
    GETEVENTSRESULT_SUCCESS,
    GETEVENTSRESULT_FAIL,

} from "../actions/types";

const initialState = [];

export default function eventsResultReducer(evResults = initialState,action){
    const {list,type} = action;

    switch(type) {
        case GETEVENTSRESULT_SUCCESS:
            return {...list};
        case GETEVENTSRESULT_FAIL:
            return evResults;
        default:
            return evResults;
    }

}