import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import InfoWindow from "../../components/InfoWindow/InfoWindow";
import Loader from "../../components/Loader/Loader";
import { createRoute } from "../../redux/actions/route";
import { deleteTrack, editTrack, getTrackItem } from "../../redux/actions/track";
import MapBlock from "./MapBlock/MapBlock";
import PhotoUploud from "./MapBlock/PhotoUpload/PhotoUploud";

const TrackItemPage = ()=>{
    const [ready, setReady] = useState(false);
    const [readyExit, setReadyExit] = useState(true);
    const [form, setForm] = useState(false);
    const { listId } = useSelector((state) => state);
    const { currentUser } = useSelector((state) => state.currentUser);
    const dispatch= useDispatch();
    const currentId=useParams().id;
    const navigate = useNavigate();
    const [error, setError] = useState({});
    const [selected, setSelected] = useState("");
    const { fullList } = useSelector((state) => state);
    const { list } = useSelector((state) => state.listId);
    const [alertMessage, setAlertMessage] = useState("");
    const [alertModal, setAlertModal] = useState(false);
    const [buttonMsg, setButtonMsg] = useState(false);
    const [buttonClick, setButtonClick] = useState(null);

    const deleteClick=()=>{
      dispatch(deleteTrack({id:currentId})).then(res=>{
        navigate('/tracklibrary');
      })
    }

    const initFunc=()=>{
            dispatch(getTrackItem({id:currentId})).then(res=>{
                setForm(res)
                setReady(true);
            })
    }
    const changeHandler = (e) => {
      setReadyExit(false);
      setError({})
      setForm({ ...form, [e.target.id]: e.target.value });
    };
    const backNav =()=>{
      navigate("/tracklibrary")
    }
    const backClick = () => {
        if(readyExit){
          backNav()
        }else{
          setAlertModal(true);
          setAlertMessage('Дані не збережено, ви впевнені що бажаєте вийти?');
          setButtonMsg('Вийти');
          setButtonClick(()=>backNav);
        }
      
      }
    const selectClick = (e) => {
      if (e.target.id == selected) {
        setSelected("");
      } else {
        setSelected(e.target.id);
      }
    };
    const setSelectClick = (e) => {
      let data = { ...form };
      setReadyExit(false);
      data[e.target.accessKey] = e.target.id;
      setForm(data);
      setSelected("");
    };

    const setSelectMassClick = (e) => {
      let data = { ...form };
      let key = e.target.accessKey;
      let id = e.target.id;
      if (!Array.isArray(data[key])) {
        data[key] = [];
      }
      if (data[key].includes(id)) {
        data[key] = data[key].filter(item => item !== id);
      } else {
        data[key].push(id);
      }
      console.log( data[key]);
      setReadyExit(false);
      setForm(data);
      // setSelected("");
    };

    const saveChanges=(e)=>{
      dispatch(editTrack(form)).then(res=>{
        console.log(res);
        setReadyExit(true);
      })

    }
    const createRouteClick =(e)=>{
      let data={trackId:currentId};
      dispatch(createRoute(data)).then(res=>{
        console.log(res);
        navigate('/routeitem/'+res.id)
      })
    }

    useEffect(() => {
        if(!ready){
            initFunc();
        }
    }, [ready]);

    if(ready){
return(
    <>
     {alertModal && (<InfoWindow
            setModal={setAlertModal}
            message={alertMessage}
            buttonMsg={buttonMsg}
            setClick={buttonClick}
          ></InfoWindow>
          )}
    
 <h1>
          Редагування треку
        </h1>
        <div className="menuFunctionLine">
          <div className="actionButtonsBlock">
            <button
              className="secondaryButtonIconTextDefault"
              onClick={backClick}
            >
              <svg>
                <use href="/images/icon24ArrowLeft.svg#icon24" />
              </svg>
              Назад
            </button>
            {/* {form.statusId === "def3497e-3498-4a17-a514-e99ec83676b4" &&
              currentUser.roleId === "2f5a84bc-52b5-4777-8790-e2c32daa6a34" && (
                <button
                  className="primaryButtonIconTextDefault"
                  accessKey="publish"
                //   onClick={changeStatus}
                >
                  <svg accessKey="publish">
                    <use
                      accessKey="publish"
                      href="/images/icon24CheckCircle.svg#icon24"
                    />
                  </svg>
                  Опублікувати
                </button>
              )} */}
              <button
                className="primaryButtonIconTextDefault"
                accessKey="send"
                onClick={saveChanges}
              >
                <svg accessKey="send">
                  <use
                    accessKey="send"
                    href="/images/icon24CheckCircle.svg#icon24"
                  />
                </svg>
                Зберегти зміни
              </button>


            {(form.statusId === "66e2ec16-03a1-4367-834a-d6b87ee709bd" ||
              form.statusId === "f7b77851-1d35-4ab1-9fcd-ef6cd2b88ace") && (
              <button
                accessKey="send"
                className="secondaryButtonIconTextDefault"
                // onClick={changeStatus}
              >
                <svg accessKey="send">
                  <use
                    accessKey="send"
                    href="/images/icon24ArrowReturn.svg#icon24"
                  />
                </svg>
                Зняти з публікації та редагувати
              </button>
            )}
                <button
                  accessKey="deactivate"
                  className="secondaryButtonTextDefault"
                  onClick={createRouteClick}
                >
                    Створити маршрут
                </button>
            <div className="apCheckBoxWithLabel">
              <input
                id="closedGroundSwiper"
                disabled={
                  form.statusId === "66e2ec16-03a1-4367-834a-d6b87ee709bd"
                }
                checked={form.integrity==='1'}
                type="checkbox"
                className="apCheckbox"
                onChange={() =>
                  setForm({ ...form, ["integrity"]:form.integrity==='1'?'0':'1'})
                }
                // onBlur={saveEdit}
              />
              <label htmlFor="closedGroundSwiper">Безбар’єрність</label>
            </div>
          </div>
          <div className="actionTriggersBlock">
            
            <button
                className="secondaryButtonIconTextDefault"
                onClick={deleteClick}
              >
                <svg accessKey="send">
                  <use
                    accessKey="send"
                    href="/images/icon24Trash.svg#icon24"
                  />
                </svg>
                Видалити
            </button>
          </div>
        </div>
        <div className="pageContentHeaderDivider" />

        <MapBlock setForm={setForm} form={form}></MapBlock>

        <div>
                <h2>Опис треку</h2>
                <div className="trackInfoRow">
                    <div className="trackColumn">
                        <h3>Назва та опис</h3>
                        <div className="apInputText apFilled">
                        <label htmlFor="name">Назва треку</label>
                        
                            <input
                            id="name"
                            onChange={changeHandler}
                            type="text"
                            placeholder="Введіть"
                            value={form.name}
                            />
                        </div>


                    <div className="apTextarea apFilled">
                    <label htmlFor="description">Опис</label>
                    <textarea name="description" placeholder="Введіть" id="description" maxLength={1000} value={form.description} onChange={changeHandler}></textarea>
                    {error.description && <span>{error.description}</span>}
                    </div>
                
                    </div>
                    <div className="trackColumn">
                        <h3>Тип, адреса та складність</h3>
                        <div className="apDropDownList apFilled">
                        <label htmlFor="type">Тип активності</label>
                        <div id="type" onClick={selectClick}>
                            <input
                            id="type"
                            readOnly
                            type="text"
                            value={list[form?.type]||'Обрати'}
                            />
                            <svg id="type" onClick={selectClick}>
                            <use
                                id="type"
                                onClick={selectClick}
                                href="/images/icon24DropDown.svg#icon24"
                            />
                            </svg>
                        </div>
                        {selected == "type" && (
                            <div className="apOptionsList">
                                <div
                                    accessKey="type"
                                    id={''}
                                    key={''}
                                    onClick={setSelectClick}
                                    tabIndex="0"
                                    className={'' === form.type ? "apActive" : ""}
                                >
                                    Обрати
                                </div>
                            {fullList.ClubTypes.map((el) => {
                                if(el.id==='eb1f23b9-a872-43ef-8462-a513504af111'){return};
                                return (
                                <div
                                    accessKey="type"
                                    id={el.id}
                                    key={el.id}
                                    onClick={setSelectClick}
                                    tabIndex="0"
                                    className={el.id === form.type ? "apActive" : ""}
                                >
                                    {el.title}
                                </div>
                                );
                            })}
                            </div>
                        )}
                        </div>
                        <div className="apInputText apFilled">
                        <label htmlFor="address">Адреса треку</label>
                        <input
                            id="address"
                            type="text"
                            placeholder=""
                            value={form.address}
                            onChange={changeHandler}
                        />
                        </div>
                        <div className="apDropDownList apFilled">
                        <label htmlFor="complexityId">Складність треку</label>
                        <div id="complexityId" onClick={selectClick}>
                            <input
                            id="complexityId"
                            readOnly
                            type="text"
                            value={form.complexityId ? list[form.complexityId] : "Обрати"}
                            />
                            <svg id="complexityId" onClick={selectClick}>
                            <use
                                id="complexityId"
                                onClick={selectClick}
                                href="/images/icon24DropDown.svg#icon24"
                            />
                            </svg>
                        </div>
                        {selected == "complexityId" && (
                            <div className="apOptionsList">
                                <div
                                    id={''}
                                    key={''}
                                    className={
                                        '' === form.complexityId ? "apActive" : ""
                                    }
                                    accessKey="complexityId"
                                    onClick={setSelectClick}
                                    >
                                    Обрати
                                </div>
                            {fullList.DegreeOfDifficulty.map((el) => {
                                return (
                                    <div
                                    id={el.id}
                                    key={el.id}
                                    className={
                                        el.id === form.complexityId ? "apActive" : ""
                                    }
                                    accessKey="complexityId"
                                    onClick={setSelectClick}
                                    >
                                    {el.title}
                                    </div>
                                );
                                
                            })}
                            </div>
                        )}
                        </div>
                    </div>
                    <div className="trackColumn trackColumnLast">
                        <h3>Додатковий опис</h3>
                        <div className="apInputText apFilled">
                        <label htmlFor="recommendedTime">Рекомендований час для проходження треку</label>
                        <input
                            id="recommendedTime"
                            type="text"
                            value={form.recommendedTime}
                            onChange={changeHandler}
                        />
                        {error.recommendedTime && <span>{error.recommendedTime}</span>}
                        </div>
                        <div className="apInputText">
                        <label htmlFor="routeLength">Довжина треку</label>
                        <input id="routeLength" 
                        disabled
                        value={(+form.routeLength).toFixed(3)}
                        type="text" 
                        name="routeLength"/>
                            <span>Зафіксована довжина треку в км.</span>
                        </div>
                      <div className="apDropDownList apFilled" style={{position:'relative'}}>
                        <label htmlFor="coverType">Покриття</label>
                        <div id="coverType" onClick={selectClick}>
                            <input
                            id="coverType"
                            readOnly
                            type="text"
                            value={'Додати покриття'}
                            />
                            <svg id="coverType" onClick={selectClick}>
                            <use
                                id="coverType"
                                onClick={selectClick}
                                href="/images/icon24DropDown.svg#icon24"
                            />
                            </svg>
                        </div>
                        {selected == "coverType" && (
                            <div className="apOptionsList">
                                <div
                                    id={''}
                                    key={''}
                                    className={
                                        '' === form.coverType ? "apActive" : ""
                                    }
                                    accessKey="coverType"
                                    onClick={setSelectMassClick}
                                    >
                                    Додати покриття
                                    </div>
                            {fullList.RoadSurface.map((el) => {
                                return (
                                    <div
                                    id={el.id}
                                    key={el.id}
                                    className={form.coverType?.includes(el.id) ? "apActive" : ""}
                                    accessKey="coverType"
                                    onClick={setSelectMassClick}
                                    >
                                    {el.title}
                                    </div>
                                );
                            })}
                            </div>
                        )}
                        <div className="coverList">
                        {form.coverType?.length > 0 && form.coverType.map(el => {
                          return (
                            <div className="coverItem" key={el}>
                              {list[el]}
                              <div className="crossButton" onClick={() => {
                                const updatedForm = { ...form };
                                updatedForm.coverType = updatedForm.coverType.filter(item => item !== el);
                                setForm(updatedForm);
                              }}>
                                X
                              </div>
                            </div>
                          );
                        })}
                        </div>
                        </div>
                    </div>
                </div>
            </div>
             
            <div>
                <h2>Фото треку</h2>
            </div>
        
        <PhotoUploud form={form} setForm={setForm}  setReadyExit={setReadyExit}/>
    
    </>
)}
else{
  return(
    <Loader/>
  )
}

}

export default TrackItemPage;