
import {
    CREATEWORKOUTSPLAN_SUCCESS,
    CREATEWORKOUTSPLAN_FAIL,
    GETWORKOUTSPLAN_SUCCESS,
    GETWORKOUTSPLAN_FAIL

} from "../actions/types";

const initialState = [];

export default function workoutsPlanReducer(workoutsPlan = initialState,action){
    const {list,item,type} = action;

    switch(type) {

        case GETWORKOUTSPLAN_SUCCESS:
            return {...list};
        case GETWORKOUTSPLAN_FAIL:
            return workoutsPlan;
        case CREATEWORKOUTSPLAN_SUCCESS:
            return {...item};
        case CREATEWORKOUTSPLAN_FAIL:
            return workoutsPlan;
        default:
            return workoutsPlan;
    }

}