import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { setCoordinate } from "../../redux/actions/auth";
import { getParksAll,getParksRoutesAll,getParksRoutesAllMap } from "../../redux/actions/parks";
import Loader from "../../components/Loader/Loader";
import Tile from "../../components/Tile/";
import Map from "./Map/Map";
import { getRoutePoints } from "../../redux/actions/route";

const MapPageUser = () => {
  const { coordinates } = useSelector((state) => state);
  const { parks } = useSelector((state) => state);
  const { parksMap } = useSelector((state) => state);
  const [routesActive, setRoutesActive] = useState(true);
  const [parksActive, setParksActive] = useState(true);
  const navigator = useNavigate();
  const [ready, setReady] = useState(false);
  const [readyMap, setReadyMap] = useState(false);

  const [searchList, setSearchList] = useState([]);
  const dispatch = useDispatch();
  const [range, setRange] = useState("150 км.");
  const [radius, setRadius] = useState("150");
  const [searchOpen, setSearchOpen] = useState(false);
  const [filterArr, setFilterArr] = useState({});
  const [filterMapArr, setFilterMapArr] = useState({});

  
  const [currentRoute,setCurrentRoute]=useState([]);
  const [difficult,setDifficult]=useState();
  const [searchValue, setSearchValue] = useState("");
  const [intervalId,setIntervalId] = useState(null);
  const [city, setCity] = useState("");

  const openInNewTab = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  const getData = async (amount = 0, filter = {}) => {
    let data = {
      offset: amount,
      limit: 100,
      filter: filter,
      sort: { distanceToPoint: "asc" },
    };
    if (!data.filter?.radius) {
      data.filter.radius = [];
      data.filter.radius[0] = radius;
    }
    if(!data.filter.radius[0]){
      data.filter.radius[0] = '150'
    }
    data.filter.statusId = ["036735ba-f634-469b-ac30-77a164e3a918"];
    setFilterArr(data.filter);
    dispatch(getParksRoutesAll(data)).then((res) => {
      setReady(true);
    });
  };
  const getData2 = async (amount = 0, filter = {}) => {
    let data = {
      offset: amount,
      limit: 100,
      filter: filter,
      sort: { distanceToPoint: "asc" },
    };
    if (!data.filter?.radius) {
      data.filter.radius = [];
      data.filter.radius[0] = radius;
    }
    if(!data.filter.radius[0]){
      data.filter.radius[0] = '150'
    }
    data.filter.statusId = ["036735ba-f634-469b-ac30-77a164e3a918"];
    // setFilterArr(data.filter);
    dispatch(getParksRoutesAll(data)).then((res) => {
      // setReady(true);
    });
  };

  const getDataMap = async (amount = 0,) => {
    let data = {
      offset: amount,
      limit: 100,
      filter: {},
      sort: { distanceToPoint: "asc" },
    };
    data.filter.radius=['3000'];
    data.filter.statusId = ["036735ba-f634-469b-ac30-77a164e3a918"];
    setFilterMapArr(data.filter);
    dispatch(getParksRoutesAllMap(data)).then((res) => {
      setReadyMap(true);
    });
  };

  useEffect(() => {
    if(!readyMap){
      getDataMap();
    }
  }, [readyMap]);

  

  const getMyCoordinates = (coordinates) => {
    getData2(0, {
      statusId: ["036735ba-f634-469b-ac30-77a164e3a918"],
      longitude: [coordinates.lng],
      latitude: [coordinates.lat],
      radius:[coordinates.radius]
    });
  };
  const getMyCoordinates2 = (coordinates) => {
    getData(0, {
      statusId: ["036735ba-f634-469b-ac30-77a164e3a918"],
      longitude: [coordinates.lng],
      latitude: [coordinates.lat],
      radius:[coordinates.radius]
    });
  };

  const searchChange = (e) => {
    let filter = { ...filterArr };
    setSearchValue(e.target.value);
    filter.all = [];

    clearTimeout(intervalId);
    const newIntervalId = setTimeout(() =>{
    
      if (e.target.value.length > 2) {
        filter.all[0] = e.target.value;
        getData(0, filter);
      } else if (e.target.value.length == 0) {
        getData(0, filter);
      }
    }, 1000)
    setIntervalId(newIntervalId);  ;

    
  };

  const changeRange = (amount) => {
    let filter = { ...filterArr };
    filter.radius = [];
    console.log('amount')
    console.log(amount)

    if (amount > 500) {
      filter.radius[0] = "";
      setRadius("");
      getData(0, filter);
      setRange("Всі парки");
    } else {
      filter.radius[0] = amount;
      setRadius(amount);
      getData(0, filter);
      setRange(amount + " км.");
    }
  };
  const cityChangeSelector = (e) => {
    setSearchOpen(true);
    setCity(e.target.value);
    if (e.target.value.length > 2) {
      axios
        .get(`https://ap.sportforall.gov.ua/api/v1/location/search?limit=35&query=${e.target.value}`)
        .then((res) => {
          setSearchList(res.data);
        });
    }
  };

  const setSearchListClick = (e) => {
    let data = {
      lat: e.target.id.split("/")[0],
      lng: e.target.id.split("/")[1],
    };
    dispatch(setCoordinate(data));
    getMyCoordinates2(data);
    setSearchOpen(false);
  };

  useEffect(() => {
    if (!ready && coordinates && coordinates.lat) {
      let filter = {
        longitude: [coordinates.lng],
        latitude: [coordinates.lat],
      };
      setFilterArr(filter);
      getMyCoordinates2(coordinates);
    }
  }, [coordinates]);

  if (!ready || !readyMap) {
    return <Loader />;
  }
  if (ready && readyMap &&coordinates && coordinates.lat && parks && parks.items) {
    return (
      <>
        <Map
          range={range}
          getMyCoordinates={getMyCoordinates}
          changeRange={changeRange}
          coordinates={coordinates}
          routesActive={routesActive}
          parksActive={parksActive}
          currentRoute={currentRoute}
          difficult={difficult}
        ></Map>

        <div className="searchMapTab">
          <div className={"apInputText"} style={{ position: "relative" }}>
            <label htmlFor="city">Пошук міста</label>
            <input
              className="searchMapTabInput"
              onChange={cityChangeSelector}
              value={city}
              id="city"
              type="text"
            />

            {
              <div className="apOptionsList searchMapTabList ">
                {searchList &&
                  searchList.length > 0 &&
                  searchList.map((el, index) => {
                    return (
                      <div
                        key={index}
                        id={el.lat + "/" + el.lng}
                        onClick={setSearchListClick}
                      >
                        {el.city+' '+el.region}
                      </div>
                    );
                  })}
              </div>
            }
          </div>

          <div className="buttonGroup">
            <button  accessKey="parks" onClick={()=>{setParksActive(!parksActive)}} className={parksActive?"calendarFilterItem secondaryButtonIconTextDefault apActive":"calendarFilterItem secondaryButtonIconTextDefault"}>
              <svg accessKey="parks">
                <use
                  accessKey="parks"
                  href="/images/icon24MapPin.svg#icon24"
                  />
              </svg>
              Парки
            </button>
            <button  accessKey="routes" onClick={()=>{setRoutesActive(!routesActive)}} className={routesActive?"calendarFilterItem secondaryButtonIconTextDefault apActive":"calendarFilterItem secondaryButtonIconTextDefault"}>
              <svg accessKey="routes">
                <use
                  accessKey="routes"
                  href="/images/icon24MapPin.svg#icon24"
                  />
              </svg>
              Маршрути
            </button>
          </div>

          {/* <div className='apInputText'>
                        <label htmlFor="radius">Пошук в радіусі</label>
                        <input onChange={changeHandler} onBlur={saveChange} id="radius" type="text"/>
                    </div> */}
        </div>
        <div className="pageContentHeaderDivider" />
        <div
          className={"apInputText"}
          style={{ width: "32%", marginTop: "20px" }}
        >
          <label htmlFor="search">Пошук за назвою (серед знайдених в радіусі пошуку)</label>
          <input
            onChange={searchChange}
            value={searchValue}
            id="search"
            type="text"
          />
        </div>

        <div className="parkItemCardList">
          {parksActive&& parks &&
            parks.items?.length > 0 &&
            parks.items.map(
              ({photos, photo, title, distanceToPoint, location, id }, index) => (
                <Tile
                  key={index}
                  type={"MAP"}
                  img={photo}
                  id={id}
                  photos={photos}
                  onError={
                    "https://ap.sportforall.gov.ua/api/v1/uploads/b34604xc-02qb-46bb-9e91-fc3ffrb92w41"
                  }
                  title={title}
                  range={distanceToPoint?.toFixed(2) || "0"}
                  nav={
                    <>
                      <div className="buttonWrap">
                        <button
                          className="primaryButtonTextDefault"
                          onClick={() =>
                            openInNewTab(
                              `https://www.google.com.ua/maps/place/${location[0]}+${location[1]}`
                            )
                          }
                        >
                          Прокласти маршрут
                        </button>
                      </div>
                      <div className="buttonWrap">
                        <button
                          className="secondaryButtonTextDefault"
                          onClick={() => navigator(`/parkInfo/${id}`)}
                        >
                          Інформація
                        </button>
                      </div>
                    </>
                  }
                  key={index}
                />
              )
            )
          }
            {routesActive &&parks &&
            parks.itemsActive?.length > 0 &&
            parks.itemsActive.map(
              (el, index) => (
                <Tile
                  key={index}
                  type={"Route"}
                  el={el}
                  nav={
                    <>
                      <div className="buttonWrap">
                        <button
                          className="primaryButtonTextDefault"
                          onClick={() =>
                            openInNewTab(
                              `https://www.google.com.ua/maps/place/${el.location[0]}+${el.location[1]}`
                            )
                          }
                        >
                          Прокласти маршрут
                        </button>
                      </div>
                      <div className="buttonWrap">
                        <button
                          className="secondaryButtonTextDefault"
                          onClick={() => navigator(`/routeInfo/${el.id}`)}
                        >
                          Інформація
                        </button>
                      </div>
                    </>
                  }
                  key={index}
                />
              )
            )
          }
        </div>
      </>
    );
  }
};

export default MapPageUser;
