import { Icon } from "../../../components/Icon";
import getDate from "../../../helpers/getDate";
import useCurrentMediaType from "../../../hooks/currentMediaType";

export const Contacts = (props) => {
  const { isCurrentSize: isMobile } = useCurrentMediaType(775);
  const date = getDate(props.creationDate, "dd/mm/yyyy", ".");

  return isMobile ? (
    <div className="apFcContacts">
      <div className="logoWrp">
        <div
          className="img"
          style={{
            backgroundImage: `url(${
              props.logo ||
              "https://ap.sportforall.gov.ua/api/v1/uploads/b34604xc-02qb-46bb-9e91-fc3ffrb92w41"
            })`,
          }}
        ></div>
      </div>
      <div className="content">
        <div className="art">
          <span>{`${props.participants}`}</span>
          Кількість учасників
        </div>
        <div className="art">
          <span>{date}</span>
          Дата створення
        </div>
        <div className="art">
          <a className="apFcContactsTel" href={`tel:${props.tel}`}>
            {props.tel}
          </a>{" "}
          Номер координатора
        </div>
        <div className="iconList">
        {props.links.telegramUrl !== null && (
          <Icon type={"telegram"} link={props.links.telegramUrl} />
        )}
        {props.links.youtubeUrl !== null && (
          <Icon type={"youtube"} link={props.links.youtubeUrl} />
        )}
        {props.links.instagramUrl !== null && (
          <Icon type={"instagram"} link={props.links.instagramUrl} />
        )}
        {props.links.facebookUrl !== null && (
          <Icon type={"facebook"} link={props.links.facebookUrl} />
        )}
      </div>
      </div>
    </div>
  ) : (
    <div className="apFcContacts">
      <div className="iconList">
        {props.links.telegramUrl !== null && (
          <Icon type={"telegram"} link={props.links.telegramUrl} />
        )}
        {props.links.youtubeUrl !== null && (
          <Icon type={"youtube"} link={props.links.youtubeUrl} />
        )}
        {props.links.instagramUrl !== null && (
          <Icon type={"instagram"} link={props.links.instagramUrl} />
        )}
        {props.links.facebookUrl !== null && (
          <Icon type={"facebook"} link={props.links.facebookUrl} />
        )}
      </div>
      {props.tel !== null && (
        <a className="apFcContactsTel" href={`tel:${props.tel}`}>
          {props.tel}
        </a>
      )}
    </div>
  );
};
