import React, { useState, useEffect } from "react";
import { Swiper as Slider, SwiperSlide as Slide } from "swiper/react";
import { Pagination } from "swiper";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import Loader from "../../components/Loader/Loader";
import Tile from "../../components/Tile/";

import useFilterArray from "../../hooks/filterArray";
import { getFcNews, getFcNewsCurrentItem } from "../../redux/actions/fcNews";
import getDate from "../../helpers/getDate";

import "swiper/css";

const FCNEwsPage = () => {
  const [ready, setReady] = useState(false);
  const [form, setForm] = useState({});
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const currentId = useParams().id;
  const currentNewsId = useParams().newsId;
  const { fcNews } = useSelector((state) => state);

  const getNewsList = (page = 1, filter) => {
    let data = {};
    if (filter) {
      data.filter = { ...filter };
    } else {
      if (localStorage.getItem("newsFilter")) {
        data.filter = { ...JSON.parse(localStorage.getItem("newsFilter")) };
      } else {
        data.filter = {};
      }
    }
    if (localStorage.getItem("newsFilter")) {
      data.sort = { ...JSON.parse(localStorage.getItem("newsFilter")) };
    } else {
      data.sort = { updatedAt: "asc" };
    }
    data.offset = (page - 1) * 9;
    data.limit = 9;
    data.id = currentId;
    dispatch(getFcNews(data)).then((res) => {
      setReady(true);
    });
  };

  const { filteredData } = useFilterArray(fcNews, { id: currentNewsId });

  const getItem = () => {
    dispatch(getFcNewsCurrentItem({ id: currentId, newsId: currentNewsId }))
      .then((res) => {
        setForm({ ...res });
        getNewsList();
        setReady(true);
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const linkClick = (e) => {
    navigate(e.target.id + currentId);
  };
  useEffect(() => {
    if (!ready) {
      getItem();
    }
  }, [ready]);

  return (
    <div className="apIinnerPage fcNewsInner">
      <div className="headerWithButtonsHeaderBlock dateOfPublish">
        <h1>
          Блог{" "}
          <div className="headerSideText">
            Актуальні та минулі заходи, інформація про оновлення, анонси та
            звіти, та ще багато цікавого та корисного
          </div>
        </h1>
        <div style={{alignSelf:'end'}}>
        Опубліковано:&nbsp;
          {getDate(form.publishedAt, "dd/mm/yyyy",'.')}
          </div>
      </div>

      <div className="pageContentHeaderDivider">&nbsp;</div>
      {ready ? (
        <div style={{ marginTop: "20px" }}>
          {form.imageUrl?.length>0 && <div>
            <img loading="lazy" src={form.imageUrl} width="100%" alt="" />
          </div>}

          <h2 style={{ marginTop: "20px", marginBottom: "10px" }}>
            {form.title}
          </h2>
         
          {form?.photos && form.photos.length !== 0 && (
            <Slider
              modules={[Pagination]}
              pagination={{ clickable: true }}
              autoHeight
            >
              {form.photos.map((el, index) => (
                <Slide key={index}>
                  <img
                    style={{
                      width: "100%",
                      heigt: "auto",
                      verticalAlign: "bottom",
                      borderRadius: "16px",
                    }}
                    src={el}
                    alt={`img${index}`}
                  />
                </Slide>
              ))}
            </Slider>
          )}
          <div
            style={{ marginTop: "20px" }}
            dangerouslySetInnerHTML={{ __html: form.body }}
          ></div>
          <button className="startsButton" id="/fc/" onClick={linkClick}>
            <svg>
              <use href="/images/icon24Info.svg#icon24" />
            </svg>
            Читати більше записів блогу
          </button>
          {/* {filteredData && (
            <>
              {" "}
              <h2>Інші записи блогу активних парків</h2>
              <div style={{ marginTop: "20px" }} className="newsList">
                <Slider
                  slidesPerView={1}
                  breakpoints={{
                    640: {
                      slidesPerView: 1,
                    },
                    768: {
                      slidesPerView: 2,
                    },
                    991: {
                      slidesPerView: 3,
                    },
                  }}
                  modules={[Pagination]}
                  pagination={{ clickable: true }}
                >
                  {filteredData.map(({ photos, title, id }, index) => (
                    <Slide key={index}>
                      <Tile
                        img={photos[0] || null}
                        title={title}
                        to={`/fc/${currentId}/news/${id}`}
                      />
                    </Slide>
                  ))}
                </Slider>
              </div>
            </>
          )} */}
        </div>
      ) : (
        <Loader></Loader>
      )}
    </div>
  );
};

export default FCNEwsPage;
