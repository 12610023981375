import {
    FULLLIST_SUCCESS,
    FULLLIST_FAIL
}from "../actions/types";

const initialState = [];

export default function dictionariesReducer(fullList = initialState, action) {
    const { type,list } = action;
      
    switch (type) {
          case FULLLIST_SUCCESS:
          return {...list};
          case FULLLIST_FAIL:
          return fullList;
        default:
          return fullList;
    }
}