import {
    GETVIDEO_SUCCESS,
    GETVIDEO_FAIL,
    EDITVIDEO_SUCCESS,
    EDITVIDEO_FAIL,
    DELETEVIDEO_SUCCESS,
    DELETEVIDEO_FAIL,
    GETVIDEOITEM_SUCCESS,
    GETVIDEOITEM_FAIL,
    CREATEVIDEO_SUCCESS,
    CREATEVIDEO_FAIL
} from "./types";

import VideoService from "../services/video.service"


export const getVideos = (data)=> async(dispatch)=>{
        
    try{
        const res = await VideoService.getVideos(data);
        
        dispatch({
          type: GETVIDEO_SUCCESS,
          list: res,
        });
        
        return res
    }catch (error){
        
        dispatch({
            type: GETVIDEO_FAIL,
            list: {error: error.response},
          });
          const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
          return error.response
    }
}

export const qrReadVideo = (data)=> async(dispatch)=>{
        
    try{
        const res = await VideoService.qrReadVideo(data);        
        return res
    }catch (error){
        dispatch({
            type: GETVIDEO_FAIL,
            list: {error: error.response},
          });
          const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
          return error.response
    }
}


export const editVideo= (form)=>(dispatch)=>{
      
    return VideoService.editVideo(form).then(
        (response)=>{
              
            dispatch({
                type:EDITVIDEO_SUCCESS,
                item:response
            });
            
            return response

        },
        (error)=>{
            dispatch({
                type: EDITVIDEO_FAIL,
              });
            console.log(error);
              return Promise.reject();
        }
    );
}

export const editVideoVote= (form)=>(dispatch)=>{
      
    return VideoService.voteVideo(form).then(
        (response)=>{
              
            dispatch({
                type:EDITVIDEO_SUCCESS,
                item:response
            });
            
            return response

        },
        (error)=>{
            dispatch({
                type: EDITVIDEO_FAIL,
              });
            console.log(error);
              return Promise.reject();
        }
    );
}

export const deleteVideo= (data)=>(dispatch)=>{
      
    return VideoService.deleteVideo(data).then(
        (response)=>{
            dispatch({
                type:DELETEVIDEO_SUCCESS,
            });
              
        },
        (error)=>{
            dispatch({
                type: DELETEVIDEO_FAIL,
              });
            console.log(error);
                 
              return Promise.reject();
        }
    );
}

export const getVideoItem = (data)=>(dispatch)=>{
    return VideoService.getVideoItem(data).then(
        (response)=>{
              
            dispatch({
                type:GETVIDEOITEM_SUCCESS,
                item:response,
            });
              
            return response

        },
        (error)=>{
            dispatch({
                type: GETVIDEOITEM_FAIL,
              });
            console.log(error);
                 
              return Promise.reject();
        }
    );

}


export const addVideo = (data)=> async(dispatch)=>{
    try {
        const res = await VideoService.addVideo(data);
        dispatch({
          type: CREATEVIDEO_SUCCESS,
          item: res
        });
        return res
    }catch (error){
        dispatch({
            type: CREATEVIDEO_FAIL,
            item: {error: error.response},
          });
          const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
          return error.response;
    }
}