// "length === 25"

const checkText = (text,length) => {
      const leng = length || 25;

    if (text&& text.length > leng) {
        text = text.substring(0, leng) + "...";
    } 
    return text;

  };
  
  export default checkText;