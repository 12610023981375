import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Loader from "../../components/Loader/Loader";
import Pagination from "../../components/Paggination/Paggination";
import Select from "../../components/Select";
import getDate from "../../helpers/getDate";
import { addEvents, getEvents } from "../../redux/actions/events";
import { getParksCoordinatorList } from "../../redux/actions/parks";
import Filter from "./Filter/Filter";

const sortOtions = [
  { id: "title", value: "За абеткою" },
  { id: "createdAt", value: "За датою cтворення" },
  { id: "startsAt", value: "За датою початку" },
  { id: "updatedAt", value: "За датою редагування" },
];

const SportEventsListPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchOpen, setSearchOpen] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [intervalId,setIntervalId] = useState(null);  const [ready, setReady] = useState(false);
  const [sortData, setSortData] = useState("startsAt");
  const [sortName, setSortName] = useState("За датою початку");
  const [sortValue, setSortValue] = useState("desc");
  const { listId } = useSelector((state) => state);
  const { events } = useSelector((state) => state);
  const { currentUser } = useSelector((state) => state.currentUser);
  const [currentPage, setCurrentPage] = useState(1);
  const [filter, setFilter] = useState(false);
  const [filterArr, setFilterArr] = useState({});
  const [items, setItems] = useState([]);

  const searchChange = (e) => {
    let filter = {};
    setSearchValue(e.target.value);
    if (localStorage.getItem("eventsFilter")) {
      filter = JSON.parse(localStorage.getItem("eventsFilter"));
    }
    filter.all = [];
    
        clearTimeout(intervalId);
    const newIntervalId = setTimeout(() =>{
      // Call your search function with the updated query value
    
      if (e.target.value.length > 2) {
        filter.all[0] = e.target.value;
        localStorage.setItem("eventsFilter", JSON.stringify(filter));
        getEventsList(1, filter);
      } else {
        localStorage.setItem("eventsFilter", JSON.stringify(filter));
        getEventsList(1, filter);
      }
    }, 1000)
    setIntervalId(newIntervalId);  ;

    
  };

  const AddEvents = (e) => {
    setReady(false);
    e.preventDefault();
    dispatch(addEvents())
      .then((res) => {
        navigate(`/eventsItem/${res.id}`);
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const sortClick = (e) => {
    setSortData(e.target.id);
    setSortName(e.target.innerHTML);

    let data = {};
    if (sortData === e.target.id && sortValue === "desc") {
      setSortValue("asc");
      data[e.target.id || sortOtions[0].id] = "asc";
    } else {
      data[e.target.id || sortOtions[0].id] = "desc";
      setSortValue("desc");
    }
    localStorage.setItem("eventsSort", JSON.stringify(data));
    getEventsList();
  };

  const getEventsList = (page = 1, filter) => {
    let data = {};
    if (filter) {
      data.filter = { ...filter };
    } else {
      if (localStorage.getItem("eventsFilter")) {
        data.filter = { ...JSON.parse(localStorage.getItem("eventsFilter")) };
      } else {
        data.filter = {};
      }
    }
    if (localStorage.getItem("eventsSort")) {
      let sort = JSON.parse(localStorage.getItem("eventsSort"));
      data.sort = { ...sort};
      // setSortData()
    } else {
      data.sort = { createdAt: "desc" };
    }
    data.filter.isClubEvent = ["0"];
    data.offset = (page - 1) * 10;
    data.limit = 10;
    dispatch(getEvents(data)).then((res) => {
      setItems(res.items);
      setReady(true);
    });
  };
  const resetFilter = () => {
    localStorage.removeItem("eventsFilter");
    setFilterArr({});
    getEventsList();
  };

  useEffect(() => {
    if (!ready) {
      if (localStorage.getItem("eventsFilter")) {
        let filter = JSON.parse(localStorage.getItem("eventsFilter"));
        getEventsList(1, filter);
        setFilterArr(filter);
      } else {
        getEventsList();
      }
    }
  }, [ready]);

  if (!ready || !events.items || !listId.list) {
    return (
      <div style={{ position: "relative" }}>
        {" "}
        <div style={{ position: "absolute", top: "50%", left: "50%" }}>
          <Loader />
        </div>{" "}
      </div>
    );
  } else if (ready && events.items && listId.list) {
    return (
      <>
        {filter && (
          <Filter
            setModal={setFilter}
            getEventsList={getEventsList}
            setFilter={setFilterArr}
          ></Filter>
        )}
        <div className="headerWithButtonsCommonBlock">
          <div className="headerWithButtonsHeaderBlock">
            <h1>Каталог заходів</h1>
          </div>
          <div className="headerWithButtonsButtonBlock">
            {searchOpen && (
              <div style={{ marginBottom: "4px" }} className="apInputText">
                <label htmlFor="search">Пошук</label>
                <input
                  id="search"
                  type="text"
                  placeholder="Мінімум 3 символи"
                  value={searchValue}
                  onChange={searchChange}
                />
              </div>
            )}
            <button
              className="secondaryButtonIconDefault"
              onClick={() => setSearchOpen(!searchOpen)}
            >
              <svg>
                <use href="/images/icon24Search.svg#icon24" />
              </svg>
            </button>
            {/* <div className="apSwitch">
              <button className="secondaryButtonIconActive">
                <svg>
                  <use href="/images/icon24List.svg#icon24" />
                </svg>
              </button>
              <button
                className="secondaryButtonIconDefault"
                onClick={() => {
                  navigate("/eventsCalendar");
                }}
              >
                <svg>
                  <use href="/images/icon24Map.svg#icon24" />
                </svg>
              </button>
            </div> */}
          </div>
        </div>
        <div className="sortAndFilterBlock">
          <div className="menuFunctionLine">
            <div className="actionButtonsBlock">
              {currentUser &&
                currentUser.roleId !==
                  "5dcf0363-d171-45db-9280-cb337ca5e101" && (
                  <button
                    className="primaryButtonIconTextDefault"
                    onClick={AddEvents}
                  >
                    <svg>
                      <use href="/images/icon24Plus.svg#icon24" />
                    </svg>
                    Створити захід
                  </button>
                )}
              <div className="filterBlock">
                <button
                  className="secondaryButtonIconTextDefault"
                  onClick={() => setFilter(true)}
                >
                  <svg>
                    <use href="/images/icon24Filter.svg#icon24" />
                  </svg>
                  Фільтр
                </button>
                <span>Знайдено {events.total} заходів:</span>
                {filterArr &&
                  Object.keys(filterArr).length > 0 &&
                  Object.keys(filterArr).map((el) => {
                    return (
                      filterArr[el].length > 0 &&
                      filterArr[el].map((elem, index) => {
                        return (
                          <div className="apChips" key={index}>
                            {el === "statusId" && `${listId.list[elem]}`}

                            {el === "createdFrom" && `Створено з ${elem}`}
                            {el === "createdTo" && `Створено по ${elem}`}
                            {el === "startsFrom" && `Розпочато з ${elem}`}
                            {el === "startsTo" && `Розпочато по ${elem}`}
                            {el === "finishesFrom" && `Закінчено з ${elem}`}
                            {el === "finishesTo" && `Закінчено по ${elem}` } 
                            {/* <svg><use href="/images/icon24CancelSmall.svg#icon24" /></svg> */}
                          </div>
                        );
                      })
                    );
                  })}
                {filterArr && Object.keys(filterArr).length > 0 && (
                  <button
                    onClick={resetFilter}
                    className="secondarySmallRoundedButtonTextDefault"
                  >
                    Скинути
                  </button>
                )}
              </div>
            </div>
            <div className="sortBlock">
            <Select
              label={"Сортування:"}
              value={sortName}
              options={sortOtions}
              onSet={sortClick}
            />
          </div>
          </div>
          
        </div>

        <div className="pageContentHeaderDivider" />
        <div className="dictAllRowsContainer">
          {(items &&
            items.length > 0 )?
            items.map((el, index) => {
              return (
                <div key={index} className="catVideosRowNormal" 
                onClick={() => navigate(`/eventsItem/${el.id}`)}
                >
                  <div className="catVideoValue">
                    <div
                      style={{ backgroundImage: `url("${el.imageUrl}")` }}
                      className="myObjectValueImage"
                    />
                  </div>
                  <div
                    className="catVideoValue"
                    onClick={() => navigate(`/eventsItem/${el.id}`)}
                  >
                    {el.title}
                    <br />
                    {el.shortDescription}
                  </div>
                  <div className="catVideoValue">{el.sportsground?.title}</div>
                  <div className="catVideoValue">
                    {getDate(el.startsAt,'dd/mm/yyyy hh:mm','.')} <br />
                    {getDate(el.finishesAt,'dd/mm/yyyy hh:mm','.')}
                  </div>
                  <div className="catVideoValue">
                    {el.statusId === "032fd5ba-f634-469b-3c30-77a1gh63a918" && (
                      <div className="apPublished">Опубліковано</div>
                    )}
                    {/* {el.statusId ==='f7b77851-1d35-4ab1-9fcd-ef6cd2b88ace'&&<div className="apDeactivated">Деактивовано</div>} */}
                    {el.statusId === "99ca4f35-623c-4e0d-b9ef-f70gs23481f3" && (
                      <div className="apCandidate">Кандидат</div>
                    )}
                    {el.statusId === "35rt3s0c-7bsa-46c7-a203-c16a4fsc3004" && (
                      <div className="apOnRepair">Чернетка</div>
                    )}
                    {el.statusId === "d3db15d3-3207-4e1d-b87d-f2ec25aeba23" && (
                      <div className="apDeactivated">Деактивований</div>
                    )}
                  </div>
                  <div className="catVideoValue">
                    {getDate(el.updatedAt,'dd/mm/yyyy hh:mm','.')}
                    <span style={{ display: "block" }}>Змінено</span>
                  </div>
                </div>
              );
            }):
            <div className="apEventsContainer fallback">Жодного створеного заходу</div>
          }
        </div>
        {events.total > 10 && (
          <Pagination
            className="pagination-bar"
            currentPage={currentPage}
            totalCount={events.total.toFixed(0)}
            pageSize="10"
            onPageChange={(page) => {
              setCurrentPage(page);
              getEventsList(page);
            }}
          />
        )}
      </>
    );
  }
};

export default SportEventsListPage;
