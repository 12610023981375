import React from "react";

const PhotoItem =(props)=>{

return(
    <>
        <div className="imageItemGallery" style={{
            backgroundImage: `linear-gradient(rgba(68, 79, 96, 0.1), rgba(68, 79, 96, 0.1)), url('${props.el}')`,
        }}>
        </div>
    </>
)

}


export default PhotoItem  



