import {
    GETFCCREATOR_SUCCESS,
    GETFCCREATOR_FAIL,
} from "../actions/types";

const initialState = [];

export default function fcReducer(FClist = initialState,action){
    const {list,type} = action;

    switch(type) {
        case GETFCCREATOR_SUCCESS:
            return {...list};
        case GETFCCREATOR_FAIL:
            return FClist;
        default:
            return FClist;
    }

}