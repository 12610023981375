import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Icon as ButtonWithIcon } from "../../components/Icon";
import { FcTile } from "../../components/Tile/FcTile";
import { Search } from "./components/Search";

import {
  addFc,
  getFcCreator,
  getFcDeacticvate,
  getFcList,
  getFcModerator,
} from "../../redux/actions/fc";
import { useNavigate } from "react-router";
import Loader from "../../components/Loader/Loader";
import Pagination from "../../components/Paggination/Paggination";

export const UserFc = () => {
  const { user } = useSelector((state) => state.auth);
  const { currentUser } = useSelector((state) => state.currentUser);
  const { fcCreator, fc } = useSelector((state) => state);
  const [intervalId,setIntervalId] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentSecondPage, setCurrentSecondPage] = useState(1);  
  const [currentPageAll, setCurrentPageAll] = useState(1);
  const [filterAll, setFilterAll] = useState('');
  const [isReadyALL, setIsReadyALL] = useState(false);  
  const [searchList, setSearchList] = useState(false);
  const [created, setCreated] = useState(true);
  const [deactivate, setDeactivate] = useState({});

  
  const [membered, setMembered] = useState(true);
  const [isReady, setIsReady] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const initialFunction = () => {
    setIsReady(true);
    if (currentUser?.roleId === "d379ecaa-fee7-48a4-84df-a176f47820e6") {
      getCoordinatorList();
      getFcFullList();
      getDeactivateList();
    } else {
      getFcCreatorList();
      
    }
    // getFcParticipantList();
    // getFcFullList();
  };

  const createClick = () => {
    dispatch(addFc()).then((res) => {
      navigate("/fcEdit/" + res.id);
    });
  };
  const getCoordinatorList = (page=1,filter) => {
    let data = {};
    if (filter) {
      data.filter = { ...filter };
    }
    data.offset = (page - 1) * 10;
    data.limit = 10;
    dispatch(getFcModerator(data)).then(() => setIsReady(true));
  };

  const getDeactivateList = (page=1,filter)=>{
    let data = {};
    if (filter) {
      data.filter = { ...filter };
    }
    data.offset = (page - 1) * 10;
    data.limit = 10;
    dispatch(getFcDeacticvate(data)).then((res) =>{
      setDeactivate(res);
      setIsReady(true)
    })

  }

  const getFcCreatorList = (page = 1, filter) => {
    let data = { offset: (page - 1) * 100, limit: 100 };
    if (filter) {
      data.filter = { ...filter };
    }else if(filterAll){
      data.filter.all=[];
      data.filter.all=[filterAll];
    }
    data.id = currentUser.id;
    dispatch(getFcCreator(data)).then(() =>{ 
        setIsReady(true);
    });
  };



  const getFcFullList = (page = 1, filter) => {
    setIsReadyALL(false);
    let data = { offset: (page - 1) * 100, limit: 100 };
    if (filter) {
      data.filter = { ...filter };
    }else if(filterAll){
      data.filter.all=[];
      data.filter.all=[filterAll];
    }
    dispatch(getFcList(data)).then((res) => {
      setIsReadyALL(true);
      setIsReady(true);
    });
  };


  const handleSearch = (e) => {
    setCreated(false);
    setMembered(false);
    setSearchList(true);
    let filter = {};
    filter.all = [];
    clearTimeout(intervalId);
    const newIntervalId = setTimeout(() =>{
      if (e.target.value && e.target.value.length > 2) {
        filter.all[0] = e.target.value;
        setFilterAll(e.target.value);
        if (currentUser?.roleId === "d379ecaa-fee7-48a4-84df-a176f47820e6") {
          getCoordinatorList(1,filter);
          getDeactivateList(1,filter);
          getFcFullList(1,filter);
        } else {
          getFcCreatorList(1, filter);
        }
      } else {
        setFilterAll('');
        delete filter.all;
        if (currentUser?.roleId === "d379ecaa-fee7-48a4-84df-a176f47820e6") {
          getCoordinatorList(1,filter);
          getFcFullList(1,filter)
        } else {
          getFcCreatorList(1, filter);
        }
      }
    }, 1000)
    setIntervalId(newIntervalId);
  };

  useEffect(() => {
    if (!user) {
      navigate("/login");
    }
    if (!isReady) {
      initialFunction();
    }
  }, [isReady]);

  if (isReady && fcCreator.items && currentUser) {
    return (
      <div className="apUserFcPage">
        <div className="headline">
          <h1 style={{width:'-webkit-fill-available'}}>Мої клуби</h1>
          <div className="flex-container">
            {(currentUser.roleId==='631db81f-fa07-42e2-b394-062c9be66b09'||currentUser.roleId==='09efbeb2-f45a-418d-89b0-b2a4c37f6122')&& <ButtonWithIcon
              type={"plus"}
              onClick={createClick}
              className={"primaryButtonIconTextDefault"}
            >
              Створити клуб
            </ButtonWithIcon>}
            <Search
              // onClick={handleSearch}
              onChangeComponent={handleSearch}
              onClose={ () =>
              {
                if (currentUser?.roleId === "d379ecaa-fee7-48a4-84df-a176f47820e6") {
                  getCoordinatorList(1,{});
                  getDeactivateList(1,{});
                  getFcFullList(1,{});
                } else {
                  getFcCreatorList(1, {});
                }
              }
            }

              

            />
          </div>
        </div>

        {currentUser.roleId === "d379ecaa-fee7-48a4-84df-a176f47820e6" && (
          <div className="apFcItemWrapper">
            <h2>Потребує модерування</h2>
            {fcCreator?.items?.length > 0 ? (
              <>
                {fcCreator?.items.map((el, index) => {
                  return (
                    <FcTile
                      key={index}
                      id={el.id}
                      logo={
                        el.logoUrl ??
                        "https://ap.sportforall.gov.ua/api/v1/uploads/b34604xc-02qb-46bb-9e91-fc3ffrb92w41"
                      }
                      title={el.name}
                      slogan={el.shortDescription}
                      status={el.statusId}
                      address={el.address}
                      place={el.place}
                      members={el.memberAmount}
                      eventDate={
                        el.soonestEvent === null ? "Невідомо" : el.soonestEvent
                      }
                      contact={el.phone}
                      info={el.info}
                    />
                  );
                })}

                {fcCreator?.total > 10 && (
                          <Pagination
                            className="pagination-bar"
                            currentPage={currentPage}
                            totalCount={fcCreator.total.toFixed(0)}
                            pageSize="10"
                            onPageChange={async (page) => {
                              await setCurrentPage(page);
                              await getCoordinatorList(page);
                            }}
                          />
                )}

              </>
            ) : (
              <div className="apEmptyCategory">Немає клубів</div>
              )}

            {currentUser.roleId === "d379ecaa-fee7-48a4-84df-a176f47820e6" && 
            <>
               <h2>Деативовані клуби</h2>

               {deactivate?.items?.map((el, index) => {
                  return (
                    <FcTile
                      key={index}
                      id={el.id}
                      logo={
                        el.logoUrl ??
                        "https://ap.sportforall.gov.ua/api/v1/uploads/b34604xc-02qb-46bb-9e91-fc3ffrb92w41"
                      }
                      title={el.name}
                      slogan={el.shortDescription}
                      status={el.statusId}
                      address={el.address}
                      place={el.place}
                      members={el.memberAmount}
                      eventDate={
                        el.soonestEvent === null ? "Невідомо" : el.soonestEvent
                      }
                      contact={el.phone}
                      info={el.info}
                    />
                  );
                })}

                      {deactivate?.total > 10 && (
                          <Pagination
                            className="pagination-bar"
                            currentPage={currentSecondPage}
                            totalCount={deactivate.total.toFixed(0)}
                            pageSize="10"
                            onPageChange={async (page) => {
                              await setCurrentSecondPage(page);
                              await getDeactivateList(page);
                            }}
                          />
                )}
            </>

            }
            <h2>Пошук  {isReadyALL? <span style={{fontSize:'14px', fontWeight:'400'}}>(Всього: {fc.total}, Опубліковано: {fc.clubPublished})</span>:''}</h2> 
            {isReadyALL? 
           <>
            {fc.items?.length > 0 ? (
              <>
                {fc.items?.map((el, index) => {
                  return (
                    <FcTile
                      key={index}
                      id={el.id}
                      logo={
                        el.logoUrl ??
                        "https://ap.sportforall.gov.ua/api/v1/uploads/b34604xc-02qb-46bb-9e91-fc3ffrb92w41"
                      }
                      title={el.name}
                      slogan={el.shortDescription}
                      status={el.statusId}
                      address={el.address}
                      place={el.place}
                      members={el.memberAmount}
                      eventDate={
                        el.soonestEvent !== null ? el.soonestEvent : "Невідомо"
                      }
                      contact={el.phone}
                      info={el.info}
                    />
                  );
                })}
                {fc.total > 100 && (
                          <Pagination
                            className="pagination-bar"
                            currentPage={currentPageAll}
                            totalCount={fc.total.toFixed(0)}
                            pageSize="100"
                            onPageChange={async (page) => {
                              await setCurrentPageAll(page);
                              await getFcFullList(page);
                            }}
                          />
                )}
              </>
            ) : (
              <div className="apEmptyCategory">Немає клубів</div>
            )}
            </>:<Loader></Loader>}
          </div>
          
        )}

        {currentUser.roleId !== "d379ecaa-fee7-48a4-84df-a176f47820e6" && (
          <div className="apFcItemWrapper">
            <h2>
              {currentUser.roleId === "d379ecaa-fee7-48a4-84df-a176f47820e6"
                ? "Потребує модерування"
                : "Ви власник"}
            </h2>
            {fcCreator?.items?.userIsHead?.length > 0 ? (
              <>
                {fcCreator?.items?.userIsHead.map((el, index) => {
                  return (
                    <FcTile
                      key={index}
                      id={el.id}
                      logo={
                        el.logoUrl ??
                        "https://ap.sportforall.gov.ua/api/v1/uploads/b34604xc-02qb-46bb-9e91-fc3ffrb92w41"
                      }
                      title={el.name}
                      slogan={el.shortDescription}
                      status={el.statusId}
                      address={el.address}
                      place={el.place}
                      members={el.memberAmount}
                      eventDate={
                        el.soonestEvent === null ? "Невідомо" : el.soonestEvent
                      }
                      contact={el.phone}
                      info={el.info}
                    />
                  );
                })}
              </>
            ) : (
              <div className="apEmptyCategory">Немає клубів</div>
            )}
            <h2>Ви учасник</h2>
            {currentUser.roleId !== "d379ecaa-fee7-48a4-84df-a176f47820e6" &&
            fcCreator?.items?.userIsMember?.length > 0 ? (
              <>
                {fcCreator?.items?.userIsMember.map((el, index) => {
                  return (
                    <FcTile
                      key={index}
                      id={el.id}
                      logo={
                        el.logoUrl ??
                        "https://ap.sportforall.gov.ua/api/v1/uploads/b34604xc-02qb-46bb-9e91-fc3ffrb92w41"
                      }
                      title={el.name}
                      slogan={el.shortDescription}
                      status={el.statusId}
                      address={el.address}
                      place={el.place}
                      members={el.memberAmount}
                      eventDate={
                        el.soonestEvent == null ? "Невідомо" : el.soonestEvent
                      }
                      contact={el.phone}
                      info={el.info}
                    />
                  );
                })}
              </>
            ) : (
              <div className="apEmptyCategory">Немає клубів</div>
            )}

            <h2>Пошук</h2>
            {fcCreator?.items?.published?.length > 0 ? (
              <>
                {fcCreator?.items?.published.map((el, index) => {
                  return (
                    <FcTile
                      key={index}
                      id={el.id}
                      logo={
                        el.logoUrl ??
                        "https://ap.sportforall.gov.ua/api/v1/uploads/b34604xc-02qb-46bb-9e91-fc3ffrb92w41"
                      }
                      title={el.name}
                      slogan={el.shortDescription}
                      status={el.statusId}
                      address={el.address}
                      place={el.place}
                      members={el.memberAmount}
                      eventDate={
                        el.soonestEvent !== null ? el.soonestEvent : "Невідомо"
                      }
                      contact={el.phone}
                      info={el.info}
                    />
                  );
                })}
              </>
            ) : (
              <div className="apEmptyCategory">Немає клубів</div>
            )}
          </div>
        )}
      </div>
    );
  } else {
    return <Loader></Loader>;
  }
};
