import { useMemo, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { useParams } from "react-router";
import { Icon } from "../../../components/Icon";
import { Status } from "../../../components/Status";
import Conference from "./Conference/Conference";
import MapHeader from "./Map/MapHeader";

export const EventHeader = (props) => {
  const { auth } = useSelector((state) => state);
  const navigate = useNavigate();
  const clubid = useParams().clubid;
  const eventid = useParams().id;
  const currentTab = useParams().contentId;
  const { conf } = useSelector((state) => state);
  let confFirstRecordLink;

  useEffect(() => {
    if (conf && conf.items && conf.items[0] && conf.items[0].url) {
      confFirstRecordLink = conf.items[0].url;
    }
  }, [conf]);

  const getPic = useMemo(() => {
    if (!props.img || props.img === null)
      return "https://ap.sportforall.gov.ua/api/v1/uploads/b34604xc-02qb-46bb-9e91-fc3ffrb92w41";
    return props.img;
  }, [props]);
  const clickEvent=(e)=>{
    if (props.status==='0q8a6xc0-1nb4-1pr4-h5at-4sw3m0l387yp') {
      // Для завершённого события кнопка ведёт на таб "просмотр" и опционально сразу включает, если запись всего одна
      if (currentTab!=="itinerary") navigate("/fc-events/"+clubid+"/"+eventid+"/itinerary");
      if (props.meetingRecordsCount===1) {
        if (confFirstRecordLink) window.open(confFirstRecordLink);
        // Д: Здесь надо дождаться отработки предыдущей строки, чтобы ссылка точно уже была. Но вообще-то и так хорошо. Первый клик - переход на таб, второй клик - включение просмотра. Меньше непоняток и мелькания.
      } else {
        if (props.meetingRecordsCount>1) {
          // Если список записей более чем из одного элемента, страница скролится к списку, чтобы юзер выбрал, какую запись смотреть. Автовоспроизведение не включается.
          const element = document.getElementById("recordslist");
          if (element) {
            element.scrollIntoView();
          }
        }
      }
    } else {
      // Для идущего события кнопка ведёт в комнату конференции
      window.open(props.link)
    }
  }

  const getStatusTitle = useMemo(() => {
    const status = props.status;
    const isCoordinator = props.userStatus?.isCoordinator;
    const isLeading = props.userStatus?.isLeading;
    const isApproved = props.userStatus?.isApproved;
    if (status === "6h8ad9c0-fd34-8kr4-h8ft-43vdm3n7do3p" && !auth.isLoggedIn) {
      return {
        status: "Захід ще не почався",
        button: "Авторизуватися",
        event: () => navigate("/login"),
      };
    } else if (
      status === "6h8ad9c0-fd34-8kr4-h8ft-43vdm3n7do3p" &&
      (!isApproved || isLeading || isCoordinator)
    ) {
      return {
        status: "Захід ще не почався",
        button: "Подати заявку на участь",
      };
    } else if (
      status === "tg2po97g-96r3-36hr-74ty-6tfgj1p8dzxq" &&
      (isApproved || isLeading || isCoordinator)
    ) {
      return {
        status: "Захід відбувається зараз",
        button: "Перейти до маршруту",
      };
    } else if (
      status === "0q8a6xc0-1nb4-1pr4-h5at-4sw3m0l387yp" &&
      (isApproved || isLeading || isCoordinator)
    ) {
      return {
        status: "Захід завершено",
        button: "Подивитись результати",
      };
    } else if (
      status === "0q8a6xc0-1nb4-1pr4-h5at-4sw3m0l387yp" &&
      (!isApproved && !isLeading && !isCoordinator)
    ) {
      // Д: Добавил ещё такое условие, иначе если событие завершилось, но юзер никто, ему срабатывает последнее условие - "ещё не началось"
      return {
        status: "Захід завершено",
        button: "",
      };
    } else if (status === "cancelled") {
      return {
        status: "Захід відмінено",
        button: "Захід відмінено",
      };
    } else {
      return {
        status: "Захід ще не почався",
        button: "Подати заявку на участь",
        isShow: false,
      };
    }
  }, [props.status]);


  return (
    <div className="apFcEventHeader">
      <div className="img-wrp apFcEventHeaderImage">
        <img
          src={getPic}
          alt={`${props.title}`}
          onError={(e) => {
            e.target.onerror = null;
            e.target.src =
              props.onError ||
              "https://ap.sportforall.gov.ua/api/v1/uploads/b34604xc-02qb-46bb-9e91-fc3ffrb92w41";
          }}
        />
      </div>
      <div className="main-description">
        <div className="top">
          <div className="headline">
            {props.fcId?.length>0 && <a href={"/fc/"+props.fcId+"/about"} className="fcTitle">
              {props.fcName}
            </a>}
            <div>
              <Status type={"eventHolding"} statusId={props.status} />
            </div>

            {/* <span className={`status ${props.status}`}>

              {getStatusTitle.status}
            </span> */}
          </div>
          <h3>{props.title}</h3>
          {props.date && (
            <div className="date">
              <Icon type={"calendar"} />
              {props.date}
            </div>
          )}
          {(props.address || props.marker?.location?.length>0) && (
            <div className="address" style={{fontWeight:'700',cursor:'pointer'}} onClick={()=>{window.open(`https://www.google.com.ua/maps/place/${props.marker?.location[0]}+${props.marker?.location[1]}`)}}>
              <Icon type={"pin"} />
              {props.address || 'Перейти до місця проведення'}
            </div>
          )}
            {props.memberAmount > 0 && <div className="address fill" style={{fontWeight:'400', marginTop:'8px'}}>
              <Icon type={"users"} />
              {'Учасників: ' + props.memberAmount} {props.maxCountUser > 0 && ' із '+props.maxCountUser}
            </div>}

        </div>

        {!getStatusTitle.isShow ? (
          ""
        ) : (
          <button
            onClick={getStatusTitle.event}
            className={`apFcEventButton ${props.status || ""}`}
          >
            <Icon type={"rocket"} />
            {getStatusTitle.button}
          </button>
        )}
      </div>
      <div className="aside ap">
        {(props.type === "bd09f36f-835c-49e4-88b8-4f835c1602ac" ||
          props.type === "848e3121-4a2b-413d-8a8f-ebdd4ecf2840") && (
          <MapHeader marker={props.marker}></MapHeader>
        )}
        {props.type === "e58e5c86-5ca7-412f-94f0-88effd1a45a8" && (
          <Conference meetingRecordsCount={props.meetingRecordsCount} conferenceLink={props.link} date={props.timeZoneDifference} status={props.status} onClick={clickEvent} />
        )}
      </div>
    </div>
  );
};
