import {
    CREATESUPPORT_SUCCESS,
    CREATESUPPORT_FAIL,
    GETSUPPORT_SUCCESS,
    GETSUPPORT_FAIL,
    GETSUPPORTITEM_SUCCESS,
    GETSUPPORTITEM_FAIL,
    EDITSUPPORT_SUCCESS,
    EDITSUPPORT_FAIL,
    DELETESUPPORT_SUCCESS,
    DELETESUPPORT_FAIL,
} from "./types";

import SupportService from "../services/support.service"


export const getSupport = (data)=> async(dispatch)=>{
        
    try{
        const res = await SupportService.getSupport(data);
        
        dispatch({
          type: GETSUPPORT_SUCCESS,
          list: res,
        });
        
        return res
    }catch (error){
        
        dispatch({
            type: GETSUPPORT_FAIL,
            list: {error: error.response},
          });
          const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
          return error.response
    }
}


export const editSupport= (form)=>(dispatch)=>{
      
    return SupportService.editSupport(form).then(
        (response)=>{
              
            dispatch({
                type:EDITSUPPORT_SUCCESS,
                item:response
            });
            
            return response

        },
        (error)=>{
            dispatch({
                type: EDITSUPPORT_FAIL,
              });
            console.log(error);
              return Promise.reject();
        }
    );
}



export const deleteSupport= (data)=>(dispatch)=>{
      
    return SupportService.deleteSupport(data).then(
        (response)=>{
            dispatch({
                type:DELETESUPPORT_SUCCESS,
            });
              
        },
        (error)=>{
            dispatch({
                type: DELETESUPPORT_FAIL,
              });
            console.log(error);
                 
              return Promise.reject();
        }
    );
}

export const getSupportItem = (data)=>(dispatch)=>{
    return SupportService.getSupportItem(data).then(
        (response)=>{
              
            dispatch({
                type:GETSUPPORTITEM_SUCCESS,
                item:response,
            });
              
            return response

        },
        (error)=>{
            dispatch({
                type: GETSUPPORTITEM_FAIL,
              });
            console.log(error);
                 
              return Promise.reject();
        }
    );

}

export const sendSupportMessage = (data)=>(dispatch)=>{
    return SupportService.sendSupport(data).then(
        (response)=>{
              
            dispatch({
                type:GETSUPPORTITEM_SUCCESS,
                item:response,
            });
              
            return response

        },
        (error)=>{
            dispatch({
                type: GETSUPPORTITEM_FAIL,
              });
            console.log(error);
                 
              return Promise.reject();
        }
    );

}




export const addSupport = (data)=> async(dispatch)=>{
    try {
        const res = await SupportService.addSupport(data);
        dispatch({
          type: CREATESUPPORT_SUCCESS,
          item: res
        });
        return res
    }catch (error){
        dispatch({
            type: CREATESUPPORT_FAIL,
            item: {error: error.response},
          });
          const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
          return error.response;
    }
}