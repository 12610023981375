import axios from "axios";
import authHeader from "./auth-header";

import constants from '../../constants/constant.json';


const getParksCoordinatorList = (data) => {
   
    return axios.get(constants.URL + `api/v1/sportsgrounds/my-sportsgrounds`, {
        headers: authHeader()
    }).then(response => {

        return response.data
    }).catch((e) => {
        console.log(e);
    });
}


const getParks = (data) => {
    if (!data.offset) {
        data.offset = 0;
    }
    if (!data.limit) {
        data.limit = 10
    }
    let filter = ''

    if (data.filter && Object.keys(data.filter).length) {

        for (const [key, value] of Object.entries(data.filter)) {

            value.forEach(el => {

                filter += `&filters[${key}]=${el}`
            });
        }
    }
    if (data.sort && Object.keys(data.sort).length) {
        for (const [key, value] of Object.entries(data.sort)) {
            filter += `&sort[${key}]=${value}`
        }
    }

    return axios.get(constants.URL + `api/v1/sportsgrounds?offset=${data.offset}&limit=${data.limit}${filter}`, {
        headers: authHeader()
    }).then(response => {

        return response.data
    }).catch((e) => {

        console.log(e);
    });;
}




const getParksAll = (data) => {
    let filter = ''

    if(data?.limit >0){
        filter+='limit='+data.limit
    }

    if (data.filter && Object.keys(data.filter).length) {

        for (const [key, value] of Object.entries(data.filter)) {

            value.forEach(el => {

                filter += `&filters[${key}]=${el}`
            });
        }
    }
    if (data.sort && Object.keys(data.sort).length) {
        for (const [key, value] of Object.entries(data.sort)) {
            filter += `&sort[${key}]=${value}`
        }
    }

    return axios.get(constants.URL + `api/v1/sportsgrounds/all?${filter}`, {
        headers: authHeader()
    }).then(response => {

        return response.data
    }).catch((e) => {

        console.log(e);
    });;
}
const getParksRoutesAll = (data) => {
    let filter = ''

    if (data.filter && Object.keys(data.filter).length) {

        for (const [key, value] of Object.entries(data.filter)) {

            value.forEach(el => {

                filter += `&filters[${key}]=${el}`
            });
        }
    }
    if (data.sort && Object.keys(data.sort).length) {
        for (const [key, value] of Object.entries(data.sort)) {
            filter += `&sort[${key}]=${value}`
        }
    }

    return axios.get(constants.URL + `api/v1/sportsgrounds/active-router?${filter}`, {
        headers: authHeader()
    }).then(response => {

        return response.data
    }).catch((e) => {

        console.log(e);
    });;
}




const addPark = (data) => {

    if (data) {
        return axios.post(constants.URL + `api/v1/sportsgrounds/${data.id}/${data.type}`, {}, {
                headers: authHeader()
            })
            .then((response) => {
                return response.data
            })
            .catch((e) => {

                console.log(e);
            });
    } else {
        return axios.post(constants.URL + `api/v1/sportsgrounds`, {}, {
                headers: authHeader()
            })
            .then((response) => {
                return response.data
            })
            .catch((e) => {

                console.log(e);
            });
    }

}

const editPark = (data) => {
    return axios.put(constants.URL + 'api/v1/sportsgrounds/' + data.id, {
            ...data
        }, {
            headers: authHeader()
        })
        .then((response) => {
            return response.data
        })
        .catch((e) => {

            console.log(e);
        });
}

const getParkItem = (data) => {
    return axios.get(constants.URL + 'api/v1/sportsgrounds/' + data.id, {
        headers: authHeader()
    }).then(response => {

        return response.data
    }).catch((e) => {
        console.log(e);
    });;
}

const deletePark = (data) => {
    return axios.delete(constants.URL + 'api/v1/sportsgrounds/' + data.id, {
            headers: authHeader()
        })
        .then((response) => {

            return response.data
        })
        .catch((e) => {
            console.log(e);
        });
}

const clonePark = (data) => {
    return axios.post(constants.URL + 'api/v1/sportsgrounds/' + data.id + '/duplicate', data, {
            headers: authHeader()
        })
        .then((response) => {
            return response.data
        })
        .catch((e) => {
            console.log(e);
        });
}

export default {
    getParks,
    addPark,
    editPark,
    getParkItem,
    deletePark,
    clonePark,
    getParksCoordinatorList,
    getParksAll,
    getParksRoutesAll
}