import {
  ROUTEPOINTS_SUCCESS
} from "../actions/types";

  const initialState = [];

  export default function (state = initialState, action) {
    const {type,item} = action;

    switch (type) {
      case ROUTEPOINTS_SUCCESS:
        return { ...item };  
      default:
        return state;
    }
  }
  