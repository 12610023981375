
function setPhoneMask(initialValue = "") {

  const phoneRegex = /^\+?([0-9]{1,2})?\s*([(]?[0-9]{3}[)]?)?\s*([0-9]{3})?\s*-?\s*([0-9]{2})?\s*-?\s*([0-9]{2})?$/;
  const digitsOnly = initialValue?.replace(/\D/g, "");
    let res='';

    if (phoneRegex.test(digitsOnly)) {
      res=digitsOnly.replace(phoneRegex, "+$1 ($2) $3-$4-$5");
    } else {
      res= digitsOnly;
    }

  return res;
}
export default setPhoneMask;