import React, { useState, useEffect } from "react";

function MonthSelector(props) {
  const [selectedMonth, setSelectedMonth] = useState("");
  const [months, setMonths] = useState([]);

  const monthNamesUkrainian = [
    "Січень",
    "Лютий",
    "Березень",
    "Квітень",
    "Травень",
    "Червень",
    "Липень",
    "Серпень",
    "Вересень",
    "Жовтень",
    "Листопад",
    "Грудень",
  ];

  const handleMonthChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedMonth(selectedValue);
    const [year, month] = selectedValue.split("-");
    const firstDate = `${year}-${month}-01`;
    const lastDate = new Date(year, month, 0).getDate();
    const lastDay = lastDate < 10 ? `0${lastDate}` : lastDate;
    const lastMonth = month < 10 ? `${month}` : month;
    const lastDateFormatted = `${year}-${lastMonth}-${lastDay}`;
    props.getStatistics(`${firstDate}/${lastDateFormatted}`);
  };

  useEffect(() => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth() + 1;

    const prevMonthDate = new Date(currentDate);
    prevMonthDate.setMonth(currentMonth - 2); // Subtracting 2 to get the previous month

    const currentMonthLabel = `${currentYear}-${currentMonth.toString().padStart(2, "0")}`;
    const prevMonthLabel = `${prevMonthDate.getFullYear()}-${(prevMonthDate.getMonth() + 1).toString().padStart(2, "0")}`;

    const monthsArray = [
      {
        label: "Поточний місяць",
        value: currentMonthLabel,
      },
      {
        label: "Попередній місяць",
        value: prevMonthLabel,
      }
    ];

    // Dynamically add months up to the previous month
    for (let i = currentMonth - 2; i >= 1; i--) {
      monthsArray.push({
        label: monthNamesUkrainian[i - 1],
        value: `${currentYear}-${i.toString().padStart(2, "0")}`,
      });
    }

    setMonths(monthsArray);
  }, []);

  return (
    <div>
      <select
        id="monthSelector"
        className="statisticSelector"
        onChange={handleMonthChange}
        value={selectedMonth}
      >
        { props.compare && <option>Обрати місяць</option>}

        {months.map((month, index) => (
          <option key={index} value={month.value}>
            {month.label}
          </option>
        ))}
      </select>
    </div>
  );
}

export default MonthSelector;
