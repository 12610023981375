import {
    GETUSERSHEAD_SUCCESS,
    GETUSERSHEAD_FAIL,
} from "../actions/types";

const initialState = [];

export default function fcUserReducer(FCUserlist = initialState,action){
    const {list,type} = action;

    switch(type) {
        case GETUSERSHEAD_SUCCESS:
            return {...list};
        case GETUSERSHEAD_FAIL:
            return FCUserlist;
        default:
            return FCUserlist;
    }

}