import {
    GETVIDEO_SUCCESS,
    GETVIDEO_FAIL,
    EDITVIDEO_SUCCESS,
    EDITVIDEO_FAIL,
    DELETEVIDEO_SUCCESS,
    DELETEVIDEO_FAIL,
    GETVIDEOITEM_SUCCESS,
    GETVIDEOITEM_FAIL,
    CREATEVIDEO_SUCCESS,
    CREATEVIDEO_FAIL
} from "../actions/types";

const initialState = [];

export default function videoReducer(video = initialState,action){
    const {list,item,type} = action;

    switch(type) {
        case  CREATEVIDEO_SUCCESS:
            return {currentPark:item}
        case CREATEVIDEO_FAIL:
            return video;
        case GETVIDEO_SUCCESS:
            return {...list};
        case GETVIDEO_FAIL:
            return video;
        case GETVIDEOITEM_SUCCESS:
            return {...item};
        case GETVIDEOITEM_FAIL:
            return video;
        case EDITVIDEO_SUCCESS:
            return video;
        case EDITVIDEO_FAIL:
            return video;
        case DELETEVIDEO_SUCCESS:
            return video;
        case DELETEVIDEO_FAIL:
            return video;
        default:
            return video;
    }

}