import {
  useMemo
} from "react";

// data: any[]
// filterById: {id: string}

const useFilterArray = (data, filterById) => {
  const arr = data.items;

  const filteredData = useMemo(() => {
    if (!arr) return [];
    if (filterById) return arr.filter((obj) => obj.id !== filterById.id) || [];
    return arr;
  }, [arr, filterById]);

  return {
    filteredData
  };
};
export default useFilterArray;