import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import Loader from "../../components/Loader/Loader";
import { addPark, editPark, getParks } from "../../redux/actions/parks";
import Pagination from "../../components/Paggination/Paggination";
import Filter from "./Filter/Filter";
import Select from "../../components/Select/";
import getDate from "../../helpers/getDate";

const sortOtions = [
  { id: "updatedAt", value: "За датою редагування" },
  { id: "title", value: "За абеткою" },
  { id: "code", value: "За кодом" },
  { id: "rating", value: "За рейтингом" },
  { id: "messagesCount", value: "За кількістю повідомлень" },
  { id: "coordinatorsCount", value: "За кількістю координаторів" },
  { id: "statusId", value: "За статусом" },
];

const ParkListPage = () => {
  const navigate = useNavigate();
  const [ready, setReady] = useState(false);
  const [searchOpen, setSearchOpen] = useState(false);
  const [selected, setSelected] = useState("");
  const dispatch = useDispatch();
  const [searchValue, setSearchValue] = useState("");
  const [intervalId,setIntervalId] = useState(null);
  const [sortData, setSortData] = useState("updatedAt");
  const [sortName, setSortName] = useState("За датою редагування");
  const [sortValue, setSortValue] = useState("desc");
  const [currentPage, setCurrentPage] = useState(1);
  const { parks } = useSelector((state) => state);
  const [filter, setFilter] = useState(false);
  const [filterArr, setFilterArr] = useState({});
  const { listId } = useSelector((state) => state);
  const { currentUser } = useSelector((state) => state.currentUser);
  const { fullList } = useSelector((state) => state);
  

  const selectClick = (e) => {
    if (e.target.id == selected) {
      setSelected("");
    } else {
      setSelected(e.target.id);
    }
  };

  const redirectClick = (e) => {
    e.preventDefault();
    navigate(`/sportsground/${e.target.id}/info`);
  };

  const getList = (page = 1, filter) => {
    setReady(false);
    let data = { offset: (page - 1) * 10, limit: 10 };
    if (filter) {
      data.filter = { ...filter };
    } else {
      if (localStorage.getItem("filter")) {
       
        data.filter = { ...JSON.parse(localStorage.getItem("filter")) };
        
      } else {
        data.filter = {};
      }
    }
    if (localStorage.getItem("sort")) {
      data.sort = { ...JSON.parse(localStorage.getItem("sort")) };
    } else {
      data.sort = { updatedAt: "asc" };
    }
    
    dispatch(getParks(data))
      .then((res) => {
        setReady(true);
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const getFile = () => {
    window.open("https://media.sportforall.gov.ua/media/requirements.docx");
  };

  const sortClick = (e) => {
    setSortName(e.target.innerHTML);
    const currentIdIndex = sortOtions.findIndex(
      (x) => x.value === e.target.innerHTML
    );
    setSortData(sortOtions[currentIdIndex].id);
    let data = {};
    if (sortData === sortOtions[currentIdIndex].id && sortValue === "asc") {
      setSortValue("desc");
      data[sortOtions[currentIdIndex].id] = "desc";
    } else {
      data[sortOtions[currentIdIndex].id] = "asc";
      setSortValue("asc");
    }
    localStorage.setItem("sort", JSON.stringify(data));
    getList();
    setSelected("");
  };

  // const searchChangeClick =(val)=>{
  //   let filter = {};
  //   if (localStorage.getItem("filter")) {
  //     filter = JSON.parse(localStorage.getItem("filter"));
  //   }
  //   if (searchValue.length > 2) {
  //     filter.all=[];
  //     filter.all[0] = searchValue;
  //     localStorage.setItem("filter", JSON.stringify(filter));
  //     getList(1, filter);
  //   }else {
  //      if(val || filter?.all?.length>0){
  //       delete filter.all;
  //       localStorage.setItem("filter", JSON.stringify(filter));
  //       getList(1, filter);
  //      }
  //      else{
  //       getList(1, filter);
  //      }
  //   }
  // }


  const searchChange = (e) => {
    let filter = {};
    setSearchValue(e.target.value);
    if (localStorage.getItem("filter")) {
      filter = JSON.parse(localStorage.getItem("filter"));
    }
    filter.all = [];
    clearTimeout(intervalId);
    const newIntervalId = setTimeout(() =>{
      if (e.target.value.length > 2) {
        filter.all[0] = e.target.value;
        localStorage.setItem("filter", JSON.stringify(filter));
        getList(1, filter);
      } else {
        localStorage.setItem("filter", JSON.stringify(filter));

        getList(1, filter);
      }
    }, 1000)
    setIntervalId(newIntervalId);  
  };
  const createPark = (e) => {
    setReady(false);
    e.preventDefault();

    dispatch(addPark())
      .then((res) => {
        navigate(`/sportsground/${res.id}/info`);
        setReady(true);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const resetFilter = () => {
    localStorage.removeItem("filter");
    setFilterArr({});
    getList();
  };
  const selectFilter = (e) => {
    let data = { ...filterArr };
    data["statusId"] = [];
    if(e.target.id!=='all'){
      data["statusId"].push(e.target.id);
    }else{
      delete data.statusId;
    }
    localStorage.setItem("filter", JSON.stringify(data));
    setFilterArr(data);
    setSelected("");
    getList(1, data);
  };

  useEffect(() => {
    if (!ready) {
      if (localStorage.getItem("filter")) {
        let filter = JSON.parse(localStorage.getItem("filter"));
        if(filter?.all?.length>0){
          setSearchValue(filter.all[0])
        }
        getList(1, filter);
        setFilterArr(filter);
      } else {
        getList();
      }
    }
  }, []);
    return (
      <>
        {filter && (
          <Filter
            setModal={setFilter}
            setFilter={setFilterArr}
            getList={getList}
          ></Filter>
        )}
        <div className="headerWithButtonsCommonBlock">
          <div className="headerWithButtonsHeaderBlock">
            <h1>Мої об'єкти</h1>
            {/* <button onClick={createParksArray}>Click</button> */}
          </div>
          <div className="headerWithButtonsButtonBlock">
          {searchOpen && (
              <div style={{ marginBottom: "4px" }} className="apInputText">
                <label htmlFor="search">Пошук</label>
                <input
                  id="search"
                  type="text"
                  placeholder="Мінімум 3 символи"
                  value={searchValue}
                  onChange={searchChange}
                />
              </div>
            )}
            <button
              className="secondaryButtonIconDefault"
              onClick={() => setSearchOpen(!searchOpen)}
            >
              <svg>
                <use href="/images/icon24Search.svg#icon24" />
              </svg>
            </button>
            {/* <button
              className="secondaryButtonIconDefault"
              onClick={() => setSearchOpen(!searchOpen)}
            >
              <svg>
                <use href="/images/icon24Search.svg#icon24" />
              </svg>
            </button> */}
            <div className="apSwitch">
              <button className="secondaryButtonIconActive">
                <svg>
                  <use href="/images/icon24List.svg#icon24" />
                </svg>
              </button>
              <button
                className="secondaryButtonIconDefault"
                onClick={() => {
                  navigate("/parkmappage");
                }}
              >
                <svg>
                  <use href="/images/icon24Map.svg#icon24" />
                </svg>
              </button>
            </div>
            {/* <button className="secondaryButtonIconTextDefault">
              <svg><use href="/images/icon24Report.svg#icon24" /></svg>
              Звіт
            </button> */}
          </div>
        </div>
        <div className="menuFunctionLine">
          <div className="actionButtonsBlock apMapPage">
           {currentUser.roleId!=='631db81f-fa07-42e2-b394-062c9be66b09' && <button
              className="primaryButtonIconTextDefault"
              onClick={createPark}
            >
              <svg>
                <use href="/images/icon24Plus.svg#icon24" />
              </svg>
              Додати майданчик
            </button>}

            <button
              className="secondaryButtonIconTextDefault"
              onClick={getFile}
            >
              <svg>
                <use href="/images/icon24Info.svg#icon24" />
              </svg>
              Технічні вимоги
            </button>

            <div className="apDropDownList apFilled">
              <label htmlFor="dropDown1">Статус об'єктів</label>
              <div id="dropDown1" onClick={selectClick}>
                <input
                  id="dropDown1"
                  readOnly
                  type="text"
                  defaultValue={
                    (filterArr.statusId &&
                      filterArr.statusId[0] &&
                      listId.list[filterArr.statusId[0]]) ||
                    "Статус об'єктів"
                  }
                  value={`${
                    (filterArr.statusId &&
                      filterArr.statusId[0] &&
                      listId.list[filterArr.statusId[0]]) ||
                    "Всі статуси"
                  }`}
                />
                <svg id="dropDown1" onClick={selectClick}>
                  <use
                    onClick={selectClick}
                    id="dropDown1"
                    href="/images/icon24DropDown.svg#icon24"
                  />
                </svg>
              </div>
              {selected == "dropDown1" && (
                <div className="apOptionsList">
                  <div
                        className={
                          filterArr.statusId == 'all' ? "apActive" : ""
                        }
                        onClick={selectFilter}
                        id={'all'}
                      >
                        Всі статуси
                      </div>
                  {fullList.SportsgroundStatuses.map((el, index) => {
                    return (
                      <div
                        key={index}
                        className={
                          filterArr.statusId == el.id ? "apActive" : ""
                        }
                        onClick={selectFilter}
                        id={el.id}
                      >
                        {el.title}
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          </div>
          {/* <div className="actionTriggersBlock">
            <div class="apCheckBoxWithLabel"><input id="nonTrustedGroundSwiper" type="checkbox" className="apCheckbox" defaultChecked />
            <label htmlFor="nonTrustedGroundSwiper">Лише неперевірені об'єкти</label></div>
          </div> */}
        </div>
        <div className="sortAndFilterBlock">
          <div className="menuFunctionLine">
            <div className="actionButtonsBlock">
              <div className="filterBlock">
                <button
                  className="secondaryButtonIconTextDefault"
                  onClick={() => setFilter(true)}
                >
                  <svg>
                    <use href="/images/icon24Filter.svg#icon24" />
                  </svg>
                  Фільтр
                </button>
                <span>Знайдено {ready? parks.total : '...'} об’єктів</span>
                {filterArr &&
                  Object.keys(filterArr)?.length > 0 &&
                  Object.keys(filterArr)?.map((el) => {
                    if(el==='all'){
                      return;
                    }
                    return (
                      filterArr[el]?.length > 0 &&
                      filterArr[el]?.map((elem, index) => {
                        return (
                          <div key={index} className="apChips">
                            {listId.list[elem]}
                            {/* <svg><use href="/images/icon24CancelSmall.svg#icon24" /></svg> */}
                          </div>
                        );
                      })
                    );
                  })}
                {filterArr && Object.keys(filterArr).length > 0 && (
                  <button
                    onClick={resetFilter}
                    className="secondarySmallRoundedButtonTextDefault"
                  >
                    Скинути
                  </button>
                )}
              </div>
            </div>
          </div>

          <div className="sortBlock">
            <Select
              label={"Сортування:"}
              value={sortName}
              options={sortOtions}
              onSet={sortClick}
            />
          </div>
        </div>
        <div className="pageContentHeaderDivider">&nbsp;</div>
        <div className="dictAllRowsContainer">
          { (!ready || !listId.list || !parks.items) && 
            <div style={{ position: "relative" }}>
              <div style={{ position: "absolute", top: "50%", left: "50%" }}>
                <Loader />
              </div>
            </div>
          }
          {ready && parks && parks?.items?.length>0 &&
            parks.items.map((el, index) => {
              return (
                <div key={index} className="myObjectsRowNormal">
                  <div className="myObjectValue">
                    <div
                      style={{
                        backgroundImage: `URL(${
                         el.photo || (el.photos.length>0 &&  el.photos[0]) || "https://ap.sportforall.gov.ua/api/v1/uploads/b34604xc-02qb-46bb-9e91-fc3ffrb92w41"
                        })`,
                      }}
                      className="myObjectValueImage"
                    />
                  </div>
                  <div
                    className="myObjectValue"
                    id={el.id}
                    onClick={redirectClick}
                  >
                    <a
                      style={{ color: "#444F60" }}
                      id={el.id}
                      onClick={redirectClick}
                    >
                      <span id={el.id} className="myObjectValueName">
                        {el.title.length > 0
                          ? el.title
                          : "Майданчик " + el.code}
                      </span>
                    </a>
                  </div>
                  <div className="myObjectValue">
                    <span className="apBold">{listId.list[el.regionId]}</span>
                    <span className="apBold">{listId.list[el.districtId]}</span>
                    <span className="apBold">{el.city}</span>
                    <span>{el.street}</span>
                  </div>
                  <div className="myObjectValue">
                    {el.statusId === "036735ba-f634-469b-ac30-77a164e3a918" && (
                      <div className="apPublished">Опубліковано</div>
                    )}
                    {el.statusId === "99ca4f35-663c-4e0a-b9ef-f70219aa8163" && (
                      <div className="apDeactivated">Деактивовано</div>
                    )}
                    {el.statusId === "3587410c-7bfa-46c7-a003-c16a408c3004" && (
                      <div className="apCandidate">Кандидат</div>
                    )}
                    {el.statusId === "977f1037-77ca-4e7a-90ef-dc1a974e2ff2" && (
                      <div className="apOnRepair">Чернетка</div>
                    )}
                    {el.statusId === "inactive" && (
                      <div className="apOnRepair">На реконструкції</div>
                    )}
                  </div>
                  <div className="myObjectValue">
                    <span className="myObjectValueNumbers">{getDate(el.updatedAt,'dd/mm/yyyy hh:mm','.')}</span>
                    Змінено
                  </div>
                  <div className="myObjectValue">
                    <span className="myObjectValueNumbers">
                      {el.coordinatorsCount}
                    </span>
                    Координатори
                  </div>
                  <div className="myObjectValue">
                    <span className="myObjectValueNumbers">
                      {el.messagesCount}
                    </span>
                    Повідомлення
                  </div>
                </div>
              );
            })}
        </div>
        {parks.total > 10 && (
          <Pagination
            className="pagination-bar"
            currentPage={currentPage}
            totalCount={parks.total}
            pageSize="10"
            onPageChange={async (page) => {
              await setCurrentPage(page);
              await getList(page);
            }}
          />
        )}
      </>
    );
  
};

export default ParkListPage;
