import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { editPark } from "../../../../redux/actions/parks";

const ModalEquipment = (props) => {
  const dispatch = useDispatch();
  const { fullList } = useSelector((state) => state);
  let arr = { ...props.form };
  const setSelect = (e) => {
    if (arr.fitnessEquipment.includes(e.target.id)) {
      const index = arr.fitnessEquipment.indexOf(e.target.id);
      if (index > -1) {
        arr.fitnessEquipment.splice(index, 1);
      }
    } else {
      arr.fitnessEquipment.push(e.target.id);
    }
    props.setForm(arr);
  };

  const leaveClick = (event) => {
    if (!event.currentTarget.contains(event.relatedTarget)) {
      dispatch(editPark(arr));

      props.setModal(false);
    }
  };
  const resetClick = () => {
    arr.fitnessEquipment = [];
    props.setForm(arr);
  };

  return (
    <div className="apFilterContainer" onBlur={leaveClick} tabindex="0">
      <div className="apFilterHeader">
        <h4>Набір тренажерів</h4>
        <div style={{ display: "flex" }}>
          <button
            style={{ marginRight: "20px" }}
            className="secondarySmallRoundedButtonTextDefault"
            onClick={resetClick}
          >
            Скинути
          </button>
          <button
            className="secondarySmallRoundedButtonTextDefault"
            onClick={leaveClick}
          >
            OK
          </button>
        </div>
      </div>
      <div className="apFilterGroupBlock ">
        <div className="apFilterButtonsBlock">
          {fullList &&
            fullList.FitnessEquipment &&
            fullList.FitnessEquipment.map((el) => {
              if (props.form.fitnessEquipment.includes(el.id)) {
                return (
                  <button
                    id={el.id}
                    key={el.id}
                    className="secondarySmallButtonTextActive"
                    onClick={setSelect}
                  >
                    {el.title}
                  </button>
                );
              } else {
                return (
                  <button
                    id={el.id}
                    key={el.id}
                    className="secondarySmallButtonTextDefault"
                    onClick={setSelect}
                  >
                    {el.title}
                  </button>
                );
              }
            })}
          {/* <button className="secondarySmallButtonTextActive">Ступеньки (выносливость)</button>
                <button className="secondarySmallButtonTextActive">Стенка-лесенка (гибкость)</button>
                <button className="secondarySmallButtonTextActive">Лавочка (тонус мышц)</button>
                <button className="secondarySmallButtonTextActive">Турник (сила)</button>
                <button className="secondarySmallButtonTextDefault">Квадратики на полу (координация)</button>
                <button className="secondarySmallButtonTextActive">Фитнес без тренажёра</button> */}
        </div>
      </div>
    </div>
  );
};

export default ModalEquipment;
