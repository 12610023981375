import {
    GETPARKMAP_SUCCESS,
    GETPARKMAP_FAIL,
} from "../actions/types";

const initialState = [];

export default function parksMapReducer(parks = initialState, action) {
    const {
        list,
        type
    } = action;

    switch (type) {
            case GETPARKMAP_SUCCESS:
                return {
                    ...list
                };
            case GETPARKMAP_FAIL:
            default:
                return parks;
    }

}