import {
    CREATEFCNEWS_SUCCESS,
    CREATEFCNEWS_FAIL,
    GETFCNEWS_SUCCESS,
    GETFCNEWS_FAIL,
    GETFCNEWSITEM_SUCCESS,
    GETFCNEWSITEM_FAIL,
    EDITFCNEWS_SUCCESS,
    EDITFCNEWS_FAIL,
    DELETEFCNEWS_SUCCESS,
    DELETEFCNEWS_FAIL,
    GETFCNEWSCURRENTITEM_SUCCESS,
    GETFCNEWSCURRENTITEM_FAIL
} from "./types";

import FcNewsService from "../services/fc-news.service"


export const getFcNews = (data)=> async(dispatch)=>{
        
    try{
        const res = await FcNewsService.getNews(data);
        
        dispatch({
          type: GETFCNEWS_SUCCESS,
          list: res,
        });
        
        return res
    }catch (error){
        
        dispatch({
            type: GETFCNEWS_FAIL,
            list: {error: error.response},
          });
          const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
          return error.response
    }
}


export const editFcNews= (form)=>(dispatch)=>{
      
    return FcNewsService.editNews(form).then(
        (response)=>{
              
            dispatch({
                type:EDITFCNEWS_SUCCESS,
                item:response
            });
            
            return response

        },
        (error)=>{
            dispatch({
                type: EDITFCNEWS_FAIL,
              });
            console.log(error);
              return Promise.reject();
        }
    );
}



export const deleteFcNews= (data)=>(dispatch)=>{
      
    return FcNewsService.deleteNews(data).then(
        (response)=>{
            dispatch({
                type:DELETEFCNEWS_SUCCESS,
            });
              
        },
        (error)=>{
            dispatch({
                type: DELETEFCNEWS_FAIL,
              });
            console.log(error);
                 
              return Promise.reject();
        }
    );
}

export const getFcNewsItem = (data)=>(dispatch)=>{
    return FcNewsService.getNewsItem(data).then(
        (response)=>{
              
            dispatch({
                type:GETFCNEWSITEM_SUCCESS,
                item:response,
            });
              
            return response

        },
        (error)=>{
            dispatch({
                type: GETFCNEWSITEM_FAIL,
              });
            console.log(error);
                 
              return Promise.reject();
        }
    );

}


export const getFcNewsCurrentItem = (data)=>(dispatch)=>{
    return FcNewsService.getNewsItem(data).then(
        (response)=>{
              
            dispatch({
                type:GETFCNEWSCURRENTITEM_SUCCESS,
                item:response,
            });
              
            return response

        },
        (error)=>{
            dispatch({
                type: GETFCNEWSCURRENTITEM_FAIL,
              });
            console.log(error);
                 
              return Promise.reject();
        }
    );

}


export const addFcNews = (data)=> async(dispatch)=>{
    try {
        const res = await FcNewsService.addNews(data);
        dispatch({
          type: CREATEFCNEWS_SUCCESS,
          item: res
        });
        return res
    }catch (error){
        dispatch({
            type: CREATEFCNEWS_FAIL,
            item: {error: error.response},
          });
          const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
          return error.response;
    }
}