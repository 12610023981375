import { useMemo } from "react";
import { useParams } from "react-router";
import { useSelector } from "react-redux";
import { Icon } from "../../../components/Icon";

export const AdditionalButton = (props) => {
  const { currentUser } = useSelector((state) => state.currentUser);
  const contentId = useParams().contentId;

  const button = useMemo(() => {
    if (!currentUser || !currentUser.roleId) return;
    switch (contentId) {
      // case "events":
      //   switch (currentUser.roleId) {
      //     case "631db81f-fa07-42e2-b394-062c9be66b09": // тут ID координатора(создателя) клуба  - заступник
      //       return {
      //         void: () => alert("events"),
      //         title: "Додати захід",
      //         icon: "plus",
      //       };
      //     default:
      //       return;
      //   }
      // case "users":
      //   switch (currentUser.roleId) {
      //     case "631db81f-fa07-42e2-b394-062c9be66b09": // тут ID координатора(создателя) клуба  - заступник
      //       return {
      //         void: () => alert("users"),
      //         title: "Показати QR",
      //         icon: "qr",
      //         className: "blue",
      //       };
      //     default:
      //       return;
      //   }
      default:
        return;
    }
  }, [contentId, currentUser]);

  return (
    (button && (
      <Button
        onClick={props.additionalClick}
        text={button.title}
        icon={button.icon}
        className={button.className}
      />
    )) ||
    null
  );
};

const Button = ({ onClick, icon, text, className }) => {
  return (
    <button
      className={`additional ${(className && className) || ""}`}
      onClick={onClick}
    >
      <Icon type={icon} />
      {text}
    </button>
  );
};
