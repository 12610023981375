import {
    CREATEPARK_SUCCESS,
    CREATEPARK_FAIL,
    GETPARK_SUCCESS,
    GETPARK_FAIL,
    GETPARKITEM_SUCCESS,
    GETPARKITEM_FAIL,
    EDITPARK_SUCCESS,
    EDITPARK_FAIL,
    DELETEPARK_SUCCESS,
    DELETEPARK_FAIL,
    CLONEPARK_FAIL,
    CLONEPARK_SUCCESS,
} from "../actions/types";

const initialState = [];

export default function parksReducer(parks = initialState, action) {
    const {
        list,
        item,
        type
    } = action;

    switch (type) {
        case CREATEPARK_SUCCESS:
            return {
                currentPark: item
            }

            case GETPARK_SUCCESS:
                return {
                    ...list
                };
            case GETPARKITEM_SUCCESS:
                return {
                    ...item
                };
            case CREATEPARK_FAIL:
            case GETPARK_FAIL:
            case GETPARKITEM_FAIL:
            case EDITPARK_SUCCESS:
            case EDITPARK_FAIL:
            case DELETEPARK_SUCCESS:
            case CLONEPARK_SUCCESS:
            case CLONEPARK_FAIL:
            case DELETEPARK_FAIL:
            default:
                return parks;
    }

}