import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import InfoWindow from "../../components/InfoWindow/InfoWindow";
import Loader from "../../components/Loader/Loader";
import Pagination from "../../components/Paggination/Paggination";
import Select from "../../components/Select";
import getDate from "../../helpers/getDate";
import { getCounter } from "../../redux/actions/counter";
import {
  getReportsUserList
} from "../../redux/actions/reports";

const sortOtions = [
  { id: "events", value: "За кількістю заходів" },
  { id: "workouts", value: "За кількістю тренувань" },
  { id: "name", value: "За абеткою" },
];
const UsersPageReportUser = () => {
  const dispatch = useDispatch();
  const { listId } = useSelector((state) => state);
  const { fullList } = useSelector((state) => state);  
  const navigate =useNavigate();
  const [sortName, setSortName] = useState("За абеткою");
  const [sortData, setSortData] = useState("name");
  const [sortValue, setSortValue] = useState("asc");
  const [ready, setReady] = useState(false);
  const [openEditForm, setOpenEditForm] = useState({});
  const [selected, setSelected] = useState("");
  const [searchOpen, setSearchOpen] = useState(false);
  const [selectedOpen, setSelectedOpen] = useState("");
  const [modal,setModal] =useState(false)
  const [receive,setReceive] =useState(false);
  const { counter } = useSelector((state) => state);
  const [currentPageAll, setCurrentPageAll] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const [intervalId,setIntervalId] = useState(null);

  // const selectClick = (e) => {
  //   if (e.target.id == selected) {
  //     setSelectedOpen("");
  //   } else {
  //     setSelectedOpen(e.target.id);
  //   }
  // };
  
  let date = new Date();
  date.setDate(1);
  date.setMonth(date.getMonth() - 1);
  let finDate = new Date();
  finDate.setDate(0);
  const storedData = localStorage.getItem("data");
  const [searchForm, setSearchForm] = useState(
    (storedData && JSON.parse(storedData))|| {
      filter: {
        startsFrom: [date.toISOString().split("T")[0]],
        startsTo: [finDate.toISOString().split("T")[0]],
      },
      offset: 0,
      limit: 100,
    }
  );
  const { reports } = useSelector((state) => state);

  // const changeRegion = (e) => {
  //   setSelected("");
  //   setOpenEditForm({ ...openEditForm, [e.target.accessKey]: e.target.id });
  // };

  const initFunc = () => {
    // dispatch(getCounter()).then(res=>{
      getDateClick()
    // })
  };
  
  const changeDateHandler = (date) => {
    let specDate = date.currentTarget.value;
    let arr = { ...searchForm };
    arr.filter[date.currentTarget.id] = [];
    arr.filter[date.currentTarget.id].push(specDate);
    setSearchForm(arr);
    localStorage.setItem("data", JSON.stringify(arr));
  };

  const searchChange = (e) => {
    setSearchValue(e.target.value);
    let arr = { ...searchForm };
    arr.filter.all = [];
    clearTimeout(intervalId);
    const newIntervalId = setTimeout(() =>{    
      if (e.target.value.length > 2) {
        arr.filter.all[0] = e.target.value;
        getDateClick(1, arr);
      } else {
        getDateClick(1, arr);
      }
    }, 1000)
    setIntervalId(newIntervalId);
  };



    const selectFilter = (e) => {
    let data = { ...searchForm };
    data.filter["region"] = [];
    if(e.target.id!=='all'){
      data.filter["region"].push(e.target.id);
    }else{
      delete data.filter.region;
    }
    localStorage.setItem("filter", JSON.stringify(data));
    setSearchForm(data);
    localStorage.setItem("data", JSON.stringify(data));
    setSelectedOpen("");
    getDateClick(1, data);
  };



  const getDateClick = (page=1, filter=searchForm) => {
    setReady(false)
    let data = { ...filter };
    data.offset=(page - 1) * 10;
    data.limit=10;
    if (localStorage.getItem("reportsSortUser")) {
      data.sort = { ...JSON.parse(localStorage.getItem("reportsSortUser")) };
    } else {
      data.sort = { name: "asc" };
    }
    
    dispatch(getReportsUserList(data)).then((res) => {
      setReady(true);setReceive(true)

    });
  };

  const sortClick = (e) => {
    setSortName(e.target.innerHTML);
    const currentIdIndex = sortOtions.findIndex(
      (x) => x.value === e.target.innerHTML
    );
    setSortData(sortOtions[currentIdIndex].id);
    let data = {};
    if (sortData === sortOtions[currentIdIndex].id && sortValue === "asc") {
      setSortValue("desc");
      data[sortOtions[currentIdIndex].id] = "desc";
    } else {
      data[sortOtions[currentIdIndex].id] = "asc";
      setSortValue("asc");
    }
    localStorage.setItem("reportsSortUser", JSON.stringify(data));
    getDateClick();
  };

  useEffect(() => {
      initFunc();
  }, []);

  return (
    <>
        {modal && (
          <InfoWindow
            setModal={setModal}
            message={"Не вдалось згенерувати звіт."}
          ></InfoWindow>
        )}
      <div className="headerWithButtonsCommonBlock">
        <div className="headerWithButtonsHeaderBlock">
          <h1>
            Рейтинг користувачів:{" "}

          
            {/* {currentUser.lastName?.length > 0 &&
            currentUser.firstName?.length > 0
              ? currentUser.firstName  + " " + currentUser.lastName
              : currentUser.nickname} */}
          </h1>
        </div>
        {/* {counter && counter.coordinator &&
        <div style={{fontSize:'14px'}}>
          Координаторів у системі: {counter.coordinator || ''};
          <br />
          Зареєстрованих користувачів у системі: {counter.userAndVerifiedUser || ''};
          <br />
          Координаторів:  {reports.total}
          <br />
          Заходів за даний період: {reports.totalEvent}
          <br />
          Учасників за даний період:{reports.totalUsers}
        </div> 
        } */}
      </div>
      <div className="menuFunctionLine forReport">
        <div className="apInputText">
          <label htmlFor="startsFrom">Дата початку звіту</label>
          <input
            id="startsFrom"
            onChange={changeDateHandler}
            type="date"
            max={getDate(new Date(),'yyyy/mm/dd','-')}
            value={
              searchForm.filter?.startsFrom?.length > 0
                ? searchForm.filter.startsFrom[0]
                : ""
            }
          />
        </div>
        <div className="apInputText">
          <label htmlFor="startsTo">Дата кінця звіту</label>
          <input
            id="startsTo"
            onChange={changeDateHandler}
            min={ searchForm?.filter?.startsFrom[0]}
            max={getDate(new Date(),'yyyy/mm/dd','-')}
            type="date"
            value={
              searchForm.filter?.startsTo?.length > 0
                ? searchForm.filter?.startsTo[0]
                : ""
            }
          />
        </div>
        

            {/* <div className="apDropDownList apFilled">
              <label htmlFor="dropDown1">Регіон діяльності</label>
              <div id="dropDown1" onClick={selectClick}>
                <input
                  id="dropDown1"
                  readOnly
                  type="text"
                  defaultValue={
                    (searchForm.filter.region &&
                      searchForm.filter.region[0] &&
                      listId.list[searchForm.filter.region[0]]) ||
                    "Регіон діяльностів"
                  }
                  value={`${
                    (searchForm.filter.region &&
                      searchForm.filter.region[0] &&
                      listId.list[searchForm.filter.region[0]]) ||
                    "Всі регіони"
                  }`}
                />
                <svg id="dropDown1" onClick={selectClick}>
                  <use
                    onClick={selectClick}
                    id="dropDown1"
                    href="/images/icon24DropDown.svg#icon24"
                  />
                </svg>
              </div>
              {selectedOpen == "dropDown1" && (
                <div className="apOptionsList">
                  <div
                        className={
                          searchForm.filter?.region?.length>0 && searchForm.filter.region[0] == 'all' ? "apActive" : ""
                        }
                        onClick={selectFilter}
                        id={'all'}
                      >
                        Всі регіони
                      </div>
                  {fullList.Regions.map((el, index) => {
                    return (
                      <div
                        key={index}
                        className={
                          searchForm.filter?.region?.length>0 && searchForm.filter?.region[0] == el.id ? "apActive" : ""
                        }
                        onClick={selectFilter}
                        id={el.id}
                      >
                        {el.title}
                      </div>
                    );
                  })}
                </div>
              )}
            </div> */}

        <div className="actionButtonsBlock forReport">
          <button
            className="primaryButtonIconTextDefault"
            onClick={getDateClick}
          >
            <svg>
              <use href="/images/icon24Refresh.svg#icon24" />
            </svg>
            Завантажити дані
          </button>
          
          {/* <a href={reports.dirname} download target='_blank'>Завантажити файл</a> */}

          {/* {clocks?
          <button className="primaryButtonIconTextDefault"><Icon type={"timer"}/>Іде завантаження PDF</button>
          :<button
            className="primaryButtonIconTextDefault"
            onClick={getPdfReport}
            disabled={!receive || reports?.items.length < 1 }
          >
            <svg>
              <use href="images/icon24Download.svg#icon24" />
            </svg>
            Створити PDF
          </button>} */}
        </div>
        <div className="headerWithButtonsButtonBlock" style={{paddingTop:'0px'}}>
            {searchOpen && (
              <div style={{ marginBottom: "4px" }} className="apInputText">
                <label htmlFor="search">Пошук</label>
                <input
                  id="search"
                  type="text"
                  value={searchValue}
                  placeholder="Мінімум 3 символи"
                  onChange={searchChange}
                />
              </div>
            )}
            <button
              className="secondaryButtonIconDefault"
              onClick={() => setSearchOpen(!searchOpen)}
            >
              <svg>
                <use href="/images/icon24Search.svg#icon24" />
              </svg>
            </button>
          </div>
          <div className="sortBlock">
            <Select
              label={"Сортування:"}
              value={sortName}
              options={sortOtions}
              onSet={sortClick}
            />
          </div>
      </div>
      <div className="pageContentHeaderDivider" />
      {ready && reports?.items && reports?.items.length > 0 && (
        <div className="dictAllRowsContainer">
          {reports.items.map((el) => {
            return (
                   <div
                  key={el.id}
                  className="usersRowNormal"
                  onClick={() => navigate(`/personalPage/${el.id}`)}
                >
                  <div className="userValue">
                    {el.photo ? (
                      <div
                        className="userValueImage"
                        style={{
                          backgroundImage: `url(${el.photo})`,
                          backgroundSize: "cover",
                          backgroundPosition: "center",
                        }}
                      ></div>
                    ) : (
                      <div className="userValueImage">
                        {el.last_name?.length>0 && el.last_name[0].toUpperCase() +
                          el.first_name[0].toUpperCase()}
                      </div>
                    )}
                    <div className="userValueName">
                      <span>
                        {el.first_name && el.last_name
                          ? el.first_name + " " + el.last_name 
                          : el.nickname}
                      </span>
                      {/* {listId.list[el.roleId]} */}
                    </div>
                  </div>
                  <div className="userValue">
                    <span>{el.events}</span>
                    Кількість заходів
                  </div>
                  <div className="userValue">
                    <span>{el.workouts}</span>
                    Кількість&nbsp;тренувань
                  </div>
                  <div className="userValue">
                    <span>{el.times}</span>
                    Кількість&nbsp;годин
                  </div>
                  <div className="userValue">
                   
                  </div>
                </div>
            );
          })}
          {/* {reports.total > 10 && (
                          <Pagination
                            className="pagination-bar"
                            currentPage={currentPageAll}
                            totalCount={reports.total.toFixed(0)}
                            pageSize="10"
                            onPageChange={async (page) => {
                              await setCurrentPageAll(page);
                              await getDateClick(page);
                            }}
                          />
          )} */}
        </div>
        
      )}
      {ready && reports?.items && reports?.items?.length == 0 && (
        <>
          <div className="apEmptyCategory">Користувачів не знайдено</div>
        </>
      )}
      {!ready && <Loader />}
    </>
  );
};

export default UsersPageReportUser;


