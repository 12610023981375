import {
  GETCURRENTINFOLIST
} from "../actions/types";
  
const initialState = [];

export default function infoReducer(currentInfoList = initialState, action) {
    const { type, list } = action;

    switch (type) {
        case GETCURRENTINFOLIST:
          return list;
        default:
          return currentInfoList;
      }

}

