import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getNotifications } from "../../redux/actions/notifications";
import { Swiper as Slider, SwiperSlide as Slide } from "swiper/react";
import { getEvents, getEventsEvaluations } from "../../redux/actions/events";
import { Navigation, Pagination, EffectFade } from "swiper";


import "swiper/css";
import { useNavigate } from "react-router";
import { Status } from "../../components/Status";
import getDate from "../../helpers/getDate";
const NotificationPage = () => {
  const dispatch = useDispatch();
  const [ready, setReady] = useState();
  const [readyEventList, setReadyEventList] = useState([]);
  const { currentUser } = useSelector((state) => state.currentUser);
  const { notifications } = useSelector((state) => state);
  const { eventEval } = useSelector((state) => state);
  const { events: allEvents } = useSelector((state) => state);
  const navigate = useNavigate();
  const { list } = useSelector((state) => state.listId);

  const initFunc = () => {
    let data = { userId: currentUser?.id };

    dispatch(getNotifications(data)).then((res) => {
      setReady(true);
    });
  };

  const getEventsList = (page = 1, filter) => {
    let data = {};
    let yourDate = new Date();
    const offset = yourDate.getTimezoneOffset();
    yourDate = new Date(yourDate.getTime() - offset * 60 * 1000);
    let specDate = yourDate.toISOString().split("T")[0];
    data.filter ={};
    // if (filter) {
    //   data.filter = { ...filter };
    // } else {
    //   if (localStorage.getItem("eventsFilter")) {
    //     data.filter = { ...JSON.parse(localStorage.getItem("eventsFilter")) };
    //   } else {
    //     data.filter = { startsFrom: [specDate] };
    //   }
    // }

    // if (localStorage.getItem("eventsSort")) {
    //   data.sort = { ...JSON.parse(localStorage.getItem("eventsSort")) };
    // } else {
      data.sort = { createdAt: "asc" };
    // }

    // if (
    //   (data.filter && !data.filter?.startsFrom) ||
    //   data.filter?.startsFrom?.length == 0
    // ) {
      data.filter = { startsFrom: [specDate],startsTo:[specDate] };
    // }

    data.offset = (page - 1) * 10;
    data.limit = 10;

    dispatch(getEvents(data)).then((res) => {
      if(res.items?.length>0){
        let resArr=[]
        res.items.map(el=>{
          if(el.statusId==='032fd5ba-f634-469b-3c30-77a1gh63a918'){
            resArr.push(el);
          }
        })
        setReadyEventList(resArr);
      }
      setReady(true);
    });
  };

  const checkFunc = (obj, stringElem, index = 0) => {
    if (stringElem.split("{{").length > 1) {
      let newStr =
        stringElem.split(`{{${index}}}`)[0] +
        `<a 
       href=${
         obj.entities[index]?.entityType +
         `${(obj.entities[index]?.entityType === "fc-events" && "/0") || ""}` +
         "/" +
         obj.entities[index]?.entityId
       }
        tagret={_blank}
         className="">${
           obj.entities[index]?.entityTitle || obj.entities[index]?.entityId
         }</a>` +
        stringElem.split(`{{${index}}}`)[1];

      return checkFunc(obj, newStr, index + 1);
    } else {
      return stringElem;
    }
  };

  const culcFunc = (date) => {
    let resHours = (new Date() - new Date(date)) / (1000 * 60 * 60);
    if (resHours < 1) {
      return Math.ceil(resHours * 60) + " хв.";
    } else if (resHours > 1 && resHours < 24) {
      return Math.ceil(resHours) + " год. тому";
    } else if (resHours > 24) {
      return Math.ceil(resHours / 24) + " д. тому";
    }
  };

  const events = useMemo(() => {
    const arr = allEvents.items;
    if (!arr || arr.length === 0) return [];
    const currentDate = Date.now();
    return (
      arr.filter((el) => new Date(el.startsAt).getTime() < currentDate) || []
    );
  }, [allEvents]);

  useEffect(() => {
    if (!ready) {
      initFunc();
      getEventsList();
      getEventEsim();
    }
  }, []);


  const getEventEsim=()=>{
    let data={id:currentUser.id}
    dispatch(getEventsEvaluations(data)).then(res=>{
      
    })
  }

  const getButtonText = (statusId) => {
    switch (statusId) {
      case "bd09f36f-835c-49e4-88b8-4f835c1602ac":
        return "Маршрут";

      case "e58e5c86-5ca7-412f-94f0-88effd1a45a8":
        return "Конференція";

      default:
        return "Перейти до заходу";
    }
  };

  return (
    <>
      <h1>Мої сповіщення</h1>
      <div className="pageContentHeaderDivider" />

      <div className="notificationPage">
        {readyEventList && readyEventList.length !== 0 && (
          <>
            {" "}
            <h3>Заходи, що відбуваються зараз:</h3>
            <div>
                <Slider
               modules={[Navigation, Pagination, EffectFade]}
               navigation
               pagination={{ clickable: true }}
                spaceBetween={24}
                slidesPerView={1}

                breakpoints={{
                  640: {
                    slidesPerView: 1,
                  },
                  768: {
                    slidesPerView: 2,
                  },
                  991: {
                    slidesPerView: 3,
                  },
                }}
              >
                {readyEventList.length>0 && readyEventList.map((el, index) => (
                  <Slide key={index}>
                  <div className="startPageItem"
                  style={{
                    backgroundImage: `linear-gradient(0deg, rgba(0,0,0,0.6) 10%, rgba(0,0,0,0) 70%), url('${
                      (el.imageUrl !== null &&
                        el.imageUrl.length !== 0 &&
                        el.imageUrl) ||
                      "https://ap.sportforall.gov.ua/api/v1/uploads/b34604xc-02qb-46bb-9e91-fc3ffrb92w41"
                      }')`,
                  }}
                  id={el.id}
                  onClick={()=>navigate('/fc-events/0/'+el.id)}
                  >
                      <div className="leftsideInfoStartPage">
                        <div  id={el.id}><Status type={"eventHolding"} statusId={el.holdingStatusId} /></div>
                        <div   id={el.id} className="leftsideInfoStartPageHeader">{el.title}</div>
                        <div  id={el.id} className="leftsideInfoStartPageSubText">{getDate(el.startsAt,'dd/mm/yyyy hh:mm')}-{getDate(el.finishesAt,'hh:mm',':')} {el.typeId!='848e3121-4a2b-413d-8a8f-ebdd4ecf2840' && list[el.typeId]}</div>
                      </div>
                     {el.createdBy?.photo?
                      <div className="roundUser" style={{
                          backgroundImage: `url('${el.createdBy.photo}')`,
                      }} id={el.createdBy.id} onClick={()=>navigate('/personalPage/' + el?.createdBy?.id)}>
                      </div>
                      :
                      el?.createdBy? <div  className="roundUser"  id={el?.createdBy?.id} onClick={()=>navigate('/personalPage/' + el?.createdBy?.id)}>
                        {
                        el?.createdBy?.lastName?.length > 0
                            ? el?.createdBy?.lastName[0] + el?.createdBy?.firstName[0]
                            : (el?.createdBy?.nickname?.length > 1 ? el?.createdBy?.nickname[0].toUpperCase() + el?.createdBy?.nickname[1].toUpperCase() : '')
                        }</div>:''}
                      {el.countUser>1 && <div className="roundUser roundUserAdded" id={el.id}>+{el.countUser-1}</div>}
                  </div>
                </Slide>
                ))}
              </Slider>
            </div>
          </>
        )}
      </div>

      <div className="notificationPage">
        {(eventEval?.exerciseEvaluation?.length>0 || eventEval?.sportsEventEvaluation?.length>0) && (
          <>
            {" "}
            <h3>Заходи, що потребують оцінки:</h3>
            <div className="sliderBlockNotifications">
              <Slider
               modules={[Navigation, Pagination, EffectFade]}
               navigation
              //  pagination={{ clickable: true }}
                spaceBetween={24}
                slidesPerView={1}
                breakpoints={{
                  640: {
                    slidesPerView: 1,
                  },
                  768: {
                    slidesPerView: 2,
                  },
                  991: {
                    slidesPerView: 3,
                  },
                }}
              >
                {eventEval?.exerciseEvaluation?.length>0 && eventEval?.exerciseEvaluation?.map((el,index)=>(
                  <Slide key={`el.${index}`}>
                    <div className="apEventTile">
                      <div className="img-wrp">
                        <img
                          src={
                            (el.imageUrl !== null &&
                              el.imageUrl?.length !== 0 &&
                              el.imageUrl) ||
                            "https://ap.sportforall.gov.ua/api/v1/uploads/b34604xc-02qb-46bb-9e91-fc3ffrb92w41"
                          }
                          alt=""
                          onError={(e) => {
                            e.target.onerror = null;
                            e.target.src =
                              "https://ap.sportforall.gov.ua/api/v1/uploads/b34604xc-02qb-46bb-9e91-fc3ffrb92w41";
                          }}
                        />
                      </div>
                      <div className="text">
                        <div className="top">
                          <h4>{el.title}</h4>
                          <p>{el.shortDescription}</p>
                        </div>
                        <button
                          onClick={() => navigate(`/personalPage/${currentUser.id}/trainings`)}
                        > 
                          Оцінити тренування
                        </button>
                      </div>
                    </div>
                  </Slide>))}
                {eventEval?.sportsEventEvaluation?.length>0 && eventEval.sportsEventEvaluation?.map((el, index) => (
                  <Slide key={`el.${index}`}>
                    <div className="apEventTile">
                      <div className="img-wrp">
                        <img
                          src={
                            (el.imageUrl !== null &&
                              el.imageUrl?.length !== 0 &&
                              el.imageUrl) ||
                            "https://ap.sportforall.gov.ua/api/v1/uploads/b34604xc-02qb-46bb-9e91-fc3ffrb92w41"
                          }
                          alt=""
                          onError={(e) => {
                            e.target.onerror = null;
                            e.target.src =
                              "https://ap.sportforall.gov.ua/api/v1/uploads/b34604xc-02qb-46bb-9e91-fc3ffrb92w41";
                          }}
                        />
                      </div>
                      <div className="text">
                        <div className="top">
                          <h4>{el.title}</h4>
                          <p>{el.shortDescription}</p>
                        </div>
                        <button
                          onClick={() => navigate(`/fc-events/0/${el.typeId==='bd09f36f-835c-49e4-88b8-4f835c1602ac'?el.id+'/itinerary':el.id}`)}
                        > 
                         Оцінити захід
                        </button>
                      </div>
                    </div>
                  </Slide>
                ))}
                
              </Slider>
            </div>
          </>
        )}
      </div>

      <div className="notificationPage">
        <h3>Загальні сповіщення</h3>
        <div>
          {notifications.items &&
            notifications.items?.length > 0 &&
            notifications.items.map((el, index) => (
              <div key={index}  className="notificationsItem">
                <div className="notificationsText">
                  {el.imgUrl ? (
                    <div
                      className="userValueImage"
                      style={{
                        backgroundImage: `url(${el.imgUrl})`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                      }}
                    ></div>
                  ) : (
                    <div
                      className="userValueImage"
                      style={{
                        backgroundImage: `url(${'https://ap.sportforall.gov.ua/api/v1/uploads/b34604xc-02qb-46bb-9e91-fc3ffrb92w41'})`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                      }}
                    ></div>

                  )}
                  {el.message.split(`{{`).length > 1 ? (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: checkFunc(el, el.message, 0),
                      }}
                    ></div>
                  ) : (
                    <div>{el.message}</div>
                  )}
                </div>
                <div className="notificationsTime">
                  {culcFunc(el.createdAt)}
                </div>
              </div>
            ))||
            <div className="apEmptyCategory">Немає жодних сповіщень</div>
            }
        </div>
      </div>
    </>
  );
};

export default NotificationPage;
