import React, { useEffect } from 'react';

const InfoWindow = (props) => {

  const closeClick = () => {
    props.setModal(false);
  };

  const buttonClick = () => {
    if (props.setClick) {
      props.setClick();
    }
  };

  // Auto-close the modal after 5 seconds
  useEffect(() => {
    let timer;
    
    // remove the 'fadeOut' class to replay the fade-in animation if needed
    const modalElement = document.querySelector('.apInformation');
    modalElement.classList.remove('fadeOut');
  
    timer = setTimeout(() => {
      // add the 'fadeOut' class to start the fade-out animation
      modalElement.classList.add('fadeOut');
  
      // wait for animation to complete before setting modal to false
      setTimeout(() => {
        props.setModal(false);
      }, 500); // match this to the length of your animation
    }, 3500000);
  
    return () => clearTimeout(timer);
  }, []);

  return (
    <div className='apInformation'>
      <svg onClick={closeClick}><use href="/images/icon24Cancel.svg#icon24"></use></svg>
      <div>
        {props.message}
        {props.buttonMsg && 
          <button onClick={buttonClick} className='secondarySmallButtonTextDefault'>
            {props.buttonMsg}
          </button>
        }
      </div>
    </div>
  );
};

export default InfoWindow;
