import axios from "axios";
import authHeader from "./auth-header";

import constants from '../../constants/constant.json';


const changeUserStatus = async(data)=>{
    try{
        return await axios.post(constants.URL + `api/v1/sport-events/${data.id}/${data.type}`,{...data},{ headers: authHeader()})
        .then((response) => { 
             
            return response.data})
        .catch((e)=>{
             
            console.log(e);
        });
    }catch(error){
         

    }

       
}

const getUserHeads = (data)=>{

    return axios.get(constants.URL + `api/v1/sport-events/${data.id}/participants/heads`,{ headers: authHeader()})
    .then((response) => { 
        return response.data})
    .catch((e)=>{
          
        console.log(e);
    });

}

const getUserMembers = (data)=>{

    return axios.get(constants.URL + `api/v1/sport-events/${data.id}/participants/members`,{ headers: authHeader()})
    .then((response) => { 
        return response.data})
    .catch((e)=>{
          
        console.log(e);
    });

}
const getUserParticipants = (data)=>{

    return axios.get(constants.URL + `api/v1/sport-events/${data.id}/participants/participants`,{ headers: authHeader()})
    .then((response) => { 
        return response.data})
    .catch((e)=>{
          
        console.log(e);
    });
}
const getEventCountUsers = (data)=>{
    return axios.post(constants.URL + `api/v1/sport-events/event-count-users`, {...data},{ headers: authHeader()})
    .then((response) => { 
        return response.data})
    .catch((e)=>{
        console.log(e);
    });
}

const getUserApplying = (data)=>{

    return axios.get(constants.URL + `api/v1/sport-events/${data.id}/participants/applying`,{ headers: authHeader()})
    .then((response) => { 
        return response.data})
    .catch((e)=>{
          
        console.log(e);
    });

}


export default{
    changeUserStatus,
    getUserApplying,
    getUserMembers,
    getUserHeads,
    getUserParticipants,
    getEventCountUsers
}