import {
    GETUSERSHEAD_SUCCESS,
    GETUSERSHEAD_FAIL,
    GETUSERSMEMBERS_SUCCESS,
    GETUSERSMEMBERS_FAIL,
    GETUSERSAPPLYING_SUCCESS,
    GETUSERSAPPLYING_FAIL,
    CHANGEUSERS_SUCCESS,
    CHANGEUSERS_FAIL,
  } from "./types";

  import FcUsersService from "../services/fc-user.service"



  export const getFcUserHeads = (data)=> async(dispatch)=>{
    try{
        const res = await FcUsersService.getUserHeads(data);
        dispatch({
          type: GETUSERSHEAD_SUCCESS,
          list: res,
        });
        
        return res
    }catch (error){
        
        dispatch({
            type: GETUSERSHEAD_FAIL,
            list: {error: error.response},
          });
          const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
          return error.response
    }
}


export const getFcUserMembers = (data)=> async(dispatch)=>{
    try{
        const res = await FcUsersService.getUserMembers(data);
        dispatch({
          type: GETUSERSMEMBERS_SUCCESS,
          list: res,
        });
        
        return res
    }catch (error){
        
        dispatch({
            type: GETUSERSMEMBERS_FAIL,
            list: {error: error.response},
          });
          const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
          return error.response
    }
}

export const getFcUserApplying = (data)=> async(dispatch)=>{
    try{
        const res = await FcUsersService.getUserApplying(data);
        dispatch({
          type: GETUSERSAPPLYING_SUCCESS,
          list: res,
        });
        
        return res
    }catch (error){
        
        dispatch({
            type: GETUSERSAPPLYING_FAIL,
            list: {error: error.response},
          });
          const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
          return error.response
    }
}


export const changeFcUserStatus = (data) => async (dispatch) => {
    try {
      const res = await FcUsersService.changeUserStatus(data);
          
      dispatch({
        type: CHANGEUSERS_SUCCESS,
        payload: data,
      });
  
      return res;
    } catch (err) {
        dispatch({
            type:   CHANGEUSERS_FAIL,
          });
      return err.response.data;
    }
  };