import {
    CREATEINFO_SUCCESS,
    CREATEINFO_FAIL,
    GETINFO_SUCCESS,
    GETINFO_FAIL,
    GETINFOITEM_SUCCESS,
    GETINFOITEM_FAIL,
    EDITINFO_SUCCESS,
    EDITINFO_FAIL,
    DELETEINFO_SUCCESS,
    DELETEINFO_FAIL,
    GETCURRENTINFO,
    GETCURRENTINFOLIST
} from "./types";

import InfoService from "../services/info-pages.service"


export const getInfo = (data)=> async(dispatch)=>{
        
    try{
        const res = await InfoService.getInfoPages(data);
        
        dispatch({
          type: GETINFO_SUCCESS,
          list: res,
        });
        
        return res
    }catch (error){
        
        dispatch({
            type: GETINFO_FAIL,
            list: {error: error.response},
          });
          const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
          return error.response
    }
}


export const getInfoCurrent = (data)=> async(dispatch)=>{
        
    try{
        const res = await InfoService.getInfoPagesItem(data);
        
        dispatch({
          type: GETCURRENTINFO,
          payload: res,
        });
        
        return res
    }catch (error){
          const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
          return error.response
    }
}

export const getInfoCurrentList = (data)=> async(dispatch)=>{
        
    try{
        const res = await InfoService.getInfoPages(data);
        
        dispatch({
          type: GETCURRENTINFOLIST,
          list: res,
        });
        
        return res
    }catch (error){
          const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
          return error.response
    }
}



export const editInfo= (form)=>(dispatch)=>{
      
    return InfoService.editInfoPages(form).then(
        (response)=>{
              
            dispatch({
                type:EDITINFO_SUCCESS,
                item:response
            });
            
            return response

        },
        (error)=>{
            dispatch({
                type: EDITINFO_FAIL,
              });
            console.log(error);
              return Promise.reject();
        }
    );
}


export const deleteInfo= (data)=>(dispatch)=>{
      
    return InfoService.deleteInfoPages(data).then(
        (response)=>{
            dispatch({
                type:DELETEINFO_SUCCESS,
            });
              
        },
        (error)=>{
            dispatch({
                type: DELETEINFO_FAIL,
              });
            console.log(error);
                 
              return Promise.reject();
        }
    );
}

export const getInfoItem = (data)=>(dispatch)=>{
    return InfoService.getInfoPagesItem(data).then(
        (response)=>{
              
            dispatch({
                type:GETINFOITEM_SUCCESS,
                item:response,
            });
              
            return response

        },
        (error)=>{
            dispatch({
                type: GETINFOITEM_FAIL,
              });
            console.log(error);
                 
              return Promise.reject();
        }
    );

}


export const addInfo = (data)=> async(dispatch)=>{
    try {
        const res = await InfoService.addInfoPages(data);
        dispatch({
          type: CREATEINFO_SUCCESS,
          item: res
        });
        return res
    }catch (error){
        dispatch({
            type: CREATEINFO_FAIL,
            item: {error: error.response},
          });
          const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
          return error.response;
    }
}