import {
    GETEVENTS_SUCCESS,
    GETEVENTS_FAIL,
    EDITEVENTS_SUCCESS,
    EDITEVENTS_FAIL,
    DELETEEVENTS_SUCCESS,
    DELETEEVENTS_FAIL,
    GETEVENTSITEM_SUCCESS,
    GETEVENTSITEM_FAIL,
    CREATEEVENTS_SUCCESS,
    CREATEEVENTS_FAIL,
    GETEVENTSCURRENTITEM_SUCCESS,
    GETEVENTSCURRENTITEM_FAIL,
    GETEVENTSRESULT_SUCCESS,
    GETEVENTSRESULT_FAIL,
    GETCONF_SUCCESS,
    GETCONF_FAIL,
    READEEVENTQR_SUCCESS,
    READEEVENTQR_FAIL,
    GETEVENTEVALUATION_SUCCESS,
    GETEVENTEVALUATION_FAIL,
    GETALLCALENDAREVENTS_SUCCESS,
    GETALLCALENDAREVENTS_FAIL
} from "./types";

import EventsService from "../services/events.service"


export const getEvents = (data) => async (dispatch) => {

    try {
        const res = await EventsService.getEvents(data);

        dispatch({
            type: GETEVENTS_SUCCESS,
            list: res,
        });

        return res
    } catch (error) {

        dispatch({
            type: GETEVENTS_FAIL,
            list: {
                error: error.response
            },
        });
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        return error.response
    }
}

export const getAllEvents = (data) => async (dispatch) => {

    try {
        const res = await EventsService.getAllEvents(data);

        dispatch({
            type: GETEVENTS_SUCCESS,
            list: res,
        });

        return res
    } catch (error) {

        dispatch({
            type: GETEVENTS_FAIL,
            list: {
                error: error.response
            },
        });
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        return error.response
    }
}


export const getMyEvents = (data) => async (dispatch) => {

    try {
        const res = await EventsService.getMyEvents(data);
        dispatch({
            type: GETEVENTS_SUCCESS,
            list: res,
        });

        return res
    } catch (error) {

        dispatch({
            type: GETEVENTS_FAIL,
            list: {
                error: error.response
            },
        });
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        return error.response
    }
}

export const getNearest = (data) => async (dispatch) => {
    try {
        const res = await EventsService.getNearest(data);
        dispatch({
            type: GETEVENTS_SUCCESS,
            list: res,
        });

        return res
    } catch (error) {

        dispatch({
            type: GETEVENTS_FAIL,
            list: {
                error: error.response
            },
        });
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        return error.response
    }
}
export const getAllCallendarEvents =(data)=>async (dispatch) => {
    try {
        const res = await EventsService.getAllCallendarEvents(data);

        dispatch({
            type: GETALLCALENDAREVENTS_SUCCESS,
            list: res,
        });
        return res
    } catch (error) {

        dispatch({
            type: GETALLCALENDAREVENTS_FAIL,
            list: {
                error: error.response
            },
        });
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        return error.response
    }
}


export const getDayCallendarEvents =(data)=>async (dispatch) => {
    try {
        const res = await EventsService.getDayCallendarEvents(data);

        dispatch({
            type: GETEVENTS_SUCCESS,
            list: res,
        });

        return res
    } catch (error) {

        dispatch({
            type: GETEVENTS_FAIL,
            list: {
                error: error.response
            },
        });
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        return error.response
    }
}


export const editEvents = (form) => (dispatch) => {

    return EventsService.editEvents(form).then(
        (response) => {

            dispatch({
                type: EDITEVENTS_SUCCESS,
                item: response
            });

            return response

        },
        (error) => {
            dispatch({
                type: EDITEVENTS_FAIL,
                item: {
                    error: error.response
                }
            });
            console.log(error);
            return {
                ...error.response.data?.errors?.[0] || error.response.data,
                isFeild: true
            };
        }
    );
}

export const readeEventQr = (data) => (dispatch) => {

    return EventsService.readeEventQr(data).then(
        (response) => {
            dispatch({
                type: READEEVENTQR_SUCCESS,
            });
            return response;
        },
        (error) => {
            dispatch({
                type: READEEVENTQR_FAIL,
            });
            console.log(error);
            return Promise.reject();
        }
    );
}

export const readeEventActionQr = (data) => (dispatch) => {

    return EventsService.readeEventActionQr(data).then(
        (response) => {
            dispatch({
                type: READEEVENTQR_SUCCESS,
            });
            return response;
        },
        (error) => {
            dispatch({
                type: READEEVENTQR_FAIL,
            });
            console.log(error);
            return Promise.reject();
        }
    );
}




export const deleteEvents = (data) => (dispatch) => {

    return EventsService.deleteEvents(data).then(
        (response) => {
            dispatch({
                type: DELETEEVENTS_SUCCESS,
            });

        },
        (error) => {
            dispatch({
                type: DELETEEVENTS_FAIL,
            });
            console.log(error);

            return Promise.reject();
        }
    );
}

export const getEventsItem = (data) => (dispatch) => {
    return EventsService.getEventsItem(data).then(
        (response) => {

            dispatch({
                type: GETEVENTSITEM_SUCCESS,
                item: response,
            });

            return response

        },
        (error) => {
            dispatch({
                type: GETEVENTSITEM_FAIL,
            });
            console.log(error);

            return Promise.reject();
        }
    );
}
export const getEventsCurrentItem = (data) => (dispatch) => {
    return EventsService.getEventsItem(data).then(
        (response) => {

            dispatch({
                type: GETEVENTSCURRENTITEM_SUCCESS,
                item: response,
            });

            return response

        },
        (error) => {
            dispatch({
                type: GETEVENTSCURRENTITEM_FAIL,
            });
            console.log(error);

            return Promise.reject();
        }
    );

}

export const addEvents = (data) => async (dispatch) => {
    try {
        const res = await EventsService.addEvents(data);
        dispatch({
            type: CREATEEVENTS_SUCCESS,
            item: res
        });
        return res
    } catch (error) {
        dispatch({
            type: CREATEEVENTS_FAIL,
            item: {
                error: error.response
            },
        });
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        return error.response;
    }
}


export const setVoteEvent = (data) => async (dispatch) => {
    try {
        const res = await EventsService.setVoteEvent(data);
        return res
    } catch (error) {
        dispatch({
            type: CREATEEVENTS_FAIL,
            item: {
                error: error.response
            },
        });
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        return error.response;
    }
}



export const getMyEventsResults = (data) => async (dispatch) => {

    try {
        const res = await EventsService.getMyEventsResults(data);

        dispatch({
            type: GETEVENTSRESULT_SUCCESS,
            list: res,
        });

        return res
    } catch (error) {

        dispatch({
            type: GETEVENTSRESULT_FAIL,
            list: {
                error: error.response
            },
        });
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        return error.response
    }
}


export const getEventsResults = (data) => async (dispatch) => {

    try {
        const res = await EventsService.getEventsResults(data);

        dispatch({
            type: GETEVENTSRESULT_SUCCESS,
            list: res,
        });

        return res
    } catch (error) {

        dispatch({
            type: GETEVENTSRESULT_FAIL,
            list: {
                error: error.response
            },
        });
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        return error.response
    }
}

export const getConference = (data) => async (dispatch) => {

    try {
        const res = await EventsService.getConfList(data);

        dispatch({
            type: GETCONF_SUCCESS,
            list: res,
        });

        return res
    } catch (error) {

        dispatch({
            type: GETCONF_FAIL,
            list: {
                error: error.response
            },
        });
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        return error.response
    }
}



export const getEventsEvaluations=(data)=>async (dispatch) => {

    try {
        const res = await EventsService.getEventsEvaluations(data);

        dispatch({
            type: GETEVENTEVALUATION_SUCCESS,
            list: res,
        });

        return res
    } catch (error) {

        dispatch({
            type: GETEVENTEVALUATION_FAIL,
            list: {
                error: error.response
            },
        });
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        return error.response
    }
}