import {
    GETUSERVIDEO_SUCCESS,
    GETUSERVIDEO_FAIL,
    EDITUSERVIDEO_SUCCESS,
    EDITUSERVIDEO_FAIL,
    DELETEUSERVIDEO_SUCCESS,
    DELETEUSERVIDEO_FAIL,
    GETUSERVIDEOITEM_SUCCESS,
    GETUSERVIDEOITEM_FAIL,
    CREATEUSERVIDEO_SUCCESS,
    CREATEUSERVIDEO_FAIL
} from "../actions/types";

const initialState = [];

export default function videoUserReducer(video = initialState,action){
    const {list,item,type} = action;

    switch(type) {
        case  CREATEUSERVIDEO_SUCCESS:
            return {currentPark:item}
        case CREATEUSERVIDEO_FAIL:
            return video;
        case GETUSERVIDEO_SUCCESS:
            return {...list};
        case GETUSERVIDEO_FAIL:
            return video;
        case GETUSERVIDEOITEM_SUCCESS:
            return {...item};
        case GETUSERVIDEOITEM_FAIL:
            return video;
        case EDITUSERVIDEO_SUCCESS:
            return video;
        case EDITUSERVIDEO_FAIL:
            return video;
        case DELETEUSERVIDEO_SUCCESS:
            return video;
        case DELETEUSERVIDEO_FAIL:
            return video;
        default:
            return video;
    }

}