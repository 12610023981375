import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import InfoWindow from "../../components/InfoWindow/InfoWindow";
import { getCodeNew, restore } from "../../redux/actions/auth";

const RestorePage = () => {
  const navigate = useNavigate();
  const [form, setForm] = useState({
    code: "",
    password: "",
  });

  const [validForm, setValidForm] = useState({
    password: true,
    code: true,
  });

  const [passwordShown, setPasswordShown] = useState(false);
  const [code, setCode] = useState(false);
  const [readyForChange, setReadyForChange] = useState(false);
  const [alertModal, setAlertModal] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [seconds, setSeconds] = useState(0);
  const [error, setError] = useState([]);
  useEffect(() => {
    if (seconds > 0) {
      setTimeout(() => setSeconds(seconds - 1), 1000);
    }
  }, [seconds]);

  const handleClick = () => {
    navigate("/login");
  };
  const dispatch = useDispatch();

  // const togglePasswordVisiblity =()=>{
  //     setPasswordShown(passwordShown ? false : true)
  // }
  
  const resiveCode = async (event) => {
    try {
      event.preventDefault();
      setSeconds(60);
      setReadyForChange(true);
      dispatch(getCodeNew({ phoneLogin: form.phoneLogin }))
        .then((res) => {
          if(res?.error){
          setAlertModal(true)
          setAlertMessage(res.message)
          }else{
            return;
          }
        })
        .catch((e) => {
          setAlertModal(true)
          setAlertMessage('Не зареєстрований')
          console.log(e);
        });
      setCode(true);
    } catch (e) {
      console.log(e);
    }
  };

  const changeHandler = (event) => {
    const emailCheck =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const passwordCheck = /^(?=.*\d)(?=.*?[a-z])(?=.*?[A-Z]).{6,30}$/;
    const codeCheck = /^[0-9]{6}$/;
    setError([]);
    switch (event.target.name) {
      case "password":
        if (passwordCheck.test(String(event.target.value))) {
          setValidForm({ ...validForm, [event.target.name]: true });
        } else {
          setValidForm({ ...validForm, [event.target.name]: false });
        }
        setForm({ ...form, [event.target.name]: event.target.value });
        break;
      case "code":
        if (codeCheck.test(String(event.target.value))) {
          setValidForm({ ...validForm, [event.target.name]: true });
        } else {
          setValidForm({ ...validForm, [event.target.name]: false });
        }
        setForm({ ...form, [event.target.name]: event.target.value });
        break;
      default:
        setForm({ ...form, [event.target.name]: event.target.value });
        break;
    }
  };
  const restorePsw = async (event) => {
    event.preventDefault();
    setReadyForChange(true);
    let data={};
    data.phoneLogin=form.phoneLogin;
    data.userCode=form.code;
    data.passwordRepeat=form.password;
    data.password=form.password;
    data.typeId=0;

    dispatch(restore(data))
      .then((res) => {
        if (res && res.errors && res.errors.length>0  ) {
          setError(res.errors[0]);
        }else if( res && res.error) {
          // console.log(res)
          setAlertModal(true)
          setAlertMessage(res.message)
        }
         else {
          handleClick();
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <>
      {alertModal && (
          <InfoWindow
            setModal={setAlertModal}
            message={alertMessage}
          ></InfoWindow>
        )}
    <div className="authContainer">
      <form className="authForm" onSubmit={restorePsw}>
        <h1 className="">Відновлення паролю</h1>

        <div
          className={
            error && error.param === "phoneLogin"
              ? "apInputText apError"
              : "apInputText"
          }
        >
          <label htmlFor="phoneLogin">Пошта, телефон або нікнейм</label>
          <input
            className={
              form.phoneLogin && form.phoneLogin.length > 0
                ? "inputInvalid btn txt-input"
                : "btn txt-input"
            }
            type="phoneLogin"
            name="phoneLogin"
            required
            onChange={changeHandler}
            value={form.phoneLogin}
          ></input>
          {error.param === "phoneLogin" && <span className="apError">{error.msg}</span>}
        </div>
        {readyForChange && seconds > 0 && (
          <span className="prompt">
            Отримати код знову можна через: {seconds}сек.
          </span>
        )}
        <div className="apInputText">
          {seconds>0 ? (
            <i className="icon ready-icon"></i>
          ) : (
            <button
              className="primaryButtonTextDefault"
              onClick={resiveCode}
              disabled={seconds > 0}
            >
              Отримати код
            </button>
          )}
        </div>

        {readyForChange && <div
          className={
            error && error.param === "code"
              ? "apInputText apError"
              : "apInputText"
          }
        >
        <label htmlFor="code">{form.phoneLogin.includes('@') ? 'Код із ел. пошти' : 'Код із телефону'}</label>
          <input
            className={
              !validForm.code ? "inputInvalid btn txt-input" : "btn txt-input"
            }
            disabled={!code}
            type="text"я
            name="code"
            required
            onChange={changeHandler}
            value={form.code}
          />
          {!validForm.code && (
            <span className="apError">Перевірте правильність коду</span>
          )}
          {error.param === "code" && <span className="apError">{error.msg}</span>}
        </div>}
        {code && (
          <div
            className={
              error && error.param === "password"
                ? "apInputText apError"
                : "apInputText"
            }
          >
            <label htmlFor="password">Новий пароль</label>
            <input
              className={
                !validForm.password ? "inputInvalid psw-input" : "psw-input"
              }
              type={passwordShown ? "text" : "password"}
              name="password"
              required
              onChange={changeHandler}
              value={form.password}
            />
            {!validForm.password && <span className="">Перевірте пароль (Пароль повинен містити великі, маленькі літери та цифри)</span>}
            {error.param === "password" && (
              <span className="">{error.msg}</span>
            )}
          </div>
        )}
        {code && (
          <button
            className="primaryButtonTextDefault"
            type="submit"
            disabled={
              !validForm.password ||
              !form.password.length > 0 ||
              !validForm.code ||
              !form.code.length > 0 ||
              error.param
            }
          >
            Зберегти новий пароль
          </button>
        )}
        <a className="" onClick={handleClick}>
          Скасувати
        </a>
      </form>
      <div className="rightSide">
        <img loading="lazy" src="images/RegistrationImage.svg" alt="LoginImage Not Found" />
        <div className="registrPage">ЗДОРОВА&nbsp;УКРАЇНА</div>
        <div className="registrPage">
          <span>ПРОГРАМА&nbsp;ПРЕЗИДЕНТА&nbsp;УКРАЇНИ</span>{" "}
        </div>
      </div>
    </div>
    </>
  );
};

export default RestorePage;
