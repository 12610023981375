import {
  ROUTE_SUCCESS,
  ROUTE_FAIL,
  EDITROUTE_SUCCESS,
  EDITROUTE_FAIL,
  DELETEROUTE_SUCCESS,
  DELETEROUTE_FAIL,
  GETROUTEITEM_SUCCESS,
  GETROUTEITEM_FAIL,
  ROUTEPOINTS_SUCCESS
} from "./types";

  import RouteService from "../services/route.service";

  export const getRoutes = (data)=> (dispatch) => {
    return RouteService.getRoutes(data).then(
      (response) => {
          dispatch({
          type: ROUTE_SUCCESS,
          payload:response
        });
        return response;
      },
      (error) => {
        dispatch({
          type: ROUTE_FAIL,
        });
    
        return Promise.reject();
      }
    );
  }

  export const getRoutePoints = (data)=> (dispatch) => {
    return RouteService.getRoutePoints(data).then(
      (response) => {
        dispatch({
          type: ROUTEPOINTS_SUCCESS,
          item:response
        });
        return response;
      },
      (error) => {
        dispatch({
          type: ROUTE_FAIL,
        });
    
        return Promise.reject();
      }
    );
  }



  export const editRoute= (form)=>(dispatch)=>{
      
    return RouteService.editRoute(form).then(
        (response)=>{
              
            dispatch({
                type:EDITROUTE_SUCCESS,
                item:response
            });
            
            return response

        },
        (error)=>{
            dispatch({
                type: EDITROUTE_FAIL,
              });
            console.log(error);
              return Promise.reject();
        }
    );
}

export const createRoute= (form)=>(dispatch)=>{
      
  return RouteService.createRoute(form).then(
      (response)=>{
            
          dispatch({
              type:EDITROUTE_SUCCESS,
              item:response
          });
          
          return response

      },
      (error)=>{
          dispatch({
              type: EDITROUTE_FAIL,
            });
          console.log(error);
            return Promise.reject();
      }
  );
}

export const deleteRoute= (data)=>(dispatch)=>{
      
  return RouteService.deleteRoute(data).then(
      (response)=>{
          dispatch({
              type:DELETEROUTE_SUCCESS,
          });
            
      },
      (error)=>{
          dispatch({
              type: DELETEROUTE_FAIL,
            });
          console.log(error);
               
            return Promise.reject();
      }
  );
}

export const getRouteItem = (data)=>(dispatch)=>{
  return RouteService.getRouteItem(data).then(
      (response)=>{
            
          dispatch({
              type:GETROUTEITEM_SUCCESS,
              item:response,
          });
            
          return response

      },
      (error)=>{
          dispatch({
              type: GETROUTEITEM_FAIL,
            });
          console.log(error);
               
            return Promise.reject();
      }
  );

}

export const favoutiteRouteItem = (data)=>(dispatch)=>{
  return RouteService.favoutiteRouteItem(data).then(
      (response)=>{
            
          dispatch({
              type:EDITROUTE_SUCCESS,
              item:response,
          });
            
          return response

      },
      (error)=>{
          dispatch({
              type: EDITROUTE_FAIL,
            });
          console.log(error);
               
            return Promise.reject();
      }
  );

}
