import React from "react";


const  TestPage=()=>{
    let email='udaff1998@icloud.com'
    let code='123456'

return(
    <>
    <div className="testPage">
        <img src="/images/1.jpg" alt="1.png" />
        <img src="/images/2.jpg" alt="2.png" />
        <img src="/images/3.jpg" alt="3.png" />
    </div>
<br />
<br />
<br />
    <div >
    <header style={{
        height: '80px',
        paddingLeft:'50px',
        background:'#B0C105',
        marginBottom:'40px'
    }}>
        <img style={{
            marginTop:'12px',
            width: '160px',
            height:'56px',
        }} src="https://ap.sportforall.gov.ua/images/Logo-2.svg" alt="" />
    </header>
    <p style={{
        fontStyle: 'normal',
        fontWeight: '700',
        fontSize: '24px',
        lineHeight:'29px',
        color: '#444F60',
        marginBottom:'40px',
        paddingLeft:'50px'
    }}>Шановний(-а), {email}!</p>
    <p style={{
        paddingLeft:'50px',
        fontStyle: 'normal',
        fontWeight: 300,
        fontSize: '14px',
        lineHeight: '17px',
        color: '#444F60',
        marginBottom:'40px'
    }}>Ми отримали запит на надсилання разового коду для вашого облікового запису <span style={{fontWeight:'600'}}>“Active Parks”</span>.</p>
    <p style={{
        fontWeight: 200,
        fontSize: '10px',
        // lineHeight: '12px',
        textAlign: 'center',       
        color: '#444F60',
        marginBottom:'5px'
    }}>Ваш разовий код:</p>
    <div style={{
        width:'235px',
        background:'#B0C105',
        borderRadius: '24px',
        margin:'0 auto 40px'
        
    }}>
        <p style={{
            fontStyle: 'normal',
            fontWeight: 700,
            fontSize: '32px',
            lineHeight:'38px',
            textAlign: 'center',
            color: '#FFFFFF',
            padding:'7px 0'
        }}>${code}</p>
    </div>
    <p style={{
        paddingLeft:'50px',
        fontStyle: 'normal',
        fontWeight: 300,
        fontSize: '14px',
        lineHeight: '17px',
        color: '#444F60',
        marginBottom:'40px'
    }}>Якщо ви не запитували цей код, можете сміливо ігнорувати повідомлення електронної пошти. Можливо, хтось ввів вашу адресу електронної пошти помилково.</p>
    <p style={{
        fontStyle: 'normal',
        fontWeight: 300,
        fontSize: '14px',
        lineHeight: '17px',
        color: '#444F60',
        marginBottom:'40px',
        paddingLeft:'50px',
    }}>З великою повагою,<br/> Служба підтримки облікових записів <span style={{fontWeight:'600'}}>“Active Parks”</span></p>
    <footer style={{
        background:'#FFFFFF',
        padding: '20px 0 20px 50px'
    }}>
        <p style={{
            fontStyle: 'normal',
            fontWeight: 300,
            fontSize: '10px',
            lineHeight: '12px',            
            color: '#444F60',
        }}>Перейти до Активних парків: <a target={'_blank'} href="https://apps.apple.com/us/app/id1661291870" style={{
            color:'#1BA8B2',
            fontStyle: 'normal',
            fontWeight: 700,
            fontSize: '10px',
            lineHeight: '12px',
            textDecorationLine: 'underline',
            }}>iPhone</a> | <a target={'_blank'} href="https://play.google.com/store/apps/details?id=com.technodreams.activeparks" style={{
                color:'#1BA8B2',
                fontStyle: 'normal',
                fontWeight: 700,
                fontSize: '10px',
                lineHeight: '12px',
                textDecorationLine: 'underline'
                }}>Android</a> | <a target={'_blank'} href="https://ap.sportforall.gov.ua/" style={{
                color:'#1BA8B2',
                fontStyle: 'normal',
                fontWeight: 700,
                fontSize: '10px',
                lineHeight: '12px',
                textDecorationLine: 'underline'}}>Веб-сайт</a></p>
    </footer>
    
    </div>
    
    </>
   


)

}


export default TestPage;