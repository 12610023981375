import React, { useEffect, useState } from "react";
import { useMemo } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import InfoWindow from "../../components/InfoWindow/InfoWindow";
import Loader from "../../components/Loader/Loader";
import {
  addPark,
  deletePark,
  getParkItem,
  clonePark,
} from "../../redux/actions/parks";
import CoordinatorPage from "./CoordinatorPage/CoordinatorPage";

import InfoPage from "./InfoPage/InfoPage";
import MessagesPage from "./MessagePage/MessagesPage";

const ParkItemPage = () => {
  const [ready, setReady] = useState(false);
  const navigate = useNavigate();
  const { fullList } = useSelector((state) => state);
  const { listId } = useSelector((state) => state);
  const { parks } = useSelector((state) => state);
  const { currentUser } = useSelector((state) => state.currentUser);
  const [deleteModal, setDeleteModal] = useState(false);
  const dispatch = useDispatch();
  const currentId = useParams().id;
  const type = useParams().type || 'info';

  const isCurrentUser = useMemo(() => {
    if (!parks || !currentUser) return;
    return (

      parks.coordinators?.filter((el) => el.id === currentUser?.id) !==
        undefined ||
      parks.owner?.id === currentUser.id ||
      currentUser.roleId === "66d22cb4-2cf9-4aa7-806b-c50b50f27020"
    );
  }, [parks, currentUser]);

  const getFile = () => {
    window.open("https://media.sportforall.gov.ua/media/requirements.docx");
  };
  const getItem = () => {
    setReady(false);
    dispatch(getParkItem({ id: currentId }))
      .then((res) => {
        setReady(true);
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const deleteClick = () => {
    setDeleteModal(true);
  };

  const deleteSubmit = () => {
    dispatch(deletePark({ id: currentId }))
      .then((res) => {
        setDeleteModal(false);
        navigate("/parklistpage");
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const CoordinatorClick = () => {
    navigate(`/sportsground/${currentId}/coordinators`);
  };
  const messageClick = () => {
    navigate(`/sportsground/${currentId}/message`);
  };
  const parkClick = () => {
    navigate(`/sportsground/${currentId}/info`);
  };
  const editClick = () => {
    let data = {};
    data.id = currentId;
    dispatch(clonePark(data)).then((res) => {
      navigate(`/sportsground/${res.id}/info`);
      getItem();
    });
  };

  const changeStatus = (e) => {
    let data = {};
    data.type = e.target.accessKey;
    data.id = currentId;
    dispatch(addPark(data)).then((res) => {
      if (parks.originalId) {
        navigate(`/sportsground/${parks.originalId}/info`);
      }
      getItem();
    });
  };

  useEffect(() => {
    if (!ready) {
      getItem();
    }
  }, []);

  if (!ready || !fullList.OwnershipTypes || !listId.list) {
    return (
      <div style={{ position: "relative" }}>
        {" "}
        <div style={{ position: "absolute", top: "50%", left: "50%" }}>
          <Loader />
        </div>{" "}
      </div>
    );
  }

  if (ready && fullList.OwnershipTypes) {
    return (
      <>
        {deleteModal && (
          <InfoWindow
            setClick={deleteSubmit}
            setModal={setDeleteModal}
            buttonMsg={"Видалити"}
            message={"Ви впевнені, що хочете видалити?"}
          ></InfoWindow>
        )}

        <h1>Картка майданчика</h1>
        <div className="currentStatusHeader">
          Статус:<h4>{listId.list[parks.statusId]}</h4>
          <span className="currentStatusDivider" />
          Рейтинг:
          <svg>
            <use href="/images/icon24Star.svg#icon24" />
          </svg>
          <h4>0</h4>
          {/*  Зачем? */}
          <span className="currentStatusDivider"></span>
          {parks.originalId && (
            <a
              onClick={(e) => {
                e.preventDefault();
                navigate(`/sportsground/${parks.originalId}/info`);
                getItem();
              }}
            >
              Сторінка вихідного майданчика
            </a>
          )}
        </div>
        <div className="menuFunctionLine">
          <div className="actionButtonsBlock">
            <button
              className="secondaryButtonIconTextDefault"
              onClick={() => navigate("/parklistpage")}
            >
              <svg>
                <use href="/images/icon24ArrowLeft.svg#icon24" />
              </svg>
              Назад
            </button>
            {parks.statusId === "977f1037-77ca-4e7a-90ef-dc1a974e2ff2" && (
              <button
                className="primaryButtonIconTextDefault"
                accessKey="send"
                onClick={changeStatus}
              >
                <svg>
                  <use href="/images/icon24Rocket.svg#icon24" />
                </svg>
                {"На модерацію"}
              </button>
            )}

            {(parks.statusId === "3587410c-7bfa-46c7-a003-c16a408c3004" ||
              parks.statusId === "99ca4f35-663c-4e0a-b9ef-f70219aa8163") &&
              currentUser.roleId === "66d22cb4-2cf9-4aa7-806b-c50b50f27020" && (
                <button
                  className="primaryButtonIconTextDefault"
                  accessKey="publish"
                  onClick={changeStatus}
                >
                  <svg>
                    <use href="/images/icon24Rocket.svg#icon24" />
                  </svg>
                  Опублікувати
                </button>
              )}
              {!parks.originalId && (parks.statusId === "3587410c-7bfa-46c7-a003-c16a408c3004" ||
              parks.statusId === "99ca4f35-663c-4e0a-b9ef-f70219aa8163") &&
              currentUser.roleId === "66d22cb4-2cf9-4aa7-806b-c50b50f27020" && (
                <button
                  className="secondaryButtonIconTextDefault"
                  accessKey="revoke"
                  onClick={changeStatus}
                >
                  <svg>
                    <use href="/images/icon24Send.svg#icon24" />
                  </svg>
                  Повернути до чорнетки
                </button>
              )}

            {parks.statusId === "036735ba-f634-469b-ac30-77a164e3a918" &&
              currentUser.roleId === "66d22cb4-2cf9-4aa7-806b-c50b50f27020" && (
                <button
                  className="primaryButtonIconTextDefault"
                  accessKey="deactivate"
                  onClick={changeStatus}
                >
                  <svg>
                    <use href="/images/icon24Rocket.svg#icon24" />
                  </svg>
                  Деактивувати
                </button>
              )}
            {(parks.statusId === "036735ba-f634-469b-ac30-77a164e3a918" ||
              parks.statusId === "99ca4f35-663c-4e0a-b9ef-f70219aa8163") && (
              <button
                className="primaryButtonIconTextDefault"
                accessKey="сlone"
                onClick={editClick}
              >
                <svg>
                  <use href="/images/icon24Edit.svg#icon24" />
                </svg>
                Редагувати
              </button>
            )}
            {parks.statusId !== "036735ba-f634-469b-ac30-77a164e3a918" &&
              parks.statusId !== "99ca4f35-663c-4e0a-b9ef-f70219aa8163" &&
              (currentUser.roleId === "66d22cb4-2cf9-4aa7-806b-c50b50f27020" ||
                parks.statusId === "977f1037-77ca-4e7a-90ef-dc1a974e2ff2") && (
                <button
                  className="secondaryButtonIconTextDefault"
                  onClick={deleteClick}
                >
                  <svg>
                    <use href="/images/icon24Trash.svg#icon24" />
                  </svg>
                  Видалити
                </button>
              )}
            {parks.statusId !== "036735ba-f634-469b-ac30-77a164e3a918" &&
              parks.statusId !== "99ca4f35-663c-4e0a-b9ef-f70219aa8163" && (
                <button
                  className="secondaryButtonIconTextDefault"
                  onClick={getFile}
                >
                  <svg>
                    <use href="/images/icon24Info.svg#icon24" />
                  </svg>
                  Технічні вимоги
                </button>
              )}
          </div>
          <div className="actionTriggersBlock">
            {/* <input id="trustedGroundSwiper" type="checkbox" className="apCheckbox" disabled />
        <label htmlFor="trustedGroundSwiper">Перевірений</label> */}
            {/* <div class="apCheckBoxWithLabel">
          <input id="closedGroundSwiper" type="checkbox" className="apCheckbox" />
          <label htmlFor="closedGroundSwiper">На реконструкції</label>
        </div> */}
          </div>
        </div>
        <div className="pageContentHeaderDivider"></div>
        <div className="menuTabsLine">
          <button
            className={
              type === "info"
                ? "tabButtonIconTextActive"
                : "tabButtonIconTextDefault"
            }
            onClick={parkClick}
          >
            <svg>
              <use href="/images/icon24Info.svg#icon24" />
            </svg>
            Про майданчик
          </button>
          {isCurrentUser && (
            <button
              className={
                type === "message"
                  ? "tabButtonIconTextActive"
                  : "tabButtonIconTextDefault"
              }
              onClick={messageClick}
            >
              <svg>
                <use href="/images/icon24MsgCircleDef.svg#icon24" />
              </svg>
              Повідомлення
            </button>
          )}
          <button
            className={
              type === "coordinators"
                ? "tabButtonIconTextActive"
                : "tabButtonIconTextDefault"
            }
            onClick={CoordinatorClick}
          >
            <svg>
              <use href="/images/icon24User.svg#icon24" />
            </svg>
            Координатори
          </button>
        </div>

        {type === "info" && <InfoPage></InfoPage>}
        {type === "message" && <MessagesPage></MessagesPage>}
        {type === "coordinators" && <CoordinatorPage></CoordinatorPage>}
      </>
    );
  }
};

export default ParkItemPage;
