import React, { useState, useEffect } from "react";

function WeekSelector(props) {
  const [selectedWeek, setSelectedWeek] = useState("");
  const [weeks, setWeeks] = useState([]);

  const handleWeekChange = (event) => {
    let data= event.target.value
    setSelectedWeek(data);
    props.getStatistics(data);
  };

  useEffect(() => {
    const currentDate = new Date();
    const currentDay = currentDate.getDay();
    const weeksArray = [];
    for (let i = 0; i < 4; i++) {
      const daysToMonday = currentDay === 0 ? 6 : currentDay - 1;
      const mondayDate = new Date(currentDate);
      mondayDate.setDate(currentDate.getDate() - daysToMonday - i * 7);
      const sundayDate = new Date(mondayDate);
      sundayDate.setDate(mondayDate.getDate() + 6);

      const weekLabel =
        i === 0 ? "Поточний тиждень" : i === 1 ? "Попередній тиждень" : `${mondayDate.toLocaleDateString()} - ${sundayDate.toLocaleDateString()}`;

      weeksArray.push({
        start: mondayDate,
        end: sundayDate,
        label: weekLabel,
      });
    }
    setWeeks(weeksArray);
  }, []);

  return (
    <div>
      <select id="weekSelector" className="statisticSelector" onChange={handleWeekChange} value={selectedWeek}>
       { props.compare && <option>Обрати тиждень</option>}
        {weeks.map((week, index) => (
          <option key={index} value={`${week.start.getFullYear()}-${(week.start.getMonth() + 1).toString().padStart(2, "0")}-${week.start.getDate().toString().padStart(2, "0")}/${week.end.getFullYear()}-${(week.end.getMonth() + 1).toString().padStart(2, "0")}-${week.end.getDate().toString().padStart(2, "0")}`}>
          {week.label}
          </option>
        ))}
      </select>
    </div>
  );
}

export default WeekSelector;
