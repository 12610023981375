import React, { useState, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { logout } from "../../redux/actions/auth";
import useCurrentMediaType from "../../hooks/currentMediaType";
import { useLocation } from "react-router-dom";

import { menuListItems, publicMenuListItems } from "./config";
import { Icon } from "../Icon";
import { editPark } from "../../redux/actions/parks";
import { getNotificationsUnread } from "../../redux/actions/notifications";
import { getEventsEvaluations } from "../../redux/actions/events";

const Header = () => {
  const { currentUser } = useSelector((state) => state.currentUser);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [itemLength, setItemLength] = useState(0);
  const [notifications, setNotifications] = useState(false);

  const { isCurrentSize } = useCurrentMediaType(991);

  const logOutClick = () => {
    dispatch(logout());
    navigate('/start');
    window.location.reload();
  };

  useEffect(() => {
    const mainLength = document.getElementById("mainMenu").childElementCount;
    if (!currentUser || !mainLength || mainLength < 0) {
      return;
    }
    setItemLength(mainLength - 1);
  }, [currentUser, isCurrentSize, isOpen]);

  useEffect(() => {
    if (!currentUser) {
      setIsOpen(false);
    }
  }, [currentUser]);

  const reasonToChangeStyle = useMemo(() => {
    return itemLength > 3 || isCurrentSize;
  }, [itemLength, isCurrentSize]);

  const reasonToShowStyle = useMemo(() => {
    return isCurrentSize;
  }, [ isCurrentSize]);

  const checkFunc=()=>{
    dispatch(getNotificationsUnread()).then(res=>{
      setNotifications(res.result);
    })
  }

  const getEvents=()=>{
    let data={id:currentUser.id}
    dispatch(getEventsEvaluations(data)).then(res=>{
      setNotifications(res.sportsEventEvaluation.length>0||res.exerciseEvaluation.length>0);
    })
  }



  useEffect(() => {
    if(currentUser && currentUser.id){
      checkFunc();
      getEvents();
      const intervalId = setInterval(() => {
        checkFunc();

      }, 300000); 
      return () => clearInterval(intervalId);
    }
   
  }, [currentUser]);

  useEffect(() => {
    if (reasonToChangeStyle) {
      setIsOpen(true);
      setIsOpen(false);
    }
  }, []);

  const notClick=()=>{
    checkFunc();
    navigate(`/notifications`);
  }


  return (
    <>
      <div className="headerLine">
        <div className={`headerCommonBlock `}>
          <div className="headerLogoBlock">
            <img
              src="/images/logo.svg"
              alt="Активні парки"
              onClick={() => {navigate("/start"); window.location.reload()}}
            />
          </div>

          <div className="headerMenuBlock">
            <div className="links menuPublick">
            {!reasonToShowStyle && publicMenuListItems.map((el, index) => 
              {
                return(
                <CustomLink
                  isHidden={el.isHidden}  
                  role={el.role}
                  text={el.text}
                  to={el.to}
                  checkFunc={checkFunc}
                  onClick={() => setIsOpen(false)}
                  key={index}
                />
              )})}
            </div>
            <div
              id={"mainMenu"}
              className={`links ${(reasonToChangeStyle && "isMobile") || ""} ${
                isOpen ? "isOpen" : "isClose"
              }`}
            >
              {reasonToChangeStyle && reasonToShowStyle && publicMenuListItems.map((el, index) => 
              {
                return(
                <CustomLink
                isHidden={el.isHidden}  
                  role={el.role}
                  text={el.text}
                  isTrainer={el.isTrainer}
                  to={el.to}
                  checkFunc={checkFunc}
                  onClick={() => setIsOpen(false)}
                  key={index}
                />
              )})}
              {menuListItems.map((el, index) => 
              {
                if(el.isHidden && !currentUser){return}
                return(
                <CustomLink
                isHidden={el.isHidden}  
                  role={el.role}
                  text={el.text}
                  isTrainer={el.isTrainer}
                  to={el.to}
                  checkFunc={checkFunc}
                  onClick={() => setIsOpen(false)}
                  key={index}
                />
              )})}
            </div>
            <div className="additionalButton" id={"iconsMenu"}>
              {currentUser ? (
                <>
                  {<Icon
                    onClick={notClick}
                    type={notifications?"notification":"notificationOff"}
                    className="notification"
                  />}
                  <Icon
                    onClick={() => navigate(`/personalPage/${currentUser.id}`)}
                    type={"user"}  
                  />
                  <Icon onClick={logOutClick} type={"logout"} />
                </>
              ) : (
                <Icon onClick={() => navigate("/login")} type={"login"} />
              )}

              {reasonToChangeStyle && (
                <button
                  className={`hamburger ${isOpen ? "isOpen" : ""}`}
                  onClick={() => setIsOpen((s) => !s)}
                >
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const CustomLink = ({ text, role, to, onClick,isHidden,checkFunc,isTrainer=false }) => {
  const { currentUser: user } = useSelector((state) => state.currentUser);
  const navigate = useNavigate();
  const path = useLocation().pathname;



  const isAvailable = useMemo(() => {
    return role.length === 0 || role.includes(user?.roleId) ;
  }, [user, role,]);

  if (isAvailable || (isTrainer && user?.isTrainer=='2' ))
    return (
      <button
        className={(path === to && "active") || null}
        onClick={() => {
          checkFunc();
          navigate(`${to}`);
          onClick();
        }}
      >
        {text}
      </button>
    );
  return null;
};

export default Header;
