import {
    GETNOTIFICATIONS_SUCCESS,
    GETNOTIFICATIONS_FAIL,
} from "./types";

import NotificationsService from "../services/notifications.service"


export const getNotifications = (data)=> async(dispatch)=>{
        
    try{
        const res = await NotificationsService.getNotifications(data);
        
        dispatch({
          type: GETNOTIFICATIONS_SUCCESS,
          list: res,
        });
        
        return res
    }catch (error){
        
        dispatch({
            type: GETNOTIFICATIONS_FAIL,
            list: {error: error.response},
          });
          const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
          return error.response
    }
}


export const getNotificationsUnread = (data)=> async(dispatch)=>{
        
    try{
        const res = await NotificationsService.getNotificationsUnread(data);
        return res
    }catch (error){
        
        dispatch({
            type: GETNOTIFICATIONS_FAIL,
            list: {error: error.response},
          });
          const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
          return error.response
    }
}