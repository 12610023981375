import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { getTrackItem } from "../../../redux/actions/track";
import Item from "./Item/Item";
import Map from "./Map/Map";

const MapBlock =(props)=>{

    return(
        <>
            <h2>Мітки треку</h2>
            <div className="apItineraryAdmin" style={{paddingTop:'0'}}>
            <Map  el={props.form?.pointsActiveRoutes}></Map>
            </div>
            
        </>
    )
}

export default MapBlock;