import React from "react";
import classnames from "classnames";

import * as calendar from "./calendar";

import { ReactComponent as Arrow } from "../../assets/images/collapseArrow.svg";

import "./index.css";

export default class Calendar extends React.Component {
  static defaultProps = {
    date: new Date(),
    years: [2020, 2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030],
    monthNames: [
      "Cічень",
      "Лютий",
      "Березень",
      "Квітень",
      "Травень",
      "Червень",
      "Липень",
      "Серпень",
      "Вересень",
      "Жовтень",
      "Листопад",
      "Грудень",
    ],
    weekDayNames: ["Пн", "Вт", "Ср", "Чт", "Пт", "Сб", "Нд"],
    onChange: Function.prototype,
  };

  state = {
    date: this.props.date,
    currentDate: new Date(),
    selectedDate: null,
  };

  get year() {
    return this.state.date.getFullYear();
  }

  get month() {
    return this.state.date.getMonth();
  }

  get day() {
    return this.state.date.getDate();
  }

  handlePrevMonthButtonClick = () => {
    const date = new Date(this.year, this.month - 1);
    this.setState({ date });
    let starts= new Date(date);
    starts.setDate(1);
    let finishs=new Date(date);
    finishs.setDate(32);
    const offset = date.getTimezoneOffset();
    starts = new Date(starts.getTime() - offset * 60 * 1000);
    finishs=new Date(finishs.getTime() - offset * 60 * 1000);
    let specDate = starts.toISOString().split("T")[0];
    let specDate2 = finishs.toISOString().split("T")[0];
    let filter={startsFrom:[specDate],startsTo:[specDate2]}
    this.props.getCalendarEventsList(filter);
  };

  handleNextMonthButtonClick = () => {
    const date = new Date(this.year, this.month + 1);
    this.setState({ date });
    let starts= new Date(date);
    starts.setDate(1);
    let finishs=new Date(date);
    finishs.setDate(32);
    const offset = date.getTimezoneOffset();
    starts = new Date(starts.getTime() - offset * 60 * 1000);
    finishs=new Date(finishs.getTime() - offset * 60 * 1000);
    let specDate = starts.toISOString().split("T")[0];
    let specDate2 = finishs.toISOString().split("T")[0];
    let filter={startsFrom:[specDate],startsTo:[specDate2]}
    this.props.getCalendarEventsList(filter);
  };

  handleSelectChange = () => {
    const year = this.yearSelect.value;
    const month = this.monthSelect.value;

    const date = new Date(year, month);

    this.setState({ date });
  };

  handleDayClick = (date) => {
    this.setState({ selectedDate: date });
    this.props.onChange(date);
  };

  render() {
    const { years, monthNames, weekDayNames } = this.props;
    const { currentDate, selectedDate } = this.state;

    const monthData = calendar.getMonthData(this.year, this.month);

    return (
      <div className="calendar">
        <aside>
          <div className="headline">Умовні позначення</div>
          <ul>
            <li>Звичайний захід </li>
            <li>Онлайн-трансляція </li>
            <li>Захід з маршрутом </li>
            {/* <li>Футбольний матч</li>
            <li>Турнір</li>
            <li>Марафон</li>
            <li>Культурний захід - біг</li>
            <li>Відмінений захід</li> */}
          </ul>
        </aside>
        <div className="calendarBody">
          <header>
            <button
              className="arrows"
              onClick={this.handlePrevMonthButtonClick}
            >
              <Arrow style={{ transform: "rotate(-90deg)" }} />
            </button>
            <select
              ref={(element) => (this.monthSelect = element)}
              value={this.month}
              onChange={this.handleSelectChange}
            >
              {monthNames.map((name, index) => (
                <option key={`${name}.${index}`} value={index}>
                  {name}
                </option>
              ))}
            </select>

            <select
              ref={(element) => (this.yearSelect = element)}
              value={this.year}
              onChange={this.handleSelectChange}
            >
              {years.map((year) => (
                <option key={year} value={year}>
                  {year}
                </option>
              ))}
            </select>

            <button
              className="arrows"
              onClick={this.handleNextMonthButtonClick}
            >
              <Arrow style={{ transform: "rotate(90deg)" }} />
            </button>
          </header>

          <table>
            <thead>
              <tr>
                {weekDayNames.map((name) => (
                  <th key={name}>{name}</th>
                ))}
              </tr>
            </thead>

            <tbody>
              {monthData.map((week, index) => (
                <tr key={index} className="week">
                  {week.map((date, index) => {
                    if (date) {
                      return (
                        <td
                          key={index}
                          className={classnames("day", {
                            today: calendar.areEqual(date, currentDate),
                            selected: calendar.areEqual(date, selectedDate),
                          })}
                          onClick={() => this.handleDayClick(date)}
                        >
                          {date.getDate()}
                          <div className="eventsItems">
                            {this.props?.events?.length>0 &&
                            this.props?.events?.map((el) => {
                            if (calendar.areEqual(date, new Date(el.data))) {
                              if (el.typeId === 'bd09f36f-835c-49e4-88b8-4f835c1602ac') {
                                return(
                                  <div
                                      key={`${date}-${'zahid'}`}
                                      className={'zahid'}
                                  ></div>
                                )
                              } else if (el.typeId === '848e3121-4a2b-413d-8a8f-ebdd4ecf2840') {
                                return(
                                  <div
                                      key={`${date}-${'event'}`}
                                      className={'event'}
                                  ></div>
                                )
                              } else if (el.typeId === 'e58e5c86-5ca7-412f-94f0-88effd1a45a8') {
                                return(
                                  <div
                                      key={`${date}-${'translac'}`}
                                      className={'translac'}
                                  ></div>
                                )
                              }}
                              })}
                          </div>
                        </td>
                      );
                    } else {
                      return <td key={index} />;
                    }
                  })}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}
