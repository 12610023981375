import {
    CHANGEUSERS_SUCCESS,
    CHANGEUSERS_FAIL,
    GETUSERSAPPLYING_SUCCESS,
    GETUSERSAPPLYING_FAIL,
} from "../actions/types";

const initialState = [];

export default function fcUserReducer(FCUser = initialState,action){
    const {type, list} = action;

    switch(type) {
        case CHANGEUSERS_SUCCESS:
            return FCUser;
        case CHANGEUSERS_FAIL:
            return FCUser;
        case GETUSERSAPPLYING_SUCCESS:
            return {...list};
        case GETUSERSAPPLYING_FAIL:
            return FCUser;
        default:
            return FCUser;
    }

}