import {
    GETCOUNTER_SUCCESS,
    GETCOUNTER_FAIL,
} from "../actions/types";

const initialState = [];

export default function infoReducer(counter = initialState,action){
    const {item,type} = action;

    switch(type) {
        case  GETCOUNTER_SUCCESS:
            return item;
        case GETCOUNTER_FAIL:
            return counter;
        default:
            return counter;
    }

}