import {
    CREATESUPPORT_SUCCESS,
    CREATESUPPORT_FAIL,
    GETSUPPORT_SUCCESS,
    GETSUPPORT_FAIL,
    GETSUPPORTITEM_SUCCESS,
    GETSUPPORTITEM_FAIL,
    EDITSUPPORT_SUCCESS,
    EDITSUPPORT_FAIL,
    DELETESUPPORT_SUCCESS,
    DELETESUPPORT_FAIL,
} from "../actions/types";

const initialState = [];

export default function supportReducer(support = initialState,action){
    const {list,item,type} = action;

    switch(type) {
        case  CREATESUPPORT_SUCCESS:
            return {currentSupport:item}
        case CREATESUPPORT_FAIL:
            return support;
        case GETSUPPORT_SUCCESS:
            return {...list};
        case GETSUPPORT_FAIL:
            return support;
        case GETSUPPORTITEM_SUCCESS:
            return {...item};
        case GETSUPPORTITEM_FAIL:
            return support;
        case EDITSUPPORT_SUCCESS:
            return support;
        case EDITSUPPORT_FAIL:
            return support;
        case DELETESUPPORT_SUCCESS:
            return support;
        case DELETESUPPORT_FAIL:
            return support;
        default:
            return support;
    }

}