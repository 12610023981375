import {
    EDITREPORTS_SUCCESS,
    GETREPORTS_SUCCESS,
    GETREPORTS_FAIL,
    EDITREPORTS_FAIL
} from "../actions/types";

// reports={
//     items:[],
//     total:0
// }
const initialState = [];

export default function Reducer(reports = initialState, action) {
    const {
        list,
        item,
        type
    } = action;

    switch (type) {
        case EDITREPORTS_SUCCESS:
            const curentElement = reports.items.filter(({
                id
            }) => id === item.id);
            const index = reports.items.indexOf(curentElement[0]);
            reports.items[index] = item;
            return reports;
        case GETREPORTS_SUCCESS:
            return {
                ...list
            };
        case GETREPORTS_FAIL:
        case EDITREPORTS_FAIL:
        default:
            return reports;
    }

}